import { useState, useRef, useEffect } from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import Card from "react-bootstrap/Card";
import Form from "react-bootstrap/Form";
import Modal from 'react-bootstrap/Modal';
import authApi from "../api/authApi";
import { Link } from "react-router-dom";
import schoolApi from "../api/schoolApi";
import { Helmet } from "react-helmet";          //added by muskan khan
// Add by Abhishek
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Profile = (props) => {
  const token = localStorage.getItem("token");
  const fileInputRef = useRef();
  const [image, setImage] = useState('');
  const [file, setFile] = useState(null);
  const [profile, setProfile] = useState({});
  const [body, setBody] = useState();
  const [show, setShow] = useState(false);
  const [user, setUser] = useState({ password: '', confirmpassword: '' });
  const [selectedFiles, setSelectedFiles] = useState(null);
  // Start: Add by Abhishek 24-06-2024
  const [oldPassword, setOldPassword] = useState(null);
  const [checkOldPassword, setCheckOldPassword] = useState(null);
  const [checkPassword, setCheckPassword] = useState(true);
  const [passwordInputDisabled, setPasswordInputDisabled] = useState({
    password: true,
    confirmPassword: true
  });
  // End: Add by Abhishek 24-06-2024

  useEffect(() => {
    var dataImage = localStorage.getItem('myimage');
    let bannerImg = document.getElementById('profile-image');
    if (localStorage.getItem('myimage') !== '/img_avatar.png') {
      // bannerImg.src = "data:image/png;base64," + dataImage;
    } else {
      bannerImg.src = localStorage.getItem('myimage');
    }
  }, [localStorage.getItem('myimage')])

  useEffect(() => {
    async function init() {
      let result = await schoolApi.getLoginUserData();
      if (result.success) {
        setProfile(result?.userRec);
        setImage(result?.userRec?.profile_image);
      }

      console.log('result:', result);
    }

    init();
  }, []);

  const handlePasswordOnchange = (e) => {
    setUser({ ...user, [e.target.name]: e.target.value });
  };

  const handleChange = (e) => {
    setProfile({ ...profile, [e.target.name]: e.target.value });
  };


  const handleSubmit = async (event) => {
    event.preventDefault();
    console.log('====>>> selected file', selectedFiles)
    console.log('====>>> profile', profile)

    // ============code added by muskan khan 7sept2024=============
    if (profile?.firstname === "") {
      return toast.error("Please Enter First name");
    }
    if (profile?.lastname === "") {
      return toast.error("Please Enter Lastname ");
    }
    if (profile?.email === "") {
      return toast.error("Please Enter email ");
    }
    if (profile?.phone === "") {
      return toast.error("Please Enter phone no..");
    }

    // =====================================================

    let result = {};
    if (selectedFiles === null) {
      result = await schoolApi.saveUser(profile);
      console.log('result save', result)
      if (result.success) {
        toast.success("Record saved successfully")
      }
      else {
        toast.error(result.errors)
      }
    } else {
      console.log('====>>> selectedFiles ! null');
      let result = await schoolApi.saveStaffMemberEditProfile(profile.id, selectedFiles, JSON.stringify(JSON.stringify(profile)));
      console.log("result ", result);


      let data = await authApi.fetchMyImage();
      console.log('data', data)
      if (data) {
        blobToBase64(data).then((base64String) => {
          console.log("base64String", base64String.replace(/^data:image\/(png|jpg|jpeg);base64,/, ""))
          localStorage.setItem("myimage", base64String.replace(/^data:image\/(png|jpg|jpeg);base64,/, ""));
        });
      }
      else {
        localStorage.setItem("myimage", "/img_avatar.png");
      }

      toast.success("Record updated successfully");

    }
  }
  const blobToBase64 = (blob) => {
    return new Promise((resolve, _) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result);
      reader.readAsDataURL(blob);
    });
  }

  const handlePhotoUpload = (event) => {
    setBody(URL.createObjectURL(event.target.files[0]));
    setSelectedFiles(event.target.files[0]);
  };

  const handleClose = () => setShow(false);

  const handleChangeSubmit = async (e) => {
    setShow(true)
    e.preventDefault();
    if (user.password === user.confirmpassword && user.password !== '') {
      const obj = {
        id: profile.id,
        password: user.password
      }
      const result = await schoolApi.updateProfile(obj);
      console.log('Update result', result);
      setShow(false)
      if (result.success) {
        toast.success(result.message, { position: toast.POSITION.TOP_CENTER });
      } else {
        toast.error(result.message, { position: toast.POSITION.TOP_CENTER });
      }
      setCheckPassword(true);
      setOldPassword(null);
      setCheckOldPassword(null);
    } else {
      console.log('Password is null, please enter a valid password.');
      setCheckPassword(false);
    }
  };

  const handleImageUpload = (e) => {
    const selectedFile = e.target.files[0];
    setFile(selectedFile);

    const reader = new FileReader();
    reader.onloadend = () => {
      setImage(reader.result);
    };

    if (selectedFile) {
      reader.readAsDataURL(selectedFile);
    }
  };


  const uploadImage = async () => {
    if (!file) {
      console.log('No file to upload');
      return;
    }
    const formData = new FormData();
    formData.append('image', file);

    console.log('file-pathan->', file);
    console.log('formData-pathan->', formData);

    const profileResult = await schoolApi.createFileForPublic(formData)
    console.log('resuul=>', profileResult);

    if (profileResult.success === true) {
      toast.success('Profile image updated successfully', { position: toast.POSITION.TOP_CENTER });
    } else {
      toast.error('Profile image not updated successfully. Please try again later', { position: toast.POSITION.TOP_CENTER });
    }

  };

  // Start: Add by Abhishek 24-06-2024
  useEffect(() => {
    const timer = setTimeout(() => {
      if (oldPassword) {
        OldPasswordCheck(profile.id, oldPassword);
      } else {
        setCheckOldPassword(null);
        setPasswordInputDisabled((prev) => ({ ...prev, password: true, confirmPassword: true }));
      }
    }, 500);

    return () => {
      clearTimeout(timer);
    }
  }, [oldPassword, profile.id]);

  const OldPasswordCheck = async (id, opassword) => {
    const result = await schoolApi.checkOldPassword(id, opassword);
    console.log(result);
    setCheckOldPassword(result);
    if (result) {
      setPasswordInputDisabled((prev) => ({ ...prev, password: false, confirmPassword: false }));
    } else {
      setPasswordInputDisabled((prev) => ({ ...prev, password: true, confirmPassword: true }));
    }
  }
  // End: Add by Abhishek 24-06-2024

  return (
    <>
      <Helmet>
        <title>{props?.tabName}</title>
      </Helmet>
      <Row className="g-0">
        {/* Add 'align-items-center d-flex justify-start' by Abhishek 24-06-2024 */}
        <Col lg={2} className="align-items-center d-flex justify-start mx-4">
          <Link className="nav-link" to="/">
            Home
          </Link>&nbsp;<i className="fa-solid fa-chevron-right"></i>&nbsp; Profile
        </Col>
        <Col lg={12} className="p-lg-3">
          <Container>
            <Row className="pt-4">
              <Col lg={1}></Col>
              <Col lg={3}>
                <Card>
                  <Card.Body className="text-center">
                    <Card.Title style={{ textAlign: "center" }}>
                      {profile.firstname} {profile.lastname}
                    </Card.Title>
                    <div>
                      {image && <img src={image} alt="Profile Image" style={{ maxHeight: '125px', maxWidth: '200px' }} />}
                      <input
                        type="file"
                        accept="image/*"
                        onChange={handleImageUpload}
                        className="mt-4"
                      />
                      <Button className="btn my-3 " variant="secondary" onClick={uploadImage} style={{ width: "100%", display: "block" }}>
                        Image Upload
                      </Button>
                    </div>

                    <Button className="btn" style={{ width: "100%", display: "block" }} variant="danger" onClick={() => { setShow(true) }}>
                      Change Password
                    </Button>
                    <input
                      onChange={handlePhotoUpload}
                      name="profilephotourl"

                      ref={fileInputRef}
                      type="file"
                      hidden
                    />
                  </Card.Body>
                </Card>
              </Col>

              <Col style={{ backgroundColor: "#fff" }} lg={7}>
                <Row className="view-form-header align-items-center">
                  <Col lg={6}>
                    Edit Profile
                  </Col>
                  <Col lg={6} className="d-flex justify-content-end">
                    {/* Remove mx-2 by Abhishek 24-06-2024 */}
                    <Button className="btn-sm" onClick={handleSubmit}>
                      Save
                    </Button>
                  </Col>
                </Row>

                <Row className="pb-5 py-3">
                  <Col lg={6}>
                    <Form.Group className="mx-3 mb-3">
                      <Form.Label
                        className="form-view-label"
                        htmlFor="formBasicFirstName"
                      >
                        First Name
                      </Form.Label>
                      <Form.Control
                        placeholder="Enter First Name" //added by muskan khan 7 sept 2024
                        required //added by muskan khan 7 sept 2024
                        type="text"
                        name="firstname"
                        controlId="formBasicFirstName"
                        value={profile.firstname}
                        onChange={handleChange}
                      />
                    </Form.Group>
                  </Col>
                  <Col lg={6}>
                    <Form.Group
                      className="mx-3 mb-3"
                    >
                      <Form.Label
                        className="form-view-label"
                        htmlFor="formBasicLastName"
                      >
                        Last Name
                      </Form.Label>
                      <Form.Control
                        placeholder="Enter Last Name" //added by muskan khan 7 sept 2024
                        required //added by muskan khan 7 sept 2024
                        type="text"
                        name="lastname"
                        controlId="formBasicLastName"
                        value={profile.lastname}
                        onChange={handleChange}
                      />
                    </Form.Group>
                  </Col>

                  <Col lg={6}>
                    <Form.Group
                      className="mx-3 mb-3"
                    >
                      <Form.Label
                        className="form-view-label"
                        htmlFor="formBasicLastEmail"
                      >
                        Email
                      </Form.Label>
                      <Form.Control
                        type="email"
                        name="email"
                        controlId="formBasicLastEmail"
                        placeholder="Enter Email"
                        value={profile.email}
                        onChange={handleChange}
                      />
                    </Form.Group>
                  </Col>
                  <Col lg={6}>
                    <Form.Group
                      className="mx-3 mb-3"
                    >
                      <Form.Label
                        className="form-view-label"
                        htmlFor="formBasicPhone"
                      >
                        Phone
                      </Form.Label>
                      <Form.Control
                        type="phone"
                        name="phone"
                        controlId="formBasicPhone"
                        placeholder="Enter Phone"
                        value={profile.phone}
                        onChange={handleChange}
                      />
                    </Form.Group>
                  </Col>
                </Row>
              </Col>
              <Col lg={1}></Col>
            </Row>

            {/* Remove className="modal-sm" by Abhishek 24-06-2024 */}
            <Modal show={show} onHide={handleClose}>
              <Modal.Header closeButton>
                <Modal.Title>Change Password</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Row>
                  <Col lg={12}>
                    <Form>
                      <Row>
                        <Col className="mt-3" >
                          <Form.Group className="mx-3" >
                            <Form.Label
                              className="form-view-label"
                              htmlFor="formBasicOldPassword"
                            >
                              Old Password
                            </Form.Label>
                            <Form.Control
                              type="password"
                              name="oldpassword"
                              id="oldPassword"
                              placeholder="Enter Old password"
                              controlId="formBasicOldPassword"
                              onChange={(e) => setOldPassword(e.target.value)}
                              required
                            />
                            {
                              oldPassword
                                ?
                                checkOldPassword != null
                                  ?
                                  checkOldPassword
                                    ?
                                    <span style={{ color: '#2bd965', fontWeight: '600' }}><i class="fa-regular fa-circle-check"></i> Current Password Match.</span>
                                    :
                                    <span style={{ color: '#f62b2b', fontWeight: '600' }}><i class="fa-regular fa-circle-xmark"></i> Old Password you entered does not match your current password.</span>
                                  : ''
                                : ''
                            }
                          </Form.Group>
                        </Col>
                      </Row>
                      <Row>
                        <Col className="mt-3" >
                          <Form.Group className="mx-3" >
                            <Form.Label
                              className="form-view-label"
                              htmlFor="formBasicNewPassword"
                            >
                              New Password
                            </Form.Label>
                            <Form.Control
                              type="password"
                              name="password"
                              placeholder="Enter New password"
                              controlId="formBasicNewPassword"
                              onChange={handlePasswordOnchange}
                              disabled={passwordInputDisabled.password}
                              required
                            />
                          </Form.Group>
                        </Col>
                      </Row>
                      <Row>
                        <Col className="mt-3">
                          <Form.Group className="mx-3" >
                            <Form.Label
                              className="form-view-label"
                              htmlFor="formBasicConfirmPassword"
                            >
                              Confirm Password
                            </Form.Label>
                            <Form.Control
                              type="password"
                              name="confirmpassword"
                              controlId="formBasicConfirmPassword"
                              disabled={passwordInputDisabled.confirmPassword}
                              placeholder="Enter Confirm Password"
                              onChange={handlePasswordOnchange}
                              required
                            />
                          </Form.Group>
                        </Col>
                      </Row>
                      <Row>
                        <Col className="mt-3 ms-3">
                          {
                            !(checkPassword) ? <span style={{ color: '#f62b2b', fontWeight: '600' }}><i class="fa-regular fa-circle-xmark"></i> Passwords do not match.</span> : ''
                          }

                        </Col>
                      </Row>
                    </Form>
                  </Col>
                  <Col></Col>
                </Row>
              </Modal.Body>
              <Modal.Footer>
                <Button variant="primary" onClick={handleChangeSubmit}>
                  Save
                </Button>
                <Button variant="danger" onClick={handleClose}>
                  Close
                </Button>
              </Modal.Footer>
            </Modal>
          </Container>
        </Col>
        <Col lg={2}></Col>
      </Row>
      <ToastContainer
        position="top-right"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </>
  );
};
export default Profile
