import React, { useEffect, useState } from "react";
import { Container, Row, Col, Card, Badge, ListGroup } from "react-bootstrap";
import schoolApi from "../../api/schoolApi";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const FeesCards = () => {
  const [reportData, setReportData] = useState({ classid: "", session_id: "" });
  const [demandRecord, setDemandRecord] = useState();

  useEffect(() => {
    fetchSessions();
  }, []);
  const formatNumberWithCommas = (number) => {
    return number ? number.toLocaleString("en-IN") : "0";
  };

  const fetchSessions = async () => {
    try {
      const sessions = await schoolApi.fetchSessions();
      console.log("sessions========>>>>>>", sessions);
      if (sessions) {
        const options = sessions.map((sess) => ({
          value: sess.id,
          label: sess.year,
        }));

        const currentSession = sessions.find((sess) => {
          const currentDate = new Date();
          const startDate = new Date(sess.startdate);
          const endDate = new Date(sess.enddate);
          return currentDate >= startDate && currentDate <= endDate;
        });
        console.log("currentSession======>>>>>>", currentSession);
        if (currentSession) {
          setReportData((prevData) => ({
            ...prevData,
            session_id: currentSession.id,
          }));
        }
      }
    } catch (error) {
      console.error("Error fetching sessions:", error);
    }
  };

  useEffect(() => {
    if (reportData.session_id) {
      searchDemands();
    }
  }, [reportData]);

  const searchDemands = async () => {
    try {
      const result = await schoolApi.getDemandCalculations(
        reportData.session_id
      );
      console.log("pawan getting demand cards=====>>>", result);
      if (result.success) {
        setDemandRecord(result.result);
      } else {
        setDemandRecord({ finaldemand: 0, received: 0, pending_amount: 0 });
      }
    } catch (error) {}
  };

  console.log("demandRecord-->", demandRecord);

  return (
    <div>
      <Row className="mb-3">
        <Col lg={3} md={6} sm={12}>
          <Card
            className="text-center p-3 shadow-sm"
            style={{ borderLeft: "4px solid #198754" }}
          >
            <Row>
              <Col lg={3} className="mt-2">
                <span className="fa-stack small">
                  <i
                    className="fa-solid fa-circle fa-stack-2x"
                    style={{ color: "#198754" }}
                  ></i>
                  <i
                    className="fa-solid fa-user-graduate fa-stack-1x"
                    style={{ color: "white", fontSize: "2rem" }}
                  ></i>
                </span>
              </Col>
              <Col lg={9}>
                <Card.Title>Total Students</Card.Title>
                <Card.Text className="display-6">
                  {formatNumberWithCommas(demandRecord?.total_students)}
                </Card.Text>
              </Col>
            </Row>
          </Card>
        </Col>
        <Col lg={3} md={6} sm={12}>
          <Card
            className="text-center p-3 shadow-sm"
            style={{ borderLeft: "4px solid #D32760" }}
          >
            <Row>
              <Col lg={3} className="mt-2">
                <span className="fa-stack small">
                  <i
                    className="fa-solid fa-circle fa-stack-2x"
                    style={{ color: "#D32760" }}
                  ></i>
                  <i
                    className="fa-solid fa-inr fa-stack-1x"
                    style={{ color: "white", fontSize: "2rem" }}
                  ></i>
                </span>
              </Col>
              <Col lg={9}>
                <Card.Title>Demands</Card.Title>
                <Card.Text className="display-6">
                  {formatNumberWithCommas(demandRecord?.final_demand)}
                </Card.Text>
              </Col>
            </Row>
          </Card>
        </Col>
        <Col lg={3} md={6} sm={12}>
          <Card
            className="text-center p-3 shadow-sm"
            style={{ borderLeft: "4px solid #FF4233" }}
          >
            <Row>
              <Col lg={3} className="mt-2">
                <span className="fa-stack small">
                  <i
                    className="fa-solid fa-circle fa-stack-2x"
                    style={{ color: "#FF4233" }}
                  ></i>
                  <i
                    className="fa-solid fa-inr fa-stack-1x"
                    style={{ color: "white", fontSize: "2rem" }}
                  ></i>
                </span>
              </Col>
              <Col lg={9}>
                <Card.Title>Received</Card.Title>
                <Card.Text className="display-6">
                  {formatNumberWithCommas(demandRecord?.received)}
                </Card.Text>
              </Col>
            </Row>
          </Card>
        </Col>
        <Col lg={3} md={6} sm={12}>
          <Card
            className="text-center p-3 shadow-sm"
            style={{ borderLeft: "4px solid #DCE123" }}
          >
            <Row>
              <Col lg={3} className="mt-2">
                <span className="fa-stack small">
                  <i
                    className="fa-solid fa-circle fa-stack-2x"
                    style={{ color: "#DCE123" }}
                  ></i>
                  <i
                    className="fa-solid fa-inr fa-stack-1x"
                    style={{ color: "white", fontSize: "2rem" }}
                  ></i>
                </span>
              </Col>
              <Col lg={9}>
                <Card.Title>Pending</Card.Title>
                <Card.Text className="display-6">
                  {formatNumberWithCommas(demandRecord?.pending_amount)}
                </Card.Text>
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>

      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      ></ToastContainer>
    </div>
  );
};

export default FeesCards;
