import React, { useEffect } from "react";
import { Row, Col, Form } from "react-bootstrap";
import { useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import schoolApi from "../../api/schoolApi";
const ParentInfo = ({
  studentrecord,
  updateStudentRecord,
  required,
  setPrimaryParent,
  handleSearch,
}) => {
  console.log("studentrecord parentinfo ->", studentrecord);

  const handleInputChange = (e, parentType) => {
    const { name, value } = e.target;
    updateStudentRecord({
      [parentType]: {
        ...studentrecord[parentType],
        [name]: value,
      },
    });
  };
  const location = useLocation();

  const handlePhoneChange = (event, parentType) => {
    const inputPhone = event.target.value.replace(/\D/g, "").slice(0, 10);
    handleInputChange(
      { target: { name: "phone", value: inputPhone } },
      parentType
    );
    handleSearch(event);
  };

  return (
    <>
      <Row className="justify-content-end">
        <Col md={3}>
          <Form>
            <Form.Group controlId="formDropdown">
              <Form.Select
                value={required}
                size="lg"
                onChange={(e) => setPrimaryParent(e.target.value)}
              >
                {/* <option value="none">--Select Primary--</option> */}
                <option value="Parent_Father">Father</option>
                <option value="Parent_Mother">Mother</option>
                <option value="Parent_Guardian">Guardian</option>
              </Form.Select>
            </Form.Group>
          </Form>
        </Col>
        <Col md={3}>
          <Form>
            <Form.Group className="text-right" controlId="parentPhone">
              <Form.Control
                type="phone"
                placeholder="Search Phone Number"
                size="lg"
                name="phone"
                maxLength={10}
                onChange={handleSearch}
              />
            </Form.Group>
          </Form>
        </Col>
      </Row>

      <div>
        {["Father", "Mother", "Guardian"].map((role) => (
          <div key={role} className="mb-3">
            <Row>
              <Col md={3}>
                <h3>{`${role} Details :`}</h3>
              </Col>
              <hr />
            </Row>
            <Row className="mt-2">
              <Col md={6}>
                <Form.Group controlId={`${role.toLowerCase()}FirstName`}>
                  <Form.Label className="text-formatted">First Name</Form.Label>
                  <Form.Control
                    type="text"
                    name="firstname"
                    placeholder={`Enter ${role}'s First Name`}
                    size="lg"
                    value={studentrecord[`Parent_${role}`]?.firstname || ""}
                    onChange={(e) => handleInputChange(e, `Parent_${role}`)}
                    required={required === `Parent_${role}`}
                  />
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group controlId={`${role.toLowerCase()}LastName`}>
                  <Form.Label className="text-formatted">Last Name</Form.Label>
                  <Form.Control
                    type="text"
                    name="lastname"
                    size="lg"
                    placeholder={`Enter ${role}'s Last Name`}
                    value={studentrecord[`Parent_${role}`]?.lastname || ""}
                    onChange={(e) => handleInputChange(e, `Parent_${role}`)}
                    required={required === `Parent_${role}`}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row className="mt-2">
              <Col md={6}>
                <Form.Group
                  className="mb-3"
                  controlId={`${role.toLowerCase()}Qualification`}
                >
                  <Form.Label className="text-formatted">
                    Qualification
                  </Form.Label>
                  <Form.Control
                    value={studentrecord[`Parent_${role}`]?.qualification || ""}
                    onChange={(e) => handleInputChange(e, `Parent_${role}`)}
                    type="text"
                    name="qualification"
                    placeholder={`Enter ${role}'s Qualification`}
                    size="lg"
                    // required={required === `Parent_${role}`}
                  />
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form>
                  <Form.Group
                    className="mb-3"
                    controlId={`${role.toLowerCase()}profession`}
                  >
                    <Form.Label className="text-formatted">
                      Profession
                    </Form.Label>
                    <Form.Control
                      type="text"
                      size="lg"
                      name="profession"
                      placeholder={`Enter ${role}'s profession`}
                      value={studentrecord[`Parent_${role}`]?.profession || ""}
                      onChange={(e) => handleInputChange(e, `Parent_${role}`)}
                      //  required={required === `Parent_${role}`}
                    />
                  </Form.Group>
                </Form>
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <Form.Group controlId={`${role.toLowerCase()}Phone`}>
                  <Form.Label className="text-formatted">Phone</Form.Label>
                  <Form.Control
                    type="text"
                    name="phone"
                    size="lg"
                    placeholder={`Enter ${role}'s phone no.`}
                    value={studentrecord[`Parent_${role}`]?.phone || ""}
                    onChange={(e) => handlePhoneChange(e, `Parent_${role}`)}
                    //                    onChange={(e) => handleInputChange(e, `Parent_${role}`)}
                    required={required === `Parent_${role}`}
                  />
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group controlId={`${role.toLowerCase()}Email`}>
                  <Form.Label className="text-formatted">Email</Form.Label>
                  <Form.Control
                    type="email"
                    name="email"
                    size="lg"
                    placeholder={`Enter ${role}'s Email`}
                    value={studentrecord[`Parent_${role}`]?.email || ""}
                    onChange={(e) => handleInputChange(e, `Parent_${role}`)}
                    //  required={required === `Parent_${role}`}
                  />
                </Form.Group>
              </Col>
            </Row>
          </div>
        ))}
      </div>
    </>
  );
};

export default ParentInfo;
