/**
 * @author      Abdul Pathan
 * @date        Aug, 2024
 * @copyright   www.ibirdsservices.com
 */

import React, { useEffect, useRef, useState } from "react";
import { Row, Col, Card, Dropdown, Image, Button } from "react-bootstrap";
import WhatsAppAPI from "../../../api/schoolApi";
import { toast } from "react-toastify"; //npm i react-toastify --force
import "react-toastify/dist/ReactToastify.css";
import TemplateModal from "./TemplateModal";
import moment from "moment";
import SendFileModal from "./SendFileModal";
import { NameInitialsAvatar } from "react-name-initials-avatar"; // npm install react-name-initials-avatar --force
import jwt_decode from "jwt-decode";
import MessageTemplateModal from "./MessageTemplateModal";
import "../../../App.css";
const WhatsAppChat = ({ show, onHide, userDetail, socket, filterData }) => {
  // const [userInfo, setUserInfo] = useState(
  //   jwt_decode(localStorage.getItem("token"))
  // );
  const [input, setInput] = useState("");
  const [messages, setMessages] = useState([]);
  const [receivedMessage, setReceivedMessage] = useState();
  const [showHideModal, setShowHideModal] = useState(false);
  const [showHideBulkModal, setShowHideBulkModal] = useState(false);
  const [contactData, setContactData] = useState();
  const [groupData, setGroupData] = useState();
  const chatWindowRef = useRef(null);
  const [fileModalShowHide, setFileModalShowHide] = useState(false);
  const pdfIcon = "/user_images/pdf-icon-png.jpg";
  const docxIcon = "/user_images/doc-icon.png";
  const xlsIcon = "/user_images/xls-icon.png";
  const mapIcon = "/user_images/google-maps.jpg";
  const textIcon = "/user_images/text-file.jpg";
  const [bgColors, setBgColors] = useState([
    "#d3761f",
    "#00ad5b",
    "#debf31",
    "#239dd1",
    "#b67eb1",
    "#d3761f",
    "#de242f",
  ]);
  const [brokenImages, setBrokenImages] = useState([]);
  let colIndex = 0;

  useEffect(() => {
    socket?.on("receivedwhatsappmessage", (data) => {
      setReceivedMessage(data);
    });
    return () => {
      socket?.off("receivedwhatsappmessage");
    };
  }, [socket]);

  useEffect(() => {
    if (chatWindowRef.current) {
      chatWindowRef.current.scrollTop = chatWindowRef.current.scrollHeight;
    }
  }, [messages]);

  useEffect(() => {
    getMessageHistotyRecords();
  }, [userDetail.id, receivedMessage]);
  const groupMessagesByDate = (messages) => {
    const groupedMessages = [];

    messages?.forEach((msg, index) => {
      const messageDate = moment(msg.createddate).format("YYYY-MM-DD"); // Format the date for comparison

      if (
        groupedMessages.length === 0 ||
        groupedMessages[groupedMessages.length - 1].date !== messageDate
      ) {
        // If the date is different, create a new group with a date separator
        groupedMessages.push({ date: messageDate, messages: [msg] });
      } else {
        // If the date is the same, just add the message to the last group
        groupedMessages[groupedMessages.length - 1].messages.push(msg);
      }
    });

    return groupedMessages;
  };
  const getMessageHistotyRecords = async () => {
    if (userDetail.id && userDetail.whatsapp_number) {
      const result = await WhatsAppAPI.getMsgHistoryRecords(
        userDetail.whatsapp_number
      );

      if (result.success) {
        console.log("result?.records =>", result?.records);
        setMessages(result?.records);
      } else {
        setMessages([]);
      }
    } else if (userDetail.id && !userDetail.whatsapp_number) {
      const result = await WhatsAppAPI.getGroupHistoryRecords(userDetail.id);
      console.log("result ->", result);
      let mergedMessages = [];
      if (result.success) {
        for (let i = 0; i < result.records.length; i++) {
          let currentRecord = result.records[i];

          // For merging consecutive messages
          if (i > 0) {
            let prevRecord = result.records[i - 1];

            if (
              prevRecord.message === currentRecord.message &&
              prevRecord.templatemsg === currentRecord.templatemsg
            ) {
              let timeDiff =
                new Date(currentRecord.createddate) -
                new Date(prevRecord.createddate);
              let timeDiffMinutes = Math.floor(timeDiff / 60000);

              mergedMessages[mergedMessages.length - 1].message =
                prevRecord.message || prevRecord.templatemsg;
              mergedMessages[mergedMessages.length - 1].whatsapp_number =
                prevRecord.whatsapp_number;
              mergedMessages[
                mergedMessages.length - 1
              ].merged_time_diff = `${timeDiffMinutes} minutes`;
              continue;
            }
          }

          let mergedRecord = {
            id: currentRecord.id,
            parent_id: currentRecord.parent_id,
            name: currentRecord.name,
            message_template_id: currentRecord.message_template_id,
            whatsapp_number: currentRecord.whatsapp_number,
            message: currentRecord.message,
            status: currentRecord.status,
            recordtypename: currentRecord.recordtypename,
            file_id: currentRecord.file_id,
            createdbyid: currentRecord.createdbyid,
            lastmodifiedbyid: currentRecord.lastmodifiedbyid,
            createddate: currentRecord.createddate,
            lastmodifieddate: currentRecord.lastmodifieddate,
            website: currentRecord.website,
            call: currentRecord.call,
            copy_code: currentRecord.copy_code,
            is_read: currentRecord.is_read,
            chatmsg: currentRecord.chatmsg,
            templatemsg: currentRecord.templatemsg,
            url: currentRecord.url,
            title: currentRecord.title,
            filetype: currentRecord.filetype,
            description: currentRecord.description,
          };

          mergedMessages.push(mergedRecord);
        }
        console.log("mergedMessages ->", mergedMessages);

        setMessages(mergedMessages);
      } else {
        setMessages([]);
      }
    } else {
      setMessages([]);
    }
  };

  // send whatsapp template message
  const sendTemplateMessage = async () => {
    if (userDetail.id && userDetail.whatsapp_number) {
      setShowHideModal(true);
      setContactData(userDetail);
    } else {
      toast.error("Error: WhatsApp number is required.");
    }
  };

  const sendBulkTemplateMessage = () => {
    if (userDetail.id && !userDetail.whatsapp_number) {
      setShowHideBulkModal(true);
      console.log("userDetail ->", userDetail);

      setGroupData([userDetail]);
    } else {
      toast.error("Error: WhatsApp number is required.");
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      handleSubmit();
    }
  };

  // contact send signle text messages
  const handleSubmit = async (event) => {
    // event.preventDefault();
    if (userDetail.whatsapp_number) {
      if (input.trim()) {
        const singleText = {
          messaging_product: "whatsapp",
          recipient_type: "individual",
          to: `91${userDetail?.whatsapp_number}`,
          type: "text",
          text: {
            preview_url: false,
            body: input,
          },
        };
        const result = await WhatsAppAPI.sendSingleWhatsAppTextMessage(
          singleText
        );
        if (result.error) {
          toast.error(`Error: ${result.error}`);
        } else {
          const newMessage = {
            parent_id: userDetail.id || null,
            name: userDetail.contactname || "",
            message_template_id: null,
            whatsapp_number: userDetail.whatsapp_number,
            message: input,
            status: "Outgoing",
            recordtypename: filterData?.recordType || "",
            file_id: null,
            // website: '',
            // call: '',
            // copy_code: '',
            is_read: true,
          };

          const responce = await WhatsAppAPI.insertMsgHistoryRecords(
            newMessage
          );
          getMessageHistotyRecords();
          toast.success("Message sent successfully.");
          setInput("");
        }
      } else {
        toast.error("Error: Input field required, please enter text here.");
      }
    } else {
      toast.error("WhatsApp number is required.");
    }
  };

  const refreshData = () => {
    getMessageHistotyRecords();
    setShowHideModal();
    setShowHideBulkModal();
  };
  const refreshImageData = () => {
    getMessageHistotyRecords();
    setFileModalShowHide();
  };

  //close
  const onCancelButton = () => {
    onHide(false);
  };

  const onUploadFile = () => {
    if (userDetail.id) {
      setFileModalShowHide(true);
    }
  };

  const copyToClipboard = (text) => {
    navigator.clipboard
      .writeText(text)
      .then(() => {
        toast.success("Code copied to clipboard!");
      })
      .catch((err) => {
        console.log("Error copying text: ", err);
      });
  };
  const renderFilePreview = (msg) => {
    const isLink = msg.chatmsg && msg.chatmsg.match(/(https?:\/\/[^\s]+)/g);

    const googleMapsUrlRegex =
      /https?:\/\/(www\.)?google\.com\/maps\/.+|https?:\/\/maps\.app\.goo\.gl\/[a-zA-Z0-9]+/g;
    const routeInfoRegex = /(From|To|via|Duration):?\s*([^,]+)(?:,\s*|$)/gi;

    const googleMapsUrlMatch =
      msg.chatmsg && msg.chatmsg.match(googleMapsUrlRegex);
    const routeInfoMatches = msg.chatmsg && msg.chatmsg.match(routeInfoRegex);

    const formatMessage = (message) => {
      return message.split("\n").map((line, index) => (
        <span key={index}>
          {line}
          <br />
        </span>
      ));
    };

    if (routeInfoMatches && Array.isArray(routeInfoMatches)) {
      const mapUrl = googleMapsUrlMatch ? googleMapsUrlMatch[0] : "";
      return (
        <div style={{ position: "relative" }}>
          <a href={mapUrl} target="_blank" rel="noopener noreferrer">
            <img
              src={mapIcon}
              alt="Route Map Preview"
              style={{ width: "100%", height: "200px", borderRadius: "8px" }}
            />
          </a>
        </div>
      );
    } else if (googleMapsUrlMatch) {
      const mapUrl = googleMapsUrlMatch[0];
      return (
        <div style={{ position: "relative" }}>
          <a href={mapUrl} target="_blank" rel="noopener noreferrer">
            <img
              src={mapIcon}
              alt="Location Map Preview"
              style={{ width: "100%", height: "200px", borderRadius: "8px" }}
            />
          </a>
        </div>
      );
    } else if (isLink) {
      return (
        <div className="text-break">
          <a href={msg.chatmsg} target="_blank" rel="noopener noreferrer">
            {msg.chatmsg}
          </a>
        </div>
      );
    } else if (msg.chatmsg) {
      return <div>{formatMessage(msg.chatmsg)}</div>;
    } else if (msg.url) {
      return (
        <div>
          <a href={msg.url} target="_blank" rel="noopener noreferrer">
            <Image
              variant="top"
              src={msg?.url}
              thumbnail
              alt="image"
              style={{ maxHeight: "38rem" }}
            />
          </a>
          {msg.templatemsg && (
            <div className="mt-2 ms-1">{formatMessage(msg.templatemsg)}</div>
          )}
        </div>
      );
    }

    if (msg.templatemsg) {
      return <div>{formatMessage(msg.templatemsg)}</div>;
    }

    const fileUrl = `/uploads/${msg.parentid}/${msg.file_id}.${msg.filetype}`;
    switch (msg.filetype) {
      case "jpeg":
      case "jpg":
      case "png":
      case "webp":
        return (
          <div>
            <a href={fileUrl} target="_blank" rel="noopener noreferrer">
              <img
                src={fileUrl}
                alt={msg.title}
                style={{
                  width: "100%",
                  height: "200px",
                  maxWidth: "100%",
                  maxHeight: "200px",
                }}
              />
            </a>
          </div>
        );
      case "pdf":
        return (
          <div style={{ position: "relative" }}>
            <a href={fileUrl} target="_blank" rel="noopener noreferrer">
              <img
                src={pdfIcon}
                alt="Pdf Preview"
                style={{
                  width: "100%",
                  height: "200px",
                  borderRadius: "8px",
                  backgroundColor: "white",
                }}
              />
            </a>
            <div className="mt-2">
              <a
                className="text-break"
                href={fileUrl}
                target="_blank"
                rel="noopener noreferrer"
              >
                {msg.title}
              </a>
            </div>
          </div>
        );
      case "aac":
      case "mpeg":
      case "mp3":
      case "amr":
      case "ogg":
      case "opus":
        return (
          <div>
            <audio controls>
              <source src={fileUrl} type={`audio/${msg.filetype}`} />
            </audio>
          </div>
        );
      case "ppt":
      case "pptx":
      case "doc":
      case "docx":
        return (
          <div style={{ position: "relative" }}>
            <a href={fileUrl} target="_blank" rel="noopener noreferrer">
              <img
                src={docxIcon}
                alt={`${msg.filetype.toUpperCase()} Preview`}
                style={{
                  width: "100%",
                  height: "200px",
                  borderRadius: "8px",
                  backgroundColor: "white",
                }}
              />
            </a>
            <div className="mt-2">
              <a
                className="text-break"
                href={fileUrl}
                target="_blank"
                rel="noopener noreferrer"
              >
                {msg.title}
              </a>
            </div>
          </div>
        );
      case "xlsx":
      case "xls":
        return (
          <div style={{ position: "relative" }}>
            <a href={fileUrl} target="_blank" rel="noopener noreferrer">
              <img
                src={xlsIcon}
                alt={`${msg.filetype.toUpperCase()} Preview`}
                style={{
                  width: "100%",
                  height: "200px",
                  borderRadius: "8px",
                  backgroundColor: "white",
                }}
              />
            </a>
            <div className="mt-2">
              <a
                className="text-break"
                href={fileUrl}
                target="_blank"
                rel="noopener noreferrer"
              >
                {msg.title}
              </a>
            </div>
          </div>
        );
      case "txt":
        return (
          <div style={{ position: "relative" }}>
            <a href={fileUrl} target="_blank" rel="noopener noreferrer">
              <img
                src={textIcon}
                alt={`${msg.filetype.toUpperCase()} Preview`}
                style={{
                  width: "100%",
                  height: "200px",
                  borderRadius: "8px",
                  backgroundColor: "white",
                }}
              />
            </a>
            <div className="mt-2">
              <a
                className="text-break"
                href={fileUrl}
                target="_blank"
                rel="noopener noreferrer"
              >
                {msg.title}
              </a>
            </div>
          </div>
        );
      case "mp4":
      case "3gpp":
        return (
          <div>
            <video controls style={{ maxWidth: "100%", maxHeight: "200px" }}>
              <source src={fileUrl} type={`video/${msg.filetype}`} />
              Your browser does not support the video element.
            </video>
          </div>
        );
      default:
        return (
          <div
            style={{ marginTop: "10px", display: "flex", alignItems: "center" }}
          >
            <a
              className="text-break"
              href={fileUrl}
              target="_blank"
              rel="noopener noreferrer"
            >
              {msg.title}
            </a>
          </div>
        );
    }
  };
  const renderWebsiteAndCall = (msg) => {
    return (
      <div className="message-actions">
        {msg.temp_website && (
          <a
            href={msg.temp_website}
            target="_blank"
            rel="noopener noreferrer"
            className="message-action"
          >
            <i className="fa fa-external-link  me-2"></i>Website
          </a>
        )}
        {msg.temp_call && (
          <a href={`tel:${msg.temp_call}`} className="message-action">
            <i className="fa fa-phone  me-2"></i>
            Call
          </a>
        )}
        {msg.temp_copy_code && (
          <div
            onClick={() => copyToClipboard(msg.temp_copy_code)}
            className="message-action"
          >
            <i className="fa fa-copy me-2"> </i>
            Copy code
          </div>
        )}
      </div>
    );
  };

  const hasOutgoingMessage = messages?.some(
    (message) => message.status === "Incoming"
  );

  const fillBgBolor = () => {
    colIndex += 1;
    if (colIndex >= bgColors.length) colIndex = 0;
    return bgColors[colIndex];
  };

  return (
    <>
      <Card className="h-100">
        <Card.Header className="p-3 bg-primary">
          <Row className="g-0">
            <Col
              className="text-uppercase"
              lg={7}
              xs={6}
              sm={6}
              style={{ display: "flex", alignItems: "stretch" }}
            >
              {/* {brokenImages.includes(`img-${userDetail?.id}`) ? (
                <NameInitialsAvatar
                  size="30px"
                  textSize="12px"
                  bgColor={fillBgBolor()}
                  borderWidth="0px"
                  textColor="#fff"
                  name={userDetail.contactname || "Unknown"}
                />
              ) : (
                <img
                  alt=""
                  style={{ height: "27px", width: "27px", objectFit: "cover" }}
                  src={`https://api.indicrm.io/images/${userInfo.tenantcode}/users/${userDetail?.id}.thumbnail`}
                  className="rounded-circle"
                  onError={() =>
                    setBrokenImages((prev) => [
                      ...prev,
                      `img-${userDetail?.id}`,
                    ])
                  }
                  id={`img-${userDetail?.id}`}
                /> 
              )}*/}
              {
                <NameInitialsAvatar
                  size="30px"
                  textSize="12px"
                  bgColor={fillBgBolor()}
                  borderWidth="0px"
                  textColor="#fff"
                  name={userDetail.contactname || "Unknown"}
                />
              }
              <span
                className="fw-bold mx-3 mt-1 text-capitalize"
                style={{ fontSize: "15px", color: "white" }}
              >
                {userDetail?.contactname || "Unknown"}{" "}
                <span className="fw-light">{userDetail?.whatsapp_number}</span>
              </span>
            </Col>
            <Col lg={5} xs={3} sm={3} className="text-end">
              <Dropdown>
                <Dropdown.Toggle style={{ border: "none" }} title="Action">
                  <i className="fa-solid fa-ellipsis-vertical"></i>
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item
                    onClick={
                      filterData?.recordType === "groups"
                        ? sendBulkTemplateMessage
                        : sendTemplateMessage
                    }
                  >
                    Send Message
                  </Dropdown.Item>
                  <Dropdown.Item onClick={onCancelButton}>Close</Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </Col>
          </Row>
        </Card.Header>
        {messages?.length > 0 ? (
          <div
            ref={chatWindowRef}
            className="chat-window"
            style={{
              height: "35rem",
              overflowY: "scroll",
              overflowX: "hidden",
              background: "#efe7dd",
            }}
          >
            <Card.Body className="">
              <Row className="g-0">
                <Col lg={12} sm={12} xs={12} className="mb-2">
                  {groupMessagesByDate(messages).map((group, groupIndex) => (
                    <div key={groupIndex}>
                      <div className="text-center m-2">
                        <span className="date-separator">
                          {moment(group.date).format("D MMMM YYYY")}
                        </span>
                      </div>

                      {group.messages.map((msg, index) => {
                        return (
                          <div className="conversation" key={index}>
                            <div className="conversation-container">
                              <div
                                className={`message ${
                                  msg.status === "Incoming"
                                    ? "received"
                                    : "sent"
                                }`}
                              >
                                <div>{renderFilePreview(msg)}</div>
                                {(msg.temp_website ||
                                  msg.temp_call ||
                                  msg.temp_copy_code) && (
                                  <div className="mb-1 mt-3">
                                    {renderWebsiteAndCall(msg)}
                                  </div>
                                )}
                                <span className="metadata">
                                  <span className="time">
                                    {moment(msg.createddate).format("h:mm A")}
                                  </span>
                                </span>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  ))}
                </Col>
              </Row>
            </Card.Body>
          </div>
        ) : (
          <div style={{ height: "100%", background: "#efe7dd" }}>
            <Row className="g-0">
              <Col lg={12} sm={12} xs={12} className="p-4">
                <span style={{ fontSize: "15px" }}>
                  There are no chats available here. Sending your first message
                  is necessary because you haven't chatted before. Send your
                  initial message using the Send button below.
                </span>
              </Col>
              {/* <Col lg={12} sm={12} xs={12} className="text-center p-2">
                {filterData?.recordType !== "groups" ? (
                  <button
                    type="button"
                    className="btn btn-sm btn-light mx-1"
                    onClick={sendTemplateMessage}
                  >
                    Send Message
                  </button>
                ) : (
                  <button
                    type="button"
                    className="btn btn-sm btn-light mx-1"
                    onClick={sendBulkTemplateMessage}
                  >
                    Send Bulk Message
                  </button>
                )}
              </Col> */}
    <Col lg={12} sm={12} xs={12} className="text-center p-2">
                {filterData?.recordType !== "groups" ? (
                  <Button
                    className="btn-light add-new-button"
                    style={{ color: "black" }}
                    variant=""
                    onClick={sendTemplateMessage}
                  >
                    Send Message
                  </Button>
                ) : (
                  <Button
                    className="btn-light add-new-button"
                    style={{ color: "black" }}
                    variant=""
                    onClick={sendBulkTemplateMessage}
                  >
                    Send Bulk Message
                  </Button>
                )}
              </Col>
       
              <Col lg={12} sm={12} xs={12} className="p-4 mt-5 text-center">
                <p>
                  <i
                    className="fa-brands fa-whatsapp"
                    style={{ fontSize: "30px", color: "rgb(119 110 102)" }}
                  ></i>
                </p>
                <p>WhatsApp for Windows</p>
                <p>Send and recieve messages</p>
              </Col>
            </Row>
          </div>
        )}
        {hasOutgoingMessage ? (
          <Card.Footer>
            <Row className="g-0 mt-2">
              <Col lg={11} sm={8} xs={8}>
                <div
                  className="input-focus-class"
                  style={{
                    position: "relative",
                    display: "inline-block",
                    width: "100%",
                  }}
                >
                  <input
                    type="text"
                    placeholder="Type message here..."
                    value={input}
                    onChange={(e) => setInput(e.target.value)}
                    onKeyDown={handleKeyDown}
                    disabled={messages?.length <= 0}
                    style={{
                      width: "100%",
                      padding: "10px 40px 10px 10px",
                      borderRadius: "20px",
                      border: "none",
                    }}
                  />
                  <i
                    className="fa-solid fa-paperclip"
                    style={{
                      position: "absolute",
                      right: "15px",
                      top: "50%",
                      transform: "translateY(-50%)",
                      fontSize: "18px",
                      color: "#999fab",
                      cursor: "pointer",
                    }}
                    onClick={onUploadFile}
                  ></i>
                </div>
              </Col>
              <Col lg={1} xs={4} sm={4} className="text-center">
                <button
                  type="button"
                  className="btn btn-outline-secondary mx-1 mt-1"
                  onClick={handleSubmit}
                >
                  <i className="fa fa-paper-plane"></i>
                </button>
              </Col>
            </Row>
          </Card.Footer>
        ) : (
          <Card.Footer>
            <Row className="g-0 mt-3">
              <Col lg={6} sm={12} xs={12} className="mt-1">
                <p>
                  <b>There are no incoming chats available here.</b>
                </p>
              </Col>
            </Row>
          </Card.Footer>
        )}
      </Card>

      {showHideModal && (
        <TemplateModal
          show={showHideModal}
          onHide={() => setShowHideModal(false)}
          contactData={contactData}
          refreshData={refreshData}
          filterData={filterData}
        />
      )}

      {fileModalShowHide && (
        <SendFileModal
          show={fileModalShowHide}
          onHide={() => setFileModalShowHide(false)}
          refreshImageData={refreshImageData}
          parentData={userDetail}
          filterData={filterData}
        />
      )}

      {showHideBulkModal && (
        <MessageTemplateModal
          show={showHideBulkModal}
          onHide={() => setShowHideBulkModal(false)}
          contactData={groupData}
          refreshData={refreshData}
          filterData={filterData}
        />
      )}
    </>
  );
};

export default WhatsAppChat;
