import React, { useEffect, useState } from "react";
import { Button, Col, Modal, Row, Form } from "react-bootstrap";
import schoolApi from "../../api/schoolApi";
import PubSub from "pubsub-js";
import { ToastContainer, toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import jwt_decode from "jwt-decode";

const CreateRoleModal = (props) => {
  console.log("roleData--======>", props.roleData);

  const [roleData, setRoleData] = useState({
    name: "",
    status: "",
  });

  useEffect(() => {
    if (props.roleData) {
      setRoleData({
        name: props.roleData.name,
        status: props.roleData.status,
      });
    } else {
      setRoleData({
        name: "",
        status: "",
      });
    }
  }, [props.roleData]);

  const handleChange = (e) => {
    setRoleData({ ...roleData, [e.target.name]: e.target.value });
  };
  
  const handleSubmit = async () => {
    if (roleData.name) {
      if (!roleData.status) {
        return toast.error('Missing required fields',{
          className: 'toast-error'
        });
      }
      if (props.roleData) {
        try {
          const result = await schoolApi.updateRole(props.roleData.id, roleData);
          console.log('result-->>' , result);
          if (result.success) {
            toast.success(result.message);
            props.handleCloseModal();
            props.fetchAllRoles();
            setRoleData({
              name: "",
              status: "",
            });
          }else{
            toast.error(result.message,{
              className: 'toast-error'
            });
          }
        } catch (error) {
          toast.error(error);
        }
      } else {
        try {
          const token = sessionStorage.getItem('token');
          const user = jwt_decode(token);

          const roleDataWithCompanyId = {
            ...roleData,
            companyId: user.companyid,
          };

          const result = await schoolApi.createRole(roleDataWithCompanyId);
          if (result.success) {
            toast.success(result.message);
            closeModal_FormField();
            props.fetchAllRoles();
          }else{
            toast.error(result.message);
            closeModal_FormField();
          }
        } catch (error) {
          toast.error(error);
        }
      }
    } else {
      return toast.error('Missing required fields',{
        className: 'toast-error'
      });
    }
  };

  const closeModal_FormField = () => {
    props.handleCloseModal();
    setRoleData({name: "", status: "", });
    // return;
  }
  
  return (
    <Modal
      show={props.modalShow}
      centered
  
      backdrop="static"
      aria-labelledby="contained-modal-title-vcenter"
      onHide={() => {
        props.handleCloseModal();
        setRoleData({
          name: props.roleData.name ? props.roleData.name : "",
          status: props.roleData.status ? props.roleData.status : "",
        });
      }}
    >
      <Modal.Header closeButton style={{maxHeight: "",}}>
        <Modal.Title>
          {props.roleData ? "Update Role" : "Create Role"}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form className="mt-3">
          <Row>
            <Col lg={6}>
              <Form.Group className="mx-3">
                <Form.Label className="form-view-label">Role Name</Form.Label>
                <Form.Select
                  name="name"
                  value={roleData.name}
                  onChange={(e)=>handleChange(e)}
                  required
                >
                  <option value="" disabled>-- Select role --</option>
                  <option value="SYS_ADMIN">SYSTEM ADMIN</option>
                  <option value="ADMIN">ADMIN</option>
                  <option value="ACCOUNTANT">ACCOUNTANT</option>
                  <option value="TEACHER">TEACHER</option>
                </Form.Select>
              </Form.Group>
            </Col>
            {/* //Change this status by shahir hussain */}
            <Col lg={6}>
              <Form.Group className="mx-3">
                <Form.Label  className="form-view-label">Status</Form.Label>
                  <Form.Select
                    name="Status"
                    value={roleData.status}
                    onChange={(e) =>setRoleData({ ...roleData, status: e.target.value })}
                    required
                  >
                    <option value="">--Select status--</option>
                    <option value="Active">Active</option>
                    <option value="Inactive">InActive</option>
                  </Form.Select>
              </Form.Group>
            </Col>
          </Row>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="success" onClick={handleSubmit}>
          {props.btnName}
        </Button>
        <Button
          variant="light"
          onClick={() => {
            props.handleCloseModal();
            setRoleData({
              name: props.roleData.name ? props.roleData.name : "",
              status: props.roleData.status ? props.roleData.status : "",
            });
          }}
        >
          Close
        </Button>

        {/* <ToastContainer
          position="top-center"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="colored"
        /> */}
      </Modal.Footer>
    </Modal>
  );
};

export default CreateRoleModal;