import React from "react";
import Main from "./layout/Main";
import { Card, Col, Row } from "react-bootstrap";
import { Link } from "react-router-dom";

import "react-toastify/dist/ReactToastify.css";


const WarningPage = () => {

  return (
      <>
      <Row className="g-0">
        <Col lg={2} className="mx-4">
          <Link className="nav-link" to="/">
            Home <i className="fa-solid fa-chevron-right"></i>
            <strong> Back</strong>
          </Link>
        </Col>
        <Col lg={12} className="p-lg-5">
            <Card align="center" className="py-3">
                <img src="https://img.freepik.com/premium-vector/gadget-security-with-fingerprint-documents-trending-concept-flat-illustration_720185-545.jpg?w=900" 
                        alt="" 
                        width="550px"
                        height="350px" 
                        style={{"display": "block",
                        "marginLeft": "auto",
                        "marginRight": "auto"}}/>
                <h2 className="text-primary">Sorry, You cannot view this page.</h2>
                <h5 className="text-danger pb-5">No permission to access this page.</h5>
                {/* <h5 className="text-danger pb-5">To access, please contact the administrator.</h5> */}
            </Card>
        </Col>
        <Col lg={2}></Col>
      </Row>
    
    </>
  );
};


export default WarningPage
