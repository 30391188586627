import React, { useState, useEffect } from 'react';
import { Form, Button, Row, Col, Modal } from 'react-bootstrap';
import schoolApi from '../../api/schoolApi';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


const FareMaster = (props) => {
    console.log('props data == ',props)
    const [formData, setFormData] = useState({
        Fare: '',
        UptoDistance: '',
        Status: '',
    });
    console.log('formData data == ',formData)
    const [errors, setErrors] = useState({
        Fare: '',
        UptoDistance: '',
    });

    const [isUpdateMode, setIsUpdateMode] = useState(false);


    useEffect(() => {
        console.log('props == ',props)
        if (props.isUpdateMode && props.fareData) {
            setFormData({
                Fare: props.fareData.fare || '',
                UptoDistance: props.fareData.uptodistance || '',
                Status: props.fareData.status || '',
            });
            setIsUpdateMode(true);
        } else {
            if (props.btnName === 'Create Fare') {
                setFormData({
                    Fare: '',
                    UptoDistance: '',
                    Status: '',
                });
                setIsUpdateMode(false);
            } else if (props.btnName === 'Update Fare') {
                setFormData({
                    Fare: props.fareData.fare || '',
                    UptoDistance: props.fareData.uptodistance || '',
                    Status: props.fareData.status || '',
                });
                setIsUpdateMode(true);
            }
        }
    }, [props.fareData, props.isUpdateMode, props.btnName]);
    console.log('fareData data == ',props.fareData)


    // Validate form fields
    const validateForm = () => {
        console.log('validateForm == ',formData)
        const newErrors = {};
        let isValid = true;

        if (formData.Fare.trim() === '') {
            // toast.error('Please Fill All Required Field');
            // newErrors.Fare = 'Fare is required';
            isValid = false;
        }

        if (formData.Status.trim() === '') {
            // toast.error('Please Fill All Required Field');
            // newErrors.UptoDistance = 'Upto Distance is required';
            isValid = false;
        }

        if (formData.UptoDistance.trim() === '') {
            // toast.error('Please Fill All Required Field');
            // newErrors.UptoDistance = 'Upto Distance is required';
            isValid = false;
        }
        if(isValid === false)
            toast.error("Missing required fields");
            // toast.error('Please Fill All Required Field');
        setErrors(newErrors);
        return isValid;
    };

    // Handle the update case
    const handleUpdate = async () => {
        if (!validateForm()) return;

        try {
            const fareIdToUpdate = props.fareData.id;
            const updatedFareData = { ...formData };
            const updatedFare = await schoolApi.updateFareMaster(fareIdToUpdate, updatedFareData);
            console.log('updatedFare == ',updatedFare)
            if (updatedFare) {
                console.log('coming update',updatedFare.message)
                toast.success(updatedFare.message);
                // setTimeout(()=>{
                    props.handleCloseModal();
                // },1100)
            }
        } catch (error) {
            console.log("error == ",error)
            return toast.error(error.response.data.message)
        }
    };

    // Handle form submission for both create and update
    const handleSubmit = async (e) => {
        e.preventDefault();
        console.log('handle submit == ',formData)
        if (!validateForm()) return;

        try {
            if (isUpdateMode) {
                console.log('isUpdateMode')
                handleUpdate();
            } else {
                const createdFare = await schoolApi.createFaremaster(formData);
                console.log('else 1 == ',createdFare)
                if (createdFare.message) {
                console.log('if')
                    toast.success(createdFare.message);
                    props.onFareCreated();
                }
            }
        } catch (error) {
            console.log("error == ",error)
            return toast.error(error.response.data.message)
        }
    };

    return (
        <div>
            <Modal show={props?.show} centered aria-labelledby="contained-modal-title-vcenter">
                <Modal.Header closeButton onClick={props?.handleCloseModal}>
                    <Modal.Title>
                        {props.title}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {/* <Form onSubmit={handleSubmit}> */}
                        <Row>
                            <Col md={6}>
                                <Form.Group>
                                    <Form.Label>Fare</Form.Label>
                                    <Form.Control
                                        type="number"
                                        name="Fare"
                                        placeholder="Enter Fare"
                                        value={formData.Fare}
                                        onChange={(e) =>
                                            setFormData({ ...formData, Fare: e.target.value })
                                        }
                                        required
                                    />
                                    {/* {errors.Fare && <p style={{ color: 'red' }}>{errors.Fare}</p>} */}
                                </Form.Group>
                            </Col>
                            <Col md={6}>
                                {" "}
                                <Form.Group>
                                    {/* <sup style={{ color: "red", fontSize: '13px' }}>*</sup> */}
                                    <Form.Label>Status</Form.Label>
                                    <div>
                                    <Form.Select
                                        name="Status"
                                        value={formData.Status}
                                        onChange={(e) =>
                                        setFormData({ ...formData, Status: e.target.value })
                                        }
                                        required
                                    >
                                        <option value="">--Select Status--</option>
                                        <option value="Active">Active</option>
                                        <option value="Inactive">InActive</option>
                                    </Form.Select>
                                    </div>
                                </Form.Group>
                                </Col>
                            </Row>
                            <Row className="mt-4">
                                <Col md={6}>
                                {" "}
                                <Form.Group>
                                    <Form.Label>Upto Distance (Km)</Form.Label>
                                    <Form.Control
                                    type="number"
                                    name="UptoDistance"
                                    placeholder="Enter Distance"
                                    value={formData.UptoDistance}
                                    onChange={(e) =>
                                        setFormData({
                                        ...formData,
                                        UptoDistance: e.target.value,
                                        })
                                    }
                                    required
                                    />
                                    {/* {errors.UptoDistance && <p style={{ color: 'red' }}>{errors.UptoDistance}</p>} */}
                                </Form.Group>
                                </Col>
                            </Row>
                        {/* <div className="text-right mt-4">
                            {isUpdateMode ? (
                                <Button variant="primary" onClick={handleUpdate}>
                                    Update
                                </Button>
                            ) : (
                                <Button type="button" variant="primary" onClick={handleSubmit}>
                                    {props.btnName}
                                </Button>
                            )}
                        </div> */}
                    {/* </Form> */}
                </Modal.Body>
                <Modal.Footer>
                <Button variant="secondary" onClick={props?.handleCloseModal}>
                    Close
                </Button>
                <div>
                    {isUpdateMode ? (
                        <Button variant="primary" onClick={handleUpdate}>
                            Update
                        </Button>
                    ) : (
                        <Button type="button" variant="primary" onClick={handleSubmit}>
                            {/* {props.btnName} */}
                            Save
                        </Button>
                    )}
                </div>
                {/* <Button variant="primary" onClick={''}>
                    Save
                </Button> */}
                </Modal.Footer>
            </Modal>
            {/* <ToastContainer position="top-center" autoClose={5000} hideProgressBar /> */}
            <ToastContainer/>

        </div>
    );
};

export default FareMaster;
