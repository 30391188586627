import React, { useState,useEffect  } from "react";
import { Alert, Col, Container, Row } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { useNavigate } from "react-router-dom";
import Card from "react-bootstrap/Card";
import authApi from "../api/authApi";
import home from "../resources/img/home.png";
import login from "../resources/img/login.png";
import { Link } from 'react-router-dom';
import schoolApi from "../api/schoolApi";
import { ToastContainer, toast } from 'react-toastify';


const ForgetPassword = () => {
  const navigate = useNavigate();
  const [credentials, setCredentials] = useState({ email: "",school_code:"" });
  const [show, setShow] = React.useState(false);
  const [getotp, getOtp] = React.useState();
  const [otp, setOtp] = React.useState();
  const [timeRemaining, setTimeRemaining] = useState(60);
  const [flage, setFlage] = React.useState(false);
  const [errorMessage, setErrorMessage] = useState();
  const emailUsername = credentials.email.split('@')[0];
const maskedUsername = emailUsername.length > 2
  ? `${emailUsername[0]}${'*'.repeat(emailUsername.length - 2)}${emailUsername.slice(-1)}`
  : emailUsername;
  const setTimeOut = async (e) => {
    const timerInterval = setInterval(() => {
      setTimeRemaining(prevTime => {
        if (prevTime <= 1) {
          clearInterval(timerInterval);
          setOtp()
           // Stop timer at 0
          return 0;
        }
        return prevTime - 1;
      });
    }, 1000);
    return () => clearInterval(timerInterval);
  }


  const handleSubmit = async (e) => {
    e.preventDefault();
    if(!credentials.email){
      toast.error("Please fill email");
      return;
    }
      console.log('handleSubmit == ', credentials);
      let result;
      if (!flage || timeRemaining === 0) {
        result = await schoolApi.ForgetPasswordApi(credentials);
        console.log("result-->", result);
        if (result.success) {
          setTimeRemaining(60)
          setTimeOut();
          console.log(result)
          toast.success(result.message);
          if (result.otp) {
            setFlage(true)
            getOtp(result.otp)
          }
          else {
            setFlage(false)
          }
        }
        else {
          toast.error(result.message);
        }
      }
      else {
        console.log("result otp == ", getotp)
        console.log("check otp == ", otp)

        if(!otp){
          toast.error("Enter OTP First");
          return;
        }
        if (getotp == otp) {
          // navigate(`/reset-password/${credentials.email}/${credentials.school_code}`);
          navigate(`/reset-password/${credentials.email}`, { state: { school_code: credentials.school_code } });
        }
        else {
          toast.error("Incorrect OTP");
        }
      }
    
  };
  const handleChange = (e) => {
    console.log('value == ', e.target.value)
    if (e.target.name === 'otp') {
      console.log('otp == ', e.target.value)
      const otpValue = e.target.value.slice(0, 6); // Limit input to 6 characters
      setOtp(otpValue);
      // setOtp(...otp,e.target.value)
    }
    else {
      setCredentials({ ...credentials, [e.target.name]: e.target.value });
    }
  };

  //created by shahir hussain 29-10-2024

  return (
    <Container>
      {/* <span className="box1"></span>
      <span className="box2"></span> */}
      <div className="login-form-section">
        <div className="forget-form-content">
          <div className="p-4">
            <Form className="mt-2" onSubmit={handleSubmit}>
              
                <div className="">
                  <h3 className="text-center ">
                    {" "}
                    <img src="indicrm.png" className="mb-2" />
                  </h3>
                  { flage &&
                  <div>
                    <p className="fs-5 mt-4 text-bg-danger">Enter your verification code</p>

                    <p className="fs-5 mt-4">Please enter one-time password to verify your account.</p>
                    <p className="fs-5 ">A code has been sent to {`${maskedUsername}@gmail.com`}</p>
                  </div>
                  }
                </div>
              
              <Alert variant="danger" show={show}>
                {errorMessage}
              </Alert>
              <Form.Group
                className="mb-3 mt-2"
                controlId="formBasicusername"
              >
                {!flage && <Row className="mt-3">
                  <Col lg={12}>
                    <Form.Label>School Code</Form.Label>
                    <Form.Control
                      type="text"
                      name="school_code"
                      onChange={handleChange}
                      placeholder="Enter Your School Code"
                      // value={credentials.school_code}
                    />
                  </Col>
                  <Col lg={12} className="mt-3">
                    <Form.Label>Enter Email</Form.Label>
                    <Form.Control
                      type="email"
                      name="email"
                      onChange={handleChange}
                      placeholder="Enter Your Official Email"
                      // required
                      value={credentials.email}
                    />
                  </Col>
                  
                </Row>
                }
                {flage &&
                  <Row className="mt-3">
                    {/* <Col lg={4}></Col> */}
                    <Col lg={12}>
                      <Form.Label>OTP</Form.Label>
                      <Form.Control
                        type="number"
                        name="otp"
                        onChange={handleChange}
                        placeholder="Enter OTP"
                        // required
                        value={otp}
                      />
                      <div className="mt-2">
                      {timeRemaining !== 0 && <p>Time remaining: {timeRemaining} seconds</p>}
                        {timeRemaining === 0 && <p>OTP expired. Please request a new one.
                          <span className="mx-2" style={{ color: 'blue', cursor: 'pointer',fontWeight:"bold" }} onClick={handleSubmit}>Resend</span>
                        </p>}
                      </div>
                    </Col>
                    {/* <Col lg={4}></Col> */}
                  </Row>
                }

                <Row className="mt-3">
                  <Col lg={12} >
                    <Button
                      className="btn btn-lg w-100 submit"
                      variant="primary"
                      type="submit"
                      disabled={flage && timeRemaining === 0}
                    >
                      {flage ? "Verify" : "Submit"}
                    </Button>
                  </Col>
                </Row>

              </Form.Group>



              <center className="mt-3">
                <strong><Link to="/login" style={{ textDecoration: 'none' }}>back to login</Link></strong>
              </center>
            </Form>

            <center className="indicrm">
              {/* <img src="indicrm.png" /> */}
            </center>
          </div>
        </div>
      </div>
      <ToastContainer />
    </Container>

  );
};

export default ForgetPassword;