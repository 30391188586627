/**
 * @author      Abdul Pathan
 * @date        Sep, 2024
 * @copyright   www.ibirdsservices.com
 */

import React, { useEffect, useState } from "react";
import { Button, Col, Container, Row, Table } from "react-bootstrap";
import {
  DatatableWrapper,
  Filter,
  Pagination,
  PaginationOptions,
  TableBody,
  TableHeader,
} from "react-bs-datatable";
import schoolApi from "../../../api/schoolApi";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import WhatsAppSetingEdit from "./WhatsAppSetingEdit";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";

const WhatsAppSetting = (props) => {
  const [body, setBody] = useState([]);
  const [isSpinner, setIsSpinner] = useState(false);
  const [modalShowHide, setModalShowHide] = useState(false);
  const [rowData, setRowData] = useState();

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const response = await schoolApi.getWhatsAppSettingRecord();

      console.log("WhatsApp Setting Data", response);
      if (response.success) {
        setBody(response.record);
      } else {
        setBody([]);
      }
    } catch (error) {
      console.error("Error fetching campaign data:", error);
      setBody([]);
    } finally {
      setIsSpinner(true);
    }
  };

  const labels = { beforeSelect: " " };
  const header = [
    { title: "Name", prop: "name", isFilterable: true },
    { title: "App ID", prop: "app_id" },
    { title: "Phone number ID", prop: "business_number_id" },
    { title: "Phone", prop: "phone" },
    {
      title: "Actions",
      prop: "id",
      cell: (row) => (
        <>
          <Button className="btn-sm mx-2 " onClick={() => editRecord({ row })}>
            <i class="fa-solid fa-pen-to-square" title="Edit"></i>
          </Button>
        </>
      ),
    },
  ];

  const editRecord = (data) => {
    setModalShowHide(true);
    setRowData(data?.row);
  };

  const createRecord = () => {
    setModalShowHide(true);
    setRowData();
  };

  const onRefreshData = () => {
    setModalShowHide(false);
    fetchData();
  };

  return (
    <>
      <Helmet><title>{props?.tabName}</title></Helmet>
      <Row className="g-0">
        <Col lg={10} className="mx-4">
          <Link className="nav-link" to="/assignsubjectclass">
            Home <i className="fa-solid fa-chevron-right"></i> WhatsApp Settings
          </Link>
        </Col>
      </Row>

      <Container className="mb-5">
        <Row className="mx-3 g-0">
          <Col lg={12} sm={12} xs={12} className="mb-3">
            <Row className="g-0">
              <Col lg={12} sm={12} xs={12}>
                <DatatableWrapper
                  body={body}
                  headers={header}
                  paginationOptionsProps={{
                    initialState: {
                      rowsPerPage: 10,
                      options: [5, 10, 15, 20],
                    },
                  }}
                >
                  <Row className="mb-2">
                    <Col
                      lg={4}
                      sm={10}
                      xs={10}
                      className="d-flex flex-col justify-content-end align-items-end"
                    >
                      <Filter />
                    </Col>
                    <Col
                      lg={4}
                      sm={2}
                      xs={2}
                      className="d-flex flex-col justify-content-start align-items-start"
                    >
                      <PaginationOptions labels={labels} />
                    </Col>

                    <Col
                      lg={4}
                      sm={12}
                      xs={12}
                      className="mt-2 d-flex flex-col justify-content-end align-items-end"
                    >
                      {body?.length <= 0 ? (
                        <>
                          <Button
                            className="btn-light add-new-button"
                            style={{ color: "black" }}
                            variant=""
                            onClick={() => createRecord()}
                          >
                            Add New
                          </Button>
                        </>
                      ) : (
                        ""
                      )}
                    </Col>
                  </Row>
                  <Table striped className="data-table" responsive="sm">
                    <TableHeader />
                    <TableBody />
                  </Table>
                  <Pagination />
                </DatatableWrapper>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>

      <ToastContainer />
      {modalShowHide && (
        <WhatsAppSetingEdit
          show={modalShowHide}
          onHide={() => {
            setModalShowHide(false);
          }}
          onRefreshData={onRefreshData}
          rowData={rowData}
          table="whatsAppSetting"
        />
      )}
    </>
  );
};

export default WhatsAppSetting;
