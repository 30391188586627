import React, { useEffect, useState } from 'react';
import Main from '../layout/Main';
import { Alert, Button, Col, Row, Table } from "react-bootstrap";
import schoolApi from '../../api/schoolApi';
import { ShimmerTable } from 'react-shimmer-effects';
import { DatatableWrapper, Filter, Pagination, PaginationOptions, TableBody, TableHeader, } from "react-bs-datatable";
import { useNavigate, Link } from 'react-router-dom';
import Confirm from '../Confirm';
import FareMaster from './FareMaster';
import InfoPill from '../InfoPill';
import PubSub from "pubsub-js"
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { usePermissions } from "../contexts/PermissionContext"; //Add

import { Helmet } from 'react-helmet';
const FareMasterList = (props) => {
    const navigate = useNavigate();
    const [Fares, setFares] = useState([]);
    const { rolePermissions, userInfo } = usePermissions();
    console.log('rolePermissions-->', rolePermissions);

    console.log("Fares==>>", Fares)
    const [modalShow, setModalShow] = useState(false);
    const [successMessage, setSuccessMessage] = useState(null);
    const [selectedFareId, setSelectedFareId] = useState(null);
    const [isModal, setIsModal] = useState(false);
    const [btnName, setBtnName] = useState('Create Fare');
    const [title, setTitle] = useState('Create Fare');

    const [fareToUpdate, setFareToUpdate] = useState(null);
    const [isUpdateMode, setIsUpdateMode] = useState(false);
    const canCreate = rolePermissions?.Fare_List?.can_create;
    const canEdit = rolePermissions?.Fare_List?.can_edit;
    const canDelete = rolePermissions?.Fare_List?.can_delete;

    const fetchUpdatedFare = async () => {
        try {
            const fareData = await schoolApi.getAllFares();
            console.log("fare == ", fareData)
            if (fareData.success) {
                setFares(fareData.result);
            }
        } catch (error) {
            return toast.error(error.response.data.message)
        }
    };

    useEffect(() => {
        fetchUpdatedFare()
    }, []);


    const handleEditButton = (row) => {
        setFareToUpdate(row); // Set the fare data you want to update
        setIsModal(true); // Open the modal
        setBtnName('Update Fare');
        setTitle('Update Fare');
    };


    const handleDeleteButton = (row) => {
        setSelectedFareId(row.id);
        setModalShow(true);
    };

    const handleDeleteAndNavigate = async () => {
        console.log('handleDeleteAndNavigate')
        if (selectedFareId) {
            try {
                const response = await schoolApi.deleteFare(selectedFareId);
                console.log('response == ', response)
                if (response && response.message === "Fare deleted successfully") {
                    console.log('delete success')

                    const updatedFares = Fares.filter(fare => fare.id !== selectedFareId);
                    setFares(updatedFares);
                    toast.success(response.message);
                    setModalShow(false);
                }
            } catch (error) {
                return toast.error(error.response.data.message)
            }
        }
    };

    const header = [
        { title: "Fare", prop: "fare", isFilterable: true },
        { title: "Up-to-Distance", prop: "uptodistance", isFilterable: true },
        {
            title: "Status",
            prop: "status",
            isFilterable: true,
            cell: (row) => {
              return row.status === "Active" ? "Active" : "InActive";
            },
          },
        {
            title: 'Action', prop: '', isFilterable: true,
            show: userInfo?.userrole === "SYS_ADMIN" || userInfo?.userrole === "ADMIN" ? true : canEdit || canDelete,
            cell: (row) => (
                <div>
                    {(userInfo?.userrole === "SYS_ADMIN" || userInfo?.userrole === "ADMIN" || canEdit) &&
                        <button className="btn btn-sm edit-button mx-1" onClick={() => handleEditButton(row)}>
                            <i className="fa-regular fa-pen-to-square"></i>
                        </button>
                    }
                    {(userInfo?.userrole === "SYS_ADMIN" || userInfo?.userrole === "ADMIN" || canDelete) &&
                        <button className="btn btn-sm btn-danger mx-1" onClick={() => handleDeleteButton(row)}>
                            <i className="fa-regular fa-trash-can"></i>
                        </button>
                    }
                </div>
            )
        },
    ];

    const labels = {
        beforeSelect: " ",
    };

    const handleShowModal = () => {
        setIsUpdateMode(false); // Set isUpdateMode to false
        setBtnName('Create Fare');
        setTitle('Create Fare');
        setIsModal(true);
    };

    const handleCloseModal = () => {
        setIsModal(false)
        fetchUpdatedFare()

    };

    const handleFareCreated = () => {
        // Close the modal and navigate to the farelist
        setIsModal(false);
        fetchUpdatedFare()
        //navigate('/transportation/farelist');
        //window.location.reload();
    };
    const headerStyle = {
        backgroundColor: 'lightblue',
    };

    return (
        <>

            {modalShow && (
                <Confirm
                    show={modalShow}
                    onHide={() => setModalShow(false)}
                    handleDeleteButton={() => handleDeleteAndNavigate()}
                    title="Confirm delete?"
                    message="You are going to delete the record. Are you sure?"
                    table="fareMaster"
                />
            )}
            <Helmet>
                <title>{props?.tabName}</title>
            </Helmet>
            {isModal && (
                <FareMaster
                    show={isModal}
                    handleCloseModal={handleCloseModal}
                    onFareCreated={handleFareCreated}
                    fareData={fareToUpdate} // Pass the fare data to update
                    isUpdateMode={isUpdateMode}
                    btnName={btnName}
                    title={title}
                ></FareMaster>
            )
            }

            {successMessage && (
                <Alert variant="success" onClose={() => setSuccessMessage(null)} dismissible>
                    {successMessage}
                </Alert>
            )}

            <Row className="g-0">
                <Col lg={3} className="mx-3">
                    <Link className="nav-link mx-2" to="/">Home <i className="fa-solid fa-chevron-right"></i> FareList</Link>
                </Col>
                <Col lg={12} className="px-lg-4">
                    {Fares ? (
                        <DatatableWrapper
                            body={Fares}
                            headers={header}
                            paginationOptionsProps={{
                                initialState: {
                                    rowsPerPage: 15,
                                    options: [5, 10, 15, 20],
                                },
                            }}
                        >
                            <Row className="mb-4">
                                <Col
                                    xs={12}
                                    lg={3}
                                    className="d-flex flex-col justify-content-end align-items-end"
                                >
                                    <Filter />
                                </Col>
                                <Col
                                    xs={12}
                                    sm={6}
                                    lg={5}
                                    className="d-flex flex-col justify-content-start align-items-start"
                                >
                                    <PaginationOptions labels={labels} />
                                    <div style={{ "marginTop": "5px" }}>
                                        <InfoPill left="Total Fares" right={Fares?.length} />

                                    </div>

                                </Col>
                                <Col
                                    xs={12}
                                    sm={6}
                                    lg={4}
                                    className="d-flex flex-col justify-content-end align-items-end"
                                >
                                    {(userInfo?.userrole === "SYS_ADMIN" || userInfo?.userrole === "ADMIN" || canCreate) &&
                                        <Button
                                            className="btn-light add-new-button"                //added by muskan khan
                                            tyle={{ color: "black" }}
                                            variant=""
                                            onClick={() => handleShowModal()}
                                        >
                                            New Fare
                                        </Button>
                                    }
                                </Col>
                            </Row>
                            <Table striped className="data-table">
                                <TableHeader headerStyles={headerStyle} />
                                <TableBody />
                            </Table>
                            <Pagination />
                        </DatatableWrapper>
                    ) : (
                        <ShimmerTable row={10} col={8} />
                    )}
                </Col>
                <Col lg={2}></Col>
            </Row>
            <ToastContainer />

        </>
    );
};

export default FareMasterList;

