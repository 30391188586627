import React, { useState, useEffect } from "react"
import Main from '../layout/Main'
import { Helmet } from 'react-helmet'
import { Link, useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { Button, Col, Container, Row } from "react-bootstrap";
import CreateSyllabusModel from "./CreateSyllabusModel";
import Confirm from "../Confirm";
import schoolApi from "../../api/schoolApi";
import { ToastContainer, toast } from "react-toastify";
import { usePermissions } from "../contexts/PermissionContext";
import BackArrow from "../BackArrow";

import {
  BtnBold,
  BtnItalic,
  createButton,
  Editor,
  EditorProvider,
  Toolbar
} from 'react-simple-wysiwyg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faListUl, faListOl, faAlignLeft, faAlignRight, faAlignCenter } from '@fortawesome/free-solid-svg-icons';


const SyllabusViewPage = () => {
  const location = useLocation();
  const { rolePermissions, userInfo } = usePermissions();
  const navigate = useNavigate();
  const [syllabusData, setSyllabusData] = useState(location.state ? location.state : {});
  const [modalShow, setModalShow] = useState(false);
  const [btnName, setBtnName] = useState('Update');
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [rowDataId, setRowDataId] = useState();
  const [body, setBody] = useState();
  const canCreate = rolePermissions?.Syllabus_List?.can_create
  const canEdit = rolePermissions?.Syllabus_List?.can_edit
  const canDelete = rolePermissions?.Syllabus_List?.can_delete

  //Add by Aamir khan
  const BtnAlignCenter = createButton('Align center', <FontAwesomeIcon icon={faAlignCenter} />, 'justifyCenter');
  const BtnAlignLeft = createButton('Align left', <FontAwesomeIcon icon={faAlignLeft} />, 'justifyLeft');
  const BtnAlignRight = createButton('Align right', <FontAwesomeIcon icon={faAlignRight} />, 'justifyRight');
  const BtnUl = createButton('Unordered List', <FontAwesomeIcon icon={faListUl} />, 'insertUnorderedList');
  const BtnLi = createButton('Ordered List', <FontAwesomeIcon icon={faListOl} />, 'insertOrderedList');



  useEffect(() => {
    fetchAllSyllabus();
  }, []);

  async function fetchAllSyllabus() {
    const result = await schoolApi.getAllSyllabus();
    console.log("result======>", result);
    if (result !== "No Data Found") {
      setBody(result);
    } else {
      setBody([]);
    }
  }

  const handleCancel = async (e) => {
    navigate('/syllabuslist');
  }

  const handleEditButton = async (e) => {
    setModalShow(true)
  }

  const handleCloseModal = () => {
    setModalShow(false);
  }

  const handleDeleteButton = () => {
    console.log('record-------->',)
    setShowDeleteModal(true)
    setRowDataId(syllabusData.id)
  }

  const handleDeleteSyllabusRecord = async () => {
    if (rowDataId) {
      try {
        const result = await schoolApi.deleteSyllabus(rowDataId);
        if (result && result.message === "Successfully Deleted") {
          toast.success(result.message, {
            position: toast.POSITION.TOP_CENTER,
            hideProgressBar: true,
          });
          setTimeout(() => {
            navigate('/syllabuslist');
          }, 5000);
          setShowDeleteModal(false)
        } else {
          console.error('deletion was not successfull', result)
        }
      } catch (error) {
        console.error("Errror deleteing ", error);
      }
    }
  }


  return (
    <>

      {showDeleteModal &&
        <Confirm
          show={showDeleteModal}
          onHide={() => setShowDeleteModal(false)}
          handleDeleteButton={() => handleDeleteSyllabusRecord()}
          title="Confirm delete?"
          message="You are going to delete the record. Are you sure?"
          table="deleteSyllabus"
        />}

      <CreateSyllabusModel
        modalShow={modalShow}
        handleCloseModal={handleCloseModal}
        fetchAllSyllabus={fetchAllSyllabus}
        syllabusData={syllabusData}
        btnName={btnName}
      />

      <Col lg={2} className="mx-4">
        <Row>
          <Col lg={10}>
            <Link className="nav-link" to="/">Home <i className="fa-solid fa-chevron-right"></i> syllabusView</Link>
          </Col>
        </Row>
      </Col>
      <div className="my-5">
        <Row className="view-form">
          <Col lg={12}>
            <Row className="view-form-header align-items-center mx-3">
              <Col lg={10}>
                <h5>View Page</h5>
              </Col>
              <Col lg={2}>
                {(userInfo?.userrole === "SYS_ADMIN" || userInfo?.userrole === "ADMIN" || canEdit) &&
                  <Button className="btn-sm mx-2 edit-button" onClick={handleEditButton}>
                    <i className="fa-regular fa-pen-to-square"></i>
                  </Button>
                }
                {(userInfo?.userrole === "SYS_ADMIN" || userInfo?.userrole === "ADMIN" || canDelete) &&
                  <Button
                    className="btn-sm"
                    variant="danger"
                    onClick={handleDeleteButton}
                  >
                    Delete
                  </Button>}
                <Button
                  className="btn-sm mx-2"
                  variant="danger"
                  onClick={handleCancel}
                >
                  Cancel
                </Button>
              </Col>
            </Row>
            <Row className="mx-2 my-2">
              <Col lg={6} className="my-2">
                <label>Class</label>
                <span>
                  {syllabusData.class}
                </span>
              </Col>
              <Col lg={6} className="my-2">
                <label>Section</label>
                <span>{syllabusData.section}</span>
              </Col>
              <Col lg={6} className="my-2">
                <label>Subject</label>
                <span>{syllabusData.subject}</span>
              </Col>
              <Col lg={6} className="my-2">
                <label>Session</label>
                <span>{syllabusData.session}</span>
              </Col>
              <Col lg={12} className="my-2">
                <label>Description</label>
                {/* <span>{syllabusData.description}</span> */}
                <Row>
                  <Col lg={12} className="mt-3">
                    <EditorProvider className="resizable-editor">
                      <Editor name="description"
                        placeholder="Enter description"
                        disabled
                        value={syllabusData.description}
                      >
                        <Toolbar>
                        </Toolbar>
                      </Editor>
                    </EditorProvider>
                  </Col>
                </Row>
              </Col>
              <Col lg={6} className="my-2">
                <label>Status</label>
                <span>{syllabusData.isactive}</span>
              </Col>
              <Col>
              </Col>
            </Row>
            <Row>
            </Row>
          </Col>

          <Col></Col>
        </Row>
      </div>
      <ToastContainer position="top-center" autoClose={5000} hideProgressBar />
    </>
  )
}

export default SyllabusViewPage
