import React, { useEffect, useState } from 'react';
import { Button, Col, Modal, Row, Form } from 'react-bootstrap';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import schoolApi from '../../api/schoolApi';

const CreateSessionModal = (props) => {
  const [sessionData, setSessionData] = useState({
    startdate: "",
    enddate: "",
    year: ""
  });

  useEffect(() => {
    if (props.sessionData) {
      setSessionData({
        startdate: props.sessionData.startdate.split("T")[0],
        enddate: props.sessionData.enddate.split("T")[0],
        year: props.sessionData.year
      });
    } else {
      setSessionData({
        startdate: "",
        enddate: "",
        year: ""
      });
    }
  }, [props.sessionData]);

  useEffect(() => {
    if (sessionData.startdate && sessionData.enddate) {
      const start = sessionData.startdate.split("-");
      const end = sessionData.enddate.split("-");
      const startYear = parseInt(start[0]);
      const endYear = parseInt(end[0]);
      const year = startYear + "-" + endYear;
      setSessionData(prevSessionData => ({
        ...prevSessionData,
        year: year
      }));
    }
  }, [sessionData.startdate, sessionData.enddate]);

  const handleChange = (event) => {
    const { name, value } = event.target;

    if(name === "enddate"){
      let tempDate = new Date(value);
      if(sessionData.startdate > tempDate.toISOString().split("T")[0]){
        setSessionData(prevSessionData => ({
          ...prevSessionData,
          enddate: null,
          year: null,
        }));
        toast.error("End date must be greater than start date");
      }
    } else {
      setSessionData(prevSessionData => ({
        ...prevSessionData,
        [name]: value
      }));
  
      if (name === "startdate") {
        const startDate = new Date(value);
        startDate.setFullYear(startDate.getFullYear() + 1);
        startDate.setDate(startDate.getDate() - 1);
        const endDateString = startDate.toISOString().split("T")[0];
  
        setSessionData(prevSessionData => ({
          ...prevSessionData,
          enddate: endDateString
        }));
      }
    }
  };

  const handleSubmit = async () => {
    if (sessionData.startdate && sessionData.enddate && sessionData.year) {
      try {
        let result;
        if (props.sessionData) {
          result = await schoolApi.updateSession(props.sessionData.id, sessionData);
        } else {
          result = await schoolApi.createSession(sessionData);
        }

        if (result) {
          props.handleCloseModal();
          toast.success(`Session ${props.sessionData ? 'updated' : 'created'} successfully`);
          props.fetchAllSession();
          setSessionData({
            startdate: "",
            enddate: "",
            year: ""
          });
        }
      } catch (error) {
        if (error.response && error.response.status === 409) {
          toast.error("Session with the same year already exists.");
        } else {
          toast.error("An error occurred. Please try again.");
        }
      }
    } else {
      toast.error("Please fill all the required fields.");
    }
  };

  return (
    <Modal
      show={props.modalShow}
      centered
      backdrop="static"
      aria-labelledby="contained-modal-title-vcenter"
      onHide={() => {
        props.handleCloseModal();
        setSessionData({
          startdate: props.sessionData ? props.sessionData.startdate.split("T")[0] : "",
          enddate: props.sessionData ? props.sessionData.enddate.split("T")[0] : "",
          year: props.sessionData ? props.sessionData.year : ""
        });
      }}
    >
      <Modal.Header closeButton>
        <Modal.Title>
          {props.sessionData ? "Update Session" : "Create Session"}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form className="mt-3">
          <Row>
            <Col lg={6}>
              <Form.Group className="mx-3">
                <Form.Label className="form-view-label">Start Date</Form.Label>
                <Form.Control
                  type="date"
                  name="startdate"
                  placeholder="Enter Start Date"
                  value={sessionData.startdate || ""}
                  onChange={handleChange}
                  required
                />
              </Form.Group>
            </Col>
            <Col lg={6}>
              <Form.Group className="mx-3">
                <Form.Label className="form-view-label">End Date</Form.Label>
                <Form.Control
                  type="date"
                  name="enddate"
                  placeholder="Enter End Date"
                  value={sessionData.enddate || ""}
                  onChange={handleChange}
                  required
                />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col lg={6}>
              <Form.Group className="mx-3 mt-3">
                <Form.Label className="form-view-label">Year</Form.Label>
                <Form.Control
                  type="text"
                  name="year"
                  placeholder="Enter your session year"
                  value={sessionData.year || ""}
                  onChange={handleChange}
                  required
                  readOnly
                />
              </Form.Group>
            </Col>
          </Row>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="success" onClick={handleSubmit}>
          {props.btnName}
        </Button>
        <Button
          variant="light"
          onClick={() => {
            props.handleCloseModal();
            setSessionData({
              startdate: props.sessionData ? props.sessionData.startdate.split("T")[0] : "",
              enddate: props.sessionData ? props.sessionData.enddate.split("T")[0] : "",
              year: props.sessionData ? props.sessionData.year : ""
            });
          }}
        >
          Close
        </Button>
      </Modal.Footer>
      {/* <ToastContainer /> */}
    </Modal>
  );
};

export default CreateSessionModal;