import React, { useEffect, useState } from "react";
import { Button, Col, Row, Table } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import {
  DatatableWrapper,
  Filter,
  Pagination,
  PaginationOptions,
  TableBody,
  TableHeader,
} from "react-bs-datatable";
import { ShimmerTable } from "react-shimmer-effects";
import InfoPill from "../InfoPill";
import schoolApi from "../../api/schoolApi";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Confirm from "../Confirm";
import { usePermissions } from "../contexts/PermissionContext";
const FeeMasterList = (props) => {
  const navigate = useNavigate();
  const [body, setBody] = useState();
  const [deleteModal, setDeleteModal] = useState(false);
  const [rowToDelete, setRowToDelete] = useState(null);
  const { rolePermissions, userInfo } = usePermissions();
  const canCreate = rolePermissions?.Fee_Master_List?.can_create;
  const canEdit = rolePermissions?.Fee_Master_List?.can_edit;
  const canDelete = rolePermissions?.Fee_Master_List?.can_delete;

  useEffect(() => {
    async function init() {
      try {
        const result = await schoolApi.fetchFeeMaster();
        console.log("feesHead==>", result);
        if (result.success) {
          setBody(result.result);
        } else {
          setBody([]);
        }
      } catch (error) {
        setBody([]);
        //toast.error(error.response.data.message);
      }
    }
    init();
   

  }, []);
   // Added by muskan khan 29-nov-24
   const formatNumberWithCommas = (number) => {
    return number ? number.toLocaleString("en-IN") : "0";
  };
  

  // Added by mohd. sarfraj | 23-sept-2024.
  const handleUpdateRow = (row) => {
    const updatedRow = { ...row };
    delete updatedRow.isClone;
    return updatedRow;
  }

  const labels = {
    beforeSelect: " ",
  };
  
  // ==================================
  const header = [
    {
      title: "Class",
      prop: "classname",
      show: true,
      isFilterable: true,
    },
    {
      title: "General",
      prop: "total_general_fees",
      cell: (row) =>formatNumberWithCommas(parseInt(row.total_general_fees)), 
      show: true,
      isFilterable: true,
    },
    {
      title: "Obc",
      prop: "total_obc_fees",
      cell: (row) => formatNumberWithCommas(parseInt(row.total_obc_fees)), 
      show: true,
      isFilterable: true,
    },
    {
      title: "Sc",
      prop: "total_sc_fees",
      cell: (row) => formatNumberWithCommas(parseInt(row.total_sc_fees)), 
      show: true,
      isFilterable: true,
    },
    {
      title: "St",
      prop: "total_st_fees",
      cell: (row) => formatNumberWithCommas(parseInt(row.total_st_fees)), 
      show: true,
      isFilterable: true,
    },
    {
      title: "Structure",
      prop: "fee_structure",
      show: true,
      isFilterable: true,
    },
    {
      title: "Status",
      prop: "status",
      show: true,
      isFilterable: true,
    },
    {
      title: "Session",
      prop: "session",
      show: true,
      isFilterable: true,
    },
    {
      title: "Type",
      prop: "type",
      show: true,
      isFilterable: true,
    },
    {
      title: "Action",
      prop: "",
      show:
        userInfo?.userrole === "SYS_ADMIN" || userInfo?.userrole === "ADMIN"
          ? true
          : canEdit || canDelete,
      isFilterable: true,
      cell: (row) => (
        row.isClone = true,
        <div>
          {/*---- Added by mohd. sarfraj | 23-sept-2024 ----*/}
          {(userInfo?.userrole === "SYS_ADMIN" ||
            userInfo?.userrole === "ADMIN" ||
            canEdit) && (
            <Link
              to={"/feesmasteredit/" + row.id}
              state={row}
              className="btn btn-sm btn-light add-new-button edit-button mx-1"
            >
            Clone
            </Link>
          )}
          {(userInfo?.userrole === "SYS_ADMIN" ||
            userInfo?.userrole === "ADMIN" ||
            canEdit) && (
            <Link
              to={"/feesmasteredit/" + row.id}
              state={handleUpdateRow(row)}
              className="btn btn-sm edit-button mx-2"
            >
              <i className="fa-regular fa-pen-to-square"></i>
            </Link>
          )}
          {(userInfo?.userrole === "SYS_ADMIN" ||
            userInfo?.userrole === "ADMIN" ||
            canDelete) && (
            <button
              className="btn btn-sm btn-danger mx-2"
              onClick={() => {
                setDeleteModal(true);
                setRowToDelete(row);
              }}
            >
              <i className="fa fa-trash"></i>
            </button>
          )}
        </div>
      ),
    },
  ];
  const visibleHeader = header?.filter((col) => col.show);

  const deleteFeeMaster = async (row) => {
    console.log("delete method called!!", row.id);
    try {
      const result = await schoolApi.deleteFeeMaster(row.id);
      console.log("deleted result => ", result);

      if (result.success === false) {
        toast.error(result.message);
        return;
      } else if (result.success === true) {
        toast.success("Record deleted successfully");
        setDeleteModal(false);
        setRowToDelete(null);
        const updatedResult = await schoolApi.fetchFeeMaster();
        if(updatedResult.success) setBody(updatedResult.result);
      }
    } catch (error) {
      console.error("Error during deleteFeeMaster:", error);
      toast.error(error.response.data.message);
    }
  };

  const handleFeeMaster = async () => {
    navigate(`/feesmastercreate`);
  };

  return (
    <>
      <Helmet>
        <title>{props?.tabName}</title>{" "}
      </Helmet>
      
      <Row className="g-0">
      <ToastContainer
      position="top-right"
      autoClose={2000}
      hideProgressBar={false}
      newestOnTop={false}
      closeOnClick
      rtl={false}
      pauseOnFocusLoss
      draggable
      pauseOnHover
      theme="light"
      />
        <Col lg={2} className="mx-3">
          <Link className="nav-link mx-2" to="/">
            Home <i className="fa-solid fa-chevron-right"></i> FeesMasterList
          </Link>
        </Col>
        <Col lg={12} className="p-lg-4">
          {body ? (
            <DatatableWrapper
              body={body}
              headers={visibleHeader}
              paginationOptionsProps={{
                initialState: {
                  rowsPerPage: 15,
                  options: [5, 10, 15, 20],
                },
              }}
            >
              <Row className="mb-4">
                <Col lg={3}>
                  <Filter />
                </Col>
                <Col lg={1} style={{ marginTop: "-18px" }}>
                  <PaginationOptions labels={labels} />
                </Col>
                <Col lg={4} style={{ marginTop: "-13px" }}>
                  <div>
                    <InfoPill left="Total Fees Master" right={body?.length} />
                  </div>
                </Col>
                <Col
                  lg={4}
                  style={{ marginTop: "2px" }}
                  className="d-flex flex-col justify-content-end align-items-end"
                >
                  {(userInfo?.userrole === "SYS_ADMIN" ||
                    userInfo?.userrole === "ADMIN" ||
                    canCreate) && (
                    <Button
                    className="btn-light add-new-button"            //added by muskan khan
                    style={{ color: "black" }}  
                      variant="outline-primary"
                      onClick={handleFeeMaster}
                    >
                      New Fees Master
                    </Button>
                  )}
                </Col>
              </Row>
              <Table striped className="data-table fee_master-custom-header">
                <TableHeader />
                <TableBody />
              </Table>
              <Pagination />
            </DatatableWrapper>
          ) : (
            <ShimmerTable row={10} col={4} />
          )}
        </Col>
        <Col lg={2}></Col>
      </Row>
      {deleteModal && rowToDelete && (
        <Confirm
          show={deleteModal}
          onHide={() => setDeleteModal(false)}
          deleteFeeMaster={() => deleteFeeMaster(rowToDelete)}
          title="Confirm delete?"
          message="You are going to delete the record. Are you sure?"
          table="fee_master"
        />
      )}
    </>
  );
};

export default FeeMasterList;
// import React, { useEffect, useState } from "react";
// import { Button, Col, Row, Table } from "react-bootstrap";
// import { useNavigate } from "react-router-dom";

// import { Helmet } from "react-helmet";
// import { Link } from "react-router-dom";

// import {
//   DatatableWrapper,
//   Filter,
//   Pagination,
//   PaginationOptions,
//   TableBody,
//   TableHeader,
// } from "react-bs-datatable";
// import { ShimmerTable } from "react-shimmer-effects";
// import InfoPill from "../InfoPill";
// import schoolApi from "../../api/schoolApi";
// import { ToastContainer, toast } from "react-toastify";
// import "react-toastify/dist/ReactToastify.css";
// import Confirm from "../Confirm";
// import { usePermissions } from "../contexts/PermissionContext";

// const FeeMasterList = (props) => {
//   const navigate = useNavigate();
//   const [body, setBody] = useState();
//   const [deleteModal, setDeleteModal] = useState(false);
//   const [rowToDelete, setRowToDelete] = useState(null);
//   const { rolePermissions, userInfo } = usePermissions();
//   const canCreate = rolePermissions?.Fee_Master_List?.can_create;
//   const canEdit = rolePermissions?.Fee_Master_List?.can_edit;
//   const canDelete = rolePermissions?.Fee_Master_List?.can_delete;

//   useEffect(() => {
//     async function init() {
//       try {
//         const result = await schoolApi.fetchFeeMaster();
//         console.log("feesHead==>", result);
//         if (result.success) {
//           setBody(result.result);
//         } else {
//           setBody([]);
//         }
//       } catch (error) {
//         setBody([]);
//         //toast.error(error.response.data.message);
//       }
//     }
//     init();
//   }, []);

//   // Added by mohd. sarfraj | 23-sept-2024.
//   const handleUpdateRow = (row) => {
//     const updatedRow = { ...row };
//     delete updatedRow.isClone;
//     return updatedRow;
//   }

//   const labels = {
//     beforeSelect: " ",
//   };

//   const header = [
//     {
//       title: "Class",
//       prop: "classname",
//       show: true,
//       isFilterable: true,
//     },
//     {
//       title: "General",
//       prop: "total_general_fees",
//       show: true,
//       isFilterable: true,
//     },
//     {
//       title: "Obc",
//       prop: "total_obc_fees",
//       show: true,
//       isFilterable: true,
//     },
//     {
//       title: "Sc",
//       prop: "total_sc_fees",
//       show: true,
//       isFilterable: true,
//     },
//     {
//       title: "St",
//       prop: "total_st_fees",
//       show: true,
//       isFilterable: true,
//     },
//     {
//       title: "Structure",
//       prop: "fee_structure",
//       show: true,
//       isFilterable: true,
//     },
//     {
//       title: "Status",
//       prop: "status",
//       show: true,
//       isFilterable: true,
//     },
//     {
//       title: "Session",
//       prop: "session",
//       show: true,
//       isFilterable: true,
//     },
//     {
//       title: "Type",
//       prop: "type",
//       show: true,
//       isFilterable: true,
//     },
//     {
//       title: "Action",
//       prop: "",
//       show:
//         userInfo?.userrole === "SYS_ADMIN" || userInfo?.userrole === "ADMIN"
//           ? true
//           : canEdit || canDelete,
//       isFilterable: true,
//       cell: (row) => (
//         row.isClone = true,
//         <div>
//           {/*---- Added by mohd. sarfraj | 23-sept-2024 ----*/}
//           {(userInfo?.userrole === "SYS_ADMIN" ||
//             userInfo?.userrole === "ADMIN" ||
//             canEdit) && (
//             <Link
//               to={"/feesmasteredit/" + row.id}
//               state={row}
//               className="btn btn-sm btn-light add-new-button edit-button mx-1"
//             >
//             Clone
//             </Link>
//           )}
//           {(userInfo?.userrole === "SYS_ADMIN" ||
//             userInfo?.userrole === "ADMIN" ||
//             canEdit) && (
//             <Link
//               to={"/feesmasteredit/" + row.id}
//               state={handleUpdateRow(row)}
//               className="btn btn-sm edit-button mx-2"
//             >
//               <i className="fa-regular fa-pen-to-square"></i>
//             </Link>
//           )}
//           {(userInfo?.userrole === "SYS_ADMIN" ||
//             userInfo?.userrole === "ADMIN" ||
//             canDelete) && (
//             <button
//               className="btn btn-sm btn-danger mx-2"
//               onClick={() => {
//                 setDeleteModal(true);
//                 setRowToDelete(row);
//               }}
//             >
//               <i className="fa fa-trash"></i>
//             </button>
//           )}
//         </div>
//       ),
//     },
//   ];
//   const visibleHeader = header?.filter((col) => col.show);

//   const deleteFeeMaster = async (row) => {
//     console.log("delete method called!!", row.id);
//     try {
//       const result = await schoolApi.deleteFeeMaster(row.id);
//       console.log("deleted result => ", result);

//       if (result.success === false) {
//         toast.error(result.message);
//         return;
//       } else if (result.success === true) {
//         toast.success("Record deleted successfully");
//         setDeleteModal(false);
//         setRowToDelete(null);
//         const updatedResult = await schoolApi.fetchFeeMaster();
//         if(updatedResult.success) setBody(updatedResult.result);
//       }
//     } catch (error) {
//       console.error("Error during deleteFeeMaster:", error);
//       toast.error(error.response.data.message);
//     }
//   };

//   const handleFeeMaster = async () => {
//     navigate(`/feesmastercreate`);
//   };

//   return (
//     <>
//       <Helmet>
//         <title>{props?.tabName}</title>{" "}
//       </Helmet>
      
//       <Row className="g-0">
//       <ToastContainer
//       position="top-right"
//       autoClose={2000}
//       hideProgressBar={false}
//       newestOnTop={false}
//       closeOnClick
//       rtl={false}
//       pauseOnFocusLoss
//       draggable
//       pauseOnHover
//       theme="light"
//       />
//         <Col lg={2} className="mx-3">
//           <Link className="nav-link mx-2" to="/">
//             Home <i className="fa-solid fa-chevron-right"></i> FeesMasterList
//           </Link>
//         </Col>
//         <Col lg={12} className="p-lg-4">
//           {body ? (
//             <DatatableWrapper
//               body={body}
//               headers={visibleHeader}
//               paginationOptionsProps={{
//                 initialState: {
//                   rowsPerPage: 15,
//                   options: [5, 10, 15, 20],
//                 },
//               }}
//             >
//               <Row className="mb-4">
//                 <Col lg={3}>
//                   <Filter />
//                 </Col>
//                 <Col lg={1} style={{ marginTop: "-18px" }}>
//                   <PaginationOptions labels={labels} />
//                 </Col>
//                 <Col lg={4} style={{ marginTop: "-13px" }}>
//                   <div>
//                     <InfoPill left="Total Fees Master" right={body?.length} />
//                   </div>
//                 </Col>
//                 <Col
//                   lg={4}
//                   style={{ marginTop: "2px" }}
//                   className="d-flex flex-col justify-content-end align-items-end"
//                 >
//                   {(userInfo?.userrole === "SYS_ADMIN" ||
//                     userInfo?.userrole === "ADMIN" ||
//                     canCreate) && (
//                     <Button
//                     className="btn-light add-new-button"            //added by muskan khan
//                     style={{ color: "black" }}  
//                       variant="outline-primary"
//                       onClick={handleFeeMaster}
//                     >
//                       New Fees Master
//                     </Button>
//                   )}
//                 </Col>
//               </Row>
//               <Table striped className="data-table fee_master-custom-header">
//                 <TableHeader />
//                 <TableBody />
//               </Table>
//               <Pagination />
//             </DatatableWrapper>
//           ) : (
//             <ShimmerTable row={10} col={4} />
//           )}
//         </Col>
//         <Col lg={2}></Col>
//       </Row>
//       {deleteModal && rowToDelete && (
//         <Confirm
//           show={deleteModal}
//           onHide={() => setDeleteModal(false)}
//           deleteFeeMaster={() => deleteFeeMaster(rowToDelete)}
//           title="Confirm delete?"
//           message="You are going to delete the record. Are you sure?"
//           table="fee_master"
//         />
//       )}
//     </>
//   );
// };

// export default FeeMasterList;
