// **
//  * @author: Pawan Singh Sisodiya
//  */
import React, { useState, useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import "react-bootstrap-typeahead/css/Typeahead.css";
import schoolApi from "../../api/schoolApi";
import { Helmet } from "react-helmet";
import PageNavigations from "../breadcrumbs/PageNavigations";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "react-datepicker/dist/react-datepicker.css";
import "react-toastify/dist/ReactToastify.css";
import $ from "jquery";
import "jquery-ui-dist/jquery-ui.css";
import "jquery-ui-dist/jquery-ui";

const ExamScheduleEdit = (props) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [examinor, setExaminor] = useState();
  const [subject, setSubject] = useState();
  const [classname, setClassname] = useState();
  const [title, setTitle] = useState();
  const [sessionYear, setSessionYear] = useState([]); //Add by Aamir khan 08-05-2024
  const [currentSession, setCurrentSession] = useState();
  const [scheduleRec, setScheduleRec] = useState({});
  const [flage, setFlage] = useState(false);
  const [section, setSection] = useState(); //added by muskan khan 25-nov-24
  const [filteredsection, setFilteredSections] = useState(); //added by muskan khan 25-nov-24
  const [assignsubject, setAssignsubject] = useState([]); //added by muskan khan 25-nov-24
  // const [filterdassignn, setFilteredAssignsubject] = useState();           //added by muskan khan 25-nov-24

  useEffect(() => {
    async function fetchData() {
      try {
        // Fetch related records
        const result = await schoolApi.fetchRelatedRecords();
        console.log("reslt=>", result);
        if (result.success) {
          console.log("related records", result);
          setExaminor(result.relatedrecs[0]);
          setSubject(result.relatedrecs[1]);
          setClassname(result.relatedrecs[2]);
          setTitle(result.relatedrecs[3]);
          // setSection(result.relatedrecs[4]);
        } else {
          console.error("No related records found.");
        }
        console.log("section@@@=>", section);
        // Fetch sessions
        const sessions = await schoolApi.fetchSessions();
        console.log("aw ->", sessions);

        if (sessions) {
          setSessionYear(sessions);

          const currentYear = new Date().getFullYear();
          const nextYear = currentYear + 1;
          setCurrentSession(
            sessions.find((res) => res.year === `${currentYear}-${nextYear}`)
          );
        } else {
          setSessionYear([]);
        }
      } catch (error) {
        console.error("An error occurred while fetching data:", error);
      }
    }

    fetchData();
  }, []);

  useEffect(() => {
    async function init() {
      if (location.state) {
        console.log("DFdg");
        try {
          const result = await schoolApi.fetchExamSchedulesById(
            location.state.id
          );
          console.log("Result by ID:", result);

          if (result.success) {
            setScheduleRec(result.examSchedule);
          } else {
            setScheduleRec([]);
            toast.warn("No data found for the provided ID.");
          }
        } catch (err) {
          console.error("Error fetching exam schedules:", err);
          toast.error(
            "Failed to fetch exam schedules. Please try again later."
          );
        }
      }
    }

    init();
  }, [location.state]);

  /* START  Date work with React J-Query  (Date-Picker)  */
  $(document).ready(() => {
    const formatDateForPicker = (dateString) => {
      if (!dateString) return "";
      const parts = dateString.includes("-") ? dateString.split("-") : [];
      if (parts.length === 3) {
        if (parts[0].length === 4) {
          const [y, m, d] = parts;
          return `${d}/${m}/${y}`;
        } else {
          const [d, m, y] = parts;
          return `${d}/${m}/${y}`;
        }
      }
      return "";
    };

    let date = formatDateForPicker(scheduleRec?.schedule_date) || "";

    $("#schedule_date_id")
      .datepicker({
        dateFormat: "dd/mm/yy",
        changeMonth: true,
        changeYear: true,
        yearRange: "1920:2024",
        // defaultDate: date,
        onSelect: function (dateText) {
          handleDateChange(dateText, "schedule_date");
          $(this).val(dateText);
        },
      })
      .datepicker("setDate", date);
  });

  const handleDateChange = (date, name) => {
    console.log("Selected date: ", date);
    const [day, month, year] = date.split("/");
    const formattedDate = `${year}-${month}-${day}`;

    setScheduleRec((prev) => {
      return { ...prev, [name]: formattedDate };
    });
  };
  /*  END J-Query Date  */

  //Add by Aamir khan 09-05-2024
  const handleChange = (e) => {
    try {
      console.log(
        "e.target.value ==>",
        e.target.value,
        " e.target.name ==> ",
        e.target.name
      );
      // const { name, value } = e.target;
      let { name, value } = e.target;

      if ((name === "max_marks" || name === "min_marks") && isNaN(value)) {
        console.log("come");
        return;
      }

      if (name === "max_marks" || name === "min_marks") {
        if (value.includes(" ")) {
          console.log("come 2");
          return;
        }
      }

      setScheduleRec((prevRecs) => {
        const updatedRecs = {
          ...prevRecs,
          [name]: value,
        };

        if (name === "start_time" || name === "end_time") {
          setFlage(true);
          calculateDuration();
        }

        if (name === "sessionid") {
          const sessionIndex = e.target.selectedIndex - 1;
          if (value !== "" && sessionIndex >= 0 && sessionYear[sessionIndex]) {
            updatedRecs.sessionid = sessionYear[sessionIndex].id;
            updatedRecs.year = value;
          } else {
            updatedRecs.sessionid = "";
            updatedRecs.year = "";
          }
        }

        return updatedRecs;
      });
    } catch (error) {
      console.error("Error fetching exam schedules:", error);
      toast.error("Failed to fetch exam schedules. Please try again later.");
    }
  };

  const calculateDuration = () => {
    const startTime = new Date(`01/01/2000 ${scheduleRec.start_time}`);
    const endTime = new Date(`01/01/2000 ${scheduleRec.end_time}`);
    const diff = endTime - startTime;
    if (!isNaN(diff)) {
      const hours = Math.floor(diff / 1000 / 60 / 60);
      const minutes = Math.floor((diff / 1000 / 60) % 60);
      const totalMinutes = hours * 60 + minutes;

      if (totalMinutes < 0) {
        setScheduleRec((prevRecs) => ({
          ...prevRecs,
          duration: 0,
          status: "Upcoming",
        }));
        if (flage)
          return toast.error("End time must be greater than start time.");
      }

      setScheduleRec((prevRecs) => ({
        ...prevRecs,
        duration: totalMinutes,
        status: "Upcoming",
      }));
    }
  };

  useEffect(() => {
    calculateDuration();
    setFlage(false);
  }, [scheduleRec?.start_time, scheduleRec?.end_time]);

  const handleCheckbox = (e) => {
    const value =
      e.target.type === "checkbox" ? e.target.checked : e.target.value;
    setScheduleRec((prevRecs) => ({
      ...prevRecs,
      [e.target.name]: value === "on" ? true : value === "" ? false : value,
    }));
  };

  console.log("scheduleRec", scheduleRec);
  //updated code by muskan khan
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!scheduleRec.year) {
      scheduleRec.year = currentSession.year;
      scheduleRec.sessionid = currentSession.id;
    }

    console.log("scheduleRec==>", scheduleRec);
    if (
      !scheduleRec.exam_title_id ||
      !scheduleRec.class_id ||
      !scheduleRec.subject_id ||
      !scheduleRec.examinor_id || // Add by Aamir khan
      !scheduleRec.schedule_date ||
      !scheduleRec.start_time ||
      !scheduleRec.end_time ||
      !scheduleRec.max_marks ||
      !scheduleRec.min_marks
    ) {
      toast.error("Fill all required values!!!");
      return;
    }

    //Added By shahir Hussain 28-11-2024
    if (scheduleRec.duration === 0 || scheduleRec.duration < 0) {
      toast.error("Enter valid duration!!!");
      return;
    }

    if (parseInt(scheduleRec.min_marks) >= parseInt(scheduleRec.max_marks)) {
      return toast.error("max marks should be greaterthan min marks");
    }

    if (
      !scheduleRec.start_time ||
      !scheduleRec.end_time ||
      scheduleRec.start_time > scheduleRec.end_time
    ) {
      toast.error("Start time should not be greater than end time!!!");
      return;
    }

    console.log("scheduleRec", scheduleRec);

    //========= Logic to perform Create or Edit ======
    let result2 = {};
    let result1 = {};
    if (scheduleRec.id) {
      result2 = await schoolApi.updateExamSchedule(scheduleRec.id, scheduleRec);
      console.log("SRESULT@@@", result2);
      if (result2.success) {
        toast.success("Record updated succefully");
        setTimeout(() => {
          navigate(`/examschedule`);
        }, 1000);
      } else {
        toast.error(result2.message);
      }
    } else {
      try {
        const result1 = await schoolApi.createExamSchedule(scheduleRec);
        console.log("Result from API@@@:", result1?.data);
        if (result1.data.success) {
          toast.success(result1.data.message);
          setTimeout(() => {
            navigate(
              `/examscheduleview/${result1?.data.examScheduleResult.id}`
            );
          }, 1200);
        } else {
          console.log("API response is empty or invalid.");
          toast.error(result1.data.message);
        }
      } catch (error) {
        toast.error(result1.data.message);
        console.error("Error creating exam schedule:", error);
      }
    }
  };

  const handleCancel = () => {
    if (location?.state?.id) {
      navigate("/examscheduleview/" + location?.state?.id);
    } else {
      navigate("/examschedule");
    }
  };

  console.log("Schedule rec", scheduleRec);
  // Code added by muskan khan 25 oct 2024
  const fetchAssignsubject = async () => {
    try {
      if (scheduleRec?.class_id) {
        console.log("Fetching subjects for class_id:", scheduleRec.class_id);

        const result = await schoolApi.getAllSubByClassId(scheduleRec.class_id);
        console.log(" ", result);
        let fetch = result.records.map((item) => ({
          label: item.subjectname,
          value: item.subject_id,
        }));
        console.log("data@@@", fetch);

        setAssignsubject(fetch);
        console.log("assignmenqwewqewet", fetch);
        console.log("assigsdsdsdfne", assignsubject);
      } else {
        console.warn("class_id is missing in scheduleRec.");
        setAssignsubject([]);
      }
    } catch (error) {
      console.error("Error fetching assigned subjects:", error);
      setAssignsubject([]);
    }
  };

  const fetchSection = async () => {
    try {
      if (scheduleRec.class_id) {
        let sectionList = await schoolApi.getSectionRecordById(
          scheduleRec.class_id
        );
        console.log("sectionList@@+>", sectionList);
        let sectionOptions = sectionList?.record.map(
          (item) => (
            console.log("first", item),
            {
              value: item.id,
              label: item.name,
            }
          )
        );
        console.log("sectionoption", sectionOptions);
        setSection(sectionOptions);
      } else {
        console.log("hearrr");
      }
    } catch (error) {
      console.error("Error fetching assigned subjects:", error);
      setAssignsubject([]);
    }
  };
  useEffect(() => {
    fetchSection();
    fetchAssignsubject();
  }, [scheduleRec.class_id]);

  // ===============================================================================
  return (
    <>
      <>
        {/* added by muskan khan */}
        <ToastContainer />
        <Helmet>
          <title>{props?.tabName}</title>{" "}
        </Helmet>
        <PageNavigations
          id={location.state?.id}
          listName={
            props?.selectedDepartment === "scheduleEdit"
              ? ""
              : "ScheduleNewExam"
          }
          listPath={
            location?.pathname?.split("/")[1] === "/examschedule"
              ? ""
              : "/examschedule"
          }
          viewName="Exam Edit"
          viewPath={"/examscheduleview/" + location.state?.id}
          colLg={10}
          colClassName="d-flex px-3 py-2"
          extrColumn={2}
        />
        <Container className="view-form">
          <Row>
            <Col></Col>
            <Col lg={8}>
              <Form className="mt-3" onSubmit={handleSubmit}>
                <Row className="view-form-header align-items-center">
                  <Col lg={3}>Exam Details</Col>
                  <Col lg={9} className="d-flex justify-content-end">
                    <Button className="btn-sm mx-2" onClick={handleSubmit}>
                      {" "}
                      Save{" "}
                    </Button>
                    <Button
                      className="btn-sm"
                      variant="danger"
                      onClick={handleCancel}
                    >
                      Cancel
                    </Button>
                  </Col>
                </Row>
                <Row>
                  <Col lg={6}>
                    <Form.Group className="mx-3">
                      <Form.Label className="form-view-label">
                        Exam Title
                      </Form.Label>
                      <Form.Select
                        name="exam_title_id"
                        onChange={handleChange}
                        value={scheduleRec.exam_title_id}
                        required
                      >
                        <option value="">--Select Title --</option>
                        {title &&
                          title.map((res) => (
                            <option key={res.title} value={res.id}>
                              {res.title}
                            </option>
                          ))}
                      </Form.Select>
                    </Form.Group>
                  </Col>
                  <Col lg={6}>
                    <Form.Group className="mx-3">
                      <Form.Label className="form-view-label">Class</Form.Label>
                      <Form.Select
                        name="class_id"
                        onChange={handleChange}
                        value={scheduleRec.class_id}
                        required
                      >
                        <option value="">-- Select Class --</option>
                        {console.log("classname==>", classname)}
                        {classname &&
                          classname.map((res) => (
                            <option key={res.classname} value={res.id}>
                              {res.classname}
                            </option>
                          ))}
                      </Form.Select>
                    </Form.Group>
                  </Col>
                  {/* Code Added By muskan khan 25 nov 2024 */}
                  <Col lg={6}>
                    <Form.Group className="mx-3">
                      <Form.Label className="form-view-label">
                        Section
                      </Form.Label>
                      <Form.Select
                        disabled={!scheduleRec.class_id}
                        name="section_id"
                        onChange={handleChange}
                        value={scheduleRec.section_id || ""}
                        l
                        required
                      >
                        <option value="">-- Select Section --</option>
                        {section &&
                          section.map((section) => (
                            <option key={section.id} value={section.value}>
                              {section.label}
                            </option>
                          ))}
                      </Form.Select>
                    </Form.Group>
                  </Col>
                  <Col lg={6}>
                    <Form.Group className="mx-3">
                      <Form.Label className="form-view-label">
                        Subject
                      </Form.Label>
                      <Form.Select
                        disabled={!scheduleRec.class_id}
                        name="subject_id"
                        onChange={handleChange}
                        value={scheduleRec.subject_id || ""}
                        required
                      >
                        <option value="">-- Select Subject --</option>
                        {console.log("assignsubjec", assignsubject)}
                        {assignsubject.map((subject) => (
                          <option key={subject.value} value={subject.value}>
                            {subject.label}
                          </option>
                        ))}
                      </Form.Select>
                    </Form.Group>
                  </Col>

                  <Col lg={6}>
                    <Form.Group className="mx-3">
                      <Form.Label className="form-view-label">
                        Examinor
                      </Form.Label>
                      <Form.Select
                        required
                        name="examinor_id" //change by Aamir khan 09-05-2024
                        onChange={handleChange}
                        value={scheduleRec.examinor_id} //change by Aamir khan 09-05-2024
                      >
                        <option value="">-- Select Examinor --</option>
                        {examinor &&
                          examinor.map((res) => (
                            <option key={res.id} value={res.id}>
                              {res.examinor_name}
                            </option>
                          ))}
                      </Form.Select>
                    </Form.Group>
                  </Col>
                  <Col lg={6}>
                    <Form.Group className="mx-3">
                      <Form.Label
                        className="form-view-label"
                        htmlFor="formBasicLastName"
                      >
                        Date
                      </Form.Label>
                      <Form.Control
                        required
                        type="text"
                        id="schedule_date_id"
                        name="schedule_date"
                        autoComplete="off"
                        placeholder="Select Date"
                        onKeyDown={(e) => e.preventDefault()}
                      />
                    </Form.Group>
                  </Col>
                  <Col lg={6}>
                    <Form.Group className="mx-3">
                      <Form.Label
                        className="form-view-label"
                        htmlFor="formBasicPhone"
                      >
                        Start Time
                      </Form.Label>
                      <Form.Control
                        type="time"
                        name="start_time"
                        placeholder="--Enter Start Time--"
                        value={scheduleRec.start_time}
                        onChange={handleChange}
                        required
                      />
                    </Form.Group>
                  </Col>
                  <Col lg={6}>
                    <Form.Group className="mx-3">
                      <Form.Label
                        className="form-view-label"
                        htmlFor="formBasicEmail"
                      >
                        End Time
                      </Form.Label>
                      <Form.Control
                        type="time"
                        name="end_time"
                        placeholder="Enter End Time"
                        value={scheduleRec.end_time}
                        onChange={handleChange}
                        required
                      />
                    </Form.Group>
                  </Col>
                  <Col lg={6}>
                    <Form.Group className="mx-3">
                      <Form.Label
                        className="form-view-label"
                        htmlFor="formBasicBirthdate"
                      >
                        Room Number
                      </Form.Label>
                      <Form.Control
                        type="text"
                        name="room_no"
                        placeholder="--Enter Room Number--"
                        value={scheduleRec.room_no}
                        onChange={handleChange}
                      />
                    </Form.Group>
                  </Col>

                  <Col lg={6}>
                    <Form.Group className="mx-3">
                      <Form.Label
                        className="form-view-label"
                        htmlFor="formBasicEmail"
                      >
                        Duration
                      </Form.Label>
                      <Form.Control
                        type="text"
                        name="duration"
                        placeholder="--Enter Duration--"
                        value={
                          scheduleRec.duration
                            ? `${scheduleRec.duration} minutes`
                            : ""
                        }
                        onChange={(e) => {
                          const value = e.target.value.replace(" minutes", "");
                          handleChange({ target: { name: "duration", value } });
                        }}
                        // required
                        disabled
                      />
                    </Form.Group>
                  </Col>
                  <Col lg={6}>
                    <Form.Group className="mx-3">
                      <Form.Label className="form-view-label">
                        Status
                      </Form.Label>
                      {scheduleRec.id && (
                        <Form.Select
                          name="status"
                          value={scheduleRec.status}
                          onChange={handleChange}
                          required
                        >
                          <option value="">--Select Status--</option>
                          <option value="Completed">Completed</option>
                          <option value="Pending">Pending</option>
                          <option value="Upcoming">Upcoming</option>
                        </Form.Select>
                      )}
                      {!scheduleRec.id && (
                        <Form.Select
                          name="status"
                          value={scheduleRec.status}
                          onChange={handleChange}
                          // required
                          disabled
                        >
                          <option value="Upcoming">Upcoming</option>
                        </Form.Select>
                      )}
                    </Form.Group>
                  </Col>
                  <Col lg={6}>
                    <Form.Group className="mx-3">
                      <Form.Label
                        className="form-view-label"
                        htmlFor="formBasicQualification"
                      >
                        Max Marks
                      </Form.Label>
                      <Form.Control
                        required
                        // min="0"
                        type="numeric"
                        name="max_marks"
                        placeholder="--Enter Max Marks--"
                        value={scheduleRec.max_marks}
                        onChange={handleChange}
                      />{" "}
                    </Form.Group>
                  </Col>
                  <Col lg={6}>
                    <Form.Group className="mx-3">
                      <Form.Label
                        className="form-view-label"
                        htmlFor="formBasicAdharnumber"
                      >
                        Min Marks
                      </Form.Label>
                      <Form.Control
                        required
                        type="numeric"
                        name="min_marks"
                        placeholder="--Enter Min Marks--"
                        value={scheduleRec.min_marks}
                        onChange={handleChange}
                      />
                    </Form.Group>
                  </Col>
                  {/* Add by Aamir khan 08-05-2024 */}
                  <Col lg={6} className="mt-3">
                    <Form.Group className="mx-3">
                      <Form.Label
                        className="form-view-label"
                        htmlFor="formBasicFirstName"
                      >
                        Session
                      </Form.Label>
                      <Form.Select
                        name="sessionid"
                        // value={scheduleRec.year}
                        value={
                          !scheduleRec.year
                            ? currentSession && currentSession.year
                            : scheduleRec.year && scheduleRec.year
                        }
                        onChange={handleChange}
                      >
                        <option value="">-- Select Session --</option>
                        {sessionYear.map((session) => (
                          <option key={session.id} value={session.year}>
                            {!session.year
                              ? currentSession && currentSession.year
                              : session.year && session.year}
                          </option>
                        ))}
                      </Form.Select>
                    </Form.Group>
                  </Col>
                  <Col lg={5} className="mx-2">
                    <Form.Group className="p-2">
                      <Form.Label
                        className="form-view-label"
                        htmlFor="formBasicStreet"
                        style={{ paddingTop: "10px" }}
                      >
                        Is Practical
                      </Form.Label>
                      <Form.Check
                        type="checkbox"
                        id="formBasicCheckbox"
                        name="ispractical"
                        style={{ fontSize: "18px" }}
                        checked={scheduleRec.ispractical}
                        onChange={handleCheckbox}
                        // required
                      />
                    </Form.Group>
                  </Col>
                </Row>
              </Form>
            </Col>
            <Col></Col>
          </Row>
        </Container>
      </>
    </>
  );
};

export default ExamScheduleEdit;
// // **
// //  * @author: Pawan Singh Sisodiya
// //  */
// import React, { useState, useEffect } from "react";
// import { Col, Container, Row } from "react-bootstrap";
// import Button from "react-bootstrap/Button";
// import Form from "react-bootstrap/Form";
// import { useLocation } from "react-router-dom";
// import { useNavigate } from "react-router-dom";
// import "react-bootstrap-typeahead/css/Typeahead.css";
// import schoolApi from "../../api/schoolApi";
// import { Helmet } from "react-helmet";
// import PageNavigations from "../breadcrumbs/PageNavigations";
// import { ToastContainer, toast } from "react-toastify";
// import "react-toastify/dist/ReactToastify.css";
// import "react-datepicker/dist/react-datepicker.css";
// import "react-toastify/dist/ReactToastify.css";
// import $ from "jquery";
// import "jquery-ui-dist/jquery-ui.css";
// import "jquery-ui-dist/jquery-ui";

// const ExamScheduleEdit = (props) => {
//   const location = useLocation();
//   const navigate = useNavigate();
//   const [examinor, setExaminor] = useState();
//   const [subject, setSubject] = useState();
//   const [classname, setClassname] = useState();
//   const [title, setTitle] = useState();
//   const [sessionYear, setSessionYear] = useState([]); //Add by Aamir khan 08-05-2024
//   const [currentSession, setCurrentSession] = useState();
//   const [scheduleRec, setScheduleRec] = useState({});
//   const [flage, setFlage] = useState(false);
//   const [section, setSection] = useState();           //added by muskan khan 25-nov-24
//   const [filteredsection, setFilteredSections] = useState();           //added by muskan khan 25-nov-24
//   const [assignsubject, setAssignsubject] = useState([]);           //added by muskan khan 25-nov-24
//   // const [filterdassignn, setFilteredAssignsubject] = useState();           //added by muskan khan 25-nov-24

//   useEffect(() => {
//     async function fetchData() {
//       try {
//         // Fetch related records
//         const result = await schoolApi.fetchRelatedRecords();
//         console.log("reslt=>", result)
//         if (result.success) {
//           console.log("related records", result);
//           setExaminor(result.relatedrecs[0]);
//           setSubject(result.relatedrecs[1]);
//           setClassname(result.relatedrecs[2]);
//           setTitle(result.relatedrecs[3]);
//           // setSection(result.relatedrecs[4]);
//         } else {
//           console.error("No related records found.");
//         }
//         console.log("section@@@=>", section)
//         // Fetch sessions
//         const sessions = await schoolApi.fetchSessions();
//         console.log("aw ->", sessions);

//         if (sessions) {
//           setSessionYear(sessions);

//           const currentYear = new Date().getFullYear();
//           const nextYear = currentYear + 1;
//           setCurrentSession(
//             sessions.find((res) => res.year === `${currentYear}-${nextYear}`)
//           );
//         } else {
//           setSessionYear([]);
//         }
//       } catch (error) {
//         console.error("An error occurred while fetching data:", error);
//       }
//     }

//     fetchData();
//   }, []);

//   useEffect(() => {
//     async function init() {
//       if (location.state) {
//         console.log("DFdg")
//         try {
//           const result = await schoolApi.fetchExamSchedulesById(location.state.id);
//           console.log("Result by ID:", result);

//           if (result.success) {
//             setScheduleRec(result.examSchedule);
//           } else {
//             setScheduleRec([]);
//             toast.warn("No data found for the provided ID.");
//           }
//         } catch (err) {
//           console.error("Error fetching exam schedules:", err);
//           toast.error('Failed to fetch exam schedules. Please try again later.');
//         }
//       }
//     }

//     init();
//   }, [location.state]);

//   /* START  Date work with React J-Query  (Date-Picker)  */
//   $(document).ready(() => {
//     const formatDateForPicker = (dateString) => {
//       if (!dateString) return "";
//       const parts = dateString.includes("-") ? dateString.split("-") : [];
//       if (parts.length === 3) {
//         if (parts[0].length === 4) {
//           const [y, m, d] = parts;
//           return `${d}/${m}/${y}`;
//         } else {
//           const [d, m, y] = parts;
//           return `${d}/${m}/${y}`;
//         }
//       }
//       return "";
//     };

//     let date = formatDateForPicker(scheduleRec?.schedule_date) || ""

//     $("#schedule_date_id").datepicker({
//       dateFormat: "dd/mm/yy",
//       changeMonth: true,
//       changeYear: true,
//       yearRange: "1920:2024",
//       // defaultDate: date,
//       onSelect: function (dateText) {
//         handleDateChange(dateText, "schedule_date");
//         $(this).val(dateText);
//       },
//     }).datepicker("setDate", date);
//   });

//   const handleDateChange = (date, name) => {
//     console.log("Selected date: ", date);
//     const [day, month, year] = date.split("/");
//     const formattedDate = `${year}-${month}-${day}`;

//     setScheduleRec((prev) => { return { ...prev, [name]: formattedDate } });
//   };
//   /*  END J-Query Date  */

//   //Add by Aamir khan 09-05-2024
//   const handleChange = (e) => {
//     try {
//       console.log(
//         "e.target.value ==>",
//         e.target.value,
//         " e.target.name ==> ",
//         e.target.name
//       );
//       const { name, value } = e.target;

//       setScheduleRec((prevRecs) => {
//         const updatedRecs = {
//           ...prevRecs,
//           [name]: value,
//         };

//         if (name === "start_time" || name === "end_time") {
//           setFlage(true)
//           calculateDuration();
//         }

//         if (name === "sessionid") {
//           const sessionIndex = e.target.selectedIndex - 1;
//           if (value !== "" && sessionIndex >= 0 && sessionYear[sessionIndex]) {
//             updatedRecs.sessionid = sessionYear[sessionIndex].id;
//             updatedRecs.year = value;
//           } else {
//             updatedRecs.sessionid = "";
//             updatedRecs.year = "";
//           }
//         }

//         return updatedRecs;
//       });

//     } catch (error) {
//       console.error("Error fetching exam schedules:", error);
//       toast.error('Failed to fetch exam schedules. Please try again later.');
//     }

//   };

//   const calculateDuration = () => {
//     const startTime = new Date(`01/01/2000 ${scheduleRec.start_time}`);
//     const endTime = new Date(`01/01/2000 ${scheduleRec.end_time}`);
//     const diff = endTime - startTime;
//     if (!isNaN(diff)) {
//       const hours = Math.floor(diff / 1000 / 60 / 60);
//       const minutes = Math.floor((diff / 1000 / 60) % 60);
//       const totalMinutes = hours * 60 + minutes;

//       if (totalMinutes < 0) {
//         setScheduleRec((prevRecs) => ({
//           ...prevRecs,
//           duration: 0,
//           status: "Upcoming",
//         }));
//         if (flage)
//           return toast.error("End time must be greater than start time.")
//       }

//       setScheduleRec((prevRecs) => ({
//         ...prevRecs,
//         duration: totalMinutes,
//         status: "Upcoming",
//       }));
//     }
//   };

//   useEffect(() => {
//     calculateDuration();
//     setFlage(false)
//   }, [scheduleRec?.start_time, scheduleRec?.end_time]);

//   const handleCheckbox = (e) => {
//     const value =
//       e.target.type === "checkbox" ? e.target.checked : e.target.value;
//     setScheduleRec((prevRecs) => ({
//       ...prevRecs,
//       [e.target.name]: value === "on" ? true : value === "" ? false : value,
//     }));
//   };

//   console.log("scheduleRec", scheduleRec);
//   //updated code by muskan khan
//   const handleSubmit = async (e) => {
//     e.preventDefault();
//     if (!scheduleRec.year) {
//       scheduleRec.year = currentSession.year;
//       scheduleRec.sessionid = currentSession.id;
//     }

//     console.log("scheduleRec==>", scheduleRec);
//     if (
//       !scheduleRec.exam_title_id ||
//       !scheduleRec.class_id ||
//       !scheduleRec.subject_id ||
//       !scheduleRec.examinor_id || // Add by Aamir khan
//       !scheduleRec.schedule_date ||
//       !scheduleRec.start_time ||
//       !scheduleRec.end_time ||
//       !scheduleRec.max_marks || !scheduleRec.min_marks
//     ) {
//       toast.error("Fill all required values!!!");
//       return;
//     }

//     if (scheduleRec.duration === 0 || scheduleRec.duration < 0) {
//       toast.error("Enter valid duration!!!");
//       return;
//     }

//     if (
//       !scheduleRec.start_time ||
//       !scheduleRec.end_time ||
//       scheduleRec.start_time > scheduleRec.end_time
//     ) {
//       toast.error("Start time should not be greater than end time!!!");
//       return;
//     }

//     console.log("scheduleRec", scheduleRec);

//     //========= Logic to perform Create or Edit ======
//     let result2 = {};
//     let result1 = {};
//     if (scheduleRec.id) {
//       result2 = await schoolApi.updateExamSchedule(scheduleRec.id, scheduleRec);
//       console.log("SRESULT@@@", result2);
//       if (result2.success) {
//         toast.success("Record updated succefully");
//         setTimeout(() => {
//           navigate(`/examschedule`);
//         }, 1000);
//       }
//     } else {
//       try {
//         const result1 = await schoolApi.createExamSchedule(scheduleRec);
//         console.log("Result from API@@@:", result1?.data);
//         if (result1) {
//           toast.success("Record created successfully");
//           setTimeout(() => {
//             navigate(`/examscheduleview/${result1?.data.examScheduleResult.id}`);
//           }, 1200);
//         } else {
//           console.log("API response is empty or invalid.");
//         }
//       } catch (error) {
//         console.error("Error creating exam schedule:", error);
//       }
//     }
//   };

//   const handleCancel = () => {
//     if (location?.state?.id) {
//       navigate("/examscheduleview/" + location?.state?.id);
//     } else {
//       navigate("/examschedule");
//     }
//   };

//   console.log("Schedule rec", scheduleRec)
//   // Code added by muskan khan 25 oct 2024
//   const fetchAssignsubject = async () => {
//     try {
//       if (scheduleRec?.class_id) {
//         console.log("Fetching subjects for class_id:", scheduleRec.class_id);

//         const result = await schoolApi.getAllSubByClassId(scheduleRec.class_id);
//         console.log(" ", result);
//         let fetch = result.records.map((item) => ({
//           label: item.subjectname,
//           value: item.subject_id
//         }));
//         console.log("data@@@", fetch);

//         setAssignsubject(fetch);
//         console.log("assignmenqwewqewet", fetch);
//         console.log("assigsdsdsdfne", assignsubject)
//       } else {
//         console.warn("class_id is missing in scheduleRec.");
//         setAssignsubject([]);
//       }
//     } catch (error) {
//       console.error("Error fetching assigned subjects:", error);
//       setAssignsubject([]);
//     }
//   };

//   const fetchSection = async () => {
//     try {
//       if (scheduleRec.class_id) {
//         let sectionList = await schoolApi.getSectionRecordById(scheduleRec.class_id);
//         console.log("sectionList@@+>", sectionList);
//         let sectionOptions = sectionList?.record.map((item) => (
//           console.log("first",item),
//           {

//           value: item.id,
//           label: item.name,
//         }));
//         console.log("sectionoption",sectionOptions)
//         setSection(sectionOptions);
//       } else {
//         console.log("hearrr");
//       }
//     } catch (error) {
//       console.error("Error fetching assigned subjects:", error);
//       setAssignsubject([]);
//     }
//   };
//   useEffect(() => {
//     fetchSection();
//     fetchAssignsubject();
//   }, [scheduleRec.class_id]);

//   // ===============================================================================
//   return (
//     <>
//       <>
//         {/* added by muskan khan */}
//         <ToastContainer
//           position="top-right"
//           autoClose={2000}
//           hideProgressBar={false}
//           newestOnTop={false}
//           closeOnClick
//           rtl={false}
//           pauseOnFocusLoss
//           draggable
//           pauseOnHover
//           theme="light"
//         />
//         <Helmet>
//           <title>{props?.tabName}</title>{" "}
//         </Helmet>
//         <PageNavigations
//           id={location.state?.id}
//           listName={props?.selectedDepartment === "scheduleEdit" ? "" : "ScheduleNewExam"}
//           listPath={
//             location?.pathname?.split("/")[1] === "/examschedule" ? "" : "/examschedule"
//           }
//           viewName="Exam Edit"
//           viewPath={"/examscheduleview/" + location.state?.id}
//           colLg={10}
//           colClassName="d-flex px-3 py-2"
//           extrColumn={2}
//         />
//         <Container className="view-form">
//           <Row>
//             <Col></Col>
//             <Col lg={8}>
//               <Form className="mt-3" onSubmit={handleSubmit}>
//                 <Row className="view-form-header align-items-center">
//                   <Col lg={3}>Exam Details</Col>
//                   <Col lg={9} className="d-flex justify-content-end">
//                     <Button className="btn-sm mx-2" onClick={handleSubmit}> Save </Button>
//                     <Button
//                       className="btn-sm"
//                       variant="danger"
//                       onClick={handleCancel}
//                     >
//                       Cancel
//                     </Button>
//                   </Col>
//                 </Row>
//                 <Row>
//                   <Col lg={6}>
//                     <Form.Group className="mx-3">
//                       <Form.Label className="form-view-label">
//                         Exam Title
//                       </Form.Label>
//                       <Form.Select
//                         name="exam_title_id"
//                         onChange={handleChange}
//                         value={scheduleRec.exam_title_id}
//                         required
//                       >
//                         <option value="">--Select Title --</option>
//                         {title &&
//                           title.map((res) => (
//                             <option key={res.title} value={res.id}>
//                               {res.title}
//                             </option>
//                           ))}
//                       </Form.Select>
//                     </Form.Group>
//                   </Col>
//                   <Col lg={6}>
//                     <Form.Group className="mx-3">
//                       <Form.Label className="form-view-label">Class</Form.Label>
//                       <Form.Select
//                         name="class_id"
//                         onChange={handleChange}
//                         value={scheduleRec.class_id}
//                         required
//                       >
//                         <option value="">-- Select Class --</option>
//                         {console.log("classname==>", classname)}
//                         {classname &&
//                           classname.map((res) => (
//                             <option key={res.classname} value={res.id}>
//                               {res.classname}
//                             </option>
//                           ))}
//                       </Form.Select>
//                     </Form.Group>
//                   </Col>
//                   {/* Code Added By muskan khan 25 nov 2024 */}
//                   <Col lg={6}>

//                     <Form.Group className="mx-3">
//                       <Form.Label className="form-view-label">Section</Form.Label>
//                       <Form.Select
//                         disabled={!scheduleRec.class_id}
//                         name="section_id"
//                         onChange={handleChange}
//                         value={scheduleRec.section_id || ""}l
//                         required
//                       >
//                         <option value="">-- Select Section --</option>
//                         {section &&
//                           section.map((section) => (
//                             <option key={section.id} value={section.value}>
//                               {section.label}
//                             </option>
//                           ))}
//                       </Form.Select>
//                     </Form.Group>
//                   </Col>
//                   <Col lg={6}>
//                     <Form.Group className="mx-3">
//                       <Form.Label className="form-view-label">Subject</Form.Label>
//                       <Form.Select
//                         disabled={!scheduleRec.class_id}
//                         name="subject_id"
//                         onChange={handleChange}
//                         value={scheduleRec.subject_id || ""}
//                         required
//                       >
//                         <option value="">-- Select Subject --</option>
//                         {console.log("assignsubjec", assignsubject)}
//                         {assignsubject.map((subject) => (
//                           <option key={subject.value} value={subject.value}>
//                             {subject.label}
//                           </option>
//                         ))}
//                       </Form.Select>
//                     </Form.Group>
//                   </Col>

//                   <Col lg={6}>
//                     <Form.Group className="mx-3">
//                       <Form.Label className="form-view-label">
//                         Examinor
//                       </Form.Label>
//                       <Form.Select
//                         required
//                         name="examinor_id" //change by Aamir khan 09-05-2024
//                         onChange={handleChange}
//                         value={scheduleRec.examinor_id} //change by Aamir khan 09-05-2024
//                       >
//                         <option value="">-- Select Examinor --</option>
//                         {examinor &&
//                           examinor.map((res) => (
//                             <option key={res.id} value={res.id}>
//                               {res.examinor_name}
//                             </option>
//                           ))}
//                       </Form.Select>
//                     </Form.Group>
//                   </Col>
//                   <Col lg={6}>
//                     <Form.Group className="mx-3">
//                       <Form.Label className="form-view-label" htmlFor="formBasicLastName">Date</Form.Label>
//                       <Form.Control
//                         required
//                         type="text"
//                         id="schedule_date_id"
//                         name="schedule_date"
//                         autoComplete="off"
//                         placeholder="Select Date"
//                         onKeyDown={(e) => e.preventDefault()}
//                       />
//                     </Form.Group>
//                   </Col>
//                   <Col lg={6}>
//                     <Form.Group className="mx-3">
//                       <Form.Label
//                         className="form-view-label"
//                         htmlFor="formBasicPhone"
//                       >
//                         Start Time
//                       </Form.Label>
//                       <Form.Control
//                         type="time"
//                         name="start_time"
//                         placeholder="--Enter Start Time--"
//                         value={scheduleRec.start_time}
//                         onChange={handleChange}
//                         required
//                       />
//                     </Form.Group>
//                   </Col>
//                   <Col lg={6}>
//                     <Form.Group className="mx-3">
//                       <Form.Label
//                         className="form-view-label"
//                         htmlFor="formBasicEmail"
//                       >
//                         End Time
//                       </Form.Label>
//                       <Form.Control
//                         type="time"
//                         name="end_time"
//                         placeholder="Enter End Time"
//                         value={scheduleRec.end_time}
//                         onChange={handleChange}
//                         required
//                       />
//                     </Form.Group>
//                   </Col>
//                   <Col lg={6}>
//                     <Form.Group className="mx-3">
//                       <Form.Label
//                         className="form-view-label"
//                         htmlFor="formBasicBirthdate"
//                       >
//                         Room Number
//                       </Form.Label>
//                       <Form.Control
//                         type="text"
//                         name="room_no"
//                         placeholder="--Enter Room Number--"
//                         value={scheduleRec.room_no}
//                         onChange={handleChange}
//                       />
//                     </Form.Group>
//                   </Col>

//                   <Col lg={6}>
//                     <Form.Group className="mx-3">
//                       <Form.Label
//                         className="form-view-label"
//                         htmlFor="formBasicEmail"
//                       >
//                         Duration
//                       </Form.Label>
//                       <Form.Control
//                         type="text"
//                         name="duration"
//                         placeholder="--Enter Duration--"
//                         value={
//                           scheduleRec.duration
//                             ? `${scheduleRec.duration} minutes`
//                             : ""
//                         }
//                         onChange={(e) => {
//                           const value = e.target.value.replace(" minutes", "");
//                           handleChange({ target: { name: "duration", value } });
//                         }}
//                         // required
//                         disabled
//                       />
//                     </Form.Group>
//                   </Col>
//                   <Col lg={6}>
//                     <Form.Group className="mx-3">
//                       <Form.Label className="form-view-label">
//                         Status
//                       </Form.Label>
//                       {scheduleRec.id && (
//                         <Form.Select
//                           name="status"
//                           value={scheduleRec.status}
//                           onChange={handleChange}
//                           required
//                         >
//                           <option value="">--Select Status--</option>
//                           <option value="Completed">Completed</option>
//                           <option value="Pending">Pending</option>
//                           <option value="Upcoming">Upcoming</option>
//                         </Form.Select>
//                       )}
//                       {!scheduleRec.id && (
//                         <Form.Select
//                           name="status"
//                           value={scheduleRec.status}
//                           onChange={handleChange}
//                           // required
//                           disabled
//                         >
//                           <option value="Upcoming">Upcoming</option>
//                         </Form.Select>
//                       )}
//                     </Form.Group>
//                   </Col>
//                   <Col lg={6}>
//                     <Form.Group className="mx-3">
//                       <Form.Label
//                         className="form-view-label"
//                         htmlFor="formBasicQualification"
//                       >
//                         Max Marks
//                       </Form.Label>
//                       <Form.Control
//                         required
//                         type="number"
//                         name="max_marks"
//                         placeholder="--Enter Max Marks--"
//                         value={scheduleRec.max_marks}
//                         onChange={handleChange}
//                       />{" "}
//                     </Form.Group>
//                   </Col>
//                   <Col lg={6}>
//                     <Form.Group className="mx-3">
//                       <Form.Label
//                         className="form-view-label"
//                         htmlFor="formBasicAdharnumber"
//                       >
//                         Min Marks
//                       </Form.Label>
//                       <Form.Control
//                         required
//                         type="number"
//                         name="min_marks"
//                         placeholder="--Enter Min Marks--"
//                         value={scheduleRec.min_marks}
//                         onChange={handleChange}
//                       />
//                     </Form.Group>
//                   </Col>
//                   {/* Add by Aamir khan 08-05-2024 */}
//                   <Col lg={6} className="mt-3">
//                     <Form.Group className="mx-3">
//                       <Form.Label
//                         className="form-view-label"
//                         htmlFor="formBasicFirstName"
//                       >
//                         Session
//                       </Form.Label>
//                       <Form.Select
//                         name="sessionid"
//                         // value={scheduleRec.year}
//                         value={
//                           !scheduleRec.year
//                             ? currentSession && currentSession.year
//                             : scheduleRec.year && scheduleRec.year
//                         }
//                         onChange={handleChange}
//                       >
//                         <option value="">-- Select Session --</option>
//                         {sessionYear.map((session) => (
//                           <option key={session.id} value={session.year}>
//                             {!session.year
//                               ? currentSession && currentSession.year
//                               : session.year && session.year}
//                           </option>
//                         ))}
//                       </Form.Select>
//                     </Form.Group>
//                   </Col>
//                   <Col lg={5} className="mx-2">
//                     <Form.Group className="p-2">
//                       <Form.Label
//                         className="form-view-label"
//                         htmlFor="formBasicStreet"
//                         style={{ paddingTop: "10px" }}
//                       >
//                         Is Practical
//                       </Form.Label>
//                       <Form.Check
//                         type="checkbox"
//                         id="formBasicCheckbox"
//                         name="ispractical"
//                         style={{ fontSize: "18px" }}
//                         checked={scheduleRec.ispractical}
//                         onChange={handleCheckbox}
//                       // required
//                       />
//                     </Form.Group>
//                   </Col>
//                 </Row>
//               </Form>
//             </Col>
//             <Col></Col>
//           </Row>
//         </Container>
//       </>
//     </>
//   );
// };

// export default ExamScheduleEdit;
