import React, { useEffect, useRef, useState } from 'react';

import { Col, Row, Button, Table } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import schoolApi from '../../api/schoolApi';
import "jspdf-autotable";
import { ToastContainer, toast } from 'react-toastify';
import { ShimmerTable } from "react-shimmer-effects";
import { DatatableWrapper, Pagination, PaginationOptions, TableBody, TableHeader } from 'react-bs-datatable';
import { usePermissions } from "../contexts/PermissionContext";
import moment from 'moment';
import jsPDF from 'jspdf';
import { Helmet } from 'react-helmet';
// import FilterComponent from "./filter_component.jsx";

const StaffReport = (props) => {
    const [addSearchRecShow, setAddSearchRecShow] = useState(false);
    const [colSize, setColSize] = useState({ tableSize: 12, filterSize: 0 });
    let tableRef = useRef(null);
    const labels = {
        beforeSelect: " ",
    };

    const [body, setBody] = useState([]);
    const [contacts, setContacts] = useState([]);
    const { rolePermissions, userInfo } = usePermissions();
    const header = [
        { title: "Employee Name", prop: "staffname", isFilterable: true },
        { title: "Designation", prop: "recordtype", isFilterable: true },
        { title: "Email", prop: "email", isFilterable: true },
        { title: "Mobile", prop: "phone", isFilterable: true },
        {
            title: "Joining Date",
            prop: "createddate",
            isFilterable: true,
            cell: (row) => (
                <span>
                    {moment(row.createddate).format('DD/MM/YYYY')}
                </span>
            ),
        },
        { title: "Gender", prop: "gender", isFilterable: true }
    ];

    useEffect(() => {
        async function init() {
            try {
              const result = await schoolApi.fetchStaffContacts();
              if (result.success) {
                setBody(result.contacts);
                setContacts(result.contacts);
              } else {
                toast.error("Record not found");
                setBody([]);
                setContacts([]);
              }
            } catch (error) {
              console.error("Error during fetchStaffContacts :", error);
              if (error.response.data.message) {
                toast.error(error.response.data.message);
              } else {
                toast.error("Something went wrong");
              }
            }
          }
        init();
    }, []);

    const onDownloadPDF = () => {
        const doc = new jsPDF({
            orientation: "landscape",
            unit: "mm",
            format: "a4",
        });

        doc.setFontSize(12);
        doc.text("Staff Report", 10, 10);

        const tableData = body.map(row => [
            row.salutation ? `${row.salutation} ${row.staffname}` : row.staffname,
            row.department,
            row.gender,
            row.phone,
            row.recordtype,
            moment(row.createddate).format('DD/MM/YYYY')
        ]);

        doc.autoTable({
            head: [['Staff Name', 'Department', 'Gender', 'Mobile', 'Designation', 'Joining Date']],
            body: tableData,
            didDrawCell: (data) => {
                doc.setLineDash([1, 1], 0);
            },
            styles: {
                lineColor: [0, 0, 0],
                lineWidth: 0.1,
            },
            headStyles: {
                fillColor: [10, 40, 108]
            },
            margin: { top: 20 }
        });

        doc.save("staff-report.pdf");
    };

    return (
        <>
            <Helmet>
                <title>{props?.tabName}</title>
            </Helmet>
            <Row className="g-0 mb-5">
                <Col lg={10}>
                    <Link className="mt-3 mx-4 nav-link" to="/" style={{ fontSize: "13px" }}>Home <i className="fa-solid fa-chevron-right"></i> Employee Report</Link>
                </Col>
                <Col lg={2} style={{textAlign:"right"}}>
                <Button className="mx-4" onClick={onDownloadPDF}>
                        Download
                    </Button>
                </Col>
            </Row>
            {body.length ? (
                <DatatableWrapper body={body} headers={header} paginationOptionsProps={{
                    initialState: {
                        rowsPerPage: 15,
                        options: [5, 10, 15, 20]
                    }
                }}>
                    <Row className="mx-3">
                        <Col lg={colSize.tableSize}>
                            <Table striped className="data-table" ref={tableRef}>
                                <TableHeader />
                                <TableBody />
                            </Table>
                        </Col>
                    </Row>
                </DatatableWrapper>
            ) : (
                <ShimmerTable row={10} col={8} />
            )}
            <ToastContainer />
        </>
    )
}

export default StaffReport;