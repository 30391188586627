import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { Alert, Button, Col, Row, Table, Form } from "react-bootstrap";
import schoolApi from "../../api/schoolApi";
import { ShimmerTable } from "react-shimmer-effects";
import { ToastContainer, toast } from "react-toastify";
import {
  DatatableWrapper,
  Filter,
  Pagination,
  PaginationOptions,
  TableBody,
  TableHeader,
} from "react-bs-datatable";
import { useNavigate } from "react-router-dom";
import Confirm from "../Confirm";
import InfoPill from "../InfoPill";
import { 
  BtnBold, 
  BtnItalic, 
  createButton, 
  Editor, 
  EditorProvider, 
  Toolbar
} from 'react-simple-wysiwyg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faListUl, faListOl, faAlignLeft, faAlignRight, faAlignCenter } from '@fortawesome/free-solid-svg-icons';
import "react-toastify/dist/ReactToastify.css";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "react-toastify/dist/ReactToastify.css";
import { Helmet } from "react-helmet";

const Anouncement = (props) => {
  const navigate = useNavigate();
  const [anndetails, setAnnDetails] = useState({
    title: "",
    description: "",
  });
  const [body, setBody] = useState([]);
  const [modalShow, setModalShow] = useState(false);
  const [successMessage, setSuccessMessage] = useState(null);
  const [isModal, setIsModal] = useState(false);
  const [btnName, setBtnName] = useState("Save");
  let [reportData, setReportData] = useState({
    date: new Date(),
  });

  console.log('date-27-nov');

  const fetchUpdatedAnouncement = async () => {
    try {
      const anouncementData = await schoolApi.getAllAnouncement();
      console.log("anouncementData@@@=>", anouncementData);
      if (anouncementData.success) {
        setBody(anouncementData.result);
      }
      else{
        setBody([]);
        toast.error(anouncementData.message);
      }
    } catch (error) {
      console.error("Error fetching anouncement:", error);
      return toast.error(error.response.data.message);
    }
  };

  const BtnAlignCenter =  createButton('Align center',<FontAwesomeIcon icon={faAlignCenter} />, 'justifyCenter');
  const BtnAlignLeft   =  createButton('Align left',<FontAwesomeIcon icon={faAlignLeft} />, 'justifyLeft');
  const BtnAlignRight  =  createButton('Align right',<FontAwesomeIcon icon={faAlignRight}/>, 'justifyRight');
  const BtnUl          =  createButton('Unordered List',<FontAwesomeIcon icon={faListUl} />, 'insertUnorderedList');
  const BtnLi          =  createButton('Ordered List',<FontAwesomeIcon icon={faListOl} />, 'insertOrderedList');

  useEffect(() => {
    fetchUpdatedAnouncement();
  }, []);

  const handleEditButton = (row) => {
    console.log("rowss=>", row);
    if(row.date){
      const [day, month, year] = row.date.split("-");
      const parsedDate = new Date(`${year}-${month}-${day}`);
      if (!isNaN(parsedDate)) {
        reportData.date = parsedDate;
      } else {
        console.error("Invalid date format: ", row.date);
        reportData.date = null; // Handle invalid date accordingly
      }
    }
    else{
      reportData.date = null;
    }
    console.log("prevState == ",reportData)

    setAnnDetails(row); 
    setIsModal(true);
    setBtnName("Update"); 
  };

  const handleDeleteButton = (row) => {
    console.log("delete row == ",row)
    setAnnDetails(row.id);
    console.log(row.id);
    setModalShow(true);
  };

  const handleDeleteAndNavigate = async () => {
    console.log("handleDeleteAndNavigate@@", anndetails);
    if (anndetails) {
      console.log("selectedAnouncementId@@@", anndetails);
      try {
        console.log("trry");
        const response = await schoolApi.deleteAnouncement(anndetails);
        console.log('responsess=>',response)
        toast.success(response.message);
        navigate("/announcements");
        fetchUpdatedAnouncement();
      } catch (error) {
        console.error("Error deleting anouncement:", error);
        return toast.error(error.response.data.message);
      }
    }
    setModalShow(false)
  };

  const header = [
    { title: "Title", prop: "title", isFilterable: true },
    {
      title: "Action",
      prop: "",
      isFilterable: true,
      cell: (row) => (
        <div>
          <button
            className="btn btn-sm edit-button mx-1"
            onClick={() => handleEditButton(row)}
          >
            <i className="fa-regular fa-pen-to-square"></i>
          </button>
          <button
            className="btn btn-sm btn-danger mx-1"
            onClick={() => handleDeleteButton(row)}
          >
            <i className="fa-regular fa-trash-can"></i>
          </button>
        </div>
      ),
    },
  ];

  const labels = {
    beforeSelect: " ",
  };

  const handleShowModal = () => {
    setBtnName("Save"); 
    setAnnDetails({
      title: "",
      // date: "",//comment by shahir hussain 14-11-2024
      description: "",
    })
    setReportData((prevState) => ({
      ...prevState,
      date: "",
    }));
    setIsModal(true);
  };

  const handleCloseModal = () => {
    setIsModal(false);
    fetchUpdatedAnouncement();
  };

  const headerStyle = {
    backgroundColor: "lightblue",
  };

  const handleSave = async (e) => {
    console.log("roport==",reportData)
    console.log("anndetails=>", anndetails);
    if(anndetails.title === '' || anndetails.date === ''){
      console.log("validation")
      toast.error("Missing required fields.");
      return;
    }
    try {
      if (btnName === "Update") {
        const updateAnouncement = await schoolApi.updateAnouncement(anndetails);
        console.log("updateAnouncement@@@=>", updateAnouncement);
        if (updateAnouncement.success === true) {
          toast.success(updateAnouncement.message);
          navigate("/announcements");
          setIsModal(false);
          fetchUpdatedAnouncement();
        }
        else{
          //add this line of code by shahir hussain 14-11-2024
          return toast.error(updateAnouncement.message);
        }
      } else {
        const createdAnouncement = await schoolApi.createAnouncement(anndetails);
        console.log("createdAnouncement@@@=>", createdAnouncement);
        if (createdAnouncement.success === true) {
          setIsModal(false);
          toast.success(createdAnouncement.message);
          fetchUpdatedAnouncement();
          /*------  Added by sarfraj | 07-Nov-2024  --------*/
          const sentNotificationToUsers = await schoolApi.sentNotification(createdAnouncement.record)
          console.log('sentNotificationToUsers-->>>' , sentNotificationToUsers);
          toast.success(sentNotificationToUsers.message);
          /*  END ./  */

          // navigate("/announcements");
        }
        else{
          //add this line of code by shahir hussain 14-11-2024
          return toast.error(createdAnouncement.message);
        }
      }
      setAnnDetails({
        title: "",
        description: "",
      })
    } catch (error) {
      console.log("error=>", error.response.data.message);
      return toast.error(error.response.data.message);

    }
  };

  const handleDateChange = (date, name) => {
    console.log("date == ",date)
    if (date) {
      const day = String(date.getDate()).padStart(2, "0"); // Get day and pad with zero if needed
      const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-based in JavaScript, so add 1
      const year = date.getFullYear(); // Get full year
      
        const dateFormat = `${year}-${month}-${day}`;
        console.log("dateFormat == ", dateFormat);
        setReportData((prevState) => ({
          ...prevState,
          [name]: date,
        }));
        setAnnDetails({ ...anndetails, [name]: dateFormat });
    }
    else{
      setReportData((prevState) => ({
        ...prevState,
        [name]: '',
      }));
      setAnnDetails({ ...anndetails, [name]: '' });
    }
  };

  return (
    <>

<Helmet>
        <title>{props?.tabName}</title>
      </Helmet>

      {modalShow && (
        <Confirm
          show={modalShow}
          onHide={() => setModalShow(false)}
          handleDeleteButton={() => handleDeleteAndNavigate()}
          title="Confirm delete?"
          message="You are going to delete the record. Are you sure?"
          table="announcement"
        />
      )}

      {successMessage && (
        <Alert
          variant="success"
          onClose={() => setSuccessMessage(null)}
          dismissible
        >
          {successMessage}
        </Alert>
      )}
      <Row className="g-0">
        <Col lg={12} className="px-lg-4">
          {body ? (
            <DatatableWrapper
              body={body}
              headers={header}
              paginationOptionsProps={{
                initialState: {
                  rowsPerPage: 15,
                  options: [5, 10, 15, 20],
                },
              }}
            >
              <Row className="mb-4">
                <Col
                  xs={12}
                  lg={3}
                  className="d-flex flex-col justify-content-end align-items-end"
                >
                  <Filter />
                </Col>
                <Col
                  xs={12}
                  sm={6}
                  lg={5}
                  className="d-flex flex-col justify-content-start align-items-start"
                >
                  <PaginationOptions labels={labels} />
                  <div style={{ marginTop: "5px" }}>
                    <InfoPill
                      left="Total Anouncements"
                      right={body?.length}
                    />
                  </div>
                </Col>
                <Col
                  xs={12}
                  sm={6}
                  lg={4}
                  className="d-flex flex-col justify-content-end align-items-end"
                >
                  <Button
                        className="btn-light add-new-button"
                      variant=""
                    onClick={() => handleShowModal()}
                  >
                    New Anouncement
                  </Button>
                </Col>
              </Row>
              <Table striped className="data-table">
                <TableHeader headerStyles={headerStyle} />
                <TableBody />
              </Table>
              <Pagination />
            </DatatableWrapper>
          ) : (
            <ShimmerTable row={10} col={8} />
          )}
        </Col>
        <Modal
          show={isModal}
          centered
          aria-labelledby="contained-modal-title-vcenter"
        >
          <Modal.Header closeButton onClick={handleCloseModal}>
            <Modal.Title>Announcement</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {successMessage && (
              <div className="alert alert-success">{successMessage}</div>
            )}
            <Row>
              <Col md={6}>
                <Form.Group>
                  <Form.Label>Title</Form.Label>
                  <Form.Control
                    type="text"
                    name="title"
                    placeholder="Enter Title"
                    value={anndetails.title}
                    onChange={(e) =>
                      setAnnDetails({
                        ...anndetails,
                        title: e.target.value,
                      })
                    }
                    required
                  />
                </Form.Group>
              </Col>
              {/* <Col md={6}>
                <Form.Group>
                  <Form.Label className="form-view-label">Date</Form.Label> */}
                  {/* <DatePicker
                    required
                    selected={reportData.date}
                    onChange={(date) => handleDateChange(date, "date")}
                    showMonthDropdown
                    showYearDropdown
                    dateFormat="dd-MM-yyyy"
                    className="form-control"
                    placeholderText="Enter Date"
                    style={{ width: "300px" }}
                    onKeyDown={(e) => e.preventDefault()}
                    highlightDates={[new Date()]}
                  /> */}
                  {/* <DatePicker
                    required
                    selected={reportData.date}
                    onChange={(date) => handleDateChange(date, "date")}
                    dateFormat="dd-MM-yyyy"
                    showMonthDropdown
                    showYearDropdown
                    dropdownMode="select"
                    placeholderText="Select a date"
                    className="form-control"
                    style={{ width: "300px" }}
                    onKeyDown={(e) => e.preventDefault()}
                    highlightDates={[new Date()]}
                  /> */}
                {/* </Form.Group>
              </Col> */}
            </Row>
            <Row>
              <Col lg={12} className="mt-3">
              <EditorProvider  className="resizable-editor">
                <Editor name="description"
                placeholder="Enter description"
                onChange={(e) =>
                  setAnnDetails({
                    ...anndetails,
                    description: e.target.value,
                  })}
                //  onChange={handleChange}
                  value={anndetails.description} 
                  >
                  <Toolbar>
                    <BtnBold />
                    <BtnItalic />
                    <BtnAlignLeft />
                    <BtnAlignCenter />
                    <BtnAlignRight />
                    <BtnLi/>
                    <BtnUl/>
                  </Toolbar>
                </Editor>
              </EditorProvider>
              </Col>
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <div className="text-right mt-4">
              <Button variant="light" onClick={handleCloseModal}>Close</Button>
            </div>
            <div className="text-right mt-4">
              <Button type="submit" variant="primary" onClick={handleSave}>
                {btnName}
              </Button>
            </div>
          </Modal.Footer>
        </Modal>
        <ToastContainer
          position="top-right"
          autoClose={2000}
          hideProgressBar={false}
        />
        <Col lg={2}></Col>
      </Row>
    </>
  );
};

export default Anouncement;