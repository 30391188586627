import React, { useState, useEffect } from "react";
import { Col, Container, Form, Row, Modal, Button } from "react-bootstrap";
import schoolApi from "../../api/schoolApi";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { parseISO } from "date-fns";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "react-toastify/dist/ReactToastify.css";
const AddBook = (props) => {
  const [rowRecord, setRowRecord] = useState({
    title: "",
    author_id: "",
    isbn: "",
    category_id: "",
    publisher_id: "",
    publish_date: "",
    language_id: "",
    status: "",
  });
  const [authors, setAuthors] = useState([]);
  const [categories, setCategories] = useState([]);
  const [publishers, setPublishers] = useState([]);
  const [languages, setLanguages] = useState([]);
  
  // code added by muskan khan
  const [reportData, setReportData] = useState({
    classid: "",
    session_id: "",
    publish_date: null,
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        const authorsResult = await schoolApi.getAuthorsRecords();
        console.log('authors--->>>', authorsResult);

        //---- Update by mohd. sarfraj | 17-sept-2024. ----
        if (authorsResult?.success) {
          const availableAuthors = authorsResult?.authors?.filter(
            (author) => author.status === "Active"
          );
          setAuthors(availableAuthors);
        } else setAuthors([]);

        const categoriesResult = await schoolApi.getCategoryRecords();
        //---- Update by mohd. sarfraj | 17-sept-2024. ----
        if (categoriesResult?.success) {
          setCategories(categoriesResult?.category);
        } else setCategories([]);

        const publishersResult = await schoolApi.getPublishersRecords();
        //---- Update by mohd. sarfraj | 17-sept-2024. ----
        if (publishersResult?.success) {
          const availablePublishers = publishersResult?.publishers?.filter(
            (publisher) => publisher.status === "Available"
          );
          setPublishers(availablePublishers);
        } else setPublishers([]);

        //---- Update by mohd. sarfraj | 17-sept-2024. ----
        const languagesResult = await schoolApi.getLanguagesRecords();
        if (languagesResult.success) setLanguages(languagesResult.language);
        else setLanguages([]);
      } catch (error) {
        toast.error("Failed to fetch initial data!");
      }
    };
    fetchData();
  }, []);
  

  useEffect(() => {
    if (props?.parent?.id) {
      setRowRecord(props?.parent);
      console.log('hi-mohd.you-are-here--->>>>' , props?.parent);

      // set date while update record ! by mohd. sarfraj
      setReportData((prevState) => ({
        ...prevState,
        publish_date: parseISO(props?.parent?.publish_date),
      }));

    }
  }, [props?.parent?.id]);

  /* =====code added by muskan khan 6 sept 2024 friaday =====*/
  const handleDateChange = (date, name) => {
    if (date) {
      console.log("date = ", date);
      console.log("name = ", name);

      const day = String(date.getDate()).padStart(2, "0");
      const month = String(date.getMonth() + 1).padStart(2, "0");
      const year = date.getFullYear();
      console.log("day = ", day);
      console.log("month = ", month);
      console.log("year = ", year);

      const dateFormat = `${year}-${month}-${day}`;
      console.log("dateFormat == ", dateFormat);
      setReportData((prevState) => ({
        ...prevState,
        [name]: date,
      }));

      setRowRecord({ ...rowRecord, [name]: dateFormat });
    }
  };
  

  const handleChange = (e) => {
    setRowRecord({ ...rowRecord, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const getAllBooks = await schoolApi.getBooksRecords();
    console.log('getAllBooks-->>', getAllBooks);

    if (props?.parent?.id) {
      const editRecord = {
        id: props?.parent?.id,
        title: rowRecord.title,
        author_id: rowRecord.author_id,
        isbn: rowRecord.isbn,
        category_id: rowRecord.category_id,
        publisher_id: rowRecord.publisher_id,
        publish_date: rowRecord?.publish_date,
        language_id: rowRecord.language_id,
        status: rowRecord.status,
      };

      if (
        editRecord.title &&
        editRecord.title.trim() !== "" &&
        editRecord.author_id &&
        editRecord.author_id.trim() !== "" &&
        editRecord.status &&
        editRecord.status.trim() !== "" &&
        /* added by mohd. sarfraj | 02-sept.2024 */
        editRecord.publisher_id &&
        editRecord.publisher_id.trim() !== "" &&
        editRecord.category_id &&
        editRecord.category_id.trim() !== "" &&
        editRecord.language_id &&
        editRecord.language_id.trim() !== ""
      ) {
        const existingBook = getAllBooks?.books?.find(
          (book) => book.title === editRecord.title && book.id !== editRecord.id
        );
        if (existingBook) {
          toast.error("Book with this title already exists!");
        } else {
          let response = await schoolApi.updateBookRecord(editRecord);
          console.log('response-->>', response);
          if (response.success) {
            toast.success(response.message);
            recordSaveSuccesfully();
          } else {
            toast.error(response.message);
          }
        }
      } else {
        toast.error("Required field missing!");
      }
    } else {
      if (props?.category) rowRecord["category_id"] = props.category;
      if (props?.author) rowRecord["author_id"] = props.author;
      if (props?.publisher) rowRecord["publisher_id"] = props.publisher;
      if (props?.language)
        rowRecord["language_id"] = props.language; /* Add by Abhishek  */

      if (rowRecord.publish_date === "") {
        rowRecord["publish_date"] = null;
      }
      if (
        rowRecord.title &&
        rowRecord.title.trim() !== "" &&
        rowRecord.author_id &&
        rowRecord.author_id.trim() !== "" &&
        rowRecord.category_id &&
        rowRecord.category_id.trim() !== "" &&
        rowRecord.publisher_id &&
        rowRecord.publisher_id.trim() !== "" &&
        rowRecord.language_id &&
        rowRecord.language_id.trim() !== "" &&
        rowRecord.status &&
        rowRecord.status.trim() !== ""
      ) {
        const existingBook = getAllBooks?.books?.find(
          (book) => book.title === rowRecord.title && book.id !== rowRecord.id
        );
        if (existingBook) {
          toast.error("Book with this title already exists!");
        } else {
          let response = await schoolApi.addBookRecord(rowRecord);
          console.log('response-->>', response);
          if (response?.success) {
            toast.success("Record saved successfully!");
            recordSaveSuccesfully();
          } else {
            toast.error(response.message);
          }
        }
      } else {
        toast.error("Required field missing");
      }
    }
  };

  const recordSaveSuccesfully = () => {
    props.recordSaveSuccesfully();
  };

  return (
    <Modal
      show={props.show}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      size="lg"
      onHide={props.onHide}
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Book Record
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Container className="view-form">
          <Row>
            <Col lg={12}>
              <Form noValidate>
                <Row className="pb-4">
                  <Col lg={6}>
                    <Form.Group>
                      <Form.Label
                        className="form-view-label"
                        htmlFor="formBasicFirstName"
                      >
                        Book Name
                      </Form.Label>
                      <Form.Control
                        required
                        type="text"
                        name="title"
                        placeholder="--Book Name--"
                        value={rowRecord.title}
                        onChange={handleChange}
                      />
                    </Form.Group>
                  </Col>
                  <Col lg={6}>
                    <Form.Group>
                      <Form.Label>Author Name</Form.Label>
                      <Form.Select
                        name="author_id"
                        value={
                          props?.author ? props.author : rowRecord.author_id
                        }
                        onChange={handleChange}
                        required
                        disabled={props.author}
                      >
                        <option value="">--Select an Author--</option>
                        {authors?.map((author) => (
                          <option key={author.id} value={author.id}>
                            {author.name}
                          </option>
                        ))}
                      </Form.Select>
                    </Form.Group>
                  </Col>
                  <Col lg={6}>
                    <Form.Group>
                      <Form.Label
                        className="form-view-label"
                        htmlFor="formBasicFirstName"
                      >
                        Book Number
                      </Form.Label>
                      <Form.Control
                        type="text"
                        name="isbn"
                        placeholder="--Enter Book Number--"
                        value={rowRecord.isbn}
                        onChange={handleChange}
                      />
                    </Form.Group>
                  </Col>
                  <Col lg={6}>
                    <Form.Group>
                      <Form.Label>Category</Form.Label>
                      <Form.Select
                        name="category_id"
                        value={
                          props?.category
                            ? props.category
                            : rowRecord.category_id
                        }
                        onChange={handleChange}
                        required
                        disabled={props.category}
                      >
                        <option value="">--Select a Category--</option>
                        {categories?.map((category) => (
                          <option key={category?.id} value={category.id}>
                            {category.name}
                          </option>
                        ))}
                      </Form.Select>
                    </Form.Group>
                  </Col>
                  <Col lg={6}>
                    <Form.Group>
                      <Form.Label>Publisher</Form.Label>
                      <Form.Select
                        name="publisher_id"
                        value={
                          props?.publisher
                            ? props.publisher
                            : rowRecord.publisher_id
                        }
                        onChange={handleChange}
                        required
                        disabled={props.publisher}
                      >
                        <option value="">--Select a Publisher--</option>
                        {publishers?.map((publisher) => (
                          <option key={publisher?.id} value={publisher.id}>
                            {publisher.name}
                          </option>
                        ))}
                      </Form.Select>
                    </Form.Group>
                  </Col>
                  <Col lg={6}>
                    <Form.Group>
                      <Form.Label className="form-view-label">Publish Date</Form.Label>
                      <DatePicker
                        selected={reportData.publish_date}
                        onChange={(date) => handleDateChange(date, "publish_date")}
                        dateFormat="dd-MM-yyyy"
                        showMonthDropdown
                        showYearDropdown
                        dropdownMode="select"
                        placeholderText="Select a date"
                        className="form-control"
                        style={{ width: "300px" }}
                        onKeyDown={(e) => e.preventDefault()}
                        highlightDates={[new Date()]}
                      />
                    </Form.Group>
                  </Col>
                  <Col lg={6}>
                    <Form.Group>
                      <Form.Label>Language</Form.Label>
                      <Form.Select
                        name="language_id"
                        onChange={handleChange}
                        required
                        value={
                          props?.language
                            ? props.language
                            : rowRecord.language_id
                        } /* Add by Abhishek  */
                        disabled={props.language} /* Add by Abhishek  */
                      >
                        <option value="">--Select a Language--</option>
                        {languages?.map((language) => (
                          <option key={language?.id} value={language.id}>
                            {language.name}
                          </option>
                        ))}
                      </Form.Select>
                    </Form.Group>
                  </Col>
                  <Col lg={6}>
                    <Form.Group>
                      <Form.Label
                        className="form-view-label"
                        htmlFor="formBasicFirstName"
                      >
                        Status
                      </Form.Label>
                      <Form.Select
                        required
                        name="status"
                        value={rowRecord.status}
                        onChange={handleChange}
                      >
                        <option value="">None</option>
                        <option value="Active">Active</option>
                        <option value="In Active">In Active</option>
                      </Form.Select>
                    </Form.Group>
                  </Col>
                </Row>
              </Form>
            </Col>
          </Row>
        </Container>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="success" onClick={handleSubmit}>
          {props?.parent?.id ? "Update" : "Save"}
        </Button>
        <Button onClick={props.onHide} variant="light">
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
export default AddBook;
