/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import schoolApi from "../../api/schoolApi";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const AddSubject = (props) => {
  const [rowRecord, setRowRecord] = useState({
    name: "",
    shortname: "",
    type: "",
    status: "",
  });

  useEffect(() => {
    if (props?.parent?.id) {
      setRowRecord(props?.parent);
    }
  }, []);

  const handleChange = (e) => {
    setRowRecord({ ...rowRecord, [e.target.name]: e.target.value });
  };
  const handleChange1 = (e) => {
    const { name, value } = e.target;

    const shortName = generateShortName(value);
    setRowRecord({
      ...rowRecord,
      [name]: value,
      shortname: shortName,
    });
  };

  //======================== Add by Aamir khan Code Start ==========================
  const generateShortNam = (subjectName) => {
    console.log("subjectName==>", subjectName);
    if (subjectName.id != null) {
      const words = subjectName.shortname.split(" ");
      let shortName = "";

      if (words.length >= 2) {
        shortName = words
          .slice(0, 10)
          .map((word) => word.charAt(0))
          .join("");
      } else if (words.length === 1) {
        shortName = words[0].slice(0, 10);
      }
      return shortName.toUpperCase();
    } else {
      console.log("ElsePartsubjectName", subjectName);
      const words = subjectName.shortname.split(" ");
      console.log("wordsData==>", words);
      let shortName = "";

      if (words.length >= 2) {
        shortName = words
          .slice(0, 2)
          .map((word) => word.charAt(0))
          .join("");
      } else if (words.length === 1) {
        shortName = words[0].slice(0, 3);
      }
      return shortName.toUpperCase();
    }
  };
  const generateShortName = (value) => {
    if (!value) return "";
    const words = value.trim().split(" ");

    if (words.length === 1) {
      return words[0].slice(0, 3).toUpperCase();
    } else if (words.length >= 2) {
      return (words[0][0] + words[1][0]).toUpperCase();
    }

    return "";
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const shortName = generateShortNam(rowRecord);

      if (shortName !== "Invalid") {
        rowRecord.shortname = shortName;

        if (props?.parent?.id) {
          const obj = {
            id: props?.parent?.id,
            name: rowRecord.name,
            shortname: rowRecord.shortname,
            type: rowRecord.type,
            status: rowRecord.status,
          };

          if (
            obj.id &&
            obj.id.trim() !== "" &&
            obj.name &&
            obj.name.trim() !== "" &&
            obj.shortname &&
            obj.shortname.trim() !== "" &&
            obj.type &&
            obj.type.trim() !== ""
          ) {
            let response = await schoolApi.updateSubjectRecord(obj);
            if (response.success) {
              toast.success(response.message);
              recordSavedSuccessfully();
            } else {
              toast.error(response.message);
            }
          } else {
            toast.error("Missing required fields");
          }
        } else {
          if (
            rowRecord.name &&
            rowRecord.name.trim() !== "" &&
            rowRecord.shortname &&
            rowRecord.shortname.trim() !== "" &&
            rowRecord.type &&
            rowRecord.type.trim() !== ""
          ) {
            let response = {};
            console.log("Checking");
            response = await schoolApi.addSubjectRecord(rowRecord);
            console.log("response", response);
            if (response.success) {
              toast.success("Subject save successfully!");
              recordSavedSuccessfully();
            } else {
              toast.error(response.message);
            }
          } else {
            toast.error("Missing required fields");
          }
        }
      } else {
        toast.error("Subject name invalid");
      }
    } catch (error) {
      console.log("error ->", error);
      if (error?.response?.data?.message) {
        toast.error(error?.response?.data?.message);
      } else {
        toast.error("Something went wroong");
      }
    }
  };

  const recordSavedSuccessfully = () => {
    props.recordSavedSuccessfully();
  };

  return (
    <Modal
      show={props.show}
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton onClick={props.onHide}>
        <Modal.Title id="contained-modal-title-vcenter">
          Subject Record
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Container className="view-form">
          <Row>
            <Col lg={12}>
              <Form noValidate>
                <Row>
                  {/*===================== Add By Aamir khan Code Start =====================*/}
                  <Col lg={6}>
                    {/* Add by Aamir khan 09-05-2024   className="mx-3" */}
                    <Form.Group className="mx-3">
                      <Form.Label
                        className="form-view-label"
                        htmlFor="formBasicFirstName"
                      >
                        Subject Name
                      </Form.Label>
                      <Form.Control
                        required
                        type="text"
                        name="name"
                        placeholder="Enter Your Subject"
                        value={rowRecord.name}
                        onChange={handleChange1}
                      />
                    </Form.Group>
                  </Col>

                  <Col lg={6}>
                    <Form.Group className="mx-3">
                      <Form.Label
                        className="form-view-label"
                        htmlFor="formBasicFirstName"
                      >
                        Short Name
                      </Form.Label>
                      <Form.Control
                        required
                        type="text"
                        name="shortname"
                        placeholder="Enter your short name"
                        disabled={!rowRecord.shortname}
                        value={rowRecord.shortname}
                        onChange={handleChange}
                      />
                    </Form.Group>
                  </Col>
                  {/*=====================Aamir khan Code End =====================*/}
                  <Col lg={6}>
                    <Form.Group className="mx-3">
                      <Form.Label
                        className="form-view-label"
                        htmlFor="formBasicFirstName"
                      >
                        Status
                      </Form.Label>
                      <Form.Select
                        required
                        name="status"
                        value={rowRecord.status}
                        onChange={handleChange}
                      >
                        <option value="">-- Select Status --</option>
                        <option value="Active">Active</option>
                        <option value="InActive">InActive</option>
                      </Form.Select>
                    </Form.Group>
                  </Col>

                  <Col lg={6}>
                    <Form.Group className="mx-3">
                      <Form.Label
                        className="form-view-label"
                        htmlFor="formBasicFirstName"
                      >
                        Type Name
                      </Form.Label>
                      <Form.Select
                        required
                        name="type"
                        value={rowRecord.type}
                        onChange={handleChange}
                      >
                        <option value="">-- Select Type --</option>
                        <option value="General">General</option>
                        <option value="Optional">Optional</option>
                      </Form.Select>
                    </Form.Group>
                  </Col>
                </Row>
              </Form>
            </Col>
          </Row>
        </Container>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="success" onClick={handleSubmit}>
          Save
        </Button>
        <Button onClick={props.onHide} variant="light">
          Close
        </Button>
      </Modal.Footer>
      <ToastContainer
        position="top-right"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </Modal>
  );
};

export default AddSubject;
