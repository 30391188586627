/**
 * @author: Pawan Singh Sisodiya
 */

import React, { useState, useEffect, useRef } from "react";
import { Form, Table } from "react-bootstrap";
import { Link } from "react-router-dom";
// Add by Abhishek
import { useReactToPrint } from "react-to-print";
import FeeReceipt from "./FeeReceipt.jsx";
import "../../App.css";
const InstallmentTable = ({ data, edit, feeDepositHandler, readonly }) => {
  const [installments, setInstallments] = useState([]);
  const [headsArray, setHeadsArray] = useState([]);
  const [indexValues, setIndexValues] = useState([]);
  const [flag, setFlag] = useState(0);
  const [latejoineediscount, setLateJoineeDiscount] = useState(0);

  // Start: Add by Abhishek
  const [selectedReceiptData, setSelectedReceiptData] = useState(null);
  const receiptRef = useRef();
  // End: Add by Abhishek

  useEffect(() => {
    setInstallments(data);
  }, [data]);

  console.log("installments-->", installments);
  console.log("latejoineediscount ->", latejoineediscount);

  useEffect(() => {
    const headsSet = new Set();
    setLateJoineeDiscount(0);
    installments.forEach((item, index) => {
      let netpayableAmount = 0;
      let discount_head_id = [];
      let grossPayableAmount = 0;

      item?.discounts?.forEach((ds) => {
        discount_head_id.push({ id: ds.fee_head_id, percent: ds.percent });
      });

      item?.heads?.forEach((head) => {
        headsSet.add(head.headname);
      });
      grossPayableAmount +=
        parseInt(item.installment_amount) +
        parseInt(item.transport_fee ?? 0) -
        parseInt(item.installment_discount ?? 0);
      netpayableAmount +=
        grossPayableAmount +
        parseInt(item.previous_due ?? 0) +
        parseInt(item.late_fee ?? 0);
      item.net_payable_amount = netpayableAmount;
      item.discounted_amount = item.installment_discount ?? 0;
      item.grossPayableAmount = grossPayableAmount;
      if (item.late_joinee) {
        setLateJoineeDiscount(
          (prev) => prev + item.installment_amount + item.transport_fee
        );
        //late_joinee_discount += item.installment_amount + item.transport_fee;
      }

      if (edit && item.status === "completed") {
        if (item?.edit) {
          item.isdissable = false;
          setIndexValues((previndex) => {
            return [...previndex, { index: index }];
          });
        } else {
          item.isdissable =
            item.isdissable === undefined ? true : item.isdissable;
        }
      } else {
        if (index === 0 && !item.status === "completed") {
          item.isdissable = false;
        } else {
          item.isdissable =
            item.isdissable === undefined ? true : item.isdissable;
        }
      }
    });

    //let dissable = installments?.filter((vl) => vl?.status !== "completed")[0];

    let dissable = installments?.filter(
      (vl) => vl?.status !== "completed" && vl?.late_joinee === false
    )[0];

    if (dissable && !edit) {
      //dissable.discounted_amount += latejoineediscount;
      dissable.isdissable = false;
    }
    console.log("dissable ->", dissable);

    headsSet.add("Transport Fee");
    headsSet.add("Total Amount");
    headsSet.add("Discount");
    headsSet.add("Late Fee");
    headsSet.add("Previous Due");
    headsSet.add("Net Payable Amount");
    headsSet.add("Deposited Amount");
    headsSet.add("Receipt Number");

    setHeadsArray(Array.from(headsSet));
  }, [installments]);

  useEffect(() => {
    const selectedInstallments = [];
    if (indexValues.length) {
      if (flag <= 0) {
        selectedInstallments.push(
          installments.filter((res, index) =>
            indexValues.some((i) => i.index === index)
          )
        );
      }

      if (selectedInstallments.length) {
        feeDepositHandler(selectedInstallments[0]);
      }
    }
  }, [indexValues]);

  const getFee = (item, headName, headFee) => {
    switch (headName) {
      case "Transport Fee":
        return formatNumberWithCommas(item.transport_fee);
      case "Previous Due":
        return formatNumberWithCommas(item?.previous_due) ?? 0;
      case "Deposited Amount":
        return formatNumberWithCommas(item?.deposited_amount) ?? 0;
      case "Net Payable Amount":
        return formatNumberWithCommas(item?.net_payable_amount) ?? 0;
      case "Discount":
        return formatNumberWithCommas(item?.discounted_amount) ?? 0;
      case "Total Amount":
        return formatNumberWithCommas(
          item.installment_amount + item.transport_fee
        );
      case "Late Fee":
        return item.late_fee ? formatNumberWithCommas(item.late_fee) : 0;
      case "Receipt Number":
        const row = item;
        return item.receipt_number ? (
          <Link to="#" state={{ row }} onClick={() => handlePrintClick(row)}>
            {item.receipt_number}
          </Link>
        ) : (
          "NA"
        );
      default:
        return headFee;
    }
  };

  const handleChange = (e, month, index) => {
    const { checked } = e.target;
    setInstallments((prevInstallments) => {
      const updatedInstallments = [...prevInstallments];
      const selectedInstallments = [];
      updatedInstallments[index].isCheked = checked;

        if (edit) {
          if (checked) {
            console.log("hear for check");
            console.log("updatedInstallments ->", updatedInstallments);
            if (updatedInstallments[index + 1].edit) {
              updatedInstallments[index + 1].isdissable = false;
            }
  
            updatedInstallments[index].status = "completed";
          } else {
            console.log("hear for uncheck");
  
            updatedInstallments[index].status = "pending";
            for (var i = index + 1; i < updatedInstallments.length; i++) {
              updatedInstallments[i].status = "pending";
              updatedInstallments[i].isCheked = checked;
              updatedInstallments[i].isdissable = true;
            }
          }
          console.log("updatedInstallments ->", updatedInstallments);
  
          selectedInstallments.push(
            updatedInstallments.filter(
              (res) =>
                res.edit && res.isdissable === false && res.status === "completed"
            )
          );
          setFlag(flag + 1);
        } else {
        if (checked) {
          if (index + 1 < updatedInstallments.length) {
            updatedInstallments[index + 1].isdissable = false;
          }
        } else {
          for (var i = index + 1; i < updatedInstallments.length; i++) {
            updatedInstallments[i].isCheked = checked;
            updatedInstallments[i].isdissable = true;
          }
        }
        selectedInstallments.push(
          updatedInstallments.filter((res) => res.isCheked === true)
        );
      }
      console.log("selectedInstallments ->", selectedInstallments);
      console.log("installments ->", installments);

      let dissable = installments?.filter((vl) => vl?.late_joinee === false)[0];
      console.log("dissable ->", dissable?.id);
      console.log("dissable ->", selectedInstallments[0][0]?.id);

      if (dissable?.id === selectedInstallments[0][0]?.id) {
        console.log("selectedInstallments ->", selectedInstallments);
        //selectedInstallments[0][0].discounted_amount += latejoineediscount;
        selectedInstallments[0][0].late_joinee_discount = latejoineediscount;
      }
      feeDepositHandler(selectedInstallments[0]);
      return updatedInstallments;
    });
  };

  const handlePrint = useReactToPrint({
    content: () => receiptRef.current,
    documentTitle: "FeeReceipt",
    onAfterPrint: () => {
      setSelectedReceiptData(null);
    },
  });

  const handlePrintClick = (data) => {
    setSelectedReceiptData(data);
    const timer = setTimeout(() => {
      handlePrint();
    }, 200);
    return () => clearTimeout(timer);
  };

  const formatNumberWithCommas = (number) => {
    return number ? number.toLocaleString("en-IN") : "0";
};

  return (
    <div style={{ overflowY: "auto" }}>
      <Table striped bordered hover id="myTable" className="mx-3">
        <thead style={{ width: "140px" }}>
          <tr>
            <th
              style={{
                whiteSpace: "nowrap",
                backgroundColor: "#637384",
                color: "white",
                textAlign: "center",
              }}
            >
              Heads
            </th>
            {installments.map((item, index) => (
              <th
                style={{
                  whiteSpace: "nowrap",
                  backgroundColor: "#637384",
                  color: "white",
                  textAlign: "center",
                }}
                key={item.id}
              >
                {item.month}
                <Form>
                  <Form.Check
                    inline
                    type="checkbox"
                    name={"checkedMonth"}
                    checked={
                      item?.status === "completed" ||
                      item?.isCheked ||
                      item?.late_joinee
                    }
                    onChange={(e) => handleChange(e, item.month, index)}
                    disabled={
                      readonly ||
                      (!item.edit &&
                        item?.isdissable &&
                        item?.status === "completed") ||
                      item?.isdissable ||
                      item?.late_joinee
                    }
                    className={item.late_joinee ? "late-joinee" : ""}
                  />
                </Form>
              </th>
            ))}
          </tr>
        </thead>
        <tbody style={{ width: "140px" }}>
          {headsArray.map((headName) => (
            <tr key={headName}>
              <td>{headName}</td>
              {installments.map((item) => {
                const head = item.heads.find((h) => h.headname === headName);
                const headFee = head ? head.head_amount : 0;
                return <td key={item.id} className="text-end" >{getFee(item, headName, formatNumberWithCommas(headFee))}</td>;
              })}
            </tr>
          ))}
        </tbody>
      </Table>
      {/* Add By Abhishek */}
      {selectedReceiptData && (
        <div style={{ display: "none" }}>
          <FeeReceipt ref={receiptRef} receiptData={selectedReceiptData} />
        </div>
      )}
    </div>
  );
};

export default InstallmentTable;
