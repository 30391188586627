/**
 * @author      Abdul Pathan
 * @date        Aug, 2024
 * @copyright   www.ibirdsservices.com
 */

import React, { useEffect, useState } from "react";
import { Col, Form, Modal, Row } from "react-bootstrap";
import WhatsAppAPI from "../../../api/schoolApi";
import { toast } from "react-toastify"; //npm i react-toastify --force
import "react-toastify/dist/ReactToastify.css";
import moment from "moment-timezone";

const TemplateModal = ({
  show,
  onHide,
  contactData,
  refreshData,
  filterData,
}) => {
  const [allTemplateData, setAllTemplateData] = useState([]);
  const [selectedTemplateName, setSelectedTemplateName] = useState();
  const [isSpinner, setIsSpinner] = useState(false);
  console.log("contactData->", contactData);

  useEffect(() => {
    fetchAllTemplate();
  }, []);

  const fetchAllTemplate = async () => {
    try {
      const result = await WhatsAppAPI.getApprovedTemplates();

      if (result.error) {
        setAllTemplateData([]);
        setIsSpinner(true);
      } else {
        const transformedData = result?.data.map((row) => {
          const header =
            row.components.find((component) => component.type === "HEADER") ||
            {};
          const body =
            row.components.find((component) => component.type === "BODY") || {};
          const footer =
            row.components.find((component) => component.type === "FOOTER") ||
            {};
          const buttons =
            row.components.find((component) => component.type === "BUTTONS")
              ?.buttons || [];
          return {
            id: row.id,
            name: row.name,
            templatename: row.name.replace(/_/g, " "),
            language: row.language,
            status: row.status,
            category: row.category,
            header: header.format || "",

            header_text: header.format === "TEXT" ? header.text : "",
            header_image_url:
              header.format === "IMAGE"
                ? header.example?.header_handle?.[0] || ""
                : "",
            header_document_url:
              header.format === "DOCUMENT"
                ? header.example?.header_handle?.[0] || ""
                : "",
            header_video_url:
              header.format === "VIDEO"
                ? header.example?.header_handle?.[0] || ""
                : "",

            message_body: body.text || "",
            example_body_text: body.example?.body_text || [], // Extracting example body text
            footer: footer.text || "",

            add_security_recommendation:
              body.add_security_recommendation || false,
            code_expiration_minutes: footer.code_expiration_minutes || null,

            // Button details extraction
            buttons: buttons
              .map((button) => {
                if (button.type === "OTP") {
                  return {
                    type: button.type,
                    otp_type: button.otp_type,
                    supported_apps:
                      button.supported_apps?.map((app) => ({
                        package_name: app.package_name,
                        signature_hash: app.signature_hash,
                      })) || [],
                  };
                } else {
                  return {
                    type: button.type,
                    text: button.text,
                    ...(button.type === "PHONE_NUMBER" && {
                      phone_number: button.phone_number,
                    }),
                    ...(button.type === "URL" && { url: button.url }),
                  };
                }
              })
              .filter((button) => button.text && button.type),
          };
        });

        console.log("transformedData", transformedData);
        setAllTemplateData(transformedData);
        setIsSpinner(true);
      }
    } catch (err) {
      console.log("error ->", err);
    }
  };

  const handleChange = async (event) => {
    const selectedName = event.target.value;

    const template = allTemplateData.find((t) => t.name === selectedName);

    console.log("###template", template);

    if (template) {
      const {
        id,
        name,
        language,
        category,
        message_body,
        header_text,
        footer,
        example_body_text,
      } = template;
      const exampleValue =
        example_body_text.length > 0 && example_body_text[0].length > 0
          ? example_body_text[0][0]
          : "";
      const finalMessageBody = message_body
        .replace("*{{1}}*", exampleValue)
        .trim();
      console.log("finalMessageBody", finalMessageBody);
      const combinedMessage =
        `${header_text}\n${finalMessageBody}\n${footer}`.trim();
      const url =
        template.header_image_url ||
        template.header_document_url ||
        template.header_video_url ||
        "";

      const formattedTemplate = {
        id,
        name,
        language,
        category,
        url,
        body_message: combinedMessage,
      };
      console.log("formattedTemplate", formattedTemplate);
      setSelectedTemplateName(formattedTemplate);
    } else {
      setSelectedTemplateName();
    }
  };

  // Function to fetch the file and convert it to a Blob
  async function fetchFile(url) {
    const response = await fetch(url);
    if (!response.ok) throw new Error("Network response was not ok");
    return await response.blob(); // Return the Blob
  }
  async function initClass() {
    try {
      let frequencySettings = 0;
      let lateFeeSettings = 0;
      const ResultSettings = await WhatsAppAPI.getAllSetting();
      let fetchSelectedSessionResult;
      const sessions = await WhatsAppAPI.fetchSessions();
      if (sessions) {
        const currentYear = new Date().getFullYear();
        const nextYear = currentYear + 1;

        fetchSelectedSessionResult = sessions.find((res) => {
          return res.year === `${currentYear}-${nextYear}`;
        });
      }
      console.log("ResultSettings-->", ResultSettings);
      if (ResultSettings.success) {
        frequencySettings = ResultSettings?.data?.filter(
          (res) => res.key === "frequency"
        )[0].value;
        lateFeeSettings = ResultSettings?.data?.filter(
          (res) => res.key === "late_fee"
        )[0].value;
      }
      console.log("tempFrequency-->", frequencySettings);
      console.log("tempsettings-->", lateFeeSettings);

      const insResult = await WhatsAppAPI.getallIntsallment(
        contactData?.id,
        fetchSelectedSessionResult?.id
      );

      console.log("insResult-->", insResult);

      const currentDate = new Date();
      const currentYear = currentDate.getFullYear();

      insResult.result = insResult?.result?.map((is) => {
        insResult.result = insResult?.result?.map((is) => {
          if (!is.late_fee > 0 && is.status === "pending") {
            let latefee_is = 0;
            const dueDate = is?.due_date;
            console.log("dueDate", dueDate);

            if (dueDate) {
              const dueDateObj = new Date(dueDate);
              console.log("dueDateObj-->", dueDateObj);

              if (
                dueDateObj.getFullYear() === currentYear &&
                dueDateObj < currentDate
              ) {
                const differenceInDays = Math.ceil(
                  (currentDate - dueDateObj) / (1000 * 3600 * 24)
                );
                console.log("dueDate differenceInDays-->", differenceInDays);
                const netDueDays = parseInt(
                  (differenceInDays + parseInt(frequencySettings)) /
                    parseInt(frequencySettings)
                );
                console.log("dueDate netDueDays-->", netDueDays);
                latefee_is += Math.round(
                  netDueDays * parseInt(lateFeeSettings)
                );
              }
            }

            is.late_fee = latefee_is;
            console.log("latefee_is-->", latefee_is);
          }

          if (is.deposit_id && is.late_fee > 0) {
            let lateFeeHelper = insResult.result.filter(
              (ft) => ft.deposit_id === is.deposit_id
            );

            if (is.id !== lateFeeHelper[lateFeeHelper.length - 1].id) {
              return { ...is, late_fee: 0 };
            }
          }

          return { ...is };
        });
        if (is.deposit_id && is.late_fee > 0) {
          let lateFeeHelper = insResult.result.filter(
            (ft) => ft.deposit_id === is.deposit_id
          );

          if (is.id !== lateFeeHelper[lateFeeHelper.length - 1].id) {
            return { ...is, late_fee: 0 };
          }
        }

        return { ...is };
      });
      console.log("insResult ->", insResult);
      const pendingData = insResult.result.filter((record) => {
        const recordDate = new Date(record.due_date);
        return (
          new Date(record.due_date) < currentDate && record.status === "pending"
        );
      });
      console.log("pendingData -->", pendingData);
      let amount = 0;
      let lateamount = 0;

      pendingData.forEach((item) => {
        const itemPendingAmount =
          (item.late_fee || 0) +
          (item.transport_fee || 0) +
          (item.installment_amount || 0) -
          (item.installment_discount || 0);

        console.log("Pending Amount for Item:", itemPendingAmount);

        amount += itemPendingAmount;
        lateamount += item.late_fee || 0;
      });

      console.log("Total Pending Amount:", amount);
      console.log("Total Pending Amount:", lateamount);
      console.log(
        "Total Pending Amount:",
        pendingData[pendingData.length - 1].due_date
      );
      return {
        amount,
        late_fee: lateamount,
        due_date: pendingData[pendingData.length - 1].due_date,
      };
      //return insResult;
    } catch (error) {
      console.log("error ->", error);
    }
  }
  const handleSubmit = async (event) => {
    let parameters = [];
    event.preventDefault();
    console.log("selectedTemplateName", selectedTemplateName);
    let documentId = null;
    setIsSpinner(false);

    try {
      const msgResult = await WhatsAppAPI.createMessageTemplateData(
        selectedTemplateName
      );
      console.log("#msgResult", msgResult);
      if (msgResult.message && msgResult.message.match(/{{\d+}}/g)) {
        console.log("Placeholders found in the message body.");
        if (msgResult.template_name === "grating_template") {
          parameters = [
            {
              type: "text",
              text: contactData?.contactname,
            },
          ];
        } else if (msgResult.template_name === "fee_reminder") {
          const installmentrecord = await initClass();
          console.log("call fees ->");

          parameters = [
            {
              type: "currency",
              currency: {
                fallback_value: `₹${installmentrecord.amount}`,
                code: "INR",
                amount_1000: installmentrecord.amount * 1000,
              },
            },
            {
              type: "text",
              text: contactData?.contactname,
            },
            {
              type: "date_time",
              date_time: {
                fallback_value: installmentrecord.due_date, // Using due date for the placeholder
                day_of_week:
                  new Date(installmentrecord.due_date).getDay() === 0
                    ? 7
                    : new Date(installmentrecord.due_date).getDay(), // Adjust Sunday (0) to 7
                year: new Date(installmentrecord.due_date).getFullYear(),
                month: new Date(installmentrecord.due_date).getMonth() + 1, // Month is 0-based, so adding 1
                day_of_month: new Date(installmentrecord.due_date).getDate(),
                hour: 0,
                minute: 0,
                calendar: "GREGORIAN",
              },
            },
            {
              type: "currency",
              currency: {
                fallback_value: `₹${installmentrecord.late_fee || 0}`,
                code: "INR",
                amount_1000: (installmentrecord.late_fee || 0) * 1000, // Assuming late fee is in full units
              },
            },
            {
              type: "date_time",
              date_time: {
                fallback_value: installmentrecord.due_date, // Using due date for the placeholder
                day_of_week:
                  new Date(installmentrecord.due_date).getDay() === 0
                    ? 7
                    : new Date(installmentrecord.due_date).getDay(), // Adjust Sunday (0) to 7
                year: new Date(installmentrecord.due_date).getFullYear(),
                month: new Date(installmentrecord.due_date).getMonth() + 1, // Month is 0-based, so adding 1
                day_of_month: new Date(installmentrecord.due_date).getDate(),
                hour: 0,
                minute: 0,
                calendar: "GREGORIAN",
              },
            },
          ];
        } else {
          toast.error("make first paramerers of this template");
          return;
        }
      }
      if (msgResult?.errors) {
        toast.error(msgResult?.errors);
        setIsSpinner(true);
        return;
      }

      if (selectedTemplateName.url) {
        const fileUrl = selectedTemplateName.url;
        const fileBlob = await fetchFile(fileUrl); // Fetch the file
        // const fileName = 'image.jpg';
        const fileName = fileUrl.split("/").pop().split("?")[0] || "image.jpg";
        console.log("fileName", fileName);

        const file = new File([fileBlob], fileName, { type: fileBlob.type });
        const formData = new FormData();
        formData.append("messaging_product", "whatsapp");
        formData.append("file", file);
        formData.append("description", "Header Image Template");
        const documentResult = await WhatsAppAPI.uploadDocumentWithApi(
          formData
        );
        documentId = documentResult.id;
        console.log("###result file id: ", documentId);
      }

      const reqBody = {
        messaging_product: "whatsapp",
        to: `91${contactData.whatsapp_number}`,
        type: "template",
        category: selectedTemplateName.category,
        template: {
          name: selectedTemplateName.name,
          language: {
            code: selectedTemplateName.language,
          },
          components: [
            {
              type: "header",
              parameters: [],
            },
          ],
          components: [
            {
              type: "header",
              parameters: [],
            },
            {
              type: "body",
              parameters: parameters || [],
            },
          ],
        },
      };

      if (documentId) {
        reqBody.template.components[0].parameters.push({
          type: "image",
          image: {
            id: documentId,
            // link: selectedTemplateName.url
          },
        });
      }

      console.log("reqBody", reqBody);

      const result = await WhatsAppAPI.sendWhatsAppTemplateMessage(reqBody);
      console.log("result", result);

      if (result.error) {
        const msgError = result.error.error_data;
        toast.error(`Error: ${msgError.details}`);
        // toast.error(`Error: Message could not be sent to this number ${number} due to a bad request.`);
        console.log("##ERROR", result.error);
        setIsSpinner(true);
      } else {
        const newMessage = {
          parent_id: contactData.id || null,
          name: contactData?.contactname || "",
          message_template_id: msgResult.id || null,
          whatsapp_number: contactData.whatsapp_number,
          message: "",
          status: "Outgoing",
          recordtypename: filterData?.recordType || "",
          file_id: null,
        };

        //const response = null;
        const response = await WhatsAppAPI.insertMsgHistoryRecords(newMessage);
        console.log("response", response);
        if (!response.error) {
          const obj = {
            id: contactData.id,
            date: moment(new Date()).format("YYYY-MM-DDTHH:mm"),
          };
        }
      }
    } catch (error) {
      console.log("error ->", error);

      toast.error("Failed to send message.");
      setIsSpinner(true);
    } finally {
      setIsSpinner(true);
      refreshData();
    }
  };

  const isFormValid = Boolean(selectedTemplateName);

  return (
    <>
      <Modal show={show} animation={false} size="lg">
        <Modal.Header closeButton onClick={onHide}>
          <Modal.Title id="contained-modal-title-vcenter">
            Send WhatsApp Message
          </Modal.Title>
        </Modal.Header>
        {isSpinner ? (
          <>
            <Modal.Body>
              <Form noValidate>
                <Row className="p-2 mb-3">
                  <Col lg={12} sm={12} xs={12}>
                    <Form.Group className="mb-3">
                      <Form.Label
                        className="form-view-label"
                        htmlFor="formBasicFirstName"
                      >
                        Template Name
                      </Form.Label>
                      <Form.Select
                        required
                        style={{ height: "36px" }}
                        aria-label="select name"
                        name="templateName"
                        onChange={handleChange}
                        placeholder="Select Template Name"
                        // value={templateName}
                      >
                        <option value="">Select Template Name</option>
                        {allTemplateData?.map((template) => (
                          <option key={template.id} value={template.name}>
                            {template.templatename}
                          </option>
                        ))}
                      </Form.Select>
                    </Form.Group>
                  </Col>
                </Row>
              </Form>
            </Modal.Body>

            <Modal.Footer>
              <button className="btn btn-light" onClick={onHide}>
                Close
              </button>
              <button
                className="btn btn-outline-secondary"
                onClick={handleSubmit}
                disabled={!isFormValid}
              >
                Send Message
              </button>
            </Modal.Footer>
          </>
        ) : (
          <div className="sk-cube-grid">
            <div className="sk-cube sk-cube1"></div>
            <div className="sk-cube sk-cube2"></div>
            <div className="sk-cube sk-cube3"></div>
            <div className="sk-cube sk-cube4"></div>
            <div className="sk-cube sk-cube5"></div>
            <div className="sk-cube sk-cube6"></div>
            <div className="sk-cube sk-cube7"></div>
            <div className="sk-cube sk-cube8"></div>
            <div className="sk-cube sk-cube9"></div>
          </div>
        )}
      </Modal>
    </>
  );
};

export default TemplateModal;
