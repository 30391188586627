//---last updated by pankaj 02-09-2024

import Sidebar from "../../components/layout/common/Sidebar";
import Header from "../../components/layout/common/Header";
import { Outlet } from "react-router-dom";
import React, { useState } from "react";

const Main = ({socket}) => {
  const [moduleRecords, setModuleRecords] = useState([]);
  const [allLauncherData, setAllLauncherData] = useState([]);
  return (
    <div className="wrapper">
      <Sidebar
        moduleRecords={moduleRecords}
        setModuleRecords={setModuleRecords}
      />
      <div id="content" className="container-fluid">
        <Header
          allLauncherData={allLauncherData}
          setAllLauncherData={setAllLauncherData}
          socket={socket}
        />
        <Outlet
          context={[
            moduleRecords,
            setModuleRecords,
            allLauncherData,
            setAllLauncherData,
          ]}
        />
      </div>
    </div>
  );
};

export default Main;

// /** @format */

// import Sidebar from "../../components/layout/common/Sidebar"
// import Header from "../../components/layout/common/Header";
// import { Outlet } from "react-router-dom";
// const Main = () => {
//   return (
//     <div className="wrapper">
//       <Sidebar />
//       <div id="content" className="container-fluid">
//         <Header />
//         <Outlet />
//       </div>
//     </div>
//   );
// };

// export default Main;
