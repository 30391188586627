//Add This Code By Shahir Hussain 26-11-2024

import React, { useEffect, useState } from "react";
import schoolApi from "../../api/schoolApi";
import DailyAttendance from "./dailyAttendance";
import MonthlyAttendance from "./monthlyAttendance";
import { Helmet } from "react-helmet";

const ManageAttendanceMode = (props) => {
    const [attendancemode, setattendancemode] = useState("monthly");

    useEffect(() => {
        const init = async () => {
          try{
            const ResultSettings = await schoolApi.getAllSetting();
            console.log("ResultSettings-->", ResultSettings.data);
            const attendancemode = ResultSettings?.data?.filter(
              (res) => res.key === "Attendentce_Mode"
            )[0].value;
            setattendancemode(attendancemode);
          } catch (error) {
            // toast.error(error.message);
          }
        };
        init();
      }, []);
    
      return(
        <>
         <Helmet>
          <title>{props?.tabName}</title>
      </Helmet>
            { attendancemode === "daily" ? (
                    <DailyAttendance />

                  ):(
                    <MonthlyAttendance />
                  )
            }
        </>
      ) 
}
export default ManageAttendanceMode;