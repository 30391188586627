import React, { useState, useEffect } from "react";
import { Button, Col, Container, Row, Table } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import WhatsAppAPI from "../../../api/schoolApi";
import Confirm from "../../Confirm";
import {
  DatatableWrapper,
  Filter,
  Pagination,
  TableBody,
  TableHeader,
} from "react-bs-datatable";
import AddGroupModal from "./AddGroupModal";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Helmet } from "react-helmet";

const GroupView = (props) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [group, setGroup] = useState(location.state ? location.state : {});
  const [showAddGroupModal, setShowAddGroupModal] = useState(false);
  const [selectedId, setSelectedId] = useState("");
  const [showHideModel, setShowHideModel] = useState(false);
  useEffect(() => {
    fetchGroup();
  }, []);

  const fetchGroup = () => {
    async function initGroup() {
      //       let result = await schoolApi.fetchMembersById(group.id);

      let result = await WhatsAppAPI.fetchGroupsById(group.id);
      if (result.success) {
        setGroup(result.records);
      } else {
        setGroup([]);
      }
    }
    initGroup();
  };

  const handleBack = () => {
    navigate("/whatsappgroups");
  };

  const handleAddMember = () => {
    setShowAddGroupModal(true);
  };

  const deleteRecord = (row) => {
    setSelectedId(row);
    setShowHideModel(true);
  };

  const deleteGroupMember = async () => {
    try {
      const response = await WhatsAppAPI.deleteGroupMember(selectedId);
      if (response.success) {
        setShowHideModel(false);
        toast.success("Group Member deleted successfully.");
        fetchGroup();
      } else {
        setShowHideModel(false);
        toast.error(response.error.message);
      }
    } catch (error) {
      console.log("Error removing member:", error);
    }
  };

  const renderTable = () => {
    const header = [
      {
        title: "Member Name",
        prop: "full_name",
        isFilterable: true,
        cell: (row) => (
          <>
            {row.member_firstname} {row.member_lastname}
          </>
        ),
      },
      { title: "WhatsApp Number", prop: "whatsapp_number" },
      {
        title: "Actions",
        prop: "groupmemberid",
        cell: (row) => (
          <>
            {group.members?.length > 1 && (
              <Button
                className="btn-sm mx-2"
                variant="danger"
                onClick={() => deleteRecord(row.groupmemberid)}
              >
                Remove
              </Button>
            )}
          </>
        ),
      },
    ];
    const membersWithFullName = group.members?.map((member) => ({
      ...member,
      full_name: `${member.member_firstname} ${member.member_lastname}`,
    }));

    return (
      <>
        {group.members ? (
          <DatatableWrapper
            body={membersWithFullName}
            headers={header}
            paginationOptionsProps={{
              initialState: { rowsPerPage: 10 },
            }}
          >
            <Row className="mb-2 d-flex justify-content-start">
              <Col
                lg={3}
                sm={6}
                xs={6}
                className="d-flex flex-col justify-content-start align-items-start"
              >
                <Filter />
              </Col>
            </Row>

            <Table striped className="data-table" responsive="sm">
              <TableHeader />
              <TableBody />
            </Table>
            <Pagination />
          </DatatableWrapper>
        ) : (
          ""
        )}
      </>
    );
  };

  return (
    <>
      <Container className="mt-5">
        <Helmet><title>{props?.tabName}</title></Helmet>
        <Row className="mx-5 ">
          <Col lg={12} sm={12} xs={12}>
            <Row className="view-form-header align-items-center">
              <Col lg={8} sm={8} xs={8}>
                Group Name
                <h5>{group?.name ? group.name + " " : <>&nbsp;</>}</h5>
              </Col>
              <Col lg={4} sm={4} xs={4} className="text-end">
                <Button
                  className="btn-sm mx-2"
                  variant="outline-light"
                  onClick={handleBack}
                >
                  Back
                </Button>
                <Button
                  className="btn-sm mx-2"
                  variant="outline-light"
                  onClick={handleAddMember}
                >
                  Add More Members
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>

      {/* <Container className='mt-1'>
        <Row className='g-0 mx-5'>
          <Col lg={12} className="section-header">
            <span className='mx-2'>Group Members Details</span>
          </Col>
        </Row>
      </Container> */}

      <Container className="mt-2 mb-5">
        <Row className="g-0 mx-5">
          <Col lg={12} sm={12} xs={12}>
            <Row className="g-0 mt-2">
              <Col lg={12} sm={12} xs={12}>
                {renderTable()}
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>

      {showHideModel && (
        <Confirm
          show={showHideModel}
          onHide={() => setShowHideModel(false)}
          deleteGroupMember={deleteGroupMember}
          title="Confirm delete?"
          message="You are going to delete the record. Are you sure?"
          table="group_member"
        />
      )}

      {showAddGroupModal && (
        <AddGroupModal
          show={showAddGroupModal}
          onHide={() => setShowAddGroupModal(false)}
          fetchGroupRecords={fetchGroup}
          selectedGroup={group}
        />
      )}

      <ToastContainer />
    </>
  );
};

export default GroupView;
// import React, { useState, useEffect } from "react";
// import {
//   Button,
//   Card,
//   Col,
//   Container,
//   Row,
//   Table,
//   Tab,
//   Tabs,
// } from "react-bootstrap";
// import { useLocation, useNavigate } from "react-router-dom";
// // import WhatsAppAPI from "../../api/WhatsAppAPI";
// import { Helmet } from "react-helmet";
// import { Link } from "react-router-dom";

// import Confirm from "../../Confirm";
// import {
//   DatatableWrapper,
//   Pagination,
//   TableBody,
//   TableHeader,
// } from "react-bs-datatable";
// import AddGroupModal from "./AddGroupModal";
// import { ToastContainer, toast } from "react-toastify";
// import "react-toastify/dist/ReactToastify.css";
// import schoolApi from "../../../api/schoolApi";

// const GroupView = (props) => {
//   const location = useLocation();
//   const navigate = useNavigate();
//   const [group, setGroup] = useState(location.state ? location.state : {});
//   const [members, setMembers] = useState({
//     contact: [],
//     student: [],
//   });
//   const [showAddGroupModal, setShowAddGroupModal] = useState(false);
//   const [selectedGroup, setSelectedGroup] = useState([]);
//   const [selectedTab, setSelectedTab] = useState("contact");
//   const [selectedId, setSelectedId] = useState("");
//   const [showHideModel, setShowHideModel] = useState(false);
//   useEffect(() => {
//     fetchGroup();
//   }, []);

//   const fetchGroup = () => {
//     if (!group.id && location.pathname.split("/").length >= 3) {
//       group.id = location.pathname.split("/")[2];
//     }

//     async function initGroup() {
//       let result = await schoolApi.fetchMembersById(group.id);
//       console.log("result ->", result);

//       if (result.success) {
//         setMembers({
//           contact: result.records.contact_members || [],
//           student: result.records.student_members || [],
//         });
//       } else {
//         setMembers({ contact: [], student: [] });
//       }
//     }
//     initGroup();
//   };

//   const handleBack = () => {
//     navigate("/whatsappgroups");
//   };

//   const handleSelect = (key) => {
//     setSelectedTab(key);
//   };

//   const handleAddMember = (group) => {
//     setSelectedGroup(group);
//     setShowAddGroupModal(true);
//   };

//   const deleteRecord = (row) => {
//     console.log(row);

//     setSelectedId(row);
//     setShowHideModel(true);
//   };

//   const deleteGroupMember = async () => {
//     try {
//       const response = await schoolApi.deleteGroupMember(selectedId);
//       if (response.success) {
//         setShowHideModel(false);
//         toast.success("Group Member deleted successfully.");
//         fetchGroup();
//       } else {
//         setShowHideModel(false);
//         toast.error(response.error.message);
//       }
//     } catch (error) {
//       console.log("Error removing member:", error);
//     }
//   };

//   const renderTable = () => {
//     let data = [];

//     if (selectedTab === "contact") {
//       data = members.contact || [];
//     } else if (selectedTab === "student") {
//       data = members.student || [];
//     }
//     const labels = { beforeSelect: " " };

//     const header = [
//       { title: "Name", prop: "name" },
//       { title: "Type", prop: "type" },
//       { title: "WhatsApp Number", prop: "whatsapp_number" },
//       {
//         title: "Actions",
//         prop: "id",
//         cell: (row) => (
//           <>
//             <Button
//               className="btn-sm mx-2"
//               variant="danger"
//               onClick={() => deleteRecord(row.groupmemberid)}
//             >
//               <i className="fa-regular fa-trash-can"></i>
//             </Button>
//           </>
//         ),
//       },
//     ];

//     return (
//       <>
//         {/* <Helmet>
//           {" "}
//           <title>{props?.tabName}</title>{" "}
//         </Helmet>
//         <Row className="g-0">
//           <Col lg={2} className="mx-3">
//             <Link className="nav-link mx-2" to="/">
//               Home <i className="fa-solid fa-chevron-right"></i> ExamList
//             </Link>
//           </Col> */}
//         {data ? (
//           <DatatableWrapper
//             body={data}
//             headers={header}
//             paginationOptionsProps={{
//               initialState: {
//                 rowsPerPage: 10,
//               },
//             }}
//           >
//             <Row className="mb-4">
//               <Col
//                 xs={12}
//                 sm={6}
//                 lg={4}
//                 className="d-flex flex-col justify-content-start align-items-start"
//               ></Col>
//               <Col
//                 xs={12}
//                 sm={6}
//                 lg={4}
//                 className="d-flex flex-col justify-content-start align-items-start"
//               ></Col>
//               <Col
//                 xs={12}
//                 sm={6}
//                 lg={4}
//                 className="d-flex flex-col justify-content-end align-items-end"
//               ></Col>
//             </Row>
//             <Table striped className="related-list-table" responsive="sm">
//               <TableHeader />
//               <TableBody />
//             </Table>
//             <Pagination />
//           </DatatableWrapper>
//         ) : (
//           " <ShimmerTable row={10} col={8} />"
//         )}
//         {/* </Row> */}
//       </>
//     );
//   };

//   return (
//     <>
//       <Container className="mt-5">
//         <Row className="mx-5 ">
//           <Col lg={12} sm={12} xs={12}>
//             <Row className="view-form-header align-items-center">
//               <Col lg={8} sm={8} xs={8}>
//                 Group
//                 <h5>{group?.name ? group.name + " " : <>&nbsp;</>}</h5>
//               </Col>
//               <Col lg={4} sm={4} xs={4} className="text-end">
//                 <Button
//                   className="btn-sm mx-2"
//                   variant="light"
//                   onClick={handleBack}
//                 >
//                   Back
//                 </Button>
//                 <Button
//                   className="btn-sm mx-2"
//                   variant="light"
//                   onClick={() => handleAddMember(group)}
//                 >
//                   Add More Members
//                 </Button>
//               </Col>
//             </Row>
//           </Col>
//         </Row>
//       </Container>

//       <Container className="mt-2 mb-5">
//         <Row className="g-0 mx-5">
//           <Col lg={12} sm={12} xs={12} className="">
//             <Card className="h-100" style={{ border: "none" }}>
//               <Card.Body>
//                 <Tabs
//                   activeKey={selectedTab}
//                   id="fill-tab-example"
//                   className="mb-1"
//                   fill
//                   onSelect={(key) => handleSelect(key)}
//                 >
//                   <Tab
//                     eventKey="contact"
//                     title={<>Contacts</>}
//                     className="mx-2"
//                   >
//                     {renderTable(members.contact || [])}
//                   </Tab>
//                   <Tab
//                     eventKey="student"
//                     title={<>Students</>}
//                     className="mx-2"
//                   >
//                     {renderTable(members.student || [])}
//                   </Tab>
//                 </Tabs>
//               </Card.Body>
//             </Card>
//           </Col>
//         </Row>

//         {showHideModel && (
//           <Confirm
//             show={showHideModel}
//             onHide={() => setShowHideModel(false)}
//             deleteGroupMember={deleteGroupMember}
//             title="Confirm delete?"
//             message="You are going to delete the record. Are you sure?"
//             table="group_member"
//           />
//         )}

//         {showAddGroupModal && (
//           <AddGroupModal
//             show={showAddGroupModal}
//             onHide={() => setShowAddGroupModal(false)}
//             fetchGroupRecords={fetchGroup}
//             selectedGroup={selectedGroup}
//           />
//         )}
//         <ToastContainer />
//       </Container>
//     </>
//   );
// };

// export default GroupView;
