import React, { useState, useEffect } from "react";
import { ShimmerTable } from "react-shimmer-effects";
import moment from "moment";
import { Table, Button } from "react-bootstrap";
import { useNavigate, Link } from "react-router-dom";
import schoolApi from "../../api/schoolApi";
import {
  DatatableWrapper,
  Pagination,
  TableBody,
  TableHeader,
} from "react-bs-datatable";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function Studentadmission(student_id) {
  console.log("props.stue b=>", student_id);
  const [admissionBody, setAdmissionBody] = useState([]);
  const navigate = useNavigate(); //navigation
  useEffect(() => {
    fetchAdmission();
  }, []);

  const fetchAdmission = async () => {
    console.log("student_id@!!@@=>", student_id);
    let studenId = student_id.student_id;
    console.log("studenId@@=>", studenId);
    if (studenId !== null) {
      console.log("first");
      try{
        const result = await schoolApi.fetchAdmissionByStudentId(studenId);
        console.log("result&&&&", result);
        if (result.success) {
          console.log('first',result.studentAddmision[0].dateofaddmission)
          result.studentAddmision[0].dateofaddmission = moment(result.studentAddmision[0].createddate, "YYYY-MM-DD").format("DD-MM-YYYY");
          setAdmissionBody([result.studentAddmision]);
        }
      }catch(error){
        toast.error(error?.response?.data?.message)
      }
      
    }
  };

  const headerAdd = [
    {
      title: "Regisration Id",
      prop: "registration_id",
      isFilterable: true,
    },
    {
      title: "Student Name",
      prop: "studentname",
      isFilterable: true,
    },
    {
      title: "Date of Admission",
      prop: "dateofaddmission",
      isFilterable: true,
    },
    {
      title: "Current Class",
      prop: "current_class",
      isFilterable: true,
    },
    {
      title: "Fee Installment Type",
      // prop: "fee_type", added by pawan
      prop: "fee_master_type", //added by pooja // latest updated by pawan
      isFilterable: true,
    },
    {
      title: "Action",
      prop: "",
      isFilterable: true,
      cell: (row) => {
        return (
          <>
            <Button
              className="btn-sm mx-2 edit-button"
              variant=""
              onClick={() => editStudentAddmision(row)}
            >
              <i className="fa-regular fa-pen-to-square"></i>
            </Button>
          </>
        );
      },
    },
  ];
  const editStudentAddmision = (row) => {
    console.log('studentaddmission@@@=>', row)
    row.student_addmission_id = row.id
    navigate(`/studentaddmissions/${row.id}/e`, {
      state: row,
    });
  };
  console.log("admissionBody@@@=>", admissionBody);
  return (
    <div>
       <ToastContainer
      position="top-right"
      autoClose={2000}
      hideProgressBar={false}
      newestOnTop={false}
      closeOnClick
      rtl={false}
      pauseOnFocusLoss
      draggable
      pauseOnHover
      theme="light"
      />
      {admissionBody ? (
        admissionBody.length > 0 ? (
          <DatatableWrapper body={admissionBody.flat()} headers={headerAdd}>
            <Table striped className="data-table custom-table-subject-list">
              <TableHeader />
              <TableBody />
            </Table>
            <Pagination />
          </DatatableWrapper>
        ) : (
          <div>
            {console.log("No records found")}
            <DatatableWrapper body={admissionBody.flat()} headers={headerAdd}>
              <Table striped className="data-table custom-table-subject-list">
                <TableHeader />
                <TableBody>
                  <tr>
                    <td colSpan={headerAdd.length} className="text-center">
                      No Records Found!!!
                    </td>
                  </tr>
                </TableBody>
              </Table>
              <Pagination />
            </DatatableWrapper>
          </div>
        )
      ) : (
        <ShimmerTable row={10} col={8} />
      )}
    </div>
  );
}

export default Studentadmission;
