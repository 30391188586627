import React, { useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { useState } from "react";
import schoolApi from "../../api/schoolApi";
import { ToastContainer, toast } from "react-toastify";
import { useLocation, useNavigate } from "react-router-dom";
import { TwitterPicker } from "react-color";
import { usePermissions } from "../contexts/PermissionContext";
import "react-toastify/dist/ReactToastify.css";
import $ from "jquery";
import "jquery-ui-dist/jquery-ui.css";
import "jquery-ui-dist/jquery-ui";
import { Helmet } from "react-helmet";
const AddEvent = (props) => {
  const { rolePermissions, userInfo } = usePermissions();
  console.log("rolePermissions class list=======>", rolePermissions);
  const navigate = useNavigate();
  const location = useLocation();
  const [event, setEvent] = useState({
    title: location.state ? location.state.title : "",
    event_type: location.state ? location.state.event_type : "",
    start_date: location.state ? location.state.start_date : "",
    start_time: location.state ? location.state.start_time : "",
    end_date: location.state ? location.state.end_date : "",
    end_time: location.state ? location.state.end_time : "",
    description: location.state ? location.state.description : "",
    colorcode: location.state ? location.state.colorcode : "",
  });
  const [btnName, setBtnName] = useState("Save");
  const [hex, setHex] = useState("#F44E3B");
  const [showColorPicker, setShowColorPicker] = useState(false);
  const canCreate = rolePermissions?.Events_Calendar?.can_create;
  const canEdit = rolePermissions?.Events_Calendar?.can_edit;
  const canDelete = rolePermissions?.Events_Calendar?.can_delete;
  console.log("canCreate canEdit canDelete", canCreate, canEdit, canDelete);

  /* START  Date work with React J-Query  (Date-Picker)  */
  $(document).ready(() => {
    $("#event-start-id").datepicker({
      dateFormat: "dd/mm/yy",
      changeMonth: true,
      changeYear: true,
      yearRange: "1920:2024",
      onSelect: function (dateText) {
        handleDateChange(dateText, "start_date");
        $(this).val(dateText);
      },
    });

    $("#event-end-id").datepicker({
      dateFormat: "dd/mm/yy",
      changeMonth: true,
      changeYear: true,
      yearRange: "1920:2024",
      onSelect: function (dateText) {
        handleDateChange(dateText, "end_date");
        $(this).val(dateText);
      },
    });
  });

  const handleDateChange = (date, name) => {
    console.log("Selected date: ", date);
    const [day, month, year] = date.split("/");
    const formattedDate = `${year}-${month}-${day}`;

    setEvent((prev) => {
      return { ...prev, [name]: formattedDate };
    });
  };  
  /*  END J-Query Date  */

  //----- Added by sarfraj | 04-Nov-2024 ------
  const formatDateForPicker = (dateString) => {
    if (!dateString) return "";
    const parts = dateString.includes("-") ? dateString.split("-") : [];
    if (parts.length === 3) {
      if (parts[0].length === 4) {
        const [y, m, d] = parts;
        return `${d}/${m}/${y}`;
      } else {
        const [d, m, y] = parts;
        return `${d}/${m}/${y}`;
      }
    }
    return "";
  };

  useEffect(() => {
    if (location.state) {
      setBtnName("Update");
    }
    // set date in date's input field while Update record
    $('#event-start-id').val(formatDateForPicker(event?.start_date))
    $('#event-end-id').val(formatDateForPicker(event?.end_date))
  }, [location.state]);

  const handleChange = (e) => {
    setEvent({ ...event, [e.target.name]: e.target.value });
    console.log("setEvent =======>", event);
  };

  const handleSubmit = async () => {
    if (
      event.title &&
      event.event_type &&
      event.start_date &&
      event.start_time &&
      event.end_date &&
      event.end_time
    ) {
      if (event.start_date === event.end_date) {
        if (event.start_date > event.end_date) {
          return toast.error("Start date must be before end date.");
        }

        if (event.start_time >= event.end_time) {
          return toast.error("End time must be after start time.");
        }
      }

      if (location.state && location.state.id) {
        console.log("enter with id =====>", location.state.id);
        console.log("event =====>", event);
        const updatedEventResult = await schoolApi.updateEvent(
          location.state.id,
          event
        );
        if (updatedEventResult) {
          toast.success("Event update successfully");
          setTimeout(() => {
            setEvent({
              title: "",
              event_type: "",
              start_date: "",
              start_time: "",
              end_date: "",
              end_time: "",
              description: "",
              colorcode: "",
            });
            navigate("/eventscalender");
          }, 2000);
        }
      } else {
        const eventResult = await schoolApi.createEvent(event);
        if (eventResult) {
          toast.success("Event created successfully");

          setTimeout(() => {
            setEvent({
              title: "",
              event_type: "",
              start_date: "",
              start_time: "",
              end_date: "",
              end_time: "",
              description: "",
              colorcode: "",
            });
            navigate("/eventscalender");
          }, 2000);
        }
      }
    } else {
      toast.error("Please fill all required fields");
    }
  };

  const handleColour = (color) => {
    setEvent({ ...event, colorcode: color.hex });
  };

  console.log("events after colors", event);

  const handleCancel = () => {
    // setEvent({
    //   title: '',
    //   event_type: '',
    //   start_date: '',
    //   start_time: '',
    //   end_date: '',
    //   end_time: '',
    //   description: '',
    // });
    navigate("/eventscalender");
  };

  const twitterStyle = {
    default: {
      input: {
        display: "none",
      },
      hash: {
        display: "none",
      },
    },
  };

  const handleColorPickerClick = () => {
    setShowColorPicker(!showColorPicker);
  };

  return (
    <>
     <Helmet>
    <title>{props?.tabName}</title>
  </Helmet>
      <Container className="view-form">
        <Row>
          <Col></Col>
          <Col lg={8}>
            <Form className="mt-3" onSubmit={handleSubmit}>
              <Row className="view-form-header align-items-center">
                <Col lg={3}>Event</Col>
                <Col lg={9} className="d-flex justify-content-end">
                  <Button className="btn-sm mx-2" onClick={handleSubmit}>
                    {btnName}
                  </Button>
                  <ToastContainer
                    position="top-right"
                    autoClose={2000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                    theme="light"
                  />
                  <Button
                    className="btn-sm"
                    variant="danger"
                    onClick={handleCancel}
                  >
                    Cancel
                  </Button>
                </Col>
              </Row>
              <Row>
                <Row>
                  <Col lg={6}>
                    <Form.Group className="mx-3">
                      <Form.Label
                        className="form-view-label"
                        htmlFor="formBasicTitle"
                      >
                        Title
                      </Form.Label>
                      <Form.Control
                        required
                        type="text"
                        name="title"
                        placeholder="Enter Title Name"
                        value={event.title}
                        onChange={handleChange}
                      />
                    </Form.Group>
                  </Col>
                  <Col lg={6}>
                    <Form.Group className="mx-3">
                      <Form.Label
                        className="form-view-label"
                        htmlFor="formBasicEventType"
                      >
                        Event Type
                      </Form.Label>
                      <Form.Control
                        required
                        type="text"
                        name="event_type"
                        placeholder="Enter Event Type"
                        value={event.event_type}
                        onChange={handleChange}
                      />
                    </Form.Group>
                  </Col>
                  <Col lg={6}>
                    <Form.Group className="mx-3">
                      <Form.Label className="form-view-label" htmlFor="formBasicStartDate">Start Date</Form.Label>
                      <Form.Control
                        required
                        type="text"
                        id="event-start-id"
                        name="event_start_date"
                        readOnly
                        autoComplete="off"
                        placeholder="Select Date"
                        style={{ padding: "8px" }}
                      />
                    </Form.Group>
                  </Col>
                  <Col lg={6}>
                    <Form.Group className="mx-3">
                      <Form.Label className="form-view-label" htmlFor="formBasicStartTime">End Date</Form.Label>
                      <Form.Control
                        required
                        type="text"
                        id="event-end-id"
                        name="event_end_date"
                        readOnly
                        autoComplete="off"
                        placeholder="Select Date"
                        style={{ padding: "8px" }}
                      />
                    </Form.Group>
                  </Col>
                  <Col lg={6}>
                    <Form.Group className="mx-3">
                      <Form.Label
                        className="form-view-label"
                        htmlFor="formBasicStartTime"
                      >
                        Start Time
                      </Form.Label>
                      <Form.Control
                        required
                        type="time"
                        name="start_time"
                        value={event.start_time}
                        onChange={handleChange}
                      />
                    </Form.Group>
                  </Col>
                  <Col lg={6}>
                    <Form.Group className="mx-3">
                      <Form.Label
                        className="form-view-label"
                        htmlFor="formBasicEndTime"
                      >
                        End Time
                      </Form.Label>
                      <Form.Control
                        required
                        type="time"
                        name="end_time"
                        placeholder="Enter End Time"
                        value={event.end_time}
                        onChange={handleChange}
                      />
                    </Form.Group>
                  </Col>
                  <Col lg={12}>
                    <Form.Group className="mx-3">
                      <Form.Label
                        className="form-view-label"
                        htmlFor="formBasicDescription"
                      >
                        Description
                      </Form.Label>
                      <Form.Control
                        as="textarea"
                        name="description"
                        placeholder="Enter Description"
                        value={event.description}
                        onChange={handleChange}
                      />
                    </Form.Group>
                  </Col>
                  <Col lg={12}>
                    <Form.Group className="mx-3">
                      <Form.Label
                        className="form-view-label"
                        htmlFor="formBasicEventColor"
                      >
                        Select Event Color
                      </Form.Label>
                      <div
                        className="color-picker-input"
                        onClick={handleColorPickerClick}
                      >
                        <Form.Control
                          type="text"
                          name="colorcode"
                          placeholder="Select Event Color"
                          value={event.colorcode}
                          style={{ height: "48px" }}
                        />
                        {showColorPicker && (
                          <TwitterPicker
                            colors={[
                              "#B80000",
                              "#DB3E00",
                              "#FCCB00",
                              "#008B02",
                              "#006B76",
                              "#1273DE",
                              "#13F6C6",
                              "#30EEA0",
                              "#CF16F0",
                              "#28D2F4",
                              "#16F17D",
                              "#A0A223",
                              "#BD6D0E",
                              "#B52AED",
                              "#8895C7",
                              "#4D5F11",
                              "#904A4A",
                              "#F48E8E",
                              "#B0BC00",
                              "#68BC00",
                              "#16A5A5",
                              "#009CE0",
                              "#7B64FF",
                              "#FA28FF",
                              "#000000",
                              "#666666",
                              "#B3B3B3",
                              "#9F0500",
                              "#C45100",
                              "#FB9E00",
                              "#808900",
                              "#194D33",
                              "#0C797D",
                              "#0062B1",
                              "#653294",
                              "#AB149E",
                            ]}
                            color={event.colorcode}
                            onChange={handleColour}
                            styles={twitterStyle}
                          />
                        )}
                      </div>
                    </Form.Group>
                  </Col>
                </Row>
              </Row>
            </Form>
          </Col>
          <Col></Col>
        </Row>
      </Container>
    </>
  );
};

export default AddEvent;
