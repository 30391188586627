//-------Last updated by shakib----- on 23-07-2024 -----------

import React, { useEffect, useState } from "react";
import schoolApi from "../../api/schoolApi";

import { Button, Col, Row, Table } from "react-bootstrap";
import { DatatableWrapper, TableBody, TableHeader } from "react-bs-datatable";
import { ShimmerTable } from "react-shimmer-effects";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import moment from "moment";

const FeeDuesShow = () => {
  const [sessionOptions, setSessionOptions] = useState([]);
  const [currentSessionYear, setCurrentSessionYear] = useState();
  const [reportData, setReportData] = useState({ classid: "", session_id: "" });
  const [feeDueData, setFeeDueData] = useState([]);
  const [recordsToShow, setRecordsToShow] = useState(5);
  const [settings, setSettings] = useState({});
  const [netAmount, setNetAmount] = useState({
    total_amount: 0,
    total_due_fees: 0,
    total_others_amount: 0,
  });
  const navigate = useNavigate();

  useEffect(() => {
    const currentDate = new Date();
    const formattedDate = `${currentDate.getFullYear()}-${
      currentDate.getFullYear() + 1
    }`;
    setCurrentSessionYear(formattedDate);
  }, []);

  useEffect(() => {
    const fetchSessions = async () => {
      try {
        const ResultSettings = await schoolApi.getAllSetting();
        console.log("ResultSettings-->", ResultSettings);
        if (ResultSettings.success) {
          settings.frequency = ResultSettings?.data?.filter(
            (res) => res.key === "frequency"
          )[0].value;
          settings.late_fee = ResultSettings?.data?.filter(
            (res) => res.key === "late_fee"
          )[0].value;
        }

        const sessions = await schoolApi.fetchSessions();
        console.log("sessions home ---->>", sessions);
        console.log("current year ====>>>", currentSessionYear);
        if (sessions) {
          const currentSession = sessions.find(
            (session) => session.year === currentSessionYear
          );
          if (currentSession) {
            setReportData((prevData) => ({
              ...prevData,
              session_id: currentSession.id,
            }));

            const options = sessions.map((sess) => ({
              value: sess.id,
              label: sess.year,
            }));
            setSessionOptions(options);
          }
        }
      } catch (error) {
        console.error("Error fetching sessions:", error);
      }
    };

    if (currentSessionYear) {
      fetchSessions();
    }
  }, [currentSessionYear]);

  useEffect(() => {
    const fetchFeeData = async () => {
      try {
        const result = await schoolApi.fetchDueFees(
          reportData.classid,
          reportData.session_id
        );
        console.log("result uivujhefuifgi======>", result.result);
        if (result.success) {
          filterData(result.result);
        }
        // else {
        //     return toast.error(result.message);
        // }
      } catch (error) {
        console.error("Error fetching fee data:", error);
        setFeeDueData([]);
      }
    };

    if (reportData.session_id) {
      fetchFeeData();
    }
  }, [reportData]);
  const formatNumberWithCommas = (number) => {
    return number ? number.toLocaleString("en-IN") : "0";
  };
  const filterData = (result) => {
    console.log("result on filterdata-->", result);
    let data = [];
    let total_due_fees = 0;
    let total_others_amount = 0;
    let total_amount = 0;

    result?.forEach((item) => {
      let due_amount = 0;
      let others = 0;
      let student_total_amount = 0;
      const currentDate = moment();

      item?.installments?.forEach((inst) => {
        console.log("inst-step-1->", inst);
        if (
          inst.status === "pending" &&
          moment(inst.due_date).isSameOrBefore(currentDate)
        ) {
          console.log("inst-step-2->", inst);
          due_amount += inst.installmentamount;
          const daysOverdue = currentDate.diff(moment(inst.due_date), "days");
          const lateFee =
            Math.round(
              (daysOverdue + parseInt(settings.frequency)) /
                parseInt(settings.frequency)
            ) * parseInt(settings.late_fee);

          others += lateFee;

          console.log("inst-step-3->", due_amount);
          console.log("inst-step-4->", daysOverdue);
        }
      });

      student_total_amount = due_amount + others;

      if (due_amount > 0) {
        total_due_fees += due_amount;
        total_others_amount += others;
        // total_amount += student_total_amount; // commented by mohd. sarfraj | 08-oct-2024.
        total_amount = student_total_amount;

        data.push({
          studentname: item.student_name,
          class: item.classname,
          section_name: item.section_name,
          due_amount: formatNumberWithCommas(due_amount),
          others: formatNumberWithCommas(others),
          total_amount: formatNumberWithCommas(total_amount),
          due_date: item.installments[0]?.due_date,
          status: due_amount > 0 ? "Overdue" : "Pending",
          session_id: item.sessionid,
          student_addmission_id: item.student_addmission_id,
          email: item.email,
          dateofbirth: item.dateofbirth,
          total_fees: item.total_fees,
          phone: item.phone,
          category: item.category,
        });
      }
    });

    setFeeDueData(data);
    setNetAmount({
      total_amount: formatNumberWithCommas(total_amount),
      total_due_fees: formatNumberWithCommas(total_due_fees),
      total_others_amount: formatNumberWithCommas(total_others_amount),
    });
  };

  const header = [
    {
      title: "Name",
      prop: "studentname",
      isFilterable: true,
      show: true,
      cell: (row) => (
        <Link to={"/feedepositeedit/e"} state={{ row }}>
          {row.studentname}
        </Link>
      ),
    },
    {
      title: "Class",
      prop: "class",
      isFilterable: true,
      show: true,
      cell: (row) => (
        <>
          {row.class} {row.section_name && ` (${row.section_name})`}
        </>
      ),
    },
    { title: "Due Fees", prop: "due_amount", isFilterable: true, show: true },
    { title: "Late Fee", prop: "others", isFilterable: true, show: true },
    { title: "Total", prop: "total_amount", isFilterable: true, show: true },
    {
      title: "Due Date",
      prop: "due_date",
      isFilterable: true,
      show: true,
      cell: (row) => <>{moment(row.due_date).format("DD-MM-YYYY")}</>,
    },
    { title: "Status", prop: "status", isFilterable: true, show: true },
  ];

  return (
    <div>
      <Row className="g-0">
        <Col lg={12} className="">
          {feeDueData.length > 0 ? (
            <DatatableWrapper
              body={feeDueData.slice(0, recordsToShow)}
              headers={header}
              paginationOptionsProps={{
                initialState: {
                  rowsPerPage: 15,
                  options: [5, 10, 15, 20],
                },
              }}
            >
              <Table striped className="data-table">
                <TableHeader />
                <TableBody />
              </Table>
              <div className="d-flex justify-content-end">
                <Link to="/duefeereport" state={reportData}>
                  View more records
                </Link>
              </div>
            </DatatableWrapper>
          ) : (
            <DatatableWrapper
              body={feeDueData.slice(0, recordsToShow)}
              headers={header}
              paginationOptionsProps={{
                initialState: {
                  rowsPerPage: 15,
                  options: [5, 10, 15, 20],
                },
              }}
            >
              <Table striped className="data-table">
                <TableHeader />
                <TableBody />
              </Table>
              <div className="d-flex justify-content-end">
                {feeDueData.length > 0 && (
                  <Link to="/duefeereport" state={reportData}>
                    View more records
                  </Link>
                )}
              </div>
            </DatatableWrapper>
          )}
        </Col>
        <Col lg={2}></Col>
      </Row>
    </div>
  );
};

export default FeeDuesShow;
