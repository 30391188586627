import React, { useEffect, useState } from 'react';
import { Modal, Button, Form, Container, Row, Col } from 'react-bootstrap';
import Select from 'react-select';
import schoolApi from '../../api/schoolApi';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const VehicleAdd = ({ show, onHide, onNewRecordCreated }) => {

    const initialFormData = {
        driver_id: '',
        vehicle_no: '',
        type: '',
        seating_capacity: '',
        status: null,
        end_point: null,
    };
    const [formData, setFormData] = useState(initialFormData);
    const [successMessage, setSuccessMessage] = useState(null);
    const [driverOptions, setDriverOptions] = useState([]);
    const [locationOptions, setLocationOptions] = useState([]);
    const [error, setError] = useState(null);

    const handleSubmit = async (e) => {
        try {
            console.log("Create Vehicle == ",formData)
            e.preventDefault();
            if (!formData.type || !formData.vehicle_no) {
                toast.error('Missing required fields');
                return;
            }
            const result = await schoolApi.createVehicle(formData);
            console.log("vehicle result == ", result);
            console.log("vehicle msg == ", result.message);

            if (result.success) {
                onHide();
                onNewRecordCreated();
                setFormData(initialFormData);
                 return toast.success(result.message);
            } 
        } catch (error) {
            console.log("error == ",error)
            return toast.error(error.response.data.message)
        }
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    useEffect(() => {
        async function fetchDrivers() {
            try {
                const drivers = await schoolApi.fetchDrivers();
                console.log("fetchDrivers == ",drivers)
                if(drivers.success){
                    const driverOptions = drivers?.drivers?.map((driver) => ({
                        value: driver.id,
                        label: driver.staffname,
                    }));
                    setDriverOptions(driverOptions);
                    driverOptions.unshift({ value: null, label: 'Select Driver' });
                }
            } catch (error) {
                //console.error('Error fetching drivers:', error);
                setDriverOptions([]);
              //  return toast.error(error.response.data.message)
            }
        }

        fetchDrivers();
    }, []);

    useEffect(() => {
        async function fetchLocations() {
            try {
                const locations = await schoolApi.getAllLocation();
                console.log("locations == ",locations)
                if(locations.success){
                    const locationOptions = locations.result.map((location) => ({
                        value: location.id,
                        label: location.location,
                    }));
                    setLocationOptions(locationOptions);
                    locationOptions.unshift({ value: null, label: 'Select End Point' });
                }
            } catch (error) {
                setLocationOptions([]);
            }
        }

        fetchLocations();
    }, []);

    return (
        <>
            <Modal show={show} aria-labelledby="contained-modal-title-vcenter" centered onHide={() => {
                onHide();
                setFormData(initialFormData);
            }}>
                <Modal.Header closeButton>
                    <Modal.Title>New Vehicle</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Container className="view-form">
                        <Form onSubmit={handleSubmit}>
                            <Row name="row1">
                                <Col lg={6}>
                                    <Form.Group>
                                        <Form.Label>Vehicle Type</Form.Label>
                                        <Form.Select
                                            required
                                            name="type"
                                            value={formData.type}
                                            onChange={handleChange}
                                        >
                                            <option value="">-- Select Vehical Type--</option>
                                            <option value="bus">Bus</option>
                                            <option value="van">Van</option>
                                        </Form.Select>
                                    </Form.Group>
                                </Col>
                                <Col lg={6}>
                                    <Form.Group>
                                        <Form.Label>Vehicle Driver</Form.Label>
                                        <Form.Select
                                            placeholder="Select Driver"
                                            name="driver_id"
                                            onChange={handleChange}
                                            value={formData.driver_id}>
                                            {driverOptions.map((res) => (
                                                <option key={res.value} value={res.value}>
                                                    {res.label}
                                                </option>
                                            ))}

                                        </Form.Select>

                                    </Form.Group>
                                </Col>
                            </Row>

                            <Row name="row2">
                                <Col lg={6}>
                                    <Form.Group>
                                        <Form.Label htmlFor="formBasicVehicleNumber">Vehicle Number</Form.Label>
                                        <Form.Control
                                            required
                                            type="text"
                                            name="vehicle_no"
                                            placeholder="Enter Vehicle Number"
                                            value={formData.vehicle_no}
                                            onChange={handleChange}
                                        />
                                    </Form.Group>

                                </Col>
                                <Col lg={6}>
                                    <Form.Group>
                                        <Form.Label>End Point</Form.Label>
                                        <Form.Select
                                            placeholder="Select End point"
                                            name="end_point"
                                            onChange={handleChange}
                                            value={formData.end_point}>
                                            {/* <option value="">-- Select end point--</option> */}
                                            {locationOptions.map((res) => (
                                                <option key={res.value} value={res.value}>
                                                    {res.label}
                                                </option>
                                            ))}

                                        </Form.Select>

                                    </Form.Group>
                                </Col>
                            </Row>

                            <Row name="row3">
                                <Col lg={6}>
                                    <Form.Group>
                                        <Form.Label>Vehicle Capacity</Form.Label>
                                        <Form.Control
                                            type="number"
                                            name="seating_capacity"
                                            placeholder="Vehicle Capacity"
                                            value={formData.seating_capacity}
                                            onChange={handleChange}
                                        />
                                    </Form.Group>
                                </Col>
                                <Col lg={6}>
                                    <Form.Group>
                                    <Form.Label>Status</Form.Label>
                                    
                                    <Form.Select
                                        name="Status"
                                        value={formData.status}
                                        onChange={(e) =>
                                            setFormData({ ...formData, status: e.target.value })
                                        }
                                        // required
                                    >
                                        <option value="">--select status--</option>
                                        <option value="Active">Active</option>
                                        <option value="Inactive">InActive</option>
                                    </Form.Select>
                                        {/* <Form.Label>Status</Form.Label>
                                        <Row lg={8}>
                                            <Col lg={4}>
                                                <Form.Check
                                                    inline
                                                    type="radio"
                                                    label="Active"
                                                    name="status"
                                                    value="Active"
                                                    checked={formData.status === 'Active'}
                                                    onChange={() => setFormData({ ...formData, status: 'Active' })}
                                                    required
                                                />
                                            </Col>
                                            <Col lg={4}>
                                                <Form.Check
                                                    inline
                                                    type="radio"
                                                    label="Inactive"
                                                    name="status"
                                                    value="Inactive"
                                                    checked={formData.status === 'Inactive'}
                                                    onChange={() => setFormData({ ...formData, status: 'Inactive' })}
                                                    required
                                                />
                                            </Col>
                                        </Row> */}
                                    </Form.Group>
                                </Col>
                            </Row>
                        </Form>
                    </Container>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => {
                        onHide();
                        setFormData(initialFormData);
                    }}>
                        Cancel
                    </Button>
                    <Button variant="primary" type="submit" onClick={handleSubmit}>
                        Save
                    </Button>
                </Modal.Footer>
            </Modal>
            {/* <ToastContainer /> */}
        </>
    );
};

export default VehicleAdd;