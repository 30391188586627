import React, { useState, useEffect } from "react";
import { Table, Button, Row, Col, Modal } from "react-bootstrap";
import schoolApi from "../../api/schoolApi";
import Badge from "react-bootstrap/Badge";
import {
  DatatableWrapper,
  Pagination,
  TableBody,
  TableHeader,
} from "react-bs-datatable";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Helmet } from "react-helmet"; //added by muskan khan
function Attendance({ studentId, sessionId }, props) {
  console.log("studentId", studentId);

  const [session, setSession] = useState();
  const [attendanceRecord, setAttendanceRecord] = useState({});
  const [existingAttendance, setExistingAttendance] = useState({});
  const [showModal, setShowModal] = useState(false);
  const [attendancemode, setattendancemode] = useState("monthly");
  const headerAtt = [
    { title: "Month", prop: "month", isFilterable: true },
    { title: "Total Lecture", prop: "total_lectures", isFilterable: true },
    { title: "Total Present", prop: "total_present" },
    { title: "Total Absent", prop: "total_absent" },
    ...(attendancemode !== "monthly"
      ? [
          {
            title: "Actions",
            prop: "id",
            cell: (id) => (
              <Button
                className="btn-sm mx-2 btnHover"
                variant="primary"
                onClick={() => handleMonthlyAttendance(id)}
              >
                View
              </Button>
            ),
          },
        ]
      : []),
  ];

  useEffect(() => {
    const init = async () => {
      try {
        const ResultSettings = await schoolApi.getAllSetting();
        console.log("ResultSettings-->", ResultSettings.data);
        const attendancemode = ResultSettings?.data?.filter(
          (res) => res.key === "Attendentce_Mode"
        )[0].value;
        setattendancemode(attendancemode);
        fetchAttendance(attendancemode);
      } catch (error) {
        // toast.error(error.message);
      }
    };
    init();
  }, []);

  //add by shahir husssain 09-10-2024
  const fetchAttendance = async (attendancemode) => {
    console.log("studentId ->", studentId);

    try {
      let result;
      result = await schoolApi.fetchAttendanceFilterByData(
        studentId,
        sessionId
      );

      if (result.success) {
        if (attendancemode === "monthly") {
          console.log("monthly == ", result);
          setAttendanceRecord(result.result);
        } else {
          console.log("daily");
          // setAttendanceRecord(result.result.monthly_attendance);
          console.log("daily == ", result.result.daily_attendance);
          setAttendanceRecord(result.result.daily_attendance);
        }
        console.log("result.rows ->", result);
      }
    } catch (error) {
      console.log("error");
      //toast.error(error?.response?.data?.message);
      setAttendanceRecord({});
    }
  };

  const handleMonthlyAttendance = (month) => {
    const monthAttendance = attendanceRecord[month];
    if (monthAttendance) {
      setExistingAttendance(monthAttendance);
      setShowModal(true);
    }
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const formatDate = (date) => {
    const d = new Date(date);
    let month = (d.getMonth() + 1).toString().padStart(2, "0");
    let day = d.getDate().toString().padStart(2, "0");
    return `${day}-${month}-${d.getFullYear()}`;
  };

  return (
    <div>
      <Helmet>
        <title>{props?.tabName}</title>
      </Helmet>
      <ToastContainer
        position="top-right"
        autoClose={2000}
        hideProgressBar={false}
      />
      <Modal
        show={showModal}
        backdrop="static"
        centered
        onHide={handleCloseModal}
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>Attendance Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row className="mt-3">
            {existingAttendance.attendance?.map((item) => (
              <Col key={item.id} lg={6}>
                <h5>{formatDate(item.attdate)}</h5>
                <div>
                  <strong>Status:</strong>{" "}
                  <Badge bg={item.status === "present" ? "success" : "danger"}>
                    {item.status.charAt(0).toUpperCase() + item.status.slice(1)}
                  </Badge>
                </div>
              </Col>
            ))}
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={handleCloseModal}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      <Row className="mt-2">
        <Col lg={12}>
          <DatatableWrapper
            body={Object.keys(attendanceRecord).map((month) => ({
              month,
              ...attendanceRecord[month],
            }))}
            headers={headerAtt}
          >
            <Table striped className="data-table custom-table-subject-list">
              <TableHeader />
              <TableBody>
                {Object.keys(attendanceRecord).length === 0 ? (
                  <tr>
                    <td colSpan={5} className="text-center">
                      No record found
                    </td>
                  </tr>
                ) : attendancemode === "monthly" ? (
                  Object.keys(attendanceRecord).map((month) => (
                    <tr key={month}>
                      <td>{attendanceRecord[month].month}</td>
                      <td>{attendanceRecord[month].total_lectures ?? 0}</td>
                      <td>{attendanceRecord[month].total_present ?? 0}</td>
                      <td>{attendanceRecord[month].total_absent ?? 0}</td>
                    </tr>
                  ))
                ) : (
                  Object.keys(attendanceRecord).map((month) => (
                    <tr key={month}>
                      <td>{month}</td>
                      <td>{attendanceRecord[month].total_lectures ?? 0}</td>
                      <td>{attendanceRecord[month].total_present ?? 0}</td>
                      <td>{attendanceRecord[month].total_absent ?? 0}</td>
                      <td>
                        <Button
                          className="btn-sm mx-2 btnHover"
                          variant="primary"
                          onClick={() => handleMonthlyAttendance(month)}
                        >
                          View
                        </Button>
                      </td>
                    </tr>
                  ))
                )}
              </TableBody>
            </Table>
            <Pagination />
          </DatatableWrapper>
        </Col>
      </Row>
    </div>
  );
}

export default Attendance;
