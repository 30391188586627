import React, { useEffect, useState } from "react";
import { Button, Col, Row, Table } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import schoolApi from "../../api/schoolApi";
import { ShimmerTable } from "react-shimmer-effects";
import InfoPill from "../InfoPill";
import {
  DatatableWrapper,
  Filter,
  Pagination,
  PaginationOptions,
  TableBody,
  TableHeader,
} from "react-bs-datatable";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import { usePermissions } from "../contexts/PermissionContext";
import moment from "moment";
import { ToastContainer, toast } from "react-toastify";

const ExamSchedule = (props) => {
  const { rolePermissions, userInfo } = usePermissions();
  const navigate = useNavigate();
  const [body, setBody] = useState([]);
  const [loading, setLoading] = useState(true);

  const canCreate = rolePermissions?.Exam_Schedule?.can_create;
  // const canEdit = rolePermissions?.Exam_Schedule?.can_edit;
  // const canDelete = rolePermissions?.Exam_Schedule?.can_delete;

  useEffect(() => {
    async function fetchExamSchedules() {
      try {
        const result = await schoolApi.fetchExamSchedules();
        if (result.success) {
          console.log("DSFDSF")
          result.examSchedules.forEach((element) => {
            element.schedule_date = moment(
              element.schedule_date,
              "YYYY-MM-DD"
            ).format("DD-MM-YYYY");
          });
          setBody(result?.examSchedules);
        } else {
          setBody([]);
        }
      } catch (err) {
        console.error("Failed to fetch exam schedules:", err);
        toast.error(
          "Internal Server Error."
        );
      } finally {
        setLoading(false);
      }
    }
    fetchExamSchedules();
  }, []);

  const header = [
    {
      title: "Subject",
      prop: "subject_name",
      isFilterable: true,
      show: true,
      cell: (row) => (
        <Link to={"/examscheduleview/" + row.id}>{row.subject_name}</Link>
      ),
    },
    {
      title: "Class",
      prop: "class_name",
      isFilterable: true,
      show: true,
    },
    {
      title: "Scheduled Date",
      prop: "schedule_date",
      isFilterable: true,
      show: true,
    },
    {
      title: "Exam Title",
      prop: "exam_title_name",
      isFilterable: true,
      show: true,
    },
    {
      title: "Duration",
      prop: "duration",
      isFilterable: true,
      show: true,
    },
    {
      title: "Room No",
      prop: "room_no",
      isFilterable: true,
      show: true,
    },
    {
      title: "Examinor",
      prop: "examinor_info",
      isFilterable: true,
      show: true,
    },
    {
      title: "Status",
      prop: "status",
      isFilterable: true,
      show: true,
    },
  ];
  const visibleHeader = header?.filter((col) => col.show);

  const labels = {
    beforeSelect: " ",
  };

  const scheduleNewExam = () => {
    navigate("/examschedule/e");
  };

  return (
    <>
      <Helmet>
        <title>{props?.tabName}</title>
      </Helmet>
      <Col lg={2} className="mx-3">
        <Link className="nav-link mx-2" to="/">
          Home <i className="fa-solid fa-chevron-right"></i> ExamSchedule
        </Link>
      </Col>
      <Row className="g-0">
        <Col lg={12} className="p-lg-4">
          {loading ? (
            <ShimmerTable row={10} col={8} />
          ) : (
            <>
              <DatatableWrapper
                body={body}
                headers={visibleHeader}
                paginationOptionsProps={{
                  initialState: {
                    rowsPerPage: 15,
                    options: [5, 10, 15, 20],
                  },
                }}
              >
                <Row className="mb-4">
                  <Col lg={3}>
                    <Filter />
                  </Col>
                  <Col lg={1} style={{ marginTop: "-18px" }}>
                    <PaginationOptions labels={labels} />
                  </Col>
                  <Col lg={3} style={{ marginTop: "-13px" }}>
                    <div>
                      <InfoPill left="Total Exams" right={body?.length} />
                    </div>
                  </Col>
                  <Col
                    lg={5}
                    style={{ marginTop: "2px" }}
                    className="d-flex flex-col justify-content-end align-items-end"
                  >
                    {(userInfo?.userrole === "SYS_ADMIN" ||
                      userInfo?.userrole === "ADMIN" ||
                      canCreate) && (
                      <Button
                        className="btn-light add-new-button"
                        style={{ color: "black" }}
                        variant="outline-primary"
                        onClick={scheduleNewExam}
                      >
                        Schedule Exam
                      </Button>
                    )}
                  </Col>
                </Row>
                <Table striped className="data-table">
                  <TableHeader />
                  <TableBody />
                </Table>
                <Pagination />
              </DatatableWrapper>
              <ToastContainer
                position="top-right"
                autoClose={2000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
              />
            </>
          )}
        </Col>
        <Col lg={2}></Col>
      </Row>
    </>
  );
};

export default ExamSchedule;

