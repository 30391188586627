//Role Permission List Change by Shahir Hussain 22-04-2024
import React, { useEffect, useState } from "react";
import { Button, Col, Row, Table, Form, Accordion } from "react-bootstrap";
import { Link } from "react-router-dom";
import { DatatableWrapper, TableBody, TableHeader } from "react-bs-datatable";
import { ShimmerTable } from "react-shimmer-effects";
import schoolApi from "../../api/schoolApi";
import CreateRolePermissionModal from "./CreateRolePermissionModal";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import authApi from "../../api/authApi";
import jwtDecode from "jwt-decode";
import { Helmet } from "react-helmet";

const RolePermissionList = (props) => {
  const [modalShow, setModalShow] = useState(false);
  const [btnName, setBtnName] = useState("Save");
  const [rolePermissionData, setRolePermissionData] = useState();
  const [rolePermissions, setRolePermissions] = useState([]);
  const [upsertRolePermissions, setUpsertRolePermissions] = useState([]);
  const [isLoading, setIsLoading] = useState(true); 
  const [role, setRole] = useState();
  const [isAdminUser , setIsAdminUser] = useState(false);
  const [userInfo , setUserInfo] = useState();

  const [tabCheckBox , setTabCheckBox] = useState({
          "read_all" : null,
          "create_all" : null,
          "edit_all" : null,
          "delete_all" : null,
          "select_all_box" : null,
        });

  useEffect(() => {
    fetchAllRoles();
    const token = sessionStorage.getItem("token");
    setUserInfo(jwtDecode(token));
  }, []);

  async function fetchAllRoles() {
    try {
      if (await authApi.companyDetail().companyid) {
        const rolePermissions = await schoolApi.getRolePermission(await authApi.companyDetail().companyid);
        setIsLoading(false);
        if(rolePermissions.success){
          setRolePermissions(rolePermissions?.result?.filter((rolePerission) => rolePerission.name !== "SYS_ADMIN"));
        } else {
          setRolePermissions([]);
          toast.error(rolePermissions)
        }
      }
    } catch (error) {
     console.log('SERERV-ERROR-->>' , error);
      toast.error(error.response.data.message,{
        className: 'toast-error'
      });
    }
  }

  useEffect(() => {

  }, [upsertRolePermissions]);

  //updated by Abdul Sir 19-04-2024
  //Change by shahir hussain 27-05-2024
  const handleCheckBox = (columnName, row) => (e) => {
    const checked = e.target.checked;

    if (columnName === 'select_all_box') {  
      setTabCheckBox({
        "read_all": checked,
        "create_all": checked,
        "edit_all": checked,
        "delete_all": checked,
        "select_all_box": checked,
      });
  
      setRolePermissions(oldRolePermissions => {
          const updatedRolePermissions = oldRolePermissions.map(record => {
              if (record.id === row.roleid) {
                  const updatedPermissions = record.permissions.map(item => ({
                      ...item,
                      can_read: checked,
                      can_create: checked,
                      can_edit: checked,
                      can_delete: checked
                  }));
                  
                  setUpsertRolePermissions(updatedPermissions);
                  return { ...record, permissions: updatedPermissions };
              }
              return record;
          });
          
          return updatedRolePermissions;
      });
    }    
    else if (columnName === 'read_all' || columnName === 'create_all' || columnName === 'edit_all' || columnName === 'delete_all') {
      
      /* this condition used only for UI , it is not affect any impact on back-end */
      if(columnName === 'read_all') setTabCheckBox((prev)=> ({...prev , read_all : checked}))
      else if(columnName === 'create_all') setTabCheckBox((prev)=> ({...prev , create_all : checked}))
      else if(columnName === 'edit_all') setTabCheckBox((prev)=> ({...prev , edit_all : checked}))
      else setTabCheckBox((prev)=> ({...prev , delete_all : checked}))

      setRolePermissions((oldRolePermissions) => {
        const updatedRolePermissions = [...oldRolePermissions];
        const roleData = updatedRolePermissions.find((record) => record.id === row.roleid);
        const rolePermission = roleData.permissions.map(item => {
          if (columnName === 'read_all') item.can_read = e.target.checked;
          else if (columnName === 'create_all') item.can_create = e.target.checked;
          else if (columnName === 'edit_all') item.can_edit = e.target.checked;
          else if (columnName === 'delete_all') item.can_delete = e.target.checked;
        })

        setUpsertRolePermissions(roleData.permissions);
        return updatedRolePermissions;
      })
    } else {
      setRolePermissions((oldRolePermissions) => {
        const updatedRolePermissions = [...oldRolePermissions];
        const roleData = updatedRolePermissions.find((record) => record.id === row.roleid);
        const rolePermission = roleData.permissions.find((perm) => perm.moduleid === row.moduleid);
        
        if (!rolePermission) return updatedRolePermissions; 
    
        if (['can_read', 'can_create', 'can_edit', 'can_delete'].includes(columnName)) {
          rolePermission[columnName] = checked;
    
          if (['can_create', 'can_edit', 'can_delete'].includes(columnName) && checked) {
            rolePermission.can_read = true;
            if((roleData.permissions.every((perm) => perm.can_read === true))){
              setTabCheckBox((prev)=>({ ...prev, read_all : true}))
            }
          }
        }
    
        // Handle select_all logic
        if (columnName === 'select_all') {
          rolePermission.can_edit = checked;
          rolePermission.can_create = checked;
          rolePermission.can_read = checked;
          rolePermission.can_delete = checked;

          const isAllPemissions = roleData.permissions?.every((permission) => (permission.can_read === true && permission.can_create === true && permission.can_edit === true && permission.can_delete === true))
          console.log('isAllPemissions--->>>' , isAllPemissions);
          
          if(isAllPemissions){
            setTabCheckBox({
              "read_all" : true,
              "create_all" : true,
              "edit_all" : true,
              "delete_all" : true,
              "select_all_box" : true,
            })
          } else {
            setTabCheckBox({
              "read_all" : (roleData.permissions.every((perm) => perm.can_read === true)),
              "create_all" : (roleData.permissions.every((perm) => perm.can_create === true)),
              "edit_all" : (roleData.permissions.every((perm) => perm.can_edit === true)),
              "delete_all" : (roleData.permissions.every((perm) => perm.can_delete === true)),
            })
          }
        } 
    
        // Update select_all based on individual permissions
        rolePermission.select_all = ['can_edit', 'can_create', 'can_read', 'can_delete'].every(perm => rolePermission[perm]);
    
        roleData.permissions.every((perm) => perm.can_read === true) ? setTabCheckBox((prev) => ({ ...prev, read_all: true})) : setTabCheckBox((prev) => ({ ...prev, read_all: false}));
        roleData.permissions.every((perm) => perm.can_create === true) ? setTabCheckBox((prev) => ({ ...prev, create_all: true})) : setTabCheckBox((prev) => ({ ...prev, create_all: false}));
        roleData.permissions.every((perm) => perm.can_edit === true) ? setTabCheckBox((prev) => ({ ...prev, edit_all: true})) : setTabCheckBox((prev) => ({ ...prev, edit_all: false}));
        roleData.permissions.every((perm) => perm.can_delete === true) ? setTabCheckBox((prev) => ({ ...prev, delete_all: true})) : setTabCheckBox((prev) => ({ ...prev, delete_all: false}));

        // Update upsertRolePermissions
        const upsertIndex = upsertRolePermissions.findIndex(record => record.roleid === row.roleid && record.moduleid === row.moduleid);
        const newUpsertPermissions = upsertRolePermissions.slice();
        if (upsertIndex > -1) {
          newUpsertPermissions[upsertIndex] = rolePermission; // Update existing record
        } else {
          newUpsertPermissions.push(rolePermission); // Add new record
        }
    
        setUpsertRolePermissions(newUpsertPermissions);
        return updatedRolePermissions;
      });
    }
  };

  const header = [
    { title: "Module", prop: "module_name", isFilterable: true },
    {
      title: (
        <span>
          Read
          <Form.Check
            disabled={isAdminUser}
            type="checkbox"
            onChange={handleCheckBox("read_all", { roleid: role })}
            checked={
              tabCheckBox.read_all !== null ? tabCheckBox?.read_all
              : rolePermissions?.some((userRole) => 
                userRole?.id === role && userRole?.permissions?.every((permission) => permission?.can_read === true)
              )
            }
          />
        </span>
      ),
      prop: "can_read",
      isFilterable: true,
      cell: (row) => (
        row.can_read && (row?.can_create || row?.can_edit || row?.can_delete) 
        ? <Form.Check
          disabled={isAdminUser}
            type="checkbox"
            onChange={handleCheckBox("can_read", row)}
            checked={row.can_read || (row?.can_create || row?.can_edit || row?.can_delete)}
          /> 
        : <Form.Check
            disabled={isAdminUser}
            type="checkbox"
            onChange={handleCheckBox("can_read", row)}
            checked={row.can_read}
          /> 
      ),
    },
    {
      title: (
        <span>
          Create
          <Form.Check
            disabled={isAdminUser}
            type="checkbox"
            onChange={handleCheckBox("create_all", { roleid: role })}
            checked={
              tabCheckBox.create_all !== null ? tabCheckBox.create_all
              : rolePermissions.some((userRole) => 
                userRole?.id === role && userRole?.permissions?.every((permission) => permission?.can_create === true)
              )
            }
          />
        </span>
      ),
      prop: "can_create",
      isFilterable: true,
      cell: (row) => (
        <Form.Check
          disabled={isAdminUser}
          type="checkbox"
          onChange={handleCheckBox("can_create", row)}
          checked={row.can_create}
        />
      ),
    },
    {
      title: (
        <span>
          Edit
          <Form.Check
            disabled={isAdminUser}
            type="checkbox"
            onChange={handleCheckBox("edit_all", { roleid: role })}
            checked={
              tabCheckBox.edit_all !== null ? tabCheckBox.edit_all
              : rolePermissions?.some((userRole) => 
                userRole?.id === role && userRole?.permissions?.every((permission) => permission?.can_edit === true)
              )
            }
          />
        </span>
      ),
      prop: "can_edit",
      isFilterable: true,
      cell: (row) => (
        <Form.Check disabled={isAdminUser} type="checkbox" onChange={handleCheckBox("can_edit", row)} checked={row.can_edit} />
      ),
    },
    {
      title: (
        <>
          Delete
          <Form.Check
            disabled={isAdminUser}
            type="checkbox"
            onChange={handleCheckBox("delete_all", { roleid: role })}
            checked={
              tabCheckBox.delete_all !== null ? tabCheckBox.delete_all
              : rolePermissions?.some((userRole) => 
                userRole?.id === role && userRole?.permissions?.every((permission) => permission?.can_delete === true)
              )
            }
          />
        </>
      ),
      prop: "can_delete",
      isFilterable: true,
      cell: (row) => (
        <Form.Check
          disabled={isAdminUser}
          type="checkbox"
          onChange={handleCheckBox("can_delete", row)}
          checked={row.can_delete}
        />
      ),
    },
    {
      title: (
        <>
          Select All
          <Form.Check
            disabled={isAdminUser}
            type="checkbox"
            onChange={handleCheckBox("select_all_box", { roleid: role })}
            checked={
              (tabCheckBox.create_all && tabCheckBox.read_all && tabCheckBox.edit_all && tabCheckBox.delete_all) || 
              rolePermissions?.some((userRole) => 
                userRole?.id === role && userRole?.permissions?.every((permission) => 
                  permission?.can_read === true &&
                  permission?.can_create === true &&
                  permission?.can_edit === true &&
                  permission?.can_delete === true
                )
              )
            }
          />
        </>
      ), 
      prop: "select_all",
      isFilterable: true,
      cell: (row) => (
        <Form.Check
          disabled={isAdminUser}
          type="checkbox"
          onChange={handleCheckBox("select_all", row)}
          checked={row.can_create && row?.can_read && row?.can_edit && row?.can_delete}
        />
      ),
    },
  ];
  const handleCloseModal = () => {
    setModalShow(false);
  };

  const handleSaveChanges = async (e) => {
    try {
      const result = await schoolApi.upsertRolePermissions(upsertRolePermissions);
      if (result.success) {
        toast.success("Record updated successfully")
      } else {
        toast.error("Record not updated..!!",{
          className: 'toast-error'
        })
      }
    } catch (error) {
      console.log('SERVER_ERROR-->>' , error);
      toast.error(error.response.data.message,{
        className: 'toast-error'
      })
    }
    
  }

  console.log('select_all_box---->>>' , tabCheckBox);
  
  return (
    <>
      <Helmet><title>{props?.tabName}</title></Helmet>
      <CreateRolePermissionModal
        modalShow={modalShow}
        handleCloseModal={handleCloseModal}
        fetchAllRolePermissions={fetchAllRoles}
        rolePermissionData={rolePermissionData}
        btnName={btnName}
      />

      <ToastContainer
        position="top-right"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />

      <Col lg={2} className="m-4">
        <Link className="nav-link" to="/">
          Home <i className="fa-solid fa-chevron-right"></i> Role Permission
        </Link>
      </Col>

      <Row className="g-0">
        <Col lg={12} className="px-lg-4">
          <Accordion defaultActiveKey="0">
            <Accordion.Item eventKey="0" >
              <div style={{ backgroundColor: "#CFE2FF", padding: "15px" }}>
                <div style={{ fontSize: "15px", paddingLeft: "10px" }}>Role Permission</div>
              </div>
              {isLoading ? <div className="loader"></div>
                :
                <div>
                  <Accordion.Body >
                    {rolePermissions ? (
                      <Accordion defaultActiveKey="0" className="container" >
                        {rolePermissions?.map((role, index) => (
                          <Accordion.Item key={role.roleid} eventKey={index}>
                            {<Accordion.Header onClick={(e) => { setRole(role.id); (userInfo.userrole === "ADMIN" && role.name === "ADMIN") ? setIsAdminUser(true) : setIsAdminUser(false) }}>{role.name}</Accordion.Header>}
                            <Accordion.Body>

                              <DatatableWrapper headers={header} key={role.roleid} body={role.permissions}>
                                <Table className="data-table">
                                  <TableHeader />
                                  <TableBody />
                                </Table>
                              </DatatableWrapper>
                            </Accordion.Body>
                          </Accordion.Item>
                        ))}
                      </Accordion>
                    ) : (
                      <ShimmerTable row={10} col={8} />
                    )}
                    <div className="row">
                      <div className="col-sm-6 col-md-11"></div>
                      <div className="col-6 col-md-1">
                        <Button className="btn-sm my-3 py-2 px-3  ms-4" variant="primary" onClick={handleSaveChanges} >
                          Save
                        </Button>
                      </div>
                    </div>
                  </Accordion.Body>
                </div>}
            </Accordion.Item>
          </Accordion>
        </Col>
        <Col lg={2}></Col>
      </Row>
    </>
  );
};
export default RolePermissionList;

// -------------code 19-nov-24 sarfraj------------


// //Role Permission List Change by Shahir Hussain 22-04-2024
// import React, { useEffect, useState } from "react";
// import { Button, Col, Row, Table, Form, Accordion } from "react-bootstrap";
// import { Link } from "react-router-dom";
// import { DatatableWrapper, TableBody, TableHeader } from "react-bs-datatable";
// import { ShimmerTable } from "react-shimmer-effects";
// import schoolApi from "../../api/schoolApi";
// import CreateRolePermissionModal from "./CreateRolePermissionModal";
// import { ToastContainer, toast } from "react-toastify";
// import "react-toastify/dist/ReactToastify.css";
// import authApi from "../../api/authApi";

// const RolePermissionList = () => {
//   const [modalShow, setModalShow] = useState(false);
//   const [btnName, setBtnName] = useState("Save");
//   const [rolePermissionData, setRolePermissionData] = useState();
//   const [rolePermissions, setRolePermissions] = useState([]);
//   const [upsertRolePermissions, setUpsertRolePermissions] = useState([]);
//   const [isLoading, setIsLoading] = useState(true); 
//   const [role, setRole] = useState();

//   const [tabCheckBox , setTabCheckBox] = useState({
//           "read_all" : null,
//           "create_all" : null,
//           "edit_all" : null,
//           "delete_all" : null,
//           "select_all_box" : null,
//         });

//   useEffect(() => {
//     fetchAllRoles();
//   }, []);

//   async function fetchAllRoles() {
//     try {
//       if (await authApi.companyDetail().companyid) {
//         const rolePermissions = await schoolApi.getRolePermission(await authApi.companyDetail().companyid);
//         setIsLoading(false);
//         if(rolePermissions.success){  
//           setRolePermissions(rolePermissions?.result?.filter((rolePerission) => rolePerission.name !== "SYS_ADMIN"));
//           // setRolePermissions(rolePermissions?.result);
//         } else {
//           setRolePermissions([]);
//           toast.error(rolePermissions)
//         }
//       }
//     } catch (error) {
//      console.log('SERERV-ERROR-->>' , error);
//       toast.error(error.response.data.message,{
//         className: 'toast-error'
//       });
//     }
//   }

//   useEffect(() => {

//   }, [upsertRolePermissions]);

//   //updated by Abdul Sir 19-04-2024
//   //Change by shahir hussain 27-05-2024
//   const handleCheckBox = (columnName, row) => (e) => {
//     const checked = e.target.checked;

//     if (columnName === 'select_all_box') {  
//       setTabCheckBox({
//         "read_all": checked,
//         "create_all": checked,
//         "edit_all": checked,
//         "delete_all": checked,
//         "select_all_box": checked,
//       });
  
//       setRolePermissions(oldRolePermissions => {
//           const updatedRolePermissions = oldRolePermissions.map(record => {
//               if (record.id === row.roleid) {
//                   const updatedPermissions = record.permissions.map(item => ({
//                       ...item,
//                       can_read: checked,
//                       can_create: checked,
//                       can_edit: checked,
//                       can_delete: checked
//                   }));
                  
//                   setUpsertRolePermissions(updatedPermissions);
//                   return { ...record, permissions: updatedPermissions };
//               }
//               return record;
//           });
          
//           return updatedRolePermissions;
//       });
//     }    
//     else if (columnName === 'read_all' || columnName === 'create_all' || columnName === 'edit_all' || columnName === 'delete_all') {
      
//       /* this condition used only for UI , it is not affect any impact on back-end */
//       if(columnName === 'read_all') setTabCheckBox((prev)=> ({...prev , read_all : checked}))
//       else if(columnName === 'create_all') setTabCheckBox((prev)=> ({...prev , create_all : checked}))
//       else if(columnName === 'edit_all') setTabCheckBox((prev)=> ({...prev , edit_all : checked}))
//       else setTabCheckBox((prev)=> ({...prev , delete_all : checked}))

//       setRolePermissions((oldRolePermissions) => {
//         const updatedRolePermissions = [...oldRolePermissions];
//         const roleData = updatedRolePermissions.find((record) => record.id === row.roleid);
//         const rolePermission = roleData.permissions.map(item => {
//           if (columnName === 'read_all') item.can_read = e.target.checked;
//           else if (columnName === 'create_all') item.can_create = e.target.checked;
//           else if (columnName === 'edit_all') item.can_edit = e.target.checked;
//           else if (columnName === 'delete_all') item.can_delete = e.target.checked;
//         })

//         // const upsertRecord = upsertRolePermissions.find(record => record.roleid === row.roleid);
//         // let newRolePermissions = upsertRolePermissions;
//         // newRolePermissions = upsertRolePermissions.filter(record => record.roleid !== row.roleid);
//         setUpsertRolePermissions(roleData.permissions);
//         return updatedRolePermissions;
//       })
//     } else {
//       setRolePermissions((oldRolePermissions) => {
//         const updatedRolePermissions = [...oldRolePermissions];
//         const roleData = updatedRolePermissions.find((record) => record.id === row.roleid);
//         const rolePermission = roleData.permissions.find((perm) => perm.moduleid === row.moduleid);
        
//         if (!rolePermission) return updatedRolePermissions; 
    
//         if (['can_read', 'can_create', 'can_edit', 'can_delete'].includes(columnName)) {
//           rolePermission[columnName] = checked;
    
//           if (['can_create', 'can_edit', 'can_delete'].includes(columnName) && checked) {
//             rolePermission.can_read = true;
//             if((roleData.permissions.every((perm) => perm.can_read === true))){
//               setTabCheckBox((prev)=>({ ...prev, read_all : true}))
//             }
//           }
//         }
    
//         // Handle select_all logic
//         if (columnName === 'select_all') {
//           rolePermission.can_edit = checked;
//           rolePermission.can_create = checked;
//           rolePermission.can_read = checked;
//           rolePermission.can_delete = checked;

//           const isAllPemissions = roleData.permissions?.every((permission) => (permission.can_read === true && permission.can_create === true && permission.can_edit === true && permission.can_delete === true))
//           console.log('isAllPemissions--->>>' , isAllPemissions);
          
//           if(isAllPemissions){
//             setTabCheckBox({
//               "read_all" : true,
//               "create_all" : true,
//               "edit_all" : true,
//               "delete_all" : true,
//               "select_all_box" : true,
//             })
//           } else {
//             setTabCheckBox({
//               "read_all" : (roleData.permissions.every((perm) => perm.can_read === true)),
//               "create_all" : (roleData.permissions.every((perm) => perm.can_create === true)),
//               "edit_all" : (roleData.permissions.every((perm) => perm.can_edit === true)),
//               "delete_all" : (roleData.permissions.every((perm) => perm.can_delete === true)),
//             })
//           }
//         } 
    
//         // Update select_all based on individual permissions
//         rolePermission.select_all = ['can_edit', 'can_create', 'can_read', 'can_delete'].every(perm => rolePermission[perm]);
    
//         roleData.permissions.every((perm) => perm.can_read === true) ? setTabCheckBox((prev) => ({ ...prev, read_all: true})) : setTabCheckBox((prev) => ({ ...prev, read_all: false}));
//         roleData.permissions.every((perm) => perm.can_create === true) ? setTabCheckBox((prev) => ({ ...prev, create_all: true})) : setTabCheckBox((prev) => ({ ...prev, create_all: false}));
//         roleData.permissions.every((perm) => perm.can_edit === true) ? setTabCheckBox((prev) => ({ ...prev, edit_all: true})) : setTabCheckBox((prev) => ({ ...prev, edit_all: false}));
//         roleData.permissions.every((perm) => perm.can_delete === true) ? setTabCheckBox((prev) => ({ ...prev, delete_all: true})) : setTabCheckBox((prev) => ({ ...prev, delete_all: false}));

//         // Update upsertRolePermissions
//         const upsertIndex = upsertRolePermissions.findIndex(record => record.roleid === row.roleid && record.moduleid === row.moduleid);
//         const newUpsertPermissions = upsertRolePermissions.slice();
//         if (upsertIndex > -1) {
//           newUpsertPermissions[upsertIndex] = rolePermission; // Update existing record
//         } else {
//           newUpsertPermissions.push(rolePermission); // Add new record
//         }
    
//         setUpsertRolePermissions(newUpsertPermissions);
//         return updatedRolePermissions;
//       });
//     }
//   };
  
//   // //Change by shahir hussain 27-05-2024
//   // const header = [
//   //   { title: "Module", prop: "module_name", isFilterable: true },
//   //   {
//   //     title: (
//   //       <span>
//   //         Read
//   //         <Form.Check
//   //           type="checkbox"
//   //           onChange={handleCheckBox("read_all", { roleid: role })}
//   //           checked={tabCheckBox.read_all}
//   //         />
//   //       </span>
//   //     ),
//   //     prop: "can_read",
//   //     isFilterable: true,
//   //     cell: (row) => (
//   //       row.can_read && (row?.can_create || row?.can_edit || row?.can_delete) 
//   //       ? <Form.Check
//   //           type="checkbox"
//   //           onChange={handleCheckBox("can_read", row)}
//   //           checked={row.can_read || (row?.can_create || row?.can_edit || row?.can_delete)}
//   //         /> 
//   //       : <Form.Check
//   //           type="checkbox"
//   //           onChange={handleCheckBox("can_read", row)}
//   //           checked={row.can_read}
//   //         /> 
//   //     ),
//   //   },
//   //   {
//   //     title: (
//   //       <span>
//   //         Create
//   //         <Form.Check
//   //           type="checkbox"
//   //           onChange={handleCheckBox("create_all", { roleid: role })}
//   //           checked={tabCheckBox.create_all}
//   //         />
//   //       </span>
//   //     ),
//   //     prop: "can_create",
//   //     isFilterable: true,
//   //     cell: (row) => (
//   //       <Form.Check
//   //         type="checkbox"
//   //         onChange={handleCheckBox("can_create", row)}
//   //         checked={row.can_create}
//   //       />
//   //     ),
//   //   },
//   //   {
//   //     title: (
//   //       <span>
//   //         Edit
//   //         <Form.Check
//   //           type="checkbox"
//   //           onChange={handleCheckBox("edit_all", { roleid: role })}
//   //           checked={tabCheckBox.edit_all}
//   //         />
//   //       </span>
//   //     ),
//   //     prop: "can_edit",
//   //     isFilterable: true,
//   //     cell: (row) => (
//   //       <Form.Check type="checkbox" onChange={handleCheckBox("can_edit", row)} checked={row.can_edit} />
//   //     ),
//   //   },
//   //   {
//   //     title: (
//   //       <>
//   //         Delete
//   //         <Form.Check
//   //           type="checkbox"
//   //           onChange={handleCheckBox("delete_all", { roleid: role })}
//   //           checked={tabCheckBox.delete_all}
//   //         />
//   //       </>
//   //     ),
//   //     prop: "can_delete",
//   //     isFilterable: true,
//   //     cell: (row) => (
//   //       <Form.Check
//   //         type="checkbox"
//   //         onChange={handleCheckBox("can_delete", row)}
//   //         checked={row.can_delete}
//   //       />
//   //     ),
//   //   },
//   //   {
//   //     title: (
//   //       <>
//   //         Select All
//   //         <Form.Check
//   //           type="checkbox"
//   //           onChange={handleCheckBox("select_all_box", { roleid: role })}
//   //           checked={tabCheckBox.create_all && tabCheckBox.read_all && tabCheckBox.edit_all && tabCheckBox.delete_all}
//   //         />
//   //       </>
//   //     ), 
//   //     prop: "select_all",
//   //     isFilterable: true,
//   //     cell: (row) => (
//   //       <Form.Check
//   //         type="checkbox"
//   //         onChange={handleCheckBox("select_all", row)}
//   //         checked={row.can_create && row?.can_read && row?.can_edit && row?.can_delete}
//   //       />
//   //     ),
//   //   },
//   // ];
//   const header = [
//     { title: "Module", prop: "module_name", isFilterable: true },
//     {
//       title: (
//         <span>
//           Read
//           <Form.Check
//             type="checkbox"
//             onChange={handleCheckBox("read_all", { roleid: role })}
//             checked={
//               tabCheckBox.read_all !== null ? tabCheckBox?.read_all
//               : rolePermissions?.some((userRole) => 
//                 userRole?.id === role && userRole?.permissions?.every((permission) => permission?.can_read === true)
//               )
//             }
//           />
//         </span>
//       ),
//       prop: "can_read",
//       isFilterable: true,
//       cell: (row) => (
//         row.can_read && (row?.can_create || row?.can_edit || row?.can_delete) 
//         ? <Form.Check
//             type="checkbox"
//             onChange={handleCheckBox("can_read", row)}
//             checked={row.can_read || (row?.can_create || row?.can_edit || row?.can_delete)}
//           /> 
//         : <Form.Check
//             type="checkbox"
//             onChange={handleCheckBox("can_read", row)}
//             checked={row.can_read}
//           /> 
//       ),
//     },
//     {
//       title: (
//         <span>
//           Create
//           <Form.Check
//             type="checkbox"
//             onChange={handleCheckBox("create_all", { roleid: role })}
//             checked={
//               tabCheckBox.create_all !== null ? tabCheckBox.create_all
//               : rolePermissions.some((userRole) => 
//                 userRole?.id === role && userRole?.permissions?.every((permission) => permission?.can_create === true)
//               )
//             }
//           />
//         </span>
//       ),
//       prop: "can_create",
//       isFilterable: true,
//       cell: (row) => (
//         <Form.Check
//           type="checkbox"
//           onChange={handleCheckBox("can_create", row)}
//           checked={row.can_create}
//         />
//       ),
//     },
//     {
//       title: (
//         <span>
//           Edit
//           <Form.Check
//             type="checkbox"
//             onChange={handleCheckBox("edit_all", { roleid: role })}
//             checked={
//               tabCheckBox.edit_all !== null ? tabCheckBox.edit_all
//               : rolePermissions?.some((userRole) => 
//                 userRole?.id === role && userRole?.permissions?.every((permission) => permission?.can_edit === true)
//               )
//             }
//           />
//         </span>
//       ),
//       prop: "can_edit",
//       isFilterable: true,
//       cell: (row) => (
//         <Form.Check type="checkbox" onChange={handleCheckBox("can_edit", row)} checked={row.can_edit} />
//       ),
//     },
//     {
//       title: (
//         <>
//           Delete
//           <Form.Check
//             type="checkbox"
//             onChange={handleCheckBox("delete_all", { roleid: role })}
//             checked={
//               tabCheckBox.delete_all !== null ? tabCheckBox.delete_all
//               : rolePermissions?.some((userRole) => 
//                 userRole?.id === role && userRole?.permissions?.every((permission) => permission?.can_delete === true)
//               )
//             }
//           />
//         </>
//       ),
//       prop: "can_delete",
//       isFilterable: true,
//       cell: (row) => (
//         <Form.Check
//           type="checkbox"
//           onChange={handleCheckBox("can_delete", row)}
//           checked={row.can_delete}
//         />
//       ),
//     },
//     {
//       title: (
//         <>
//           Select All
//           <Form.Check
//             type="checkbox"
//             onChange={handleCheckBox("select_all_box", { roleid: role })}
//             checked={
//               (tabCheckBox.create_all && tabCheckBox.read_all && tabCheckBox.edit_all && tabCheckBox.delete_all) || 
//               rolePermissions?.some((userRole) => 
//                 userRole?.id === role && userRole?.permissions?.every((permission) => 
//                   permission?.can_read === true &&
//                   permission?.can_create === true &&
//                   permission?.can_edit === true &&
//                   permission?.can_delete === true
//                 )
//               )
//             }
//           />
//         </>
//       ), 
//       prop: "select_all",
//       isFilterable: true,
//       cell: (row) => (
//         <Form.Check
//           type="checkbox"
//           onChange={handleCheckBox("select_all", row)}
//           checked={row.can_create && row?.can_read && row?.can_edit && row?.can_delete}
//         />
//       ),
//     },
//   ];
//   const handleCloseModal = () => {
//     setModalShow(false);
//   };

//   const handleSaveChanges = async (e) => {
//     try {
//       const result = await schoolApi.upsertRolePermissions(upsertRolePermissions);
//       if (result.success) {
//         toast.success("Record updated successfully")
//       } else {
//         toast.error("Record not updated..!!",{
//           className: 'toast-error'
//         })
//       }
//     } catch (error) {
//       console.log('SERVER_ERROR-->>' , error);
//       toast.error(error.response.data.message,{
//         className: 'toast-error'
//       })
//     }
    
//   }

//   console.log('select_all_box---->>>' , tabCheckBox);
  
//   return (
//     <>
//       <CreateRolePermissionModal
//         modalShow={modalShow}
//         handleCloseModal={handleCloseModal}
//         fetchAllRolePermissions={fetchAllRoles}
//         rolePermissionData={rolePermissionData}
//         btnName={btnName}
//       />

//       <ToastContainer
//         position="top-right"
//         autoClose={2000}
//         hideProgressBar={false}
//         newestOnTop={false}
//         closeOnClick
//         rtl={false}
//         pauseOnFocusLoss
//         draggable
//         pauseOnHover
//         theme="light"
//       />


//       <Col lg={2} className="m-4">
//         <Link className="nav-link" to="/">
//           Home <i className="fa-solid fa-chevron-right"></i> Role Permission
//         </Link>
//       </Col>

//       <Row className="g-0">
//         <Col lg={12} className="px-lg-4">
//           <Accordion defaultActiveKey="0">
//             <Accordion.Item eventKey="0" >
//               <div style={{ backgroundColor: "#CFE2FF", padding: "15px" }}>
//                 <div style={{ fontSize: "15px", paddingLeft: "10px" }}>Role Permission</div>
//                 {/* <Col xs={12} sm={6} lg={11} className="d-flex flex-col justify-content-end align-items-end">
//                 </Col> */}
//                 {/*Change by shahir hussain 05 04 2024*/}
//               </div>
//               {isLoading ? <div className="loader"></div>
//                 :
//                 <div>
//                   <Accordion.Body >
//                     {rolePermissions ? (
//                       <Accordion defaultActiveKey="0" className="container" >
//                         {rolePermissions?.map((role, index) => (
//                           <Accordion.Item key={role.roleid} eventKey={index}>

//                             {<Accordion.Header onClick={(e) => { setRole(role.id) }}>{role.name}</Accordion.Header>}
//                             <Accordion.Body>

//                               <DatatableWrapper headers={header} key={role.roleid} body={role.permissions}>
//                                 <Table className="data-table">
//                                   <TableHeader />
//                                   <TableBody />
//                                 </Table>
//                               </DatatableWrapper>
//                             </Accordion.Body>
//                           </Accordion.Item>
//                         ))}
//                       </Accordion>
//                     ) : (
//                       <ShimmerTable row={10} col={8} />
//                     )}
//                     <div className="row">
//                       <div className="col-sm-6 col-md-11"></div>
//                       <div className="col-6 col-md-1">
//                         <Button className="btn-sm my-3 py-2 px-3  ms-4" variant="primary" onClick={handleSaveChanges} >
//                           Save
//                         </Button>
//                       </div>
//                     </div>
//                   </Accordion.Body>
//                 </div>}
//             </Accordion.Item>
//           </Accordion>
//         </Col>
//         <Col lg={2}></Col>
//       </Row>
//     </>
//   );
// };
// export default RolePermissionList;

// // //Role Permission List Change by Shahir Hussain 22-04-2024
// // import React, { useEffect, useState } from "react";
// // import { Button, Col, Row, Table, Form, Accordion } from "react-bootstrap";
// // import { Link } from "react-router-dom";
// // import { DatatableWrapper, TableBody, TableHeader } from "react-bs-datatable";
// // import { ShimmerTable } from "react-shimmer-effects";
// // import schoolApi from "../../api/schoolApi";
// // import CreateRolePermissionModal from "./CreateRolePermissionModal";
// // import { ToastContainer, toast } from "react-toastify";
// // import "react-toastify/dist/ReactToastify.css";
// // import authApi from "../../api/authApi";

// // const RolePermissionList = () => {
// //   const [modalShow, setModalShow] = useState(false);
// //   const [btnName, setBtnName] = useState("Save");
// //   const [rolePermissionData, setRolePermissionData] = useState();
// //   const [rolePermissions, setRolePermissions] = useState([]);
// //   const [upsertRolePermissions, setUpsertRolePermissions] = useState([]);
// //   const [isLoading, setIsLoading] = useState(true); 
// //   const [role, setRole] = useState();

// //   const [tabCheckBox , setTabCheckBox] = useState({
// //           "read_all" : null,
// //           "create_all" : null,
// //           "edit_all" : null,
// //           "delete_all" : null,
// //         });

// //   useEffect(() => {
// //     fetchAllRoles();
// //   }, []);

// //   async function fetchAllRoles() {
// //     try {
// //       if (await authApi.companyDetail().companyid) {
// //         const rolePermissions = await schoolApi.getRolePermission(await authApi.companyDetail().companyid);
// //         setIsLoading(false);
// //         if(rolePermissions.success){  
// //           setRolePermissions(rolePermissions?.result?.filter((rolePerission) => rolePerission.name !== "SYS_ADMIN"));
// //           // setRolePermissions(rolePermissions?.result);
// //         } else {
// //           setRolePermissions([]);
// //           toast.error(rolePermissions)
// //         }
// //       }
// //     } catch (error) {
// //      console.log('SERERV-ERROR-->>' , error);
// //       toast.error(error.response.data.message,{
// //         className: 'toast-error'
// //       });
// //     }
// //   }

// //   useEffect(() => {

// //   }, [upsertRolePermissions]);

// //   //updated by Abdul Sir 19-04-2024
// //   //Change by shahir hussain 27-05-2024
// //   const handleCheckBox = (columnName, row) => (e) => {
// //     const checked = e.target.checked;

// //     if (columnName === 'read_all' || columnName === 'create_all' || columnName === 'edit_all' || columnName === 'delete_all') {
      
// //       /* this condition used only for UI , it is not affect any impact on back-end */
// //       if(columnName === 'read_all') setTabCheckBox((prev)=> ({...prev , read_all : checked}))
// //       else if(columnName === 'create_all') setTabCheckBox((prev)=> ({...prev , create_all : checked}))
// //       else if(columnName === 'edit_all') setTabCheckBox((prev)=> ({...prev , edit_all : checked}))
// //       else setTabCheckBox((prev)=> ({...prev , delete_all : checked}))

// //       setRolePermissions((oldRolePermissions) => {
// //         const updatedRolePermissions = [...oldRolePermissions];
// //         const roleData = updatedRolePermissions.find((record) => record.id === row.roleid);
// //         const rolePermission = roleData.permissions.map(item => {
// //           if (columnName === 'read_all') item.can_read = e.target.checked;
// //           else if (columnName === 'create_all') item.can_create = e.target.checked;
// //           else if (columnName === 'edit_all') item.can_edit = e.target.checked;
// //           else if (columnName === 'delete_all') item.can_delete = e.target.checked;
// //         })

// //         const upsertRecord = upsertRolePermissions.find(record => record.roleid === row.roleid);
// //         let newRolePermissions = upsertRolePermissions;
// //         newRolePermissions = upsertRolePermissions.filter(record => record.roleid !== row.roleid);
// //         setUpsertRolePermissions(roleData.permissions);
// //         return updatedRolePermissions;
// //       })

// //     } else {
// //       setRolePermissions((oldRolePermissions) => {
// //         const updatedRolePermissions = [...oldRolePermissions];
// //         const roleData = updatedRolePermissions.find((record) => record.id === row.roleid);
// //         const rolePermission = roleData.permissions.find((perm) => perm.moduleid === row.moduleid);
        
// //         if (!rolePermission) return updatedRolePermissions; 
    
// //         if (['can_read', 'can_create', 'can_edit', 'can_delete'].includes(columnName)) {
// //           rolePermission[columnName] = checked;
    
// //           if (['can_create', 'can_edit', 'can_delete'].includes(columnName) && checked) {
// //             rolePermission.can_read = true;
// //             if((roleData.permissions.every((perm) => perm.can_read === true))){
// //               setTabCheckBox((prev)=>({ ...prev, read_all : true}))
// //             }
// //           }
// //         }
    
// //         // Handle select_all logic
// //         if (columnName === 'select_all') {
// //           rolePermission.can_edit = checked;
// //           rolePermission.can_create = checked;
// //           rolePermission.can_read = checked;
// //           rolePermission.can_delete = checked;

// //           const isAllPemissions = roleData.permissions?.every((permission) => (permission.can_read === true && permission.can_create === true && permission.can_edit === true && permission.can_delete === true))
// //           console.log('isAllPemissions--->>>' , isAllPemissions);
          
// //           if(isAllPemissions){
// //             setTabCheckBox({
// //               "read_all" : true,
// //               "create_all" : true,
// //               "edit_all" : true,
// //               "delete_all" : true,
// //             })
// //           } else {
// //             setTabCheckBox({
// //               "read_all" : (roleData.permissions.every((perm) => perm.can_read === true)),
// //               "create_all" : (roleData.permissions.every((perm) => perm.can_create === true)),
// //               "edit_all" : (roleData.permissions.every((perm) => perm.can_edit === true)),
// //               "delete_all" : (roleData.permissions.every((perm) => perm.can_delete === true)),
// //             })
// //           }
// //         } 
    
// //         // Update select_all based on individual permissions
// //         rolePermission.select_all = ['can_edit', 'can_create', 'can_read', 'can_delete'].every(perm => rolePermission[perm]);
    
// //         roleData.permissions.every((perm) => perm.can_read === true) ? setTabCheckBox((prev) => ({ ...prev, read_all: true})) : setTabCheckBox((prev) => ({ ...prev, read_all: false}));
// //         roleData.permissions.every((perm) => perm.can_create === true) ? setTabCheckBox((prev) => ({ ...prev, create_all: true})) : setTabCheckBox((prev) => ({ ...prev, create_all: false}));
// //         roleData.permissions.every((perm) => perm.can_edit === true) ? setTabCheckBox((prev) => ({ ...prev, edit_all: true})) : setTabCheckBox((prev) => ({ ...prev, edit_all: false}));
// //         roleData.permissions.every((perm) => perm.can_delete === true) ? setTabCheckBox((prev) => ({ ...prev, delete_all: true})) : setTabCheckBox((prev) => ({ ...prev, delete_all: false}));

// //         // Update upsertRolePermissions
// //         const upsertIndex = upsertRolePermissions.findIndex(record => record.roleid === row.roleid && record.moduleid === row.moduleid);
// //         const newUpsertPermissions = upsertRolePermissions.slice();
// //         if (upsertIndex > -1) {
// //           newUpsertPermissions[upsertIndex] = rolePermission; // Update existing record
// //         } else {
// //           newUpsertPermissions.push(rolePermission); // Add new record
// //         }
    
// //         setUpsertRolePermissions(newUpsertPermissions);
// //         return updatedRolePermissions;
// //       });
// //     }
// //   };
  

// //   //Change by shahir hussain 27-05-2024
// //   const header = [
// //     { title: "Module", prop: "module_name", isFilterable: true },
// //     {
// //       title: (
// //         <span>
// //           Read
// //           <Form.Check
// //             type="checkbox"
// //             onChange={handleCheckBox("read_all", { roleid: role })}
// //             checked={tabCheckBox.read_all}
// //           />
// //         </span>
// //       ),
// //       prop: "can_read",
// //       isFilterable: true,
// //       cell: (row) => (
// //         row.can_read && (row?.can_create || row?.can_edit || row?.can_delete) 
// //         ? <Form.Check
// //             type="checkbox"
// //             onChange={handleCheckBox("can_read", row)}
// //             checked={row.can_read || (row?.can_create || row?.can_edit || row?.can_delete)}
// //           /> 
// //         : <Form.Check
// //             type="checkbox"
// //             onChange={handleCheckBox("can_read", row)}
// //             checked={row.can_read}
// //           /> 
// //       ),
// //     },
// //     {
// //       title: (
// //         <span>
// //           Create
// //           <Form.Check
// //             type="checkbox"
// //             onChange={handleCheckBox("create_all", { roleid: role })}
// //             checked={tabCheckBox.create_all}
// //           />
// //         </span>
// //       ),
// //       prop: "can_create",
// //       isFilterable: true,
// //       cell: (row) => (
// //         <Form.Check
// //           type="checkbox"
// //           onChange={handleCheckBox("can_create", row)}
// //           checked={row.can_create}
// //         />
// //       ),
// //     },
// //     {
// //       title: (
// //         <span>
// //           Edit
// //           <Form.Check
// //             type="checkbox"
// //             onChange={handleCheckBox("edit_all", { roleid: role })}
// //             checked={tabCheckBox.edit_all}
// //           />
// //         </span>
// //       ),
// //       prop: "can_edit",
// //       isFilterable: true,
// //       cell: (row) => (
// //         <Form.Check type="checkbox" onChange={handleCheckBox("can_edit", row)} checked={row.can_edit} />
// //       ),
// //     },
// //     {
// //       title: (
// //         <>
// //           Delete
// //           <Form.Check
// //             type="checkbox"
// //             onChange={handleCheckBox("delete_all", { roleid: role })}
// //             checked={tabCheckBox.delete_all}
// //           />
// //         </>
// //       ),
// //       prop: "can_delete",
// //       isFilterable: true,
// //       cell: (row) => (
// //         <Form.Check
// //           type="checkbox"
// //           onChange={handleCheckBox("can_delete", row)}
// //           checked={row.can_delete}
// //         />
// //       ),
// //     },
// //     {
// //       title: "select all",
// //       prop: "select_all",
// //       isFilterable: true,
// //       cell: (row) => (
// //         <Form.Check
// //           type="checkbox"
// //           onChange={handleCheckBox("select_all", row)}
// //           checked={row.can_create && row?.can_read && row?.can_edit && row?.can_delete}
// //         />
// //       ),
// //     },
// //   ];

// //   const handleCloseModal = () => {
// //     setModalShow(false);
// //   };

// //   const handleSaveChanges = async (e) => {
// //     try {
// //       const result = await schoolApi.upsertRolePermissions(upsertRolePermissions);
// //       if (result.success) {
// //         toast.success("Record updated successfully")
// //       } else {
// //         toast.error("Record not updated..!!",{
// //           className: 'toast-error'
// //         })
// //       }
// //     } catch (error) {
// //       console.log('SERVER_ERROR-->>' , error);
// //       toast.error(error.response.data.message,{
// //         className: 'toast-error'
// //       })
// //     }
    
// //   }

// //   return (
// //     <>
// //       <CreateRolePermissionModal
// //         modalShow={modalShow}
// //         handleCloseModal={handleCloseModal}
// //         fetchAllRolePermissions={fetchAllRoles}
// //         rolePermissionData={rolePermissionData}
// //         btnName={btnName}
// //       />

// //       <ToastContainer
// //         position="top-right"
// //         autoClose={2000}
// //         hideProgressBar={false}
// //         newestOnTop={false}
// //         closeOnClick
// //         rtl={false}
// //         pauseOnFocusLoss
// //         draggable
// //         pauseOnHover
// //         theme="light"
// //       />


// //       <Col lg={2} className="m-4">
// //         <Link className="nav-link" to="/">
// //           Home <i className="fa-solid fa-chevron-right"></i> Role Permission
// //         </Link>
// //       </Col>

// //       <Row className="g-0">
// //         <Col lg={12} className="px-lg-4">
// //           <Accordion defaultActiveKey="0">
// //             <Accordion.Item eventKey="0" >
// //               <div style={{ backgroundColor: "#CFE2FF", padding: "15px" }}>
// //                 <div style={{ fontSize: "15px", paddingLeft: "10px" }}>Role Permission</div>
// //                 {/* <Col xs={12} sm={6} lg={11} className="d-flex flex-col justify-content-end align-items-end">
// //                 </Col> */}
// //                 {/*Change by shahir hussain 05 04 2024*/}
// //               </div>
// //               {isLoading ? <div className="loader"></div>
// //                 :
// //                 <div>
// //                   <Accordion.Body >
// //                     {rolePermissions ? (
// //                       <Accordion defaultActiveKey="0" className="container" >
// //                         {rolePermissions?.map((role, index) => (
// //                           <Accordion.Item key={role.roleid} eventKey={index}>

// //                             {<Accordion.Header onClick={(e) => { setRole(role.id) }}>{role.name}</Accordion.Header>}
// //                             <Accordion.Body>

// //                               <DatatableWrapper headers={header} key={role.roleid} body={role.permissions}>
// //                                 <Table className="data-table">
// //                                   <TableHeader />
// //                                   <TableBody />
// //                                 </Table>
// //                               </DatatableWrapper>
// //                             </Accordion.Body>
// //                           </Accordion.Item>
// //                         ))}
// //                       </Accordion>
// //                     ) : (
// //                       <ShimmerTable row={10} col={8} />
// //                     )}
// //                     <div className="row">
// //                       <div className="col-sm-6 col-md-11"></div>
// //                       <div className="col-6 col-md-1">
// //                         <Button className="btn-sm my-3 py-2 px-3  ms-4" variant="primary" onClick={handleSaveChanges} >
// //                           Save
// //                         </Button>
// //                       </div>
// //                     </div>
// //                   </Accordion.Body>
// //                 </div>}
// //             </Accordion.Item>
// //           </Accordion>
// //         </Col>
// //         <Col lg={2}></Col>
// //       </Row>
// //     </>
// //   );
// // };
// // export default RolePermissionList;