import React, { useState, useEffect } from "react";
import { Col, Container, Row, Button } from "react-bootstrap";
import Card from "react-bootstrap/Card";
import { useLocation } from "react-router-dom";
import PageNavigations from "../breadcrumbs/PageNavigations";
import { useNavigate } from "react-router-dom";
import StudentInfo from "../tabNavigation/StudentInfo";
import { Helmet } from "react-helmet";
import { ToastContainer, toast } from "react-toastify";
import "../../resources/css/Student.css";
import { ParentInfo } from "../tabNavigation";
import schoolApi from "../../api/schoolApi";
import PreviousSchoolingInfo from "../tabNavigation/previousSchoolingInfo";
import SiblingInfo from "../tabNavigation/SiblingInfo";
import { parseISO } from "date-fns";

const StudentMainTab = (props) => {
  const [activeStep, setActiveStep] = useState(0);
  const [errors, setErrors] = useState({});
  const navigate = useNavigate();
  const [required, setRequired] = useState("Parent_Father");
  const [primarycontactno, setPrimaryContactNo] = useState();
  const location = useLocation();
  console.log("location ->", location.state);

  const handleSearch = async (e) => {
    const searchPhone = e.target.value.replace(/\D/g, "").slice(0, 10);
    e.target.value = searchPhone;
    console.log("Contact Phone:", searchPhone);
    const isValidPhone = /^\d{10}$/.test(searchPhone);

    if (searchPhone && isValidPhone) {
      try {
        const allParentContact = await schoolApi.fetchParentsInfoByPhone(
          searchPhone
        );
        console.log("=====> All Parent Contacts:", allParentContact);

        if (allParentContact.success) {
          const defaultParentRecord = {
            firstname: "",
            lastname: "",
            qualification: "",
            profession: "",
            phone: "",
            email: "",
            is_primary: false,
          };

          setstudentrecord((prev) => {
            const updatedStudentRecord = {
              ...prev,
              parentInfo: {
                // Update the parentInfo structure correctly
                Parent_Father: { ...defaultParentRecord },
                Parent_Mother: { ...defaultParentRecord },
                Parent_Guardian: { ...defaultParentRecord },
              },
            };

            allParentContact.result.parents.forEach((res) => {
              console.log("res ->", res);
              updatedStudentRecord.parentInfo[res.recordtype] = {
                ...updatedStudentRecord.parentInfo[res.recordtype],
                firstname: res.firstname,
                id: res.id,
                lastname: res.lastname,
                phone: res.phone,
                email: res.email,
                is_primary: res.is_primary,
                profession: res.profession,
                qualification: res.qualification,
              };

              if (res.is_primary) {
                setPrimaryContactNo(res.phone);
                setRequired(res.recordtype); // Assuming setRequired is defined elsewhere
              }
            });

            return updatedStudentRecord;
          });
        } else {
          //  toast.error(allParentContact.message);
        }
      } catch (error) {
        console.error("Error fetching parent contact:", error);
        toast.error("Internal Server Error");
      }
    }
  };

  useEffect(() => {
    const studentInfo = location?.state?.student_info;
    const parentInfo = location?.state?.parent_info;
    const previousschool = location?.state?.previous_school;
    console.log("parentInfo ->", parentInfo);

    if (studentInfo) {
      setActiveStep(0);
      for (const key in studentInfo) {
        if (Object.prototype.hasOwnProperty.call(studentInfo, key)) {
          const element = studentInfo[key];
          console.log("element previs->", element);

          if(key === "studentname") {
            setstudentrecord((prev) => ({
              ...prev,
              stutdentinfo: { ...prev.stutdentinfo, firstname: element.split(' ')[0] , lastname: element.split(' ')[1] , [key] : element},
            }));
          } else if (key !== "dateofbirth") {
            setstudentrecord((prev) => ({
              ...prev,
              stutdentinfo: { ...prev.stutdentinfo, [key]: element },
            }));
          } else {
            let date = new Date(element);
            setstudentrecord((prev) => ({
              ...prev,
              stutdentinfo: { ...prev.stutdentinfo, dateofbirth: date },
            }));
          }
        }
      }
    }
    if (previousschool) {
      for (const key in previousschool) {
        if (Object.prototype.hasOwnProperty.call(previousschool, key)) {
          const element = previousschool[key];
          console.log("element previs->", element);
          setstudentrecord((prev) => ({
            ...prev,
            previousschool: { ...prev.previousschool, [key]: element },
          }));
        }
      }
    }

    if (parentInfo && typeof parentInfo === "object") {
      for (const key in parentInfo) {
        if (Object.prototype.hasOwnProperty.call(parentInfo, key)) {
          console.log("key ->", key);
          console.log("parentInfo ->", parentInfo[key].is_primary);
          if (parentInfo[key].is_primary) {
            handleSearch({
              target: { name: "phone", value: parentInfo[key].phone },
            });
          }
        }
      }
    }

    setActiveStep(0);
  }, [location?.state?.student_info, location?.state?.parent_info]);
  useEffect(() => {
    if (location?.state?.status === "Not Registered") {
      console.log("location?.state? ->", location?.state);
      const lead = location?.state;
      setstudentrecord((prev) => ({
        ...prev,
        stutdentinfo: {
          firstname: lead.firstname,
          lastname: lead.lastname,
          country: lead.country,
          state: lead.state,
          city: lead.city,
          pincode: lead.pincode,
          street: lead.street,
          dateofbirth: parseISO(lead.dateofbirth),
          gender: lead.gender,
          email: lead.email,
          class_name: lead.classname,
          classid: lead.classid,
          section_name: lead.section_name,
          sectionid: lead.sectionid,
          adharnumber: lead.adharnumber,
          phone: lead.phone,
          religion: lead.religion,
          isrte: false,
          islateadmission: false,
          description: lead.description,
          lead_id: location?.state?.id,
        },
        parentInfo: {
          Parent_Father: {
            firstname: lead.father_name,
            lastname: "",
            qualification: lead.father_qualification,
            profession: lead.father_occupation,
            phone: "",
            email: "",
            is_primary: true,
          },
          Parent_Mother: {
            firstname: lead.mother_name,
            lastname: "",
            qualification: lead.mother_qualification,
            profession: lead.mother_occupation,
            phone: "",
            email: "",
            is_primary: false,
          },
          Parent_Guardian: {
            firstname: "",
            lastname: "",
            qualification: "",
            profession: "",
            phone: "",
            email: "",
            is_primary: false,
          },
        },
      }));
    }
  }, [location?.state?.status]);
  const handlePrimaryParent = (selectedParent) => {
    setRequired(selectedParent);

    updateStudentRecord({
      Parent_Father: {
        ...studentrecord.parentInfo.Parent_Father,
        is_primary: selectedParent === "Parent_Father",
      },
      Parent_Mother: {
        ...studentrecord.parentInfo.Parent_Mother,
        is_primary: selectedParent === "Parent_Mother",
      },
      Parent_Guardian: {
        ...studentrecord.parentInfo.Parent_Guardian,
        is_primary: selectedParent === "Parent_Guardian",
      },
    });
  };

  const steps = [
    { stepName: "Student Information" },
    { stepName: "Parents Information" },
    { stepName: "Siblings Information" },
    { stepName: "Previous Schooling" },
  ];

  const [studentrecord, setstudentrecord] = useState({
    stutdentinfo: {
      firstname: "",
      lastname: "",
      country: "",
      permanentcountry: "",
      state: "",
      permanentstate: "",
      city: "",
      permanentcity: "",
      pincode: "",
      permanentpostalcode: "",
      street: "",
      permanentstreet: "",
      dateofbirth: "",
      gender: "",
      email: "",
      class_name: "",
      classid: "",
      section_name: "",
      sectionid: "",
      category: "",
      adharnumber: "",
      phone: "",
      religion: "",
      isrte: false,
      islateadmission: false,
      fee_type: "",
      discounts: [],
      description: "",
      student_addmission_id: null,
      previous_school_id: null,
      studentProfileImage : null,
    },
    parentInfo: {
      Parent_Father: {
        firstname: "",
        lastname: "",
        qualification: "",
        profession: "",
        phone: "",
        email: "",
        is_primary: true,
      },
      Parent_Mother: {
        firstname: "",
        lastname: "",
        qualification: "",
        profession: "",
        phone: "",
        email: "",
        is_primary: false,
      },
      Parent_Guardian: {
        firstname: "",
        lastname: "",
        qualification: "",
        profession: "",
        phone: "",
        email: "",
        is_primary: false,
      },
    },
    siblingInfo: {},
    previousschool: {
      class_name: "",
      class: "",
      grade: "",
      passed_year: "",
      school_name: "",
      phone: "",
      school_address: "",
      student_id: null,
    },
  });

  const totalMonths = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const updateStudentRecord = (data) => {
    if (activeStep === 0) {
      setstudentrecord((prev) => ({
        ...prev,
        stutdentinfo: { ...prev.stutdentinfo, ...data },
      }));
    } else if (activeStep === 1) {
      setstudentrecord((prev) => ({
        ...prev,
        parentInfo: { ...prev.parentInfo, ...data },
      }));
    } else if (activeStep === 2) {
      setstudentrecord((prev) => ({
        ...prev,
        siblingInfo: { ...prev.siblingInfo, ...data },
      }));
    } else if (activeStep === 3) {
      setstudentrecord((prev) => ({
        ...prev,
        previousschool: { ...prev.previousschool, ...data },
      }));
    }
  };

  console.log("studentrecord in parent sdfsdfsdfdsfsd->", studentrecord);

  const validateStudentRecord = (studentrecord, activeStep) => {
    let errors = "";

    if (activeStep === 0) {
      if (
        !studentrecord.stutdentinfo.firstname ||
        !studentrecord.stutdentinfo.lastname ||
        !studentrecord.stutdentinfo.class_name ||
        !studentrecord.stutdentinfo.classid ||
        !studentrecord.stutdentinfo.sectionid ||
        !studentrecord.stutdentinfo.section_name ||
        !studentrecord.stutdentinfo.phone ||
        !studentrecord.stutdentinfo.dateofbirth ||
        !studentrecord.stutdentinfo.category ||
        !studentrecord.stutdentinfo.fee_type
      ) {
        console.log(
          "studentrecord.stutdentinfo ->",
          studentrecord.stutdentinfo
        );

        errors = " Student Information";
      }
    } else if (activeStep === 1) {
      if (
        !studentrecord.parentInfo[required].firstname ||
        !studentrecord.parentInfo[required].lastname ||
        !studentrecord.parentInfo[required].phone
      ) {
        errors = " Parent Information";
      }
    }
    return errors;
  };

  const getCurrentDate = () => {
    const today = new Date();
    const year = today.getFullYear();
    let month = today.getMonth() + 1;
    let day = today.getDate();
    if (month < 10) {
      month = "0" + month;
    }
    if (day < 10) {
      day = "0" + day;
    }
    const currentDate = `${year}-${month}-${day}`;
    return currentDate;
  };

  const handleFinish = async (e) => {
    e.preventDefault();
    try {
      let errorMessages = [];
      steps.forEach((ele, index) => {
        const errorMessage = validateStudentRecord(studentrecord, index);
        if (index === 1) {
          if (
            primarycontactno !== studentrecord.parentInfo[required].phone &&
            studentrecord.parentInfo[required].id
          ) {
            if (
              window.confirm("you are continue with old parnet sey yes") !==
              true
            ) {
              delete studentrecord.parentInfo.Parent_Father.id;
              delete studentrecord.parentInfo.Parent_Mother.id;
              delete studentrecord.parentInfo.Parent_Guardian.id;
            }
          }
        }
        if (errorMessage) {
          errorMessages.push(errorMessage);
        }
      });
      if (errorMessages.length > 0) {
        const formattedMessages = errorMessages.join(" and ");
        toast.error(`Please complete all required fields in the ${formattedMessages} section before proceeding`);
      } else {
        const contResult = await schoolApi.createContOnRecType(studentrecord.parentInfo);

        if (contResult.success) {
          const primaryContact = contResult.contactRec.filter((contact) => contact.is_primary);
          if (primaryContact) {
            studentrecord.stutdentinfo.parentId = primaryContact[0].id;
            const studentRec = await schoolApi.createStudent(studentrecord.stutdentinfo);

            if (studentRec.success) {

              /*----  Delete Existing Student Profile Image while Update  ----*/
              if(studentrecord.stutdentinfo.studentProfileImage && studentrecord.stutdentinfo.file_id){
                const resultDeleteFile = await schoolApi.deleteFile(studentrecord?.stutdentinfo?.file_id)
                console.log('resultDeleteFilw---->>>>' , resultDeleteFile);
              }
              
              /*----  Upload & Update Student Profile Image  ----*/
              let formData = new FormData();
              formData.append(`files`, studentrecord?.stutdentinfo.studentProfileImage)
              const resultUploadImage = (studentrecord?.stutdentinfo.studentProfileImage && studentrecord?.stutdentinfo?.file_parentId)
              ? await schoolApi.saveFiles(studentrecord?.file_parentId , formData)
              : await schoolApi.createFile(studentRec?.result?.id , formData);

              if(resultUploadImage){
                console.log('resultUploadImage---->>>>' , resultUploadImage);
              }
              /*  END ./  */

              studentrecord.previousschool.student_id = studentRec?.result?.id;
              const createPrevSchoolRecord = await schoolApi.createPreviousSchool(studentrecord?.previousschool);
              if (createPrevSchoolRecord?.success) {
                studentrecord.stutdentinfo.previous_school_id = createPrevSchoolRecord?.result?.id;
              }

              const arr = {
                studentid: studentRec.result.id,
                classid: studentrecord.stutdentinfo.classid,
                section_id: studentrecord.stutdentinfo.sectionid,
                parentid: studentrecord.stutdentinfo.parentId,
                fee_type: studentrecord.stutdentinfo.fee_type,
                session_id: studentrecord.stutdentinfo?.sessionId,
                dateofaddmission: getCurrentDate(),
                isrte: studentrecord.stutdentinfo?.isrte,
                previous_school_id: createPrevSchoolRecord?.result?.id
                  ? createPrevSchoolRecord?.result?.id
                  : null,
                islateadmission: studentrecord.stutdentinfo?.islateadmission,
                id: studentrecord.stutdentinfo?.student_addmission_id,
              };
              
              const result2 = await schoolApi.createStudentAddmission(arr);
              console.log("result2 ->", result2);

              if (!studentrecord.stutdentinfo?.student_addmission_id) {
                //only for create
                let discountLineItms = [];
                if (studentrecord.stutdentinfo?.discounts && result2?.studentAddmisionRec?.id) {
                  let discountLineItemRecord = {
                    student_addmission_id: result2?.studentAddmisionRec?.id,
                    discounts: studentrecord.stutdentinfo?.discounts,
                  };
                  const discountLineItemsResult = await schoolApi.createFeeDiscountLineItems(discountLineItemRecord);
                  discountLineItms = discountLineItemsResult;
                }
                if (studentrecord.stutdentinfo?.fee_type && result2?.studentAddmisionRec?.id) {
                  const getFeeMasterTypeResult = await schoolApi.fetchFeeMaster(studentrecord.stutdentinfo?.fee_type);
                  const feeMasterType = getFeeMasterTypeResult.result[0]?.type;
                  let feeMasterRecord =
                    await schoolApi.getMasterInstallmentByFeeMasterId(studentrecord.stutdentinfo?.fee_type);
                  const fetchSelectedSessionResult =
                    await schoolApi.getSessionById(studentrecord.stutdentinfo?.sessionId);

                  const currentDate = new Date().toISOString().slice(0, 10);
                  const lateMonths = [];
                  let sessionStartMonth =
                    fetchSelectedSessionResult?.result?.startdate.split("-")[1];
                  let sessionEndMonth =
                    fetchSelectedSessionResult?.result?.enddate.split("-")[1];
                  const currentIndex = new Date().getMonth() + 1; // 1 to 12

                  const endIndex = sessionEndMonth - 1; // index of "March" is 2

                  let monthsToLoop = [];

                  let monthArray = [];
                  let firstMonth;

                  monthsToLoop = totalMonths;
                  feeMasterRecord.forEach((record, index) => {
                    firstMonth = record.month.split(" to " || " ")[0];
                    monthsToLoop.forEach((month, index) => {
                      if (month === firstMonth) {
                        if (index > endIndex && index < currentIndex - 1) {
                          record.isLateAdmission =
                            studentrecord.stutdentinfo?.islateadmission;
                        } else {
                          record.isLateAdmission = false;
                        }
                        monthArray.push(record);
                      }
                    });
                  });

                  feeMasterRecord = monthArray;

                  // --- formatting due_date
                  const sessionDates = {};
                  const startdate =
                    fetchSelectedSessionResult?.result?.startdate;
                  const enddate = fetchSelectedSessionResult?.result?.enddate;

                  const startDate = new Date(startdate);
                  const endDate = new Date(enddate);

                  const generateInstallmentDates = (start, end) => {
                    const dates = [];
                    let currentDate = new Date(start);

                    while (currentDate <= end) {
                      dates.push(new Date(currentDate));
                      const monthName = currentDate.toLocaleString("default", {
                        month: "long",
                      });
                      const formattedDate = currentDate
                        .toISOString()
                        .split("T")[0];
                      sessionDates[monthName] = formattedDate;
                      currentDate.setMonth(currentDate.getMonth() + 1);

                      if (currentDate.getDate() !== start.getDate()) {
                        currentDate.setDate(0);
                      }
                    }

                    if (dates[dates.length - 1] < end) {
                      dates.push(new Date(end));
                      const monthName = end.toLocaleString("default", {
                        month: "long",
                      });
                      const formattedDate = end.toISOString().split("T")[0];
                      sessionDates[monthName] = formattedDate;
                    }

                    return dates;
                  };

                  const installmentDates = generateInstallmentDates(
                    startDate,
                    endDate
                  );

                  let pendingDueResult = [];
                  const ResultSettings = await schoolApi.getSettingByKey(
                    "pending_due_day"
                  );

                  if (ResultSettings.success) {
                    pendingDueResult.push(ResultSettings.data[0]);
                  } else {
                    pendingDueResult([]);
                  }

                  let recordsForStudentInstallment = [];
                  if (feeMasterRecord) {
                    const transportFee = 0;
                    console.log("transportFee-->", transportFee);
                    console.log(
                      "student.isLateAdmission ->",
                      studentrecord.stutdentinfo?.islateadmission
                    );

                    feeMasterRecord.forEach((res, index) => {
                      console.log("res-@@->", res);
                      let splittedMonth = res?.month?.split(" ");
                      console.log("splittedMonth-->", splittedMonth);

                      let lastDate = new Date(sessionDates[splittedMonth[0]]);
                      console.log("lastDate-1->", lastDate);

                      if (pendingDueResult[0].value) {
                        lastDate.setDate(pendingDueResult[0].value);
                      }

                      let installmentDate = lastDate.toISOString().slice(0, 10);

                      console.log("rrx installmentDate-->", installmentDate);

                      recordsForStudentInstallment.push({
                        student_addmission_id: result2?.studentAddmisionRec?.id,
                        fee_master_installment_id: res?.id,
                        amount:
                          res[
                            `${studentrecord.stutdentinfo?.category}_fee`.toLowerCase()
                          ],
                        status: "pending",
                        orderno: index + 1,
                        due_date: installmentDate,
                        late_joinee: res.isLateAdmission,
                        session_id: studentrecord.stutdentinfo?.sessionId,
                        transport_fee: transportFee,
                        assign_transport_id: null,
                        month: res?.month,
                        installment_discount: 0,
                      });
                    });

                    console.log(
                      "Student installments to be created-->",
                      recordsForStudentInstallment
                    );

                    //   //-start--------------Added By pawan for discounts ------------

                    let categoryAmount = "";
                    if (studentrecord.stutdentinfo?.category === "General") {
                      categoryAmount = "general_amount";
                    } else if (studentrecord.stutdentinfo?.category === "Obc") {
                      categoryAmount = "obc_amount";
                    } else if (studentrecord.stutdentinfo?.category === "Sc") {
                      categoryAmount = "sc_amount";
                    } else if (studentrecord.stutdentinfo?.category === "St") {
                      categoryAmount = "st_amount";
                    }

                    if (discountLineItms.length > 0) {
                      for (
                        let i = 0;
                        i <= recordsForStudentInstallment.length - 1;
                        i++
                      ) {
                        const lineItems =
                          await schoolApi.getLineItemsByFeeMasterId(
                            recordsForStudentInstallment[i]
                              .fee_master_installment_id
                          );
                        console.log("lineItems-@@studentEdit->", lineItems);
                        let discountedAmount = 0;
                        discountLineItms.map((dis) => {
                          console.log("dis-sarfraj->", dis);
                          let discountFeeHeadId =
                            dis.resultDiscount?.fee_head_id;
                          let discountPercent = dis.resultDiscount.percent;
                          let discountAmount = dis.resultDiscount.amount;

                          console.log("discountAmount-->", discountAmount);
                          console.log(
                            "discountFeeHeadId-->",
                            discountFeeHeadId
                          );
                          console.log("discountPercent-->", discountPercent);

                          console.log("non rte");
                          let lineItemAmountByCategory = 0;

                          if (discountFeeHeadId) {
                            for (
                              let i = 0;
                              i <= discountFeeHeadId.length - 1;
                              i++
                            ) {
                              const matchedItem = lineItems.find(
                                (li) =>
                                  li.fee_head_master_id === discountFeeHeadId[i]
                              );
                              lineItemAmountByCategory += matchedItem
                                ? parseInt(matchedItem[categoryAmount])
                                : 0;
                            }
                          }

                          console.log(
                            "lineItemAmountByCategory data ==",
                            lineItemAmountByCategory
                          );

                          if (discountAmount > 0) {
                            discountedAmount += parseInt(discountAmount);
                          } else {
                            discountedAmount += Math.round(
                              (parseInt(lineItemAmountByCategory) *
                                parseInt(discountPercent)) /
                                100
                            );
                          }

                          console.log(`lineItemAmountByCategory-${i}->`, lineItemAmountByCategory);
                        });
                        console.log("discountedAmount-->", discountedAmount);
                        if (recordsForStudentInstallment[i].amount > discountedAmount) {
                          recordsForStudentInstallment[i].installment_discount = discountedAmount;
                        } else {
                          recordsForStudentInstallment[i].installment_discount = recordsForStudentInstallment[i].amount;
                        }
                      }
                    }

                    const resultStudentInstallments = await schoolApi.createStudentFeeInstallments(recordsForStudentInstallment);

                    if (resultStudentInstallments) {
                      console.log(
                        "Student fee transport records created successfully",
                        resultStudentInstallments
                      );
                      if (studentrecord?.stutdentinfo?.lead_id) {
                        console.log("Yes regiestraion");
                        const result3 = await schoolApi.savelead({
                          id: studentrecord?.stutdentinfo.lead_id,
                          firstname: studentrecord?.stutdentinfo.firstname,
                          lastname: studentrecord?.stutdentinfo.lastname,
                          status: "Registered",
                          classid: studentrecord.stutdentinfo.classid,
                          religion: studentrecord.stutdentinfo.religion,
                          dateofbirth: studentrecord.stutdentinfo.dateofbirth,
                          gender: studentrecord.stutdentinfo.gender,
                          email: studentrecord.stutdentinfo.email,
                          adharnumber: studentrecord.stutdentinfo.adharnumber,
                          phone: studentrecord.stutdentinfo.phone,
                          pincode: studentrecord.stutdentinfo.pincode,
                          street: studentrecord.stutdentinfo.street,
                          city: studentrecord.stutdentinfo.city,
                          state: studentrecord.stutdentinfo.state,
                          country: studentrecord.stutdentinfo.country,
                          description: studentrecord.stutdentinfo.description,
                          father_name:
                            studentrecord.parentInfo.Parent_Father.firstname,
                          mother_name:
                            studentrecord.parentInfo.Parent_Mother.firstname,
                          father_qualification:
                            studentrecord.parentInfo.Parent_Father
                              .qualification,
                          mother_qualification:
                            studentrecord.parentInfo.Parent_Mother
                              .qualification,
                          father_occupation:
                            studentrecord.parentInfo.Parent_Father.profession,
                          mother_occupation:
                            studentrecord.parentInfo.Parent_Father
                              .qualification,
                        });
                        if (result3.success) {
                          toast.success("Record save successfully");

                          setTimeout(() => {
                            navigate("/students");
                          }, 2000);
                        } else {
                          console.log("error");
                        }
                      } else {
                        toast.success("Student registered successfully");
                        if (result2?.studentAddmisionRec?.id) {
                          setTimeout(() => {
                            navigate("/studentaddmissions");
                          }, 2000);
                        }
                      }
                    }
                  }
                }
              } else {
                toast.success("Record updated succesfully");
                if (result2?.studentAddmisionRec?.id) {
                  setTimeout(() => {
                    navigate("/studentaddmissions");
                  }, 2000);
                }
              }
            } else {
              toast.error(studentRec.message);
            }
          } else {
            console.error("No primary contact found!");
          }
        } else {
          toast.error(contResult.message);
        }
      }
    } catch (error) {
      toast.error(error?.response?.data?.message);
    }
  };
  const handleNext = () => {
    if (activeStep === 0) {
      if (validateStudentRecord(studentrecord, activeStep)) {
        toast.error("Missing required fields");
      } else {
        console.log("studentrecord =>", studentrecord);
        setActiveStep((prev) => prev + 1);
      }
    } else if (activeStep === 1) {
      if (validateStudentRecord(studentrecord, activeStep)) {
        toast.error("Missing required fields");
      } else {
        console.log("studentrecord =>", studentrecord);
        setActiveStep((prev) => prev + 1);
      }
    } else if (activeStep === 2) {
      setActiveStep((prev) => prev + 1);
    } else {
      toast.error("Something went wrong");
    }
  };

  const handlePrevious = () => {
    if (activeStep > 0) {
      setActiveStep((prev) => prev - 1);
    }
  };

  return (
    <>
      <Helmet>
        <title>{props?.tabName}</title>
      </Helmet>
      <PageNavigations
        id={location.state?.id}
        listName="Student List"
        listPath="/studentaddmissions"
        viewName="Student View"
        viewPath={`/studentaddmissions/${location.state?.id}`}
        colLg={12}
        colClassName="d-flex px-4 py-0 pb-2"
        extrColumn={0}
      />
      <Card className="m-5 custom-card">
        <Container fluid style={{ marginTop: "15px" }}>
          <Row>
            <Col xs={12} md={12} className="mr-auto ml-auto">
              <div className="navigation-header">
                <h2 className="custom-heading pt-4">Student Registration</h2>
                <div className="step-navigation">
                  {steps.map((step, index) => (
                    <button
                      key={index}
                      className={`step-button ${
                        activeStep === index ? "active" : ""
                      }`}
                      onClick={() => setActiveStep(index)}
                    >
                      {step.stepName}
                    </button>
                  ))}
                </div>
              </div>
              <div className="mt-5">
                {activeStep === 0 && (
                  <StudentInfo
                    studentrecord={studentrecord.stutdentinfo}
                    updateStudentRecord={updateStudentRecord}
                    errors={errors}
                  />
                )}
                {activeStep === 1 && (
                  <ParentInfo
                    studentrecord={studentrecord.parentInfo}
                    updateStudentRecord={updateStudentRecord}
                    required={required}
                    handleSearch={handleSearch}
                    setPrimaryParent={handlePrimaryParent}
                  />
                )}
                {/*-------- Added by mohd. sarfraj | 07-oct-2024 --------*/}
                {activeStep === 2 && (
                  <SiblingInfo
                    studentrecord={studentrecord.parentInfo}
                    studentinforecord={studentrecord.stutdentinfo}
                    updateStudentRecord={updateStudentRecord}
                  />
                )}
                {activeStep === 3 && (
                  <PreviousSchoolingInfo
                    studentrecord={studentrecord.previousschool}
                    updateStudentRecord={updateStudentRecord}
                  />
                )}
                {/*------ ./  END by mohd. sarfraj ------*/}
              </div>
              <div className="text-center mt-3">
                {activeStep > 0 && (
                  <Button
                    variant="primary"
                    onClick={handlePrevious}
                    style={{ margin: "5px", fontWeight: "bold" }}
                  >
                    Previous
                  </Button>
                )}
                {activeStep < steps.length - 1 && (
                  <Button
                    variant="primary"
                    onClick={handleNext}
                    style={{ margin: "5px", fontWeight: "bold" }}
                  >
                    Next
                  </Button>
                )}
                {activeStep === steps.length - 1 && (
                  <Button variant="success" onClick={handleFinish}>
                    Finish
                  </Button>
                )}
              </div>
            </Col>
          </Row>
        </Container>
      </Card>

      <ToastContainer
        position="top-right"
        autoClose={2000}
        hideProgressBar={false}
      />
    </>
  );
};
export default StudentMainTab;