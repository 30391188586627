import React, { useState, useEffect } from "react";
import { Col, Container, Form, Row, Button, Modal } from "react-bootstrap";
import schoolApi from "../../api/schoolApi";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Select from "react-select";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { parseISO } from "date-fns";

const AddIssueBook = (props) => {
  const [rowRecord, setRowRecord] = useState({
    parent_id: "",
    parent_type: "",
    parent_reg_id: "",
    book_id: "",
    checkout_date: "",
    due_date: "",
    return_date: "",
    status: "",
    remark: "",
  });
  const [staff, setStaff] = useState([]);
  const [students, setStudents] = useState([]);
  const [books, setBooks] = useState([]);
  const [isRequired, setIsRequired] = useState(false);
  const [bookIssueDates, setBookIssueDates] = useState({
    checkout_date: null,
    due_date: null,
    return_date: null,
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        const staffResult = await schoolApi.fetchStaffContacts();
        if (staffResult.success) {
          let staffdemo = staffResult?.contacts?.map((s) => ({
            value: s.id,
            label: s.contactno,
            staffname: s.staffname,
          }));
          setStaff(staffdemo);
        } else {
          setStaff([]);
        }

        const studentResult = await schoolApi.fetchStudentAddmission();
        let studentdemo = studentResult?.studentAddmision?.map((s) => ({
          value: s?.student_info.id,
          label: s?.student_info.srno,
          studentname: s?.student_info.studentname,
          classname: s?.student_info.classname,
        }));
        setStudents(studentdemo);

        /*------- Updated by mohd. sarfraj | 16-sept-2024 --------*/
        const bookResult = await schoolApi.getBooksRecords();
        if (bookResult?.success) {
          const availableBooks = bookResult.books?.filter(
            (b) => b.status === "Active" && b.available > 0
          );
          const bookdemo = availableBooks?.map((b) => ({
            value: b.id,
            label: b.title,
          }));
          // Added condition | 18-sept-2024.
          if (bookdemo.length) {
            setBooks(bookdemo);
          } else {
            toast.error("Book Are not Availble !");
            setBooks([]);
          }
        }
      } catch (error) {
        console.log("error-->>>>", error);
        toast.error("Un-able to fetch initial data!");
      }
    };
    fetchData();
    setDefaultDates();
  }, []);

  useEffect(() => {
    if (props?.parent?.id) {
      setRowRecord(props?.parent);
      setBookIssueDates((prevState) => ({
        ...prevState,
        checkout_date:
          props?.parent?.checkout_date &&
          parseISO(props?.parent?.checkout_date),
        due_date: props?.parent?.due_date && parseISO(props?.parent?.due_date),
        return_date: props?.parent?.return_date
          ? parseISO(props?.parent?.return_date)
          : null,
      }));
    }
  }, []);

  const handleChange = (name, value) => {
    console.log("name-->>", name);
    if (name === "parent_id") {
      const parent = students
        ?.concat(staff)
        ?.find((item) => item.value === value);
      setRowRecord((prev) => ({
        ...prev,
        parent_id: value,
        parent_reg_id: parent ? parent.srno || parent.contactno : "",
      }));
    } else {
      // Added by mohd. sarfraj | 10-sept-2024. | updated by mohd. sarfraj | 16-sept-2024.
      if (name === "parent_type") {
        setRowRecord((prev) => ({
          ...prev,
          parent_id: null,
          parent_type:
            rowRecord?.parent_type === ""
              ? value
              : rowRecord?.parent_type === "Student"
              ? "Staff"
              : "Student",
          status: props?.parent?.status,
        }));
      } else setRowRecord((prev) => ({ ...prev, [name]: value }));
    }
  };

  /*-------- Due & Check-Out Date set defaut --------*/
  const setDefaultDates = () => {
    if (!props?.parent?.id) {
      const today = new Date();
      const dueDate = new Date(today);
      dueDate.setDate(dueDate.getDate() + 30); // updated by mohd. sarfraj | 18-sept-2024.
      console.log("");

      setRowRecord({
        ...rowRecord,
        checkout_date: parseISO(today.toISOString().split("T")[0]),
        due_date: parseISO(dueDate.toISOString().split("T")[0]),
      });
    }
  };

  const handleSubmit = async (e) => {
    try {
      e.preventDefault();
      if (props?.parent?.id) {
        const editRecord = {
          id: props?.parent?.id,
          parent_id: rowRecord.parent_id,
          parent_type: rowRecord.parent_type,
          book_id: rowRecord.book_id,
          checkout_date: rowRecord.checkout_date,
          due_date: rowRecord.due_date,
          return_date: rowRecord.return_date,
          status: rowRecord.status,
          remark: rowRecord.remark,
        };

        if (
          editRecord.parent_id &&
          editRecord.parent_id.trim() !== "" &&
          editRecord.book_id &&
          editRecord.book_id.trim() !== ""
          // editRecord.status !== "Issued"
        ) {
          if (
            editRecord.status === "Returned" &&
            (!editRecord.return_date || editRecord.return_date.trim() === "")
          ) {
            toast.error("Return date is required for status 'Returned'", {
              position: toast.POSITION.TOP_RIGHT,
            });
            //-----code start Mohd. Sarfraj | 10-sept-2024.
          } else if (
            editRecord.status === "Missing" &&
            (!editRecord.return_date || editRecord.return_date === null)
          ) {
            toast.error("Please Select the Return Date");
          } else if (
            editRecord.status === "Issued" &&
            (props?.parent?.status === "Missing" ||
              props?.parent?.status === "Returned")
          ) {
            toast.error("You Can't issue book in case of Missing OR Return");
          } else if (
            props?.parent?.status === "Returned" &&
            editRecord?.status === "Missing"
          ) {
            toast.error(
              "This Book Already Return , so you can't change Return To Missing"
            );
          } else {
            //-----code end Mohd. Sarfraj | 10-sept-2024.
            let response = {};
            response = await schoolApi.updateIssue(editRecord);
            console.log("response-updated-issue-status---->>>", response);

            if (response.success) {
              toast.success(response.message);
              recordSaveSuccesfully();
            } else {
              setIsRequired(true);
              toast.error(response.message);
            }
          }
        } else {
          setIsRequired(true);
          toast.error("Required field missing!");
        }
      } else {
        if (props?.bookId) rowRecord["book_id"] = props.bookId;
        /*------ Updated By Mohd. Sarfraj | 09-sept-2024 -------*/
        if (
          rowRecord.parent_type &&
          rowRecord.parent_type.trim() !== "" &&
          rowRecord.parent_id &&
          rowRecord.parent_id.trim() !== "" &&
          rowRecord.book_id &&
          rowRecord.book_id.trim() !== ""
        ) {
          rowRecord.status = "Issued";
          let response = await schoolApi.createIssue(rowRecord);
          if (response) {
            toast.success("Record saved successfully!");
            recordSaveSuccesfully();
          } else {
            toast.error(response.message, {
              position: toast.POSITION.TOP_RIGHT,
            });
          }
        } else {
          toast.error("Required field missing!", {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      }
    } catch (error) {
      console.log("error-->>>", error);
      toast.error("Something went wrong!");
    }
  };

  const recordSaveSuccesfully = () => {
    props.recordSaveSuccesfully();
  };

  const handleDateChange = (date, name) => {
    if (date) {
      const day = String(date.getDate()).padStart(2, "0");
      const month = String(date.getMonth() + 1).padStart(2, "0");
      const year = date.getFullYear();

      const dateFormat = `${year}-${month}-${day}`;
      setBookIssueDates((prevState) => ({
        ...prevState,
        [name]: date,
      }));

      setRowRecord({ ...rowRecord, [name]: dateFormat });
    }
  };

  console.log("add-book-issue-rowRecord-->>", rowRecord);

  return (
    <>
      <Modal
        show={props?.show}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        size="lg"
        onHide={props.onHide}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Issue Record
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Container className="view-form">
            <Row>
              <Col lg={12}>
                <Form noValidate>
                  <Row className="pb-4">
                    <Row>
                      <Col lg={6}>
                        <Form.Group className="mt-3">
                          <Form.Label
                            className="form-view-label"
                            htmlFor="formBasicFirstName"
                          >
                            Type
                          </Form.Label>
                          <Form.Select
                            className="book_and_isuue_input"
                            required
                            name="parent_type"
                            value={rowRecord.parent_type}
                            onChange={(e) =>
                              handleChange(e.target.name, e.target.value)
                            }
                          >
                            <option value="" selected disabled>
                              None
                            </option>
                            <option value="Student">Student</option>
                            <option value="Staff">Staff</option>
                          </Form.Select>
                        </Form.Group>
                      </Col>
                      <Col lg={6}>
                        <Form.Group>
                          <Form.Label>Registration Number (Name)</Form.Label>
                          {/* Bug fixed by muskan khan 5sept 2024 */}
                          <Form.Select
                            name="parent_id"
                            required
                            placeholder="Select a Reg No"
                            value={rowRecord?.parent_id || ""}
                            onChange={(e) =>
                              handleChange("parent_id", e.target.value)
                            }
                          >
                            <option value="" disabled selected>
                              Select user
                            </option>
                            {/* Updated by mohd. sarfraj | 18-sept-2024. */}
                            {rowRecord?.parent_type === "Staff" &&
                              staff?.map((staffMember) => (
                                <option
                                  key={staffMember.value}
                                  value={staffMember.value}
                                >
                                  {staffMember.label + " "}
                                  {staffMember.staffname
                                    ? staffMember.staffname
                                    : ""}
                                </option>
                              ))}
                            {rowRecord?.parent_type === "Student" &&
                              students?.map((student) => (
                                <option
                                  key={student.value}
                                  value={student.value}
                                >
                                  {student.label + " "}
                                  {student.studentname
                                    ? student.studentname
                                    : ""}
                                </option>
                              ))}
                            {/* {rowRecord?.parent_type === "Student"
                              ? students?.map((student) => (
                                  <option
                                    key={student.value}
                                    value={student.value}
                                  >
                                    {student.label}{" "}
                                    {student.studentname
                                      ? student.studentname
                                      : ""}
                                  </option>
                                ))
                              : rowRecord?.parent_type === "Staff"
                              ? staff?.map((staffMember) => (
                                  <option
                                    key={staffMember.value}
                                    value={staffMember.value}
                                  >
                                    {staffMember.label}{" "}
                                    {staffMember.staffname
                                      ? staffMember.staffname
                                      : ""}
                                  </option>
                                ))  
                              : ""} */}
                          </Form.Select>
                          {isRequired && !rowRecord?.parent_id && (
                            <p style={{ color: "red" }}>
                              Please select a Reg No
                            </p>
                          )}
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg={6}>
                        <Form.Group>
                          <Form.Label>Book</Form.Label>
                          {props.parent?.book_id && (
                            <Select
                              required={true}
                              className="add-issue-book"
                              placeholder="Select a Book"
                              name="book_id"
                              value={
                                props?.parent?.book_id && {
                                  value: props?.parent?.book_id,
                                  label: books?.find(
                                    (b) => b.value === props?.parent?.book_id
                                  )?.label,
                                }
                              }
                              onChange={(selectedOption) =>
                                handleChange(
                                  "book_id",
                                  selectedOption ? selectedOption.value : null
                                )
                              }
                              options={books}
                              isDisabled={props?.parent?.book_id}
                            />
                          )}
                          {!props?.parent?.book_id && (
                            <Select
                              required={true}
                              className="add-issue-book"
                              placeholder="Select a Book"
                              name="book_id"
                              value={
                                props.parent?.id
                                  ? {
                                      value: props.parent?.book_title,
                                      label: props.parent?.book_title,
                                    }
                                  : rowRecord?.book_id
                                  ? {
                                      value: rowRecord.book_id,
                                      label: books?.find(
                                        (b) => b.value === rowRecord.book_id
                                      )?.label,
                                    }
                                  : ""
                              }
                              onChange={(selectedOption) =>
                                handleChange(
                                  "book_id",
                                  selectedOption ? selectedOption.value : null
                                )
                              }
                              options={books}
                            />
                          )}
                          {isRequired && !rowRecord.book_id && (
                            <p style={{ color: "red" }}>Please select a Book</p>
                          )}
                        </Form.Group>
                      </Col>
                      <Col lg={6}>
                        <Form.Group className="my-3">
                          <Form.Label className="form-view-label">Issue Date</Form.Label>
                          <DatePicker
                            required
                            selected={ bookIssueDates.checkout_date ? bookIssueDates.checkout_date : rowRecord?.checkout_date}
                            onChange={(date) => handleDateChange(date, "checkout_date")}
                            dateFormat="dd-MM-yyyy"
                            showMonthDropdown
                            showYearDropdown
                            dropdownMode="select"
                            placeholderText="Select a date"
                            className="form-control"
                            style={{ width: "300px" }}
                            onKeyDown={(e) => e.preventDefault()}
                            highlightDates={[new Date()]}
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg={6}>
                        <Form.Group className="my-3">
                          <Form.Label className="form-view-label">Due Date</Form.Label>
                          <DatePicker
                            required
                            selected={ bookIssueDates?.due_date ? bookIssueDates.due_date : rowRecord.due_date }
                            onChange={(date) => handleDateChange(date, "due_date")}
                            dateFormat="dd-MM-yyyy"
                            showMonthDropdown
                            showYearDropdown
                            dropdownMode="select"
                            placeholderText="Select a date"
                            className="form-control"
                            style={{ width: "300px" }}
                            onKeyDown={(e) => e.preventDefault()}
                            highlightDates={[new Date()]}
                          />
                        </Form.Group>
                      </Col>
                      {props?.parent?.id && (
                        <Col lg={6}>
                          <Form.Group className="my-3">
                            <Form.Label className="form-view-label">Return Date</Form.Label>
                            <DatePicker
                              required
                              selected={bookIssueDates?.return_date ? bookIssueDates.return_date : rowRecord.return_date}
                              onChange={(date) => handleDateChange(date, "return_date")}
                              dateFormat="dd-MM-yyyy"
                              showMonthDropdown
                              showYearDropdown
                              dropdownMode="select"
                              placeholderText="Select a date"
                              className="form-control"
                              style={{ width: "300px" }}
                              onKeyDown={(e) => e.preventDefault()}
                            />
                          </Form.Group>
                        </Col>
                      )}
                    </Row>
                    <Row>
                      {props?.parent?.id && (
                        <Col lg={6}>
                          <Form.Group className="my-3">
                            <Form.Label
                              className="form-view-label"
                              htmlFor="formBasicFirstName"
                            >
                              Status
                            </Form.Label>
                            <Form.Select
                              className="book_and_isuue_input"
                              required
                              name="status"
                              value={rowRecord.status}
                              onChange={(e) =>
                                handleChange("status", e.target.value)
                              }
                              placeholder="Status"
                            >
                              <option value="Issued">Issued</option>
                              <option value="Returned">Returned</option>
                              <option value="Missing">Missing</option>
                            </Form.Select>
                          </Form.Group>
                        </Col>
                      )}
                    </Row>
                    <Col lg={12}>
                      <Form.Group className="my-3">
                        <Form.Label
                          className="form-view-label"
                          htmlFor="formBasicFirstName"
                        >
                          Remark
                        </Form.Label>
                        <Form.Control
                          as="textarea"
                          name="remark"
                          placeholder="Remark"
                          value={rowRecord.remark}
                          onChange={(e) =>
                            handleChange("remark", e.target.value)
                          }
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                </Form>
              </Col>
            </Row>
          </Container>
        </Modal.Body>
        <Modal.Footer>
          {props?.parent?.id && (
            <Button variant="success" onClick={handleSubmit}>
              Update
            </Button>
          )}
          {!props?.parent?.id && (
            <Button variant="success" onClick={handleSubmit}>
              Issued
            </Button>
          )}
          <Button onClick={props.onHide} variant="light">
            Close
          </Button>
        </Modal.Footer>
        {/* <ToastContainer
          position="top-right"
          autoClose={2000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
        /> */}
      </Modal>
    </>
  );
};
export default AddIssueBook;
