import React, { useState, useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";

import PageNavigations from "../breadcrumbs/PageNavigations";
import SiblingTab from "../tabNavigation/SiblingTab";
import ParentTab from "../tabNavigation/ParentTab";
import StudentTab from "../tabNavigation/StudentTab";
import schoolApi from "../../api/schoolApi";
import PreviousSchoolingTab from "../tabNavigation/PreviousSchoolingTab";
import { Helmet } from "react-helmet";
import "../../resources/css/Student.css";
import { ToastContainer, toast } from "react-toastify";
const StudentEdit = (props) => {
  const location = useLocation();
  console.log("location data on final stage-->", location?.state);
  const navigate = useNavigate();
  const [validated, setValidated] = useState(false);
  const [validatedForParent, setvalidatedForParent] = useState(false);
  const [activeStep, setActiveStep] = useState(0);
  const [isParent, setIsParent] = useState("");
  const [discountRecords, setDiscountRecords] = useState();
  const [assignTransportRecord, setAssignTransportRecord] = useState({});
  const [feeMasterId, setFeeMasterId] = useState();
  const [feeCategory, setFeeCategory] = useState();
  const [currentYear] = useState(() => new Date().getFullYear());
  // const [studentCategory, setStudentCategory] = useState();
  const [lead, setLead] = useState(location?.state);
  const [previousVal, setPreviousVal] = useState("");
  const [student, setStudent] = useState(
    location?.state ? location?.state : []
  );
  const [familyInfo, setFamilyInfo] = useState({
    fatherfirstname: location?.state?.father_name?.split(" ")[0] || "",
    fatherlastname: location?.state?.father_name?.split(" ")[1] || "",
    fatherprofession: location?.state?.father_occupation || "",
    fatherqualification: location?.state?.father_qualification || "",
    fatherphone: "",
    fatheremail: "",
    fatherRecordtype : "Parent_Father",
    // primarypar: "",
    motherfirstname: location?.state?.mother_name?.split(" ")[0] || "",
    motherlastname: location?.state?.mother_name?.split(" ")[1] || "",
    motherphone: "",
    motheremail: "",
    motherprofession: location?.state?.mother_occupation || "",
    motherqualification: location?.state?.mother_qualification || "",
    motherRecordtype : "Parent_Mother",
    // primarymoth: "",
    guardianfirstname: "",
    guardianlastname: "",
    guardianphone: "",
    guardianemail: "",
    primaryparent: "",
    // primaryGuar : "",
    // primaryguar: "",
    guardianRecordtype : "Parent_Guardian",
  });

  const [priviousSchool, setPriviousSchool] = useState({
    school_name: "",
    student_id: "",
  });
  console.log("hament bhai");

  const [validatedForPreSchool, setValidatedForPreSchool] = useState(false);
  const steps = [
    { stepName: "Student Information", component: StudentTab },
    { stepName: "Parent Information", component: ParentTab },
    { stepName: "Sibling Information", component: SiblingTab },
    { stepName: "Previous Schooling", component: PreviousSchoolingTab },
  ];

  const [isSearch, setIsSearch] = useState(false);
  //Added By Shahir Hussain 27-08-2024
  const [currentMonthName] = useState(() => {
    const monthNames = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    return monthNames[new Date().getMonth()];
  });

  const [totalMonths, setTotalMonths] = useState([
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ]);
  const [currentMonth, setCurrentMonth] = useState(
    () => new Date().getMonth() + 1
  );
  //added by shahir hussain
  const [currentDate] = useState(() => new Date().getDate() + 1);
  //End By Shahir Hussain 27-08-2024

  useEffect(() => {
    if (location?.state) {
      setStudent(location?.state);
    }
  }, []);

  const StepComponent = steps[activeStep].component;
  const handleParentsInfo = (data) => {
    console.log("handleParentsInfo@@=>", data);
    setIsParent(data);
  };

  const handleCheckGuar = (checkVal) => {
    console.log("handleCheckGuar", checkVal);
    if (checkVal?.primaryguar === true) {
      console.log("in the ifyyy");
      setIsParent(true);
    } else {
      setIsParent(false);
    }
  };

  const handleInsertedParents = (data) => {
    console.log("handleInsertedParents@@!@!#=>", data);
    setFamilyInfo({ ...data });
  };
  const handleParentData = (data) => {
    console.log("handleParentData@@@+>", data);
    if (data) {
      console.log("KLLKK");
      if (
        data?.father_recordtype === "Parent_Father" ||
        data?.mother_recordtype === "Parent_Mother" ||
        data?.guardian_recordtype === "Parent_Guardian"
      ) {
        console.log("first");
        setFamilyInfo({
          ...familyInfo,
          fatherfirstname: data?.fatherfirstname,
          motherfirstname: data?.motherfirstname,
          guardianfirstname: data?.guardianfirstname,
        });
      } else {
        console.log("secondyy");
        setIsSearch(true);
        if (
          Object.keys(data)?.length > 0 &&
          (data.hasOwnProperty("father_id") ||
            data.hasOwnProperty("mother_id") ||
            data.hasOwnProperty("guardian_id"))
        ) {
          console.log("Inn7898990");
          setFamilyInfo(data);
        } else if (data[0]?.recordtype === "Parent_Father") {
          console.log("first789o");

          setFamilyInfo((familyInfo) => ({
            ...familyInfo,

            fatherfirstname: data[0].fatherfirstname,

            fatherlastname: data[0].fatherlastname,

            fatherphone: data[0].fatherphone,

            fatheremail: data[0].fatheremail,

            recordtype: data[0].recordtype,

            primarypar: data[0].primarypar,
          }));
        }
        if (data[1]?.recordtype === "Parent_Mother") {
          console.log("famillyiNddo=>", familyInfo);
          setFamilyInfo((familyInfo) => ({
            ...familyInfo,
            motherfirstname: data[1].motherfirstname,
            motherlastname: data[1].motherlastname,
            motherphone: data[1].motherphone,
            motheremail: data[1].motheremail,
            recordtype: data[1].recordtype,
            primarymoth: data[1].primarymoth,
          }));
        } else if (data[2]?.recordtype === "Parent_Guardian") {
          setFamilyInfo({
            ...familyInfo,
            guardianfirstname: data[2].firstname,
            guardianlastname: data[2].lastname,
            guardianphone: data[2].phone,
            guardianemail: data[2].email,
            recordtype: data[2].recordtype,
            primaryguar: data[2].is_primary,
          });
        }
        if (data[0]?.primarypar === true) {
          console.log("first");
          setIsParent(data[0].id);
        } else if (data[1]?.primarymoth === true) {
          setIsParent(data[1].id);
        } else if (data[2]?.primaryguar === true) {
          setIsParent(data[2].id);
        }
      }
    }
  };
  const getCurrentDate = () => {
    const today = new Date();
    const year = today.getFullYear();
    let month = today.getMonth() + 1;
    let day = today.getDate();

    if (month < 10) {
      month = "0" + month;
    }
    if (day < 10) {
      day = "0" + day;
    }

    const currentDate = `${year}-${month}-${day}`;
    console.log("currentDate@@+>", currentDate);
    return currentDate;
  };
  const handleStudentTab = (data) => {
    console.log("data--> on handleStudentTab-->", data);
    console.log("data?.assign_transport>", data?.assign_transport);
    //  student.vehicleid = data?.assign_transport?.transport_id;
    if (data?.assign_transport) {
      console.log("firsty");
      setAssignTransportRecord(data?.assign_transport);
    } else if (data?.discounts) {
      console.log("sidky");
      setDiscountRecords(data?.discounts);
    } else if (data?.fee_type) {
      console.log("feety");
      setStudent({ ...data });
      setFeeMasterId(data?.fee_type);
      student.fee_type = data?.fee_type;
    } else if (data?.isLateAdmission) {
      setStudent((prevState) => ({
        ...prevState,
        isLateAdmission: data?.isLateAdmission,
      }));
    } else if (data?.category) {
      console.log("katty");
      setStudent(data);
      if (data?.category === "General") {
        setFeeCategory("general_fee");
      } else if (data?.category === "Obc") {
        setFeeCategory("obc_fee");
      } else if (data?.category === "Sc") {
        setFeeCategory("sc_fee");
      } else if (data?.category === "St") {
        setFeeCategory("st_fee");
      }
    } else {
      console.log("Nitty");
      setStudent(data);
    }
  };

  const handlePreviousSchool = (data) => {
    setPriviousSchool(data);
  };

  ///---------HANDLE FINISH IS HERE --------------------
  const handleFinish = async (e) => {
    e.preventDefault();
    console.log("student@@=>", student);
    console.log("priviousSchool@@=>", priviousSchool);
    let result = "";
    try {
      if (student?.id) {
        result = await schoolApi.fetchStudentbyId(student?.id);
        console.log("result=>", result);
      }

      if ((result.success === true && student?.id) || priviousSchool?.id) {
        console.log("STudentg=>", student);
        const updateStudent = await schoolApi.updateStudent(student);
        console.log("updateStudent@@@-=>", updateStudent);

        let studentadmission = {
          id: location?.state?.student_addmission_id,
          studentid: location?.state?.studentid,
          parentid: location?.state?.parentid,
          classid: updateStudent?.result?.classid,
          section_id: updateStudent?.result?.section_id,
          previous_school_id: location?.state?.previous_school_id,
          fee_type: student?.fee_type,
        };
        const updateStudentAdd = await schoolApi.saveStudentAddmission(
          studentadmission
        );
        console.log("updateStudentAdd@@@-=>", updateStudentAdd);

        console.log("familyInfo-->", familyInfo);
        if (
          familyInfo.father_id ||
          familyInfo.mother_id ||
          familyInfo.guardian_id
        ) {
          //Update Contact
          const updateContact = await schoolApi.updateContactById(familyInfo);
          console.log("updateContact@-=>", updateContact);
        }
        //Previous School
        if (priviousSchool?.id) {
          const updatePreviousSchool = await schoolApi.savePreviousSchool(
            priviousSchool
          );
          console.log("updatePreviousSchool@@@-=>", updatePreviousSchool);
          toast.success(updatePreviousSchool?.message, {
            position: toast.POSITION.TOP_CENTER,
            hideProgressBar: true,
          });
          navigate("/studentaddmissions/");
        }
        toast.success("Record save successfully", {
          position: toast.POSITION.TOP_CENTER,
          hideProgressBar: true,
        });
        // navigate("/studentaddmissions");
        //const editAdmission = location?.state?.student_addmission_id;
        console.log("location?.state-->@@=>", location?.state);
        console.log(
          "studentaddmissions@@@=>",
          location?.state?.student_addmission_id
        );
        navigate(
          `/studentaddmissions/${location?.state?.student_addmission_id}`,
          {
            state: { ...location?.state },
          }
        );
      } else {
        console.log("session_id latest-->", student);
        const sessions = await schoolApi.fetchSessions();
        console.log("pawan getting all sessions-->", sessions);
        let feeMasterType = null;
        if (sessions) {
          const currentYear = new Date().getFullYear();
          const nextYear = currentYear + 1;

          const fetchSelectedSessionResult = sessions?.find((res) => {
            return res.year === `${currentYear}-${nextYear}`;
          });
          console.log(
            "fetchSelectedSessionResult--> xyz",
            fetchSelectedSessionResult
          );

          if (!fetchSelectedSessionResult) {
            return toast.error("Create session records first!!");
          }

          student.session_id = fetchSelectedSessionResult?.id;
        }
        if (
          student !== "" &&
          familyInfo !== "" &&
          priviousSchool !== "" &&
          props.rteUrlName === "rte"
        ) {
          student.isrte = true;
          const result = await schoolApi.createContOnRecType(familyInfo);
          const getParentCont = await schoolApi.fetchParentContacts();
          getParentCont?.contacts?.forEach((item) => {
            if (item.phone === student.phone) {
              student.parentId = item.id;
            }
          });
          const fee_type = student?.fee_type;

          const studentRec = await schoolApi.createStudent(student);
          console.log("studentRec-->rte", studentRec);
          priviousSchool.student_id = studentRec.result.id;
          priviousSchool.student_phone = studentRec.result.phone;
          const previousSchoolRec = await schoolApi.createPreviousSchool(
            priviousSchool
          );
          if (previousSchoolRec.message) {
            toast.error(previousSchoolRec.message, {
              position: toast.POSITION.TOP_RIGHT,
            });
          } else {
            console.log("first");
            const arr = {
              studentid: studentRec?.result.id,
              classid: student.classid,
              section_id: student.sectionid,
              parentid: studentRec?.result.parentid,
              fee_type: fee_type,
              session_id: student?.session_id,
              dateofaddmission: getCurrentDate(),
              year: currentYear,
              isrte: true,
            };

            console.log("arr-rte->", arr);
            const result2 = await schoolApi.createStudentAddmission(arr);
            toast.success(result2?.message, {
              position: toast.POSITION.TOP_CENTER,
              hideProgressBar: true,
            });
            if (
              lead &&
              typeof lead === "object" &&
              Object.keys(lead).length > 0
            ) {
              console.log("Yes regiestraion");
              lead.status = "Registered";
              result = await schoolApi.savelead(lead);
              if (result.success) {
                toast.success("Record save successfully", {
                  position: toast.POSITION.TOP_CENTER,
                  hideProgressBar: true,
                });
                navigate(`/students/${result.lead.resultCon.id}`, {
                  state: lead,
                });
              }
            }
            if (result2?.studentAddmisionRec?.id) {
              navigate("/rte");
            }
          }
        } else if (student !== "" && familyInfo !== "") {
          // console.log('Yes^&&',lead)
          console.log("familyInfo", familyInfo);

          const contResult = await schoolApi.createContOnRecType(familyInfo);
          console.log("result@@@@=>", contResult);

          student.parentId = contResult.contactRec
            ? contResult?.contactRec
            : isParent;

          //Changed by Pooja Vaishnav(01-07-2024)
          if (student?.class_id) {
            student.classid = student?.class_id;
          }
          const student_fee_type = student?.fee_type;
          console.log("after student=>", student);
          const studentRec = await schoolApi.createStudent(student);
          console.log("@@@studentRec-->", studentRec);

          // const arr = {
          //   studentid: studentRec.result.id,
          //   classid: student.classid,
          //   section_id: student.sectionid,
          //   parentid: contResult.contactRec ? contResult?.contactRec : isParent,
          //   fee_type: student_fee_type,
          //   session_id: student?.session_id,
          //   dateofaddmission: getCurrentDate(),
          //   year: currentYear,
          //   isrte: false,
          //   previous_school_id: null,
          // };
          const arr = {
            studentid: studentRec.result.id,
            classid: student.classid,
            section_id: student.sectionid,
            parentid: contResult.contactRec ? contResult?.contactRec : isParent,
            fee_type: student_fee_type,
            session_id: student?.session_id,
            dateofaddmission: getCurrentDate(),
            year: currentYear,
            isrte: false,
            previous_school_id: null,
            islateadmission: student?.isLateAdmission,
          };
          //----------for normal regular student ------------
          if (
            priviousSchool?.school_name !== "" &&
            priviousSchool?.school_name !== undefined
          ) {
            priviousSchool.student_id = studentRec.result.id;
            //priviousSchool.student_phone = studentRec.result.phone;
            const previousSchoolRec = await schoolApi.createPreviousSchool(
              priviousSchool
            );
            console.log("previousSchoolRec-->", previousSchoolRec);
            console.log(
              "priviousSchool?.result?.id@@=>",
              previousSchoolRec?.result?.id
            );
            arr.previous_school_id = previousSchoolRec?.result?.id;
          }

          const result2 = await schoolApi.createStudentAddmission(arr);
          console.log("result2-->", result2);
          //commented By Me
          if (
            lead &&
            typeof lead === "object" &&
            Object.keys(lead).length > 0 &&
            lead?.status === "Not Registered"
          ) {
            console.log("No regiestraion");
            lead.status = "Registered";
            const leadresult = await schoolApi.savelead(lead);
            if (leadresult?.success) {
              toast.success("Record save successfully", {
                position: toast.POSITION.TOP_CENTER,
                hideProgressBar: true,
              });
              navigate(`/students/${leadresult.lead.resultCon.id}`, {
                state: lead,
              });
            }
          }

          let discountLineItms = [];
          if (discountRecords && result2?.studentAddmisionRec?.id) {
            console.log("going to create discount records-->");
            let discountLineItemRecord = {
              student_addmission_id: result2?.studentAddmisionRec?.id,
              discounts: discountRecords,
            };
            const discountLineItemsResult =
              await schoolApi.createFeeDiscountLineItems(
                discountLineItemRecord
              );
            console.log(
              "discountLineItemsResult after create-->",
              discountLineItemsResult
            );
            discountLineItms = discountLineItemsResult;
          }

          console.log("discountLineItms-->", discountLineItms);

          let resultCreateAssignTransport = {};
          if (assignTransportRecord && result2?.studentAddmisionRec?.id) {
            let obj = {
              ...assignTransportRecord,
              student_addmission_id: result2?.studentAddmisionRec?.id,
              sessionid: student?.session_id,
            };
            console.log("obj for creating assignTransport-->", obj);
            console.log(
              "resultCreateAssignTransport-->",
              resultCreateAssignTransport
            );
          }

          if (feeMasterId && result2?.studentAddmisionRec?.id) {
            const getFeeMasterTypeResult = await schoolApi.fetchFeeMaster(
              feeMasterId
            );
            feeMasterType = getFeeMasterTypeResult.result[0]?.type;
            console.log("getFeeMasterTypeResult-->", getFeeMasterTypeResult);
            console.log("feeMasterType-->", feeMasterType);

            let feeMasterRecord =
              await schoolApi.getMasterInstallmentByFeeMasterId(feeMasterId);
            console.log("feeMaster installment Record-->", feeMasterRecord);
            const fetchSelectedSessionResult = await schoolApi.getSessionById(
              student?.session_id
            );
            console.log(
              "fetchSelectedSessionResult--> xyz",
              fetchSelectedSessionResult
            );

            //This Code Added By Shahir Hussain 27-08-2024
            const currentDate = new Date().toISOString().slice(0, 10);
            const lateMonths = [];
            let sessionStartMonth =
              fetchSelectedSessionResult?.result?.startdate.split("-")[1];
            let sessionEndMonth =
              fetchSelectedSessionResult?.result?.enddate.split("-")[1];
            const currentIndex = currentMonth - 1; // Index of "August" is 8
            const endIndex = sessionEndMonth - 1; // index of "March" is 2

            // const startIndex = sessionStartMonth - 1;
            let monthsToLoop = [];
            // let flage = false;
            let monthArray = [];
            let firstMonth;
            // // preview filter code
            // if (endIndex < currentIndex) {
            //   monthsToLoop = [
            //     ...totalMonths.slice(currentIndex),
            //     ...totalMonths.slice(0, endIndex + 1),
            //   ];
            // } else {
            //   monthsToLoop = totalMonths.slice(currentIndex, endIndex + 1);
            // }
            // console.log("handle Finish monthsToLoop == ", monthsToLoop);
            // //---commented by pawan -----------
            monthsToLoop = totalMonths;
            feeMasterRecord.map((record, index) => {
              firstMonth = record.month.split(" to " || " ")[0];
              monthsToLoop.map((month, index) => {
                if (month == firstMonth) {
                  console.log("index ->", index);
                  console.log("endIndex index->", endIndex);
                  console.log("currentIndex index->", currentIndex);
                  if (index > endIndex && index < currentIndex) {
                    record.isLateAdmission = student.isLateAdmission;
                  } else {
                    record.isLateAdmission = false;
                  }
                  monthArray.push(record);
                }
              });
            });
            // //-------comment end by pawan ----------
            // console.log("handle Finish After data bi-month == ", monthArray);
            feeMasterRecord = monthArray;

            // --- formatting due_date
            const sessionDates = {};
            const startdate = fetchSelectedSessionResult?.result?.startdate;
            const enddate = fetchSelectedSessionResult?.result?.enddate;

            const startDate = new Date(startdate);
            const endDate = new Date(enddate);

            console.log("Parsed startdate:", startDate);
            console.log("Parsed enddate:", endDate);

            const generateInstallmentDates = (start, end) => {
              const dates = [];
              let currentDate = new Date(start);

              while (currentDate <= end) {
                dates.push(new Date(currentDate));
                const monthName = currentDate.toLocaleString("default", {
                  month: "long",
                });
                const formattedDate = currentDate.toISOString().split("T")[0];
                sessionDates[monthName] = formattedDate;
                currentDate.setMonth(currentDate.getMonth() + 1);

                if (currentDate.getDate() !== start.getDate()) {
                  currentDate.setDate(0);
                }
              }

              if (dates[dates.length - 1] < end) {
                dates.push(new Date(end));
                const monthName = end.toLocaleString("default", {
                  month: "long",
                });
                const formattedDate = end.toISOString().split("T")[0];
                sessionDates[monthName] = formattedDate;
              }

              return dates;
            };

            const installmentDates = generateInstallmentDates(
              startDate,
              endDate
            );
            console.log("ppx Generated installment dates:", installmentDates);
            console.log("ppx Session dates:", sessionDates);

            installmentDates.forEach((date) => {
              console.log(
                `ppx Installment Date--> ${date.toISOString().split("T")[0]}`
              );
            });

            let pendingDueResult = [];
            const ResultSettings = await schoolApi.getSettingByKey(
              "pending_due_day"
            );
            console.log("ResultSettings-->", ResultSettings);
            if (ResultSettings.success) {
              pendingDueResult.push(ResultSettings.data[0]);
            } else {
              pendingDueResult([]);
            }

            console.log("pendingDueResult-->", pendingDueResult);
            let transportFeeByInstall = 0;
            if (resultCreateAssignTransport) {
              // transportFeeByInstall = parseInt(resultCreateAssignTransport?.result?.fare_amount) / feeMasterRecord.length
              transportFeeByInstall = parseInt(
                resultCreateAssignTransport?.result?.fare_amount
              );
            }

            if (feeMasterType === "Bi-Monthly") {
              transportFeeByInstall = parseInt(transportFeeByInstall) * 2;
            } else if (feeMasterType === "Quarterly") {
              transportFeeByInstall = parseInt(transportFeeByInstall) * 3;
            } else if (feeMasterType === "Half Yearly") {
              transportFeeByInstall = parseInt(transportFeeByInstall) * 6;
            } else if (feeMasterType === "Yearly") {
              transportFeeByInstall = parseInt(transportFeeByInstall) * 12;
            }

            console.log("transportFeeByInstall-->", transportFeeByInstall);
            let recordsForStudentInstallment = [];
            if (feeMasterRecord) {
              const transportFee = Math.ceil(transportFeeByInstall || 0);
              console.log("transportFee-->", transportFee);
              console.log(
                "student.isLateAdmission ->",
                student.isLateAdmission
              );

              feeMasterRecord.forEach((res, index) => {
                console.log("res-@@->", res);
                let splittedMonth = res?.month?.split(" ");
                console.log("splittedMonth-->", splittedMonth);

                let lastDate = new Date(sessionDates[splittedMonth[0]]);
                console.log("lastDate-1->", lastDate);

                if (pendingDueResult[0].value) {
                  lastDate.setDate(pendingDueResult[0].value);
                }

                let installmentDate = lastDate.toISOString().slice(0, 10);

                console.log("rrx installmentDate-->", installmentDate);

                recordsForStudentInstallment.push({
                  student_addmission_id: result2?.studentAddmisionRec?.id,
                  fee_master_installment_id: res?.id,
                  amount: res[feeCategory],
                  status: "pending",
                  orderno: index + 1,
                  due_date: installmentDate,
                  late_joinee: res.isLateAdmission,
                  session_id: student?.session_id,
                  transport_fee: transportFee,
                  assign_transport_id: resultCreateAssignTransport?.result?.id
                    ? resultCreateAssignTransport?.result?.id
                    : null,
                  month: res?.month,
                  installment_discount: 0,
                });
              });

              console.log(
                "student installments to be created-->",
                recordsForStudentInstallment
              );

              //-start--------------Added By pawan for discounts ------------

              let categoryAmount = "";
              if (student.category === "General") {
                categoryAmount = "general_amount";
              } else if (student.category === "Obc") {
                categoryAmount = "obc_amount";
              } else if (student.category === "Sc") {
                categoryAmount = "sc_amount";
              } else if (student.category === "St") {
                categoryAmount = "st_amount";
              }

              if (discountLineItms.length > 0) {
                for (
                  let i = 0;
                  i <= recordsForStudentInstallment.length - 1;
                  i++
                ) {
                  const lineItems = await schoolApi.getLineItemsByFeeMasterId(
                    recordsForStudentInstallment[i].fee_master_installment_id
                  );
                  console.log("lineItems-@@studentEdit->", lineItems);
                  let discountedAmount = 0;
                  discountLineItms.map((dis) => {
                    console.log("dis-sarfraj->", dis);
                    let discountFeeHeadId = dis.resultDiscount?.fee_head_id;
                    let discountPercent = dis.resultDiscount.percent;
                    let discountAmount = dis.resultDiscount.amount;

                    console.log("discountAmount-->", discountAmount);
                    console.log("discountFeeHeadId-->", discountFeeHeadId);
                    console.log("discountPercent-->", discountPercent);

                    console.log("non rte");
                    let lineItemAmountByCategory = 0;

                    if (discountFeeHeadId) {
                      for (let i = 0; i <= discountFeeHeadId.length - 1; i++) {
                        const matchedItem = lineItems.find(
                          (li) => li.fee_head_master_id === discountFeeHeadId[i]
                        );
                        lineItemAmountByCategory += matchedItem
                          ? parseInt(matchedItem[categoryAmount])
                          : 0;
                      }
                    }

                    console.log(
                      "lineItemAmountByCategory data ==",
                      lineItemAmountByCategory
                    );

                    if (discountAmount > 0) {
                      discountedAmount += parseInt(discountAmount);
                    } else {
                      discountedAmount += Math.round(
                        (parseInt(lineItemAmountByCategory) *
                          parseInt(discountPercent)) /
                          100
                      );
                    }

                    console.log(
                      `lineItemAmountByCategory-${i}->`,
                      lineItemAmountByCategory
                    );
                    // }
                  });
                                    //comment by shahir hussain
                console.log("discountedAmount-->", discountedAmount);
                if (recordsForStudentInstallment[i].amount > discountedAmount) {
                  recordsForStudentInstallment[i].installment_discount =
                    discountedAmount;
                } else {
                  recordsForStudentInstallment[i].installment_discount =
                    recordsForStudentInstallment[i].amount;
                }


                  // console.log("discountedAmount-->", discountedAmount);
                  // recordsForStudentInstallment[i].installment_discount =
                  //   discountedAmount;
                }
              }

              console.log(
                "recordsForStudentInstallment-->",
                recordsForStudentInstallment
              );

              // -----end managing discount-----------------------------------------------------

              const resultStudentInstallments =
                await schoolApi.createStudentFeeInstallments(
                  recordsForStudentInstallment
                );
              console.log(
                "resultStudentInstallments",
                resultStudentInstallments
              );

              if (resultStudentInstallments) {
                console.log(
                  "student fee transport records created successfully",
                  resultStudentInstallments
                );
              }
            }
          }

          toast.success("Record save successfully", {
            position: toast.POSITION.TOP_CENTER,
            hideProgressBar: true,
          });
          if (result2?.studentAddmisionRec?.id) {
            navigate("/studentaddmissions");
          }
        }
        // }
      }
    } catch (error) {
      console.log("error=>", error);
      toast.error(error?.response?.data?.message);
    }
  };
  const handleNext = async (e) => {
    console.log("handle next", student, activeStep);
  familyInfo.primaryparent = familyInfo.recordtype;
    // console.log('leadd=>',lead)
    e.preventDefault();

    if (
      (!student.firstname ||
        (student?.class_id ? !student.class_id : !student.classid) ||
        !student.sectionid ||
        !student.category ||
        !student.phone ||
        !student.fee_type) &&
      activeStep === 0
    ) {
      console.log("handle next 1", student);
      if (checkRequredFields()) {
        console.log("first111");
        setValidated(true);
        toast.error("Fill all required fileds!!!");
      }
      console.log("Hey yes");
    } else if (
      ((!familyInfo.fatherfirstname ||
        !familyInfo.fatherlastname ||
        !familyInfo.fatherphone) &&
        familyInfo.primaryparent === "Parent_Father" &&
        activeStep === 1) ||
      ((!familyInfo.motherfirstname ||
        !familyInfo.motherlastname ||
        !familyInfo.motherphone) &&
        familyInfo.primaryparent === "Parent_Mother" &&
        activeStep === 1) ||
      ((!familyInfo.guardianfirstname ||
        !familyInfo.guardianlastname ||
        !familyInfo.guardianphone) &&
        familyInfo.primaryparent === "Parent_Guardian" &&
        activeStep === 1)
    ) {
      console.log("handle next 2", familyInfo);
      if (checkRequredFields1()) {
        setvalidatedForParent(true);
        toast.error("Fill all required fields!!!");
      }
    } else if (
      (!familyInfo.primaryparent === "Parent_Father" || !familyInfo.primaryparent === "Parent_Mother" 
      || !familyInfo.primaryparent === "Parent_Guardian") &&
      activeStep === 1
    ) {
      toast.error("Please provide the parent's details.");
    } else {
      console.log("handle next 3", student);
      console.log("student.edit==>", student);

      if (student?.id) {
        setActiveStep(activeStep + 1);
        setValidated(false);
        setvalidatedForParent(false);
      } else {
        try {
          const resultStuDup = await schoolApi.checkStudentDuplicacy(student);
          console.log("resultStuDup@@@=>", resultStuDup);
          if (
            familyInfo.fatherphone !== "" ||
            familyInfo.motherphone !== "" ||
            familyInfo.guardianphone !== ""
          ) {
            console.log("familyInfo211`1=>", familyInfo);
            if (isSearch === false) {
              const resultContDup = await schoolApi.checkContactDuplicacy(
                familyInfo
              );
              setActiveStep(activeStep + 1);
              setValidated(false);
              setvalidatedForParent(false);
            } else {
              setActiveStep(activeStep + 1);
              setValidated(false);
              setvalidatedForParent(false);
            }
          } else {
            setActiveStep(activeStep + 1);
            setValidated(false);
            setvalidatedForParent(false);
          }
        } catch (error) {
          console.log("errpr=>", error);
          toast.error(error?.response?.data?.message);
        }
      }
    }
  };
  const checkRequredFields = () => {
    console.log("checkRequredFields@@@=>", student);
    if (
      student &&
      student.firstname &&
      student.firstname.trim() !== "" &&
      ((student.class_id && student.class_id.trim() !== "") ||
        (student.classid && student.classid.trim() !== "")) &&
      student.sectionid &&
      student.sectionid.trim() !== "" &&
      student.category &&
      student.category.trim() !== "" &&
      student.phone &&
      student.phone.trim() !== "" &&
      student.fee_type &&
      student.fee_type.trim() !== ""
    ) {
      console.log("Yest");
      return false;
    } else {
      console.log("notee");
      return true;
    }
  };

  const isFatherInfoComplete =
    familyInfo.fatherfirstname &&
    familyInfo.fatherfirstname?.trim() !== "" &&
    familyInfo.fatherlastname &&
    familyInfo.fatherlastname?.trim() !== "" &&
    familyInfo.fatherphone &&
    familyInfo.fatherphone?.trim() !== "";

  const isMotherInfoComplete =
    familyInfo.motherfirstname?.trim() !== "" &&
    familyInfo.motherlastname?.trim() !== "" &&
    familyInfo.motherphone?.trim() !== "";

  const isGuardianInfoComplete =
    familyInfo.guardianfirstname?.trim() !== "" &&
    familyInfo.guardianlastname?.trim() !== "" &&
    familyInfo.guardianphone?.trim() !== "";

  const checkRequredFields1 = () => {
    console.log("first familyInfo", familyInfo);
    if (
      familyInfo.father_name &&
      familyInfo.father_name.trim() !== "" &&
      familyInfo.father_qualification &&
      familyInfo.father_qualification.trim() !== "" &&
      familyInfo.father_occupation &&
      familyInfo.father_occupation.trim() !== "" &&
      familyInfo.mother_name &&
      familyInfo.mother_name.trim() !== "" &&
      familyInfo.mother_qualification &&
      familyInfo.mother_qualification.trim() !== "" &&
      familyInfo.mother_occupation &&
      familyInfo.mother_occupation.trim() !== ""
    ) {
      console.log("NNNNN");
      return false;
    } else if (
      isFatherInfoComplete ||
      isMotherInfoComplete ||
      isGuardianInfoComplete
    ) {
      console.log("PPPP");
      return false;
    } else {
      console.log("KKLLL");
      return true;
    }
  };

  const checkRequredFieldsgurdian = () => {
    console.log("checkRequredFieldsgurdian", familyInfo);
    if (
      familyInfo.guardianfirstname &&
      familyInfo.guardianfirstname.trim() !== "" &&
      familyInfo.guardianlastname &&
      familyInfo.guardianlastname.trim() !== "" &&
      familyInfo.guardianphone &&
      familyInfo.guardianphone.trim() !== ""
    ) {
      console.log("iiiiu");
      return false;
    } else {
      console.log("222211");
      return true;
    }
  };
  //Commented By Pooja Vaishnav(01-07-2024)
  // const checkRequredFields2 = () => {
  //   if ((priviousSchool.school_name && priviousSchool.school_name.trim() !== '')) {
  //     return false;
  //   }
  //   else {
  //     return true;
  //   }
  // }
  const handlePrevious = () => {
    if (student !== "" || familyInfo !== "") {
      setPreviousVal("Edit");
      if (activeStep > 0) {
        setActiveStep(activeStep - 1);
        setValidated(false);
      }
    } else {
      setValidated(true);
    }
  };
  return (
    <>
      <Helmet>
        <title>{props?.tabName}</title>
      </Helmet>
      <PageNavigations
        id={location.state?.id}
        listName="Student List"
        listPath="/studentaddmissions"
        viewName="Student View"
        viewPath={`/studentaddmissions/${location.state?.id}`}
        colLg={12}
        colClassName="d-flex px-4 py-0 pb-2"
        extrColumn={0}
      />
      <Card className="m-5 custom-card">
        <Container fluid style={{ marginTop: "15px" }}>
          <Row>
            <Col xs={12} md={12} className="mr-auto ml-auto">
              <div className="navigation-header">
                <h2 className="custom-heading pt-4">Student Registration</h2>
                <div className="step-navigation">
                  {steps.map((step, index) => (
                    <button
                      key={index}
                      className={`step-button ${
                        index === activeStep ? "active" : ""
                      }`}
                    >
                      {step.stepName}
                    </button>
                  ))}
                </div>
              </div>
              <div className="mt-5">
                <StepComponent
                  handleCheckGuar={handleCheckGuar}
                  handleInsertedParents={handleInsertedParents}
                  validatedForPreSchool={validatedForPreSchool}
                  validatedForParent={validatedForParent}
                  student={student}
                  previousVal={previousVal}
                  familyInfo={familyInfo}
                  discountRecords={discountRecords}
                  handleParentsInfo={handleParentsInfo}
                  handleParentTab={handleParentData}
                  isParent={isParent}
                  validated={validated}
                  handleStudentTab={handleStudentTab}
                  handlePreviousSchool={handlePreviousSchool}
                />
              </div>
              <div className="text-center mt-3">
                {activeStep > 0 && (
                  <Button
                    variant="primary"
                    onClick={handlePrevious}
                    style={{ margin: "5px", fontWeight: "bold" }}
                  >
                    Previous
                  </Button>
                )}
                {activeStep < steps.length - 1 && (
                  <Button
                    variant="primary"
                    onClick={handleNext}
                    style={{
                      height: "29px",
                      width: "62px",
                      fontWeight: "bold",
                    }}
                  >
                    Next
                  </Button>
                )}
                {activeStep === steps.length - 1 && (
                  <Button variant="success" onClick={handleFinish}>
                    Finish
                  </Button>
                )}
              </div>
            </Col>
          </Row>
          <ToastContainer
            position="top-right"
            autoClose={2000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="light"
          />
        </Container>
      </Card>
    </>
  );
};
export default StudentEdit;

// import { Col, Container, Row } from "react-bootstrap";
// import Card from "react-bootstrap/Card";
// import Button from "react-bootstrap/Button";
// import { useLocation } from "react-router-dom";
// import { useNavigate } from "react-router-dom";

// import PageNavigations from "../breadcrumbs/PageNavigations";
// import SiblingTab from "../tabNavigation/SiblingTab";
// import ParentTab from "../tabNavigation/ParentTab";
// import StudentTab from "../tabNavigation/StudentTab";
// import schoolApi from "../../api/schoolApi";
// import PreviousSchoolingTab from "../tabNavigation/PreviousSchoolingTab";
// import { Helmet } from "react-helmet";
// import "../../resources/css/Student.css";
// import PubSub from "pubsub-js";
// import { ToastContainer, toast } from "react-toastify";
// const StudentEdit = (props) => {
//   const location = useLocation();
//   console.log("location data on final stage-->", location?.state);
//   const navigate = useNavigate();
//   const [validated, setValidated] = useState(false);
//   const [validatedForParent, setvalidatedForParent] = useState(false);
//   const [activeStep, setActiveStep] = useState(0);
//   const [isParent, setIsParent] = useState("");
//   const [discountRecords, setDiscountRecords] = useState();
//   const [assignTransportRecord, setAssignTransportRecord] = useState({});
//   const [feeMasterId, setFeeMasterId] = useState();
//   const [feeCategory, setFeeCategory] = useState();
//   const [currentYear] = useState(() => new Date().getFullYear());
//   // const [studentCategory, setStudentCategory] = useState();
//   const [lead, setLead] = useState(location?.state);
//   const [previousVal, setPreviousVal] = useState("");
//   const [student, setStudent] = useState(
//     location?.state ? location?.state : []
//   );
//   const [familyInfo, setFamilyInfo] = useState({
//     fatherfirstname: location?.state?.father_name?.split(" ")[0] || "",
//     fatherlastname: location?.state?.father_name?.split(" ")[1] || "",
//     fatherprofession: location?.state?.father_occupation || "",
//     fatherqualification: location?.state?.father_qualification || "",
//     fatherphone: "",
//     fatheremail: "",
//     // primarypar: "",
//     motherfirstname: location?.state?.mother_name?.split(" ")[0] || "",
//     motherlastname: location?.state?.mother_name?.split(" ")[1] || "",
//     motherphone: "",
//     motheremail: "",
//     motherprofession: location?.state?.mother_occupation || "",
//     motherqualification: location?.state?.mother_qualification || "",
//     // primarymoth: "",
//     guardianfirstname: "",
//     guardianlastname: "",
//     guardianphone: "",
//     guardianemail: "",
//     primaryparent: "",
//     // primaryGuar : "",
//     // primaryguar: "",
//     recordtype: "",
//   });

//   const [priviousSchool, setPriviousSchool] = useState({
//     school_name: "",
//     student_id: "",
//   });
//   console.log("hament bhai");

//   const [validatedForPreSchool, setValidatedForPreSchool] = useState(false);
//   const steps = [
//     { stepName: "Student Information", component: StudentTab },
//     { stepName: "Parent Information", component: ParentTab },
//     { stepName: "Sibling Information", component: SiblingTab },
//     { stepName: "Previous Schooling", component: PreviousSchoolingTab },
//   ];

//   const [isSearch, setIsSearch] = useState(false);
//   //Added By Shahir Hussain 27-08-2024
//   const [currentMonthName] = useState(() => {
//     const monthNames = [
//       "January",
//       "February",
//       "March",
//       "April",
//       "May",
//       "June",
//       "July",
//       "August",
//       "September",
//       "October",
//       "November",
//       "December",
//     ];
//     return monthNames[new Date().getMonth()];
//   });

//   const [totalMonths, setTotalMonths] = useState([
//     "January",
//     "February",
//     "March",
//     "April",
//     "May",
//     "June",
//     "July",
//     "August",
//     "September",
//     "October",
//     "November",
//     "December",
//   ]);
//   const [currentMonth, setCurrentMonth] = useState(
//     () => new Date().getMonth() + 1
//   );
//   //added by shahir hussain
//   const [currentDate] = useState(() => new Date().getDate() + 1);
//   //End By Shahir Hussain 27-08-2024

//   useEffect(() => {
//     if (location?.state) {
//       setStudent(location?.state);
//     }
//   }, []);

//   const StepComponent = steps[activeStep].component;
//   const handleParentsInfo = (data) => {
//     console.log("handleParentsInfo@@=>", data);
//     setIsParent(data);
//   };

//   const handleCheckGuar = (checkVal) => {
//     console.log("handleCheckGuar", checkVal);
//     if (checkVal?.primaryguar === true) {
//       console.log("in the ifyyy");
//       setIsParent(true);
//     } else {
//       setIsParent(false);
//     }
//   };

//   const handleInsertedParents = (data) => {
//     console.log("handleInsertedParents@@!@!#=>", data);
//     setFamilyInfo({ ...data });
//   };
//   const handleParentData = (data) => {
//     console.log("handleParentData@@@+>", data);
//     if (data) {
//       console.log("KLLKK");
//       if (
//         data?.father_recordtype === "Parent_Father" ||
//         data?.mother_recordtype === "Parent_Mother" ||
//         data?.guardian_recordtype === "Parent_Guardian"
//       ) {
//         console.log("first");
//         setFamilyInfo({
//           ...familyInfo,
//           fatherfirstname: data?.fatherfirstname,
//           motherfirstname: data?.motherfirstname,
//           guardianfirstname: data?.guardianfirstname,
//         });
//       } else {
//         console.log("secondyy");
//         setIsSearch(true);
//         if (
//           Object.keys(data)?.length > 0 &&
//           (data.hasOwnProperty("father_id") ||
//             data.hasOwnProperty("mother_id") ||
//             data.hasOwnProperty("guardian_id"))
//         ) {
//           console.log("Inn7898990");
//           setFamilyInfo(data);
//         } else if (data[0]?.recordtype === "Parent_Father") {
//           console.log("first789o");

//           setFamilyInfo((familyInfo) => ({
//             ...familyInfo,

//             fatherfirstname: data[0].fatherfirstname,

//             fatherlastname: data[0].fatherlastname,

//             fatherphone: data[0].fatherphone,

//             fatheremail: data[0].fatheremail,

//             recordtype: data[0].recordtype,

//             primarypar: data[0].primarypar,
//           }));
//         }
//         if (data[1]?.recordtype === "Parent_Mother") {
//           console.log("famillyiNddo=>", familyInfo);
//           setFamilyInfo((familyInfo) => ({
//             ...familyInfo,
//             motherfirstname: data[1].motherfirstname,
//             motherlastname: data[1].motherlastname,
//             motherphone: data[1].motherphone,
//             motheremail: data[1].motheremail,
//             recordtype: data[1].recordtype,
//             primarymoth: data[1].primarymoth,
//           }));
//         } else if (data[2]?.recordtype === "Parent_Guardian") {
//           setFamilyInfo({
//             ...familyInfo,
//             guardianfirstname: data[2].firstname,
//             guardianlastname: data[2].lastname,
//             guardianphone: data[2].phone,
//             guardianemail: data[2].email,
//             recordtype: data[2].recordtype,
//             primaryguar: data[2].is_primary,
//           });
//         }
//         if (data[0]?.primarypar === true) {
//           console.log("first");
//           setIsParent(data[0].id);
//         } else if (data[1]?.primarymoth === true) {
//           setIsParent(data[1].id);
//         } else if (data[2]?.primaryguar === true) {
//           setIsParent(data[2].id);
//         }
//       }
//     }
//   };
//   const getCurrentDate = () => {
//     const today = new Date();
//     const year = today.getFullYear();
//     let month = today.getMonth() + 1;
//     let day = today.getDate();

//     if (month < 10) {
//       month = "0" + month;
//     }
//     if (day < 10) {
//       day = "0" + day;
//     }

//     const currentDate = `${year}-${month}-${day}`;
//     console.log("currentDate@@+>", currentDate);
//     return currentDate;
//   };
//   const handleStudentTab = (data) => {
//     console.log("data--> on handleStudentTab-->", data);
//     console.log("data?.assign_transport>", data?.assign_transport);
//     //  student.vehicleid = data?.assign_transport?.transport_id;
//     if (data?.assign_transport) {
//       console.log("firsty");
//       setAssignTransportRecord(data?.assign_transport);
//     } else if (data?.discounts) {
//       console.log("sidky");
//       setDiscountRecords(data?.discounts);
//     } else if (data?.fee_type) {
//       console.log("feety");
//       setStudent({ ...data });
//       setFeeMasterId(data?.fee_type);
//       student.fee_type = data?.fee_type;
//     } else if (data?.category) {
//       console.log("katty");
//       setStudent(data);
//       if (data?.category === "General") {
//         setFeeCategory("general_fee");
//       } else if (data?.category === "Obc") {
//         setFeeCategory("obc_fee");
//       } else if (data?.category === "Sc") {
//         setFeeCategory("sc_fee");
//       } else if (data?.category === "St") {
//         setFeeCategory("st_fee");
//       }
//     } else {
//       console.log("Nitty");
//       setStudent(data);
//     }
//   };
//   // console.log('assignTransportRecord has riched on parent-->', assignTransportRecord);

//   // console.log('disountrecords-->', discountRecords);
//   // console.log('studnt7777=>',student)

//   const handlePreviousSchool = (data) => {
//     setPriviousSchool(data);
//   };

//   ///---------HANDLE FINISH IS HERE --------------------
//   const handleFinish = async (e) => {
//     e.preventDefault();
//     // const session_id = student?.session_id;
//     console.log("student@@=>", student);
//     console.log("priviousSchool@@=>", priviousSchool);
//     let result = "";
//     if (student?.id) {
//       result = await schoolApi.fetchStudentbyId(student?.id);
//       console.log("result=>", result);
//     }
//     // if(student?.id && priviousSchool?.id ){ commented by pawan
//     if ((result.success === true && student?.id) || priviousSchool?.id) {
//       console.log("STudentg=>", student);
//       //Update Student
//       const updateStudent = await schoolApi.updateStudent(student);
//       console.log("updateStudent@@@-=>", updateStudent);

//       let studentadmission = {
//         student_addmission_id: location?.state?.student_addmission_id,
//         studentid: location?.state?.studentid,
//         parentid: location?.state?.parentid,
//         classid: updateStudent?.result?.classid,
//         section_id: updateStudent?.result?.section_id,
//         previous_school_id: location?.state?.previous_school_id,
//         fee_type: student?.fee_type,
//       };
//       const updateStudentAdd = await schoolApi.saveStudentAddmission(
//         studentadmission
//       );
//       console.log("updateStudentAdd@@@-=>", updateStudentAdd);

//       console.log("familyInfo-->", familyInfo);
//       if (
//         familyInfo.father_id ||
//         familyInfo.mother_id ||
//         familyInfo.guardian_id
//       ) {
//         //Update Contact
//         const updateContact = await schoolApi.updateContactById(familyInfo);
//         console.log("updateContact@-=>", updateContact);
//       }
//       //Previous School
//       if (priviousSchool?.id) {
//         const updatePreviousSchool = await schoolApi.savePreviousSchool(
//           priviousSchool
//         );
//         console.log("updatePreviousSchool@@@-=>", updatePreviousSchool);
//         PubSub.publish("RECORD_SAVED_TOAST", {
//           title: "Record Saved",
//           message: "Record saved successfully",
//         });
//         navigate("/studentaddmissions/");
//       }
//       PubSub.publish("RECORD_SAVED_TOAST", {
//         title: "Record Save",
//         message: "Record Save successfully",
//       });
//       // navigate("/studentaddmissions");
//       //const editAdmission = location?.state?.student_addmission_id;
//       console.log("location?.state-->@@=>", location?.state);
//       console.log(
//         "studentaddmissions@@@=>",
//         location?.state?.student_addmission_id
//       );
//       navigate(
//         `/studentaddmissions/${location?.state?.student_addmission_id}`,
//         {
//           state: { ...location?.state },
//         }
//       );
//     } else {
//       console.log("session_id latest-->", student);
//       const sessions = await schoolApi.fetchSessions();
//       let feeMasterType = null;
//       if (sessions) {
//         const currentYear = new Date().getFullYear();
//         const nextYear = currentYear + 1;

//         const fetchSelectedSessionResult = sessions.find((res) => {
//           return res.year === `${currentYear}-${nextYear}`;
//         });
//         console.log(
//           "fetchSelectedSessionResult--> xyz",
//           fetchSelectedSessionResult
//         );

//         if (!fetchSelectedSessionResult) {
//           return toast.error("Create session records first!!");
//         }

//         student.session_id = fetchSelectedSessionResult?.id;
//       }
//       if (
//         student !== "" &&
//         familyInfo !== "" &&
//         priviousSchool !== "" &&
//         props.rteUrlName === "rte"
//       ) {
//         student.isrte = true;
//         const result = await schoolApi.createContOnRecType(familyInfo);
//         const getParentCont = await schoolApi.fetchParentContacts();
//         getParentCont.forEach((item) => {
//           if (item.phone === student.phone) {
//             student.parentId = item.id;
//           }
//         });
//         const fee_type = student?.fee_type;

//         const studentRec = await schoolApi.createStudent(student);
//         console.log("studentRec-->rte", studentRec);
//         priviousSchool.student_id = studentRec.result.id;
//         priviousSchool.student_phone = studentRec.result.phone;
//         const previousSchoolRec = await schoolApi.createPreviousSchool(
//           priviousSchool
//         );
//         if (previousSchoolRec.message) {
//           toast.error(previousSchoolRec.message, {
//             position: toast.POSITION.TOP_RIGHT,
//           });
//         } else {
//           console.log("first");
//           const arr = {
//             studentid: studentRec?.result.id,
//             classid: student.classid,
//             section_id: student.sectionid,
//             parentid: studentRec?.result.parentid,
//             fee_type: fee_type,
//             session_id: student?.session_id,
//             dateofaddmission: getCurrentDate(),
//             year: currentYear,
//             isrte: true,
//           };

//           console.log('arr-rte->',arr);
//           const result2 = await schoolApi.createStudentAddmission(arr);
//           PubSub.publish("RECORD_SAVED_TOAST", {
//             title: "Record Create",
//             message: "Record Created successfully",
//           });
//           if (
//             lead &&
//             typeof lead === "object" &&
//             Object.keys(lead).length > 0
//           ) {
//             console.log("Yes regiestraion");
//             lead.status = "Registered";
//             result = await schoolApi.savelead(lead);
//             if (result.success) {
//               PubSub.publish("RECORD_SAVED_TOAST", {
//                 title: "Record Saved",
//                 message: "Record saved successfully",
//               });
//               navigate(`/students/${result.lead.resultCon.id}`, {
//                 state: lead,
//               });
//             }
//           }
//           if (result2?.studentAddmisionRec?.id) {
//             navigate("/rte");
//           }
//         }
//       } else if (student !== "" && familyInfo !== "") {
//         // console.log('Yes^&&',lead)
//         console.log("familyInfo", familyInfo);

//         const contResult = await schoolApi.createContOnRecType(familyInfo);
//         console.log("result@@@@=>", contResult);

//         student.parentId = contResult.contactRec
//           ? contResult?.contactRec
//           : isParent;

//         //Changed by Pooja Vaishnav(01-07-2024)
//         if (student?.class_id) {
//           student.classid = student?.class_id;
//         }
//         const student_fee_type = student?.fee_type;
//         console.log("after student=>", student);
//         const studentRec = await schoolApi.createStudent(student);
//         console.log("@@@studentRec-->", studentRec);

//         const arr = {
//           studentid: studentRec.result.id,
//           classid: student.classid,
//           //  parentid: contResult?.contactRec?.[0].id,
//           section_id: student.sectionid,
//           parentid: contResult.contactRec ? contResult?.contactRec : isParent,
//           // parentid: student.parentId,
//           fee_type: student_fee_type,
//           session_id: student?.session_id,
//           dateofaddmission: getCurrentDate(),
//           year: currentYear,
//           isrte: false,
//           // previous_school_id : previousSchoolRec?.result?.id,
//           previous_school_id: null,
//         };

//         if (
//           priviousSchool?.school_name !== "" &&
//           priviousSchool?.school_name !== undefined
//         ) {
//           priviousSchool.student_id = studentRec.result.id;
//           //priviousSchool.student_phone = studentRec.result.phone;
//           const previousSchoolRec = await schoolApi.createPreviousSchool(
//             priviousSchool
//           );
//           console.log("previousSchoolRec-->", previousSchoolRec);
//           console.log(
//             "priviousSchool?.result?.id@@=>",
//             previousSchoolRec?.result?.id
//           );
//           arr.previous_school_id = previousSchoolRec?.result?.id;
//         }

//         const result2 = await schoolApi.createStudentAddmission(arr);
//         console.log("result2-->", result2);
//         //commented By Me
//         if (
//           lead &&
//           typeof lead === "object" &&
//           Object.keys(lead).length > 0 &&
//           lead?.status === "Not Registered"
//         ) {
//           console.log("No regiestraion");
//           lead.status = "Registered";
//           const leadresult = await schoolApi.savelead(lead);
//           if (leadresult?.success) {
//             PubSub.publish("RECORD_SAVED_TOAST", {
//               title: "Record Saved",
//               message: "Record saved successfully",
//             });
//             navigate(`/students/${leadresult.lead.resultCon.id}`, {
//               state: lead,
//             });
//           }
//         }

//         let discountLineItms = [];
//         if (discountRecords && result2?.studentAddmisionRec?.id) {
//           console.log("going to create discount records-->");
//           let discountLineItemRecord = {
//             student_addmission_id: result2?.studentAddmisionRec?.id,
//             discounts: discountRecords,
//           };
//           const discountLineItemsResult =
//             await schoolApi.createFeeDiscountLineItems(discountLineItemRecord);
//           console.log(
//             "discountLineItemsResult after create-->",
//             discountLineItemsResult
//           );
//           discountLineItms = discountLineItemsResult;
//         }

//         console.log("discountLineItms-->", discountLineItms);

//         let resultCreateAssignTransport = {};
//         if (assignTransportRecord && result2?.studentAddmisionRec?.id) {
//           let obj = {
//             ...assignTransportRecord,
//             student_addmission_id: result2?.studentAddmisionRec?.id,
//             sessionid: student?.session_id,
//           };
//           console.log("obj for creating assignTransport-->", obj);
//           //   resultCreateAssignTransport = await schoolApi.createAssignTransport(obj);
//           console.log(
//             "resultCreateAssignTransport-->",
//             resultCreateAssignTransport
//           );
//         }

//         if (feeMasterId && result2?.studentAddmisionRec?.id) {
//           const getFeeMasterTypeResult = await schoolApi.fetchFeeMaster(
//             feeMasterId
//           );
//           feeMasterType = getFeeMasterTypeResult[0]?.type;
//           console.log("getFeeMasterTypeResult-->", getFeeMasterTypeResult);
//           console.log("feeMasterType-->", feeMasterType);

//           let feeMasterRecord =
//             await schoolApi.getMasterInstallmentByFeeMasterId(feeMasterId);
//           console.log("feeMaster installment Record-->", feeMasterRecord);
//           // console.log('first student=>',student)
//           // console.log('session record-->', student?.session_id);
//           const fetchSelectedSessionResult = await schoolApi.getSessionById(
//             student?.session_id
//           );
//           console.log(
//             "fetchSelectedSessionResult--> xyz",
//             fetchSelectedSessionResult
//           );

//           //This Code Added By Shahir Hussain 27-08-2024
//           const currentDate = new Date().toISOString().slice(0, 10);
//           console.log("currentDate-->", currentDate);
//           const lateMonths = [];
//           let sessionStartMonth =
//             fetchSelectedSessionResult?.result?.startdate.split("-")[1];
//           let sessionEndMonth =
//             fetchSelectedSessionResult?.result?.enddate.split("-")[1];
//           const currentIndex = currentMonth - 1; // Index of "August" is 7
//           console.log("currentIndex-->", currentIndex);
//           const endIndex = sessionEndMonth - 1; // index of "March" is 2
//           console.log("endIndex-->", endIndex);
//           const startIndex = sessionStartMonth - 1;
//           console.log("startIndex-->", startIndex);
//           console.log("totalMonths--->", totalMonths);
//           let monthsToLoop = [];
//           let flage = false;
//           let monthArray = [];
//           let firstMonth;
//           // preview filter code
//           if (endIndex < currentIndex) {
//             monthsToLoop = [
//               ...totalMonths.slice(currentIndex),
//               ...totalMonths.slice(0, endIndex + 1),
//             ];
//           } else {
//             monthsToLoop = totalMonths.slice(currentIndex, endIndex + 1);
//           }
//           console.log("handle Finish monthsToLoop == ", monthsToLoop);
//           //---commented by pawan -----------
//           feeMasterRecord.map((record) => {
//             console.log("handle Finish record month =", record.month);
//             firstMonth = record.month.split(" to " || " ")[0];
//             console.log("handle Finish firstMonth == ", firstMonth);
//             monthsToLoop.map((month) => {
//               if (month == firstMonth) {
//                 monthArray.push(record);
//               }
//             });
//           });
//           //-------comment end by pawan ----------
//           console.log("handle Finish After data bi-month == ", monthArray);
//           feeMasterRecord = monthArray;

//           // --- formatting due_date
//           const sessionDates = {};
//           const startdate = fetchSelectedSessionResult?.result?.startdate;
//           const enddate = fetchSelectedSessionResult?.result?.enddate;

//           const startDate = new Date(startdate);
//           const endDate = new Date(enddate);

//           console.log("Parsed startdate:", startDate);
//           console.log("Parsed enddate:", endDate);

//           const generateInstallmentDates = (start, end) => {
//             const dates = [];
//             let currentDate = new Date(start);

//             while (currentDate <= end) {
//               dates.push(new Date(currentDate));
//               const monthName = currentDate.toLocaleString("default", {
//                 month: "long",
//               });
//               const formattedDate = currentDate.toISOString().split("T")[0];
//               sessionDates[monthName] = formattedDate;
//               currentDate.setMonth(currentDate.getMonth() + 1);

//               if (currentDate.getDate() !== start.getDate()) {
//                 currentDate.setDate(0);
//               }
//             }

//             if (dates[dates.length - 1] < end) {
//               dates.push(new Date(end));
//               const monthName = end.toLocaleString("default", {
//                 month: "long",
//               });
//               const formattedDate = end.toISOString().split("T")[0];
//               sessionDates[monthName] = formattedDate;
//             }

//             return dates;
//           };

//           const installmentDates = generateInstallmentDates(startDate, endDate);
//           console.log("ppx Generated installment dates:", installmentDates);
//           console.log("ppx Session dates:", sessionDates);

//           installmentDates.forEach((date) => {
//             console.log(
//               `ppx Installment Date--> ${date.toISOString().split("T")[0]}`
//             );
//           });

//           let pendingDueResult = [];
//           const ResultSettings = await schoolApi.getSettingByKey(
//             "pending_due_day"
//           );
//           console.log("ResultSettings-->", ResultSettings);
//           if (ResultSettings.success) {
//             pendingDueResult.push(ResultSettings.data[0]);
//           } else {
//             pendingDueResult([]);
//           }

//           console.log("pendingDueResult-->", pendingDueResult);
//           let transportFeeByInstall = 0;
//           if (resultCreateAssignTransport) {
//             // transportFeeByInstall = parseInt(resultCreateAssignTransport?.result?.fare_amount) / feeMasterRecord.length
//             transportFeeByInstall = parseInt(
//               resultCreateAssignTransport?.result?.fare_amount
//             );
//           }

//           if (feeMasterType === "Bi-Monthly") {
//             transportFeeByInstall = parseInt(transportFeeByInstall) * 2;
//           } else if (feeMasterType === "Quarterly") {
//             transportFeeByInstall = parseInt(transportFeeByInstall) * 3;
//           } else if (feeMasterType === "Half Yearly") {
//             transportFeeByInstall = parseInt(transportFeeByInstall) * 6;
//           } else if (feeMasterType === "Yearly") {
//             transportFeeByInstall = parseInt(transportFeeByInstall) * 12;
//           }

//           console.log("transportFeeByInstall-->", transportFeeByInstall);
//           let recordsForStudentInstallment = [];
//           if (feeMasterRecord) {
//             const transportFee = Math.ceil(transportFeeByInstall || 0);
//             console.log("transportFee-->", transportFee);

//             feeMasterRecord.forEach((res, index) => {
//               console.log("res-@@->", res);
//               let splittedMonth = res?.month?.split(" ");
//               console.log("splittedMonth-->", splittedMonth);

//               let lastDate = new Date(sessionDates[splittedMonth[0]]);
//               console.log("lastDate-1->", lastDate);

//               if (pendingDueResult[0].value) {
//                 lastDate.setDate(pendingDueResult[0].value);
//               }

//               let installmentDate = lastDate.toISOString().slice(0, 10);

//               console.log("rrx installmentDate-->", installmentDate);

//               recordsForStudentInstallment.push({
//                 student_addmission_id: result2?.studentAddmisionRec?.id,
//                 fee_master_installment_id: res?.id,
//                 amount: res[feeCategory],
//                 status: "pending",
//                 orderno: index + 1,
//                 due_date: installmentDate,
//                 session_id: student?.session_id,
//                 transport_fee: transportFee,
//                 assign_transport_id: resultCreateAssignTransport?.result?.id
//                   ? resultCreateAssignTransport?.result?.id
//                   : null,
//                 month: res?.month,
//                 installment_discount: 0,
//               });
//             });

//             console.log(
//               "student installments to be created-->",
//               recordsForStudentInstallment
//             );

//             //-start--------------Added By pawan for discounts ------------

//             let categoryAmount = "";
//             if (student.category === "General") {
//               categoryAmount = "general_amount";
//             } else if (student.category === "Obc") {
//               categoryAmount = "obc_amount";
//             } else if (student.category === "Sc") {
//               categoryAmount = "sc_amount";
//             } else if (student.category === "St") {
//               categoryAmount = "st_amount";
//             }

//             if (discountLineItms.length > 0) {
//               for (
//                 let i = 0;
//                 i <= recordsForStudentInstallment.length - 1;
//                 i++
//               ) {
//                 const lineItems = await schoolApi.getLineItemsByFeeMasterId(
//                   recordsForStudentInstallment[i].fee_master_installment_id
//                 );
//                 console.log("lineItems-@@studentEdit->", lineItems);
//                 let discountedAmount = 0;
//                 discountLineItms.map((dis) => {
//                   console.log("dis-sarfraj->", dis);
//                   let discountFeeHeadId = dis.resultDiscount?.fee_head_id;
//                   let discountPercent = dis.resultDiscount.percent;
//                   let discountAmount = dis.resultDiscount.amount;

//                   console.log("discountAmount-->", discountAmount);
//                   console.log("discountFeeHeadId-->", discountFeeHeadId);
//                   console.log("discountPercent-->", discountPercent);

//                   console.log("non rte");
//                   let lineItemAmountByCategory = 0;

//                   if (discountFeeHeadId) {
//                     for (let i = 0; i <= discountFeeHeadId.length - 1; i++) {
//                       const matchedItem = lineItems.find(
//                         (li) => li.fee_head_master_id === discountFeeHeadId[i]
//                       );
//                       lineItemAmountByCategory += matchedItem? parseInt(matchedItem[categoryAmount]): 0;
//                     }
//                   }

//                   console.log(
//                     "lineItemAmountByCategory data ==",
//                     lineItemAmountByCategory
//                   );

//                   if (discountAmount > 0) {
//                     discountedAmount += parseInt(discountAmount);
//                   } else {
//                     discountedAmount += Math.round(
//                       (parseInt(lineItemAmountByCategory) * parseInt(discountPercent)) / 100
//                     );
//                   }

//                   console.log(
//                     `lineItemAmountByCategory-${i}->`,
//                     lineItemAmountByCategory
//                   );
//                   // }
//                 });
//                 //comment by shahir hussain
//                 console.log("discountedAmount-->", discountedAmount);
//                 recordsForStudentInstallment[i].installment_discount = discountedAmount;
//               }
//             }

//             console.log(
//               "recordsForStudentInstallment-->",
//               recordsForStudentInstallment
//             );

//             // -----end managing discount-----------------------------------------------------

//             const resultStudentInstallments =
//               await schoolApi.createStudentFeeInstallments(
//                 recordsForStudentInstallment
//               );
//             console.log("resultStudentInstallments", resultStudentInstallments);

//             if (resultStudentInstallments) {
//               console.log(
//                 "student fee transport records created successfully",
//                 resultStudentInstallments
//               );
//             }
//           }
//         }

//         PubSub.publish("RECORD_SAVED_TOAST", {
//           title: "Record Create",
//           message: "Record Created successfully",
//         });
//         if (result2?.studentAddmisionRec?.id) {
//           navigate("/studentaddmissions");
//         }
//       }
//       // }
//     }
//   };
//   const handleNext = async (e) => {
//     console.log("handle next", student, activeStep);
//     // console.log('leadd=>',lead)
//     e.preventDefault();

//     if (
//       (!student.firstname ||
//         (student?.class_id ? !student.class_id : !student.classid) ||
//         !student.sectionid ||
//         !student.category ||
//         !student.phone ||
//         !student.fee_type) &&
//       activeStep === 0
//     ) {
//       console.log("handle next 1", student);
//       if (checkRequredFields()) {
//         console.log("first111");
//         setValidated(true);
//         toast.error("Fill all required fileds!!!");
//       }
//       console.log("Hey yes");
//     } else if (
//       ((!familyInfo.fatherfirstname ||
//         !familyInfo.fatherlastname ||
//         !familyInfo.fatherphone) &&
//         familyInfo.primaryparent === "Parent_Father" &&
//         activeStep === 1) ||
//       ((!familyInfo.motherfirstname ||
//         !familyInfo.motherlastname ||
//         !familyInfo.motherphone) &&
//         familyInfo.primaryparent === "Parent_Mother" &&
//         activeStep === 1) ||
//       ((!familyInfo.guardianfirstname ||
//         !familyInfo.guardianlastname ||
//         !familyInfo.guardianphone) &&
//         familyInfo.primaryparent === "Parent_Guardian" &&
//         activeStep === 1)
//     ) {
//       console.log("handle next 2", familyInfo);
//       if (checkRequredFields1()) {
//         setvalidatedForParent(true);
//         toast.error("Fill all required fields!!!");
//       }
//     } else {
//       console.log("handle next 3", student);
//       console.log("student.edit==>", student);

//       if (student?.id) {
//         setActiveStep(activeStep + 1);
//         setValidated(false);
//         setvalidatedForParent(false);
//       } else {
//         const resultStuDup = await schoolApi.checkStudentDuplicacy(student);
//         if (resultStuDup.message) {
//           toast.error("This Student is already exist", {
//             position: toast.POSITION.TOP_RIGHT,
//           });
//         } else if (
//           familyInfo.fatherphone !== "" ||
//           familyInfo.motherphone !== "" ||
//           familyInfo.guardianphone !== ""
//         ) {
//           console.log("familyInfo211`1=>", familyInfo);
//           if (isSearch === false) {
//             const resultContDup = await schoolApi.checkContactDuplicacy(
//               familyInfo
//             );
//             if (resultContDup.message) {
//               toast.error("The Contact is already exist", {
//                 position: toast.POSITION.TOP_RIGHT,
//               });
//             } else {
//               setActiveStep(activeStep + 1);
//               setValidated(false);
//               setvalidatedForParent(false);
//             }
//           } else {
//             setActiveStep(activeStep + 1);
//             setValidated(false);
//             setvalidatedForParent(false);
//           }
//         } else {
//           setActiveStep(activeStep + 1);
//           setValidated(false);
//           setvalidatedForParent(false);
//         }
//       }
//     }
//   };
//   const checkRequredFields = () => {
//     console.log("checkRequredFields@@@=>", student);
//     if (
//       student &&
//       student.firstname &&
//       student.firstname.trim() !== "" &&
//       ((student.class_id && student.class_id.trim() !== "") ||
//         (student.classid && student.classid.trim() !== "")) &&
//       student.sectionid &&
//       student.sectionid.trim() !== "" &&
//       student.category &&
//       student.category.trim() !== "" &&
//       student.phone &&
//       student.phone.trim() !== "" &&
//       student.fee_type &&
//       student.fee_type.trim() !== ""
//     ) {
//       console.log("Yest");
//       return false;
//     } else {
//       console.log("notee");
//       return true;
//     }
//   };

//   const isFatherInfoComplete =
//     familyInfo.fatherfirstname &&
//     familyInfo.fatherfirstname?.trim() !== "" &&
//     familyInfo.fatherlastname &&
//     familyInfo.fatherlastname?.trim() !== "" &&
//     familyInfo.fatherphone &&
//     familyInfo.fatherphone?.trim() !== "";

//   const isMotherInfoComplete =
//     familyInfo.motherfirstname?.trim() !== "" &&
//     familyInfo.motherlastname?.trim() !== "" &&
//     familyInfo.motherphone?.trim() !== "";

//   const isGuardianInfoComplete =
//     familyInfo.guardianfirstname?.trim() !== "" &&
//     familyInfo.guardianlastname?.trim() !== "" &&
//     familyInfo.guardianphone?.trim() !== "";

//   const checkRequredFields1 = () => {
//     console.log("first familyInfo", familyInfo);
//     if (
//       familyInfo.father_name &&
//       familyInfo.father_name.trim() !== "" &&
//       familyInfo.father_qualification &&
//       familyInfo.father_qualification.trim() !== "" &&
//       familyInfo.father_occupation &&
//       familyInfo.father_occupation.trim() !== "" &&
//       familyInfo.mother_name &&
//       familyInfo.mother_name.trim() !== "" &&
//       familyInfo.mother_qualification &&
//       familyInfo.mother_qualification.trim() !== "" &&
//       familyInfo.mother_occupation &&
//       familyInfo.mother_occupation.trim() !== ""
//     ) {
//       console.log("NNNNN");
//       return false;
//     } else if (
//       isFatherInfoComplete ||
//       isMotherInfoComplete ||
//       isGuardianInfoComplete
//     ) {
//       console.log("PPPP");
//       return false;
//     } else {
//       console.log("KKLLL");
//       return true;
//     }
//   };

//   const checkRequredFieldsgurdian = () => {
//     console.log("checkRequredFieldsgurdian", familyInfo);
//     if (
//       familyInfo.guardianfirstname &&
//       familyInfo.guardianfirstname.trim() !== "" &&
//       familyInfo.guardianlastname &&
//       familyInfo.guardianlastname.trim() !== "" &&
//       familyInfo.guardianphone &&
//       familyInfo.guardianphone.trim() !== ""
//     ) {
//       console.log("iiiiu");
//       return false;
//     } else {
//       console.log("222211");
//       return true;
//     }
//   };
//   //Commented By Pooja Vaishnav(01-07-2024)
//   // const checkRequredFields2 = () => {
//   //   if ((priviousSchool.school_name && priviousSchool.school_name.trim() !== '')) {
//   //     return false;
//   //   }
//   //   else {
//   //     return true;
//   //   }
//   // }
//   const handlePrevious = () => {
//     if (student !== "" || familyInfo !== "") {
//       setPreviousVal("Edit");
//       if (activeStep > 0) {
//         setActiveStep(activeStep - 1);
//         setValidated(false);
//       }
//     } else {
//       setValidated(true);
//     }
//   };
//   return (
//     <>
//       <Helmet>
//         <title>{props?.tabName}</title>
//       </Helmet>
//       <PageNavigations
//         id={location.state?.id}
//         listName="Student List"
//         listPath="/studentaddmissions"
//         viewName="Student View"
//         viewPath={`/studentaddmissions/${location.state?.id}`}
//         colLg={12}
//         colClassName="d-flex px-4 py-0 pb-2"
//         extrColumn={0}
//       />
//       <Card className="m-5 custom-card">
//         <Container fluid style={{ marginTop: "15px" }}>
//           <Row>
//             <Col xs={12} md={12} className="mr-auto ml-auto">
//               <div className="navigation-header">
//                 <h2 className="custom-heading pt-4">Student Registration</h2>
//                 <div className="step-navigation">
//                   {steps.map((step, index) => (
//                     <button
//                       key={index}
//                       className={`step-button ${
//                         index === activeStep ? "active" : ""
//                       }`}
//                     >
//                       {step.stepName}
//                     </button>
//                   ))}
//                 </div>
//               </div>
//               <div className="mt-5">
//                 <StepComponent
//                   handleCheckGuar={handleCheckGuar}
//                   handleInsertedParents={handleInsertedParents}
//                   validatedForPreSchool={validatedForPreSchool}
//                   validatedForParent={validatedForParent}
//                   student={student}
//                   previousVal={previousVal}
//                   familyInfo={familyInfo}
//                   discountRecords={discountRecords}
//                   handleParentsInfo={handleParentsInfo}
//                   handleParentTab={handleParentData}
//                   isParent={isParent}
//                   validated={validated}
//                   handleStudentTab={handleStudentTab}
//                   handlePreviousSchool={handlePreviousSchool}
//                 />
//               </div>
//               <div className="text-center mt-3">
//                 {activeStep > 0 && (
//                   <Button
//                     variant="primary"
//                     onClick={handlePrevious}
//                     style={{ margin: "5px", fontWeight: "bold" }}
//                   >
//                     Previous
//                   </Button>
//                 )}
//                 {activeStep < steps.length - 1 && (
//                   <Button
//                     variant="primary"
//                     onClick={handleNext}
//                     style={{
//                       height: "29px",
//                       width: "62px",
//                       fontWeight: "bold",
//                     }}
//                   >
//                     Next
//                   </Button>
//                 )}
//                 {activeStep === steps.length - 1 && (
//                   <Button variant="success" onClick={handleFinish}>
//                     Finish
//                   </Button>
//                 )}
//               </div>
//             </Col>
//           </Row>
//           <ToastContainer position="top-center" autoClose={2000} />
//         </Container>
//       </Card>
//     </>
//   );
// };
// export default StudentEdit;
