import React, { useState, useEffect } from "react";
import { Form, Row, Col } from "react-bootstrap";
import "../../resources/css/Student.css";
import schoolApi from "../../api/schoolApi";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const PreviousSchoolingInfo = (props) => {
  console.log("props-on-previousInfo-->>", props);
  const [optionClasses, setOptionClasses] = useState([]);
  const [sessionYear, setSessionYear] = useState([]);
  useEffect(() => {
    async function init() {
      try {
        let classList = await schoolApi.getActiveClassRecords();
        console.log("classList12@@+>", classList);
        if (classList?.result) {
          let ar = [];
          classList?.result?.map((item) => {
            var obj = {};
            obj.value = item.id;
            obj.label = item.classname;
            ar.push(obj);
          });
          setOptionClasses(ar);
          async function getSessions() {  // Add By Aamir khan 22-10-2024
            try {
              const sessions = await schoolApi.fetchSessions();
              console.log("SESIONS==>", sessions);
              if (sessions) {
                setSessionYear(sessions);
              } else {
                setSessionYear([]);
              }
            } catch (err) {
              console.error("Error fetching sessions:", err);
              toast.error("An error occurred while fetching sessions.");
              setSessionYear([]);
            }
          }
          getSessions();
        } else {
          setOptionClasses([]);
        }
      } catch (error) {
        toast.error(error?.response?.data?.message);
      }
    }
    init();
  }, []);

  const handleChange = (event) => {
    const { name, value } = event.target;
    props?.updateStudentRecord({
      [name]: value,
    });
  };

  const handleClasses = async (e) => {
    console.log("e ->", e.target.value);
    const classId = e.target.value;
    const selectedClass = optionClasses.find(
      (option) => option.value === classId
    );
    const className = selectedClass ? selectedClass.label : "";

    props?.updateStudentRecord({
      class: e.target.value,
      class_name: className,
    });
  };

  const handlePhoneChange = (event) => {
    const inputPhone = event.target.value.replace(/\D/g, "").slice(0, 10);
    handleChange({ target: { name: "phone", value: inputPhone } });
  };
  const handleYearChange = (event) => {
    const inputYear = event.target.value.replace(/\D/g, "").slice(0, 4);
    handleChange({ target: { name: "passed_year", value: inputYear } });
  };

  return (
    <>
      <Form noValidate>
        <Row>
          <Col md={4}>
            <Form.Group className="mb-3" controlId="formFirstName">
              <Form.Label className="text-formatted" htmlFor="formBasicClass">
                Class Name
              </Form.Label>
              <Form.Select
                size="lg"
                name="class"
                onChange={handleClasses}
                value={props?.studentrecord?.class}
              >
                <option value="">Select Class</option>
                {optionClasses.map((className, index) => (
                  <option key={index} value={className.value}>
                    {className.label}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>
          </Col>
          <Col md={4}>
            <Form.Group className="mb-3" controlId="formLastName">
              <Form.Label className="text-formatted">Grade</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter Grade"
                size="lg"
                name="grade"
                onChange={handleChange}
                value={props.studentrecord.grade}
              />
            </Form.Group>
          </Col>
          {/*---- Add by Aamir khan 22-10-2024 -----*/}
          <Col md={4}>                        
              <Form.Group className="mx-3">
                <Form.Label className="text-formatted">Session</Form.Label>
                <Form.Select
                  name="passed_year"
                  value={props.studentrecord.passed_year}
                  onChange={handleChange}
                  style={{padding : "9px"}}
                >
                  <option value="">-- Select Session --</option>
                  {sessionYear.map((session) => (
                    <option key={session.id} value={session.id}>
                      {session.year}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <Form.Group className="mb-3" controlId="formCity">
              <Form.Label className="text-formatted">School Name</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter School Name"
                size="lg"
                name="school_name"
                onChange={handleChange}
                value={props.studentrecord.school_name}
              />
            </Form.Group>
          </Col>
          <Col md={6}>
            <Form.Group className="mb-3" controlId="formPostalCode">
              <Form.Label className="text-formatted">Phone</Form.Label>
              <Form.Control
                type="phone"
                placeholder="Enter Phone"
                size="lg"
                name="phone"
                onChange={handlePhoneChange}
                value={props.studentrecord.phone}
              />
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <Form.Group className="mb-3 " controlId="formStreet">
              <Form.Label className="text-formatted">School Address</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter School Address"
                size="lg"
                name="school_address"
                onChange={handleChange}
                value={props.studentrecord.school_address}
              />
            </Form.Group>
          </Col>
        </Row>
      </Form>
    </>
  );
};
export default PreviousSchoolingInfo;
