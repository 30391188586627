// Add By Aamir khan
import { Button, Card, Col, Form, Row } from "react-bootstrap";
import React, { useState } from "react";
import schoolApi from "../../api/schoolApi";
import { useLocation } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";

const FilterComponent = ({ setBody }) => {
  const location = useLocation();
  const [filterData, setFilterData] = useState({});
  const [flaterData, setFlaterData] = useState({});
  const [contact, setContact] = useState({});

  const handleChange = (event) => {
    event.preventDefault();
    setFlaterData({
      ...flaterData,
      [event.target.name]: event.target.value,
    });
  };

  const handleFilter = async (event) => {
    event.preventDefault();
    setFlaterData({
      ...flaterData,
      [event.target.name]: event.target.value,
    });
  };

  const handleSearch = async () => {
    let result;
    if (location.pathname === "/staffs") {
      try {
        result = await schoolApi.fetchStaffContactsName(flaterData);
        if (result.success) {
          result = result.contacts;
        } else {
          result = [];
        }
      } catch (error) {
        result = [];
      }
    } else if (location.pathname === "/subjects") {
      try {
        result = await schoolApi.getSubjectRecordName(flaterData);
        if (result.success) {
          result = result.result;
        } else {
          toast.error("Record not found");
          result = [];
        }
      } catch (error) {
        result = [];
      }
    }
    console.log("Search Result:", result);

    setBody(result);
  };

  return (
    <>
      <Card className="filterCard">
        <Card.Body>
          <div
            style={{
              background: "#ffffff",
              borderBottom: "1px solid black",
              height: "2.6rem",
              display: "flex",
              width: "100%",
              alignItems: "center",
              justifyContent: "center",
              marginTop: "-9px",
              marginBottom: "20px",
              boxShadow: "7 9px 9px rgba(931, 835, 238, 6.9)",
            }}
          >
            <Form.Label className="mb-2 w-100 text-center">
              {location.pathname === "/staffs" ? "SEARCH EMPLOYEE" : "FILTER"}
            </Form.Label>
          </div>
          <Form>
            {location.pathname === "/staffs" ? (
              <>
                <Col lg={12}>
                  <Form.Group controlId="formBasicFirstName">
                    <Form.Label className="form-view-label">
                      Employee Name
                    </Form.Label>
                    <Form.Control
                      type="text"
                      name="name"
                      placeholder="Enter Employee Name"
                      onChange={handleChange}
                    />
                  </Form.Group>
                </Col>
                <Col lg={12} style={{ marginTop: "15px" }}>
                  <Form.Group>
                    <Form.Label className="form-view-label">
                      Employee Type
                    </Form.Label>
                    <Form.Select
                      name="recordtype"
                      onChange={handleChange}
                      value={contact.recordtype}
                    >
                      <option value="">--Select Employee Type--</option>
                      <option value="Principal">Principal</option>
                      <option value="Parent">Parent</option>
                      <option value="Teacher">Teacher</option>
                      <option value="Admin">Admin</option>
                      <option value="Librarian">Librarian</option>
                      <option value="Accountant">Accountant</option>
                      <option value="Driver">Driver</option>
                      <option value="Peon">Peon</option>
                      <option value="Security Guard">Security Guard</option>
                      <option value="PTI">PTI</option>
                    </Form.Select>
                  </Form.Group>
                </Col>
              </>
            ) : (
              <>
                <Form.Group as={Row} className="mt-3">
                  <Form.Label column lg="6">
                    Type Name
                  </Form.Label>
                  <Col lg="12">
                    <Form.Select
                      name="type"
                      onChange={handleFilter}
                      className="form-control"
                    >
                      <option value="">--Select Type--</option>
                      <option value="General">General</option>
                      <option value="Optional">Optional</option>
                    </Form.Select>
                  </Col>
                </Form.Group>
                <Form.Group as={Row} className="mt-3">
                  <Form.Label column lg="6">
                    Status
                  </Form.Label>
                  <Col lg="12">
                    <Form.Select
                      name="status"
                      onChange={handleFilter}
                      className="form-control"
                    >
                      <option value="">--Select Status--</option>
                      <option value="Active">Active</option>
                      <option value="InActive">InActive</option>
                    </Form.Select>
                  </Col>
                </Form.Group>
              </>
            )}
            <Button
              className="btn-sm add-new-button btn mt-4"
              variant=""
              onClick={handleSearch}
              block
            >
              Search
            </Button>
          </Form>
        </Card.Body>
      </Card>
    </>
  );
};
export default FilterComponent;
