/**
 * @author: Pawan Singh Sisodiya
 */
import Form from "react-bootstrap/Form"; // or the appropriate library

import React, { useState, useEffect } from "react";
import "../../App.css";
import { Button, Col, Container, Table, Row } from "react-bootstrap";
import {
  DatatableWrapper,
  Filter,
  Pagination,
  PaginationOptions,
  TableBody,
  TableHeader,
} from "react-bs-datatable";

import Confirm from "../Confirm";
import InfoPill from "../InfoPill";
import { ShimmerTable } from "react-shimmer-effects";
import { Link, useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import schoolApi from "../../api/schoolApi";
import moment from "moment";
import PubSub from "pubsub-js";
import { Helmet } from "react-helmet";
import PageNavigations from "../breadcrumbs/PageNavigations";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { usePermissions } from "../contexts/PermissionContext";
import { text } from "@fortawesome/fontawesome-svg-core";

const ExamScheduleView = (props) => {
  const { rolePermissions, userInfo } = usePermissions();

  const paramId = useParams();

  console.log("paramId==>", paramId.id);
  const navigate = useNavigate();
  const [modalShow, setModalShow] = useState(false);
  const [record, setRecord] = useState();
  const [scheduleAndClassId, setScheduleAndClassId] = useState({
    classId: "",
    scheduleId: "",
  });
  const [resultRecords, setResultRecords] = useState();

  const [resultIdArr, setResultArr] = useState([]);
  // const [flage, setFlage] = useState(false);

  console.log("scheduleAndClassId@#$==>", scheduleAndClassId);

  console.log("@#!1resultRecords==>", resultRecords);
  console.log("@#1resultIdArr==>", resultIdArr);

  console.log("@#resultRecords==>", resultRecords);

  const canEdit = rolePermissions?.Exam_Schedule?.can_edit;
  const canDelete = rolePermissions?.Exam_Schedule?.can_delete;
  // update by pankaj 24-8-2024
  const fatchexamSchdual = async (id) => {
    const result = await schoolApi.fetchExamSchedulesById(id);

    if (result.success) {
      setScheduleAndClassId({
        classId: result.examSchedule.class_id,
        scheduleId: paramId.id,
        section_id: result.examSchedule.section_id,
      });

      console.log("schedulecklas", scheduleAndClassId);
      setRecord(result.examSchedule);
    } else {
      setRecord([]);
    }
  };
  useEffect(() => {
    fatchexamSchdual(paramId.id);
  }, []);
  // update by pankaj 24-8-2024
  const students = () => {
    if (scheduleAndClassId) {
      console.log("DsfdsfWORKKRKD");
      console.log("scheee", scheduleAndClassId);
      console.log("scheduleAndClassId-muskan->", scheduleAndClassId.section_id);
      schoolApi
        .fetchStudentsByIds(
          scheduleAndClassId?.classId,
          scheduleAndClassId?.scheduleId,
          scheduleAndClassId?.section_id
        )
        .then((result) => {
          // Bug fixed by muskan khan 5sept2024
          if (result?.success) {
            console.log("RESAJSDJSD", result);
            result = result?.result?.map((val) => {
              return {
                ...val,
                ispresent: val.ispresent == null ? true : val.ispresent,
                exam_schedule_id: paramId.id,
                isUpdate: false,
              };
            });
            console.log("result Data :", result);
            setResultRecords(result);
          } else {
            setResultRecords([]);
          }
        })
        .catch(() => {
          // if (!result.success) {
          setResultRecords([]);
          return toast.error("No student found!!");
          // }
        });
    }
  };

  const editSchedule = () => {
    navigate(`/examschedule/${paramId.id}/e`, { state: record });
  };

  const deleteSchedule = async () => {
    console.log("deleteSchedule", record.id);
    const result = await schoolApi.deleteSchedule(record.id);
    console.log("RESULTTTTTTT", result);
    if (result.success) {
      toast.success("Delete successfully"); //added by muskan khan
      setTimeout(() => {
        navigate(`/examschedule`);
      }, 1000);
    } else {
      toast.error(result.message);
      setModalShow(false);
    }
  };
  const header = [
    {
      title: "Student",
      prop: "student_name",
      isFilterable: true,
      cell: (row) => <a href="/studentaddmissions">{row.student_name}</a>,
    },
    {
      title: "Obtained Marks",
      prop: "obtained_marks",
      isFilterable: true,
      cell: (row) => (
        console.log("Hello == ", row),
        (
          <input
            // type="number"
            type="numeric"
            name="obtained_marks"
            defaultValue={row.obtained_marks}
            value={row.obtained_marks}
            placeholder="Enter Marks"
            min={1} //added by muskan
            onChange={(e) => handleChange(e, row)}
            // disabled={row.isPresent === true ? 'true' : 'false'}
            disabled={row.ispresent === false ? "true" : false}
          />
        )
        // <div>
        //   <input
        //     // type="number"
        //     type="numeric"
        //     name="obtained_marks"
        //     defaultValue={row.obtained_marks}
        //     value={row.obtained_marks}
        //     placeholder="Enter Marks"
        //     min={1} //added by muskan
        //     onChange={(e) => handleChange(e, row)}
        //     // disabled={row.isPresent === true ? 'true' : 'false'}
        //     disabled={row.ispresent === false ? 'true' : false}
        //     style={flage && row.obtained_marks > record.max_marks ? { borderColor: "red", borderWidth: "2px", borderStyle: "solid" } : undefined}
        //   />
        //   {flage && row.obtained_marks > record.max_marks && (
        //     <span style={{ display: "block", fontSize: "9px", color: "red", marginTop: "4px" ,fontWeight:"bold" }}>
        //       Enter valid marks
        //     </span>
        //   )}
        // </div>
      ),
    },
    {
      title: "Present",
      prop: "",
      cell: (row) => (
        <Form.Group className="mx-3 d-flex ">
          <Form.Check
            className={`student-result-switch ${
              row?.ispresent ? "switch-true" : "switch-false"
            }`} // Conditional classes based on the status
            type="switch"
            id={`student_result_toggle_${row.student_admissionid}`}
            checked={row?.ispresent === true}
            onChange={(e) => {
              const newStatus = e.target.checked ? true : false;
              handleCheckbox(row, newStatus, null);
            }}
          />
        </Form.Group>
      ),
    },
  ];

  const showToast = (
    err = "Obtained marks cannot exceed the maximum marks."
  ) => {
    toast.error(err);
  };

  const handleCheckbox = (row, checkbox) => {
    console.log("ROWW", checkbox);
    console.log("ROWWWWWWW", row);
    setResultRecords((prevRecords) =>
      prevRecords.map((record) => {
        if (record.student_admissionid === row.student_admissionid) {
          const isPresent = !record.ispresent; // Toggle the present status
          const obtainedMarks = isPresent ? row.obtained_marks : 0; // Set obtained_marks to 0 if absent
          return {
            ...record,
            ispresent: checkbox,
            obtained_marks: obtainedMarks,
            isUpdate: true, // Mark it as updated
          };
        }
        return record; // Return unchanged record
      })
    );
  };

  // update by pankaj 24-8-2024
  const handleChange = (e, row) => {
    console.log("ROW===>", row);
    console.log("hear");
    console.log("record ->", record.max_marks);

    if (e.target.name === "obtained_marks" && isNaN(e.target.value)) {
      return;
    }
    if (e.target.name === "obtained_marks") {
      if (e.target.value.includes(" ")) {
        console.log("come 2");
        return;
      }
    }
    if (e.target.value > record.max_marks) {
      // showToast();
      e.currentTarget.className = "is-invalid";
    } else {
      // setFlage(false)
      e.currentTarget.className = "";
    }
    console.log(e.target);
    setResultRecords((prevRecords) =>
      prevRecords.map((record) => {
        if (record.student_admissionid === row.student_admissionid) {
          const isUpdate = true;

          return { ...record, obtained_marks: e.target.value, isUpdate };
        }
        return record;
      })
    );
  };
  // update by pankaj 24-8-2024
  const handleSaveStudentResult = async () => {
    console.log("save hear");
    console.log("resultRecords in save ->", resultRecords);
    console.log("rRecords in save ->", record);
    let error = false;

    const result = resultRecords.map((element) => {
      console.log("ele->", element);

      console.log(element.obtained_marks);
      console.log(record.max_marks);
      if (element.obtained_marks > record.max_marks) {
        console.log("if");
        // setFlage(true)
        if (!error) {
          //Add this condition by shahir hussain 03-12-2024
          showToast();
          error = true;
        }
        // error = true;
        // showToast();
        return element;
      } else if (
        (element.obtained_marks == null || element.obtained_marks == "") &&
        element.ispresent
      ) {
        // setFlage(false)
        console.log("else if");
        if (!error) {
          //Add this condition by shahir hussain 28-11-2024
          showToast("Please fill obtain marks for all students");
          error = true;
        }
        return element;
      } else if (!element.ispresent) {
        console.log("else else if");

        console.log("hear error");
        error = false;
        return { ...element, obtained_marks: 0 };
      } else {
        console.log("else");
        return element;
      }
    });

    console.log("resultRecords in save after checking->", result);
    if (!error) {
      try {
        const res = await schoolApi.updateResult(result);
        console.log("result in save after upsert query ->", res);
        toast.success(res.message);
        handleCloseModal("successfully added Record");
      } catch (err) {
        console.log(err);
        toast.warn("Internel server error");
      }
    }
  };

  const handleCloseModal = (res) => {
    // toast.success(res);
    students();
  };

  const labels = {
    beforeSelect: " ",
  };

  console.log("resultRecords-singh->", resultRecords);

  return (
    <>
      {/* added by muskan khan */}
      <ToastContainer
        position="top-right"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <Helmet>
        {" "}
        <title>{props?.tabName}</title>{" "}
      </Helmet>
      <div>
        {record && (
          <Container>
            {modalShow && (
              <Confirm
                show={modalShow}
                onHide={() => setModalShow(false)}
                deleteSchedule={deleteSchedule}
                title="Confirm delete?"
                message="You are going to delete the record. Are you sure?"
                table="exam_schedule"
              />
            )}
            <PageNavigations
              listName="Exam Detail"
              listPath="/examschedule"
              viewName=""
              viewPath=""
              colLg={2}
              colClassName="d-flex mx-3 mb-3"
              extrColumn={12}
            />

            <Row className="view-form">
              <Col lg={12}>
                <Row className="view-form-header align-items-center mx-2">
                  <Col lg={3}>
                    <h5>{record.exam_title_name}</h5>
                  </Col>
                  <Col lg={9} className="d-flex justify-content-end">
                    {(userInfo?.userrole === "SYS_ADMIN" ||
                      userInfo?.userrole === "ADMIN" ||
                      canEdit) && (
                      <Button
                        className="btn-sm mx-2 edit-button"
                        onClick={() => editSchedule(true)}
                      >
                        <i className="fa-regular fa-pen-to-square"></i>
                      </Button>
                    )}
                    {(userInfo?.userrole === "SYS_ADMIN" ||
                      userInfo?.userrole === "ADMIN" ||
                      canDelete) && (
                      <Button
                        className="btn-sm"
                        variant="danger"
                        onClick={() => setModalShow(true)}
                        // onClick={handleDelete}
                      >
                        Delete
                      </Button>
                    )}
                  </Col>
                </Row>
                <Row>
                  <Col lg={12}>
                    <Col className="mx-3">
                      <Col className="section-header my-3">
                        <span style={{ color: "black" }}></span>
                      </Col>
                    </Col>
                  </Col>
                </Row>
                <Row>
                  <Col lg={1}></Col>
                  <Col lg={5}>
                    <label>Title</label>
                    <span>{record.exam_title_name}</span>
                  </Col>
                  <Col lg={5}>
                    <label>Class</label>
                    <span>
                      {record.class_name} [{record.section_name}]
                    </span>
                  </Col>
                  <Col lg={1}></Col>
                  <Col lg={1}></Col>
                  <Col lg={5}>
                    <label>Date</label>
                    <span>
                      {moment(record.schedule_date).format("DD-MM-YYYY")}
                    </span>
                  </Col>
                  <Col lg={5}>
                    <label>Examinor</label>
                    <span>{record.examinor_info}</span>
                  </Col>

                  <Col lg={1}></Col>
                  <Col lg={1}></Col>
                  <Col lg={5}>
                    <label>Subject</label>
                    <span>{record.subject_name}</span>
                  </Col>
                  <Col lg={5}>
                    <label>Max Marks</label>
                    <span>{record.max_marks}</span>
                  </Col>
                  <Col lg={1}></Col>
                  <Col lg={1}></Col>
                  <Col lg={5}>
                    <label>Min Marks</label>
                    <span>{record.min_marks}</span>
                  </Col>
                  <Col lg={5}>
                    <label>Duration</label>
                    <span>{record.duration}</span>
                  </Col>
                  <Col lg={1}></Col>
                  <Col lg={1}></Col>
                  <Col lg={5}>
                    <label>Start Time</label>
                    <span>{record.start_time}</span>
                  </Col>

                  <Col lg={5}>
                    <label>End Time</label>
                    <span>{record.end_time}</span>
                  </Col>
                  <Col lg={1}></Col>
                  <Col lg={1}></Col>
                  <Col lg={5}>
                    <label>status</label>
                    <span>{record.status}</span>
                  </Col>

                  <Col lg={5}>
                    <label>Room Number</label>
                    <span>{record.room_no}</span>
                  </Col>
                  <Col lg={1}></Col>
                  <Col lg={1}></Col>
                  <Col lg={5}>
                    <label>Is Practical</label>
                    <span>{record.ispractical === true ? "Yes" : "No"}</span>
                  </Col>
                  {/* <Col lg={1}>
                  <label>Section</label>
                
                  <span>{record.section_name}</span>
                  </Col> */}
                  <Col lg={1}></Col>
                  <Col lg={1}></Col>

                  <Col lg={1}></Col>
                  <Col lg={1}></Col>

                  <Col></Col>
                </Row>
                <Row></Row>
              </Col>
              <Col></Col>
            </Row>
          </Container>
        )}
      </div>

      <Row>
        <Col lg={12}>
          <Col className="mx-3">
            <Col className="section-header my-3">
              <span
                className="d-flex justify-content-between"
                style={{ color: "black" }}
              >
                Exam Result
                <Button
                  className="btn-sm mx-3 add-new-button"
                  variant="primary"
                  onClick={() => students()}
                >
                  Add Result
                </Button>
              </span>
            </Col>
          </Col>
        </Col>
      </Row>

      {resultRecords?.length > 0 && (
        <Row className="g-0">
          <Col lg={12} className="p-lg-4">
            {resultRecords?.length > 0 ? (
              <DatatableWrapper
                body={resultRecords}
                headers={header}
                paginationOptionsProps={{
                  initialState: {
                    rowsPerPage: 15,
                    options: [5, 10, 15, 20],
                  },
                }}
              >
                <Row className="mb-4">
                  <Col lg={3}>
                    <Filter />
                  </Col>
                  <Col lg={1} style={{ "margin-top": "-18px" }}>
                    <PaginationOptions labels={labels} />
                  </Col>
                  <Col lg={3} style={{ "margin-top": "-13px" }}>
                    <div>
                      <InfoPill
                        left="Total Exams"
                        right={resultRecords?.length}
                      />
                    </div>
                  </Col>
                  <Col
                    lg={5}
                    className="d-flex justify-content-end align-items-center ms-7"
                  >
                    <div className="text-end">
                      <Button
                        className="btn-sm mx-3 add-new-button  btn"
                        variant=""
                        onClick={handleSaveStudentResult}
                        style={{
                          width: "70px",
                          height: "25px",
                          fontSize: "12px",
                          marginTop: "-10px",
                        }}
                      >
                        Save
                      </Button>
                    </div>
                  </Col>
                </Row>

                <Table striped className="data-table">
                  <TableHeader />
                  <TableBody />
                </Table>
                <Pagination />
              </DatatableWrapper>
            ) : (
              <ShimmerTable row={10} col={8} />
            )}
          </Col>

          <Col lg={2}></Col>
        </Row>
      )}
    </>
  );
};
export default ExamScheduleView;
// /**
//  * @author: Pawan Singh Sisodiya
//  */
// import Form from "react-bootstrap/Form"; // or the appropriate library

// import React, { useState, useEffect } from "react";
// import "../../App.css";
// import { Button, Col, Container, Table, Row } from "react-bootstrap";
// import {
//   DatatableWrapper,
//   Filter,
//   Pagination,
//   PaginationOptions,
//   TableBody,
//   TableHeader,
// } from "react-bs-datatable";

// import Confirm from "../Confirm";
// import InfoPill from "../InfoPill";
// import { ShimmerTable } from "react-shimmer-effects";
// import { Link, useParams } from "react-router-dom";
// import { useNavigate } from "react-router-dom";
// import schoolApi from "../../api/schoolApi";
// import moment from "moment";
// import PubSub from "pubsub-js";
// import { Helmet } from "react-helmet";
// import PageNavigations from "../breadcrumbs/PageNavigations";
// import { ToastContainer, toast } from "react-toastify";
// import "react-toastify/dist/ReactToastify.css";
// import { usePermissions } from "../contexts/PermissionContext";

// const ExamScheduleView = (props) => {

//   const { rolePermissions, userInfo } = usePermissions();

//   const paramId = useParams();

//   console.log("paramId==>", paramId.id);
//   const navigate = useNavigate();
//   const [modalShow, setModalShow] = useState(false);
//   const [record, setRecord] = useState();
//   const [scheduleAndClassId, setScheduleAndClassId] = useState({
//     classId: "",
//     scheduleId: "",
//   });
//   const [resultRecords, setResultRecords] = useState();

//   const [resultIdArr, setResultArr] = useState([]);

//   console.log("scheduleAndClassId@#$==>", scheduleAndClassId);

//   console.log("@#!1resultRecords==>", resultRecords);
//   console.log("@#1resultIdArr==>", resultIdArr);

//   console.log("@#resultRecords==>", resultRecords);

//   const canEdit = rolePermissions?.Exam_Schedule?.can_edit;
//   const canDelete = rolePermissions?.Exam_Schedule?.can_delete;
//   // update by pankaj 24-8-2024
//  const fatchexamSchdual = async (id) => {
//     const result = await schoolApi.fetchExamSchedulesById(id);

//     if (result.success) {
//       setScheduleAndClassId({
//         classId: result.examSchedule.class_id,
//         scheduleId: paramId.id,
//         section_id : result.examSchedule.section_id
//       });

//       console.log("schedulecklas",scheduleAndClassId)
//       setRecord(result.examSchedule);
//     } else {
//       setRecord([]);
//     }
//   };
//   useEffect(() => {
//     fatchexamSchdual(paramId.id);
//   }, []);
//   // update by pankaj 24-8-2024
//   const students = () => {
//     if (scheduleAndClassId) {
//       console.log("DsfdsfWORKKRKD")
//       console.log("scheee",scheduleAndClassId)
//       console.log('scheduleAndClassId-muskan->',scheduleAndClassId.section_id);
//       schoolApi
//         .fetchStudentsByIds(
//           scheduleAndClassId?.classId,
//           scheduleAndClassId?.scheduleId,
//           scheduleAndClassId?.section_id
//         )
//         .then((result) => {

//           // Bug fixed by muskan khan 5sept2024
//           if (result?.success) {
//             console.log("RESAJSDJSD", result);
//             result = result?.result?.map((val) => {
//               return {
//                 ...val,
//                 ispresent: val.ispresent == null ? true : val.ispresent,
//                 exam_schedule_id: paramId.id,
//                 isUpdate: false,
//               };
//             });
//             console.log("result Data :", result);
//             setResultRecords(result);
//           } else {
//             setResultRecords([]);
//           }
//         }).catch(()=>{
//           // if (!result.success) {
//             setResultRecords([]);
//             return toast.error("No student found!!");
//           // }
//         });
//     }
//   };

//   const editSchedule = () => {
//     navigate(`/examschedule/${paramId.id}/e`, { state: record });
//   };

//   const deleteSchedule = async () => {
//     console.log("deleteSchedule", record.id);
//     const result = await schoolApi.deleteSchedule(record.id);
//     console.log("RESULTTTTTTT", result);
//     if (result.success) {
//       toast.success("Delete successfully"); //added by muskan khan
//       setTimeout(() => {
//         navigate(`/examschedule`);
//       }, 1000);
//     } else {
//       toast.error(result.message);
//       setModalShow(false);
//     }
//   };
//   const header = [
//     {
//       title: "Student",
//       prop: "student_name",
//       isFilterable: true,
//       cell: (row) => <a href="/studentaddmissions">{row.student_name}</a>,
//     },
//     {
//       title: "Obtained Marks",
//       prop: "obtained_marks",
//       isFilterable: true,
//       cell: (row) => (
//         <input
//           type="number"
//           name="obtained_marks"
//           defaultValue={row.obtained_marks}
//           value={row.obtained_marks}
//           placeholder="Enter Marks"
//           min={1} //added by muskan
//           onChange={(e) => handleChange(e, row)}
//         />
//       ),
//     },
//     {
//       title: "Present",
//       prop: "",
//       cell: (row) => (
//         <Form.Group className="mx-3 d-flex ">
//           <Form.Check
//             className={`student-result-switch ${
//               row?.ispresent ? "switch-true" : "switch-false"
//             }`} // Conditional classes based on the status
//             type="switch"
//             id={`student_result_toggle_${row.student_admissionid}`}
//             checked={row?.ispresent === true}
//             onChange={(e) => {
//               const newStatus = e.target.checked ? true : false;
//               handleCheckbox(row, newStatus, null);
//             }}

//           />
//         </Form.Group>
//       ),
//     },
//   ];

//   const showToast = (
//     err = "Obtained marks cannot exceed the maximum marks."
//   ) => {
//     toast.error(err);
//   };

//   const handleCheckbox = (row, checkbox) => {
//     console.log("ROWW", checkbox);
//     console.log("ROWWWWWWW", row);
//     setResultRecords((prevRecords) =>
//       prevRecords.map((record) => {
//         if (record.student_admissionid === row.student_admissionid) {
//           const isPresent = !record.ispresent; // Toggle the present status
//           const obtainedMarks = isPresent ? row.obtained_marks : 0; // Set obtained_marks to 0 if absent
//           return {
//             ...record,
//             ispresent: checkbox,
//             obtained_marks: obtainedMarks,
//             isUpdate: true, // Mark it as updated
//           };
//         }
//         return record; // Return unchanged record
//       })
//     );
//   };

//   // update by pankaj 24-8-2024
//   const handleChange = (e, row) => {
//     console.log("ROW===>", row);
//     console.log("hear");
//     console.log("record ->", record.max_marks);
//     if (e.target.value > record.max_marks) {
//       showToast();
//       e.currentTarget.className = "is-invalid";
//     } else {
//       e.currentTarget.className = "";
//     }
//     console.log(e.target);
//     setResultRecords((prevRecords) =>
//       prevRecords.map((record) => {
//         if (record.student_admissionid === row.student_admissionid) {
//           const isUpdate = true;

//           return { ...record, obtained_marks: e.target.value, isUpdate };
//         }
//         return record;
//       })
//     );
//   };
//   // update by pankaj 24-8-2024
//   const handleSaveStudentResult = async () => {
//     console.log("save hear");
//     console.log("resultRecords in save ->", resultRecords);
//     console.log("rRecords in save ->", record);
//     let error = false;

//     const result = resultRecords.map((element) => {
//       console.log("ele->", element);

//       console.log(element.obtained_marks);
//       console.log(record.max_marks);
//       if (element.obtained_marks > record.max_marks) {
//         error = true;
//         showToast();
//         return element;
//       } else if (element.obtained_marks == null && element.ispresent) {
//         showToast("Please fill obtain marks for all students");
//         error = true;
//         return element;
//       } else if (!element.ispresent) {
//         console.log("hear error");
//         error = false;
//         return { ...element, obtained_marks: 0 };
//       } else {
//         return element;
//       }
//     });

//     console.log("resultRecords in save after checking->", result);
//     if (!error) {
//       try {
//         const res = await schoolApi.updateResult(result);
//         console.log("result in save after upsert query ->", res);
//         toast.success(res.message);
//         handleCloseModal("successfully added Record");
//       } catch (err) {
//         console.log(err);
//         toast.warn("Internel server error");
//       }
//     }
//   };

//   const handleCloseModal = (res) => {
//     // toast.success(res);
//     students();
//   };

//   const labels = {
//     beforeSelect: " ",
//   };

//   console.log('resultRecords-singh->',resultRecords)

//   return (
//     <>
//       {/* added by muskan khan */}
//       <ToastContainer
//         position="top-right"
//         autoClose={2000}
//         hideProgressBar={false}
//         newestOnTop={false}
//         closeOnClick
//         rtl={false}
//         pauseOnFocusLoss
//         draggable
//         pauseOnHover
//         theme="light"
//       />
//       <Helmet>
//         {" "}
//         <title>{props?.tabName}</title>{" "}
//       </Helmet>
//       <div>
//         {record && (
//           <Container>
//             {modalShow && (
//               <Confirm
//                 show={modalShow}
//                 onHide={() => setModalShow(false)}
//                 deleteSchedule={deleteSchedule}
//                 title="Confirm delete?"
//                 message="You are going to delete the record. Are you sure?"
//                 table="exam_schedule"
//               />
//             )}
//             <PageNavigations
//               listName="Exam Detail"
//               listPath="/examschedule"
//               viewName=""
//               viewPath=""
//               colLg={2}
//               colClassName="d-flex mx-3 mb-3"
//               extrColumn={12}
//             />

//             <Row className="view-form">
//               <Col lg={12}>
//                 <Row className="view-form-header align-items-center mx-2">
//                   <Col lg={3}>
//                     <h5>{record.exam_title_name}</h5>
//                   </Col>
//                   <Col lg={9} className="d-flex justify-content-end">
//                     {(userInfo?.userrole === "SYS_ADMIN" ||
//                       userInfo?.userrole === "ADMIN" ||
//                       canEdit) && (
//                       <Button
//                         className="btn-sm mx-2 edit-button"
//                         onClick={() => editSchedule(true)}
//                       >
//                         <i className="fa-regular fa-pen-to-square"></i>
//                       </Button>
//                     )}
//                     {(userInfo?.userrole === "SYS_ADMIN" ||
//                       userInfo?.userrole === "ADMIN" ||
//                       canDelete) && (
//                       <Button
//                         className="btn-sm"
//                         variant="danger"
//                         onClick={() => setModalShow(true)}
//                         // onClick={handleDelete}
//                       >
//                         Delete
//                       </Button>
//                     )}
//                   </Col>
//                 </Row>
//                 <Row>
//                   <Col lg={12}>
//                     <Col className="mx-3">
//                       <Col className="section-header my-3">
//                         <span style={{ color: "black" }}></span>
//                       </Col>
//                     </Col>
//                   </Col>
//                 </Row>
//                 <Row>
//                   <Col lg={1}></Col>
//                   <Col lg={5}>
//                     <label>Title</label>
//                     <span>{record.exam_title_name}</span>
//                   </Col>
//                   <Col lg={5}>
//                     <label>Class</label>
//                     <span>{record.class_name} [{record.section_name}]</span>
//                   </Col>
//                   <Col lg={1}></Col>
//                   <Col lg={1}></Col>
//                   <Col lg={5}>
//                     <label>Date</label>
//                     <span>
//                       {moment(record.schedule_date).format("DD-MM-YYYY")}
//                     </span>
//                   </Col>
//                   <Col lg={5}>
//                     <label>Examinor</label>
//                     <span>{record.examinor_info}</span>
//                   </Col>

//                   <Col lg={1}></Col>
//                   <Col lg={1}></Col>
//                   <Col lg={5}>
//                     <label>Subject</label>
//                     <span>{record.subject_name}</span>
//                   </Col>
//                   <Col lg={5}>
//                     <label>Max Marks</label>
//                     <span>{record.max_marks}</span>
//                   </Col>
//                   <Col lg={1}></Col>
//                   <Col lg={1}></Col>
//                   <Col lg={5}>
//                     <label>Min Marks</label>
//                     <span>{record.min_marks}</span>
//                   </Col>
//                   <Col lg={5}>
//                     <label>Duration</label>
//                     <span>{record.duration}</span>
//                   </Col>
//                   <Col lg={1}></Col>
//                   <Col lg={1}></Col>
//                   <Col lg={5}>
//                     <label>Start Time</label>
//                     <span>{record.start_time}</span>
//                   </Col>

//                   <Col lg={5}>
//                     <label>End Time</label>
//                     <span>{record.end_time}</span>
//                   </Col>
//                   <Col lg={1}></Col>
//                   <Col lg={1}></Col>
//                   <Col lg={5}>
//                     <label>status</label>
//                     <span>{record.status}</span>
//                   </Col>

//                   <Col lg={5}>
//                     <label>Room Number</label>
//                     <span>{record.room_no}</span>
//                   </Col>
//                   <Col lg={1}></Col>
//                   <Col lg={1}></Col>
//                   <Col lg={5}>
//                     <label>Is Practical</label>
//                     <span>{record.ispractical === true ? "Yes" : "No"}</span>
//                   </Col>
//                   {/* <Col lg={1}>
//                   <label>Section</label>

//                   <span>{record.section_name}</span>
//                   </Col> */}
//                   <Col lg={1}></Col>
//                   <Col lg={1}></Col>

//                   <Col lg={1}></Col>
//                   <Col lg={1}></Col>

//                   <Col></Col>
//                 </Row>
//                 <Row></Row>
//               </Col>
//               <Col></Col>
//             </Row>
//           </Container>
//         )}
//       </div>

//       <Row>
//         <Col lg={12}>
//           <Col className="mx-3">
//             <Col className="section-header my-3">
//               <span
//                 className="d-flex justify-content-between"
//                 style={{ color: "black" }}
//               >
//                 Exam Result
//                 <Button
//                   className="btn-sm mx-3 add-new-button"
//                   variant="primary"
//                   onClick={() => students()}
//                 >
//                   Add Result
//                 </Button>
//               </span>
//             </Col>
//           </Col>
//         </Col>
//       </Row>

//       {resultRecords?.length > 0 && (
//         <Row className="g-0">
//           <Col lg={12} className="p-lg-4">
//             {resultRecords?.length > 0 ? (
//               <DatatableWrapper
//                 body={resultRecords}
//                 headers={header}
//                 paginationOptionsProps={{
//                   initialState: {
//                     rowsPerPage: 15,
//                     options: [5, 10, 15, 20],
//                   },
//                 }}
//               >
//                 <Row className="mb-4">
//                   <Col lg={3}>
//                     <Filter />
//                   </Col>
//                   <Col lg={1} style={{ "margin-top": "-18px" }}>
//                     <PaginationOptions labels={labels} />
//                   </Col>
//                   <Col lg={3} style={{ "margin-top": "-13px" }}>
//                     <div>
//                       <InfoPill
//                         left="Total Exams"
//                         right={resultRecords?.length}
//                       />
//                     </div>
//                   </Col>
//                   <Col
//                     lg={5}
//                     className="d-flex justify-content-end align-items-center ms-7"
//                   >
//                     <div className="text-end">
//                       <Button
//                         className="btn-sm mx-3 add-new-button  btn"
//                         variant=""
//                         onClick={handleSaveStudentResult}
//                         style={{
//                           width: "70px",
//                           height: "25px",
//                           fontSize: "12px",
//                           marginTop: "-10px",
//                         }}
//                       >
//                         Save
//                       </Button>
//                     </div>
//                   </Col>
//                 </Row>

//                 <Table striped className="data-table">
//                   <TableHeader />
//                   <TableBody />
//                 </Table>
//                 <Pagination />
//               </DatatableWrapper>
//             ) : (
//               <ShimmerTable row={10} col={8} />
//             )}
//           </Col>

//           <Col lg={2}></Col>
//         </Row>
//       )}
//     </>
//   );
// };
// export default ExamScheduleView;
