import React, { useState, useEffect } from 'react'
import { ShimmerTable } from "react-shimmer-effects";
import moment from "moment";
import { Table, Button, Row, Col, Form } from "react-bootstrap";
import schoolApi from '../../api/schoolApi';

import {
  DatatableWrapper,
  Pagination,
  TableBody,
  TableHeader,
} from "react-bs-datatable";
import { MdPreview } from "react-icons/md";
import { Link, useNavigate } from 'react-router-dom';
import PreviewFeeInstallment from './PreviewFeeInstallment';
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function Fees(props) {
  console.log('studenId--->',props.studenId);
  const navigate = useNavigate();

  const [studentaddmission, setStudentAddmission] = useState({ fee_type_id: props?.state?.fee_type });
  const [fees, setFees] = useState([]);
  const [studentInstallments, setStudentInstallments] = useState([]);
  const [feeTypeOptions, setFeeTypeOptions] = useState([]);
  const [upcomingTypes, setUpcomingType] = useState({'Monthly': true, 'Bi-Monthly': false, 'Quarterly': false, 'Half Yearly': false})
  const [showModal, setShowModal] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  
  // console.log('row data-->',row);
  const handlePreviewClick = (row) => {
    console.log('row on preview click-->', row);
    setSelectedRow(row);
    setShowModal(true);
  };

  const header1 = [
    {
      title: 'Month',
      prop: 'month',
      isFilterable: true,

    },
    // {
    //   title: 'fee_type',
    //   prop: 'fee_master_type'
    // },
    // {
    //   title: 'Total Fees',
    //   prop: 'master_fees'
    // },
    {
      title: 'Installment Amt',
      prop: 'total_inst_amt'
    },
    {
      title: 'Other Amt',
      prop: 'other_amount'
    },
    // {
    //   title: 'Instmt Amt',
    //   prop: 'amount',

    // },
    // {
    //   title: 'Transport Fee',
    //   prop: 'transport_fee',

    // },
    // {
    //   title: 'Late Fee',
    //   prop: 'late_fee'
    // },
    // {
    //   title: 'Previous Due',
    //   prop: 'previous_due',
    //   cell: (row) => (row.previous_due ? row.previous_due : 0)
    // },
    // {
    //   title: 'Discount',
    //   prop: 'discount'
    // },
    {
      title: 'Deposited amt',
      prop: 'deposit_amount',
      isFilterable: true,
      cell: (row) => (row.deposit_amount ? row.deposit_amount : 0)

    },
    {
      title: 'Due Date',
      prop: 'due_date',
      isFilterable: true,
      cell: row => formatDate(row.due_date)
    },
    {
      title: 'Status',
      prop: 'status',
      cell: (row) => (row.status === 'completed' ? 'Completed' : 'Pending')
    },
    {
      title: 'Preview',
      prop: '',
      isFilterable: true,
      cell: (row) => (
        <Button title="Preview" onClick={() => handlePreviewClick(row)}>
          <MdPreview />
        </Button>
      )
    },
    // {
    //   title: 'Preview',
    //   prop: '',
    //   isFilterable: true,
    //   cell: (row) => (
    //     <Button onClick={()=>handlePreviewClick(row)}>
    //       <MdPreview />
    //     </Button>
    //     // <Link to="/feedepositeedit/e" state={{ row, readonly: true }}
    //     //   className="btn btn-sm btn-primary mx-2">
    //     //   <MdPreview />
    //     // </Link>
    //   )
    // }
  ];

  useEffect(() => {
    fetchFees();
    fetchInstallments();
  }, []);


  const fetchInstallments = async()=>{
    try{
      let feeMasterResult = await schoolApi.fetchFeeMasterByIdOrClassid(props?.studenId?.classid);
    console.log('feeMasterResult by classid-->', feeMasterResult);

    const currentSelectedType = feeMasterResult?.result?.find((res)=>res.id === props?.studentId?.fee_type)?.type
    console.log('currentSelectedType-->',currentSelectedType);

    if (feeMasterResult.success) {
      let ar = [];
      feeMasterResult?.result?.map((item) => {
        var obj = {};
        obj.value = item.id;
        obj.label = item.type;
        ar.push(obj);
      })
      console.log('ar- fee master result->', ar);
      setFeeTypeOptions(ar);
    } else {
      setFeeTypeOptions([]);
    }
    

    // ----- Last updated by pooja on 14 aug 2024 --------
    let result = await schoolApi.fetchStudentFeeInstallments(props?.studenId?.student_addmission_id);
      
      // let result = await schoolApi.fetchStudentFeeInstallments(props?.studenId?.id);
      console.log('result studentFeeInstallment on tab ==>', result);

      const pendingInstallmentCount = result.result.filter((res)=>res.status === 'pending');
      console.log('pendingInstallmentCount-->',pendingInstallmentCount);
      
      if (currentSelectedType === 'Half Yearly') {
        console.log('inside 1',currentSelectedType);
        if(pendingInstallmentCount.length === 1){
          console.log('inside 11-->', currentSelectedType)
          setUpcomingType((prev) => {
            return {...prev,'Monthly': true,'Bi-Monthly': true,'Quarterly': true,'Half Yearly': false}});
          
        }
        else  if(pendingInstallmentCount.length === 2){
          console.log('inside 12-->', currentSelectedType)
          setUpcomingType((prev) => {
            return {...prev,'Monthly': true,'Bi-Monthly': true,'Quarterly': true,'Half Yearly': false}});
        }
      }
      else if (currentSelectedType === 'Quarterly') {
        if(pendingInstallmentCount.length === 1){
          setUpcomingType((prev) => {
            return {...prev,'Monthly': true,'Bi-Monthly': false,'Quarterly': false,'Half Yearly': false}});
        }
        else  if(pendingInstallmentCount.length === 2){
          setUpcomingType((prev) => {
            return {...prev,'Monthly': true,'Bi-Monthly': true,'Quarterly': false,'Half Yearly': false}});
        } else if(pendingInstallmentCount.length === 3){
          setUpcomingType((prev) => {
            return {...prev,'Monthly': true,'Bi-Monthly': false,'Quarterly': false,'Half Yearly': false}});
        }
        else  if(pendingInstallmentCount.length === 4){
          setUpcomingType((prev) => {
            return {...prev,'Monthly': true,'Bi-Monthly': true,'Quarterly': false,'Half Yearly': false}});
        }

      }
      else if (currentSelectedType === 'Bi-Monthly') {
        setUpcomingType((prev) => {
          return {...prev,'Monthly': true,'Bi-Monthly': false,'Quarterly': false,'Half Yearly': false}});
      } 
      
      if (result.success) {
        setStudentInstallments(result.result);
      }
      else {
        setStudentInstallments([]);
      }
    }catch(error){
      toast.error(error.message);
    }
    
  }

  const fetchFees = async () => {
    try{
      console.log('fetchFees@@@=>', props.studenId)
      // let student_id = props.studenId;
     //  console.log('Hey sudet=>', student_id)
       if (props?.studenId?.id !== null) {
         const result = await schoolApi.fetchStudentAddmission(props?.studenId?.id);
         console.log('result-deposit->', result);
         if (result.success) {
           setFees(result?.studentAddmision);
         } else {
           setFees([]);
         }
       }
    }catch(error){
      toast.error(error?.response?.data?.message);
    }
   
  }

  // const handleChange = (e) => {
  //   console.log('inside the handleChange', e.target.value);
  //   setStudentAddmission({
  //     ...studentaddmission, [e.target.name]: e.target.value,
  //   });
  // };

  function formatDate(dateString) {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();
    return `${day}-${month}-${year}`;
  }

  console.log('studentaddmission-->',studentaddmission);

  return (
    <div>
        <ToastContainer
      position="top-right"
      autoClose={2000}
      hideProgressBar={false}
      newestOnTop={false}
      closeOnClick
      rtl={false}
      pauseOnFocusLoss
      draggable
      pauseOnHover
      theme="light"
      />
       <Row className="mt-4 mb-3">
          <Col className="mx-3">
            <DatatableWrapper body={studentInstallments} headers={header1} >
              <Table striped className="data-table">
                <TableHeader />
                <TableBody />
              </Table>
            </DatatableWrapper>
          </Col>

          {showModal && (
        <PreviewFeeInstallment
          row={selectedRow}
          showModal={showModal}
          setShowModal={setShowModal}
        />
      )}
        </Row>

    </div>
  )
}

export default Fees
// import React, { useState, useEffect } from 'react'
// import { ShimmerTable } from "react-shimmer-effects";
// import moment from "moment";
// import { Table, Button, Row, Col, Form } from "react-bootstrap";
// import schoolApi from '../../api/schoolApi';

// import {
//   DatatableWrapper,
//   Pagination,
//   TableBody,
//   TableHeader,
// } from "react-bs-datatable";
// import { MdPreview } from "react-icons/md";
// import { Link, useNavigate } from 'react-router-dom';
// import PreviewFeeInstallment from './PreviewFeeInstallment';

// function Fees(props) {
//   console.log('studenId--->',props.studenId);
//   const navigate = useNavigate();

//   const [studentaddmission, setStudentAddmission] = useState({ fee_type_id: props?.state?.fee_type });
//   const [fees, setFees] = useState([]);
//   const [studentInstallments, setStudentInstallments] = useState([]);
//   const [feeTypeOptions, setFeeTypeOptions] = useState([]);
//   const [upcomingTypes, setUpcomingType] = useState({'Monthly': true, 'Bi-Monthly': false, 'Quarterly': false, 'Half Yearly': false})
//   const [showModal, setShowModal] = useState(false);
//   const [selectedRow, setSelectedRow] = useState(null);
  
//   // console.log('row data-->',row);
//   const handlePreviewClick = (row) => {
//     console.log('row on preview click-->', row);
//     setSelectedRow(row);
//     setShowModal(true);
//   };

//   const header1 = [
//     {
//       title: 'Month',
//       prop: 'month',
//       isFilterable: true,

//     },
//     // {
//     //   title: 'fee_type',
//     //   prop: 'fee_master_type'
//     // },
//     // {
//     //   title: 'Total Fees',
//     //   prop: 'master_fees'
//     // },
//     {
//       title: 'Installment Amt',
//       prop: 'total_inst_amt'
//     },
//     {
//       title: 'Other Amt',
//       prop: 'other_amount'
//     },
//     // {
//     //   title: 'Instmt Amt',
//     //   prop: 'amount',

//     // },
//     // {
//     //   title: 'Transport Fee',
//     //   prop: 'transport_fee',

//     // },
//     // {
//     //   title: 'Late Fee',
//     //   prop: 'late_fee'
//     // },
//     // {
//     //   title: 'Previous Due',
//     //   prop: 'previous_due',
//     //   cell: (row) => (row.previous_due ? row.previous_due : 0)
//     // },
//     // {
//     //   title: 'Discount',
//     //   prop: 'discount'
//     // },
//     {
//       title: 'Deposited amt',
//       prop: 'deposit_amount',
//       isFilterable: true,
//       cell: (row) => (row.deposit_amount ? row.deposit_amount : 0)

//     },
//     {
//       title: 'Due Date',
//       prop: 'due_date',
//       isFilterable: true,
//       cell: row => formatDate(row.due_date)
//     },
//     {
//       title: 'Status',
//       prop: 'status',
//       cell: (row) => (row.status === 'completed' ? 'Completed' : 'Pending')
//     },
//     {
//       title: 'Preview',
//       prop: '',
//       isFilterable: true,
//       cell: (row) => (
//         <Button title="Preview" onClick={() => handlePreviewClick(row)}>
//           <MdPreview />
//         </Button>
//       )
//     },
//     // {
//     //   title: 'Preview',
//     //   prop: '',
//     //   isFilterable: true,
//     //   cell: (row) => (
//     //     <Button onClick={()=>handlePreviewClick(row)}>
//     //       <MdPreview />
//     //     </Button>
//     //     // <Link to="/feedepositeedit/e" state={{ row, readonly: true }}
//     //     //   className="btn btn-sm btn-primary mx-2">
//     //     //   <MdPreview />
//     //     // </Link>
//     //   )
//     // }
//   ];

//   useEffect(() => {
//     fetchFees();
//     fetchInstallments();
//   }, []);


//   const fetchInstallments = async()=>{

//     let feeMasterResult = await schoolApi.fetchFeeMasterByIdOrClassid(props?.studenId?.classid);
//     console.log('feeMasterResult by classid-->', feeMasterResult);

//     const currentSelectedType = feeMasterResult?.find((res)=>res.id === props?.studentId?.fee_type)?.type
//     console.log('currentSelectedType-->',currentSelectedType);

//     if (feeMasterResult) {
//       let ar = [];
//       feeMasterResult.map((item) => {
//         var obj = {};
//         obj.value = item.id;
//         obj.label = item.type;
//         ar.push(obj);
//       })
//       console.log('ar- fee master result->', ar);
//       setFeeTypeOptions(ar);
//     } else {
//       setFeeTypeOptions([]);
//     }
    

//     // ----- Last updated by pooja on 14 aug 2024 --------
//     let result = await schoolApi.fetchStudentFeeInstallments(props?.studenId?.student_addmission_id);
      
//       // let result = await schoolApi.fetchStudentFeeInstallments(props?.studenId?.id);
//       console.log('result studentFeeInstallment on tab ==>', result);

//       const pendingInstallmentCount = result.result.filter((res)=>res.status === 'pending');
//       console.log('pendingInstallmentCount-->',pendingInstallmentCount);
      
//       if (currentSelectedType === 'Half Yearly') {
//         console.log('inside 1',currentSelectedType);
//         if(pendingInstallmentCount.length === 1){
//           console.log('inside 11-->', currentSelectedType)
//           setUpcomingType((prev) => {
//             return {...prev,'Monthly': true,'Bi-Monthly': true,'Quarterly': true,'Half Yearly': false}});
          
//         }
//         else  if(pendingInstallmentCount.length === 2){
//           console.log('inside 12-->', currentSelectedType)
//           setUpcomingType((prev) => {
//             return {...prev,'Monthly': true,'Bi-Monthly': true,'Quarterly': true,'Half Yearly': false}});
//         }
//       }
//       else if (currentSelectedType === 'Quarterly') {
//         if(pendingInstallmentCount.length === 1){
//           setUpcomingType((prev) => {
//             return {...prev,'Monthly': true,'Bi-Monthly': false,'Quarterly': false,'Half Yearly': false}});
//         }
//         else  if(pendingInstallmentCount.length === 2){
//           setUpcomingType((prev) => {
//             return {...prev,'Monthly': true,'Bi-Monthly': true,'Quarterly': false,'Half Yearly': false}});
//         } else if(pendingInstallmentCount.length === 3){
//           setUpcomingType((prev) => {
//             return {...prev,'Monthly': true,'Bi-Monthly': false,'Quarterly': false,'Half Yearly': false}});
//         }
//         else  if(pendingInstallmentCount.length === 4){
//           setUpcomingType((prev) => {
//             return {...prev,'Monthly': true,'Bi-Monthly': true,'Quarterly': false,'Half Yearly': false}});
//         }

//       }
//       else if (currentSelectedType === 'Bi-Monthly') {
//         setUpcomingType((prev) => {
//           return {...prev,'Monthly': true,'Bi-Monthly': false,'Quarterly': false,'Half Yearly': false}});
//       } 
      
//       if (result.success) {
//         setStudentInstallments(result.result);
//       }
//       else {
//         setStudentInstallments([]);
//       }
//   }

//   const fetchFees = async () => {
//     console.log('fetchFees@@@=>', props.studenId)
//    // let student_id = props.studenId;
//   //  console.log('Hey sudet=>', student_id)
//     if (props?.studenId?.id !== null) {
//       const result = await schoolApi.fetchStudentAddmission(props?.studenId?.id);
//       console.log('result-deposit->', result);
//       if (result) {
//         setFees(result);
//       } else {
//         setFees([]);
//       }
//     }
//   }

//   const handleChange = (e) => {
//     console.log('inside the handleChange', e.target.value);
//     setStudentAddmission({
//       ...studentaddmission, [e.target.name]: e.target.value,
//     });
//   };

//   function formatDate(dateString) {
//     const date = new Date(dateString);
//     const day = String(date.getDate()).padStart(2, '0');
//     const month = String(date.getMonth() + 1).padStart(2, '0');
//     const year = date.getFullYear();
//     return `${day}-${month}-${year}`;
//   }

//   console.log('studentaddmission-->',studentaddmission);

//   return (
//     <div>
//        <Row className="mt-4 mb-3">
//           <Col className="mx-3">
//             <DatatableWrapper body={studentInstallments} headers={header1} >
//               <Table striped className="data-table">
//                 <TableHeader />
//                 <TableBody />
//               </Table>
//             </DatatableWrapper>
//           </Col>

//           {showModal && (
//         <PreviewFeeInstallment
//           row={selectedRow}
//           showModal={showModal}
//           setShowModal={setShowModal}
//         />
//       )}
//         </Row>

//     </div>
//   )
// }

// export default Fees