import React, { useEffect, useState } from "react";
import { Col, Row, Table } from "react-bootstrap";
// import { useNavigate } from "react-router-dom";
import schoolApi from "../../api/schoolApi";
import PdfHeader from "../layout/common/PdfHeader";

const Result = ({ classId, studentrecords, admissionId }) => {
  const [studentData, setStudentData] = useState(null);
  const [smarks, setMarks] = useState([]);
  const [subjectArray, setSubjectArray] = useState([]);
  const fetchStudentResult = async (classId, admissionId) => {
    console.log("Fetching student result...");

    try {
      const result = await schoolApi.getStudentResult(classId, admissionId);
      console.log("API Result:", result);

      if (result.success) {
        let studentRecord = {
          studentname: result?.results?.student_result.studentname,
          classname: result?.results?.student_result.classname,
          dateofbirth: result?.results?.student_result.dateofbirth,
          fathername: result?.results?.student_result.fathername,
          mothername: result?.results?.student_result.mothername,
          registration_no: result?.results?.student_result.registration_no,
          parentPhone: result?.results?.student_result.parentPhone,
        };
        console.log("studentRecord-->>>", studentRecord);

        setStudentData(studentRecord);
        const subjects = [];

        const resArr = result?.results?.student_result?.result;
        console.log("resArr--->>>>", resArr);

        result?.results?.student_result?.result?.map((rs) => {
          if (!subjects.some((sub) => sub.label === rs.subject_id)) {
            subjects.push({ label: rs.subject_id, value: rs.subject });
          }
        });

        const groupedData = {};

        for (const record of resArr) {
          const { exam_type } = record;

          if (!groupedData[exam_type]) {
            groupedData[exam_type] = [];
          }

          groupedData[exam_type].push(record);
        }
        console.log("groupedData->", groupedData);

        const redddsult = Object.keys(groupedData).map((key) => ({
          exam_type: key,
          exams: groupedData[key],
        }));

        console.log("res->", redddsult);
        setSubjectArray(subjects);
        setMarks(redddsult);
        // setSubjectArray(subjects);
        // setMarks(resArr);
      } else {
        console.error(
          "Failed to fetch results:",
          result.message || "Unknown error"
        );
      }
    } catch (error) {
      console.error("Error fetching student results:", error);
    }
  };

  console.log("smarks--->>>", smarks);
  console.log("studentrecords ->", studentrecords);

  useEffect(() => {
    if (classId && admissionId) {
      fetchStudentResult(classId, admissionId);
    }
  }, [classId, admissionId]);

  const calculateTotals = () => {
    const totals = (smarks || []).reduce(
      (acc, group) => {
        const groupTotals = (group.exams || []).reduce(
          (groupAcc, exam) => {
            groupAcc.totalMarks += exam.max_marks || 0;
            groupAcc.obtainedMarks += exam.marks_obtained || 0;
            return groupAcc;
          },
          { totalMarks: 0, obtainedMarks: 0 }
        );

        acc.totalMarks += groupTotals.totalMarks;
        acc.obtainedMarks += groupTotals.obtainedMarks;
        return acc;
      },
      { totalMarks: 0, obtainedMarks: 0 }
    );

    const percentage =
      totals.totalMarks > 0
        ? (totals.obtainedMarks / totals.totalMarks) * 100
        : 0;

    // Determine grade based on percentage
    const grade = (() => {
      if (percentage >= 90) return "A+";
      if (percentage >= 80) return "A";
      if (percentage >= 70) return "B+";
      if (percentage >= 60) return "B";
      if (percentage >= 50) return "C";
      return "F"; // Fail
    })();

    // Determine division based on percentage
    const division = (() => {
      if (percentage >= 60) return "First Division";
      if (percentage >= 50) return "Second Division";
      if (percentage >= 40) return "Third Division";
      return "Fail";
    })();

    return { ...totals, percentage, grade, division };
  };

  // Call the function and destructure its results
  const { totalMarks, obtainedMarks, percentage, grade, division } =
    calculateTotals();
  console.log("Total Marks:", totalMarks);
  console.log("Obtained Marks:", obtainedMarks);
  console.log("Percentage:", percentage.toFixed(2));
  console.log("Grade:", grade);
  console.log("Division:", division);

  console.log("student-data--->>>", studentData);

  return (
    <div>
      {studentData ? (
        <>
          <Row>
            <PdfHeader />
          </Row>
          <Row className="m-0">
            <Col lg={12} className="p-0">
              <h4
                className="m-0"
                style={{
                  fontWeight: "700",
                  textAlign: "center",
                  color: "white",
                  backgroundColor: "#de8989",
                  padding: "5px 0px",
                  textTransform: "uppercase",
                  fontFamily: "sans-serif",
                }}
              >
                Student's Profile
              </h4>
            </Col>

            {studentData ? (
              <section className="studentProfile my-3">
                <Col lg={4} className="p-0">
                  <Row className="m-0">
                    <Col lg={1}></Col>
                    <Col lg={4}>Name</Col>
                    <Col lg={1} className="p-0">
                      :
                    </Col>
                    <Col lg={5} className="ps-0">
                      {studentData.studentname}
                    </Col>
                  </Row>
                  <Row className="m-0">
                    <Col lg={1}></Col>
                    <Col lg={4}>Mother's Name</Col>
                    <Col lg={1} className="p-0">
                      :
                    </Col>
                    <Col lg={5} className="ps-0">
                      {studentData.mothername}
                    </Col>
                  </Row>
                  <Row className="m-0">
                    <Col lg={1}></Col>
                    <Col lg={4}>Class Name</Col>
                    <Col lg={1} className="p-0">
                      :
                    </Col>
                    <Col lg={5} className="ps-0">
                      {studentData.classname}
                    </Col>
                  </Row>
                  <Row className="m-0">
                    <Col lg={1}></Col>
                    <Col lg={4}>DOB</Col>
                    <Col lg={1} className="p-0">
                      :
                    </Col>
                    <Col lg={5} className="ps-0">
                      {studentData.dateofbirth}
                    </Col>
                  </Row>
                </Col>
                <Col lg={4} className="p-0">
                  <Row className="m-0">
                    <Col lg={1}></Col>
                    <Col lg={4}>Father's Name</Col>
                    <Col lg={1} className="p-0">
                      :
                    </Col>
                    <Col lg={5} className="ps-0">
                      {studentData.fathername}
                    </Col>
                  </Row>
                  <Row className="m-0">
                    <Col lg={1}></Col>
                    <Col lg={4}>Mobile</Col>
                    <Col lg={1} className="p-0">
                      :
                    </Col>
                    <Col lg={5} className="ps-0">
                      {studentData.parentPhone}
                    </Col>
                  </Row>
                  <Row className="m-0">
                    <Col lg={1}></Col>
                    <Col lg={4}>Registration no</Col>
                    <Col lg={1} className="p-0">
                      :
                    </Col>
                    <Col lg={5} className="ps-0">
                      {studentData.registration_no}
                    </Col>
                  </Row>
                </Col>
                <Col lg={4} className="student-image">
                  {studentrecords.file_parent_id && studentrecords.file_id ? (
                    <img
                      src={`/uploads/${studentrecords.file_parent_id}/${studentrecords.file_id}.${studentrecords.file_type}`}
                      alt="profile"
                      className="student-profile-picture"
                    />
                  ) : (
                    <img
                      src={`/uploads/dwps_ajmer/image_2024102442318.png`}
                      alt="dummy_profile"
                      height={100}
                    />
                  )}
                  {/* <img src={"/uploads/dwps_ajmer/no-user.png"} alt="Student" /> */}
                </Col>
              </section>
            ) : (
              "Please Wait"
            )}
          </Row>
          <Row className="m-0">
            <Col lg={12} className="p-0">
              <h4
                className="m-0"
                style={{
                  fontWeight: "700",
                  textAlign: "center",
                  color: "white",
                  backgroundColor: "#de8986",
                  padding: "5px 0px",
                  textTransform: "uppercase",
                  fontFamily: "sans-serif",
                }}
              >
                Academic Performance
              </h4>
            </Col>
            {/* {smarks && smarks?.length && (
              <Col lg={12} className="p-0">
                <Table striped bordered hover className="mx-1">
                  <thead>
                    <tr>
                      <th style={{ textAlign: "center" }}>Exam Title</th>
                      {[...new Set(smarks.map((item) => item.exam_type))].map(
                        (examType) => {
                          const subjectFound = smarks.find(
                            (r) => r.exam_type === examType
                          );

                          return (
                            <th
                              colSpan="2"
                              style={{ textAlign: "center" }}
                              key={examType}
                            >
                              {examType}
                            </th>
                          );
                        }
                      )}
                    </tr>
                    <tr>
                      <th style={{ textAlign: "center" }}>Subject</th>
                      {[...new Set(smarks.map((item) => item.exam_type))].map(
                        (examType) => (
                          <>
                            <th
                              key={`${examType}-total`}
                              style={{ textAlign: "center" }}
                            >
                              Total
                            </th>
                            <th
                              key={`${examType}-obtained`}
                              style={{ textAlign: "center" }}
                            >
                              Obtained
                            </th>
                          </>
                        )
                      )}
                    </tr>
                  </thead>

                  <tbody>
                    {subjectArray.map((subjectName) => (
                      <tr key={subjectName.value}>
                        <td>
                          <strong>{subjectName.value}</strong>
                        </td>
                        {console.log("sds", subjectName)}
                        {[...new Set(smarks.map((item) => item.exam_type))].map(
                          (item) => {
                            return (
                              <>
                                <td align="right">
                                  {smarks.find(
                                    (item) => item.subject === subjectName.value
                                  )?.max_marks || 0}
                                </td>
                                <td align="right">
                                  {smarks.find(
                                    (item) => item.subject === subjectName.value
                                  )?.marks_obtained || 0}
                                </td>
                              </>
                            );
                          }
                        )}
                      </tr>
                    ))}
                    <tr>
                      <td>
                        <strong>Total Marks Obtained</strong>
                      </td>
                      {[...new Set(smarks.map((item) => item.exam_type))].map(
                        (item) => {
                          const totalMarksObtained = item.marks_obtained;
                          const totalMaxMarks = item.max_marks;
                          return (
                            <>
                              <td align="right" key={item.exam}>
                                <strong>{totalMaxMarks}</strong>
                              </td>
                              <td align="right" key={item.exam}>
                                <strong>{totalMarksObtained}</strong>
                              </td>
                            </>
                          );
                        }
                      )}
                    </tr>
                  </tbody>
                </Table>
              </Col>
            )} */}
            {smarks && (
              <Col lg={12} className="p-0">
                <Table bordered hover>
                  <thead>
                    <tr>
                      <th style={{ textAlign: "center" }}>Exam Title</th>
                      {smarks?.map((group) => (
                        <th
                          colSpan="2"
                          style={{ textAlign: "center" }}
                          key={group.exam_type}
                        >
                          {group.exam_type}
                        </th>
                      ))}
                      <th style={{ textAlign: "center" }} rowSpan={2}>
                        Total Marks
                      </th>
                      <th style={{ textAlign: "center" }} rowSpan={2}>
                        Obtained Marks
                      </th>
                      <th style={{ textAlign: "center" }} rowSpan={2}>
                        Grade
                      </th>
                    </tr>
                    <tr>
                      <th style={{ textAlign: "center" }}>Subject</th>
                      {smarks?.map((group) => (
                        <React.Fragment key={group.exam_type}>
                          <th style={{ textAlign: "center" }}>Total</th>
                          <th style={{ textAlign: "center" }}>Obtained</th>
                        </React.Fragment>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {/* Iterate through unique subjects */}
                    {subjectArray.map((subjectName) => {
                      let totalMaxMarks = 0; // Accumulator for subject's total max marks
                      let totalObtainedMarks = 0; // Accumulator for subject's total obtained marks

                      return (
                        <tr key={subjectName.value}>
                          <td>
                            <strong>{subjectName.value}</strong>
                          </td>
                          {smarks?.map((group) => {
                            const subjectExam = group.exams.find(
                              (exam) => exam.subject === subjectName.value
                            );

                            // Accumulate totals for this subject
                            totalMaxMarks += subjectExam?.max_marks || 0;
                            totalObtainedMarks +=
                              subjectExam?.marks_obtained || 0;

                            return (
                              <React.Fragment key={group.exam_type}>
                                <td align="right">
                                  {subjectExam?.max_marks || ""}
                                </td>
                                <td align="right">
                                  {subjectExam?.marks_obtained || ""}
                                </td>
                              </React.Fragment>
                            );
                          })}
                          {/* Display total marks for this subject */}
                          <td align="right">
                            <strong>{totalMaxMarks}</strong>
                          </td>
                          <td align="right">
                            <strong>{totalObtainedMarks}</strong>
                          </td>
                          <td align="right">
                            {(() => {
                              if (totalMaxMarks > 0) {
                                const percentage =
                                  (totalObtainedMarks / totalMaxMarks) * 100;

                                if (percentage >= 90) return "A+";
                                if (percentage >= 80) return "A";
                                if (percentage >= 70) return "B+";
                                if (percentage >= 60) return "B";
                                if (percentage >= 50) return "C";
                                return "F";
                              }
                              return "-";
                            })()}
                          </td>
                        </tr>
                      );
                    })}

                    {/* Total Marks Row */}
                    <tr>
                      <td>
                        <strong>Total Marks Obtained</strong>
                      </td>
                      {smarks?.map((group) => {
                        const totalMarks = group.exams.reduce(
                          (sum, exam) => sum + (exam.max_marks || 0),
                          0
                        );
                        const obtainedMarks = group.exams.reduce(
                          (sum, exam) => sum + (exam.marks_obtained || 0),
                          0
                        );

                        return (
                          <React.Fragment key={group.exam_type}>
                            <td align="right">
                              <strong>{totalMarks}</strong>
                            </td>
                            <td align="right">
                              <strong>{obtainedMarks}</strong>
                            </td>
                          </React.Fragment>
                        );
                      })}
                      <td align="right">
                        <strong>
                          {/* Grand Total of all exam max marks */}
                          {smarks.reduce(
                            (grandTotal, group) =>
                              grandTotal +
                              group.exams.reduce(
                                (sum, exam) => sum + (exam.max_marks || 0),
                                0
                              ),
                            0
                          )}
                        </strong>
                      </td>
                      <td align="right">
                        <strong>
                          {/* Grand Total of all exam obtained marks */}
                          {smarks.reduce(
                            (grandObtained, group) =>
                              grandObtained +
                              group.exams.reduce(
                                (sum, exam) => sum + (exam.marks_obtained || 0),
                                0
                              ),
                            0
                          )}
                        </strong>
                      </td>
                      <td align="right">
                        {/* Grade Logic */}
                        {(() => {
                          const grandTotalMarks = smarks.reduce(
                            (grandTotal, group) =>
                              grandTotal +
                              group.exams.reduce(
                                (sum, exam) => sum + (exam.max_marks || 0),
                                0
                              ),
                            0
                          );

                          const grandObtainedMarks = smarks.reduce(
                            (grandObtained, group) =>
                              grandObtained +
                              group.exams.reduce(
                                (sum, exam) => sum + (exam.marks_obtained || 0),
                                0
                              ),
                            0
                          );

                          if (grandTotalMarks > 0) {
                            const percentage =
                              (grandObtainedMarks / grandTotalMarks) * 100;

                            if (percentage >= 90) return "A+";
                            if (percentage >= 80) return "A";
                            if (percentage >= 70) return "B+";
                            if (percentage >= 60) return "B";
                            if (percentage >= 50) return "C";
                            return "F";
                          }
                          return "-";
                        })()}
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </Col>
            )}
          </Row>
          {/* Total and Percentage */}
          <Row className="m-0">
            {smarks?.length ? (
              <Col lg={12} className="p-0">
                <Table striped bordered hover>
                  <tbody>
                    <tr>
                      <th
                        style={{
                          textAlign: "right",
                          fontFamily: "arial",
                          fontVariantCaps: "all-small-caps",
                          fontSize: "14px",
                        }}
                        colSpan={"6"}
                      >
                        Total Marks
                      </th>
                      <td
                        style={{
                          textAlign: "right",
                          fontWeight: "bold",
                          fontVariantCaps: "all-small-caps",
                          fontSize: "16px",
                        }}
                      >
                        {totalMarks}
                      </td>
                    </tr>
                    <tr>
                      <th
                        style={{
                          textAlign: "right",
                          fontFamily: "arial",
                          fontVariantCaps: "all-small-caps",
                          fontSize: "14px",
                        }}
                        colSpan={"6"}
                      >
                        Obtained Marks
                      </th>
                      <td
                        style={{
                          textAlign: "right",
                          fontWeight: "bold",
                          fontVariantCaps: "all-small-caps",
                          fontSize: "16px",
                        }}
                      >
                        {obtainedMarks}
                      </td>
                    </tr>
                    <tr>
                      <th
                        style={{
                          textAlign: "right",
                          fontFamily: "arial",
                          fontVariantCaps: "all-small-caps",
                          fontSize: "14px",
                        }}
                        colSpan={"6"}
                      >
                        Percentage
                      </th>
                      <td
                        style={{
                          textAlign: "right",
                          fontWeight: "bold",
                          fontVariantCaps: "all-small-caps",
                          fontSize: "16px",
                        }}
                      >
                        {percentage.toFixed(2)}%
                      </td>
                    </tr>
                    <tr>
                      <th
                        style={{
                          textAlign: "right",
                          fontFamily: "arial",
                          fontVariantCaps: "all-small-caps",
                          fontSize: "14px",
                        }}
                        colSpan={"6"}
                      >
                        Division
                      </th>
                      <td
                        style={{
                          textAlign: "right",
                          fontWeight: "bold",
                          fontVariantCaps: "all-small-caps",
                          fontSize: "16px",
                        }}
                      >
                        {division}
                      </td>
                    </tr>
                    <tr>
                      <th
                        style={{
                          textAlign: "right",
                          fontFamily: "arial",
                          fontVariantCaps: "all-small-caps",
                          fontSize: "14px",
                        }}
                        colSpan={"6"}
                      >
                        Grade
                      </th>
                      <td
                        style={{
                          textAlign: "right",
                          fontWeight: "bold",
                          fontVariantCaps: "all-small-caps",
                          fontSize: "16px",
                        }}
                      >
                        {grade}
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </Col>
            ) : (
              <div className="mx-2 border-0 text-center m-3 ">
                No Records Found!!!
              </div>
            )}
          </Row>
        </>
      ) : (
        <div className="mx-2 border-0 text-center m-3 ">
          No Records Found!!!
        </div>
      )}
    </div>
  );
};

export default Result;

// import React, { useEffect, useState } from "react";
// import { Col, Row, Table } from "react-bootstrap";
// // import { useNavigate } from "react-router-dom";
// import schoolApi from "../../api/schoolApi";
// import PdfHeader from "../layout/common/PdfHeader";

// const Result = ({ classId, studentrecords, admissionId }) => {
//   const [studentData, setStudentData] = useState(null);
//   const [smarks, setMarks] = useState([]);
//   const [subjectArray, setSubjectArray] = useState([]);
//   const fetchStudentResult = async (classId, admissionId) => {
//     console.log("Fetching student result...");

//     try {
//       const result = await schoolApi.getStudentResult(classId, admissionId);
//       console.log("API Result:", result);

//       if (result.success) {
//         let studentRecord = {
//           studentname: result?.results?.student_result.studentname,
//           classname: result?.results?.student_result.classname,
//           dateofbirth: result?.results?.student_result.dateofbirth,
//           fathername: result?.results?.student_result.fathername,
//           mothername: result?.results?.student_result.mothername,
//           registration_no: result?.results?.student_result.registration_no,
//           parentPhone: result?.results?.student_result.parentPhone,
//         };
//         console.log("studentRecord-->>>", studentRecord);

//         setStudentData(studentRecord);
//         const subjects = [];

//         const resArr = result?.results?.student_result?.result;
//         console.log("resArr--->>>>", resArr);

//         result?.results?.student_result?.result?.map((rs) => {
//           if (!subjects.some((sub) => sub.label === rs.subject_id)) {
//             subjects.push({ label: rs.subject_id, value: rs.subject });
//           }
//         });

//         const groupedData = {};

//         for (const record of resArr) {
//           const { exam_type } = record;

//           if (!groupedData[exam_type]) {
//             groupedData[exam_type] = [];
//           }

//           groupedData[exam_type].push(record);
//         }
//         console.log("groupedData->", groupedData);

//         const redddsult = Object.keys(groupedData).map((key) => ({
//           exam_type: key,
//           exams: groupedData[key],
//         }));

//         console.log("res->", redddsult);
//         setSubjectArray(subjects);
//         setMarks(redddsult);
//       } else {
//         console.error(
//           "Failed to fetch results:",
//           result.message || "Unknown error"
//         );
//       }
//     } catch (error) {
//       console.error("Error fetching student results:", error);
//     }
//   };

//   console.log("smarks--->>>", smarks);
//   console.log("studentrecords ->", studentrecords);

//   useEffect(() => {
//     if (classId && admissionId) {
//       fetchStudentResult(classId, admissionId);
//     }
//   }, [classId, admissionId]);

//   // const calculateTotals = () => {
//   //   const totals = (smarks || []).reduce(
//   //     (acc, val) => {
//   //       acc.totalMarks += val.max_marks || 0;
//   //       acc.obtainedMarks += val.marks_obtained || 0;
//   //       return acc;
//   //     },
//   //     { totalMarks: 0, obtainedMarks: 0 }
//   //   );

//   //   console.log("TOTAL", totals);
//   //   const percentage =
//   //     totals.totalMarks > 0
//   //       ? (totals.obtainedMarks / totals.totalMarks) * 100
//   //       : 0;
//   //   return { ...totals, percentage };
//   // };

//   // const { totalMarks, obtainedMarks, percentage } = calculateTotals();
//   console.log("student-data--->>>", studentData);

//   return (
//     <div>
//       {studentData ? (
//         <>
//           <Row>
//             <PdfHeader />
//           </Row>
//           <Row className="m-0">
//             <Col lg={12} className="p-0">
//               <h4
//                 className="m-0"
//                 style={{
//                   fontWeight: "700",
//                   textAlign: "center",
//                   backgroundColor: "#de8989",
//                   padding: "5px 0px",
//                   textTransform: "uppercase",
//                   fontFamily: "sans-serif",
//                 }}
//               >
//                 Student's Profile
//               </h4>
//             </Col>

//             {studentData ? (
//               <section className="studentProfile my-3">
//                 <Col lg={4} className="p-0">
//                   <Row className="m-0">
//                     <Col lg={1}></Col>
//                     <Col lg={4}>Name</Col>
//                     <Col lg={1} className="p-0">
//                       :
//                     </Col>
//                     <Col lg={5} className="ps-0">
//                       {studentData.studentname}
//                     </Col>
//                   </Row>
//                   <Row className="m-0">
//                     <Col lg={1}></Col>
//                     <Col lg={4}>Mother's Name</Col>
//                     <Col lg={1} className="p-0">
//                       :
//                     </Col>
//                     <Col lg={5} className="ps-0">
//                       {studentData.mothername}
//                     </Col>
//                   </Row>
//                   <Row className="m-0">
//                     <Col lg={1}></Col>
//                     <Col lg={4}>Class Name</Col>
//                     <Col lg={1} className="p-0">
//                       :
//                     </Col>
//                     <Col lg={5} className="ps-0">
//                       {studentData.classname}
//                     </Col>
//                   </Row>
//                   <Row className="m-0">
//                     <Col lg={1}></Col>
//                     <Col lg={4}>DOB</Col>
//                     <Col lg={1} className="p-0">
//                       :
//                     </Col>
//                     <Col lg={5} className="ps-0">
//                       {studentData.dateofbirth}
//                     </Col>
//                   </Row>
//                 </Col>
//                 <Col lg={4} className="p-0">
//                   <Row className="m-0">
//                     <Col lg={1}></Col>
//                     <Col lg={4}>Parent's Name</Col>
//                     <Col lg={1} className="p-0">
//                       :
//                     </Col>
//                     <Col lg={5} className="ps-0">
//                       {studentData.fathername}
//                     </Col>
//                   </Row>
//                   <Row className="m-0">
//                     <Col lg={1}></Col>
//                     <Col lg={4}>Mobile</Col>
//                     <Col lg={1} className="p-0">
//                       :
//                     </Col>
//                     <Col lg={5} className="ps-0">
//                       {studentData.parentPhone}
//                     </Col>
//                   </Row>
//                   <Row className="m-0">
//                     <Col lg={1}></Col>
//                     <Col lg={4}>Registration no</Col>
//                     <Col lg={1} className="p-0">
//                       :
//                     </Col>
//                     <Col lg={5} className="ps-0">
//                       {studentData.registration_no}
//                     </Col>
//                   </Row>
//                 </Col>
//                 <Col lg={4} className="student-image">
//                   {studentrecords.file_parent_id && studentrecords.file_id ? (
//                     <img
//                       src={`/uploads/${studentrecords.file_parent_id}/${studentrecords.file_id}.${studentrecords.file_type}`}
//                       alt="profile"
//                       className="student-profile-picture"
//                     />
//                   ) : (
//                     <img
//                       src={`/uploads/dwps_ajmer/image_2024102442318.png`}
//                       alt="dummy_profile"
//                       height={100}
//                     />
//                   )}
//                   {/* <img src={"/uploads/dwps_ajmer/no-user.png"} alt="Student" /> */}
//                 </Col>
//               </section>
//             ) : (
//               "Please Wait"
//             )}
//           </Row>
//           <Row className="m-0">
//             <Col lg={12} className="p-0">
//               <h4
//                 className="m-0"
//                 style={{
//                   fontWeight: "700",
//                   textAlign: "center",
//                   backgroundColor: "#de8986",
//                   padding: "5px 0px",
//                   textTransform: "uppercase",
//                   fontFamily: "sans-serif",
//                 }}
//               >
//                 Academic Performance
//               </h4>
//             </Col>
//             {/* {smarks && smarks?.length && (
//               <Col lg={12} className="p-0">
//                 <Table striped bordered hover className="mx-1">
//                   <thead>
//                     <tr>
//                       <th style={{ textAlign: "center" }}>Exam Title</th>
//                       {[...new Set(smarks.map((item) => item.exam_type))].map(
//                         (examType) => {
//                           const subjectFound = smarks.find(
//                             (r) => r.exam_type === examType
//                           );

//                           return (
//                             <th
//                               colSpan="2"
//                               style={{ textAlign: "center" }}
//                               key={examType}
//                             >
//                               {examType}
//                             </th>
//                           );
//                         }
//                       )}
//                     </tr>
//                     <tr>
//                       <th style={{ textAlign: "center" }}>Subject</th>
//                       {[...new Set(smarks.map((item) => item.exam_type))].map(
//                         (examType) => (
//                           <>
//                             <th
//                               key={`${examType}-total`}
//                               style={{ textAlign: "center" }}
//                             >
//                               Total
//                             </th>
//                             <th
//                               key={`${examType}-obtained`}
//                               style={{ textAlign: "center" }}
//                             >
//                               Obtained
//                             </th>
//                           </>
//                         )
//                       )}
//                     </tr>
//                   </thead>

//                   <tbody>
//                     {subjectArray.map((subjectName) => (
//                       <tr key={subjectName.value}>
//                         <td>
//                           <strong>{subjectName.value}</strong>
//                         </td>
//                         {console.log("sds", subjectName)}
//                         {[...new Set(smarks.map((item) => item.exam_type))].map(
//                           (item) => {
//                             return (
//                               <>
//                                 <td align="right">
//                                   {smarks.find(
//                                     (item) => item.subject === subjectName.value
//                                   )?.max_marks || ""}
//                                 </td>
//                                 <td align="right">
//                                   {smarks.find(
//                                     (item) => item.subject === subjectName.value
//                                   )?.marks_obtained || ""}
//                                 </td>
//                               </>
//                             );
//                           }
//                         )}
//                       </tr>
//                     ))}
//                     <tr>
//                       <td>
//                         <strong>Total Marks Obtained</strong>
//                       </td>
//                       <td
//                         style={{
//                           textAlign: "right",
//                           fontFamily: "arial",
//                           fontVariantCaps: "all-small-caps",
//                           fontSize: "14px",
//                         }}
//                       >
//                         {totalMarks}
//                       </td>
//                       <td
//                         style={{
//                           textAlign: "right",
//                           fontFamily: "arial",
//                           fontVariantCaps: "all-small-caps",
//                           fontSize: "14px",
//                         }}
//                       >
//                         {" "}
//                         {obtainedMarks}
//                       </td>
//                       {/* {[...new Set(smarks.map((item) => item.exam_type))].map(
//                         (item) => {
//                           const totalMarksObtained = item.marks_obtained;
//                           const totalMaxMarks = item.max_marks;
//                           return (
//                             <>
//                               <td align="right" key={item.exam}>
//                                 <strong>{totalMaxMarks}</strong>
//                               </td>
//                               <td align="right" key={item.exam}>
//                                 <strong>{totalMarksObtained}</strong>
//                               </td>
//                             </>
//                           );
//                         }
//                       )}
//                     </tr>
//                   </tbody>
//                 </Table>
//               </Col>
//             )} */}
//             {smarks && (
//               <Col lg={12} className="p-0">
//                 <Table striped bordered hover className="mx-1">
//                   <thead>
//                     <tr>
//                       <th style={{ textAlign: "center" }}>Exam Title</th>
//                       {smarks?.map((group) => (
//                         <th
//                           colSpan="2"
//                           style={{ textAlign: "center" }}
//                           key={group.exam_type}
//                         >
//                           {group.exam_type}
//                         </th>
//                       ))}
//                     </tr>
//                     <tr>
//                       <th style={{ textAlign: "center" }}>Subject</th>
//                       {smarks?.map((group) =>
//                         group.exams.map((exam, index) => (
//                           <React.Fragment key={`${group.exam_type}-${index}`}>
//                             <th style={{ textAlign: "center" }}>Total</th>
//                             <th style={{ textAlign: "center" }}>Obtained</th>
//                           </React.Fragment>
//                         ))
//                       )}
//                     </tr>
//                   </thead>

//                   <tbody>
//                     {/* Iterate through unique subjects */}
//                     {subjectArray.map((subjectName) => (
//                       <tr key={subjectName.value}>
//                         <td>
//                           <strong>{subjectName.value}</strong>
//                         </td>
//                         {smarks?.map((group) => {
//                           const subjectExam = group.exams.find(
//                             (exam) => exam.subject === subjectName.value
//                           );

//                           return (
//                             <React.Fragment key={group.exam_type}>
//                               <td align="right">
//                                 {subjectExam?.max_marks || ""}
//                               </td>
//                               <td align="right">
//                                 {subjectExam?.marks_obtained || ""}
//                               </td>
//                             </React.Fragment>
//                           );
//                         })}
//                       </tr>
//                     ))}

//                     {/* Total Marks Row */}
//                     <tr>
//                       <td>
//                         <strong>Total Marks Obtained</strong>
//                       </td>
//                       {smarks?.map((group) => {
//                         const totalMarks = group.exams.reduce(
//                           (sum, exam) => sum + (exam.max_marks || 0),
//                           0
//                         );
//                         const obtainedMarks = group.exams.reduce(
//                           (sum, exam) => sum + (exam.marks_obtained || 0),
//                           0
//                         );

//                         return (
//                           <React.Fragment key={group.exam_type}>
//                             <td
//                               style={{
//                                 textAlign: "right",
//                                 fontFamily: "arial",
//                                 fontVariantCaps: "all-small-caps",
//                                 fontSize: "14px",
//                               }}
//                             >
//                               <strong>{totalMarks}</strong>
//                             </td>
//                             <td
//                               style={{
//                                 textAlign: "right",
//                                 fontFamily: "arial",
//                                 fontVariantCaps: "all-small-caps",
//                                 fontSize: "14px",
//                               }}
//                             >
//                               <strong>{obtainedMarks}</strong>
//                             </td>
//                           </React.Fragment>
//                         );
//                       })}
//                     </tr>
//                   </tbody>
//                 </Table>
//               </Col>
//             )}
//           </Row>
//           {/* Total and Percentage */}
//           <Row className="m-0">
//             {smarks?.length ? (
//               <Col lg={12} className="p-0">
//                 <Table striped bordered hover>
//                   <tbody>
//                     <tr>
//                       <th
//                         style={{
//                           textAlign: "right",
//                           fontFamily: "arial",
//                           fontVariantCaps: "all-small-caps",
//                           fontSize: "14px",
//                         }}
//                         colSpan={"6"}
//                       >
//                         Total Marks
//                       </th>
//                       <td
//                         style={{
//                           textAlign: "right",
//                           fontWeight: "bold",
//                           fontVariantCaps: "all-small-caps",
//                           fontSize: "16px",
//                         }}
//                       >
//                         {/* {totalMarks} */}
//                       </td>
//                     </tr>
//                     <tr>
//                       <th
//                         style={{
//                           textAlign: "right",
//                           fontFamily: "arial",
//                           fontVariantCaps: "all-small-caps",
//                           fontSize: "14px",
//                         }}
//                         colSpan={"6"}
//                       >
//                         Obtained Marks
//                       </th>
//                       <td
//                         style={{
//                           textAlign: "right",
//                           fontWeight: "bold",
//                           fontVariantCaps: "all-small-caps",
//                           fontSize: "16px",
//                         }}
//                       >
//                         {/* {obtainedMarks} */}
//                       </td>
//                     </tr>
//                     <tr>
//                       <th
//                         style={{
//                           textAlign: "right",
//                           fontFamily: "arial",
//                           fontVariantCaps: "all-small-caps",
//                           fontSize: "14px",
//                         }}
//                         colSpan={"6"}
//                       >
//                         Percentage
//                       </th>
//                       <td
//                         style={{
//                           textAlign: "right",
//                           fontWeight: "bold",
//                           fontVariantCaps: "all-small-caps",
//                           fontSize: "16px",
//                         }}
//                       >
//                         {/* {percentage.toFixed(2)}% */}
//                       </td>
//                     </tr>
//                     <tr>
//                       <th
//                         style={{
//                           textAlign: "right",
//                           fontFamily: "arial",
//                           fontVariantCaps: "all-small-caps",
//                           fontSize: "14px",
//                         }}
//                         colSpan={"6"}
//                       >
//                         Division
//                       </th>
//                       <td
//                         style={{
//                           textAlign: "right",
//                           fontWeight: "bold",
//                           fontVariantCaps: "all-small-caps",
//                           fontSize: "16px",
//                         }}
//                       >
//                         {/* {percentage > 60
//                           ? "First Division"
//                           : percentage > 50 && percentage < 60
//                           ? "Second Division"
//                           : percentage > 40 && percentage < 50
//                           ? "Third Divison"
//                           : "Fail"} */}
//                       </td>
//                     </tr>
//                   </tbody>
//                 </Table>
//               </Col>
//             ) : (
//               <div className="mx-2 border-0 text-center m-3 ">
//                 No Records Found!!!
//               </div>
//             )}
//           </Row>
//         </>
//       ) : (
//         <div className="mx-2 border-0 text-center m-3 ">
//           No Records Found!!!
//         </div>
//       )}
//     </div>
//   );
// };

// export default Result;
