import React, { useEffect, useState } from "react";
import { Button, Col, Modal, Row, Form } from "react-bootstrap";
import schoolApi from "../../api/schoolApi";
import { toast } from "react-toastify";
import { FilesCreate } from "../file";
import "react-toastify/dist/ReactToastify.css";
import "react-toastify/dist/ReactToastify.css";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { parseISO } from "date-fns";

const CreateAssignmentModal = (props) => {
  console.log("props assign data", props);

  const [classOptions, setClassOptions] = useState([]);
  const [subjectOptions, setSubjectOptions] = useState([]);
  const [sessionOptions, setSessionOptions] = useState([]);
  const [modal, setModal] = useState(false);
  const [assignmentData, setAssignmentData] = useState({
    class_id: "",
    subject_id: "",
    due_date: "",
    assign_date: "",
    status: "",
    title: "",
    description: "",
    session_id: "",
    document: null,
  });
  const [files, setFiles] = useState([]);
  const [fileDescription, setFileDescription] = useState("");
  const [fileName, setFileName] = useState("");
  const MIMEType = new Map([
    ["text/csv", "csv"],
    ["application/msword", "doc"],
    [
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
      "docx",
    ],
    ["image/gif", "gif"],
    ["text/html", "html"],
    ["image/jpeg", "jpeg"],
    ["image/jpg", "jpg"],
    ["application/json", "json"],
    ["audio/mpeg", "mp3"],
    ["video/mp4", "mp4"],
    ["image/png", "png"],
    ["application/pdf", "pdf"],
    ["application/vnd.ms-powerpoint", "ppt"],
    [
      "application/vnd.openxmlformats-officedocument.presentationml.presentation",
      "pptx",
    ],
    ["image/svg+xml", "svg"],
    ["text/plain", "txt"],
    ["application/vnd.ms-excel", "xls"],
    [
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      "xlsx",
    ],
    ["text/xm", "xml"],
    ["application/xml", "xml"],
    ["application/atom+xml", "xml"],
    ["application/zip", "zip"],
  ]);
  const [assignmentStatus, setAssignmentStatus] = useState([
    { value: "assigned", label: "Assigned" },
    { value: "inprogress", label: "In Progress" },
    { value: "completed", label: "Completed" },
    // { value: "onhold", label: "On Hold" },
    // { value: "postponed", label: "Postponed" },
    { value: "cancelled", label: "Cancelled" },
  ]);
  /*------ Added by mohd. sarfraj | 05-nov-2024 ------*/ 
  const [assignmentDates , setAssignmentDates] = useState({
    due_date: null,
    assign_date: null,
  });

  //----- STARAT Updated by mohd. sarfraj | 25-sept-2024. -----
  useEffect(() => {
    async function fetchAllClasses() {
      try {
        const classes = await schoolApi.getActiveClassRecords();
        console.log("classes=======> ", classes);
        if (classes.success) {
          const classOptions = classes?.result?.map((cls) => ({
            value: cls.id,
            label: cls.classname,
          }));
          setClassOptions(classOptions);
        } else {
          setClassOptions([]);
        }
      } catch (error) {
        console.error("Error fetching classes:", error);
      }
    }
    fetchAllClasses();
  }, []);

  useEffect(() => {
    async function fetchAllSubjects() {
      try {
        const subjects = await schoolApi.fetchSubject();
        if (subjects.success) {
          const subjectOptions = subjects?.result?.map((sub) => ({
            value: sub.id,
            label: sub.name,
          }));
          setSubjectOptions(subjectOptions);
        } else {
          setSubjectOptions([]);
        }
      } catch (error) {
        console.error("Error fetching subjects:", error);
      }
    }
    fetchAllSubjects();
  }, []);
  // ----- ./ END by mohd. sarfraj -----

  useEffect(() => {
    if (props.assignData) {
      let [day, month, year] = props.assignData.due_date.split("-");
      let dueDate = new Date(year, month - 1, day);
      [day, month, year] = props.assignData.assign_date.split("-");
      const assignDate = new Date(year, month - 1, day);
      
      setAssignmentDates((prevState) => ({
        ...prevState,
        due_date: dueDate,
        assign_date: assignDate,
      }));

      setAssignmentData({
        class_id: props.assignData.class_id,
        subject_id: props.assignData.subject_id,
        due_date: dueDate,
        assign_date: assignDate,
        status: props.assignData.status,
        title: props.assignData.title,
        description: props.assignData.description,
        session_id: props.assignData.session_id,
        document: props.assignData.file_id || null,
      });
      setFileName(props.assignData.file_title || "");
    } else {
      setAssignmentData({
        class_id: "",
        subject_id: "",
        due_date: "",
        assign_date: "",
        status: "",
        title: "",
        description: "",
        session_id: "",
        document: null,
      });
      setFiles("");
      setFileName("");
      setAssignmentDates((prevState) => ({
        ...prevState,
        due_date: "",
        assign_date: "",
      }));
    }
  }, [props.assignData]);

  useEffect(() => {
    async function fetchAllSessions() {
      try {
        const sessions = await schoolApi.fetchSessions();
        const sessionOptions = sessions.map((sess) => ({
          value: sess.id,
          label: sess.year,
        }));
        setSessionOptions(sessionOptions);
      } catch (error) {
        console.error("Error fetching subjects:", error);
      }
    }
    fetchAllSessions();
  }, []);

  const handleChange = (e) => {
    // console.log("e.target======>", e.target.value);
    setAssignmentData({ ...assignmentData, [e.target.name]: e.target.value });
  };
  console.log("setAssignmentData ======>", assignmentData);

  const handleDocumentChange = (event) => {
    setFiles(event.target.files);
    setFileName(event.target.files[0]?.name || "");
  };

  /* =====code added by muskan khan 6 sept 2024 friaday =====*/
  const handleDateChange = (date, name) => {
    if (date) {
      const day = String(date.getDate()).padStart(2, "0"); 
      const month = String(date.getMonth() + 1).padStart(2, "0"); 
      const year = date.getFullYear(); 
      const dateFormat = `${year}-${month}-${day}`;

      setAssignmentDates((prevState) => ({
        ...prevState,
        [name]: date,
      }));

      setAssignmentData({ ...assignmentData, [name]: dateFormat });
    }
  };

  const handleSubmit = async () => {
    if ( assignmentData.class_id && assignmentData.title &&
      assignmentData.subject_id && assignmentData.status &&
      assignmentData.due_date && assignmentData.assign_date) {
      if (props.assignData) {
        try {
          const result = await schoolApi.updateAssignment(props.assignData.id, assignmentData);
          if (!result.success) {
            return toast.error(result.message);
          }

          const token = sessionStorage.getItem("token");
          let current = new Date();
          var formData = new FormData();

          for (let i = 0; i < files.length; i++) {
            console.log(`files--->>>`, files[i]);

            formData.append(`files${i}`, files[i]);
            formData.append(`description`, fileDescription);
          }

          console.log("formData=======>>>>>", formData);
          console.log("props.assignData=======>>>>>", props.assignData);
          console.log(
            "assignmentData.document=======>>>>>",
            assignmentData.document
          );

          try {
            // If no document exists, create a new file
            if (assignmentData.document === null) {
              /*---- First Delete Exisitng/Old file with same parent-id ----*/
              if (props?.assignData?.file_id) {
                const deleteExistFile = await schoolApi.deleteFile(props?.assignData?.file_id);
                if (!deleteExistFile.success) {
                  toast.error(deleteExistFile.message);
                }
                console.log("deleteExistFile--->>", deleteExistFile);
              }

              if (props.assignData.id) {
                const fileResult = await schoolApi.createFile(props.assignData.id, formData);
                console.log("fileResult--->>>>", fileResult);

                if (fileResult.success) {
                  toast.success("Record update successfully!");
                } else {
                  toast.success("Record update without file successfully!");
                }

                if (fileResult.success) {
                  props.handleCloseModal();
                  props.fetchAllAssignments();
                  resetFormState();
                } else {
                  props.handleCloseModal();
                  props.fetchAllAssignments();
                  resetFormState();
                }
              }
            } else {
              // If a document exists, update the existing file
              const exitFile = await schoolApi.fetchFileById(assignmentData.document);
              console.log("exitFile--->>", exitFile);

              if (exitFile.success) {
                /*---- First Delete Exisitng/Old file with same parent-id ----*/
                if (props?.assignData?.file_id && files.length > 0) {
                  const deleteExistFile = await schoolApi.deleteFile(props?.assignData?.file_id);
                  console.log("deleteExistFile--->>", deleteExistFile);
                  if (!deleteExistFile.success) {
                    toast.error(deleteExistFile.message);
                  }
                }

                let fileResult;
                if (files.length > 0) {
                  fileResult = await schoolApi.createFile(props.assignData.id, formData);
                  console.log("fileResult-update-in-else-->>>", fileResult);
                }

                // const fileResult = await schoolApi.saveFiles(assignmentData.document, formData);
                // console.log('fileResult-else--->>>>' , fileResult);

                if (!fileResult?.success) {
                  toast.success("Record updated without file successfully!");
                }

                if (fileResult?.success) {
                  toast.success("Record update successfully..!!");
                  props.handleCloseModal();
                  props.fetchAllAssignments();
                  resetFormState();
                } else {
                  props.handleCloseModal();
                  props.fetchAllAssignments();
                  resetFormState();
                }
              }
            }
          } catch (error) {
            console.error("Error uploading file:", error);
          }
        } catch (error) {
          console.error("Error updating assignment:", error);
          toast.error(error?.response?.data?.message);
        }
      } else {
        try {
          const result = await schoolApi.createAssignment(assignmentData);
          console.log("assignment-creation-result--->>>>", result);

          if (result.message === "This record already exists") {
            return toast.error(result.message);
          }

          const token = sessionStorage.getItem("token");
          let current = new Date();
          var formData = new FormData();
          for (let i = 0; i < files.length; i++) {
            console.log(`files--->>>`, files[i]);

            formData.append(`files${i}`, files[i]);
            formData.append(`description`, fileDescription);
          }
          console.log("formData=======>>>>>", formData);

          try {
            const fileResult = await schoolApi.createFile(
              result?.result[0]?.id,
              formData
            );
            console.log("fileResult--->>>", fileResult);

            if (fileResult.success && result.success) {
              toast.success("Record created with file successfully!");
            } else {
              toast.success("Record created without successfully!");
            }

            if (fileResult) {
              // if (fileResult) {
              props.handleCloseModal();
              props.fetchAllAssignments();
              resetFormState();
              // }
            }
          } catch (error) {
            // Handle error here (e.g., show an error message)
            console.error("Error uploading file:", error);
          }
        } catch (error) {
          console.error("Error creating assignment:", error);
        }
      }
    } else {
      return toast.error("Fill all required fields.");
    }
  };

  // Comman method for both create & update assignment.
  const resetFormState = () => {
    setAssignmentData({
      class_id: "",
      subject_id: "",
      due_date: "",
      assign_date: "",
      status: "",
      title: "",
      description: "",
      session_id: "",
      document: null,
    });
    setFiles([]);
    setFileName("");
    assignmentDates((prevState) => ({
      ...prevState,
      due_date: "",
      assign_date: "",
    }));
  };

  return (
    <Modal
      show={props.modalShow}
      centered
      // size="lg"
      backdrop="static"
      aria-labelledby="contained-modal-title-vcenter"
      onHide={() => {
        props.handleCloseModal();
        setAssignmentData({
          class_id: props.assignData.class_id ? props.assignData.class_id : "",
          subject_id: props.assignData.subject_id
            ? props.assignData.subject_id
            : "",
          due_date: props.assignData.due_date ? props.assignData.due_date : "",
          assign_date: props.assignData.assign_date
            ? props.assignData.assign_date
            : "",
          status: props.assignData.status ? props.assignData.status : "",
          title: props.assignData.title ? props.assignData.title : "",
          description: props.assignData.description
            ? props.assignData.description
            : "",
          session_id: props.assignData.session_id
            ? props.assignData.session_id
            : "",
          document: null,
        });
        setFileName(props.assignData.file_title || "");
      }}
    >
      <Modal.Header
        closeButton
        style={{
          maxHeight: "",
        }}
      >
        <Modal.Title>
          {props.assignData ? "Update Assignment" : "Create Assignment"}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form className="mt-3">
          <Row>
            <Col lg={6}>
              <Form.Group className="mx-3">
                <Form.Label className="form-view-label">Class</Form.Label>
                <Form.Select
                  name="class_id"
                  onChange={handleChange}
                  value={assignmentData.class_id}
                  required
                >
                  <option key="default" value="">-- Select Class --</option>
                  {classOptions &&
                    classOptions.map((res) => (
                      <option key={res.value} value={res.value}>
                        {res.label}
                      </option>
                    ))}
                </Form.Select>
              </Form.Group>
            </Col>
            <Col lg={6}>
              <Form.Group className="mx-3">
                <Form.Label className="form-view-label">Subject</Form.Label>
                <Form.Select
                  name="subject_id"
                  onChange={handleChange}
                  value={assignmentData.subject_id}
                  required
                >
                  <option key="default" value="">-- Select Subject --</option>
                  {subjectOptions &&
                    subjectOptions.map((sub) => (
                      <option key={sub.value} value={sub.value}>
                        {sub.label}
                      </option>
                    ))}
                </Form.Select>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col lg={6} className="mt-3">
              <Form.Group className="mx-3">
                <Form.Label className="form-view-label">Title</Form.Label>
                <Form.Control
                  type="text"
                  name="title"
                  required
                  placeholder="Enter title"
                  value={assignmentData.title}
                  onChange={handleChange}
                />
              </Form.Group>
            </Col>
            <Col lg={6} className="mt-3">
              <Form.Group className="mx-3">
                <Form.Label className="form-view-label">Status</Form.Label>
                <Form.Select
                  name="status"
                  onChange={handleChange}
                  value={assignmentData.status}
                  required
                >
                  <option value="">-- Select Status --</option>
                  {assignmentStatus &&
                    assignmentStatus.map((res) => (
                      <option key={res.label} value={res.value}>
                        {res.label}
                      </option>
                    ))}
                </Form.Select>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col lg={6} className="mt-3">
              <Form.Group className="mx-3">
                <Form.Label className="form-view-label">Assign Date</Form.Label>
                <DatePicker
                  required
                  selected={assignmentDates?.assign_date}
                  onChange={(date) => handleDateChange(date, "assign_date")}
                  dateFormat="dd-MM-yyyy"
                  showMonthDropdown
                  showYearDropdown
                  dropdownMode="select"
                  placeholderText="Select a date"
                  className="form-control"
                  style={{ width: "300px" }}
                  onKeyDown={(e) => e.preventDefault()}
                  highlightDates={[new Date()]}
                />
              </Form.Group>
            </Col>
            <Col lg={6} className="mt-3">
              <Form.Group className="mx-3">
                <Form.Label className="form-view-label">Due Date</Form.Label>
                <DatePicker
                  required
                  selected={assignmentDates?.due_date}
                  onChange={(date) => handleDateChange(date, "due_date")}
                  dateFormat="dd-MM-yyyy"
                  showMonthDropdown
                  showYearDropdown
                  dropdownMode="select"
                  placeholderText="Select a date"
                  className="form-control"
                  style={{ width: "300px" }}
                  onKeyDown={(e) => e.preventDefault()}
                  highlightDates={[new Date()]}
                />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col lg={6} className="mt-3">
              <Form.Group className="mx-3">
                <Form.Label className="form-view-label">Session</Form.Label>
                <Form.Select
                  name="session_id"
                  onChange={handleChange}
                  value={assignmentData.session_id}
                  required
                >
                  <option value="">-- Select Session --</option>
                  {sessionOptions &&
                    sessionOptions.map((res) => (
                      <option key={res.label} value={res.value}>
                        {res.label}
                      </option>
                    ))}
                </Form.Select>
              </Form.Group>
            </Col>

            <Col lg={6} className="mt-3">
              <Form.Group className="mx-3">
                <Form.Label>Document:</Form.Label>

                <Form.Control
                  type="file"
                  name="document"
                  accept=".pdf,.doc,.docx,.ppt,.pptx,.xls,.xlsx,.csv,.txt,.jpg,.jpeg,.png,.gif"
                  onChange={handleDocumentChange}
                />

                {fileName && (
                  <Form.Text className="text-muted">{fileName}</Form.Text>
                )}
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col lg={12} className="mt-3">
              <Form.Group className="mx-3">
                <Form.Label className="form-view-label">Description</Form.Label>
                <Form.Control
                  as="textarea"
                  name="description"
                  placeholder="Enter description"
                  value={assignmentData.description}
                  onChange={handleChange}
                />
              </Form.Group>
            </Col>
          </Row>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="success" onClick={handleSubmit}>
          {props.btnName}
        </Button>
        <Button
          variant="light"
          onClick={() => {
            props.handleCloseModal();
            setAssignmentData({
              class_id: props.assignData.class_id
                ? props.assignData.class_id
                : "",
              subject_id: props.assignData.subject_id
                ? props.assignData.subject_id
                : "",
              due_date: props.assignData.due_date
                ? props.assignData.due_date
                : "",
              assign_date: props.assignData.assign_date
                ? props.assignData.assign_date
                : "",
              status: props.assignData.status ? props.assignData.status : "",
              title: props.assignData.title ? props.assignData.title : "",
              description: props.assignData.description
                ? props.assignData.description
                : "",
              session_id: props.assignData.session_id
                ? props.assignData.session_id
                : "",
              document: null,
            });
          }}
        >
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
export default CreateAssignmentModal;