/**
 * @author: Abdul Pathan
 */
import React, { useState, useEffect } from "react";
import { Button, Col, Row, Table } from "react-bootstrap";
import { ShimmerTable } from "react-shimmer-effects";
import InfoPill from "../InfoPill";
import { Link } from "react-router-dom";
import schoolApi from "../../api/schoolApi";
import { useNavigate } from "react-router-dom";
import {
  DatatableWrapper,
  Filter,
  Pagination,
  PaginationOptions,
  TableBody,
  TableHeader,
} from "react-bs-datatable";
// ---------------code pawan----------------
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Helmet } from "react-helmet";

const AssignSubjectClassList = (props) => {
  const navigate = useNavigate();
  const [body, setBody] = useState();
  const [deleteId, setDeleteId] = useState();
  const [deleteModal, setDeleteModal] = useState(false);
  useEffect(() => {
    async function init() {
      try {
        const result = await schoolApi.getAssignSubjectClassRecords(); //fetch Records
        console.log("####getAssignSubjectClassRecords", result);

        if (result.success) {
          console.log("####getAssignSubjectClassRecords", result.records);

          // Group data by classid START
          let data = result.records;
          const groupedData = data.reduce((groups, item) => {
            const key = `${item.class_id}-${item.classname}`;
            if (!groups[key]) {
              groups[key] = {
                id: item.id,
                classId: item.class_id,
                classname: item.classname,
                subjectname: [],
              };
            }
            groups[key].subjectname.push(item.subjectname);
            return groups;
          }, {});
          const resultArray = Object.values(groupedData);
          setBody(resultArray);
          console.log("resultArray==>", resultArray);
        } else {
          console.log("result ->", result);

          toast.error(result.message);
          console.log("hear");

          setBody([]);
        }
      } catch (error) {
        console.log("error ->", error);
        if (error.response.data.message && error.response.data)
          toast.error(error.response.data.message);
        else toast.error("Something went wrong");
      }
    }
    init();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const labels = {
    beforeSelect: " ",
  };

  //Table headers
  const header = [
    {
      title: "Class Name",
      prop: "classname",
      isFilterable: true,
      cell: (row) => row.classname,
    },
    {
      title: "Subject Name",
      prop: "subjectname",
      isFilterable: true,
      cell: (row) => row.subjectname.join(" , "),
    },
    {
      title: "Actions",
      prop: "className",
      cell: (row) => (
        <div>
          <Button
            className="btn-sm edit-button"
            variant=""
            onClick={() => updateAssignSubjectClass(row)}
          >
            <i className="fa-regular fa-pen-to-square"> </i>
          </Button>
        </div>
      ),
    },
  ];
  //update Assign Subject Class
  const updateAssignSubjectClass = (row) => {
    navigate(`/assignsubjectclass/e`, { state: row });
  };

  //add Assign Subject Class
  const addAssignSubjectClass = () => {
    navigate(`/assignsubjectclass/e`);
  };

  return (
    <>
      <Helmet>
        <title>{props?.tabName}</title>
      </Helmet>

      <ToastContainer
        position="top-right"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <Row className="g-0">
      <Col lg={5} className="mx-4" style={{ marginTop: "7px" }}>
          <Link className="nav-link" to="/">
            Home <i className="fa-solid fa-chevron-right"style={{ marginLeft: "1px", marginRight: "1px" }}></i> Assign Subject Class
          </Link>
        </Col>
        <Col lg={12} className="px-lg-4">
          {body ? (
            <DatatableWrapper
              body={body}
              headers={header}
              paginationOptionsProps={{
                initialState: {
                  rowsPerPage: 15,
                  options: [5, 10, 15, 20],
                },
              }}
            >
              <Row className="mb-4">
                <Col
                  xs={12}
                  lg={3}
                  className="d-flex flex-col justify-content-end align-items-end"
                >
                  <Filter />
                </Col>
                <Col
                  xs={12}
                  sm={6}
                  lg={5}
                  className="d-flex flex-col justify-content-start align-items-start"
                >
                  <PaginationOptions labels={labels} />
                  <div style={{ marginTop: "5px" }}>
                    <InfoPill
                      left="Total Assign Subject Class"
                      right={body?.length}
                    />
                  </div>
                </Col>
                <Col
                  xs={12}
                  sm={6}
                  lg={4}
                  className="d-flex flex-col justify-content-end align-items-end"
                >
                  <Button
                    className="btn-light add-new-button"
                    variant=""
                    onClick={() => addAssignSubjectClass(true)}
                  >
                    Assign Class Subject
                  </Button>
                </Col>
              </Row>
              <Table striped className="data-table custom-table-subject-list">
                <TableHeader />
                <TableBody />
              </Table>
              <Pagination />
            </DatatableWrapper>
          ) : (
            <ShimmerTable row={10} col={8} />
          )}
        </Col>
      </Row>
    </>
  );
};

export default AssignSubjectClassList;
