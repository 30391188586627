import React, { useState, useEffect } from "react";
import schoolApi from "../../api/schoolApi";
import { Col, Row, Button } from "react-bootstrap";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import Card from "react-bootstrap/Card";
import Studentadmission from "../AdmissionRelatedList/Studentadmission";
import Parents from "../AdmissionRelatedList/Parents";
import Sibling from "../AdmissionRelatedList/Sibling";
import Attendance from "../AdmissionRelatedList/Attendance";
import Fees from "../AdmissionRelatedList/Fees";
import Transport from "../AdmissionRelatedList/Transport";
import Result from "../AdmissionRelatedList/Result";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import moment from "moment";

const StudentAddRelatedList = (props) => {
  console.log("props@@=>", props);
  const [selectedTab, setSelectedTab] = useState("Details");
  const [tabs, setTabs] = useState([
    "Details",
    "Parents",
    "Siblings",
    "Class registration", //Changed by Pooja Vaishnav(01-07-2024)
    "Attendance",
    "Fees",
    "Transport",
    "Result",
  ]);

  const [student, setStudent] = useState(true);
  // const [studentDetail, setStudentDetail] = useState([]); // comented by mohd. sarfraj
  const [studentDetail, setStudentDetail] = useState(props.studentaddmission);

  const handleMainTab = async (e, tabname) => {
    try {
      if (tabname === "Details") {
        setStudent(true);
        setSelectedTab(tabname);
      }
      if (tabname === "Class registration") {
        //Changed by Pooja Vaishnav(01-07-2024)
        setSelectedTab(tabname);
      }
      if (tabname === "Parents") {
        setSelectedTab(tabname);
      }
      if (tabname === "Siblings") {
        setSelectedTab(tabname);
      }
      if (tabname === "Attendance") {
        setSelectedTab(tabname);
      }
      if (tabname === "Fees") {
        setSelectedTab(tabname);
      }
      if (tabname === "Transport") {
        setSelectedTab(tabname);
      }
      if (tabname === "Result") {
        setSelectedTab(tabname);
      }
    } catch (error) {
      console.log("error=>", error);
    }
  };

  console.log("studentDetail-in-Details-->>>>", studentDetail);

  return (
    <>
      <Card>
        <ToastContainer
          position="top-right"
          autoClose={2000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
        />
        <TabContext>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <TabList aria-label="lab API tabs example">
              {tabs.map((tab, index) => (
                <Tab
                  label={tab}
                  key={index}
                  onClick={(e) => handleMainTab(e, tab)}
                  style={{ color: "black" }}
                  selected={selectedTab === tab}
                  sx={{
                    color: selectedTab === tab ? "blue" : "black",
                    borderBottom:
                      selectedTab === tab ? "1px solid blue" : "none",
                  }}
                ></Tab>
              ))}
            </TabList>
            {console.log("selectedtab=>", selectedTab)}
            {console.log("props.studentadmission=>", props.studentadmission)}
            {selectedTab === "Parents" && (
              <div>
                {console.log("Parents")}
                <Parents parentid={props.studentaddmission.parentid} />
              </div>
            )}
            {selectedTab === "Siblings" && (
              <div>
                {console.log("Siblings", props.studentaddmission)}
                <Sibling
                  parentid={
                    props.studentaddmission.student_addmission_id
                      ? props.studentaddmission.parentid
                      : ""
                  }
                  studentid={props.studentaddmission.id}
                />
              </div>
            )}

            {selectedTab === "Class registration" && ( //Changed by Pooja Vaishnav(01-07-2024)
              <div>
                {console.log("first")}
                <Studentadmission
                  student_id={
                    props.studentaddmission.student_id
                      ? props.studentaddmission.student_id
                      : props.studentaddmission.id
                  }
                />
              </div>
            )}
            {selectedTab === "Attendance" && (
              <div>
                <Attendance
                  // studentId={props.studentaddmission.id}
                  studentId={props.studentaddmission.id}
                  sessionId={props.studentaddmission.session_id}
                />
              </div>
            )}
            {selectedTab === "Fees" && (
              <div>
                {console.log("fees tab clicked-->", props)}
                <Fees studenId={props.studentaddmission} />
              </div>
            )}

            {selectedTab === "Transport" && (
              <div>
                {console.log("first")}
                <Transport student={props?.studentaddmission} />
              </div>
            )}
            {selectedTab === "Result" && (
              <div>
                <Result
                  classId={props.studentaddmission.classid}
                  studentrecords={props.studentaddmission}
                  admissionId={props.studentaddmission.student_addmission_id}
                />
              </div>
            )}
          </Box>
          {console.log("studentDetail@@=>", studentDetail)}

          {selectedTab === "Details" && student && (
            <>
              <Row className="mt-2">
                <Col lg={1}></Col>
                <Col lg={3} className="d-flex justify-content-center">
                  {studentDetail.file_parent_id && studentDetail.file_id ? (
                    <img
                      src={`/uploads/${studentDetail.file_parent_id}/${studentDetail.file_id}.${studentDetail.file_type}`}
                      alt="profile"
                      className="student-profile-picture"
                    />
                  ) : (
                    <img
                      src={`/uploads/dwps_ajmer/image_2024102442318.png`}
                      alt="dummy_profile"
                      height={100}
                    />
                  )}
                </Col>
                <Col lg={1}></Col>
                <Col lg={7}>
                  <Row className="g-0">
                    <Col lg={5}>
                      <label>Serial No</label>
                      <span>{studentDetail.srno || "\u00A0"}</span>
                    </Col>
                    <Col lg={1}></Col>
                    <Col lg={5} className="ms-5">
                      <label>Student Name</label>
                      <span>{studentDetail.studentname || "\u00A0"}</span>
                    </Col>

                    <Col lg={5}>
                      <label>Religion</label>
                      <span>{studentDetail.religion || "\u00A0"}</span>
                    </Col>
                    <Col lg={1}></Col>
                    <Col lg={5} className="ms-5">
                      <label>Date of Birth</label>
                      <span>
                        {moment(studentDetail.dateofbirth).format(
                          "DD-MM-YYYY"
                        ) || "\u00A0"}
                      </span>
                    </Col>

                    <Col lg={5}>
                      <label>Gender</label>
                      <span>{studentDetail.gender || "\u00A0"}</span>
                    </Col>
                    <Col lg={1}></Col>
                    <Col lg={5} className="ms-5">
                      <label>Email</label>
                      <span>{studentDetail.email || "\u00A0"}</span>
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Row className="mb-2">
                <Col lg={12}></Col>
                {/* <Row>
                  <Col lg={1}></Col>
                  <Col lg={3}>
                    <label>Serial No</label>
                    <span>{studentDetail.srno || "\u00A0"}</span>
                  </Col>
                  <Col lg={1}></Col>
                  <Col lg={3}>
                    <label>Student Name</label>
                    <span>{studentDetail.studentname || "\u00A0"}</span>
                  </Col>
                  <Col lg={1}></Col>
                  <Col lg={3}>
                    <label>Religion</label>
                    <span>{studentDetail.religion || "\u00A0"}</span>
                  </Col>
                </Row>
                <Row>
                  <Col lg={1}></Col>
                  <Col lg={3}>
                    <label>Date of Birth</label>
                    <span>
                      {moment(studentDetail.dateofbirth).format("DD-MM-YYYY") ||
                        "\u00A0"}
                    </span>
                  </Col>
                  <Col lg={1}></Col>
                  <Col lg={3}>
                    <label>Gender</label>
                    <span>{studentDetail.gender || "\u00A0"}</span>
                  </Col>
                  <Col lg={1}></Col>
                  <Col lg={3}>
                    <label>Email</label>
                    <span>{studentDetail.email || "\u00A0"}</span>
                  </Col>
                </Row> */}
                <Row>
                  <Col lg={1}></Col>
                  <Col lg={3}>
                    <label>Aadhar Number</label>
                    <span>{studentDetail.adharnumber || "\u00A0"}</span>
                  </Col>
                  <Col lg={1}></Col>
                  <Col lg={3}>
                    <label>Phone</label>
                    <span>{studentDetail.phone || "\u00A0"}</span>
                  </Col>
                  <Col lg={1}></Col>
                  <Col lg={3}>
                    <label>Pincode</label>
                    <span>{studentDetail.pincode || "\u00A0"}</span>
                  </Col>
                </Row>
                <Row>
                  <Col lg={1}></Col>
                  <Col lg={3}>
                    <label>Street</label>
                    <span>{studentDetail.street || "\u00A0"}</span>
                  </Col>
                  <Col lg={1}></Col>
                  <Col lg={3}>
                    <label>City</label>
                    <span>{studentDetail.city || "\u00A0"}</span>
                  </Col>
                  <Col lg={1}></Col>
                  <Col lg={3}>
                    <label>State</label>
                    <span>{studentDetail.state || "\u00A0"}</span>
                  </Col>
                </Row>
                <Row>
                  <Col lg={1}></Col>
                  <Col lg={3}>
                    <label>Country</label>
                    <span>{studentDetail.country || "\u00A0"}</span>
                  </Col>
                  <Col lg={1}></Col>
                  <Col lg={3}>
                    <label>Permanent Street</label>
                    <span>{studentDetail.permanentstreet || "\u00A0"}</span>
                  </Col>
                  <Col lg={1}></Col>
                  <Col lg={3}>
                    <label>Permanent City</label>
                    <span>{studentDetail.city || "\u00A0"}</span>
                  </Col>
                </Row>
                <Row>
                  <Col lg={1}></Col>
                  <Col lg={3}>
                    <label>Permanent Postal Code</label>
                    <span>{studentDetail.permanentpostalcode || "\u00A0"}</span>
                  </Col>
                  <Col lg={1}></Col>
                  <Col lg={3}>
                    <label>Permanent State</label>
                    <span>{studentDetail.permanentstate || "\u00A0"}</span>
                  </Col>
                  <Col lg={1}></Col>
                  <Col lg={3}>
                    <label>Permanent Country</label>
                    <span>{studentDetail.permanentcountry || "\u00A0"}</span>
                  </Col>
                </Row>
                <Row>
                  <Col lg={1}></Col>
                  <Col lg={3}>
                    <label>Category</label>
                    <span>{studentDetail.category || "\u00A0"}</span>
                  </Col>
                  <Col lg={1}></Col>
                  <Col lg={3}>
                    <label>Class Name</label>
                    <span>{studentDetail.class_name || "\u00A0"}</span>
                  </Col>
                  <Col lg={1}></Col>
                  <Col lg={3}>
                    <label>Section Name</label>
                    <span>{studentDetail.section_name || "\u00A0"}</span>
                  </Col>
                  <Col lg={1}></Col>
                  <Col lg={3}>
                    <label>Parent Name</label>
                    <span>{studentDetail.parentname || "\u00A0"}</span>
                  </Col>
                  <Col lg={1}></Col>
                  <Col lg={3}>
                    <label>Description</label>
                    <span>{studentDetail?.description || "\u00A0"}</span>
                  </Col>
                </Row>
              </Row>
            </>
          )}
        </TabContext>
      </Card>
    </>
  );
};
export default StudentAddRelatedList;
