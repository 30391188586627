import React, { useEffect, useState } from 'react';
import { Button, Modal } from "react-bootstrap";
import schoolApi from '../../api/schoolApi';
import InstallmentTable from '../fee/InstallmentTable';

const PreviewFeeInstallment = ({ row, showModal, setShowModal }) => {
  console.log('row data on receipt-->',row);
  const [studentRecord, setStudentRecord] = useState(row ? row : {});
  const [studentInstallment, setStudentInstallment] = useState();

  useEffect(() => {
    const initClass = async () => {
      let frequencySettings = 0;
      let lateFeeSettings = 0;
      const ResultSettings = await schoolApi.getAllSetting();
      if (ResultSettings.success) {
        frequencySettings = ResultSettings?.data?.filter((res) => res.key === 'frequency')[0].value;
        lateFeeSettings = ResultSettings?.data?.filter((res) => res.key === 'late_fee')[0].value;
      }

      if (studentRecord?.edit) {
        const pendingAmount = await schoolApi.fetchPendingAmount();
      }

      const insResult = await schoolApi.getallIntsallment(studentRecord?.student_addmission_id,studentRecord?.session_id,studentRecord?.fee_master_installment_id);
      console.log('insResult-on previewInstallment->',insResult);

      const currentDate = new Date();
      const currentYear = currentDate.getFullYear();

      insResult.result = insResult.result.map((is) => {
        if (!is.late_fee > 0 && is.status === 'pending') {
          let latefee_is = 0;
          const dueDate = is?.due_date;

          if (dueDate) {
            const dueDateObj = new Date(dueDate);
            if (dueDateObj.getFullYear() === currentYear && dueDateObj < currentDate) {
              const differenceInDays = Math.ceil((currentDate - dueDateObj) / (1000 * 3600 * 24));
              const netDueDays = parseInt((differenceInDays + parseInt(frequencySettings)) / parseInt(frequencySettings));
              latefee_is += Math.round(netDueDays * parseInt(lateFeeSettings));
            }
          }

          is.late_fee = latefee_is;
        }

        if (is.deposit_id && is.late_fee > 0) {
          let lateFeeHelper = insResult.result.filter((ft) => ft.deposit_id === is.deposit_id);
          if (is.id !== lateFeeHelper[lateFeeHelper.length - 1].id) {
            return { ...is, late_fee: 0 };
          }
        }

        return { ...is };
      });

      if (studentRecord?.edit) {
        insResult.result = insResult.result.map((res) => {
          if (res.deposit_id === studentRecord.deposit_id) {
            return { ...res, edit: true };
          } else {
            return { ...res, edit: false };
          }
        });
      }

      if (insResult.success) {
        setStudentInstallment(insResult.result);
      }
    };

    initClass();
  }, [studentRecord]);

  const handleCloseModal = () => {
    setShowModal(false);
  };

  console.log('studentInstallment-while preview->',studentInstallment);
  console.log('studentRecord-while preview->',studentRecord);

  return (
    <Modal
      show={showModal}
      onHide={handleCloseModal}
      backdrop="static"
      centered
      aria-labelledby="contained-modal-title-vcenter"
      // size="lg" 
    >
      {/* <Modal.Header closeButton>
        <Modal.Title className="text-center">Preview {studentInstallment?.month}</Modal.Title>
      </Modal.Header> */}
        <Modal.Header closeButton>
        <Modal.Title className="text-center w-100">Preview {studentRecord?.month}</Modal.Title>
      </Modal.Header>
      <Modal.Body style={{ maxHeight: '70vh', overflowY: 'auto' }}>
        {studentInstallment && (
          <InstallmentTable
            data={studentInstallment}
            edit={studentRecord?.edit}
            readonly = "true"
          />
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleCloseModal}>
          Close
        </Button>
        {/* <Button variant="primary">
          Save
        </Button> */}
      </Modal.Footer>
    </Modal>
  );
};

export default PreviewFeeInstallment;


// import React, { useEffect, useState } from 'react';
// import { Button, Modal } from "react-bootstrap";
// import schoolApi from '../../api/schoolApi';
// import InstallmentTable from '../fee/InstallmentTable';

// const PreviewFeeInstallment = ({ row, showModal, setShowModal }) => {
//   const [studentRecord, setStudentRecord] = useState(row ? row : {});
//   const [studentInstallment, setStudentInstallment] = useState();

//   useEffect(() => {
//     const initClass = async () => {
//       let frequencySettings = 0;
//       let lateFeeSettings = 0;
//       const ResultSettings = await schoolApi.getAllSetting();
//       if (ResultSettings.success) {
//         frequencySettings = ResultSettings?.data?.filter((res) => res.key === 'frequency')[0].value;
//         lateFeeSettings = ResultSettings?.data?.filter((res) => res.key === 'late_fee')[0].value;
//       }

//       if (studentRecord?.edit) {
//         const pendingAmount = await schoolApi.fetchPendingAmount();
//       }

//       const insResult = await schoolApi.getallIntsallment(
//         studentRecord?.student_addmission_id,
//         studentRecord?.session_id,
//         studentRecord?.fee_master_installment_id
//       );

//       const currentDate = new Date();
//       const currentYear = currentDate.getFullYear();

//       insResult.result = insResult.result.map((is) => {
//         if (!is.late_fee > 0 && is.status === 'pending') {
//           let latefee_is = 0;
//           const dueDate = is?.due_date;

//           if (dueDate) {
//             const dueDateObj = new Date(dueDate);
//             if (dueDateObj.getFullYear() === currentYear && dueDateObj < currentDate) {
//               const differenceInDays = Math.ceil((currentDate - dueDateObj) / (1000 * 3600 * 24));
//               const netDueDays = parseInt((differenceInDays + parseInt(frequencySettings)) / parseInt(frequencySettings));
//               latefee_is += Math.round(netDueDays * parseInt(lateFeeSettings));
//             }
//           }

//           is.late_fee = latefee_is;
//         }

//         if (is.deposit_id && is.late_fee > 0) {
//           let lateFeeHelper = insResult.result.filter((ft) => ft.deposit_id === is.deposit_id);
//           if (is.id !== lateFeeHelper[lateFeeHelper.length - 1].id) {
//             return { ...is, late_fee: 0 };
//           }
//         }

//         return { ...is };
//       });

//       if (studentRecord?.edit) {
//         insResult.result = insResult.result.map((res) => {
//           if (res.deposit_id === studentRecord.deposit_id) {
//             return { ...res, edit: true };
//           } else {
//             return { ...res, edit: false };
//           }
//         });
//       }

//       if (insResult.success) {
//         setStudentInstallment(insResult.result);
//       }
//     };

//     initClass();
//   }, [studentRecord]);

//   const handleCloseModal = () => {
//     setShowModal(false);
//   };

//   return (
//     <Modal
//       show={showModal}
//       onHide={handleCloseModal}
//       backdrop="static"
//       centered
//       aria-labelledby="contained-modal-title-vcenter"
//     >
//       <Modal.Header closeButton>
//         <Modal.Title>New Exam Title</Modal.Title>
//       </Modal.Header>
//       <Modal.Body>
//         {studentInstallment && (
//           <InstallmentTable
//             data={studentInstallment}
//             edit={studentRecord?.edit}
//             readonly= "true"
//           />
//         )}
//       </Modal.Body>
//       <Modal.Footer>
//         <Button variant="secondary" onClick={handleCloseModal}>
//           Close
//         </Button>
//         {/* <Button variant="primary">
//           Save
//         </Button> */}
//       </Modal.Footer>
//     </Modal>
//   );
// };

// export default PreviewFeeInstallment;