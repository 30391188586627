import React, { useEffect, useState } from "react";
import { Card, Col, Form, Row, Button } from "react-bootstrap";
import schoolApi from "../../api/schoolApi";
import { Link } from "react-router-dom";
import "../../App.css";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import PdfHeader from "../layout/common/PdfHeader";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { MdFilterAlt } from "react-icons/md";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Helmet } from "react-helmet";

const ReportFeeDeposit = (props) => {
  const [classOptions, setClassOptions] = useState([]);
  const [sessionOptions, setSessionOptions] = useState([]);

  const [reportData, setReportData] = useState({
    classid: "",
    session_id: "",
    start_date: null,
    end_date: null,
  });
  const [total, setTotal] = useState(0);
  const [feeData, setFeeData] = useState([]);

  useEffect(() => {
    fetchClassRecords();
    fetchAllSessions();
  }, []);

  const fetchClassRecords = async () => {
    try {
      const result = await schoolApi.getActiveClassRecords();
      if (result.success) {
          const options = result.result.map((item) => ({
              value: item.id,
              label: item.classname,
          }));
          setClassOptions(options);
      }
    } catch (error) {
      console.error("Error fetching class records:", error);
    }
  };

  const fetchAllSessions = async () => {
    try {
      const sessions = await schoolApi.fetchSessions();
      console.log("sessions========>>>>>>", sessions);
      if (sessions) {
        const options = sessions.map((sess) => ({
          value: sess.id,
          label: sess.year,
        }));
        setSessionOptions(options);

        const currentSession = sessions.find((sess) => {
          const currentDate = new Date();
          const startDate = new Date(sess.startdate);
          const endDate = new Date(sess.enddate);
          return currentDate >= startDate && currentDate <= endDate;
        });

        if (currentSession) {
          setReportData((prevState) => ({
            ...prevState,
            session_id: currentSession.id,
          }));
        }
      }
    } catch (error) {
      console.error("Error fetching sessions:", error);
    }
  };
  const formatNumberWithCommas = (number) => {
    return number ? number.toLocaleString("en-IN") : "0";
  };
  const handleChange = (event) => {
    const { name, value } = event.target;
    setReportData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };
  const handleDateChange = (date, name) => {
    setReportData((prevState) => ({
      ...prevState,
      [name]: date,
    }));
  };

  const handleSearch = async () => {
    try {
      console.log("reportData-12212->", reportData);
      if (!reportData.session_id) {
        return toast.error("Please select required field!!");
      }
      const result = await schoolApi.fetchClassRecordsFeeDeposit(
        reportData?.classid,
        reportData.session_id,
        reportData?.start_date,
        reportData?.end_date
      );
      console.log("--;;;;;;;;;;>", result);
      if (result.success) {
        const depodata = result.result;
        let totalAmount = 0;
        for (let i = 0; i <= depodata.length - 1; i++) {
          totalAmount += parseInt(depodata[i].deposited_amount);
        }
        setTotal(totalAmount);
        setFeeData(result.result);
      } else {
        setFeeData([]);
        return toast.error(result.message);
      }
    } catch (error) {
      console.error("Error fetching subjects:", error);
    }
  };

  const handleDownload = async () => {
    const input = document.getElementById("reportContent");
    const canvas = await html2canvas(input, { scale: 2 });
    const imgData = canvas.toDataURL("image/png");
    const pdf = new jsPDF("p", "mm", "a4");

    const pdfWidth = pdf.internal.pageSize.getWidth() - 20;
    const imgProps = pdf.getImageProperties(imgData);
    const imgWidth = pdfWidth;
    const imgHeight = (imgProps.height * imgWidth) / imgProps.width;

    let heightLeft = imgHeight;
    let position = 10;

    while (heightLeft > 0) {
      pdf.addImage(imgData, "PNG", 10, position, imgWidth, imgHeight);
      heightLeft -= pdf.internal.pageSize.getHeight();
      if (heightLeft > 0) {
        pdf.addPage();
        position = 0;
      }
    }

    pdf.save("FeeDepositReport.pdf");
  };

  let serialNumber = 1;

  return (
    <>
      <Helmet>
        <title>{props?.tabName}</title>
      </Helmet>

      <Row className="g-0">
        <Col lg={3} className="mx-3">
          <Link className="nav-link mx-2" to="/">
            Home <i className="fa-solid fa-chevron-right"></i> Fees Deposit
            Report
          </Link>
        </Col>
      </Row>

      <Row className="mt-5 m-0 px-4">
        <Card className="px-0 py-3">
          <Row className="m-0">
            <Col lg={12} className="px-3">
              <h5 className="fw-semibold">Filters</h5>
            </Col>
            <hr />
          </Row>
          <Row className="flex-row mt-3 mb-2 px-4">
            <Col lg={3}>
              <Form.Group>
                <Form.Label className="form-view-label">Class Name</Form.Label>
                <Form.Select
                  name="classid"
                  onChange={handleChange}
                  value={reportData.classid}
                >
                  <option key="default" value="">
                    All
                  </option>
                  {classOptions.map((res) => (
                    <option key={res.value} value={res.value}>
                      {res.label}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>
            </Col>
            <Col lg={3}>
              <Form.Group>
                <Form.Label className="form-view-label">Session</Form.Label>
                <Form.Select
                  name="session_id"
                  onChange={handleChange}
                  required
                  value={reportData.session_id}
                >
                  <option value="">-- Select Session --</option>
                  {sessionOptions.map((res) => (
                    <option key={res.value} value={res.value}>
                      {res.label}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>
            </Col>
            <Col lg={3}>
              <Form.Group>
                <Form.Label className="form-view-label">Start Date</Form.Label>
                <DatePicker
                  selected={reportData.start_date}
                  onChange={(date) => handleDateChange(date, "start_date")}
                  dateFormat="dd-MM-yyyy"
                  className="form-control"
                  placeholderText="Select start date"
                  showMonthDropdown
                  showYearDropdown
                  onKeyDown={(e) => e.preventDefault()}
                  highlightDates={[new Date()]}
                />
              </Form.Group>
            </Col>

            <Col lg={3}>
              <Form.Group>
                <Form.Label className="form-view-label">End Date</Form.Label>
                <DatePicker
                  selected={reportData.end_date}
                  onChange={(date) => handleDateChange(date, "end_date")}
                  dateFormat="dd-MM-yyyy"
                  className="form-control"
                  placeholderText="Select end date"
                  showMonthDropdown
                  showYearDropdown
                  onKeyDown={(e) => e.preventDefault()}
                  highlightDates={[new Date()]}
                />
              </Form.Group>
            </Col>

            <Col className="mt-4">
              <div className="button-wrapper float-end">
                <Button onClick={handleSearch} variant="primary">
                  Search
                </Button>
                {feeData.length > 0 && (
                  <Button
                    className="mx-2"
                    onClick={handleDownload}
                    variant="primary"
                  >
                    Download
                  </Button>
                )}
              </div>
            </Col>
          </Row>
        </Card>
      </Row>

      {feeData.length > 0 && (
        <Row className="mt-5 m-0 px-4">
          <Card className="px-0 py-3">
            <Row className="flex-row mt-3 mb-2 px-4">
              <Col lg={12} className="p-0">
                <div id="reportContent">
                  <PdfHeader />
                  <div
                    style={{ borderTop: "2px solid #000", margin: "10px 0" }}
                  ></div>
                  <Row className="mt-4">
                    <Col lg={12} className="px-3">
                      <h5 style={{ textAlign: "center" }}className="fw-semibold">
                        Fee Deposit Report
                      </h5>
                    </Col>
                    <hr />
                  </Row>
                  <table className="reportTable" id="reportTable">
                    <thead>
                      <tr>
                        <th>Sr</th>
                        <th>Student Name</th>
                        <th>Class</th>
                        <th>Deposit Date</th>
                        <th>Payment Mode</th>
                        <th>Transaction Number</th>
                        <th>Deposited Amount</th>
                      </tr>
                    </thead>
                    <tbody>
                      {feeData.length > 0 ? (
                        feeData?.map((student, studentIndex) => {
                          return (
                            <tr>
                              <td>{serialNumber++}</td>
                              <td style = {{textAlign : "left"}}>{student.student_name}</td>
                              <td  style = {{textAlign : "left"}}>
                                {student.classname}{" "}
                                {student.section_name &&
                                  ` (${student.section_name})`}
                              </td>
                              <td>{student.deposit_date}</td>
                              <td  style = {{textAlign : "left"}}>{student.payment_method}</td>
                              <td  style = {{textAlign : "left"}}>{student?.transaction_no}</td>
                              <td  style = {{textAlign : "right"}}>{formatNumberWithCommas(parseInt(student.deposited_amount))}</td>
                            </tr>
                          );
                        })
                      ) : (
                        <tr>
                          <td colSpan="10" className="text-center">
                            No data available
                          </td>
                        </tr>
                      )}
                      {feeData.length > 0 && (
                        <tr>
                          <td  style = {{textAlign : "right"}}
                            colSpan={6}
                            className="text-right font-weight-bold"
                          >
                            Total
                          </td>
                          <td  style = {{textAlign : "right"}} className="font-weight-bold">{formatNumberWithCommas(parseInt(total))}</td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              </Col>
            </Row>
          </Card>
        </Row>
      )}
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      ></ToastContainer>
    </>
  );
};

export default ReportFeeDeposit;
