// src/FeeReconciliation.js
import React, { useEffect, useState } from "react";
import schoolApi from "../../api/schoolApi";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Card, Col, Form, Row, Button } from "react-bootstrap";
import PdfHeader from "../layout/common/PdfHeader";
import { Link } from "react-router-dom";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import { Helmet } from "react-helmet";

const FeeReconciliation = (props) => {
  const [sessionOptions, setSessionOptions] = useState([]);
  const [reportData, setReportData] = useState({
    session_id: "",
  });
  const [feeData, setFeeData] = useState([]);

  console.log("feeData---->", feeData);
  useEffect(() => {
    fetchAllSessions();
  }, []);
  const formatNumberWithCommas = (number) => {
    return number ? number.toLocaleString("en-IN") : "0";
  };
  const fetchAllSessions = async () => {
    try {
      const sessions = await schoolApi.fetchSessions();
      console.log("sessions========>>>>>>", sessions);
      if (sessions) {
        const options = sessions?.map((sess) => ({
          value: sess.id,
          label: sess.year,
        }));
        setSessionOptions(options);

        // Find the current session
        const currentSession = sessions?.find((sess) => {
          const currentDate = new Date();
          const startDate = new Date(sess.startdate);
          const endDate = new Date(sess.enddate);
          return currentDate >= startDate && currentDate <= endDate;
        });

        if (currentSession) {
          setReportData((prevState) => ({
            ...prevState,
            session_id: currentSession.id,
          }));
        }
      }
    } catch (error) {
      console.error("Error fetching sessions:", error);
    }
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setReportData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSearch = async () => {
    if (!reportData?.session_id) {
      return toast.error("Please select required fields");
    }
    try {
      const result = await schoolApi.getFeeReconciliationData(reportData);
      console.log("result======>>>>>>>>><<<<<<", result);
      console.log("result ????? ======>>>>>>>>><<<<<<", result[0].classes);
      console.log("result00000000 ======>>>>>>>>><<<<<<", result[0].less);
      console.log("resultjjjkk ======>>>>>>>>><<<<<<", result);

      const classOrder = {
        NUR: 1,
        LKG: 2,
        HKG: 3,
        "1st": 4,
        "2nd": 5,
        "3rd": 6,
        "4th": 7,
        "5th": 8,
        "6th": 9,
        "7th": 10,
        "8th": 11,
        "9th": 12,
        "10th": 13,
        "11th-SCI": 14,
        "11th-COMM": 15,
        "11th-ARTS": 16,
        "12th-SCI": 17,
        "12th-COMM": 18,
        "12th-ARTS": 19,
      };

      result[0].classes.sort(
        (a, b) => classOrder[a.classname] - classOrder[b.classname]
      );

      console.log("after sorted class by order no.", result[0].classes);

      if (result.success === false && result.message === "No record found") {
        toast.error(result.message);
        setFeeData([]);
      } else {
        setFeeData(Array.isArray(result) ? result : []);
      }
    } catch (error) {
      console.error("Error fetching student fee data:", error);
      toast.error("Error fetching student fee data");
      setFeeData([]);
    }
  };

  const handleDownload = async () => {
    const input = document.getElementById("reportContent");
    const canvas = await html2canvas(input, { scale: 2 });
    const imgData = canvas.toDataURL("image/png");
    const pdf = new jsPDF("p", "mm", "a4");

    const pdfWidth = pdf.internal.pageSize.getWidth() - 20;
    const imgProps = pdf.getImageProperties(imgData);
    const imgWidth = pdfWidth;
    const imgHeight = (imgProps.height * imgWidth) / imgProps.width;

    let heightLeft = imgHeight;
    let position = 10;

    while (heightLeft > 0) {
      pdf.addImage(imgData, "PNG", 10, position, imgWidth, imgHeight);
      heightLeft -= pdf.internal.pageSize.getHeight();
      if (heightLeft > 0) {
        pdf.addPage();
        position = 0;
      }
    }

    pdf.save("FeeReconciliationReport.pdf");
  };

  const grandFees = Array.isArray(feeData)
    ? feeData.reduce((acc, item) => {
        const classTotal = Array.isArray(item.classes)
          ? item.classes.reduce(
              (classAcc, classItem) => classAcc + (classItem.grand_total || 0),
              0
            )
          : 0;
        return acc + classTotal;
      }, 0)
    : 0;

  const actualFeeRecieved = Array.isArray(feeData)
    ? feeData.reduce((acc, item) => {
        const classTotal = Array.isArray(item.classes)
          ? item.classes.reduce(
              (classAcc, classItem) =>
                classAcc + (classItem.actual_deposited_amount || 0),
              0
            )
          : 0;
        return acc + classTotal;
      }, 0)
    : 0;
  const grandStu = Array.isArray(feeData)
    ? feeData.reduce((acc, item) => {
        const classTotal = Array.isArray(item.classes)
          ? item.classes.reduce(
              (classAcc, classItem) =>
                classAcc + (classItem.total_students || 0),
              0
            )
          : 0;
        return acc + classTotal;
      }, 0)
    : 0;
  let totalConcession = Array.isArray(feeData)
    ? feeData.reduce((acc, item) => {
        const lessTotal = Array.isArray(item.less)
          ? item.less.reduce(
              (lessAcc, lessItem) =>
                lessAcc + (lessItem.total_general_amount || 0),
              0
            )
          : 0;

        const studentsJoinedLate = 0;
        const studentsLeftMid = Array.isArray(item.student_left_in_mid)
          ? item.student_left_in_mid.reduce(
              (leftAcc, leftItem) =>
                leftAcc + (leftItem.total_amount_left || 0),
              0
            )
          : 0;

        return acc + lessTotal + studentsJoinedLate + studentsLeftMid;
      }, 0)
    : 0;
  const otherFees = feeData.reduce((acc, item) => {
    return item.otherfees;
  }, 0);

  const totalFeeDemand = grandFees + otherFees;
  totalConcession += feeData[0]?.late_joined_st_discount;
  const afterConcession = totalFeeDemand - totalConcession;
  const diffrence_amount = afterConcession - actualFeeRecieved;
  let grand_total_student = null;
  let grand_total_Months = null;
  let grand_total_monthly_fees = null;
  let grand_total_totalMonthly_fees = null;
  let grand_total_annual_charges = null;
  let grand_total_annual_charges_fees = null;

  return (
    <>
      <Helmet>
        <title>{props?.tabName}</title>
      </Helmet>

      <Row className="g-0">
        <Col lg={2} className="mx-3">
          <Link className="nav-link mx-2" to="/">
            Home <i className="fa-solid fa-chevron-right"></i> Fees collection
          </Link>
        </Col>
      </Row>

      <Row className="mt-5 m-0 px-4">
        <Card className="px-0 py-3" style={{ margin: "-18" }}>
          <Row className="m-0">
            <Col lg={12} className="px-3">
              <h5 className="fw-semibold">Filters</h5>
            </Col>
            <hr />
          </Row>
          <Row className="flex-row mt-3 mb-2 px-4">
            <Col lg={4}>
              <Form.Group className="mx-3">
                <Form.Label className="form-view-label">Session</Form.Label>
                <Form.Select
                  name="session_id"
                  onChange={handleChange}
                  value={reportData.session_id}
                  required
                >
                  <option value="">-- Select Session --</option>
                  {sessionOptions?.map((res) => (
                    <option key={res.value} value={res.value}>
                      {res.label}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>
            </Col>

            <Col lg={4} className="d-flex align-items-end ">
              <div className="button-wrapper ">
                <Button onClick={handleSearch} variant="primary">
                  Search
                </Button>
                {feeData.length > 0 && (
                  <Button
                    onClick={handleDownload}
                    variant="primary"
                    className="mx-2"
                  >
                    Download
                  </Button>
                )}
              </div>
            </Col>
          </Row>
        </Card>
      </Row>

      {feeData.length > 0 && (
        <Row className="mt-5 m-0 px-4">
          <Card className="px-0 py-3">
            <Row className="flex-row mt-3 mb-2 px-4">
              <Col lg={12} className="p-0">
                <div id="reportContent">
                  <PdfHeader />
                  <div
                    style={{ borderTop: "2px solid #000", margin: "10px 0" }}
                  ></div>
                  <Row className="mt-4">
                    <Col lg={12} className="px-3">
                      <h5 className="fw-semibold">Fee Reconciliation Report</h5>
                    </Col>
                    <hr />
                  </Row>
                  <table className="reportTable " id="reportTable">
                    <thead>
                      <tr>
                        <th>Sr</th>
                        <th className="text-right">Class</th>
                        <th>No Of Students</th>
                        <th>Months</th>
                        <th>Monthly Fees</th>
                        <th>Total Monthly Fees</th>
                        <th>Annual Charges</th>
                        <th>Annual Charges Fee</th>
                        <th>Grand Total</th>
                      </tr>
                    </thead>

                    <tbody>
                      {feeData.length > 0 ? (
                        feeData?.map((data, index) =>
                          data.classes.length > 0 ? (
                            data?.classes?.map(
                              (classItem, classIndex) => (
                                (grand_total_student +=
                                  classItem.total_students),
                                (grand_total_Months += classItem.months),
                                (grand_total_monthly_fees +=
                                  classItem.monthly_fee),
                                (grand_total_totalMonthly_fees +=
                                  classItem.total_monthly),
                                (grand_total_annual_charges +=
                                  classItem.Annual_charges),
                                (grand_total_annual_charges_fees +=
                                  classItem.total_annual_charges),
                                (
                                  <tr key={classItem.class_id}>
                                    <td style={{ textAlign: "center" }}>
                                      {classIndex + 1}
                                    </td>
                                    <td style={{ textAlign: "left" }}>
                                      {classItem.classname}
                                    </td>
                                    <td style={{ textAlign: "right" }}>
                                      {formatNumberWithCommas(
                                        classItem.total_students
                                      )}
                                    </td>
                                    <td style={{ textAlign: "right" }}>
                                      {formatNumberWithCommas(classItem.months)}
                                    </td>
                                    <td style={{ textAlign: "right" }}>
                                      {formatNumberWithCommas(
                                        classItem.monthly_fee
                                      )}
                                    </td>
                                    <td style={{ textAlign: "right" }}>
                                      {formatNumberWithCommas(
                                        classItem.total_monthly
                                      )}
                                    </td>
                                    <td style={{ textAlign: "right" }}>
                                      {formatNumberWithCommas(
                                        classItem.Annual_charges
                                      )}
                                    </td>
                                    <td style={{ textAlign: "right" }}>
                                      {formatNumberWithCommas(
                                        classItem.total_annual_charges
                                      )}
                                    </td>
                                    <td style={{ textAlign: "right" }}>
                                      {formatNumberWithCommas(
                                        classItem.grand_total
                                      )}
                                    </td>
                                  </tr>
                                )
                              )
                            )
                          ) : (
                            <tr key={`no-data-${index}`}>
                              <td colSpan="8" className="text-center">
                                No classes available
                              </td>
                            </tr>
                          )
                        )
                      ) : (
                        <tr>
                          <td colSpan="8" className="text-center">
                            No data available
                          </td>
                        </tr>
                      )}
                    </tbody>

                    <tfoot>
                      <tr>
                        <td colSpan="1">20</td>
                        <td width={90} className="text-start">
                          Grand Totals{" "}
                        </td>
                        <td className="text-end">
                          {formatNumberWithCommas(grand_total_student)}
                        </td>
                        <td className="text-end">
                          {formatNumberWithCommas(grand_total_Months)}
                        </td>
                        <td className="text-end">
                          {formatNumberWithCommas(grand_total_monthly_fees)}
                        </td>
                        <td className="text-end">
                          {formatNumberWithCommas(
                            grand_total_totalMonthly_fees
                          )}
                        </td>
                        <td className="text-end">
                          {formatNumberWithCommas(grand_total_annual_charges)}
                        </td>
                        <td className="text-end">
                          {formatNumberWithCommas(
                            grand_total_annual_charges_fees
                          )}
                        </td>
                        <td colSpan="1" style={{ textAlign: "right" }}>
                          <strong>{formatNumberWithCommas(grandFees)}</strong>
                        </td>
                      </tr>
                      <tr>
                        <td colSpan="1">21</td>
                        <td colSpan="2" style={{ textAlign: "left" }}>
                          Other Fees :
                        </td>
                        <td colSpan="5" style={{ textAlign: "left" }}>
                          <strong>admi.fee</strong> + <strong>Fines</strong> +{" "}
                          <strong>T.C</strong> + <strong>Reg.fee</strong>
                        </td>
                        <td colSpan="1" style={{ textAlign: "right" }}>
                          <strong>{formatNumberWithCommas(otherFees)}</strong>
                        </td>
                      </tr>

                      <tr>
                        <td colSpan="1">I</td>
                        <td colSpan="6" style={{ textAlign: "left" }}>
                          FEE DEMAND FOR :
                        </td>
                        <td colSpan="1">
                          <strong>{formatNumberWithCommas(grandStu)}</strong>
                        </td>
                        <td colSpan="1" style={{ textAlign: "right" }}>
                          <strong>
                            {formatNumberWithCommas(totalFeeDemand)}
                          </strong>
                        </td>
                      </tr>

                      {feeData.length > 0 &&
                        feeData?.map((data, index) =>
                          data?.less?.map((lessItem, lessIndex) => (
                            <tr key={lessItem.name}>
                              {lessIndex === 0 && (
                                <td
                                  rowSpan={data.less.length}
                                  style={{
                                    borderTop: "none",
                                    borderBottom: "none",
                                    verticalAlign: "middle",
                                  }}
                                >
                                  Less
                                </td>
                              )}
                              <td colSpan="7" style={{ textAlign: "left" }}>
                                {lessItem.name}
                              </td>
                              <td colSpan="1" style={{ textAlign: "right" }}>
                                {formatNumberWithCommas(
                                  lessItem.total_general_amount
                                )}
                              </td>
                            </tr>
                          ))
                        )}

                      <tr>
                        <td
                          colSpan="1"
                          style={{ borderTop: "none", borderBottom: "none" }}
                        ></td>
                        <td colSpan="7" style={{ textAlign: "left" }}>
                          {" "}
                          Students joined late
                        </td>
                        <td colSpan="1" style={{ textAlign: "right" }}>
                          {formatNumberWithCommas(
                            feeData[0]?.late_joined_st_discount
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td
                          colSpan="1"
                          style={{ borderTop: "none", borderBottom: "none" }}
                        ></td>
                        <td colSpan="7" style={{ textAlign: "left" }}>
                          {" "}
                          Students left in mid-session
                        </td>
                        {feeData.length > 0 &&
                          feeData?.map((data, index) =>
                            data?.student_left_in_mid?.map(
                              (studentLessItem, classIndex) => (
                                <td
                                  key={`${index}-${classIndex}`}
                                  colSpan="1"
                                  style={{ textAlign: "right" }}
                                >
                                  {formatNumberWithCommas(
                                    studentLessItem.total_amount_left
                                  )}
                                </td>
                              )
                            )
                          )}
                      </tr>
                      <tr>
                        <td colSpan="1">II</td>
                        <td colSpan="7" style={{ textAlign: "left" }}>
                          TOTAL (Less)
                        </td>
                        <td colSpan="1" style={{ textAlign: "right" }}>
                          {formatNumberWithCommas(totalConcession)}
                        </td>
                      </tr>
                      <tr>
                        <td colSpan="1">III</td>
                        <td colSpan="7" style={{ textAlign: "left" }}>
                          FEE DEMAND AFTER CONCESSION
                        </td>
                        <td colSpan="1" style={{ textAlign: "right" }}>
                          {formatNumberWithCommas(afterConcession)}
                        </td>
                      </tr>
                      <tr>
                        <td colSpan="1">IV</td>
                        <td colSpan="7" style={{ textAlign: "left" }}>
                          Actual Fee Received
                        </td>
                        <td colSpan="1" style={{ textAlign: "right" }}>
                          {formatNumberWithCommas(actualFeeRecieved)}
                        </td>
                      </tr>
                      <tr>
                        <td colSpan="1">V</td>
                        <td colSpan="7" style={{ textAlign: "left" }}>
                          Fee Defaulters
                        </td>
                        <td colSpan="1">{/* Insert fee defaulters */}</td>
                      </tr>
                      <tr>
                        <td colSpan="1">VI</td>
                        <td colSpan="7" style={{ textAlign: "left" }}>
                          Misc. Fee
                        </td>
                        <td colSpan="1">{/* Insert miscellaneous fee */}</td>
                      </tr>
                      <tr>
                        <td colSpan="1">VII</td>
                        <td colSpan="7" style={{ textAlign: "left" }}>
                          GRAND TOTAL (IV + V)
                        </td>
                        <td colSpan="1">{/* Insert grand total */}</td>
                      </tr>
                      <tr>
                        <td colSpan="1">VIII</td>
                        <td colSpan="7" style={{ textAlign: "left" }}>
                          Difference Between (III & VI)
                        </td>
                        <td colSpan="1" style={{ textAlign: "right" }}>
                          {formatNumberWithCommas(diffrence_amount)}
                        </td>
                      </tr>
                    </tfoot>
                  </table>
                </div>
              </Col>
            </Row>
          </Card>
        </Row>
      )}
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      ></ToastContainer>
    </>
  );
};
export default FeeReconciliation;
