import React, { useEffect, useState } from "react";
import { Card, Col, Form, Row, Button } from "react-bootstrap";
import schoolApi from "../../api/schoolApi";
import { Link, useLocation } from "react-router-dom";

import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import PdfHeader from "../layout/common/PdfHeader";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import moment from "moment";
import { Helmet } from "react-helmet";

const DueFeeReport = (props) => {
  const location = useLocation();
  console.log("location ------>>>>><<<<<<", location);
  const [classOptions, setClassOptions] = useState([]);
  const [sessionOptions, setSessionOptions] = useState([]);
  const [reportData, setReportData] = useState({
    classid: "",
    session_id: "",
  });
  const [feeData, setFeeData] = useState([]);
  const [settings, setSettings] = useState({});
  const [netAmount, setNetAmount] = useState({
    total_amount: 0,
    total_due_fees: 0,
    total_others_amount: 0,
  });

  useEffect(() => {
    fetchClassRecords();
    fetchAllSessions();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      if (location.state && location.pathname === "/duefeereport") {
        console.log("enter in use effect");

        await fetchAllSessions();

        if (settings && settings.frequency && settings.late_fee) {
          fetchDueFeesData();
        } else {
          //   toast.error("Settings not loaded properly.");
        }
      }
    };

    fetchData();
  }, [location.pathname, location.state]);

  useEffect(() => {
    if (location.state && location.pathname === "/duefeereport") {
      if (settings && settings.frequency && settings.late_fee) {
        fetchDueFeesData();
      }
    }
  }, [settings]);

  const fetchDueFeesData = async () => {
    try {
      const result = await schoolApi.fetchDueFees(
        location.state.classid,
        location.state.session_id
      );
      if (result.success) {
        if (settings && settings.frequency && settings.late_fee) {
          filterData(result.result);
        } else {
          //   return toast.error("Settings not properly.");
        }
      } else {
        return toast.error(result.message);
      }
    } catch (error) {
      console.error("Error fetching due fees:", error);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      if (location.state && location.pathname === "/duefeereport") {
        console.log("enter in use effect");
        await fetchAllSessions();
        fetchDueFeesData();
      }
    };

    fetchData();
  }, [location.pathname, location.state]);

  const fetchClassRecords = async () => {
    try {
      const result = await schoolApi.getActiveClassRecords();
      if (result.success) {
        const options = result.result.map((item) => ({
          value: item.id,
          label: item.classname,
        }));
        setClassOptions(options);
      }
    } catch (error) {
      console.error("Error fetching class records:", error);
    }
  };

  const fetchAllSessions = async () => {
    try {
      const ResultSettings = await schoolApi.getAllSetting();
      console.log("ResultSettings====>>>", ResultSettings);
      if (ResultSettings.success) {
        setSettings({
          frequency: ResultSettings.data.find((res) => res.key === "frequency")
            .value,
          late_fee: ResultSettings.data.find((res) => res.key === "late_fee")
            .value,
        });
      }

      const sessions = await schoolApi.fetchSessions();
      if (sessions) {
        const options = sessions.map((sess) => ({
          value: sess.id,
          label: sess.year,
        }));
        setSessionOptions(options);

        const currentSession = sessions.find((sess) => {
          const currentDate = new Date();
          return (
            new Date(sess.startdate) <= currentDate &&
            new Date(sess.enddate) >= currentDate
          );
        });

        if (currentSession) {
          setReportData((prevState) => ({
            ...prevState,
            session_id: currentSession.id,
          }));
        }
      }
    } catch (error) {
      console.error("Error fetching sessions:", error);
    }
  };
  console.log("setSettings", settings);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setReportData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSearch = async () => {
    if (reportData.session_id) {
      try {
        const result = await schoolApi.fetchDueFees(
          reportData.classid,
          reportData.session_id
        );
        if (result.success) {
          filterData(result.result);
        } else {
          return toast.error(result.message);
        }
      } catch (error) {
        console.error("Error fetching due fees:", error);
      }
    } else {
      setFeeData([]);
      return toast.error("Please select required fields!!");
    }
  };

  const handleDownload = async () => {
    const input = document.getElementById("reportContent");
    const canvas = await html2canvas(input, { scale: 2 });
    const imgData = canvas.toDataURL("image/png");
    const pdf = new jsPDF("p", "mm", "a4");

    const pdfWidth = pdf.internal.pageSize.getWidth() - 20;
    const imgProps = pdf.getImageProperties(imgData);
    const imgWidth = pdfWidth;
    const imgHeight = (imgProps.height * imgWidth) / imgProps.width;

    let heightLeft = imgHeight;
    let position = 10;

    while (heightLeft > 0) {
      pdf.addImage(imgData, "PNG", 10, position, imgWidth, imgHeight);
      heightLeft -= pdf.internal.pageSize.getHeight();
      if (heightLeft > 0) {
        pdf.addPage();
        position = 0;
      }
    }

    pdf.save("FeeDepositReport.pdf");
  };

  const filterData = (result) => {
    console.log("result on filterdata-->", result);
    let data = [];
    let total_due_fees = 0;
    let total_others_amount = 0;
    let total_amount = 0;

    result?.forEach((item) => {
      let due_amount = 0;
      let others = 0;
      let student_total_amount = 0;
      const currentDate = moment();

      item?.installments?.forEach((inst) => {
        console.log("inst-step-1->", inst);
        if (
          inst.status === "pending" &&
          moment(inst.due_date).isSameOrBefore(currentDate)
        ) {
          console.log("inst-step-2->", inst);
          due_amount += inst.installmentamount;
          const daysOverdue = currentDate.diff(moment(inst.due_date), "days");
          console.log("daysOverdue =====>>>>", daysOverdue);
          console.log("settings.frequency =====>>>>", settings.frequency);
          console.log("settings.late_fee =====>>>>", settings.late_fee);
          const lateFee =
            Math.round(
              (daysOverdue + parseInt(settings.frequency)) /
                parseInt(settings.frequency)
            ) * parseInt(settings.late_fee);
          console.log("only lateFee", lateFee);
          others += lateFee;
          console.log("others lateFee", others);

          console.log("inst-step-3->", due_amount);
          console.log("inst-step-4->", daysOverdue);
        }
      });

      student_total_amount = due_amount + others;

      if (due_amount > 0) {
        data.push({
          student_name: item.student_name,
          class: item.classname,
          section_name: item.section_name,
          due_amount: due_amount,
          others: others,
          total_amount: student_total_amount,
          due_date: item.installments[0]?.due_date,
          status: due_amount > 0 ? "Overdue" : "Pending",
        });

        total_due_fees += due_amount;
        console.log("total_due_fees====>>", total_due_fees);
        console.log("others====>>", others);
        total_others_amount += others;
        console.log("total_others_amount====>>", total_others_amount);
        total_amount += student_total_amount;
        console.log("total_others_amount====>>", total_others_amount);
      }
    });

    setFeeData(data);
    setNetAmount({
      total_amount: total_amount,
      total_due_fees: total_due_fees,
      total_others_amount: total_others_amount,
    });

    if (data.length === 0) {
      toast.error("No Record Found");
    }
  };
  const formatNumberWithCommas = (number) => {
    return number ? number.toLocaleString("en-IN") : "0";
  };

  return (
    <>
      <Helmet>
        <title>{props?.tabName}</title>
      </Helmet>
      <Row className="g-0">
        <Col lg={3} className="mx-3">
          <Link className="nav-link mx-2" to="/">
            Home <i className="fa-solid fa-chevron-right"></i> Fees Dues Report
          </Link>
        </Col>
      </Row>

      <Row className="mt-5 m-0 px-4">
        <Card className="px-0 py-3" style={{ marginTop: "-18px" }}>
          <Row className="m-0">
            <Col lg={12} className="px-3">
              <h5 className="fw-semibold">Filters</h5>
            </Col>
            <hr />
          </Row>
          <Row className="flex-row mt-3 mb-2 px-4">
            <Col lg={4}>
              <Form.Group className="mx-3">
                <Form.Label className="form-view-label">Class Name</Form.Label>
                <Form.Select
                  name="classid"
                  onChange={handleChange}
                  value={reportData.classid}
                >
                  <option key="default" value="">
                    All
                  </option>
                  {classOptions.map((res) => (
                    <option key={res.value} value={res.value}>
                      {res.label}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>
            </Col>
            <Col lg={4}>
              <Form.Group className="mx-3">
                <Form.Label className="form-view-label">Session</Form.Label>
                <Form.Select
                  name="session_id"
                  onChange={handleChange}
                  value={reportData.session_id}
                  required
                >
                  <option value="">-- Select Session --</option>
                  {sessionOptions.map((res) => (
                    <option key={res.value} value={res.value}>
                      {res.label}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>
            </Col>

            <Col lg={4} className="d-flex align-items-end">
              <div className="button-wrapper">
                <Button onClick={handleSearch} variant="primary">
                  Search
                </Button>
                {feeData.length > 0 && (
                  <Button
                    onClick={handleDownload}
                    variant="primary"
                    className="ms-3"
                  >
                    Download
                  </Button>
                )}
              </div>
            </Col>
          </Row>
        </Card>
      </Row>

      {feeData.length > 0 && (
        <Row className="mt-5 m-0 px-4">
          <Card className="px-0 py-3">
            <Row className="flex-row mb-3 px-4">
              <Col lg={12} className="p-0">
                <div id="reportContent">
                  <PdfHeader />
                  <div
                    style={{ borderTop: "2px solid #000", margin: "10px 0" }}
                  ></div>
                  <Row className="mt-4">
                    <Col lg={12} className="px-3">
                      <h5
                        style={{ textAlign: "center" }}
                        className="fw-semibold"
                      >
                        Fee Pending Report
                      </h5>
                    </Col>
                    <hr />
                  </Row>
                  <table className="reportTable mt-5">
                    <thead>
                      <tr>
                        <th>S. No.</th>
                        <th>Student Name</th>
                        <th>Class</th>
                        <th>Due Fees</th>
                        <th>Others</th>
                        <th>Total Amount</th>
                        <th>Due Date</th>
                        <th>Status</th>
                      </tr>
                    </thead>
                    <tbody>
                      {feeData?.map((item, index) => {
                        return (
                          <tr key={index}>
                            <td>{index + 1}</td>
                            <td style={{ textAlign: "left" }}>
                              {item.student_name}
                            </td>
                            <td style={{ textAlign: "left" }}>
                              {item.class}{" "}
                              {item.section_name && ` (${item.section_name})`}
                            </td>
                            <td style={{ textAlign: "right" }}>
                              {formatNumberWithCommas(item.due_amount)}
                            </td>
                            <td style={{ textAlign: "right" }}>
                              {formatNumberWithCommas(item.others)}
                            </td>
                            <td style={{ textAlign: "right" }}>
                              {formatNumberWithCommas(item.total_amount)}
                            </td>
                            <td>
                              {moment(item.due_date).format("DD-MM-YYYY")}
                            </td>
                            <td style={{ textAlign: "left" }}>{item.status}</td>
                          </tr>
                        );
                      })}

                      <tr>
                        <td colSpan={3} style={{ textAlign: "right" }}>
                          <strong>Totals</strong>
                        </td>
                        <td style={{ textAlign: "right" }}>
                          {formatNumberWithCommas(netAmount.total_due_fees)}
                        </td>
                        <td style={{ textAlign: "right" }}>
                          {formatNumberWithCommas(
                            netAmount.total_others_amount
                          )}
                        </td>
                        <td style={{ textAlign: "right" }}>
                          {formatNumberWithCommas(netAmount.total_amount)}
                        </td>
                        <td></td>
                        <td></td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </Col>
            </Row>
          </Card>
        </Row>
      )}
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      ></ToastContainer>
    </>
  );
};

export default DueFeeReport;
