import React from "react";

import QL_Model from "./QL_Model";
import { useState } from "react";
import { useEffect } from "react";
import { Button, Col, Row, Table } from "react-bootstrap";
import {
  DatatableWrapper,
  Filter,
  Pagination,
  PaginationOptions,
  TableBody,
  TableHeader,
} from "react-bs-datatable";
import InfoPill from "../InfoPill";
import { ShimmerTable } from "react-shimmer-effects";
import { Link } from "react-router-dom";
import schoolApi from "../../api/schoolApi";
import Confirm from "../Confirm";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useOutletContext } from "react-router-dom";
import { Helmet } from "react-helmet";
const QuickLauncher = (props) => {
  const [QuickModel, setQuickModel] = useState(false);
  //const [allLauncherData, setAllLauncherData] = useState();
  const [moduleRecords, setModuleRecords, allLauncherData, setAllLauncherData] =
    useOutletContext();

  const labels = { beforeSelect: " " };
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [rowDataId, setRowDataId] = useState();

  const deleteHandler = (id) => {
    // Added By Mohd. Sarfraj | 10-Aug-2024.
    setShowDeleteModal(true);
    setRowDataId(id);
  };

  // Added By Mohd. Sarfraj | 10-Aug-2024.
  const handleDeleteQuicklouncher = async () => {
    if (rowDataId) {
      try {
        const result = await schoolApi.deletQuickLaucher(rowDataId);
        if (result && result.success) {
          const deleteLoncher = allLauncherData?.filter(
            (rec) => rec.id !== rowDataId
          );
          setAllLauncherData(deleteLoncher);
          setShowDeleteModal(false);
          return toast.success("Record Deleted successfully");
        } else {
          console.error("deletion was not successfull", result);
        }
      } catch (error) {
        console.error("Errror deleteing ", error);
      }
    }
  };

  const header = [
    {
      title: "Module Name",
      prop: "icon",
      isFilterable: true,
      cell: (row) => (
        <>
          <i className={row.icon}></i>
          {row.name}
        </>
      ),
    },
    {
      title: "url",
      prop: "sub_module_url",
      isFilterable: true,
    },
    {
      title: "Action",
      prop: "",
      isFilterable: true,
      cell: (row) => (
        // Added role by Mohd. Sarfraj | 10-Aug-2024.
        <button
          className="btn btn-sm btn-danger mx-1"
          onClick={() => deleteHandler(row.id)}
        >
          <i className="fa-regular fa-trash-can"></i>
        </button>
        // <i role='button' className="fa-solid fa-trash text-danger"  onClick={()=>deleteHandler(row.id)}></i>
      ),
    },
  ];

  console.log(allLauncherData, "allLauncherData");
  useEffect(() => {
    featchallLauncherData();
  }, []);
  const featchallLauncherData = async () => {
    try {
      const result = await schoolApi.getAllQuickLauncher();
      if (result && result.success) {
        setAllLauncherData(result.records);
      } else {
        setAllLauncherData([]);
      }
    } catch (error) {
      console.error("Errror deleteing ", error);
    }
  };
  useEffect(() => {
    if (QuickModel) {
      setQuickModel(true);
    }
  }, []);

  return (
    <>
      <Helmet>																
        <title>{props?.tabName}</title>			
      </Helmet>
      <Row className="g-0">
        <Col lg={2} className="mx-3">
          <Link className="nav-link mx-2" to="/">
            Home <i className="fa-solid fa-chevron-right"></i> Quick Launcher
          </Link>
        </Col>

        <ToastContainer
          position="top-right"
          autoClose={2000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
        />
        <Col lg={12} className="p-lg-4">
          {allLauncherData ? (
            <DatatableWrapper
              body={allLauncherData}
              headers={header}
              paginationOptionsProps={{
                initialState: {
                  rowsPerPage: 15,
                  options: [5, 10, 15, 20],
                },
              }}
            >
              <Row className="mb-4">
                <Col lg={3}>
                  <Filter />
                </Col>
                <Col lg={1} style={{ "margin-top": "-18px" }}>
                  <PaginationOptions labels={labels} />
                </Col>
                <Col lg={3} style={{ "margin-top": "-13px" }}>
                  <div>
                    <InfoPill
                      left="Total Exams"
                      right={allLauncherData?.length}
                    />
                  </div>
                </Col>
                <Col
                  lg={5}
                  style={{ "margin-top": "2px" }}
                  className="d-flex flex-col justify-content-end align-items-end"
                >
                  <Button
                    className="btn add-new-button"
                    variant=""
                    onClick={() => setQuickModel(true)}
                  >
                    Manage Launcher
                  </Button>
                </Col>
              </Row>
              <Table striped className="data-table">
                <TableHeader />
                <TableBody />
              </Table>
              <Pagination />
            </DatatableWrapper>
          ) : (
            <ShimmerTable row={10} col={4} />
          )}
        </Col>
        <Col lg={2}></Col>
      </Row>
      {QuickModel && (
        <QL_Model
          show={QuickModel}
          hideModel={() => setQuickModel(false)}
          featchallLauncherData={() => featchallLauncherData()}
        />
      )}

      {showDeleteModal && (
        <Confirm
          show={showDeleteModal}
          onHide={() => setShowDeleteModal(false)}
          handleDeleteButton={() => handleDeleteQuicklouncher()}
          title="Confirm delete?"
          message="You are going to delete the record. Are you sure?"
          table="deleteQuickLouncher"
        />
      )}
    </>
  );
};

export default QuickLauncher;
