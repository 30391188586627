/**
 * @author: Pooja Vaishnav
 */

import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import "react-bootstrap-typeahead/css/Typeahead.css";
import Form from "react-bootstrap/Form";
import { Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import schoolApi from "../../api/schoolApi";
import EditTimeTable from "./edit_timetable";
import PrintClassTimeTable from "./print_class_timetable";
import { Helmet } from "react-helmet";

const TimeTable = (props) => {
  const [category, setCategory] = useState("");
  const [timeTableScreen, setTimeTableScreen] = useState("");
  const [timeTableSlots, setTimeTableSlots] = useState([]);

  const [sectionId, setSectionId] = useState(null);
  const [classId, setClassId] = useState(null);

  let [sessions, setSessions] = useState([]);
  let [classList, setClassList] = useState([]);
  let [classMap, setClassMap] = useState(new Map());

  useEffect(() => {
    async function doInit() {
      try{
        const sessions = await schoolApi.fetchSessions(true);
        console.log("sessions3424", sessions);
        setSessions(sessions);
      }catch(error){
        toast.error(error.message);
      }
      
    }
    doInit();
  }, []);

  const fetchTimetable = async (loadView = null) => {
    try{
      let timetableResult = await schoolApi.fetchTimetableRecords(
        classId,
        sectionId
      );
      console.log('timetableResult@!@@!=>',timetableResult)
      if (timetableResult.success === true) {
        setTimeTableSlots(timetableResult?.timeSlotsResult);
        if (loadView && timetableResult.is_edit === true) {
          setTimeTableScreen(loadView);
        } else {
          if (timetableResult.is_edit === true) {
            setTimeTableScreen("VIEW");
          } else {
            setTimeTableScreen("EDIT");
          }
        }
      }
    }catch(error){
      console.log('erro=>',error)
      toast.error(error?.response?.data?.message);
    }
  };
  const onChangeCategory = async (e) => {
    try{
      console.log('e.target.value@!!@->',e.target.value)
      setCategory(e.target.value);
      classList = await schoolApi.getClassRecordsWithSections(e.target.value);
      console.log("classList@@+>", classList?.data);
      setClassList(classList?.data);
      const tempClassMap = classList?.data?.reduce((map, item) => {
        map.set(item.section_id, item);
        return map;
      }, new Map());
      console.log("first classList-==>", classList?.data);
      console.log("first tempClassMap-==>", tempClassMap);
      setClassMap(tempClassMap);
    }catch(error){
      console.log('rwyruyu')
      toast.error(error?.response?.data?.message);
    }
  };
  const onChangeClass = async (e) => {
    console.log('classMap22@@=>',classMap)
    const classObject = classMap?.get(e.target.value);
    console.log("$$classObject", classObject);
    setClassId(classObject?.class_id);
    setSectionId(e.target.value);
    setTimeTableScreen("");
  };

  useEffect(() => {
    if (classId && sectionId) {
      console.log('fgdjgkjfgjk')
      fetchTimetable();
    }
  }, [sectionId]);

  const changeView = async (view) => {
    await fetchTimetable(view);
  };

  return (
    <>
    <Helmet>
        <title>{props?.tabName}</title>
      </Helmet>
      
     <ToastContainer
      position="top-right"
      autoClose={2000}
      hideProgressBar={false}
      newestOnTop={false}
      closeOnClick
      rtl={false}
      pauseOnFocusLoss
      draggable
      pauseOnHover
      theme="light"
      />
      <>
        <Row className="g-0">
          <Col lg={10} className="mx-4">
            <Link className="nav-link" to="/">
              Home <i className="fa-solid fa-chevron-right"></i> Class Time
              Table
            </Link>
          </Col>
        </Row>
        <Row className="mt-5">
          <Col lg={4}>
            <Form.Group className="mx-3">
              <Form.Label className="form-view-label">Category</Form.Label>
              <Form.Select
                name="category"
                 onChange={onChangeCategory}
                //value={contact.category}
                //disabled={location.pathname === "/driver/e"}
              >
                <option value="">--Select Category--</option>
                <option value="Pre-Primary">Pre-Primary</option>
                <option value="Primary">Primary</option>
                <option value="Secondary">Secondary</option>
                <option value="Higher-Secondary">Higher-Secondary</option>
              </Form.Select>
            </Form.Group>
          </Col>
          <Col lg={4}>
            <Form.Group className="mx-3">
              <Form.Label className="form-view-label">Class Name</Form.Label>
              <Form.Select
                name="class_id"
                onChange={onChangeClass}
                value={sectionId}
              >
                <option value="">-Select Class-</option>
                {classList.length>0 && classList.map(
                  (item) => (
                    console.log("itemn", item),
                    (
                      <option key={item.section_id} value={item.section_id}>
                        {item.classname}
                      </option>
                    )
                  )
                )}
              </Form.Select>
            </Form.Group>
          </Col>
        </Row>

        {timeTableScreen === "VIEW" && (
          <Row className="mt-5 mx-2">
            <Col lg={12}>
              <PrintClassTimeTable
                timeTableSlots={timeTableSlots}
                changeView={changeView}
                showDeleteButton={true}
              />
            </Col>
          </Row>
        )}
        {console.log('timeTableSlots.timeSlotsResult@@!@@=>',timeTableSlots)}
        {timeTableScreen === "EDIT" && (
          <Row className="mt-5 mx-2">
            <Col lg={12}>
              <EditTimeTable
                timeTableSlots={timeTableSlots}
                sessionId={sessions[0]?.id}
                sectionId={sectionId}
                classId={classId}
                changeView={changeView}
                category={category}
              />
            </Col>
          </Row>
        )}
        <ToastContainer />
      </>
    </>
  );
};

export default TimeTable;