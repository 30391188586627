import React, { useEffect, useState } from "react";
import { Button, Col, Row, Table } from "react-bootstrap";
import schoolApi from "../../api/schoolApi";
import InfoPill from "../InfoPill";
import {
  DatatableWrapper,
  Filter,
  Pagination,
  PaginationOptions,
  TableBody,
  TableHeader,
} from "react-bs-datatable";
import PageNavigations from "../breadcrumbs/PageNavigations";
import Confirm from "../Confirm";
import CreateAssignmentModal from "./CreateAssignmentModal";
import { Link } from "react-router-dom";
import { usePermissions } from "../contexts/PermissionContext";
import fileDownload from "js-file-download";
import { saveAs } from "file-saver";
import moment from "moment";
import { ToastContainer, toast } from "react-toastify";
import { Helmet } from "react-helmet";          //added by muskan khan
const AssignmentList = (props) => {
  console.log("props AssignmentList ", props);
  const { rolePermissions, userInfo } = usePermissions();
  const [selectedRowFileid, setSelectedRowFileid] = useState();
  console.log("rolePermissions class list=======>", rolePermissions);
  const [body, setBody] = useState([]);
  const [modalShow, setModalShow] = useState(false);
  const [assignData, setAssignData] = useState();
  const [btnName, setBtnName] = useState("Save");
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [rowDataId, setRowDataId] = useState();
  const canCreate = rolePermissions?.Assignment_List?.can_create;
  const canEdit = rolePermissions?.Assignment_List?.can_edit;
  const canDelete = rolePermissions?.Assignment_List?.can_delete;

  useEffect(() => {
    fetchAllAssignments();
  }, []);

  //--- Updated by mohd. sarfraj | 25-sept-2024 ---
  async function fetchAllAssignments() {
    try {
      const result = await schoolApi.getAllAssignment();
      if (result.success) {
        result?.result?.forEach((element) => {
          if (element.due_date) {
            element.due_date = moment(element.due_date).format("DD-MM-YYYY");
            element.assign_date = moment(element.assign_date).format(
              "DD-MM-YYYY"
            );
          }
        });
        setBody(result.result);
      } else {
        setBody([]);
      }
    } catch (error) {
      console.log("error-->>", error);
      //toast.error(error.response.data.message);
    }
  }

  const handleEditButton = (row) => {
    setModalShow(true);
    setAssignData(row);
    setBtnName("Update");
  };

  const handleDeleteButton = (row) => {
    setShowDeleteModal(true);
    setRowDataId(row.id);
    setSelectedRowFileid(row.file_id);
  };

  const downloadFile = async (row) => {
    console.log("row===>", row);
    try {
      const result = await schoolApi.downloadFiles(row.file_id);
      console.log("fileDownload======>", result);
      if (result) {
        const blob = new Blob([result], { type: result.type });
        saveAs(blob, row.title);
      }
    } catch (error) {
      console.error("Error downloading file:", error);
    }
  };

  const header = [
    { title: "Class Name", prop: "class", isFilterable: true, show: true },
    {
      title: "Subject Name",
      prop: "subject_name",
      isFilterable: true,
      show: true,
    },
    {
      title: "Assign Date",
      prop: "assign_date",
      isFilterable: true,
      show: true,
    },
    { title: "Due Date", prop: "due_date", isFilterable: true, show: true },
    { title: "Title", prop: "title", isFilterable: true, show: true },
    { title: "Status", prop: "status", isFilterable: true, show: true },
    {
      title: "Action",
      prop: "",
      isFilterable: true,
      show: true,
      cell: (row) => (
        <div>
          {(userInfo?.userrole === "SYS_ADMIN" ||
            userInfo?.userrole === "ADMIN" ||
            canEdit) && (
            <button
              className="btn btn-sm edit-button mx-2"
              onClick={() => handleEditButton(row)}
            >
              <i className="fa-regular fa-pen-to-square"></i>
            </button>
          )}
          {(userInfo?.userrole === "SYS_ADMIN" ||
            userInfo?.userrole === "ADMIN" ||
            canDelete) && (
            <button
              className="btn btn-sm btn-danger mx-2"
              onClick={() => handleDeleteButton(row)}
            >
              <i className="fa fa-trash"></i>
            </button>
          )}
          {(userInfo?.userrole === "SYS_ADMIN" ||
            userInfo?.userrole === "ADMIN" ||
            canDelete) && (
            <Button
              className="btn-sm mx-2"
              variant="danger"
              disabled={row.file_id == null}
              onClick={() => downloadFile(row)}
            >
              <i className="fa fa-download"></i>
            </Button>
          )}
        </div>
      ),
    },
  ];
  const visibleHeader = header?.filter((col) => col.show);

  const labels = {
    beforeSelect: " ",
  };

  const createAssignment = () => {
    setModalShow(true);
    setAssignData("");
    setBtnName("Save");
  };

  const handleCloseModal = () => {
    setModalShow(false);
  };

  //--- Updated by mohd. sarfraj | 25-sept-2024 ---
  const handleDeleteAssignmentRecord = async () => {
    if (rowDataId) {
      try {
        const result = await schoolApi.deleteAssignment(rowDataId);

        if (result.success) {
          //----- Added condition by mohd. sarfraj | 25-sept-2024.  ------
          if (selectedRowFileid) {
            const fileResult = await schoolApi.deleteFile(selectedRowFileid);
            if (fileResult.success) {
              toast.success("Record & File deleted successfully");
            }
          } else {
            toast.success("Record deleted successfully");
          }
          const deleteAssignment = body.filter(
            (rteRec) => rteRec.id !== rowDataId
          );
          setBody(deleteAssignment);
          setShowDeleteModal(false);
        } else {
          console.error("deletion was not successfull", result);
        }
      } catch (error) {
        console.error("Errror deleteing ", error);
      }
    }
  };

  return (
    <>
     <Helmet>
    <title>{props?.tabName}</title>
  </Helmet>
      {showDeleteModal && (
        <Confirm
          show={showDeleteModal}
          onHide={() => setShowDeleteModal(false)}
          handleDeleteButton={() => handleDeleteAssignmentRecord()}
          title="Confirm delete?"
          message="You are going to delete the record. Are you sure?"
          table="deleteAssignment"
        />
      )}

      <CreateAssignmentModal
        modalShow={modalShow}
        handleCloseModal={handleCloseModal}
        fetchAllAssignments={fetchAllAssignments}
        assignData={assignData}
        btnName={btnName}
      />

      <ToastContainer
        position="top-right"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />

      {/* <Helmet><title>{props?.tabName}</title> </Helmet>
     <PageNavigations   colLg={2} colClassName="d-flex mx-4 " extrColumn={12}/> */}
      <Col lg={2} className="mx-2">
        <Link className="nav-link" to="/">
          Home <i className="fa-solid fa-chevron-right"></i> Assignments
        </Link>
      </Col>

      <Row className="g-0">
        <Col lg={12} className="px-lg-4">
          {/* {body ? */}
          <DatatableWrapper
            body={body}
            headers={visibleHeader}
            paginationOptionsProps={{
              initialState: {
                rowsPerPage: 15,
                options: [5, 10, 15, 20],
              },
            }}
          >
            <Row className="mb-4">
              <Col
                xs={12}
                lg={3}
                className="d-flex flex-col justify-content-end align-items-end"
              >
                <Filter />
              </Col>
              <Col
                xs={12}
                sm={6}
                lg={5}
                className="d-flex flex-col justify-content-start align-items-start"
              >
                <PaginationOptions labels={labels} />
                <div style={{ marginTop: "5px" }}>
                  <InfoPill left="Total Assignments" right={body?.length} />
                </div>
              </Col>
              <Col
                xs={12}
                sm={6}
                lg={4}
                className="d-flex flex-col justify-content-end align-items-end"
              >
                {(userInfo?.userrole === "SYS_ADMIN" ||
                  userInfo?.userrole === "ADMIN" ||
                  canCreate) && (
                  <Button
                    className="btn-light add-new-button"
                    style={{ color: "black" }}
                    variant=""
                    onClick={() => createAssignment()}
                  >
                    New Assignment
                  </Button>
                )}
              </Col>
            </Row>
            <Table striped className="data-table">
              <TableHeader />
              {body.length > 0 ? (
                <TableBody />
              ) : (
                <TableBody>
                  <tr>
                    <td colSpan={6} className="text-center">
                      No Records Found!
                    </td>
                  </tr>
                </TableBody>
              )}
            </Table>
            <Pagination />
          </DatatableWrapper>
        </Col>
        <Col lg={2}></Col>
      </Row>
    </>
  );
};

export default AssignmentList;

// import React, { useEffect, useState } from "react";
// import { Button, Col, Row, Table } from "react-bootstrap";
// import schoolApi from "../../api/schoolApi";
// import { ShimmerTable } from "react-shimmer-effects";
// import InfoPill from "../InfoPill";
// import { DatatableWrapper, Filter, Pagination, PaginationOptions, TableBody, TableHeader } from 'react-bs-datatable';

// import { Helmet } from 'react-helmet';
// import PageNavigations from "../breadcrumbs/PageNavigations";
// import Confirm from "../Confirm";
// import CreateAssignmentModal from "./CreateAssignmentModal";
// import { Link } from "react-router-dom";
// import PubSub from "pubsub-js";
// import { usePermissions } from "../contexts/PermissionContext";
// import fileDownload from 'js-file-download';
// import { saveAs } from 'file-saver';
// import moment from "moment";

// const AssignmentList = (props) => {
//   console.log('props AssignmentList ', props)
//   const { rolePermissions, userInfo } = usePermissions();
//   const [selectedRowFileid, setSelectedRowFileid] = useState();
//   console.log('rolePermissions class list=======>', rolePermissions);
//   const [body, setBody] = useState();
//   const [modalShow, setModalShow] = useState(false);
//   const [assignData, setAssignData] = useState();
//   const [btnName, setBtnName] = useState('Save');
//   const [showDeleteModal, setShowDeleteModal] = useState(false);
//   const [rowDataId, setRowDataId] = useState();
//   const canCreate = rolePermissions?.Assignment_List?.can_create
//   const canEdit = rolePermissions?.Assignment_List?.can_edit
//   const canDelete = rolePermissions?.Assignment_List?.can_delete

//   useEffect(() => {
//     fetchAllAssignments();
//   }, []);

//   async function fetchAllAssignments() {
//     const result = await schoolApi.getAllAssignment();
//     console.log('result ----------->', result);
//     if (result) {
//       result.forEach(element => {
//         element.date = moment(element.date, "YYYY-MM-DD").format("DD-MM-YYYY");
//       });
//       setBody(result);
//     } else {
//       setBody([]);
//     }
//   }

//   const handleEditButton = (row) => {
//     setModalShow(true);
//     setAssignData(row);
//     setBtnName('Update')
//   }

//   const handleDeleteButton = (row) => {
//     setShowDeleteModal(true)
//     setRowDataId(row.id)
//     setSelectedRowFileid(row.file_id)
//   }

//   const downloadFile = async (row) => {
//     console.log('row===>', row);
//     try {
//       const result = await schoolApi.downloadFiles(row.file_id);
//       console.log('fileDownload======>', result);
//       if (result) {
//         const blob = new Blob([result], { type: result.type });
//         saveAs(blob, row.title);
//       }
//     } catch (error) {
//       console.error('Error downloading file:', error);
//     }
//   };

//   const header = [
//     { title: 'Class Name', prop: 'class', isFilterable: true, show: true, },
//     { title: 'Subject Name', prop: 'subject_name', isFilterable: true, show: true, },
//     { title: 'Date', prop: 'date', isFilterable: true, show: true, },
//     { title: 'Title', prop: 'title', isFilterable: true, show: true, },
//     { title: 'Status', prop: 'status', isFilterable: true, show: true, },
//     {
//       title: 'Action', prop: '', isFilterable: true, show: true, cell: (row) => (
//         <div>
//           {(userInfo?.userrole === "SYS_ADMIN" || userInfo?.userrole === "ADMIN" || canEdit) &&
//             <button className="btn btn-sm edit-button mx-2" onClick={() => handleEditButton(row)}>
//               <i className="fa-regular fa-pen-to-square"></i>
//             </button>}
//           {(userInfo?.userrole === "SYS_ADMIN" || userInfo?.userrole === "ADMIN" || canDelete) &&
//             <button className="btn btn-sm btn-danger mx-2" onClick={() => handleDeleteButton(row)}>
//               <i className="fa fa-trash"></i>
//             </button>}
//           {(userInfo?.userrole === "SYS_ADMIN" || userInfo?.userrole === "ADMIN" || canDelete) &&
//             <Button className='btn-sm mx-2'
//               variant='danger'
//               disabled={row.file_id == null}
//               onClick={() => downloadFile(row)} ><i className="fa fa-download"></i></Button>}
//         </div>
//       )
//     },
//   ];
//   const visibleHeader = header?.filter(col => col.show);

//   const labels = {
//     beforeSelect: " "
//   }

//   const createAssignment = () => {
//     setModalShow(true);
//     setAssignData('')
//     setBtnName('Save')
//   }

//   const handleCloseModal = () => {
//     setModalShow(false);
//   }

//   const handleDeleteAssignmentRecord = async () => {
//     if (rowDataId) {
//       try {
//         const result = await schoolApi.deleteAssignment(rowDataId);
//         if (result && result.message === "Successfully Deleted") {
//           const result = await schoolApi.deleteFile(selectedRowFileid);
//           const deleteAssignment = body.filter(rteRec => rteRec.id !== rowDataId);
//           setBody(deleteAssignment)
//           setShowDeleteModal(false)
//           //Add By Aamir khan only publish list add
//           PubSub.publish('RECORD_SAVED_TOAST', {
//             title: 'Record Deleted',
//             message: 'Record Deleted successfully'
//           });
//         } else {
//           console.error('deletion was not successfull', result)
//         }
//       } catch (error) {
//         console.error("Errror deleteing ", error);
//       }
//     }
//   }

//   return (
//     <>

//       {showDeleteModal &&
//         <Confirm
//           show={showDeleteModal}
//           onHide={() => setShowDeleteModal(false)}
//           handleDeleteButton={() => handleDeleteAssignmentRecord()}
//           title="Confirm delete?"
//           message="You are going to delete the record. Are you sure?"
//           table="deleteAssignment"
//         />}

//       <CreateAssignmentModal
//         modalShow={modalShow}
//         handleCloseModal={handleCloseModal}
//         fetchAllAssignments={fetchAllAssignments}
//         assignData={assignData}
//         btnName={btnName}
//       />

//       {/* <Helmet><title>{props?.tabName}</title> </Helmet>
//      <PageNavigations   colLg={2} colClassName="d-flex mx-4 " extrColumn={12}/> */}
//       <Col lg={2} className="mx-2">
//         <Link className="nav-link" to="/">Home <i className="fa-solid fa-chevron-right"></i> Assignments</Link>
//       </Col>

//       <Row className="g-0">
//         <Col lg={12} className="px-lg-4">

//           {body ?
//             <DatatableWrapper body={body} headers={visibleHeader} paginationOptionsProps={{
//               initialState: {
//                 rowsPerPage: 15,
//                 options: [5, 10, 15, 20]
//               }
//             }}>
//               <Row className="mb-4">
//                 <Col
//                   xs={12}
//                   lg={3}
//                   className="d-flex flex-col justify-content-end align-items-end"
//                 >
//                   <Filter />

//                 </Col>
//                 <Col
//                   xs={12}
//                   sm={6}
//                   lg={5}
//                   className="d-flex flex-col justify-content-start align-items-start"
//                 >
//                   <PaginationOptions labels={labels} />
//                   <div style={{ "marginTop": "5px" }}>
//                     <InfoPill left="Total Assignments" right={body?.length} />
//                   </div>
//                 </Col>
//                 <Col
//                   xs={12}
//                   sm={6}
//                   lg={4}
//                   className="d-flex flex-col justify-content-end align-items-end"
//                 >
//                   {(userInfo?.userrole === "SYS_ADMIN" || userInfo?.userrole === "ADMIN" || canCreate) &&
//                     <Button className="btn-light add-new-button" style ={{color : "black"}}
//                     variant="outline-primary" onClick={() => createAssignment()}>
//                       New Assignment
//                     </Button>}
//                 </Col>
//               </Row>
//               <Table striped className="data-table">
//                 <TableHeader />
//                 <TableBody />
//               </Table>
//               <Pagination />
//             </DatatableWrapper> : <ShimmerTable row={10} col={8} />}
//         </Col>
//         <Col lg={2}></Col>
//       </Row>
//     </>
//   );
// };

// export default AssignmentList;
