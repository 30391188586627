/* eslint-disable react-hooks/exhaustive-deps */
/**
 * @author: Abdul Pathan
 */
import React, { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import { Col, Container, Form, Row } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import schoolApi from "../../api/schoolApi";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer, toast } from "react-toastify";

const AddClass = (props) => {
  console.log("props======>", props);

  const [rowRecord, setRowRecord] = useState({
    classname: "",
    category : "",
    aliasname: "",
    status: "active", //added by muskan khan
  });

  useEffect(() => {
    if (props?.parent?.class_id) {
      setRowRecord(props?.parent);
    }
  }, []);

  const handleChange = (e) => {
    setRowRecord({ ...rowRecord, [e.target.name]: e.target.value });
  
  
  };

  const handleSubmit = async (e) => {
    try {
      
    e.preventDefault();
    if (props?.parent?.class_id) {
      console.log("props?.parent?.id==========>", props?.parent?.id);
      const editRecord = {
        id: props?.parent?.class_id,
        category: rowRecord.category,
        classname: rowRecord.classname,
        aliasname: rowRecord.aliasname,
        status: rowRecord.status,
      };

      if (
        editRecord.status &&
        editRecord.status.trim() !== "" &&
        editRecord.classname &&
        editRecord.classname.trim() !== ""
      ) {
        let response = {};
        response = await schoolApi.updateClassRecord(editRecord);
        if (response.success) {
          toast.success("Record Updated Succefully");
          recordSaveSuccesfully();
        } 

      } else {
        toast.error("Missing required fields");
        if(rowRecord?.status === ""){
          toast.error("Please Select Status")
        }
      }
    } else {
      if (rowRecord.classname && rowRecord.classname.trim() !== "" && rowRecord.status && rowRecord.status.trim() !== "") {
        console.log("rowRecord", rowRecord);
        let response = await schoolApi.addClassRecord(rowRecord);
        console.log("response", response);
        if (response.success) {
          toast.success("Record saved successfully!");
          recordSaveSuccesfully();
        } 
      } else {
        toast.error("Missing required fields");
      }
    }
  } catch (error) {
    toast.error(error.response.data.message);
  }
  };
  const recordSaveSuccesfully = () => {
    props.recordSaveSuccesfully();
  };

  return (
    <Modal
      show={props.show}
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton onClick={props.onHide}>
        <Modal.Title id="contained-modal-title-vcenter">
          Class Record
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Container className="view-form">
          <Row>
            <Col lg={12}>
              <Form noValidate>
                <Row className="pb-4">
                  <Col lg={6}>
                    <Form.Group>
                      <Form.Label 
                        className="form-view-label"
                        htmlFor="formBasicFirstName"
                      >
                        Class Name
                      </Form.Label>
                      <Form.Control
                        required
                        type="text"
                        name="classname"
                        placeholder="--Class Name--"
                        value={rowRecord.classname}
                        onChange={handleChange}
                      />
                    </Form.Group>
                  </Col>
                  <Col lg={6}>
                                        <Form.Group >
                                        <Form.Label className="form-view-label">Category</Form.Label>
                                        <Form.Select
                                            required
                                            name="category"
                                            onChange={handleChange}
                                            value={rowRecord.category}
                                            //disabled={location.pathname === "/driver/e"}
                                        >
                                            <option value="">--Select Category--</option>
                                            <option value="Pre-Primary">Pre-Primary</option>
                                            <option value="Primary">Primary</option>
                                            <option value="Secondary">Secondary</option>
                                            <option value="Higher-Secondary">Higher-Secondary</option>
                                        </Form.Select>
                                        </Form.Group>
                                    </Col>
                  <Col lg={6}>
                    <Form.Group className="my-3">
                      <Form.Label
                        className="form-view-label"
                        htmlFor="formBasicFirstName"
                      >
                        Alias Name
                      </Form.Label>
                      <Form.Control
                        type="text"
                        name="aliasname"
                        placeholder="--Alias Name--"
                        value={rowRecord.aliasname}
                        onChange={handleChange}
                      />
                    </Form.Group>
                  </Col>
                  <Col lg={6}>
                    <Form.Group>
                      <Form.Label
                        className="form-view-label"
                        htmlFor="formBasicFirstName"
                      >
                        Status
                      </Form.Label>
                      <Form.Select
                        required
                        name="status"
                        value={rowRecord.status}
                        onChange={handleChange}
                      >
                        <option value="">--None--</option>
                        <option value="active" aria-selected>
                          Active
                        </option>
                        <option value="inactive">InActive</option>
                      </Form.Select>
                    </Form.Group>
                  </Col>
                </Row>
              </Form>
            </Col>
          </Row>
        </Container>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="success" onClick={handleSubmit}>
          {props?.parent?.id ? "Update" : "Save"}
        </Button>
        <Button onClick={props.onHide} variant="light">
          Close
        </Button>
      </Modal.Footer>
      <ToastContainer
          position="top-right"
          autoClose={2000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
        />
    </Modal>
  );
};

export default AddClass;