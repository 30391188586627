/**
 * @author      Abdul Pathan
 * @date        Aug, 2024
 * @copyright   www.ibirdsservices.com
 */
import React, { useEffect, useState } from "react";
import EmojiPicker from "emoji-picker-react"; // Import the emoji picker
import "../../../App.css";

import {
  Button,
  Card,
  Col,
  Container,
  Form,
  Image,
  Row,
} from "react-bootstrap";
import WhatsAppAPI from "../../../api/schoolApi";
import { ToastContainer, toast } from "react-toastify"; // npm i react-toastify --force
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";

const MarketingTemplate = ({ previewData }) => {
  const initialFormData = {
    id: "",
    name: "",
    templatename: "",
    language: "",
    status: "",
    category: "",
    header: "",
    header_text: "",
    header_image_url: "",
    header_document_url: "",
    header_video_url: "",
    message_body: "",
    message_body_example: [],
    footer: "",
    buttons: [],
  };

  const navigate = useNavigate();
  const [isSending, setIsSending] = useState(false);
  const [isSpinner, setIsSpinner] = useState(false);
  const [rowData, setRowData] = useState(initialFormData);
  const [buttons, setButtons] = useState([]);
  // file
  const [selectedFiles, setSelectedFiles] = useState();
  const [errorMessage, setErrorMessage] = useState("");
  const [selectedImage2, setSelectedImage2] = useState();
  const [selectedDocument2, setSelectedDocument2] = useState();
  const [selectedVideo2, setSelectedVideo2] = useState();
  const [showbody, setshowbody] = useState(false);
  console.log("MarketingTemplate previewData", previewData);
  const [template, setTemplate] = useState("");
  const [showEmojiPicker, setShowEmojiPicker] = useState(false);

  const handleInputChange = (e) => {
    // setTemplate(e.target.value);
    setRowData((prevData) => ({
      ...prevData,
      message_body: e.target.value,
    }));
  };

  // const addVariable = () => {
  //   setTemplate(template + " {{}}"); // Add an empty variable placeholder
  // };
  function formatMessage(message) {
    message = message.replace(/\*\*(.*?)\*\*/g, "<b>$1</b>");
    message = message.replace(/_(.*?)_/g, "<i>$1</i>");
    message = message.replace(/~(.*?)~/g, "<s>$1</s>");
    message = message.replace(/`(.*?)`/g, "<code>$1</code>");
    message = message.replace(/\n/g, "<br/>");
    return message;
  }

  const applyFormatting = (format) => {
    const selectedText = window.getSelection().toString();
    const formattedText =
      format === "bold"
        ? `**${selectedText}**`
        : format === "italic"
        ? `_${selectedText}_`
        : format === "strikethrough"
        ? `~${selectedText}~`
        : `\`${selectedText}\``;
    setRowData((prevData) => ({
      ...prevData,
      message_body: rowData.message_body.replace(selectedText, formattedText),
    }));
  };
  const handleEmojiClick = (emojiObject) => {
    console.log("Emoji object: ", emojiObject);

    // const emojiToAdd =
    //   emojiObject.emoji !== "🥸" ?emojiObject.emoji:
    //   `<img src="${emojiObject.imageUrl}" alt="${emojiObject.names[0]}" style="width: 24px; height: 24px;" />`;

    const emojiToAdd = emojiObject.emoji;
    setRowData((prevData) => ({
      ...prevData,
      message_body: prevData.message_body + emojiToAdd,
    }));

    // Close the emoji picker
    setShowEmojiPicker(false);
  };

  useEffect(() => {
    if (previewData && previewData?.category === "MARKETING") {
      // Assuming previewData.example_body_text contains an array of example values
      const exampleBodyText = previewData.example_body_text[0] || []; // Extract the first example (if exists)

      // Determine the maximum number of placeholders by checking the length of exampleBodyText
      const expectedMaxPlaceholder = exampleBodyText.length;

      // Map the example values to the placeholders
      const updatedExamples = Array.from(
        { length: expectedMaxPlaceholder },
        (_, index) => ({
          placeholder: index + 1,
          value: exampleBodyText[index] || "", // Map the value for each placeholder or set as empty string
        })
      );

      // Set the updated example values back into rowData
      setRowData((prevData) => ({
        ...prevData,
        message_body: rowData.message_body,
        message_body_example: updatedExamples,
      }));

      // Ensure previewData is defined before logging
      if (previewData && previewData.example_body_text) {
        console.log("previewData -<", previewData.example_body_text);
      } else {
        console.log("previewData or example_body_text is undefined.");
      }

      const buttons =
        previewData?.buttons && previewData.buttons.length > 0
          ? previewData.buttons.map((button) => ({
              type: button.type,
              text: button.text,
              ...(button.type === "PHONE_NUMBER" && {
                phone_number: button.phone_number,
              }),
              ...(button.type === "URL" && { url: button.url }),
            }))
          : [];

      setRowData({
        id: previewData?.id || "",
        name: previewData?.templatename || "",
        language: previewData?.language || "",
        status: previewData?.status || "",
        category: previewData?.category || "",
        header: previewData?.header || "",
        header_text: previewData?.header_text || "",
        header_image_url: previewData?.header_image_url || "",
        header_document_url: previewData?.header_document_url || "",
        header_video_url: previewData?.header_video_url || "",
        message_body: previewData?.message_body || "",
        footer: previewData?.footer || "",
        buttons,
        message_body_example: updatedExamples || [],
      });

      setButtons(buttons);
      setSelectedImage2(previewData?.header_image_url || null);
      setSelectedDocument2(previewData?.header_document_url || null);
      setSelectedVideo2(previewData?.header_video_url || null);
      // setSelectedFiles(previewData?.header_image_url || null)
    } else {
      setRowData(initialFormData);
      setButtons([]);
      setSelectedFiles(null);
    }
  }, [previewData]);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setRowData((prevData) => ({ ...prevData, [name]: value }));
    if (name === "header") {
      setSelectedFiles(null);
      setSelectedImage2(null);
      setSelectedDocument2(null);
      setSelectedVideo2(null);
    }
  };

  const handleFileChange = (event) => {
    const { name, files } = event.target;
    const file = files[0];

    if (!file) return;

    const fileTypeMappings = {
      header_image_url: ["jpg", "jpeg", "png"],
      header_document_url: ["pdf"],
      header_video_url: ["mp4"],
    };

    const validExtensions = fileTypeMappings[name];
    if (!validExtensions.includes(file.name.split(".").pop().toLowerCase())) {
      setErrorMessage(`Only ${validExtensions.join(", ")} files are allowed.`);
      event.target.value = "";
      return;
    }

    setErrorMessage("");
    switch (name) {
      case "header_image_url":
        setSelectedImage2(null);
        setSelectedFiles(file);
        break;
      case "header_document_url":
        setSelectedDocument2(null);
        setSelectedFiles(file);
        break;
      case "header_video_url":
        setSelectedVideo2(null);
        setSelectedFiles(file);
        break;
      default:
        break;
    }
  };
  const addVariable = () => {
    console.log(
      "rowData.message_body_example ->",
      rowData?.message_body_example?.length || 0
    );
    const existingExamples = rowData?.message_body_example || [];
    console.log(existingExamples);

    const newExample = {
      placeholder: existingExamples.length + 1,
      value: "",
    };
    const updatedExamples = [...existingExamples, newExample];
    setRowData((prevData) => ({
      ...prevData,
      message_body_example: updatedExamples,
      message_body:
        `${prevData?.message_body} {{${existingExamples.length + 1}}} ` || "",
    }));
  };
  useEffect(() => {
    if (!rowData?.message_body) return;

    // Extract unique placeholders from message_body
    const placeholders = Array.from(
      new Set(rowData.message_body.match(/{{\d+}}/g) || [])
    ).map((val) => parseInt(val.match(/\d+/)[0]));
    console.log("placeholders->", placeholders);

    if (placeholders.length > 0) {
      placeholders.sort((a, b) => a - b);

      const maxPlaceholder = Math.max(...placeholders);
      const expectedMaxPlaceholder = placeholders.length;

      if (maxPlaceholder !== expectedMaxPlaceholder) {
        const placeholderMap = new Map(
          placeholders.map((val, index) => [val, index + 1])
        );
        console.log("rowData.message_body ->", rowData.message_body);
        console.log("placeholders ->", placeholders);
        console.log("placeholderMap ->", placeholderMap);

        rowData.message_body = rowData.message_body.replace(
          /{{\d+}}/g,
          (match) =>
            `{{${placeholderMap.get(parseInt(match.match(/\d+/)[0]))}}}`
        );
      }
      console.log("rowData.message_body ->", rowData.message_body);
      console.log("placeholders ->", placeholders);

      const existingExamples = rowData.message_body_example || [];
      const exampleMap = existingExamples.reduce((map, example) => {
        map[example.placeholder] = example.value;
        return map;
      }, {});

      const updatedExamples = Array.from(
        { length: expectedMaxPlaceholder },
        (_, index) => ({
          placeholder: index + 1,
          value: exampleMap[index + 1] || "",
        })
      );

      setRowData((prevData) => ({
        ...prevData,
        message_body: rowData.message_body,
        message_body_example: updatedExamples,
      }));

      setshowbody(true);
    } else {
      setshowbody(false);
    }
  }, [rowData?.message_body]);
  console.log("rowData.message_body_example =>", rowData?.message_body_example);

  const handleSubmit = async (event) => {
    event.preventDefault();
    console.log("##row Data, ", rowData);
    console.log("##selectedFiles", selectedFiles);
    console.log("##buttons", buttons);

    if (rowData.header === "TEXT" && !rowData.header_text.trim()) {
      toast.error("Template header text is required.");
      return;
    }

    if (rowData.header === "IMAGE" && !selectedFiles && !selectedImage2) {
      toast.error("Upload image is required.");
      return;
    }
    if (rowData.header === "DOCUMENT" && !selectedFiles && !selectedDocument2) {
      toast.error("Upload document is required.");
      return;
    }
    if (rowData.header === "VIDEO" && !selectedFiles && !selectedVideo2) {
      toast.error("Upload video mp4 is required.");
      return;
    }

    if (buttons.length > 0) {
      for (const button of buttons) {
        if (
          button.type === "URL" &&
          (!button?.text.trim() || !button?.url.trim())
        ) {
          toast.error("Please fill in all fields for the URL button.");
          return;
        }
        if (
          button.type === "PHONE_NUMBER" &&
          (!button?.text.trim() || !button?.phone_number.trim())
        ) {
          toast.error("Please fill in all fields for the Phone Number button.");
          return;
        }
      }
    }
    if (rowData?.message_body_example?.length > 0) {
      const hasEmptyValues = rowData.message_body_example.some(
        (example) => !example.value.trim()
      );
      if (hasEmptyValues) {
        toast.error("Please fill all example values.");
        return;
      }
    }

    let imageId = "";

    if (selectedFiles) {
      console.log("object", selectedFiles);
      const sessionId = await WhatsAppAPI.generateSessionId(selectedFiles);
      //console.log("sessionId", sessionId);
      //const sessionId = null;
      if (sessionId.id) {
        const document = await WhatsAppAPI.uploadDocumentSessionId(
          selectedFiles,
          sessionId.id
        );
        imageId = document.h;
        console.log("uploaded id", document);
      }
    }

    const formattedName = rowData.name
      .toLowerCase()
      .replace(/[^a-z0-9]+/g, "_")
      .replace(/^_+|_+$/g, "");
    const placeholders = rowData.message_body.match(/{{\d+}}/g) || [];
    console.log("placeholders ->", placeholders);

    const reqBody = {
      name: formattedName,
      language: rowData.language,
      category: "MARKETING",
      components: [
        {
          type: "HEADER",
          format: rowData.header,
          ...(rowData.header === "TEXT" && { text: rowData.header_text }),
          ...(rowData.header === "IMAGE" && {
            example: { header_handle: [imageId || rowData?.header_image_url] },
          }),
          ...(rowData.header === "DOCUMENT" && {
            example: {
              header_handle: [imageId || rowData?.header_document_url],
            },
          }),
          ...(rowData.header === "VIDEO" && {
            example: { header_handle: [imageId || rowData?.header_video_url] },
          }),
        },
        // {
        //   type: "BODY",
        //   text: rowData?.message_body,
        // },
        // {
        //   type: "BODY",
        //   text: rowData?.message_body,
        //   ...(rowData.message_body.includes("{{1}}") && {
        //     example: {
        //       body_text: [["shivani mehra"]],
        //     },
        //   }),
        // },
        {
          type: "BODY",
          text: rowData?.message_body,
          ...(rowData?.message_body_example?.length > 0 && {
            example: {
              body_text: [
                rowData?.message_body_example.map((example) => example.value),
              ],
            },
          }),
        },
        {
          type: "FOOTER",
          text: rowData?.footer || "",
        },
        ...(buttons.filter((button) => button.text && button.type).length > 0
          ? [
              {
                type: "BUTTONS",
                buttons: buttons
                  .filter((button) => button.text && button.type)
                  .map((button) => ({
                    type: button.type,
                    text: button.text,
                    ...(button.type === "PHONE_NUMBER" && {
                      phone_number: button.phone_number,
                    }),
                    ...(button.type === "URL" && { url: button.url }),
                  })),
              },
            ]
          : []),
      ],
    };

    console.log("##### reqBody :", reqBody);

    try {
      setIsSpinner(true);
      let result;
      if (rowData?.id) {
        result = await WhatsAppAPI.updateMarketingTemplate(rowData.id, reqBody);
      } else {
        result = await WhatsAppAPI.createMarketingTemplate(reqBody);
      }

      console.log("###result", result);

      if (result.error) {
        // const errorResponse = result;
        // const jsonPart = errorResponse.error.split("Bad Request - ")[1];
        // const parsedError = JSON.parse(jsonPart);
        // toast.error(JSON.parse(parsedError.error));
        const errorJson = result.error.split(" - ")[1];
        const parsedError1 = JSON.parse(errorJson);
        const errorMessage =
          parsedError1.error.error_user_msg || parsedError1.error.message;
        toast.error(errorMessage);
      } else {
        toast.success(
          rowData?.id
            ? "Template updated successfully."
            : "Template created successfully."
        );
        setIsSpinner(false);
        navigate("/Templates");
      }
    } catch (error) {
      toast.error("An unexpected error occurred.");
    } finally {
      setIsSending(false);
      setIsSpinner(false);
    }
  };

  const handleBack = () => {
    navigate("/Templates");
  };

  const isFormValid =
    Boolean(rowData.name.trim()) &&
    Boolean(rowData.language) &&
    Boolean(rowData.message_body.trim()) &&
    Boolean(rowData.footer.trim());

  const handleAddButton = () => {
    if (buttons.length < 2) {
      const newButton = { type: "", text: "", PHONE_NUMBER: "", url: "" };
      setButtons([...buttons, newButton]);
    } else {
      toast.error("Maximum of 2 buttons can be added.");
    }
  };

  const handleRemoveButton = (index) => {
    const updatedButtons = buttons.filter((_, i) => i !== index);
    setButtons(updatedButtons);
  };

  const handleButtonChange = (index, field, value) => {
    const updatedButtons = buttons.map((button, i) => {
      if (i === index) {
        return { ...button, [field]: value };
      }
      return button;
    });
    setButtons(updatedButtons);
  };

  const getDisabledOptions = (currentType) => {
    return buttons.some((button) => button.type === currentType);
  };

  return (
    <>
      {!isSpinner ? (
        <>
          <Container className="mt-1">
            <Row className="mx-5 g-0">
              <Col lg={12} sm={12} xs={12}>
                <Card className="h-100" style={{ border: "none" }}>
                  <Card.Body>
                    <Row>
                      <Col lg={12} sm={12} xs={12}>
                        <Form.Group
                          className="mx-2 mb-3"
                          // //controlId="formBasicName"
                        >
                          <Form.Label
                            className="form-view-label"
                            htmlFor="formBasicName"
                          >
                            <b>Template name and language</b>
                          </Form.Label>
                        </Form.Group>
                      </Col>
                    </Row>

                    <Row className="mb-3">
                      <Col lg={6} sm={12} xs={12}>
                        <Form.Group
                          className="mx-2 mb-3"
                          // //controlId="formBasicTemplateName"
                        >
                          <Form.Label
                            className="form-view-label"
                            htmlFor="formBasicTemplateName"
                          >
                            Template Name
                          </Form.Label>
                          <Form.Control
                            disabled={rowData?.id}
                            type="text"
                            name="name"
                            value={rowData?.name}
                            onChange={handleChange}
                            placeholder="Enter template name"
                            required
                          />
                        </Form.Group>
                      </Col>

                      <Col lg={6} sm={12} xs={12}>
                        <Form.Group className="mb-2 mx-3">
                          <Form.Label
                            className="form-view-label"
                            htmlFor="formBasicLanguage"
                          >
                            Language
                          </Form.Label>
                          <Form.Select
                            // disabled={rowData?.id}
                            aria-label="select language"
                            name="language"
                            value={rowData?.language}
                            onChange={handleChange}
                            required
                          >
                            <option value="">Select Language</option>
                            <option value="en">English</option>
                            <option value="en_US">English (US)</option>
                            <option value="en_GB">English (UK)</option>
                            <option value="hi">Hindi</option>
                            <option value="ur">Urdu</option>
                          </Form.Select>
                        </Form.Group>
                      </Col>
                    </Row>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </Container>

          <Container className="mt-1">
            <Row className="mx-5 mb-5 g-0">
              <Col lg={12} sm={12} xs={12} className="mb-2">
                <Card className="h-100" style={{ border: "none" }}>
                  <Card.Body>
                    <Row>
                      <Col lg={6} sm={6} xs={6}>
                        <Row>
                          <Col lg={12} sm={12} xs={12}>
                            <Form.Group className="mx-2 mb-3">
                              <Form.Label
                                className="form-view-label"
                                htmlFor="formBasicContent"
                              >
                                <b>Content </b> ( Fill in the header, body and
                                footer sections of your template. )
                              </Form.Label>
                            </Form.Group>
                          </Col>
                        </Row>

                        <Row className="mb-3">
                          <Col lg={6} sm={12} xs={12}>
                            <Form.Group className="mb-3 mx-3">
                              <Form.Label
                                className="form-view-label"
                                htmlFor="formBasicHeader"
                              >
                                Header
                              </Form.Label>
                              <Form.Select
                                aria-label="select language"
                                name="header"
                                value={rowData?.header}
                                onChange={handleChange}
                              >
                                <option value="">Select Header</option>
                                <option value="TEXT">Text</option>
                                <option value="IMAGE">Image</option>
                                <option value="DOCUMENT">Document</option>
                                <option value="VIDEO">Video</option>
                              </Form.Select>
                            </Form.Group>
                          </Col>

                          {rowData.header === "TEXT" && (
                            <Col lg={6} sm={12} xs={12}>
                              <Form.Group
                                className="mx-2 mb-3"
                                //controlId="formBasicHeaderText"
                              >
                                <Form.Label
                                  className="form-view-label"
                                  htmlFor="formBasicHeaderText"
                                >
                                  Header Text
                                </Form.Label>
                                <Form.Control
                                  type="text"
                                  name="header_text"
                                  value={rowData?.header_text}
                                  onChange={handleChange}
                                  placeholder="Enter text here..."
                                  required
                                />
                              </Form.Group>
                            </Col>
                          )}
                        </Row>

                        <Row className="mb-3">
                          <Col lg={6} sm={12} xs={12}>
                            {rowData.header === "IMAGE" && (
                              <>
                                <Form.Group
                                  className="mb-3 mx-3"
                                  //controlId="formFileImage"
                                >
                                  <Form.Label>Upload Image</Form.Label>
                                  <Form.Control
                                    required
                                    type="file"
                                    name="header_image_url"
                                    accept="image/*" // Allow only image files
                                    onChange={handleFileChange}
                                  />
                                  {errorMessage && (
                                    <Form.Text className="text-danger">
                                      {errorMessage}
                                    </Form.Text>
                                  )}
                                </Form.Group>
                                {selectedImage2 ? (
                                  <Image
                                    className="mx-3"
                                    variant="top"
                                    src={selectedImage2}
                                    thumbnail
                                    style={{ width: "15%" }}
                                  ></Image>
                                ) : (
                                  selectedFiles && (
                                    <Image
                                      className="mx-3"
                                      variant="top"
                                      src={URL.createObjectURL(selectedFiles)}
                                      thumbnail
                                      style={{ width: "15%" }}
                                    ></Image>
                                  )
                                )}
                              </>
                            )}

                            {rowData.header === "DOCUMENT" && (
                              <>
                                <Form.Group
                                  className="mb-3 mx-3"
                                  //controlId="formFileDocument"
                                >
                                  <Form.Label>Upload Document</Form.Label>
                                  <Form.Control
                                    required
                                    type="file"
                                    name="header_document_url"
                                    accept=".pdf" // Allow only PDF files
                                    onChange={handleFileChange}
                                  />
                                  {errorMessage && (
                                    <Form.Text className="text-danger">
                                      {errorMessage}
                                    </Form.Text>
                                  )}
                                </Form.Group>
                                {console.log(
                                  "selectedDocument2",
                                  selectedDocument2
                                )}
                                {selectedDocument2 ? (
                                  <Form.Text className="text-muted mx-2">
                                    <a
                                      href={selectedDocument2}
                                      target="_blank"
                                      rel="noopener noreferrer"
                                    >
                                      <i
                                        className="fa-solid fa-file-pdf mx-2"
                                        style={{ fontSize: "15px" }}
                                      ></i>
                                      View PDF
                                    </a>
                                  </Form.Text>
                                ) : (
                                  selectedFiles && (
                                    <Form.Text className="text-muted mx-2">
                                      <a
                                        href={URL.createObjectURL(
                                          selectedFiles
                                        )}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                      >
                                        <i
                                          className="fa-solid fa-file-pdf mx-2"
                                          style={{ fontSize: "15px" }}
                                        ></i>
                                        {selectedFiles.name}
                                      </a>
                                    </Form.Text>
                                  )
                                )}
                              </>
                            )}

                            {rowData.header === "VIDEO" && (
                              <>
                                <Form.Group
                                  className="mb-3 mx-3"
                                  //controlId="formFileVideo"
                                >
                                  <Form.Label>Upload Video</Form.Label>
                                  <Form.Control
                                    required
                                    type="file"
                                    name="header_video_url"
                                    accept="video/mp4" // Allow only MP4 videos
                                    onChange={handleFileChange}
                                  />
                                  {errorMessage && (
                                    <Form.Text className="text-danger">
                                      {errorMessage}
                                    </Form.Text>
                                  )}
                                </Form.Group>

                                {selectedVideo2 ? (
                                  <Image
                                    className="mx-3"
                                    variant="top"
                                    src={selectedVideo2}
                                    thumbnail
                                    style={{ width: "15%" }}
                                  ></Image>
                                ) : (
                                  selectedFiles && (
                                    <video
                                      controls
                                      className="mx-3"
                                      style={{ width: "100%" }}
                                    >
                                      <source
                                        src={URL.createObjectURL(selectedFiles)}
                                        type="video/mp4"
                                      />
                                      Your browser does not support the video
                                      tag.
                                    </video>
                                  )
                                )}
                              </>
                            )}
                          </Col>
                        </Row>

                        <Row className="mb-3">
                          <div className="template-editor">
                            <label htmlFor="templateBody">Body</label>
                            <textarea
                              id="templateBody"
                              rows="6"
                              value={rowData?.message_body}
                              onChange={handleInputChange}
                              placeholder="Enter your template here..."
                            />

                            <div className="toolbar">
                              <button onClick={() => applyFormatting("bold")}>
                                B
                              </button>
                              <button onClick={() => applyFormatting("italic")}>
                                I
                              </button>
                              <button
                                onClick={() => applyFormatting("strikethrough")}
                              >
                                <i className="fa-solid fa-strikethrough"></i>
                              </button>
                              <button
                                onClick={() => applyFormatting("code")}
                              >{`</>`}</button>
                              <button onClick={addVariable}>
                                + Add variable
                              </button>
                              <button
                                onClick={() =>
                                  setShowEmojiPicker(!showEmojiPicker)
                                }
                              >
                                😊
                              </button>
                            </div>

                            {showEmojiPicker && (
                              <div className="emoji-picker">
                                <EmojiPicker onEmojiClick={handleEmojiClick} />
                              </div>
                            )}

                            {/* <p className="char-count">{template.length}/1070</p> */}
                          </div>
                          {/* <Col lg={12} sm={12} xs={12}>
                        <Form.Group
                          className="mx-3 mb-3"
                          //controlId="formBasicBody"
                        >
                          <Form.Label
                            className="form-view-label"
                            htmlFor="formBasicBody"
                          >
                            Body
                          </Form.Label>
                          <Form.Control
                            as="textarea"
                            name="message_body"
                            value={rowData?.message_body}
                            onChange={handleChange}
                            placeholder="type text here..."
                            required
                            rows={3}
                          />
                        </Form.Group>
                      </Col> */}
                          {showbody && (
                            <Col lg={12} sm={12} xs={12}>
                              <Form.Group className="mx-3 mb-3 col-12">
                                <Form.Label
                                  className="form-view-label col-12 d-flex me-5"
                                  htmlFor="formBasicFooter"
                                >
                                  <div className="d-flex flex-column justify-content-start align-items-start col-6">
                                    Body Example
                                  </div>
                                  {/* <div className="d-flex flex-column justify-content-end align-items-end col-6 ">
                                    <Button
                                      className="me-3"
                                      onClick={addVariable}
                                    >
                                      <i className="fa fa-plus"></i>Add variable
                                    </Button>
                                  </div> */}
                                </Form.Label>

                                {rowData?.message_body_example?.map(
                                  (example, index) => (
                                    <Row
                                      key={index}
                                      className="align-items-center mb-3"
                                    >
                                      <Col lg={3} sm={12} xs={12}>
                                        <span className="h6">
                                          {`{{${example.placeholder}}}`}
                                        </span>
                                      </Col>
                                      <Col lg={1} sm={0} xs={0}></Col>
                                      <Col lg={6} sm={12} xs={12}>
                                        <Form.Control
                                          required
                                          type="text"
                                          name={`${example.placeholder}.value`}
                                          value={example.value || ""}
                                          onChange={(e) => {
                                            const updatedValue = e.target.value;
                                            setRowData((prevData) => ({
                                              ...prevData,
                                              message_body_example:
                                                prevData.message_body_example.map(
                                                  (item) =>
                                                    item.placeholder ===
                                                    example.placeholder
                                                      ? {
                                                          ...item,
                                                          value: updatedValue,
                                                        }
                                                      : item
                                                ),
                                            }));
                                          }}
                                          placeholder={`Enter content for {{${example.placeholder}}}`}
                                        />
                                      </Col>
                                    </Row>
                                  )
                                )}
                              </Form.Group>
                            </Col>
                          )}

                          <Col lg={6} sm={12} xs={12}>
                            <Form.Group
                              className="mx-3 mb-3"
                              //controlId="formBasicFooter"
                            >
                              <Form.Label
                                className="form-view-label"
                                htmlFor="formBasicFooter"
                              >
                                Footer
                              </Form.Label>
                              <Form.Control
                                required
                                type="text"
                                name="footer"
                                value={rowData?.footer}
                                onChange={handleChange}
                                placeholder="type text here..."
                              />
                            </Form.Group>
                          </Col>
                        </Row>

                        <Row className="mb-1">
                          <Col lg={12} sm={12} xs={12}>
                            <Button
                              className="mb-3 mx-3"
                              variant="outline-secondary"
                              onClick={handleAddButton}
                            >
                              <i class="fa-solid fa-plus mx-1"></i>Add Button
                            </Button>
                          </Col>
                        </Row>

                        <Row className="mb-1 mx-2">
                          <Col lg={12} sm={12} xs={12} className="mb-2">
                            {buttons.map((button, index) => (
                              <Row key={index} className="mb-3">
                                <Col lg={3} sm={12} xs={12}>
                                  <Form.Group className="mb-0">
                                    <Form.Label className="form-view-label">
                                      Type of action
                                    </Form.Label>
                                    <Form.Select
                                      value={button.type}
                                      onChange={(e) =>
                                        handleButtonChange(
                                          index,
                                          "type",
                                          e.target.value
                                        )
                                      }
                                    >
                                      <option value="">Select Type</option>
                                      <option
                                        value="URL"
                                        disabled={
                                          getDisabledOptions("URL") &&
                                          button.type !== "URL"
                                        }
                                      >
                                        Visit Website
                                      </option>
                                      <option
                                        value="PHONE_NUMBER"
                                        disabled={
                                          getDisabledOptions("PHONE_NUMBER") &&
                                          button.type !== "PHONE_NUMBER"
                                        }
                                      >
                                        Call Phone Number
                                      </option>
                                    </Form.Select>
                                  </Form.Group>
                                </Col>

                                <Col lg={3} sm={12} xs={12}>
                                  <Form.Group className="mb-0">
                                    <Form.Label className="form-view-label">
                                      Button Text
                                    </Form.Label>
                                    <Form.Control
                                      type="text"
                                      value={button.text}
                                      onChange={(e) =>
                                        handleButtonChange(
                                          index,
                                          "text",
                                          e.target.value
                                        )
                                      }
                                      placeholder="Enter button text..."
                                    />
                                  </Form.Group>
                                </Col>

                                {button.type === "URL" && (
                                  <Col lg={3} sm={12} xs={12}>
                                    <Form.Group className="mb-0">
                                      <Form.Label className="form-view-label">
                                        URL
                                      </Form.Label>
                                      <Form.Control
                                        required
                                        type="text"
                                        value={button.url}
                                        onChange={(e) =>
                                          handleButtonChange(
                                            index,
                                            "url",
                                            e.target.value
                                          )
                                        }
                                        placeholder="https://www.example.com"
                                      />
                                    </Form.Group>
                                  </Col>
                                )}

                                {button.type === "PHONE_NUMBER" && (
                                  <Col lg={3} sm={12} xs={12}>
                                    <Form.Group className="mb-0">
                                      <Form.Label className="form-view-label">
                                        Phone Number
                                      </Form.Label>
                                      <Form.Control
                                        required
                                        type="text"
                                        value={button.phone_number}
                                        onChange={(e) =>
                                          handleButtonChange(
                                            index,
                                            "phone_number",
                                            e.target.value
                                          )
                                        }
                                        placeholder="16467043595"
                                      />
                                    </Form.Group>
                                  </Col>
                                )}

                                <Col
                                  lg={3}
                                  sm={12}
                                  xs={12}
                                  className="d-flex align-items-center mt-4"
                                >
                                  <Button
                                    variant="danger"
                                    onClick={() => handleRemoveButton(index)}
                                  >
                                    <i
                                      className="fa-regular fa-trash-can"
                                      title="Delete"
                                    ></i>
                                  </Button>
                                </Col>
                              </Row>
                            ))}
                          </Col>
                        </Row>
                      </Col>

                      <Col lg={6} sm={6} xs={6}>
                        <div className="template-preview-container">
                          <h4 className="template-title p-4">
                            Template Preview
                          </h4>

                          <div className="template-preview">
                            {/* Header Section */}
                            <div className="template-header">
                              {rowData?.header === "TEXT" && (
                                <h5 className="text-dark fw-bold fs-5">
                                  {rowData?.header_text}
                                </h5>
                              )}
                              {rowData?.header === "IMAGE" && (
                                <img
                                  src={
                                    selectedImage2 ||
                                    (selectedFiles &&
                                      URL.createObjectURL(selectedFiles))
                                  }
                                  alt="Header Image"
                                  className="preview-image"
                                  style={{ width: "100%", hight: "160px" }}
                                />
                              )}

                              {rowData?.header === "DOCUMENT" && (
                                <a
                                  href={
                                    selectedDocument2 ||
                                    (selectedFiles &&
                                      URL.createObjectURL(selectedFiles))
                                  }
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  View Document
                                </a>
                              )}
                              {rowData?.header === "VIDEO" && (
                                <video controls style={{ width: "100%" }}>
                                  <source
                                    src={
                                      selectedVideo2 ||
                                      (selectedFiles &&
                                        URL.createObjectURL(selectedFiles))
                                    }
                                    type="video/mp4"
                                  />
                                  Your browser does not support the video tag.
                                </video>
                              )}
                            </div>

                            {/* Body Section */}
                            <div className="template-body">
                              <p
                                dangerouslySetInnerHTML={{
                                  __html: formatMessage(rowData?.message_body),
                                }}
                              />
                            </div>

                            {/* Footer Section */}
                            <div className="footer-preview d-flex">
                              <div className="d-flex flex-column justify-content-start align-items-start col-6 ">
                                {rowData?.footer}
                              </div>
                              <div className="d-flex flex-column justify-content-end align-items-end col-6 ">
                                {new Date().toLocaleString("en-US", {
                                  hour: "2-digit",
                                  minute: "2-digit",
                                  hour12: true,
                                })}
                              </div>
                            </div>
                            {buttons && buttons.length > 0 && (
                              <div className="text-center">
                                {buttons.map((button, index) => (
                                  <>
                                    <hr />
                                    <div key={index} className="button-preview">
                                      {button.type === "PHONE_NUMBER" && (
                                        <>
                                          <button className="btn btn-link border-0 btn-block text-decoration-none">
                                            <i className="fas fa-phone"></i>{" "}
                                            {button.text}
                                            {/* <span>{button.phone_number}</span> */}
                                          </button>
                                        </>
                                      )}
                                      {button.type === "URL" && (
                                        <>
                                          {/* <a
                                            href={button.url}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            className="w-100" // To make the anchor tag behave like a button
                                          > */}
                                          <button className="btn btn-link border-0 btn-block text-decoration-none">
                                            <i className="fas fa-external-link-alt"></i>{" "}
                                            {button.text}
                                          </button>
                                          {/* </a> */}
                                        </>
                                      )}
                                    </div>
                                  </>
                                ))}
                              </div>
                            )}
                          </div>

                          {/* Buttons Section */}
                        </div>
                        {/* timestamp */}
                      </Col>
                    </Row>

                    <Row>
                      <Col lg={12} sm={12} xs={12}>
                        <hr></hr>
                      </Col>
                    </Row>

                    <Row className="g-0 mb-2">
                      <Col lg={12} sm={12} xs={12} className="text-end mt-2">
                        <Button
                          className="mx-2"
                          variant="light"
                          onClick={handleBack}
                          disabled={isSending}
                        >
                          Back
                        </Button>
                        <Button
                          variant="outline-secondary"
                          disabled={!isFormValid || isSending}
                          onClick={handleSubmit}
                        >
                          {isSending ? "Submitting..." : "Submit for Review"}
                        </Button>
                      </Col>
                    </Row>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
            <Row className="mb-3"></Row>
          </Container>
        </>
      ) : (
        <Container className="mt-1">
          <Row className="mx-5">
            <Col lg={12} sm={12} xs={12}>
              <Card className="h-100" style={{ border: "none" }}>
                <Card.Body>
                  <Row className="mb-3">
                    <Col lg={12} sm={12} xs={12}>
                      <div className="sk-cube-grid">
                        <div className="sk-cube sk-cube1"></div>
                        <div className="sk-cube sk-cube2"></div>
                        <div className="sk-cube sk-cube3"></div>
                        <div className="sk-cube sk-cube4"></div>
                        <div className="sk-cube sk-cube5"></div>
                        <div className="sk-cube sk-cube6"></div>
                        <div className="sk-cube sk-cube7"></div>
                        <div className="sk-cube sk-cube8"></div>
                        <div className="sk-cube sk-cube9"></div>
                      </div>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      )}

      <ToastContainer />
    </>
  );
};

export default MarketingTemplate;

// /**
//  * @author      Abdul Pathan
//  * @date        Aug, 2024
//  * @copyright   www.ibirdsservices.com
//  */
// import React, { useEffect, useState } from "react";
// import {
//   Button,
//   Card,
//   Col,
//   Container,
//   Form,
//   Image,
//   Row,
// } from "react-bootstrap";
// import WhatsAppAPI from "../../../api/schoolApi";
// import { ToastContainer, toast } from "react-toastify"; // npm i react-toastify --force
// import "react-toastify/dist/ReactToastify.css";
// import { useNavigate } from "react-router-dom";

// const MarketingTemplate = ({ previewData }) => {
//   const initialFormData = {
//     id: "",
//     name: "",
//     templatename: "",
//     language: "",
//     status: "",
//     category: "",
//     header: "",
//     header_text: "",
//     header_image_url: "",
//     header_document_url: "",
//     header_video_url: "",
//     message_body: "",
//     footer: "",
//     buttons: [],
//   };

//   const navigate = useNavigate();
//   const [isSending, setIsSending] = useState(false);
//   const [isSpinner, setIsSpinner] = useState(false);
//   const [rowData, setRowData] = useState(initialFormData);
//   const [buttons, setButtons] = useState([]);
//   // file
//   const [selectedFiles, setSelectedFiles] = useState();
//   const [errorMessage, setErrorMessage] = useState("");
//   const [selectedImage2, setSelectedImage2] = useState();
//   const [selectedDocument2, setSelectedDocument2] = useState();
//   const [selectedVideo2, setSelectedVideo2] = useState();

//   console.log("MarketingTemplate previewData", previewData);

//   useEffect(() => {
//     if (previewData && previewData?.category === "MARKETING") {
//       const buttons =
//         previewData?.buttons && previewData.buttons.length > 0
//           ? previewData.buttons.map((button) => ({
//               type: button.type,
//               text: button.text,
//               ...(button.type === "PHONE_NUMBER" && {
//                 phone_number: button.phone_number,
//               }),
//               ...(button.type === "URL" && { url: button.url }),
//             }))
//           : [];

//       setRowData({
//         id: previewData?.id || "",
//         name: previewData?.templatename || "",
//         language: previewData?.language || "",
//         status: previewData?.status || "",
//         category: previewData?.category || "",
//         header: previewData?.header || "",
//         header_text: previewData?.header_text || "",
//         header_image_url: previewData?.header_image_url || "",
//         header_document_url: previewData?.header_document_url || "",
//         header_video_url: previewData?.header_video_url || "",
//         message_body: previewData?.message_body || "",
//         footer: previewData?.footer || "",
//         buttons,
//       });

//       setButtons(buttons);
//       setSelectedImage2(previewData?.header_image_url || null);
//       setSelectedDocument2(previewData?.header_document_url || null);
//       setSelectedVideo2(previewData?.header_video_url || null);
//       // setSelectedFiles(previewData?.header_image_url || null)
//     } else {
//       setRowData(initialFormData);
//       setButtons([]);
//       setSelectedFiles(null);
//     }
//   }, [previewData]);

//   const handleChange = (event) => {
//     const { name, value } = event.target;
//     setRowData((prevData) => ({ ...prevData, [name]: value }));
//     if (name === "header") {
//       setSelectedFiles(null);
//       setSelectedImage2(null);
//       setSelectedDocument2(null);
//       setSelectedVideo2(null);
//     }
//   };

//   const handleFileChange = (event) => {
//     const { name, files } = event.target;
//     const file = files[0];

//     if (!file) return;

//     const fileTypeMappings = {
//       header_image_url: ["jpg", "jpeg", "png"],
//       header_document_url: ["pdf"],
//       header_video_url: ["mp4"],
//     };

//     const validExtensions = fileTypeMappings[name];
//     if (!validExtensions.includes(file.name.split(".").pop().toLowerCase())) {
//       setErrorMessage(`Only ${validExtensions.join(", ")} files are allowed.`);
//       event.target.value = "";
//       return;
//     }

//     setErrorMessage("");
//     switch (name) {
//       case "header_image_url":
//         setSelectedImage2(null);
//         setSelectedFiles(file);
//         break;
//       case "header_document_url":
//         setSelectedDocument2(null);
//         setSelectedFiles(file);
//         break;
//       case "header_video_url":
//         setSelectedVideo2(null);
//         setSelectedFiles(file);
//         break;
//       default:
//         break;
//     }
//   };

//   const handleSubmit = async (event) => {
//     event.preventDefault();
//     console.log("##row Data, ", rowData);
//     console.log("##selectedFiles", selectedFiles);
//     console.log("##buttons", buttons);

//     if (rowData.header === "TEXT" && !rowData.header_text.trim()) {
//       toast.error("Template header text is required.");
//       return;
//     }

//     if (rowData.header === "IMAGE" && !selectedFiles && !selectedImage2) {
//       toast.error("Upload image is required.");
//       return;
//     }
//     if (rowData.header === "DOCUMENT" && !selectedFiles && !selectedDocument2) {
//       toast.error("Upload document is required.");
//       return;
//     }
//     if (rowData.header === "VIDEO" && !selectedFiles && !selectedVideo2) {
//       toast.error("Upload video mp4 is required.");
//       return;
//     }

//     if (buttons.length > 0) {
//       for (const button of buttons) {
//         if (
//           button.type === "URL" &&
//           (!button?.text.trim() || !button?.url.trim())
//         ) {
//           toast.error("Please fill in all fields for the URL button.");
//           return;
//         }
//         if (
//           button.type === "PHONE_NUMBER" &&
//           (!button?.text.trim() || !button?.phone_number.trim())
//         ) {
//           toast.error("Please fill in all fields for the Phone Number button.");
//           return;
//         }
//       }
//     }

//     let imageId = "";

//     if (selectedFiles) {
//       console.log("object", selectedFiles);
//       const sessionId = await WhatsAppAPI.generateSessionId(selectedFiles);
//       //console.log("sessionId", sessionId);
//       //const sessionId = null;
//       if (sessionId.id) {
//         const document = await WhatsAppAPI.uploadDocumentSessionId(
//           selectedFiles,
//           sessionId.id
//         );
//         imageId = document.h;
//         console.log("uploaded id", document);
//       }
//     }

//     const formattedName = rowData.name
//       .toLowerCase()
//       .replace(/[^a-z0-9]+/g, "_")
//       .replace(/^_+|_+$/g, "");

//     const reqBody = {
//       name: formattedName,
//       language: rowData.language,
//       category: "MARKETING",
//       components: [
//         {
//           type: "HEADER",
//           format: rowData.header,
//           ...(rowData.header === "TEXT" && { text: rowData.header_text }),
//           ...(rowData.header === "IMAGE" && {
//             example: { header_handle: [imageId || rowData?.header_image_url] },
//           }),
//           ...(rowData.header === "DOCUMENT" && {
//             example: {
//               header_handle: [imageId || rowData?.header_document_url],
//             },
//           }),
//           ...(rowData.header === "VIDEO" && {
//             example: { header_handle: [imageId || rowData?.header_video_url] },
//           }),
//         },
//         {
//           type: "BODY",
//           text: rowData?.message_body,
//         },
//         {
//           type: "FOOTER",
//           text: rowData?.footer || "",
//         },
//         ...(buttons.filter((button) => button.text && button.type).length > 0
//           ? [
//               {
//                 type: "BUTTONS",
//                 buttons: buttons
//                   .filter((button) => button.text && button.type)
//                   .map((button) => ({
//                     type: button.type,
//                     text: button.text,
//                     ...(button.type === "PHONE_NUMBER" && {
//                       phone_number: button.phone_number,
//                     }),
//                     ...(button.type === "URL" && { url: button.url }),
//                   })),
//               },
//             ]
//           : []),
//       ],
//     };

//     console.log("##### reqBody :", reqBody);

//     try {
//       setIsSpinner(true);
//       let result;
//       if (rowData?.id) {
//         result = await WhatsAppAPI.updateMarketingTemplate(rowData.id, reqBody);
//       } else {
//         result = await WhatsAppAPI.createMarketingTemplate(reqBody);
//       }

//       console.log("###result", result);

//       if (result.error) {
//         // const errorResponse = result;
//         // const jsonPart = errorResponse.error.split("Bad Request - ")[1];
//         // const parsedError = JSON.parse(jsonPart);
//         // toast.error(JSON.parse((parsedError.error)));
//         const errorJson = result.error.split(" - ")[1];
//         const parsedError = JSON.parse(errorJson);
//         const errorMessage =
//           parsedError.error.error_user_msg || parsedError.error.message;
//         toast.error(errorMessage);
//       } else {
//         toast.success(
//           rowData?.id
//             ? "Template updated successfully."
//             : "Template created successfully."
//         );
//         setIsSpinner(false);
//         navigate("/Templates");
//       }
//     } catch (error) {
//       toast.error("An unexpected error occurred.");
//     } finally {
//       setIsSending(false);
//       setIsSpinner(false);
//     }
//   };

//   const handleBack = () => {
//     navigate("/Templates");
//   };

//   const isFormValid =
//     Boolean(rowData.name.trim()) &&
//     Boolean(rowData.language) &&
//     Boolean(rowData.message_body.trim()) &&
//     Boolean(rowData.footer.trim());

//   const handleAddButton = () => {
//     if (buttons.length < 2) {
//       const newButton = { type: "", text: "", PHONE_NUMBER: "", url: "" };
//       setButtons([...buttons, newButton]);
//     } else {
//       toast.error("Maximum of 2 buttons can be added.");
//     }
//   };

//   const handleRemoveButton = (index) => {
//     const updatedButtons = buttons.filter((_, i) => i !== index);
//     setButtons(updatedButtons);
//   };

//   const handleButtonChange = (index, field, value) => {
//     const updatedButtons = buttons.map((button, i) => {
//       if (i === index) {
//         return { ...button, [field]: value };
//       }
//       return button;
//     });
//     setButtons(updatedButtons);
//   };

//   const getDisabledOptions = (currentType) => {
//     return buttons.some((button) => button.type === currentType);
//   };

//   return (
//     <>
//       {!isSpinner ? (
//         <>
//           <Container className="mt-1">
//             <Row className="mx-5 g-0">
//               <Col lg={12} sm={12} xs={12}>
//                 <Card className="h-100" style={{ border: "none" }}>
//                   <Card.Body>
//                     <Row>
//                       <Col lg={12} sm={12} xs={12}>
//                         <Form.Group
//                           className="mx-2 mb-3"
//                           //controlId="formBasicName"
//                         >
//                           <Form.Label
//                             className="form-view-label"
//                             htmlFor="formBasicName"
//                           >
//                             <b>Template name and language</b>
//                           </Form.Label>
//                         </Form.Group>
//                       </Col>
//                     </Row>

//                     <Row className="mb-3">
//                       <Col lg={6} sm={12} xs={12}>
//                         <Form.Group
//                           className="mx-2 mb-3"
//                           //controlId="formBasicTemplateName"
//                         >
//                           <Form.Label
//                             className="form-view-label"
//                             htmlFor="formBasicTemplateName"
//                           >
//                             Template Name
//                           </Form.Label>
//                           <Form.Control
//                             disabled={rowData?.id}
//                             type="text"
//                             name="name"
//                             value={rowData?.name}
//                             onChange={handleChange}
//                             placeholder="Enter template name"
//                             required
//                           />
//                         </Form.Group>
//                       </Col>

//                       <Col lg={6} sm={12} xs={12}>
//                         <Form.Group className="mb-2 mx-3">
//                           <Form.Label
//                             className="form-view-label"
//                             htmlFor="formBasicLanguage"
//                           >
//                             Language
//                           </Form.Label>
//                           <Form.Select
//                             // disabled={rowData?.id}
//                             aria-label="select language"
//                             name="language"
//                             value={rowData?.language}
//                             onChange={handleChange}
//                             required
//                           >
//                             <option value="">Select Language</option>
//                             <option value="en">English</option>
//                             <option value="en_US">English (US)</option>
//                             <option value="en_GB">English (UK)</option>
//                             <option value="hi">Hindi</option>
//                             <option value="ur">Urdu</option>
//                           </Form.Select>
//                         </Form.Group>
//                       </Col>
//                     </Row>
//                   </Card.Body>
//                 </Card>
//               </Col>
//             </Row>
//           </Container>

//           <Container className="mt-1">
//             <Row className="mx-5 mb-5 g-0">
//               <Col lg={12} sm={12} xs={12} className="mb-2">
//                 <Card className="h-100" style={{ border: "none" }}>
//                   <Card.Body>
//                     <Row>
//                       <Col lg={12} sm={12} xs={12}>
//                         <Form.Group className="mx-2 mb-3">
//                           <Form.Label
//                             className="form-view-label"
//                             htmlFor="formBasicContent"
//                           >
//                             <b>Content </b> ( Fill in the header, body and
//                             footer sections of your template. )
//                           </Form.Label>
//                         </Form.Group>
//                       </Col>
//                     </Row>

//                     <Row className="mb-3">
//                       <Col lg={6} sm={12} xs={12}>
//                         <Form.Group className="mb-3 mx-3">
//                           <Form.Label
//                             className="form-view-label"
//                             htmlFor="formBasicHeader"
//                           >
//                             Header
//                           </Form.Label>
//                           <Form.Select
//                             aria-label="select language"
//                             name="header"
//                             value={rowData?.header}
//                             onChange={handleChange}
//                           >
//                             <option value="">Select Header</option>
//                             <option value="TEXT">Text</option>
//                             <option value="IMAGE">Image</option>
//                             <option value="DOCUMENT">Document</option>
//                             <option value="VIDEO">Video</option>
//                           </Form.Select>
//                         </Form.Group>
//                       </Col>

//                       {rowData.header === "TEXT" && (
//                         <Col lg={6} sm={12} xs={12}>
//                           <Form.Group
//                             className="mx-2 mb-3"
//                             //controlId="formBasicHeaderText"
//                           >
//                             <Form.Label
//                               className="form-view-label"
//                               htmlFor="formBasicHeaderText"
//                             >
//                               Header Text
//                             </Form.Label>
//                             <Form.Control
//                               type="text"
//                               name="header_text"
//                               value={rowData?.header_text}
//                               onChange={handleChange}
//                               placeholder="Enter text here..."
//                               required
//                             />
//                           </Form.Group>
//                         </Col>
//                       )}
//                     </Row>

//                     <Row className="mb-3">
//                       <Col lg={6} sm={12} xs={12}>
//                         {rowData.header === "IMAGE" && (
//                           <>
//                             <Form.Group
//                               className="mb-3 mx-3"
//                               //controlId="formFileImage"
//                             >
//                               <Form.Label>Upload Image</Form.Label>
//                               <Form.Control
//                                 required
//                                 type="file"
//                                 name="header_image_url"
//                                 accept="image/*" // Allow only image files
//                                 onChange={handleFileChange}
//                               />
//                               {errorMessage && (
//                                 <Form.Text className="text-danger">
//                                   {errorMessage}
//                                 </Form.Text>
//                               )}
//                             </Form.Group>
//                             {selectedImage2 ? (
//                               <Image
//                                 className="mx-3"
//                                 variant="top"
//                                 src={selectedImage2}
//                                 thumbnail
//                                 style={{ width: "15%" }}
//                               ></Image>
//                             ) : (
//                               selectedFiles && (
//                                 <Image
//                                   className="mx-3"
//                                   variant="top"
//                                   src={URL.createObjectURL(selectedFiles)}
//                                   thumbnail
//                                   style={{ width: "15%" }}
//                                 ></Image>
//                               )
//                             )}
//                           </>
//                         )}

//                         {rowData.header === "DOCUMENT" && (
//                           <>
//                             <Form.Group
//                               className="mb-3 mx-3"
//                               //controlId="formFileDocument"
//                             >
//                               <Form.Label>Upload Document</Form.Label>
//                               <Form.Control
//                                 required
//                                 type="file"
//                                 name="header_document_url"
//                                 accept=".pdf" // Allow only PDF files
//                                 onChange={handleFileChange}
//                               />
//                               {errorMessage && (
//                                 <Form.Text className="text-danger">
//                                   {errorMessage}
//                                 </Form.Text>
//                               )}
//                             </Form.Group>
//                             {console.log(
//                               "selectedDocument2",
//                               selectedDocument2
//                             )}
//                             {selectedDocument2 ? (
//                               <Form.Text className="text-muted mx-2">
//                                 <a
//                                   href={selectedDocument2}
//                                   target="_blank"
//                                   rel="noopener noreferrer"
//                                 >
//                                   <i
//                                     class="fa-solid fa-file-pdf mx-2"
//                                     style={{ fontSize: "15px" }}
//                                   ></i>
//                                   View PDF
//                                 </a>
//                               </Form.Text>
//                             ) : (
//                               selectedFiles && (
//                                 <Form.Text className="text-muted mx-2">
//                                   <a
//                                     href={URL.createObjectURL(selectedFiles)}
//                                     target="_blank"
//                                     rel="noopener noreferrer"
//                                   >
//                                     <i
//                                       class="fa-solid fa-file-pdf mx-2"
//                                       style={{ fontSize: "15px" }}
//                                     ></i>
//                                     {selectedFiles.name}
//                                   </a>
//                                 </Form.Text>
//                               )
//                             )}
//                           </>
//                         )}

//                         {rowData.header === "VIDEO" && (
//                           <>
//                             <Form.Group
//                               className="mb-3 mx-3"
//                               //controlId="formFileVideo"
//                             >
//                               <Form.Label>Upload Video</Form.Label>
//                               <Form.Control
//                                 required
//                                 type="file"
//                                 name="header_video_url"
//                                 accept="video/mp4" // Allow only MP4 videos
//                                 onChange={handleFileChange}
//                               />
//                               {errorMessage && (
//                                 <Form.Text className="text-danger">
//                                   {errorMessage}
//                                 </Form.Text>
//                               )}
//                             </Form.Group>

//                             {selectedVideo2 ? (
//                               <Image
//                                 className="mx-3"
//                                 variant="top"
//                                 src={selectedVideo2}
//                                 thumbnail
//                                 style={{ width: "15%" }}
//                               ></Image>
//                             ) : (
//                               selectedFiles && (
//                                 <video
//                                   controls
//                                   className="mx-3"
//                                   style={{ width: "100%" }}
//                                 >
//                                   <source
//                                     src={URL.createObjectURL(selectedFiles)}
//                                     type="video/mp4"
//                                   />
//                                   Your browser does not support the video tag.
//                                 </video>
//                               )
//                             )}
//                           </>
//                         )}
//                       </Col>
//                     </Row>

//                     <Row className="mb-3">
//                       <Col lg={12} sm={12} xs={12}>
//                         <Form.Group
//                           className="mx-3 mb-3"
//                           //controlId="formBasicBody"
//                         >
//                           <Form.Label
//                             className="form-view-label"
//                             htmlFor="formBasicBody"
//                           >
//                             Body
//                           </Form.Label>
//                           <Form.Control
//                             as="textarea"
//                             name="message_body"
//                             value={rowData?.message_body}
//                             onChange={handleChange}
//                             placeholder="type text here..."
//                             required
//                             rows={3}
//                           />
//                         </Form.Group>
//                       </Col>

//                       <Col lg={6} sm={12} xs={12}>
//                         <Form.Group
//                           className="mx-3 mb-3"
//                           //controlId="formBasicFooter"
//                         >
//                           <Form.Label
//                             className="form-view-label"
//                             htmlFor="formBasicFooter"
//                           >
//                             Footer
//                           </Form.Label>
//                           <Form.Control
//                             required
//                             type="text"
//                             name="footer"
//                             value={rowData?.footer}
//                             onChange={handleChange}
//                             placeholder="type text here..."
//                           />
//                         </Form.Group>
//                       </Col>
//                     </Row>

//                     <Row className="mb-1">
//                       <Col lg={12} sm={12} xs={12}>
//                         <Button
//                           className="mb-3 mx-3"
//                           variant="outline-secondary"
//                           onClick={handleAddButton}
//                         >
//                           <i class="fa-solid fa-plus mx-1"></i>Add Button
//                         </Button>
//                       </Col>
//                     </Row>

//                     <Row className="mb-1 mx-2">
//                       <Col lg={12} sm={12} xs={12} className="mb-2">
//                         {buttons.map((button, index) => (
//                           <Row key={index} className="mb-3">
//                             <Col lg={3} sm={12} xs={12}>
//                               <Form.Group className="mb-0">
//                                 <Form.Label className="form-view-label">
//                                   Type of action
//                                 </Form.Label>
//                                 <Form.Select
//                                   value={button.type}
//                                   onChange={(e) =>
//                                     handleButtonChange(
//                                       index,
//                                       "type",
//                                       e.target.value
//                                     )
//                                   }
//                                 >
//                                   <option value="">Select Type</option>
//                                   <option
//                                     value="URL"
//                                     disabled={
//                                       getDisabledOptions("URL") &&
//                                       button.type !== "URL"
//                                     }
//                                   >
//                                     Visit Website
//                                   </option>
//                                   <option
//                                     value="PHONE_NUMBER"
//                                     disabled={
//                                       getDisabledOptions("PHONE_NUMBER") &&
//                                       button.type !== "PHONE_NUMBER"
//                                     }
//                                   >
//                                     Call Phone Number
//                                   </option>
//                                 </Form.Select>
//                               </Form.Group>
//                             </Col>

//                             <Col lg={3} sm={12} xs={12}>
//                               <Form.Group className="mb-0">
//                                 <Form.Label className="form-view-label">
//                                   Button Text
//                                 </Form.Label>
//                                 <Form.Control
//                                   type="text"
//                                   value={button.text}
//                                   onChange={(e) =>
//                                     handleButtonChange(
//                                       index,
//                                       "text",
//                                       e.target.value
//                                     )
//                                   }
//                                   placeholder="Enter button text..."
//                                 />
//                               </Form.Group>
//                             </Col>

//                             {button.type === "URL" && (
//                               <Col lg={3} sm={12} xs={12}>
//                                 <Form.Group className="mb-0">
//                                   <Form.Label className="form-view-label">
//                                     URL
//                                   </Form.Label>
//                                   <Form.Control
//                                     required
//                                     type="text"
//                                     value={button.url}
//                                     onChange={(e) =>
//                                       handleButtonChange(
//                                         index,
//                                         "url",
//                                         e.target.value
//                                       )
//                                     }
//                                     placeholder="https://www.example.com"
//                                   />
//                                 </Form.Group>
//                               </Col>
//                             )}

//                             {button.type === "PHONE_NUMBER" && (
//                               <Col lg={3} sm={12} xs={12}>
//                                 <Form.Group className="mb-0">
//                                   <Form.Label className="form-view-label">
//                                     Phone Number
//                                   </Form.Label>
//                                   <Form.Control
//                                     required
//                                     type="text"
//                                     value={button.phone_number}
//                                     onChange={(e) =>
//                                       handleButtonChange(
//                                         index,
//                                         "phone_number",
//                                         e.target.value
//                                       )
//                                     }
//                                     placeholder="16467043595"
//                                   />
//                                 </Form.Group>
//                               </Col>
//                             )}

//                             <Col
//                               lg={3}
//                               sm={12}
//                               xs={12}
//                               className="d-flex align-items-center mt-4"
//                             >
//                               <Button
//                                 variant="danger"
//                                 onClick={() => handleRemoveButton(index)}
//                               >
//                                 <i
//                                   className="fa-regular fa-trash-can"
//                                   title="Delete"
//                                 ></i>
//                               </Button>
//                             </Col>
//                           </Row>
//                         ))}
//                       </Col>
//                     </Row>

//                     <Row>
//                       <Col lg={12} sm={12} xs={12}>
//                         <hr></hr>
//                       </Col>
//                     </Row>

//                     <Row className="g-0 mb-2">
//                       <Col lg={12} sm={12} xs={12} className="text-end mt-2">
//                         <Button
//                           className="mx-2"
//                           variant="light"
//                           onClick={handleBack}
//                           disabled={isSending}
//                         >
//                           Back
//                         </Button>
//                         <Button
//                           variant="outline-secondary"
//                           disabled={!isFormValid || isSending}
//                           onClick={handleSubmit}
//                         >
//                           {isSending ? "Submitting..." : "Submit for Review"}
//                         </Button>
//                       </Col>
//                     </Row>
//                   </Card.Body>
//                 </Card>
//               </Col>
//             </Row>
//           </Container>
//         </>
//       ) : (
//         <Container className="mt-1">
//           <Row className="mx-5">
//             <Col lg={12} sm={12} xs={12}>
//               <Card className="h-100" style={{ border: "none" }}>
//                 <Card.Body>
//                   <Row className="mb-3">
//                     <Col lg={12} sm={12} xs={12}>
//                       <div className="sk-cube-grid">
//                         <div className="sk-cube sk-cube1"></div>
//                         <div className="sk-cube sk-cube2"></div>
//                         <div className="sk-cube sk-cube3"></div>
//                         <div className="sk-cube sk-cube4"></div>
//                         <div className="sk-cube sk-cube5"></div>
//                         <div className="sk-cube sk-cube6"></div>
//                         <div className="sk-cube sk-cube7"></div>
//                         <div className="sk-cube sk-cube8"></div>
//                         <div className="sk-cube sk-cube9"></div>
//                       </div>
//                     </Col>
//                   </Row>
//                 </Card.Body>
//               </Card>
//             </Col>
//           </Row>
//         </Container>
//       )}

//       <ToastContainer />
//     </>
//   );
// };

// export default MarketingTemplate;
