import React, { useState, useEffect } from "react";
import { ShimmerTable } from "react-shimmer-effects";
import { Table, Row, Col, Button, Form } from "react-bootstrap";
import schoolApi from "../../api/schoolApi";
import Modal from 'react-bootstrap/Modal';
import {
  DatatableWrapper,
  Pagination,
  TableBody,
  TableHeader,
} from "react-bs-datatable";
import Confirm from '../Confirm';
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import moment from "moment";

import { Helmet } from "react-helmet";
function Transport(props) {
  console.log('props.student=>', props.student)
  const [body, setBody] = useState([]);
  const [showBtn, setShowBtn] = useState(true);
  const [modalBtn, setModalBtn] = useState('Save');
  const [modalShow, setModalShow] = useState(false);
  const [optionVehicals, setoptionVehicals] = useState([]);

  const [distance, setDistance] = useState([]);
  const [reload, setReload] = useState(false);
  const [transport, setTransport] = useState({
    transport_id: "",
    route_direction: "",
    fare_id: "",
    drop_location: ""
  });
  const [assTrans, setAssTrans] = useState([]);
  const [selectedId, setSelectedId] = useState(null);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  // Comment by Abhishek
  // const [distanceLabel, setDistanceLabel] = useState(false);

  const header = [
    { title: "Driver Name", prop: "driver_name", isFilterable: true, },
    { title: "Vehicle No", prop: "vehicle_no" },
    { title: "Type", prop: "type", isFilterable: true },
    { title: "Distance", prop: "distance" },
    { title: "Fare", prop: "fare_amount" },
    { title: "Location Name", prop: "drop_location", isFilterable: true },
    {
      title: "Action",
      prop: "",
      isFilterable: true,
      show: true,
      cell: (row) => {
        return (
          <>
            <Button
              className="btn-sm mx-2 edit-button"
              variant=""
              onClick={() => handleEditButton(row)}
            >
              <i className="fa-regular fa-pen-to-square"></i>
            </Button>
            <button
              className="btn btn-sm btn-danger mx-2"
              onClick={() => handleDelete(row)}
            >
              <i className="fa fa-trash"></i>
            </button>
          </>
        );
      },
    },
  ];

  useEffect(() => {
    async function init() {
      fetchTransport(props?.student?.student_addmission_id);
    };
    init();
  }, []);

  const handleEditButton = (row) => {
    console.log('rowss=>', row)
    setModalShow(true);
    setTransport(row);
    setModalBtn("Update");
  };

  const handleDelete = (row) => {
    console.log('rowss=>', row)
    setShowDeleteModal(true);
    setSelectedId(row.id);
  };

  const fetchTransport = async (admission_id) => {
    try {
      if (transport !== null) {
        const assingTransport = await schoolApi.getAssignTransportByIdOrStudentid(admission_id);
        console.log("result ==> fetch ", assingTransport);
        if (assingTransport.success) {
          setBody([assingTransport?.result]);
          setShowBtn(false);
        } else {
          setBody([]);
        }
      }
    } catch (error) {
      toast.error(error.message);
    }
  };

  console.log("body@@+>", body);


  useEffect(() => {
    async function init() {
      try {
        let fareList = await schoolApi.getAllFares();
        console.log('fareList == ', fareList)
        if (fareList.success) {
          let ar = [];
          fareList?.result?.map((item) => {
            var obj = {};
            obj.value = item.id;
            obj.label = item.uptodistance;
            ar.push(obj);
          });
          console.log('Distance data == ', ar)
          setDistance(ar);
        } else {
          setDistance([]);
        }
      } catch (error) {
        toast.error(error.message);
      }

    }
    init();
  }, [reload]);
  useEffect(() => {
    async function init() {
      try {
        let vehicalList = await schoolApi.fetchVehicles();
        if (vehicalList.success) {
          let ar = [];
          vehicalList?.result?.map((item) => {
            var obj = {};
            obj.value = item.id;
            obj.label = item.vehicle_no + " " + item.type;
            ar.push(obj);
          });
          setoptionVehicals(ar);
        } else {
          setoptionVehicals([]);
        }
      } catch (error) {
        toast.error(error.message);
      }
    }
    init();
  }, [reload]);
  //handleClose
  const handleClose = () => setModalShow(false);

  //handleTransport
  const handleTransport = () => {
    console.log('handleTransport@@');
    setModalShow(true);
  }

  const handleChangeSubmit = async (e) => {
    e.preventDefault();
    try {
      console.log('transport-assi->', transport);

      if (!transport.transport_id || !transport.route_direction || !transport.distance || !transport.drop_location) {
        return toast.error("Missing required fields");
      }

      if (modalBtn === 'Update') {
        transport.student_addmission_id = props.student.student_addmission_id;  // Add by Abhishek
        transport.fee_type = props.student.fee_type;  // Add by Abhishek
        console.log('updatttt', transport)
        // if (modalBtn === 'Update') {  // Comment by Abhishek
        console.log('updatttt');
        try {
          let transportList = await schoolApi.updateAssignTransport(transport.id, transport);
          console.log('transportList@@=>', transportList)
          fetchTransport(props?.student?.student_addmission_id);
          setModalShow(false);
          toast.success("Record Saved Succesfully!");
        } catch (error) {
          toast.error("Update Failed!");
        }
      } else {
        console.log('distanceLabel == ')
        if ((transport.transport_id && transport.transport_id.trim() !== "")
          && (transport.route_direction && transport.route_direction.trim() !== "")
          && (transport.fare_id && transport.fare_id.trim() !== "")
          && (transport.drop_location && transport.drop_location.trim() !== "")
        ) {
          console.log('gdyfjfgjdsdgk')
          transport.student_addmission_id = props.student.student_addmission_id;
          transport.fee_type = props.student.fee_type;  // Add by Abhishek
          transport.sessionid = props.student.session_id;

          console.log('transporttt=>', transport)

          console.log('student22=>', props.student)
          let transportFeeByInstall = 0

          let vehicalList = await schoolApi.createAssignTransport(transport);
          console.log('vehicalList@@=>', vehicalList)
          props.student.vehicleid = vehicalList.result.transport_id;
          const inputDate = props.student.dateofbirth;
          const stu_dob = moment(inputDate, "DD-MM-YYYY").format("YYYY-MM-DD");
          props.student.dateofbirth = stu_dob;
          console.log('props.student=>', props.student)
          fetchTransport(props?.student?.student_addmission_id);
          setModalShow(false);
        } else {
          console.log('Required fields are missing!');
          toast.error('Required field missing!');;
        }
      }
    } catch (error) {
      toast.error(error.message);
    }
  }
  const handleChange = (e) => {
    const selectedValue = e.target.value;
    console.log('selectedValue =>', selectedValue)

    if (e.target.name === 'fare_id') {
      const selectedLabel = distance.find(d => d.value === selectedValue)?.label || '';
      console.log('selectedLabel =>', selectedLabel);
      console.log(e.target.name, e.target);

      transport.distance = selectedLabel;
    }

    setTransport({ ...transport, [e.target.name]: e.target.value });
  }

  const handleDeleteModuleRecord = async () => {
    console.log('handleDeleteModuleRecord', selectedId)
    if (selectedId) {
      try {
        const result = await schoolApi.deleteAssignTransport(selectedId);
        props.student.vehicleid = null;
        const updatedStudent = await schoolApi.updateStudent(props.student);
        setShowDeleteModal(false);
        setSelectedId(null);
        setBody([]);
        setShowBtn(true);
        toast.success("Record Deleted successfully!");
      } catch (error) {
        if (error.response && error.response.data && error.response.data.errors) {
          toast.error(error.response.data.errors);
        } else {
          toast.error('Unknown error occurred. Please try again.');
        }
      }
    }
  };
  return (

    <div>
      {showDeleteModal && (
        <Confirm
          show={showDeleteModal}
          onHide={() => setShowDeleteModal(false)}
          deleteAssignTransport={() => handleDeleteModuleRecord()}
          title="Confirm delete?"
          message="You are going to delete the record. Are you sure?"
          table="assign_transport"
        />
      )}
      <Helmet>
        <title>{props?.tabName}</title>
      </Helmet>
      {showBtn &&
        (
          <Row className="mr-3">
            <Col className="d-flex justify-content-end">
              <Button
                className="btn-sm mx-2 mt-3 mb-3 me-2 btnHover"
                variant="primary"
                size="sm"
                onClick={handleTransport}
              >
                Create Transport
              </Button>
            </Col>
          </Row>
        )
      }
      <Row className="mt-2">

        <Col lg={12}>
          {body ? (
            body.length > 0 ? (
              <DatatableWrapper body={body} headers={header}>
                <Table striped className="data-table custom-table-subject-list">
                  <TableHeader />
                  <TableBody />
                </Table>
                <Pagination />
              </DatatableWrapper>
            ) : (
              <div>
                <DatatableWrapper body={body} headers={header}>
                  <Table
                    striped
                    className="data-table custom-table-subject-list"
                  >
                    <TableHeader />
                    <TableBody>
                      <tr>
                        <td colSpan={header.length} className="text-center">
                          No Records Found!!!
                        </td>
                      </tr>
                    </TableBody>
                  </Table>
                  <Pagination />
                </DatatableWrapper>
              </div>
            )
          ) : (
            <ShimmerTable row={10} col={8} />
          )}
        </Col>
      </Row>
      <Modal show={modalShow} onHide={handleClose} backdrop="static"
        centered
        aria-labelledby="contained-modal-title-vcenter">
        <Modal.Header closeButton>
          <Modal.Title>Transport</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Row>
              <Col lg={6}>
                <Form.Group className="mb-3">
                  <Form.Label className="form-view-label">Vehicle</Form.Label>
                  <Form.Select
                    //  size="lg"
                    name="transport_id"
                    onChange={handleChange}
                    value={transport?.transport_id}
                    required
                  >
                    <option value="">Select Vehicle</option>
                    {optionVehicals.map((vehicle, index) => (
                      <option key={index} value={vehicle.value}>
                        {vehicle.label}
                      </option>
                    ))}
                  </Form.Select>
                </Form.Group>
              </Col>
              <Col lg={6}>
                <Form.Group className="mb-3">
                  <Form.Label className="form-view-label">Status</Form.Label>
                  <Form.Select
                    //   size="lg"
                    name="route_direction"
                    value={transport?.route_direction}
                    onChange={handleChange}
                    required
                  >
                    <option value="">-- Select Status --</option>
                    <option value="One way">One way</option>
                    <option value="Two way">Two way</option>
                  </Form.Select>
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col lg={6}>
                <Form.Group className="mb-3">
                  <Form.Label className="form-view-label">Distance(km)</Form.Label>
                  <Form.Select
                    //     size="lg"
                    name="fare_id"
                    onChange={handleChange}
                    value={transport?.fare_id}
                    required
                  >
                    <option value="">Select Distance</option>
                    {distance.map((distance, index) => (
                      <option key={index} value={distance.value}>
                        {distance.label}
                      </option>
                    ))}
                  </Form.Select>
                </Form.Group>
              </Col>
              <Col lg={6}>
                <Form.Group>
                  <Form.Label className="form-view-label">Drop Location</Form.Label>
                  <Form.Control
                    //    size="lg"
                    type="text"
                    name="drop_location"
                    placeholder="Enter drop location"
                    value={transport?.drop_location}
                    onChange={handleChange}
                    required
                  />
                </Form.Group>
              </Col>
            </Row>
          </Form>

        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={handleChangeSubmit}>
            {modalBtn}
          </Button>
        </Modal.Footer>
        <ToastContainer
          position="top-right"
          autoClose={2000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
        />
      </Modal>
    </div>
  );
}

export default Transport;
