import React, { useState, useEffect } from 'react';
import { Form, Button, Row, Col, Modal } from 'react-bootstrap';
import schoolApi from '../../api/schoolApi';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


const LocationMaster = (props) => {

    const [formData, setFormData] = useState({
        location: '',
        distance: '',
        status: '',
    });
    const [errorMessage, setErrorMessage] = useState('');
    const [successMessage, setSuccessMessage] = useState('');
    const [isUpdateMode, setIsUpdateMode] = useState(false);

    useEffect(() => {
        if (props.isUpdateMode && props.locationData) {
            setFormData({
                location: props.locationData.location || '',
                distance: props.locationData.distance || '',
                status: props.locationData.status || '',
            });
            setIsUpdateMode(true);
        } else {
            // Reset the formData based on btnName
            if (props.btnName === 'Create Location') {
                setFormData({
                    location: '',
                    distance: '',
                    status: '',
                });
                setIsUpdateMode(false);
            } else if (props.btnName === 'Update') {
                // Fill the form with existing data if it's an update operation
                setFormData({
                    location: props.locationData.location || '',
                    distance: props.locationData.distance || '',
                    status: props.locationData.status || '',
                });
                setIsUpdateMode(true);
            }
        }
    }, [props.btnName, props.isUpdateMode, props.locationData]);

    //Created By Shahir Hussain
    const validateForm = () => {
        console.log('validateForm == ',formData)
        const newErrors = {};
        let isValid = true;

        if (formData.location.trim() === '') {
            isValid = false;
        }
        if (formData.distance.trim() === '') {
            isValid = false;
        }
        if (formData.status.trim() === '') {
            isValid = false;
        }
        if(isValid === false)
            toast.error("Missing required fields");
            // toast.error('Please Fill All Required Field');
        // setErrors(newErrors);
        return isValid;
    };

    const handleUpdate = async () => {
        console.log('updatedLocationData formData == ',formData)
        if (!validateForm()) return;

        try {
            const locationMasterIdToUpdate = props.locationData.id;
            const updatedLocationData = {
                ...formData,
            };
                const updatedLocation = await schoolApi.updateLocationMaster(
                    locationMasterIdToUpdate,
                    updatedLocationData
                );
                console.log('updatedLocation == ',updatedLocation)
                if (updatedLocation.success) {
                    toast.success(updatedLocation.message);
                } else {
                    toast.error(updatedLocation.message);
                };
            // }
            // else{
            //     toast.error("Please Fill All Required Field");
            // }
            setIsUpdateMode(false);
            // if(flage === false){
                props.handleCloseModal();
            // }
        } catch (error) {
            console.log("error == ",error)
            return toast.error(error.response.data.message)
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!validateForm()) return;

        try {
            if (isUpdateMode) {
                handleUpdate();
            } else {
                const createdLocation = await schoolApi.createLocationmaster(formData);
                console.log('createdLocation == ',createdLocation)
                if (createdLocation.success) {
                    console.log("createdLocation.message == ",createdLocation.message)
                    toast.success(createdLocation.message);
                } else {
                    toast.error(createdLocation);
                }
                props.onLocationCreated();
            }

            setFormData({
                location: '',
                distance: '',
                status: '',
            });
        }  catch (error) {
            console.log("error == ",error)
            return toast.error(error.response.data.message)
        }
    };

    return (


        <div>
            <Modal show={props?.show} centered aria-labelledby="contained-modal-title-vcenter">
                <Modal.Header closeButton onClick={props?.handleCloseModal}>
                    <Modal.Title> {props.title}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {errorMessage && (
                        <div className="alert alert-danger">{errorMessage}</div>
                    )}
                    {successMessage && (
                        <div className="alert alert-success">{successMessage}</div>
                    )}
                    {/* <Form onSubmit={handleSubmit}> */}
                        <Row>
                            <Col md={6}>
                                <Form.Group>
                                    <Form.Label>Location</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="location"
                                        placeholder="Enter Locatoin"
                                        value={formData.location}
                                        onChange={(e) =>
                                            setFormData({ ...formData, location: e.target.value })
                                        }
                                        required
                                    />
                                </Form.Group>
                            </Col>
                            <Col md={6}>
                                <Form.Group>
                                    <Form.Label> Distance(km)</Form.Label>
                                    <Form.Control
                                        type="number"
                                        placeholder="Enter Distance"
                                        name="distance"
                                        value={formData.distance}
                                        onChange={(e) =>
                                            setFormData({ ...formData, distance: e.target.value })
                                        }
                                        required
                                    />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>

                            <Col md={6} className='mt-4 mb-2'>
                                <Form.Group>
                                    <Form.Label>Status</Form.Label>
                                    <Form.Select
                                        name="Status"
                                        value={formData.status}
                                        onChange={(e) =>
                                            setFormData({ ...formData, status: e.target.value })
                                        }
                                        required
                                    >
                                        <option value="">--Select Status--</option>
                                        <option value="Active">Active</option>
                                        <option value="Inactive">InActive</option>
                                    </Form.Select>
                                </Form.Group>
                            </Col>
                        </Row>
                </Modal.Body>
                <Modal.Footer>
                
                        <Button variant="secondary"
                            onClick={props?.handleCloseModal}
                        >
                            Cancel
                        </Button>
                            {isUpdateMode ? (
                                <Button  className="mx-3" variant="primary" onClick={handleUpdate}>
                                    Update 
                                </Button>
                            ) : (
                                <Button  className="mx-3" type="button" variant="primary" onClick={handleSubmit}>
                                    Save
                                </Button>
                            )}
                        
                </Modal.Footer>
            </Modal>
        </div>
    );
};

export default LocationMaster;