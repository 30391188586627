import React, { useState, useEffect } from "react";
import { Button, Row, Col, Container } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import schoolApi from "../../api/schoolApi";
import { useNavigate, Link, useLocation } from "react-router-dom";
import Select from "react-select";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { v4 as uuidv4 } from "uuid";
import Confirm from "../Confirm";
import { Helmet } from "react-helmet";

const AddAssignSubjectClass = (props) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [selectedClass, setSelectedClass] = useState(
    location?.state?.classId ? location?.state?.classId : ""
  );
  const [deleteassignsubject, setDeleteassignsubject] = useState([]);
  const [optionClass, setOptionClass] = useState([]);
  const [allSubjects, setAllSubjects] = useState([]);
  const [statusRow, setStatusRow] = useState(undefined);
  const [rowRecord, setRowRecord] = useState([
    {
      id: uuidv4(),
      selectedSubject: {
        id: "",
        name: "",
        shortname: "",
        category: "",
        type: "",
      },
    },
  ]);

  let subjectOptions = allSubjects.map((subject) => ({
    value: subject.id,
    label: subject.name,
  }));

  useEffect(() => {
    async function init() {
      try {
        const result = await schoolApi.fetchSubject();
        console.log("result ->", result);

        if (result.success) {
          setAllSubjects(result.result);
        } else {
         // toast.error(result.message);
          setAllSubjects([]);
        }

        // Handle existing assigned subjects
        try {
          const resultAsinSub = await schoolApi.getAssignSubjectClassRecords();
          const responce = await schoolApi.getActiveClassRecords();

          if (responce.success) {
            let ar = [];
            responce.result.forEach((item) => {
              let isUnique = true;
              let recordsData = resultAsinSub.records;
              if (Array.isArray(recordsData)) {
                recordsData.forEach((itemsub) => {
                  if (item.id === itemsub.class_id) {
                    isUnique = false;
                  }
                });
              }
              if (isUnique) {
                ar.push({ value: item.id, label: item.classname });
              }
            });
            if (location?.state?.classId) {
              ar.push({
                value: location.state.classId,
                label: location.state.classname,
              });
            }
            setOptionClass(ar);
          } else {
            console.log("");
            setOptionClass([]);
          }
        } catch (error) {
          console.log("error ->", error);
          setOptionClass([]);
          // if (error.response.data.message) {
          //   toast.error(error.response.data.message);
          // } else if (error.response.data.errors) {
          //   toast.error(error.response.data.errors);
          // } else {
          //   toast.error("Something went wrong");
          // }
        }

        // Filter out already assigned subjects
        if (location?.state?.classId) {
          const responce = await schoolApi.getAssignSubjectClassIdByRecords(
            location?.state?.classId
          );

          if (responce.success) {
            const assignedSubjects = responce.records.map(
              (item) => item.subject_id
            );
            const filteredSubjects = result.result.filter(
              (subject) => !assignedSubjects.includes(subject.id)
            );

            setAllSubjects(filteredSubjects);

            const updatedRowRecords = responce.records.map((item) => ({
              id: item.id,
              selectedSubject: {
                id: item.subject_id,
                name: item.subjectname,
                shortname: item.shortname,
                category: item.category,
                type: item.type,
              },
            }));
            updatedRowRecords.push({
              id: uuidv4(),
              selectedSubject: {
                id: "",
                name: "",
                shortname: "",
                category: "",
                type: "",
              },
            });

            setRowRecord(updatedRowRecords);
          }
        }
      } catch (error) {
        console.log("error ->", error);
        setRowRecord([
          {
            id: uuidv4(),
            selectedSubject: {
              id: "",
              name: "",
              shortname: "",
              category: "",
              type: "",
            },
          },
        ])
        return toast.error("Create subject records first");
        // if (error?.response?.data?.message && error?.response?.data) {
        //   toast.error(error.response.data.message);
        // } else {
        //   toast.error("Something went wrong");
        // }
      }
    }
    init();
  }, []);

  // Select Class Name
  const selectedClassName = (e) => {
    setSelectedClass(e.target.value);
  };

  // Remove Rows
  const removeRows = async (index, rowId, item) => {
    setStatusRow({ index, rowId, item });
  };
  const removesubject = () => {
    const updatedRowRecord = rowRecord.filter(
      (rowItem) => rowItem.id !== statusRow.rowId
    );

    if (rowRecord.length === 1) {
      toast.error("Cannot delete the last row.");
    } else {
      if (location?.state?.classId) {
        deleteassignsubject.push(statusRow.rowId);
        console.log("deleteassignsubject ->", deleteassignsubject);
      //  toast.success("Record deleted Successfuly"); //added by muskan khan
      }
      setRowRecord(updatedRowRecord);
      if (statusRow.item?.selectedSubject?.id) {
        setAllSubjects([...allSubjects, statusRow.item.selectedSubject]);
      }
    }
    setStatusRow(undefined);
  };
  const handleSubjectChange = (selectOption, rowId, item) => {
    const selectedSubject = allSubjects.find(
      (subject) => subject.id === selectOption.value
    );

    if (item?.selectedSubject?.id) {
      allSubjects.push(item.selectedSubject);
    }
    let updatedAllSubjects = allSubjects.filter(
      (subject) => subject.id !== selectOption.value
    );

    const updatedRowRecords = rowRecord.map((record) => {
      if (record.id === rowId) {
        return { ...record, selectedSubject };
      } else {
        return record;
      }
    });

    const lastRow = updatedRowRecords[updatedRowRecords.length - 1];

    if (lastRow.selectedSubject.id !== "") {
      const newRow = {
        id: uuidv4(),
        selectedSubject: {
          id: "",
          name: "",
          shortname: "",
          category: "",
          type: "",
        },
      };
      updatedRowRecords.push(newRow);
    }

    setRowRecord(updatedRowRecords);
    setAllSubjects(updatedAllSubjects);
  };

  // Save Record
  const handleSubmit = async (e) => {
    e.preventDefault();
    let array = [];
    try {
      if (deleteassignsubject.length > 0) {
        deleteassignsubject.forEach(async (id) => {
          const deleteresult = await schoolApi.deleteAssignSubjectClass(id);
          console.log("deleteresult ->", deleteresult);
        });
      }

      if (selectedClass) {
        rowRecord.forEach((item) => {
          if (item.selectedSubject.id) {
            array.push({
              subject_id: item.selectedSubject.id,
              class_id: selectedClass,
              id: item.id ? item.id : "",
            });
          }
        });
      }
      console.log("array ->", array);
      let allRecordsSaved = true;
      if (array.length) {
        for (let obj of array) {
          const response = await schoolApi.addAssignSubjectClassRecord(obj);
          if (
            response.success === false &&
            response.message === "Record is already exist"
          ) {
            toast.error("Record is already exist");
            return;
          } else {
            console.log("deleteassignsubject ->", deleteassignsubject);
          }
               //added by muskan khan
        }
        if (allRecordsSaved) {
          toast.success("Record saved successfully");
          setTimeout(() => {
            navigate("/assignsubjectclass");
          }, 2000);
        } 
      } else {
        toast.error("Class name and subject name is required!!");
      }
    } catch (error) {
      console.log("error ->", error);
      if (error?.response?.data?.message && error?.response?.data) {
        toast.error(error.response.data.message);
      } else {
        toast.error("something went wrong");
      }
    }
  };

  // Handle Cancel
  const handleCancel = () => {
    navigate(`/assignsubjectclass`);
  };

  return (
    <>
      <Helmet>
        <title>{props?.tabName}</title>
      </Helmet>

      <Row className="g-0">
        <Col lg={10} className="mx-4">
          <Link className="nav-link" to="/assignsubjectclass">
            Home <i className="fa-solid fa-chevron-right"></i> Subject{" "}
            <i className="fa-solid fa-chevron-right"></i>
            {location?.state?.classId ? " Edit" : " Add"} Assign Subject Class
          </Link>
        </Col>
      </Row>

      <Container className="mt-5 pt-5">
        <Row className="mx-3">
          <Col></Col>
          <Col lg={10}>
            <Row className="view-form-header align-items-center">
              <Col lg={3}> Add Assign Subject Class</Col>
              <Col lg={9} className="d-flex justify-content-end">
                <Button
                  className="btn-sm mx-2"
                  variant="danger"
                  onClick={handleCancel}
                >
                  Cancel
                </Button>
                <Button
                  className="btn-sm"
                  variant="success"
                  onClick={handleSubmit}
                >
                  Save
                </Button>
              </Col>
            </Row>
          </Col>
          <Col></Col>
        </Row>
      </Container>

      <Container>
        <Row className="mt-2 mx-3">
          <Col></Col>
          <Col lg={10}>
            <Row>
              <Col lg={3}>
                <Form.Group>
                  <Form.Label>Class Name</Form.Label>
                  <Form.Select
                    name="class"
                    required
                    disabled={location?.state?.classId ? true : false}
                    value={selectedClass}
                    onChange={selectedClassName}
                  >
                    {optionClass.length === 0 ? (
                      <option value="">No Records Available</option>
                    ) : (
                      <>
                        <option value="">-- Select Class --</option>
                        {optionClass.map((cls) => (
                          <option key={cls.value} value={cls.value}>
                            {cls.label}
                          </option>
                        ))}
                      </>
                    )}
                  </Form.Select>
                </Form.Group>
              </Col>
            </Row>
          </Col>
          <Col></Col>
        </Row>
      </Container>

      {selectedClass && (
        <Container>
          <Row>
            <Col></Col>
            <Col lg={10}>
              <Form className="mt-3">
                <div className="container">
                  <div className="row clearfix">
                    <div className="col-md-12 column">
                      <table
                        className="table table-bordered table-hover"
                        id="tab_logic"
                      >
                        <thead>
                          <tr>
                            <th className="text-center">S. No.</th>
                            <th className="text-center">Subject Name</th>
                            <th className="text-center">Short Name</th>
                            <th className="text-center">Category</th>
                            <th className="text-center">Type</th>
                            <th className="text-center">Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {rowRecord.map((item, index) => (
                            <tr id="addr0" key={item.id}>
                              <td>{index + 1}</td>
                              <td>
                                <Select
                                  options={subjectOptions}
                                  value={
                                    item.selectedSubject?.id
                                      ? {
                                          value: item.selectedSubject.id,
                                          label: item.selectedSubject.name,
                                        }
                                      : null
                                  }
                                  onChange={(selectedOption) =>
                                    handleSubjectChange(
                                      selectedOption,
                                      item.id,
                                      item
                                    )
                                  }
                                  menuPlacement={index < 5 ? "bottom" : "top"}
                                  menuPortalTarget={document.body}
                                />
                              </td>
                              <td>
                                <input
                                  type="text"
                                  className="form-control"
                                  name="shortname"
                                  value={item.selectedSubject.shortname}
                                  readOnly
                                />
                              </td>
                              <td>
                                <input
                                  type="text"
                                  className="form-control"
                                  name="category"
                                  value={item.selectedSubject.category}
                                  readOnly
                                />
                              </td>
                              <td>
                                <input
                                  type="text"
                                  className="form-control"
                                  name="type"
                                  value={item.selectedSubject.type}
                                  readOnly
                                />
                              </td>
                              <td className="text-center">
                                <Button
                                  size="sm"
                                  variant="danger"
                                  disabled={!item.selectedSubject.name}
                                  onClick={() =>
                                    removeRows(index, item.id, item)
                                  }
                                >
                                  <i
                                    className="fa fa-trash"
                                    aria-hidden="true"
                                  ></i>
                                </Button>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </Form>
            </Col>
            <Col></Col>
          </Row>
        </Container>
      )}
      {statusRow && (
        <Confirm
          irm
          show={statusRow}
          onHide={() => setStatusRow(undefined)}
          changeClassStatus={removesubject}
          title={`Confirm delete`}
          message="You are going to delete the subject. Are you sure?"
          table="classList"
        />
      )}
      <ToastContainer
        position="top-right"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </>
  );
};

export default AddAssignSubjectClass;