/**
 * @author      Abdul Pathan
 * @date        Aug, 2024
 * @copyright   www.ibirdsservices.com
 */

import React, { useEffect, useState } from "react";
import { Col, Form, Modal, Row } from "react-bootstrap";
import WhatsAppAPI from "../../../api/schoolApi";
// import WhatsAppAPI from '../../api/WhatsAppAPI';
import { toast } from "react-toastify"; //npm i react-toastify --force
import "react-toastify/dist/ReactToastify.css";
import moment from "moment-timezone";

const MessageTemplateModal = ({
  show,
  onHide,
  contactData,
  refreshData,
  filterData,
}) => {
  const [allTemplateData, setAllTemplateData] = useState([]);
  const [selectedTemplateName, setSelectedTemplateName] = useState("");
  const [textMessage, setTextMessage] = useState("");
  const [isSpinner, setIsSpinner] = useState(false);
  const [body, setBody] = useState();

  useEffect(() => {
    fetchAllTemplate();
  }, []);

  const fetchAllTemplate = async () => {
    try {
      const result = await WhatsAppAPI.getApprovedTemplates();
      // const result = null;
      if (result.error) {
        setAllTemplateData([]);
        setIsSpinner(true);
      } else {
        const transformedData = result?.data.map((row) => {
          const header =
            row.components.find((component) => component.type === "HEADER") ||
            {};
          const body =
            row.components.find((component) => component.type === "BODY") || {};
          const footer =
            row.components.find((component) => component.type === "FOOTER") ||
            {};
          const buttons =
            row.components.find((component) => component.type === "BUTTONS")
              ?.buttons || [];
          return {
            id: row.id,
            name: row.name,
            templatename: row.name.replace(/_/g, " "),
            language: row.language,
            status: row.status,
            category: row.category,
            header: header.format || "",

            header_text: header.format === "TEXT" ? header.text : "",
            header_image_url:
              header.format === "IMAGE"
                ? header.example?.header_handle?.[0] || ""
                : "",
            header_document_url:
              header.format === "DOCUMENT"
                ? header.example?.header_handle?.[0] || ""
                : "",
            header_video_url:
              header.format === "VIDEO"
                ? header.example?.header_handle?.[0] || ""
                : "",

            message_body: body.text || "",
            example_body_text: body.example?.body_text || [],
            footer: footer.text || "",

            add_security_recommendation:
              body.add_security_recommendation || false,
            code_expiration_minutes: footer.code_expiration_minutes || null,

            buttons: buttons
              .map((button) => {
                if (button.type === "OTP") {
                  return {
                    type: button.type,
                    otp_type: button.otp_type,
                    supported_apps:
                      button.supported_apps?.map((app) => ({
                        package_name: app.package_name,
                        signature_hash: app.signature_hash,
                      })) || [],
                  };
                } else {
                  return {
                    type: button.type,
                    text: button.text,
                    ...(button.type === "PHONE_NUMBER" && {
                      phone_number: button.phone_number,
                    }),
                    ...(button.type === "URL" && { url: button.url }),
                  };
                }
              })
              .filter((button) => button.text && button.type),
          };
        });

        console.log("transformedData", transformedData);
        setAllTemplateData(transformedData);
        setIsSpinner(true);
      }
    } catch (err) {
      console.log("error ->", err);
    }
  };

  const handleChange = async (event) => {
    const selectedName = event.target.value;

    const template = allTemplateData.find((t) => t.name === selectedName);

    console.log("###template", template);

    if (template) {
      const {
        id,
        name,
        language,
        category,
        message_body,
        header_text,
        footer,
        example_body_text,
      } = template;
      const exampleValue =
        example_body_text.length > 0 && example_body_text[0].length > 0
          ? example_body_text[0][0]
          : "";
      const finalMessageBody = message_body
        .replace("*{{1}}*", exampleValue)
        .trim();
      const combinedMessage =
        `${header_text}\n${finalMessageBody}\n${footer}`.trim();
      const url =
        template.header_image_url ||
        template.header_document_url ||
        template.header_video_url ||
        "";
      const formattedTemplate = {
        id,
        name,
        language,
        category,
        url,
        body_message: combinedMessage,
      };
      console.log(formattedTemplate);

      setSelectedTemplateName(formattedTemplate);
    } else {
      setSelectedTemplateName();
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    console.log("selectedTemplateName", selectedTemplateName);
    console.log("contactData->", contactData);
    if (!contactData[0].whatsapp_number) {
      let result = await WhatsAppAPI.fetchGroupsById(contactData[0].id);
      const groupmember = result.records;
      console.log("groupmember->", groupmember);
      //setIsSpinner(true);
      contactData = groupmember.members;
    }
    console.log("contactData->", contactData);

    // let result = await WhatsAppAPI.fetchGroupsById(contactData[0].id);
    // const groupmember = result.records;
    // console.log("groupmember->", groupmember);

    setIsSpinner(true);
    let successCount = 0;
    try {
      for (const contact of contactData) {
        const textMsgBody = textMessage || "Default text message";
        const isTextMessage = Boolean(textMessage);
        const singleText = {
          messaging_product: "whatsapp",
          recipient_type: "individual",
          to: `91${contact.whatsapp_number}`,
          type: isTextMessage ? "text" : "template",
          text: isTextMessage
            ? {
                preview_url: false,
                body: textMsgBody,
              }
            : undefined,
          template: isTextMessage
            ? undefined
            : {
                name: selectedTemplateName.name,
                language: {
                  code: selectedTemplateName.language,
                },
                components: [
                  {
                    type: "header",
                    parameters: [],
                  },
                ],
              },
        };

        if (!isTextMessage && selectedTemplateName.url) {
          singleText.template.components[0].parameters.push({
            type: "image",
            image: {
              link: selectedTemplateName.url,
            },
          });
        }

        console.log("###singleText", singleText);

        const result = isTextMessage
          ? await WhatsAppAPI.sendSingleWhatsAppTextMessage(singleText)
          : await WhatsAppAPI.sendWhatsAppTemplateMessage(singleText);

        console.log("##result", result);
        if (result.error) {
          const msgError = result.error.error_data;
          toast.error(
            `Error sending to ${contact.contactname}: ${
              msgError.details || result.error
            }`
          );
        } else {
          successCount++;
          console.log("###single Message success", result);
          console.log("contact->", contact);

          const newMessage = {
            parent_id: contact.id || null,
            name:
              contact.contactname ||
              `${contact.member_firstname} ${contact.member_lastname}` ||
              "",
            message_template_id: isTextMessage
              ? null
              : (
                  await WhatsAppAPI.createMessageTemplateData(
                    selectedTemplateName
                  )
                ).id || null,
            whatsapp_number: contact.whatsapp_number,
            message: isTextMessage ? textMsgBody : "",
            status: "Outgoing",
            recordtypename: filterData.recordType || "",
            file_id: null,
          };

          const response = await WhatsAppAPI.insertMsgHistoryRecords(
            newMessage
          );
          console.log("response", response);
        }
      }

      if (successCount > 0) {
        toast.success(`${successCount} messages sent successfully!`);
      }
    } catch (error) {
      console.error("Error in sending messages:", error);
      toast.error("Failed to send messages.");
    } finally {
      setIsSpinner(false);
      refreshData();
    }
  };

  const isFormValid = Boolean(selectedTemplateName || textMessage);

  return (
    <>
      <Modal show={show} animation={false} size="lg">
        <Modal.Header closeButton onClick={onHide}>
          <Modal.Title id="contained-modal-title-vcenter">
            Send WhatsApp Message
          </Modal.Title>
        </Modal.Header>
        {isSpinner ? (
          <>
            <Modal.Body>
              <Form noValidate>
                <Row className="p-2 mb-3">
                  <Col lg={12} sm={12} xs={12}>
                    <Form.Group className="mb-4">
                      <Form.Label
                        className="form-view-label"
                        htmlFor="formBasicFirstName"
                      >
                        Template Name
                      </Form.Label>
                      <Form.Select
                        style={{ height: "36px" }}
                        aria-label="select name"
                        name="templateName"
                        onChange={(e) => {
                          handleChange(e);
                          if (textMessage) {
                            setTextMessage("");
                          }
                        }}
                        placeholder="Select Template Name"
                        // value={templateName}
                      >
                        <option value="">Select Template Name</option>
                        {allTemplateData?.map((template) => (
                          <option key={template.id} value={template.name}>
                            {template.templatename}
                          </option>
                        ))}
                      </Form.Select>
                    </Form.Group>
                  </Col>

                  {!selectedTemplateName && (
                    <Col lg={12} sm={12} xs={12} className="mt-2">
                      <Form.Group controlId="formTextMessage">
                        <Form.Label>Text Message</Form.Label>
                        <Form.Control
                          as="textarea"
                          rows={3}
                          value={textMessage}
                          onChange={(e) => {
                            setTextMessage(e.target.value);
                          }}
                        />
                      </Form.Group>
                    </Col>
                  )}
                </Row>
              </Form>
            </Modal.Body>

            <Modal.Footer>
              <button className="btn btn-light" onClick={onHide}>
                Close
              </button>
              <button
                className="btn btn-outline-secondary"
                onClick={handleSubmit}
                disabled={!isFormValid}
              >
                Send Message
              </button>
            </Modal.Footer>
          </>
        ) : (
          <div className="sk-cube-grid">
            <div className="sk-cube sk-cube1"></div>
            <div className="sk-cube sk-cube2"></div>
            <div className="sk-cube sk-cube3"></div>
            <div className="sk-cube sk-cube4"></div>
            <div className="sk-cube sk-cube5"></div>
            <div className="sk-cube sk-cube6"></div>
            <div className="sk-cube sk-cube7"></div>
            <div className="sk-cube sk-cube8"></div>
            <div className="sk-cube sk-cube9"></div>
          </div>
        )}
      </Modal>
    </>
  );
};

export default MessageTemplateModal;
