/**
 * @author      Abdul Pathan
 * @date        Aug, 2024
 * @copyright   www.ibirdsservices.com
 */

import React, { useEffect, useState } from "react";
import {
  Form,
  Container,
  Row,
  Col,
  Card,
  Button,
  DropdownButton,
} from "react-bootstrap";
import WhatsAppChat from "./WhatsAppChat";
import WhatsAppAPI from "../../../api/schoolApi";
import jwt_decode from "jwt-decode";

import { ToastContainer, toast } from "react-toastify"; //npm i react-toastify --force
import "react-toastify/dist/ReactToastify.css";
// import TemplateModal from './TemplateModal';
import { NameInitialsAvatar } from "react-name-initials-avatar"; // npm install react-name-initials-avatar --force
import Select from "react-select";
import MessageTemplateModal from "./MessageTemplateModal";
import { MdFilterAlt } from "react-icons/md";
import { Link } from "react-bootstrap-icons";

const WhatsAppMessenger = ({ socket }) => {
  const [body, setBody] = useState([]);
  const [showHide, setShowHide] = useState(false);
  const [userData, setUserData] = useState();
  const [filters, setFilters] = useState({
    textName: "",
    classes: "",
    section: "",
    date: "",
    sessionId: "",
    recordType: "student",
  });
  const [receivedMessage, setReceivedMessage] = useState();
  const [showHideModal, setShowHideModal] = useState(false);
  const [Classes, setClassOptions] = useState([]);
  const [Section, setSectionOptions] = useState([]);

  // const [userInfo, setUserInfo] = useState(jwt_decode(localStorage.getItem('token')));
  const [bgColors, setBgColors] = useState([
    "#d3761f",
    "#00ad5b",
    "#debf31",
    "#239dd1",
    "#b67eb1",
    "#d3761f",
    "#de242f",
  ]);
  const [brokenImages, setBrokenImages] = useState([]);
  let colIndex = 0;

  const [selectAll, setSelectAll] = useState(false);
  const [selectedItems, setSelectedItems] = useState([]);
  const profileImage = "/user_images/users";
  const [unreadMsgCount, setUnreadMsgCount] = useState([]);
  const [userInfo, setUserInfo] = useState({});
  const [showDropdowns, setShowDropdowns] = useState(false);
  const toggleDropdowns = () => {
    setShowDropdowns(!showDropdowns);
  };
  useEffect(() => {
    socket?.on("receivedwhatsappmessage", (data) => {
      setReceivedMessage(data);
    });
  }, [socket]);

  useEffect(() => {
    fetchData();
    getUnreadMsgCounts();
  }, [filters, receivedMessage]);

  const fetchData = async () => {
    try {
      if (sessionStorage.getItem("token")) {
        let user = jwt_decode(sessionStorage.getItem("token"));
        setUserInfo(user);
      }
      console.log("filters ->", filters);
      if (
        filters.recordType === "employee" ||
        filters.recordType === "recentlyMessage" ||
        filters.recordType === "groups" ||
        filters.recordType === "enquiry"
      ) {
        setShowDropdowns(false);
      }
      const { textName, classes, recordType, sessionId, section } = filters;
      let result = await WhatsAppAPI.getFilterData(
        textName,
        classes,
        recordType,
        section,
        sessionId
      );
      if (result.success) {
        setBody(result.records);
      } else {
        setBody([]);
      }
    } catch (error) {
      setBody([]);

      console.log(error);
    }
  };

  useEffect(() => {
    async function init() {
      try {
        let classList = await WhatsAppAPI.getActiveClassRecords();
        console.log("classListclassList-->", classList);
        if (classList?.result) {
          let ar = [{ value: "", label: "--Select All--" }];
          classList?.result.forEach((item) => {
            var obj = {};
            obj.value = item.id;
            obj.label = item.classname;
            ar.push(obj);
          });
          const sessions = await WhatsAppAPI.fetchSessions();
          let fetchSelectedSessionResult = null;
          if (sessions) {
            const currentYear = new Date().getFullYear();
            const nextYear = currentYear + 1;

            fetchSelectedSessionResult = sessions.find((res) => {
              return res.year === `${currentYear}-${nextYear}`;
            });
          }
          setFilters((prevFilters) => ({
            ...prevFilters,
            sessionId: fetchSelectedSessionResult.id
              ? fetchSelectedSessionResult.id
              : "",
          }));
          setClassOptions(ar);
        } else {
          setClassOptions([]);
        }
      } catch (error) {
        console.log("error ->", error);
      }
    }
    init();
  }, []);
  useEffect(() => {
    async function section() {
      try {
        let sectionList = await WhatsAppAPI.getSectionRecordById(
          filters.classes
        );

        console.log("sh section list == ", sectionList);

        if (sectionList.success) {
          let ar = [{ value: "", label: "--Select All--" }];
          sectionList?.record.forEach((item) => {
            var obj = {};
            obj.value = item.id;
            obj.label = item.name;
            ar.push(obj);
          });
          setSectionOptions(ar);
        } else {
          setSectionOptions([]);
        }
      } catch (error) {
        setSectionOptions([]);
        console.log("error ->", error);
      }
    }
    section();
  }, [filters.classes]);

  const getUnreadMsgCounts = async () => {
    let result = await WhatsAppAPI.fetchUnreadMsgCount();
    // let result = { success: false };
    if (result.success) {
      setUnreadMsgCount(result.records);
    } else {
      setUnreadMsgCount([]);
    }
  };

  const handleChange = async (event) => {
    setShowHide(false);
    const { name, value } = event.target;
    setFilters((prevFilters) => ({
      ...prevFilters,
      [name]: value.trim(),
    }));

    setSelectedItems([]);
    setSelectAll(false);
  };

  const handleUserClick = (item, unreadCount) => {
    setShowHide(true);
    setUserData(item);

    if (item.whatsapp_number && unreadCount > 0) {
      markMessagesAsRead(item.whatsapp_number);
    }
  };
  const handleClassChange = (selectedOption) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      classes: selectedOption ? selectedOption.value : "",
      section: "",
    }));
  };
  const handleSectionChange = (selectedOption) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      section: selectedOption ? selectedOption.value : "",
    }));
  };
  const markMessagesAsRead = async (whatsapp_number) => {
      let result = await WhatsAppAPI.markMessagesAsRead(whatsapp_number);
    // let result = { success: false };
    if (result.success) {
      getUnreadMsgCounts();
    }
  };

  const handleSelectAll = () => {
    if (!selectAll) {
      setSelectedItems([...body]);
    } else {
      setSelectedItems([]);
    }
    setSelectAll(!selectAll);
  };

  const handleCheckboxChange = (event, item) => {
    if (event.target.checked) {
      setSelectedItems((prevSelected) => [...prevSelected, item]);
    } else {
      setSelectedItems((prevSelected) =>
        prevSelected.filter((selectedItem) => selectedItem.id !== item.id)
      );
    }
  };

  const isAllSelected = selectedItems.length === body.length;

  const sendMessagesBulk = (e) => {
    setShowHide(false);
    if (selectedItems.length > 0) {
      if (selectedItems[0].whatsapp_number) {
        setShowHideModal(true);
      } else {
        if (selectedItems.length === 1) {
          setShowHideModal(true);
        } else {
          toast.error("Please select only one group at a time.");
        }
      }
    } else {
      toast.error("Please select at least one item.");
    }
  };

  const refreshData = () => {
    fetchData();
    setShowHideModal();
    setSelectedItems([]);
    setSelectAll(false);
  };

  const fillBgBolor = () => {
    colIndex += 1;
    if (colIndex >= bgColors.length) colIndex = 0;
    return bgColors[colIndex];
  };

  return (
    <>
     <Container className="mt-3 mb-2">
        <Row>
          <Col lg={4} className="mx-2">
            <Link className="nav-link" to="/">
              Home <i className="fa-solid fa-chevron-right"></i> Whatsapp Messesger
            </Link>
          </Col>
        </Row>
      </Container>

      <Container className="mt-2 mb-5">
        <Row className="g-0 mx-5">
          <Col lg={5} sm={12} xs={12} className="mb-3">
            <Card className="h-100">
              <Card.Header className="p-3 bg-primary">
                <Row className="g-0 ">
                  <Col
                    lg={8}
                    xs={6}
                    sm={6}
                    style={{ display: "flex", alignItems: "stretch" }}
                  >
                    <i
                      className="fa-brands fa-whatsapp"
                      style={{ fontSize: "30px", color: "white" }}
                    ></i>
                    <span
                      className="mx-3 mt-1 fw-bold"
                      style={{ fontSize: "15px", color: "white" }}
                    >
                      {userInfo.companyname} College
                    </span>
                  </Col>
                  <Col lg={4} xs={6} sm={6} className="text-end">
                  <Button
                      className="btn-light add-new-button"
                      style={{ color: "black" }}
                      variant=""
                      onClick={sendMessagesBulk}
                    >
                      Send Bulk Messages
                    </Button>
                  </Col>
                </Row>
              </Card.Header>

              <Card.Body className="mb-5">
                <div style={{ height: "30rem" }}>
                  <Row className="g-0">
                    <Col lg={12} sm={12} xs={12} className="mb-2">
                      <Row className="mt-2">
                        <Col lg={5} xs={12} sm={12}>
                          <Form.Group className="mb-3">
                            <Form.Control
                              style={{ height: "38px" }}
                              type="text"
                              name="textName"
                              placeholder="Search by name..."
                              onChange={handleChange}
                            />
                          </Form.Group>
                        </Col>
                        <Col lg={5} xs={12} sm={12}>
                          <Form.Group className="mb-3">
                            <Form.Select
                              style={{ height: "38px" }}
                              aria-label="select name"
                              name="recordType"
                              onChange={handleChange}
                            >
                              <option value="student">Student</option>
                              <option value="parent">Parent</option>
                              <option value="employee">Employee</option>
                              <option value="enquiry">Enquiry</option>
                              <option value="recentlyMessage">
                                Recently Message
                              </option>
                              <option value="groups">Groups</option>
                            </Form.Select>
                          </Form.Group>
                        </Col>
                        <Col
                          lg={2}
                          xs={12}
                          sm={12}
                          style={{ position: "relative" }}
                        >
                          <Button
                            variant="outline-secondary"
                            className={
                              filters.recordType === "employee" ||
                              filters.recordType === "recentlyMessage" ||
                              filters.recordType === "groups" ||
                              filters.recordType === "enquiry"
                                ? "d-none"
                                : ""
                            }
                            onClick={toggleDropdowns}
                            style={{
                              padding: "8px 16px",
                              borderRadius: "5px",
                              marginRight: "10px",
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            <MdFilterAlt
                              style={{
                                marginRight: "5px",
                                fontSize: "18px",
                              }}
                            />
                          </Button>

                          {showDropdowns && (
                            <div
                              style={{
                                position: "absolute",
                                top: "40px",
                                right: "0",
                                padding: "10px",
                                backgroundColor: "#fff",
                                boxShadow: "0 4px 8px rgba(0,0,0,0.1)",
                                borderRadius: "5px",
                                zIndex: 1000,
                                width: "250px",
                              }}
                            >
                              <Form.Group className="mb-3">
                                <Form.Label>Select Class</Form.Label>
                                <Select
                                  options={Classes}
                                  placeholder="Select Class"
                                  onChange={handleClassChange}
                                  value={
                                    Classes.find(
                                      (option) =>
                                        option.value === filters.classes
                                    ) || null
                                  }
                                />
                              </Form.Group>

                              <Form.Group className="mb-3">
                                <Form.Label>Select Section</Form.Label>
                                <Select
                                  options={Section}
                                  placeholder="Select Section"
                                  onChange={handleSectionChange}
                                  value={
                                    Section.find(
                                      (option) =>
                                        option.value === filters.section
                                    ) || null
                                  }
                                />
                              </Form.Group>
                            </div>
                          )}
                        </Col>
                        {/*<Col lg={2} xs={12} sm={12}>
                          <Button
                            className="btn"
                            variant="outline-secondary"
                            //onClick={addFilter}
                            style={{ marginLeft: "10px" }}
                          >
                            <span className="d-inline-flex align-items-center">
                              <MdFilterAlt
                                style={{
                                  marginRight: "0px",
                                  paddingTop: "2px",
                                  fontSize: "15px",
                                }}
                              />
                            </span>
                          </Button>
                        </Col>
                         <Col lg={4} xs={12} sm={12}>
                          <Form.Group className="mb-3">
                            <Select
                              isDisabled={
                                filters.recordType === "employee" ||
                                filters.recordType === "recentlyMessage" ||
                                filters.recordType === "groups" ||
                                filters.recordType === "enquiry"
                              }
                              options={Classes}
                              placeholder="Select Class"
                              onChange={handleClassChange}
                              value={
                                Classes.find(
                                  (option) => option.value === filters.classes
                                ) || null
                              }
                            />
                          </Form.Group>
                        </Col>
                        <Col lg={4} xs={12} sm={12}>
                          <Form.Group className="mb-3">
                            <Select
                              isDisabled={
                                filters.recordType === "employee" ||
                                filters.recordType === "recentlyMessage" ||
                                filters.recordType === "groups" ||
                                filters.recordType === "enquiry"
                              }
                              options={Classes}
                              placeholder="Select Section"
                              onChange={handleClassChange}
                              value={
                                Classes.find(
                                  (option) => option.value === filters.classes
                                ) || null
                              }
                            />
                          </Form.Group>
                        </Col> */}
                      </Row>
                    </Col>

                    <hr></hr>

                    <div
                      className="table-container mt-3"
                      style={{ maxHeight: "25rem", overflowY: "auto" }}
                    >
                      <Col lg={12} sm={12} xs={12}>
                        {body.length > 0 ? (
                          <table className="table">
                            <thead>
                              <tr>
                                <th style={{ width: "20px" }}>
                                  <input
                                    className="mx-1"
                                    name="selectName"
                                    type="checkbox"
                                    checked={isAllSelected}
                                    onChange={handleSelectAll}
                                    style={{ cursor: "pointer" }}
                                  />
                                </th>
                                <th style={{ width: "310px" }} className="px-5">
                                  Name
                                </th>
                                <th className="px-5"></th>
                              </tr>
                            </thead>
                            <tbody>
                              {body?.map((item) => {
                                const unreadCount = unreadMsgCount.find(
                                  (unread) =>
                                    unread.whatsapp_number ===
                                    item.whatsapp_number
                                )?.unread_msg_count;
                                return (
                                  <tr key={item.id}>
                                    <td className="pt-3">
                                      <input
                                        className="mx-1"
                                        name={`select-${item.id}`}
                                        type="checkbox"
                                        checked={selectedItems.some(
                                          (selectedItem) =>
                                            selectedItem.id === item.id
                                        )}
                                        onChange={(event) =>
                                          handleCheckboxChange(event, item)
                                        }
                                        style={{ cursor: "pointer" }}
                                      />
                                    </td>
                                    <td
                                      onClick={() =>
                                        handleUserClick(item, unreadCount)
                                      }
                                      style={{ cursor: "pointer" }}
                                      className="d-flex  mx-2"
                                    >
                                      {brokenImages.includes(
                                        `img-${item?.id}`
                                      ) ? (
                                        <NameInitialsAvatar
                                          size="30px"
                                          textSize="13px"
                                          bgColor={fillBgBolor()}
                                          borderWidth="0px"
                                          textColor="#fff"
                                          name={item?.contactname}
                                        />
                                      ) : (
                                        <img
                                          alt=""
                                          style={{
                                            height: "30px",
                                            width: "30px",
                                            objectFit: "cover",
                                          }}
                                          src={profileImage + "/" + item.id}
                                          className="rounded-circle"
                                          onError={() =>
                                            setBrokenImages((prev) => [
                                              ...prev,
                                              `img-${item?.id}`,
                                            ])
                                          }
                                          id={`img-${item?.id}`}
                                        />
                                      )}
                                      <span
                                        className="mx-3 mt-1 text-capitalize"
                                        style={{ fontSize: "15px" }}
                                      >
                                        {item.contactname}
                                        {item?.whatsapp_number && (
                                          <i
                                            className="mx-2"
                                            style={{ fontSize: "10px" }}
                                          >
                                            ( {item?.whatsapp_number} )
                                          </i>
                                        )}
                                      </span>
                                    </td>
                                    <td className="pt-3">
                                      {unreadCount && (
                                        <span
                                          className="badge bg-success rounded-circle float-end mx-2"
                                          style={{ fontSize: "10px" }}
                                        >
                                          {unreadCount}
                                        </span>
                                      )}
                                    </td>
                                  </tr>
                                );
                              })}
                            </tbody>
                          </table>
                        ) : (
                          "No record exists."
                        )}
                      </Col>
                    </div>
                  </Row>
                </div>
              </Card.Body>

              <Card.Footer>
                <Row className="g-0 mt-3">
                  <Col lg={6} sm={12} xs={12} className="mt-1">
                    <p>
                      <b>Total Records: {body?.length}</b>
                    </p>
                  </Col>
                </Row>
              </Card.Footer>
            </Card>
          </Col>

          {showHide && (
            <Col lg={7} sm={12} xs={12} className="mb-3">
              <WhatsAppChat
                show={showHide}
                onHide={() => setShowHide(false)}
                userDetail={userData}
                filterData={filters}
                socket={socket}
              />
            </Col>
          )}
        </Row>
      </Container>
      <ToastContainer />

      {showHideModal && (
        <MessageTemplateModal
          show={showHideModal}
          onHide={() => setShowHideModal(false)}
          contactData={selectedItems}
          refreshData={refreshData}
          filterData={filters}
        />
      )}
    </>
  );
};

export default WhatsAppMessenger;
