import React, { useEffect, useState } from "react";
import schoolApi from "../../api/schoolApi";
import { Button, Col, Row, Table } from "react-bootstrap";
import { DatatableWrapper, TableBody, TableHeader } from "react-bs-datatable";
import jsPDF from "jspdf";
import "jspdf-autotable";

const DemandReports = () => {
  const [allDemand, setAllDemand] = useState([]);

  useEffect(() => {
    fetchAllFeesDetails();
  }, []);

  const fetchAllFeesDetails = async () => {
    try {
      const sessions = await schoolApi.fetchSessions();
      if (sessions) {
        const options = sessions.map((sess) => ({
          value: sess.id,
          label: sess.year,
        }));

        const currentSession = sessions.find((sess) => {
          const currentDate = new Date();
          const startDate = new Date(sess.startdate);
          const endDate = new Date(sess.enddate);
          return currentDate >= startDate && currentDate <= endDate;
        });

        const result = await schoolApi.getAllFeesDemand(currentSession.id);
        if (result.result) {
          setAllDemand(result.result);
        }
      }
    } catch (error) {
      console.error("Error fetching fee data:", error);
    }
  };

  const formatNumberWithCommas = (number) => {
    return number ? number.toLocaleString("en-IN") : "0";
  };

  const formatData = (data) => {
    return {
      ...data,
      total_demands: formatNumberWithCommas(data.total_demands),
      defaulter: formatNumberWithCommas(data.defaulter),
      dropouts: formatNumberWithCommas(data.dropouts),
      concession: formatNumberWithCommas(data.concession),
      final_demand: formatNumberWithCommas(data.final_demand),
    };
  };

  const formattedData = allDemand.map(formatData);

  const handleDownload = () => {
    const doc = new jsPDF({
      orientation: "landscape",
      unit: "mm",
      format: "a4",
    });

    doc.setFontSize(12);
    doc.text("Demand Report", 10, 10);

    const tableData = allDemand.map((row) => [
      formatNumberWithCommas(row.total_demands),
      formatNumberWithCommas(row.defaulter),
      formatNumberWithCommas(row.dropouts),
      formatNumberWithCommas(row.concession),
      formatNumberWithCommas(row.final_demand),
    ]);

    doc.autoTable({
      head: [
        ["Total Demand", "Defaulter", "Dropouts", "Concession", "Final Demand"],
      ],
      body: tableData,
      didDrawCell: (data) => {
        doc.setLineDash([1, 1], 0); // Set the line dash pattern here
      },
      styles: {
        lineColor: [0, 0, 0],
        lineWidth: 0.1,
      },
      headStyles: {
        fillColor: [10, 40, 108],
      },
      margin: { top: 20 },
    });

    doc.save("demand-report.pdf");
  };

  const header = [
    {
      title: "Total Demands",
      prop: "total_demands",
      isFilterable: true,
      show: true,
    },
    { title: "Defaulter", prop: "defaulter", isFilterable: true, show: true },
    { title: "Dropouts", prop: "dropouts", isFilterable: true, show: true },
    { title: "Concession", prop: "concession", isFilterable: true, show: true },
    {
      title: "Final Demand",
      prop: "final_demand",
      isFilterable: true,
      show: true,
    },
  ];

  return (
    <div>
      <Row className="g-0">
        <Col lg={12}>
          {formattedData.length > 0 ? (
            <DatatableWrapper
              body={formattedData}
              headers={header}
              paginationOptionsProps={{
                initialState: {
                  rowsPerPage: 15,
                  options: [5, 10, 15, 20],
                },
              }}
            >
              <Table striped className="data-table">
                <TableHeader />
                <TableBody />
              </Table>
            </DatatableWrapper>
          ) : (
            <p>No record found</p>
          )}
        </Col>
        <Col lg={2}></Col>
      </Row>
    </div>
  );
};

export default DemandReports;
