import React, { useState, useEffect } from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import Confirm from "../Confirm";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import schoolApi from "../../api/schoolApi";
import moment from "moment";

// import CurrencyFormat from "react-currency-format";

// import { FilesCreate, RelatedListFiles } from "../file";
import { Helmet } from "react-helmet";
import PageNavigations from "../breadcrumbs/PageNavigations";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { usePermissions } from "../contexts/PermissionContext";

const ContactView = (props) => {
  const location = useLocation();
  const navigate = useNavigate();
  //const contact = location.state;
  const [contact, setContact] = useState(location.state ? location.state : {});
  console.log("coantactats", contact);
  const [modalShow, setModalShow] = useState(false);
  const { rolePermissions, userInfo } = usePermissions();
  const canEdit = rolePermissions?.Employee_List?.can_edit;
  const canDelete = rolePermissions?.Employee_List?.can_delete;

  useEffect(() => {
    fetchContact();
  }, []);

  const fetchContact = () => {
    // Coming from Email
    try {
      if (location.hasOwnProperty("pathname")) {
        contact.id = location.pathname.split("/")[2];
      }
      async function initContact() {
        let result = await schoolApi.fetchContact(contact.id);
        console.log("@Result of Staff List Single1111 =>", result);
        if (result.success) {
          setContact(result.contactRec);
        } else {
          toast.error("Record not found");
          setContact({});
        }
      }
      initContact();
    } catch (error) {
      console.log("error ->", error);
      toast.error("Internel server error");
    }
  };

  const deleteContact = async () => {
    try {
      // let recordtype = contact.recordtypeid
      const result = await schoolApi.deleteContact(contact.id);
      if (result.success === false) {
        toast.error(result.message);
        return;
      } else if (result.success === true) {
        toast.success("Record Deleted successfully");
        console.log("location?.pathnam", location?.pathname?.split("/")[1]);

        setTimeout(() => {
          navigate(
            location?.pathname?.split("/")[1] === "staff"
              ? "/staffs"
              : "/driver"
          );
        }, 2000);
      }
    } catch (error) {
      toast.error(error.response.data.message);
    }
  };

  const editContact = () => {
    console.log("contact@@edit=>", contact);
    if (location?.pathname?.split("/")[1] === "staff") {
      navigate(`/staff/${contact.id}/e`, { state: contact });
    } else {
      navigate(`/driver/${contact.id}/e`, { state: contact });
    }
  };

  return (
    <>
      <Helmet>
        <title>{props?.tabName}</title>{" "}
      </Helmet>
      <div>
        {contact && (
          <Container>
            {modalShow && (
              <Confirm
                show={modalShow}
                onHide={() => setModalShow(false)}
                deleteContact={deleteContact}
                title="Confirm delete?"
                message="You are going to delete the record. Are you sure?"
                table="contact"
              />
            )}
            {/* Add by Aamir khan Employee View  | Updated by Mohd. Sarfraj - 31-Aug.-2024 */}
            <PageNavigations
              listName={
                contact?.recordtype === "Driver"
                  ? "Driver View"
                  : "Employee View"
              }
              listPath={
                contact?.recordtype === "Driver" ? "/driver" : "/staffs"
              }
              viewName=""
              viewPath=""
              colLg={2}
              colClassName="d-flex mx-3 mb-3"
              extrColumn={12}
            />

            <Row className="view-form">
              <Col lg={12}>
                <Row className="view-form-header align-items-center mx-2">
                  <Col lg={3}>
                    <h5>{contact.recordtype}</h5>
                    <h5>
                      {contact.salutation} {contact.firstname}{" "}
                      {contact.lastname}
                    </h5>
                  </Col>
                  <Col lg={9} className="d-flex justify-content-end">
                    {(userInfo?.userrole === "SYS_ADMIN" ||
                      userInfo?.userrole === "ADMIN" ||
                      canEdit) && (
                      <Button
                        className="btn-sm mx-2 edit-button"
                        onClick={() => editContact(true)}
                      >
                        <i className="fa-regular fa-pen-to-square"></i>
                      </Button>
                    )}
                    {(userInfo?.userrole === "SYS_ADMIN" ||
                      userInfo?.userrole === "ADMIN" ||
                      canDelete) && (
                      <Button
                        className="btn-sm"
                        variant="danger"
                        onClick={() => setModalShow(true)}
                      >
                        Delete
                      </Button>
                    )}
                    <ToastContainer
                      position="top-right"
                      autoClose={2000}
                      hideProgressBar={false}
                      newestOnTop={false}
                      closeOnClick
                      rtl={false}
                      pauseOnFocusLoss
                      draggable
                      pauseOnHover
                      theme="light"
                    />
                  </Col>
                </Row>
                <Row>
                  <Col lg={12}>
                    <Col className="mx-3">
                      <Col className="section-header my-3">
                        <span style={{ color: "black" }}>
                          {contact?.recordtype === "Driver"
                            ? "Driver"
                            : "Employee"}{" "}
                          Details
                        </span>
                      </Col>
                    </Col>
                  </Col>
                </Row>
                {/* ====================== Add by Aamir khan 07-05-2024 Start Code ============= */}
                <Row>
                  <Col lg={1}></Col>
                  <Col lg={5}>
                    <label>Name</label>
                    <span>
                      {contact.salutation} {contact.firstname}{" "}
                      {contact.lastname}
                    </span>
                  </Col>

                  <Col lg={5}>
                    <label>Gender</label>
                    {contact.gender ? (
                      <span>{contact.gender}</span>
                    ) : (
                      <>
                        <span>
                          <br />
                        </span>
                      </>
                    )}
                  </Col>

                  <Col lg={1}></Col>
                  <Col lg={1}></Col>
                  <Col lg={5}>
                    <label>Date of Birth</label>
                    {contact.dateofbirth ? (
                      <span>
                        {moment(contact.dateofbirth).format("DD-MM-YYYY")}
                      </span>
                    ) : (
                      <span>
                        <br />
                      </span>
                    )}
                  </Col>

                  {console.log(
                    "contact.dateofbirth@@@@->",
                    contact.dateofbirth
                  )}
                  <Col lg={5}>
                    <label>Employee Type</label>
                    <span>{contact.recordtype}</span>
                  </Col>
                  <Col lg={1}></Col>
                  <Col lg={1}></Col>
                  <Col lg={5}>
                    <label>State</label>
                    {contact.state ? (
                      <span>{contact.state}</span>
                    ) : (
                      <span>
                        <br />
                      </span>
                    )}
                  </Col>

                  <Col lg={5}>
                    <label>Pincode</label>
                    {contact.pincode ? (
                      <span>{contact.pincode}</span>
                    ) : (
                      <span>
                        <br />
                      </span>
                    )}
                  </Col>

                  <Col lg={1}></Col>
                  <Col lg={1}></Col>
                  <Col lg={5}>
                    <label>Email</label>
                    {contact.email ? (
                      <span>{contact.email}</span>
                    ) : (
                      <span>
                        <br />
                      </span>
                    )}
                  </Col>

                  <Col lg={5}>
                    <label>Phone</label>
                    {contact.phone ? (
                      <span>{contact.phone}</span>
                    ) : (
                      <span>
                        <br />
                      </span>
                    )}
                  </Col>

                  <Col lg={1}></Col>
                  <Col lg={1}></Col>
                  <Col lg={5}>
                    <label>Aadhar number</label>
                    {contact.adharnumber ? (
                      <span>{contact.adharnumber}</span>
                    ) : (
                      <span>
                        <br />
                      </span>
                    )}
                  </Col>

                  <Col lg={5}>
                    <label>Qualification</label>
                    {contact.qualification ? (
                      <span>{contact.qualification}</span>
                    ) : (
                      <span>
                        <br />
                      </span>
                    )}
                  </Col>

                  <Col lg={1}></Col>
                  <Col lg={1}></Col>
                  <Col lg={5}>
                    <label>Religion</label>
                    {contact.religion ? (
                      <span>{contact.religion}</span>
                    ) : (
                      <span>
                        <br />
                      </span>
                    )}
                  </Col>

                  <Col lg={5}>
                    <label>Street</label>
                    {contact.street ? (
                      <span>{contact.street}</span>
                    ) : (
                      <span>
                        <br />
                      </span>
                    )}
                  </Col>

                  <Col lg={1}></Col>
                  <Col lg={1}></Col>
                  <Col lg={5}>
                    <label>City</label>
                    {contact.city ? (
                      <span>{contact.city}</span>
                    ) : (
                      <span>
                        <br />
                      </span>
                    )}
                  </Col>

                  <Col lg={5}>
                    <label>Country</label>

                    {contact.country ? (
                      <span>{contact.country}</span>
                    ) : (
                      <span>
                        <br />
                      </span>
                    )}
                  </Col>
                  <Col lg={1}></Col>
                  <Col lg={1}></Col>

                  <Col lg={1}></Col>
                  <Col lg={1}></Col>

                  <Col></Col>
                </Row>
                {/* =========================== Code End ====================*/}
                <Row></Row>
              </Col>
              <Col></Col>
            </Row>
          </Container>
        )}
      </div>
    </>
  );
};
export default ContactView;

{
  /* Add by Aamir khan Employee View  | Updated by Mohd. Sarfraj - 31-Aug.-2024 */
}
