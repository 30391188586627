import React, { useEffect, useState } from "react";
import { Card, Col, Form, Row, Button } from "react-bootstrap";
import schoolApi from "../../api/schoolApi";
import { Link } from "react-router-dom";

import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import { ToastContainer, toast } from 'react-toastify'
import PdfHeader from "../layout/common/PdfHeader";
import { Helmet } from "react-helmet"

const ReportsDetails = (props) => {
    const [classOptions, setClassOptions] = useState([]);
    const [sessionOptions, setSessionOptions] = useState([]);
    const [students, setStudents] = useState([]);
    const [showTable, setShowTable] = useState(false);
    const [reportData, setReportData] = useState({classid: "",session_id: "",});

    useEffect(() => {
        fetchClassRecords();
        fetchAllSessions();
    }, []);

    const fetchClassRecords = async () => {
        try {
            const result = await schoolApi.getActiveClassRecords();
            if (result.success) {
                const options = result.result.map((item) => ({
                    value: item.id,
                    label: item.classname,
                }));
                setClassOptions(options);
            }
        } catch (error) {
            console.error("Error fetching class records:", error);
        }
    };

    const fetchAllSessions = async () => {
        try {
            const sessions = await schoolApi.fetchSessions();
            console.log('sessions========>>>>>>', sessions);
            if (sessions) {
                const options = sessions.map((sess) => ({
                    value: sess.id,
                    label: sess.year,
                }));
                setSessionOptions(options);

                const currentSession = sessions.find(sess => {
                    const currentDate = new Date();
                    const startDate = new Date(sess.startdate);
                    const endDate = new Date(sess.enddate);
                    return currentDate >= startDate && currentDate <= endDate;
                });

                if (currentSession) {
                    setReportData(prevState => ({
                        ...prevState,
                        session_id: currentSession.id
                    }));
                }
            }
        } catch (error) {
            console.error("Error fetching sessions:", error);
        }
    };

    const handleChange = (event) => {
        const { name, value } = event.target;
        setReportData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const onSearch = async () =>{
      if(reportData.session_id){
       try {
        console.log('reportData-shakib->',reportData);
         const result = await schoolApi.getAllStudentByClassidAndSessionid(reportData);
         console.log('result tttttttttttttttttt', result);
         if(result && result.message === "No record found"){
            toast.success(result.message, {
             position: toast.POSITION.TOP_CENTER,
             hideProgressBar: true
           });
           setShowTable(false); 
           setStudents([]);
         }else{
           setStudents(result); 
           setShowTable(true); 
         }
        
       } catch (error) {
         console.error("Error fetching student data:", error);
         setShowTable(false);
       }
      }else{
        console.log('enter')
       toast.error('Please fill session year', {
         position: toast.POSITION.TOP_CENTER,
         hideProgressBar: true,
       });
       setShowTable(false);
       setStudents([]);
      }
 }

    const handleDownload = async () => {
      const input = document.getElementById('reportContent');
      const canvas = await html2canvas(input, { scale: 2 });
      const imgData = canvas.toDataURL('image/png');
      const pdf = new jsPDF('p', 'mm', 'a4');

      const pdfWidth = pdf.internal.pageSize.getWidth() - 20;
      const pdfHeight = (canvas.height * pdfWidth) / canvas.width;
      const imgProps = pdf.getImageProperties(imgData);
      const imgWidth = pdfWidth;
      const imgHeight = (imgProps.height * imgWidth) / imgProps.width;

      let heightLeft = imgHeight;
      let position = 10;

      while (heightLeft > 0) {
          pdf.addImage(imgData, 'PNG', 10, position, imgWidth, imgHeight);
          heightLeft -= pdf.internal.pageSize.getHeight();
          if (heightLeft > 0) {
              pdf.addPage();
              position = 0;
          }
      }

      pdf.save('Student.pdf');
  };

  const getAddress = (data) => {
    return `${data.street || ''} ${data.city || ''} ${data.state || ''} ${data.country || ''} ${data.pincode || ''}`.trim();
  };

    return (
      <>
       <Helmet>
        <title>{props?.tabName}</title>
      </Helmet>
      
        <Row className="g-0">
          <Col lg={2} className="mx-3">
            <Link className="nav-link mx-2" to="/">
              Home <i className="fa-solid fa-chevron-right"></i> Student Report
            </Link>
          </Col>
        </Row>

        <Row className="mt-5 m-0 px-4">
          <Card className="px-0 py-3">
            <Row className="m-0">
              <Col lg={12} className="px-3">
                <h5 className="fw-semibold">Filters</h5>
              </Col>
              <hr />
            </Row>
            <Row className="flex-row mt-3 mb-2 px-4">
              <Col lg={4}>
                <Form.Group className="mx-3">
                  <Form.Label className="form-view-label">
                    Class Name
                  </Form.Label>
                  <Form.Select
                    name="classid"
                    onChange={handleChange}
                    value={reportData.classid}
                  >
                    <option key="default" value="">
                      All
                    </option>
                    {classOptions.map((res) => (
                      <option key={res.value} value={res.value}>
                        {res.label}
                      </option>
                    ))}
                  </Form.Select>
                </Form.Group>
              </Col>
              <Col lg={4}>
                <Form.Group className="mx-3">
                  <Form.Label className="form-view-label">Session</Form.Label>
                  <Form.Select
                    name="session_id"
                    onChange={handleChange}
                    value={reportData.session_id}
                    required
                  >
                    <option value="">-- Select Session --</option>
                    {sessionOptions.map((res) => (
                      <option key={res.value} value={res.value}>
                        {res.label}
                      </option>
                    ))}
                  </Form.Select>
                </Form.Group>
              </Col>

              <Col lg={4} className="d-flex align-items-end">
                <div className="button-wrapper">
                  <Button onClick={onSearch} variant="primary">
                    Search
                  </Button>
                  {students.length > 0 && (
                    <Button
                      onClick={handleDownload}
                      variant="primary"
                      className="mx-2"
                    >
                      Download
                    </Button>
                  )}
                </div>
              </Col>
            </Row>
          </Card>
        </Row>
        {showTable && (
          <Row className="mt-5 m-0 px-4">
            <Card className="px-0 py-3">
              <Row className="flex-row mt-3 mb-2 px-4">
                <Col lg={12} className="p-0">
                  <div id="reportContent">
                    <PdfHeader />
                    <div
                      style={{ borderTop: "2px solid #000", margin: "10px 0" }}
                    ></div>
                    <Row className="mt-4">
                      <Col lg={12} className="px-3">
                        <h5
                          style={{ textAlign: "center" }}
                          className="fw-semibold"
                        >
                          Student Report
                        </h5>
                      </Col>
                      <hr />
                    </Row>
                    <table className="reportTable" id="reportTable">
                      <thead>
                        <tr>
                          <th>Sr</th>
                          <th>Name</th>
                          <th>Parent Name</th>
                          <th>Class Name</th>
                          <th>Parent Phone</th>
                          <th>Gender</th>
                          <th>Phone</th>
                          <th>Address</th>
                        </tr>
                      </thead>
                      <tbody>
                        {students.length > 0 ? (
                          students.map((data, index) => (
                            <tr key={data.student_admission_id}>
                              <td>{index + 1}</td>
                              <td style={{ textAlign: "left" }}>
                                {data.firstname} {data.lastname}
                              </td>
                              <td style={{ textAlign: "left" }}>
                                {data.contact_firstname} {data.contact_lastname}
                              </td>
                              <td style={{ textAlign: "left" }}>
                                {data.class_name}{" "}
                                {data.section_name && ` (${data.section_name})`}
                              </td>
                              <td style={{ textAlign: "right" }}>
                                {data.contact_phone}
                              </td>
                              <td style={{ textAlign: "left" }}>
                                {data.gender}
                              </td>
                              <td style={{ textAlign: "right" }}>
                                {data.phone}
                              </td>
                              <td style={{ textAlign: "left" }}>
                                {getAddress(data)}
                              </td>
                            </tr>
                          ))
                        ) : (
                          <tr>
                            <td colSpan="8" className="text-center">
                              No data available
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                </Col>
              </Row>
            </Card>
          </Row>
        )}
        <ToastContainer />
      </>
    );
};

export default ReportsDetails;