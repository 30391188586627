import React, { useState } from "react";
import { Alert, Col, Container, Row } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { useNavigate } from "react-router-dom";
import Card from "react-bootstrap/Card";
import authApi from "../api/authApi";
import home from "../resources/img/home.png";
import login from "../resources/img/login.png";
import { Link } from "react-router-dom";

const Login = () => {
  const navigate = useNavigate();
  const [credentials, setCredentials] = useState({
    username: "",
    password: "",
  });
  const [show, setShow] = React.useState(false);
  const [errorMessage, setErrorMessage] = useState();
  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log(credentials);

    const result = await authApi.login(
      credentials.username,
      credentials.password
    );
    console.log("result-->", result);
    if (result.success) {
      sessionStorage.setItem("token", result.authToken.afterauthToken);
      sessionStorage.setItem(
        "permissiontoken",
        result.authToken.permissionsauthToken
      );
      let data = await authApi.fetchMyImage();
      console.log("data", data);
      if (data)
        sessionStorage.setItem("myimage", window.URL.createObjectURL(data));
      else
        sessionStorage.setItem(
          "myimage",
          "https://www.w3schools.com/howto/img_avatar.png"
        );
      window.location.assign("/");
    } else {
      setErrorMessage(result.errors);
      setShow(true);
    }
  };

  console.log("errorMessage-->", errorMessage);

  const handleChange = (e) => {
    setCredentials({ ...credentials, [e.target.name]: e.target.value });
  };

  //created by shahir hussain 29-10-2024
  const handleClick = () => {
    navigate("/forget-password");
  };

  return (
    <Container>
      <span className="box1"></span>
      <span className="box2"></span>
      <div className="login-form-section">
        <div className="login-form-content">
          <div className="login-content">
            <div className="login-img">
              <img src="/login3.png" />
            </div>
            <div className="login-form">
              <div className="p-4">
                <Form className="mt-2" onSubmit={handleSubmit}>
                  <div className="">
                    <h3 className="text-center mb-2">
                      {" "}
                      {/* <img
                        src="/comonLogoSchool.png"
                        style={{ width: "auto", height: "200px" }}
                      /> */}
                      <img
                        src="/idigi-logo.png"
                        style={{marginLeft : "-17px", width: "auto",marginTop : "50px", height: "150px" }}
                      />
                    </h3>
                  </div>
                  <Alert variant="danger" show={show}>
                    {errorMessage}
                  </Alert>
                  <Form.Group
                    className="mb-3 mt-4"
                    controlId="formBasicusername"
                  >
                    <Form.Label>Username</Form.Label>
                    <Form.Control
                      type="username"
                      name="username"
                      onChange={handleChange}
                      placeholder="Enter username"
                      required
                      value={credentials.username}
                    />
                  </Form.Group>

                  <Form.Group className="mb-3" controlId="formBasicPassword">
                    <Form.Label>Password</Form.Label>
                    <Form.Control
                      type="password"
                      name="password"
                      onChange={handleChange}
                      placeholder="Password"
                      required
                      value={credentials.password}
                    />
                  </Form.Group>

                  <Button
                    className="btn btn-lg w-100"
                    variant="primary"
                    type="submit"
                  >
                    Login
                  </Button>
                  {/* <center className="mt-3" onClick={handleClick} style={{ cursor: 'pointer',color: 'blue', textDecoration: 'underline' }}>
                    <strong>Forget Password</strong>
                  </center> */}
                  <center className="mt-3">
                    <strong>
                      <Link
                        to="/forget-password"
                        style={{ textDecoration: "none" }}
                      >
                        Forget Password
                      </Link>
                    </strong>
                  </center>
                </Form>

                <center className="indicrm">
                  <img src="indicrm.png" />
                </center>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Container>
  );
};
export default Login;

// import React, { useState } from "react";
// import { Alert, Col, Container, Row } from "react-bootstrap";
// import Button from "react-bootstrap/Button";
// import Form from "react-bootstrap/Form";
// import { useNavigate } from "react-router-dom";
// import Card from "react-bootstrap/Card";
// import authApi from "../api/authApi";
// import home from "../resources/img/home.png";
// import login from "../resources/img/login.png";

// const Login = () => {
//   const navigate = useNavigate();
//   const [credentials, setCredentials] = useState({
//     username: "",
//     password: "",
//   });
//   const [show, setShow] = React.useState(false);
//   const [errorMessage, setErrorMessage] = useState();
//   const handleSubmit = async (e) => {
//     e.preventDefault();
//     console.log(credentials);

//     const result = await authApi.login(
//       credentials.username,
//       credentials.password
//     );
//     console.log("result-->", result);
//     if (result.success) {
//       sessionStorage.setItem("token", result.authToken);
//       let data = await authApi.fetchMyImage();
//       console.log("data", data);
//       if (data)
//         sessionStorage.setItem("myimage", window.URL.createObjectURL(data));
//       else
//         sessionStorage.setItem(
//           "myimage",
//           "https://www.w3schools.com/howto/img_avatar.png"
//         );
//       window.location.assign("/");
//     } else {
//       setErrorMessage(result.errors);
//       setShow(true);
//     }
//   };

//   console.log("errorMessage-->", errorMessage);

//   const handleChange = (e) => {
//     setCredentials({ ...credentials, [e.target.name]: e.target.value });
//   };
//   return (
//     <Container>
//       <span className="box1"></span>
//       <span className="box2"></span>
//       <div className="login-form-section">
//         <div className="login-form-content">
//           <div className="login-content">
//             <div className="login-img">
//               <img src="/login3.png" />
//             </div>
//             <div className="login-form">
//               <div className="p-4">
//                 <Form className="mt-2" onSubmit={handleSubmit}>
//                   <div className="">
//                     <h3 className="text-center mb-2">
//                       {" "}
//                       <img
//                         src="/comonLogoSchool.png"
//                         style={{ width: "auto", height: "200px" }}
//                       />
//                     </h3>
//                   </div>
//                   <Alert variant="danger" show={show}>
//                     {errorMessage}
//                   </Alert>
//                   <Form.Group
//                     className="mb-3 mt-4"
//                     controlId="formBasicusername"
//                   >
//                     <Form.Label>username</Form.Label>
//                     <Form.Control
//                       type="username"
//                       name="username"
//                       onChange={handleChange}
//                       placeholder="Enter username"
//                       required
//                       value={credentials.username}
//                     />
//                   </Form.Group>

//                   <Form.Group className="mb-3" controlId="formBasicPassword">
//                     <Form.Label>Password</Form.Label>
//                     <Form.Control
//                       type="password"
//                       name="password"
//                       onChange={handleChange}
//                       placeholder="Password"
//                       required
//                       value={credentials.password}
//                     />
//                   </Form.Group>

//                   <Button
//                     className="btn btn-lg w-100"
//                     variant="primary"
//                     type="submit"
//                   >
//                     Login
//                   </Button>
//                 </Form>

//                 <center className="indicrm">
//                   <img src="indicrm.png" />
//                 </center>
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>
//     </Container>
//     // <Container>
//     //   <Row className="login-form p-3 mt-5">
//     //     <Col lg={6}>
//     //       <img src="/login_banner.jpg" style={{display: "flex", alignItems: "center",marginTop: "105px"}} />
//     //     </Col>
//     //     <Col lg={6} className="login-section">
//     //       <h3 className="text-center mb-2"></h3>
//     //       <div className="p-5">
//     //         <Form onSubmit={handleSubmit}>
//     //           <div className="mb-2">
//     //           <h3 className="text-center mb-2"> <img src="/comonLogoSchool.png" style={{width:"auto", height:"200px"}}/></h3>
//     //           </div>
//     //           <Alert variant="danger" show = {show}>{errorMessage}</Alert>
//     //           <Form.Group className="mb-3" controlId="formBasicEmail">
//     //             <Form.Label>Email address</Form.Label>
//     //             <Form.Control
//     //               type="email"
//     //               name="email"
//     //               onChange={handleChange}
//     //               placeholder="Enter email"
//     //               required
//     //               value={credentials.email}
//     //             />
//     //           </Form.Group>

//     //           <Form.Group className="mb-3" controlId="formBasicPassword">
//     //             <Form.Label>Password</Form.Label>
//     //             <Form.Control
//     //               type="password"
//     //               name="password"
//     //               onChange={handleChange}
//     //               placeholder="Password"
//     //               required
//     //               value={credentials.password}
//     //             />
//     //           </Form.Group>

//     //           <Button variant="primary" type="submit">
//     //             Login
//     //           </Button>
//     //         </Form>
//     //       </div>
//     //     </Col>
//     //   </Row>
//     //   <center className="m-4"><img src="indicrm.png" /></center>
//     // </Container>
//   );
// };

// export default Login;
