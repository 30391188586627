/**
 * @author: Mohd. Sarfraj | 23-Auguest-2024
 */

import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { Link, useLocation , useNavigate } from "react-router-dom";
import { Row, Col, Form, Table, Button } from "react-bootstrap";
import { DatatableWrapper, Filter, Pagination, PaginationOptions, TableBody, TableHeader,} from "react-bs-datatable";
import InfoPill from "../InfoPill";
import schoolApi from "../../api/schoolApi";
import { ToastContainer, toast } from "react-toastify";
import Confirm from "../Confirm"; // added by mohd. sarfraj | 12-sept-2024.

const PromotionView = (props) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [finalPromotionStudents, setFinalPromotionStudents] = useState([]);
  const [showConfirmModal, setShowConfirmModal] = useState(false); // added by mohd. sarfraj | 12-sept-2024.

  useEffect(() => {setFinalPromotionStudents(location.state)}, []);
  console.log("finalPromotionStudents", finalPromotionStudents);

  // added by mohd. sarfraj | 12-sept-2024.
  const saveRecord = async () => {setShowConfirmModal(true)}

  // added by mohd. sarfraj | 12-sept-2024.
  // added by mohd. sarfraj | 12-sept-2024.
  const handleConfirmStudentPromotion = async () => {
    try {
      /*--------------- Fitred student Records according there isPromote status --------------*/
      let newSt = [];
      let leaveStu = [];
      for (let i = 0; i < finalPromotionStudents.length; i++) {
        if(finalPromotionStudents[i]?.isPromote !== false){
          newSt.push(finalPromotionStudents[i])
        } else {
          leaveStu.push(finalPromotionStudents[i]);
        }
      }
      
      //--- yha se Leave wale student ka status drop-out kerna he. (Pending.)
      //-----   START update leave'student status through dropout | 21-sept-2024. ----
      if(leaveStu.length > 0) {
        const studentRec = await schoolApi.updateStudentStatus(leaveStu);
        console.log('studentRec----->>>>>' , studentRec);
        if(studentRec.success){
          toast.success(studentRec.message)
        }
      }
      //-----  END  ./  -----

      if(newSt.length){
        const result = await schoolApi.promtionStudentAddmission(newSt);
        console.log("result-created-record-->>", result);
        if(result?.success) {
          return toast.success("Student promoted successfully..!!" , {position : "top-right"});
        } else {
          return toast.error("Student promotion failed..!!" , {position : "top-right"});
        }
      }
    } catch (error) {
      console.log("errro during create new students_admissions -->>", error);
      return toast.error("Internal Server Error..!!" , {position : "top-right"});
    }
  }

  const labels = {
    beforeSelect: " ",
  };

  const headers = [
    {
      title: "Next Session Status",
      prop: "",
      isFilterable: true,
      cell: (row) => (
        row.isPromote === true ? 'Continue' : 'Leave'
      ),
    },
    {
      title: "Student Name",
      prop: "student_name",
      isFilterable: true,
      cell: (row) => (
        <div className="text-capitalize my-2">{row?.student_name}</div>
      ),
    },
    {
      title: "Promote Class",
      prop: "",
      cell: (row, handleChange) => (
        <Form.Group>
          <Form.Select name="classId" value={row?.result === "pass" ? row.class_id : row?.classid} disabled>
            <option value="" selected disabled>-- Select --</option>
            {/* <option value={row?.result === "pass" ? row.class_id : row?.classid}>
              {row?.result === "pass" ? row.classname : row?.classname}
            </option> */}
            {
              row?.result === "fail" 
              ? <option value={row?.classid}>{row?.classname}</option>
              : <option value={row?.class_id}>{row?.classname}</option>
            }
          </Form.Select>
        </Form.Group>
      ),
    },
    {
      title: "Section",
      prop: "",
      cell: (row, handleChange) => (
        <Form.Group>
          <Form.Select name="sectionid" value={row?.resultresult === "pass" ? row?.section_id : row?.sectionid} disabled>
            <option value="" selected disabled>-- Select Section --</option>
            {/* <option value={row?.resultresult === "pass" ? row?.section_id : row?.sectionid}>
              {row?.result === "pass" ? row.section_name : row?.section_name}
            </option> */}
            {
              row?.result === "fail" 
              ? <option value={row?.sectionid}>{row?.section_name}</option>
              : <option value={row?.section_id}>{row?.section_name}</option>
            }
          </Form.Select>
        </Form.Group>
      ),
    },
    {
      title: "Result",
      prop: "result",
      width: "50px",
      isFilterable: true,
      cell: (row) => <div className="text-capitalize my-2">{row?.result}</div>,
    },
    {
      title: "Pending Fee",
      prop: "pending_amount_calculated",
      width: "50px",
      isFilterable: true,
      cell: (row) => (
        <div className="text-end mx-2 my-2">
          ₹&nbsp;&nbsp;{row.pending_amount_calculated}
        </div>
      ),
    },
  ];

  return (
    <>
        {showConfirmModal && (
          <Confirm
            show={showConfirmModal}
            onHide={() => setShowConfirmModal(false)}
            handleDeleteButton={() => handleConfirmStudentPromotion()}
            title="Confirm promotion?"
            message="Are you sure want to promote students ?"
            table="promotionConfirm"
          />
        )}

      <ToastContainer
        position="top-right"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />

      <Helmet><title>{props?.tabName}</title></Helmet>

      <Row className="g-0">
        <Col lg={2} className="mx-3">
          <Link className="nav-link mx-2" to="-1">
            Home <i className="fa-solid fa-chevron-right"></i> PromotioPreview
          </Link>
        </Col>
      </Row>

      {/* <Row className="g-0 mt-2">
          <Col lg={2} className="mx-3">
            <Button className="btn-light add-new-button fs-5 btn-sm" variant="" onClick={()=> navigate(-1)}>Previous</Button>
          </Col>
      </Row> */}

      <Row className="mx-3 mt-4 py-2 px-3 text-light fw-bold" style={{ background: "grey" }}>
        <h5 className="mb-0">Student List</h5>
      </Row>

      <Row className="mx-2 mt-3">
        <DatatableWrapper
          body={finalPromotionStudents}
          headers={headers}
          paginationOptionsProps={{
            initialState: {
              rowsPerPage: 10,
              options: [5, 10, 15, 20],
            },
          }}
        >
          <Row className="mb-4">
            <Col xs={12} lg={4} className="d-flex flex-col justify-content-end align-items-end">
              <Filter />
            </Col>
            <Col
              xs={12}
              sm={6}
              lg={4}
              className="d-flex flex-col justify-content-lg-center align-items-center justify-content-sm-start mb-2 mb-sm-0"
            >
              <PaginationOptions labels={labels} />
              <div style={{ marginTop: "5px" }}>
                <InfoPill left="Total Student" right={finalPromotionStudents?.length}/>
              </div>
              {/* <Row>
                  <Col lg={2} className="mt-4" >
                    <Button className="btn-light add-new-button fs-5 btn-sm" variant="" onClick={()=> navigate(-1)}>Previous</Button>
                  </Col>
              </Row> */}
            </Col>
            
            <Col xs={12} sm={6} lg={4} className="d-flex flex-col justify-content-end align-items-end">
              {<Button className="btn-light add-new-button fs-5" variant="" onClick={saveRecord}>Save</Button>}
            </Col>
          </Row>
          <Table hover bordered responsive="lg" className="coustume-header">
            <TableHeader />
            <TableBody />
          </Table>
          <Row>
            <div xs={12} sm={6} lg={4} className="d-flex flex-col justify-content-center align-items-center">
              <Pagination />
            </div>
          </Row>
        </DatatableWrapper>
      </Row>
    </>
  );
};
export default PromotionView;
