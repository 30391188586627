import React, { useEffect, useState } from "react";
import { Card, Col, Form, Row, Button } from "react-bootstrap";
import schoolApi from "../../api/schoolApi";
import { Link } from "react-router-dom";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import PdfHeader from "../layout/common/PdfHeader";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Helmet } from "react-helmet";

const FeeCollectionReport = (props) => {
  const [classOptions, setClassOptions] = useState([]);
  const [sessionOptions, setSessionOptions] = useState([]);
  const [reportData, setReportData] = useState({
    classid: "",
    session_id: "",
  });
  const [feeData, setFeeData] = useState([]);

  useEffect(() => {
    fetchClassRecords();
    fetchAllSessions();
  }, []);

  const fetchClassRecords = async () => {
    try {
      const result = await schoolApi.getActiveClassRecords();
      if (result.success) {
        const options = result.result.map((item) => ({
          value: item.id,
          label: item.classname,
        }));
        setClassOptions(options);
      }
    } catch (error) {
      console.error("Error fetching class records:", error);
    }
  };

  const fetchAllSessions = async () => {
    try {
      const sessions = await schoolApi.fetchSessions();
      console.log("sessions========>>>>>>", sessions);
      if (sessions) {
        const options = sessions.map((sess) => ({
          value: sess.id,
          label: sess.year,
        }));
        setSessionOptions(options);

        // Find the current session
        const currentSession = sessions.find((sess) => {
          const currentDate = new Date();
          const startDate = new Date(sess.startdate);
          const endDate = new Date(sess.enddate);
          return currentDate >= startDate && currentDate <= endDate;
        });

        if (currentSession) {
          setReportData((prevState) => ({
            ...prevState,
            session_id: currentSession.id,
          }));
        }
      }
    } catch (error) {
      console.error("Error fetching sessions:", error);
    }
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setReportData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSearch = async () => {
    try {
      if (!reportData?.session_id) {
        return toast.error("Please select required fields");
      }

      const result = await schoolApi.getAllStudentByClassidFeeData(reportData);
      if (result.success) {
        setFeeData(result.result);
      }
    } catch (error) {
      setFeeData([]);
      toast.error(error.response.data.message);
    }
  };

  const handleDownload = async () => {
    const input = document.getElementById("reportContent");
    const canvas = await html2canvas(input, { scale: 2 });
    const imgData = canvas.toDataURL("image/png");
    const pdf = new jsPDF("p", "mm", "a4");

    const pdfWidth = pdf.internal.pageSize.getWidth() - 20;
    const imgProps = pdf.getImageProperties(imgData);
    const imgWidth = pdfWidth;
    const imgHeight = (imgProps.height * imgWidth) / imgProps.width;

    let heightLeft = imgHeight;
    let position = 10;

    while (heightLeft > 0) {
      pdf.addImage(imgData, "PNG", 10, position, imgWidth, imgHeight);
      heightLeft -= pdf.internal.pageSize.getHeight();
      if (heightLeft > 0) {
        pdf.addPage();
        position = 0;
      }
    }

    pdf.save("FeeCollectionReport.pdf");
  };

  // Ensure feeData is always an array before performing array operations
  const totalFees = Array.isArray(feeData)
    ? feeData.reduce((acc, item) => acc + item?.total_fee, 0)
    : 0;
  const totalPaid = Array.isArray(feeData)
    ? feeData.reduce((acc, item) => acc + item?.total_deposited, 0)
    : 0;
  const totalPending = Array.isArray(feeData)
    ? feeData.reduce((acc, item) => acc + item?.pending_amount_calculated, 0)
    : 0;
  const otherFee = Array.isArray(feeData)
    ? feeData.reduce((acc, item) => acc + item?.others, 0)
    : 0;
  const netpaybleFee = Array.isArray(feeData)
    ? feeData.reduce((acc, item) => acc + item?.netpayble, 0)
    : 0;

  console.log("reportData-->", reportData);
  const formatNumberWithCommas = (number) => {
    return number ? number.toLocaleString("en-IN") : "0";
  };

  return (
    <>
      <Helmet>
        <title>{props?.tabName}</title>
      </Helmet>

      <Row className="g-0">
        <Col lg={2} className="mx-3">
          <Link className="nav-link mx-2" to="/">
            Home <i className="fa-solid fa-chevron-right"></i> Fees collection
          </Link>
        </Col>
      </Row>

      <Row className="mt-5 m-0 px-4">
        <Card className="px-0 py-3 " style={{ marginTop: "-17px" }}>
          <Row className="m-0">
            <Col lg={12} className="px-3">
              <h5 className="fw-semibold">Filters</h5>
            </Col>
            <hr />
          </Row>
          <Row className="flex-row mt-3 mb-2 px-4">
            <Col lg={4}>
              <Form.Group className="mx-3">
                <Form.Label className="form-view-label">Class Name</Form.Label>
                <Form.Select
                  name="classid"
                  onChange={handleChange}
                  value={reportData.classid}
                >
                  <option key="default" value="">
                    All
                  </option>
                  {classOptions.map((res) => (
                    <option key={res.value} value={res.value}>
                      {res.label}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>
            </Col>
            <Col lg={4}>
              <Form.Group className="mx-3">
                <Form.Label className="form-view-label">Session</Form.Label>
                <Form.Select
                  name="session_id"
                  onChange={handleChange}
                  value={reportData.session_id}
                  required
                >
                  <option value="">-- Select Session --</option>
                  {sessionOptions.map((res) => (
                    <option key={res.value} value={res.value}>
                      {res.label}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>
            </Col>

            <Col lg={4} className="d-flex align-items-end ">
              <div className="button-wrapper ">
                <Button onClick={handleSearch} variant="primary">
                  Search
                </Button>
                {feeData.length > 0 && (
                  <Button
                    onClick={handleDownload}
                    variant="primary"
                    className="mx-2"
                  >
                    Download
                  </Button>
                )}
              </div>
            </Col>
          </Row>
        </Card>
      </Row>

      {feeData.length > 0 && (
        <Row className="mt-5 m-0 px-4">
          <Card className="px-0 py-3">
            <Row className="flex-row mt-3 mb-2 px-4">
              <Col lg={12} className="p-0">
                <div id="reportContent">
                  <PdfHeader />
                  <div
                    style={{ borderTop: "2px solid #000", margin: "10px 0" }}
                  ></div>
                  <Row className="mt-4">
                    <Col lg={12} className="px-3">
                      <h5
                        style={{ textAlign: "center" }}
                        className="fw-semibold"
                      >
                        Fee Collection Report
                      </h5>
                    </Col>
                    <hr />
                  </Row>
                  <table className="reportTable" id="reportTable">
                    <thead>
                      <tr>
                        <th>Sr</th>
                        <th>Student Name</th>
                        <th>Parent Name</th>
                        <th>Class</th>
                        <th>Total Fees</th>
                        <th>Others</th>
                        <th>Net Fee</th>
                        <th>Total Paid</th>
                        <th>Total Pending</th>
                      </tr>
                    </thead>
                    <tbody>
                      {feeData.length > 0 ? (
                        feeData.map((data, index) => (
                          <tr key={data.student_admission_id}>
                            <td>{index + 1}</td>
                            <td style={{ textAlign: "left" }}>
                              {data.student_name}
                            </td>
                            <td style={{ textAlign: "left" }}>
                              {data.parent_name}
                            </td>
                            <td style={{ textAlign: "left" }}>
                              {data.classname} ({data.section_name})
                            </td>
                            <td style={{ textAlign: "right" }}>
                              {formatNumberWithCommas(data.total_fee)}
                            </td>
                            <td style={{ textAlign: "right" }}>
                              {formatNumberWithCommas(data.others)}
                            </td>
                            <td style={{ textAlign: "right" }}>
                              {formatNumberWithCommas(data.netpayble)}
                            </td>
                            <td style={{ textAlign: "right" }}>
                              {formatNumberWithCommas(data.total_deposited)}
                            </td>
                            <td style={{ textAlign: "right" }}>
                              {formatNumberWithCommas(
                                data.pending_amount_calculated
                              )}
                            </td>
                          </tr>
                        ))
                      ) : (
                        <tr>
                          <td colSpan="8" className="text-center">
                            No data available
                          </td>
                        </tr>
                      )}
                    </tbody>
                    <tfoot>
                      <tr>
                        <td
                          style={{ textAlign: "right" }}
                          colSpan="4"
                          className="text-right"
                        >
                          <strong>Totals</strong>
                        </td>
                        <td style={{ textAlign: "right" }}>
                          <strong>{formatNumberWithCommas(totalFees)}</strong>
                        </td>
                        <td style={{ textAlign: "right" }}>
                          <strong>{formatNumberWithCommas(otherFee)}</strong>
                        </td>
                        <td style={{ textAlign: "right" }}>
                          <strong>
                            {formatNumberWithCommas(netpaybleFee)}
                          </strong>
                        </td>
                        <td style={{ textAlign: "right" }}>
                          <strong>{formatNumberWithCommas(totalPaid)}</strong>
                        </td>
                        <td style={{ textAlign: "right" }}>
                          <strong>
                            {formatNumberWithCommas(totalPending)}
                          </strong>
                        </td>
                      </tr>
                    </tfoot>
                  </table>
                </div>
              </Col>
            </Row>
          </Card>
        </Row>
      )}
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      ></ToastContainer>
    </>
  );
};

export default FeeCollectionReport;
