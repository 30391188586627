/**
 * @author: Pooja Vaishnav and Pawan Singh Sisodiya
 */
import React, { useEffect, useState } from "react";
import { Button, Col, Form, Modal, Row, Table } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import Confirm from "../Confirm";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  DatatableWrapper,
  Filter,
  Pagination,
  PaginationOptions,
  TableBody,
  TableHeader,
} from "react-bs-datatable";
import { ShimmerTable } from "react-shimmer-effects";
import InfoPill from "../InfoPill";
import schoolApi from "../../api/schoolApi";
import { usePermissions } from "../contexts/PermissionContext";

const FeesHeadMasterList = (props) => {
  const [body, setBody] = useState();
  const [feeHeadMaster, setFeeHeadMaster] = useState({status: 'Active'});
  const [showModal, setShowModal] = useState(false);
  const [reload, setReload] = useState(false);
  const [rowRecords, setRowRecords] = useState([]);
  const [updateStatus, setUpdateStatus] = useState(false);
  const { rolePermissions, userInfo } = usePermissions();
  const canCreate = rolePermissions?.Fee_Head_Master_List?.can_create;
  const canEdit = rolePermissions?.Fee_Head_Master_List?.can_edit;
  console.log("props ->", props);

  useEffect(() => {
    async function init() {
      try {
        const result = await schoolApi.fetchFeesHeadMaster();
        console.log("result->", result);
        if (result.success) {
          setBody(
            result?.result?.map((element, index) => ({
              ...element,
             order_no: index + 1,
            }))
          );
        } else {
          setBody([]);
        }
      } catch (err) {
      setBody([]);
      }
    }
    init();
  }, [reload]);

  const labels = {
    beforeSelect: " ",
  };

  const header = [
    {
      title: "Name",
      prop: "name",
      show: true,
      isFilterable: true,
    },
    {
      title: "Installment Type",
      prop: "type",
      show: true,
      isFilterable: true,
    },
    {
      title: "Order No",
      prop: "order_no",
      show: true,
      isFilterable: true,
    },
    {
      title: "Status",
      prop: "status",
      isFilterable: true,
      show: true,
      cell: (row) => (
        <Button
          className="btn-sm mx-2"
          style={{ width: "80px" }}
          variant={row.status === "Active" ? "success" : "danger"}
          onClick={() => toggleStatus(row)}
        >
          {row.status}
        </Button>
      ),
    },
    {
      title: "Action",
      prop: "",
      isFilterable: true,
      show:
        userInfo?.userrole === "SYS_ADMIN" || userInfo?.userrole === "ADMIN"
          ? true
          : canEdit,
      cell: (row) => (
        <div>
          <button
            className="btn btn-sm edit-button mx-2"
            onClick={() => handleEditButton(row)}
          >
            <i className="fa-regular fa-pen-to-square"></i>
          </button>
        </div>
      ),
    },
  ];

  const visibleHeader = header?.filter((col) => col.show);

  const handleEditButton = (row) => {
    console.log("handle edit button clicked", row);
    setFeeHeadMaster(row);
    setShowModal(true);
  };

  const toggleStatus = (row) => {
    if (
      canEdit ||
      userInfo?.userrole === "SYS_ADMIN" ||
      userInfo?.userrole === "ADMIN"
    ) {
      setUpdateStatus(true);
      setRowRecords(row);
    }
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setFeeHeadMaster({});
  };

  const handleChange = (e) => {
    setFeeHeadMaster((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  const checkDuplicacy = (feeHeadMaster) => {
    let count = 0;
    if (!feeHeadMaster.id) {
      body.forEach((element) =>
        element.name === feeHeadMaster.name ? count++ : null
      );
    } else {
    body.forEach((element) =>
      (element.name === feeHeadMaster.name && element.id !=feeHeadMaster.id) ? count++ : null
    );
  }
    if (count > 0) {
      return true;
    } else {
      return false;
    }
  };
  
  const handleSaveNewTitle = async () => {
    console.log("feeHeadMaster--> 2", feeHeadMaster);
    try {
      if (
        !feeHeadMaster.name ||
        !feeHeadMaster.status ||
        !feeHeadMaster.order_no ||
        !feeHeadMaster.type
      ) {
        toast.error("Missing required fields");
        return;
      }
      setReload(false);
      const duplicate = checkDuplicacy(feeHeadMaster);
      if (!duplicate) {
        if (feeHeadMaster.id) {
          body.forEach((element) => {
            if (element.id === feeHeadMaster.id) {
              body.splice(element.order_no - 1, 1);
              body.splice(feeHeadMaster.order_no - 1, 0, feeHeadMaster);
            }
          });

          const newbody = body.map((element, index) => ({
            ...element,
            order_no: index + 1,
          }));
          const result2 = await schoolApi.updateAllFeeHeadMaster(newbody);
          if (result2.success) {
            setReload(true);
            setShowModal(false);
            setFeeHeadMaster({});
           return toast.success("Record updated successfully");
          } else {
            console.log("Error occured!!!");
          }
        } else {
          body.splice(feeHeadMaster.order_no - 1, 0, feeHeadMaster);
          const newbody = body.map((element, index) => ({
            ...element,
            order_no: index + 1,
          }));
          const result = await schoolApi.updateAllFeeHeadMaster(newbody);
          if (result.success) {
            setReload(true);
            setShowModal(false);
            setFeeHeadMaster({});
            return toast.success("Record saved successfully");
          } else {
            return toast.error(result.message);
          }
        }
      } else {
        toast.error("Record already exists");
      }
    } catch (err) {
    return  toast.error("Internel server error");
    }
  };

  const changeHeadStatus = async () => {
    try {
      if (rowRecords.status === "Active") {
        rowRecords.status = "InActive";
      } else {
        rowRecords.status = "Active";
      }
      const result = await schoolApi.updateFeeHeadMaster(rowRecords.id,rowRecords);

      if (result) {
        setUpdateStatus(false);
      }
    } catch (error) {
      if (rowRecords.status === "Active") {
        rowRecords.status = "InActive";
      } else {
        rowRecords.status = "Active";
      }
      setUpdateStatus(false);
    return  toast.error("Internel server error");
    }
  };

  console.log('headMaster-->>>' , feeHeadMaster);
  
  return (
    <>
      <Helmet>
        <title>{props?.tabName}</title>
      </Helmet>
      <Row className="g-0">
        <Col lg={3} className="mx-3">
          <Link className="nav-link mx-2" to="/">
            Home <i className="fa-solid fa-chevron-right"></i>{" "}
            FeesHeadMasterList
          </Link>
        </Col>

          <ToastContainer
            position="top-right"
            autoClose={2000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="light"
          />

        {updateStatus && (
          <Confirm
            show={updateStatus}
            onHide={() => setUpdateStatus(false)}
            changeHeadStatus={changeHeadStatus}
            titleupdate="Confirm update?"
            message="You are going to update the status. Are you sure?"
            table="fee_head_master"
          />
        )}

        <Col lg={12} className="p-lg-4">
          {body ? (
            <DatatableWrapper
              body={body}
              headers={visibleHeader}
              paginationOptionsProps={{
                initialState: {
                  rowsPerPage: 15,
                  options: [5, 10, 15, 20],
                },
              }}
            >
              <Row className="mb-4">
                <Col lg={3}>
                  <Filter />
                </Col>
                <Col lg={1} style={{ "margin-top": "-18px" }}>
                  <PaginationOptions labels={labels} />
                </Col>
                <Col lg={4} style={{ "margin-top": "-13px" }}>
                  <InfoPill left="Total Head Masters" right={body?.length} />
                </Col>
                <Col lg={2}></Col>
                <Col
                  lg={2}
                  style={{ "margin-top": "2px" }}
                  className="d-flex flex-col justify-content-end align-items-end"
                >
                  {(userInfo?.userrole === "SYS_ADMIN" ||
                    userInfo?.userrole === "ADMIN" ||
                    canCreate) && (
                    <Button
                      className="btn-light add-new-button"
                      variant=""
                      onClick={() =>{ setShowModal(true);setFeeHeadMaster({status: 'Active'})}}
                    >
                      New Fees Head Master
                    </Button>
                  )}
                </Col>
              </Row>
              <Table striped className="data-table">
                <TableHeader />
                <TableBody />
              </Table>
              <Pagination />
            </DatatableWrapper>
          ) : (
            <ShimmerTable row={10} col={4} />
          )}
        </Col>
        <Col lg={2}></Col>
      </Row>
      {/*---------------------- New Exam Schedule Modal -----------------------------------*/}

      <Modal
        show={showModal}
        backdrop="static"
        centered
        aria-labelledby="contained-modal-title-vcenter"
        onHide={handleCloseModal}
        size="md"
      >
        <Modal.Header closeButton>
          <Modal.Title className="text-center w-100">
            Fee Head Master
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form className="mt-3">
            <Row>
              <Col lg={6} className="mt-3">
                <Form.Group className="mx-3 fees">
                  <Form.Label className="form-view-label" htmlFor="nameInput">
                    Name
                  </Form.Label>
                  <Form.Control
                    required
                    type="text"
                    id="nameInput"
                    name="name"
                    value={feeHeadMaster.name}
                    placeholder="Enter fee head master"
                    onChange={handleChange}
                  />
                </Form.Group>
              </Col>
              <Col lg={6} className="mt-3">
                <Form.Group className="mx-3">
                  <Form.Label
                    className="form-view-label"
                    htmlFor="formBasicFirstName"
                  >
                    Status
                  </Form.Label>
                  <Form.Select
                    required
                    name="status"
                    value={feeHeadMaster.status}
                    onChange={handleChange}
                  >
                    <option value="">-- Select Status --</option>
                    <option value="Active">Active</option>
                    <option value="InActive">InActive</option>
                  </Form.Select>
                </Form.Group>
              </Col>
              <Col lg={6} className="mt-3">
                <Form.Group className="mx-3">
                  <Form.Label
                    className="form-view-label"
                    htmlFor="formBasicFirstName"
                  >
                    Installment Type
                  </Form.Label>
                  <Form.Select
                    required
                    name="type"
                    value={feeHeadMaster.type}
                    onChange={handleChange}
                  >
                    <option value="">-- Select Status --</option>
                    <option value="Installment payment">
                      Installment payment
                    </option>
                    <option value="Single-time payment">
                      Single-time payment
                    </option>
                  </Form.Select>
                </Form.Group>
              </Col>
              <Col lg={6} className="mt-3">
                <Form.Group className="mx-3 fees">
                  <Form.Label className="form-view-label" htmlFor="nameInput">Order Number</Form.Label>
                  <Form.Control
                    required
                    type="text"
                    // min={1}
                    id="orderNumberInput"
                    name="order_no"
                    value={feeHeadMaster.order_no}
                    placeholder="Enter Order No"
                    onChange={(e) => {
                      // const value = e.target.value.replace(/\D/g, '');//value.replace(/[^0-9]/g, '');
                      const value = e.target.value.replace(/[^0-9]/g, '');
                      setFeeHeadMaster((prevData) => ({
                        ...prevData,
                        [e.target.name]: value,
                      }));
                    }}
                  />
                </Form.Group>
              </Col>
            </Row>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            Close
          </Button>
          <Button variant="primary" onClick={handleSaveNewTitle}>
            Save
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default FeesHeadMasterList;