/**
 * @author: Pawan Singh Sisodiya
 */

import React, { useEffect, useState } from "react";
import { Button, Col, Form, Modal, Row, Table } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import schoolApi from "../../api/schoolApi";
import { ShimmerTable } from "react-shimmer-effects";
import moment from "moment";
import InfoPill from "../InfoPill";
import { DatatableWrapper, Filter, Pagination, PaginationOptions, TableBody, TableHeader } from 'react-bs-datatable';
import { Link } from "react-router-dom";

import { Helmet } from 'react-helmet';
import PageNavigations from "../breadcrumbs/PageNavigations";
import Confirm from "../Confirm";
import PubSub from 'pubsub-js';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { usePermissions } from "../contexts/PermissionContext";

const ResultList = (props) => {

  const { rolePermissions, userInfo } = usePermissions();
  const [body, setBody] = useState();
  const canCreate = rolePermissions?.Exam_List?.can_create
  const canEdit = rolePermissions?.Exam_List?.can_edit
  const canDelete = rolePermissions?.Exam_List?.can_delete
  const [optionClasses, setOptionClasses] = useState([]);
  console.log('canCreate canEdit canDelete', canCreate, canEdit, canDelete);


  useEffect(() => {
    getActiveClassFunction();
  }, []);

  const getActiveClassFunction=async()=>{
    try {
      const classList = await schoolApi.getActiveClassRecords();
      if (classList.success) {
        let ar = [];
        classList?.result?.map((item) => {
          var obj = {};
          obj.value = item.id;
          obj.label = item.classname;
          ar.push(obj);
        });
        setOptionClasses(ar);
      } else {
        setOptionClasses([]);
      }
    } catch (error) {
      console.log(error);
    }
  }

  const handleChange=()=>{

  }

  return (
    <>
      <Helmet> <title>{props?.tabName}</title> </Helmet>
      {/* <PageNavigations colLg={2} colClassName="d-flex mx-4" extrColumn={12} /> */}
      <Row className="g-0">
        <Col lg={2} className="mx-3">
          <Link className="nav-link mx-2" to="/">Home <i className="fa-solid fa-chevron-right"></i> ExamList</Link>
        </Col>
        <Row>
            <Col lg={12}>
              <Row className="view-form-header align-items-center mx-2 my-3">
                <Col lg={3}>Result</Col>
              </Row>
            </Col>
          </Row>
        <Row>
                  <Col lg={4}>
                    <Form.Group className="my-3 mx-2">
                      <Form.Label
                        className="form-view-label"
                        htmlFor="formBasicClass"
                      >
                        Class Name
                      </Form.Label>
                      <Form.Select
                        required
                        name="classid"
                        value={body?.classid}
                        onChange={handleChange}
                      >
                        <option value="">-- Select Class Name --</option>
                        {optionClasses.map((res) => (
                          <option key={res.value} value={res.value}>
                            {res.label}
                          </option>
                        ))}
                      </Form.Select>
                    </Form.Group>
                  </Col>
                  </Row>
      </Row>
    </>
  );
};

export default ResultList;