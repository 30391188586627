import React, { useEffect, useState } from "react";

import { Button, Col, Row, Table } from "react-bootstrap";
import { Link } from "react-router-dom";
import { ShimmerTable } from "react-shimmer-effects";
import InfoPill from "../InfoPill";
import schoolApi from "../../api/schoolApi";
import PubSub from "pubsub-js";
import {
  DatatableWrapper,
  Filter,
  Pagination,
  PaginationOptions,
  TableBody,
  TableHeader,
} from "react-bs-datatable";
import AddPublisher from "./AddPublisher";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Confirm from "../Confirm";
import { usePermissions } from "../contexts/PermissionContext";
import { Helmet } from "react-helmet";

const PublisherList = (props) => {
  const [body, setBody] = useState([]);
  const [modalShow, setModalShow] = useState(false);
  const [rowRecords, setRowRecords] = useState();
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [rowDataId, setRowDataId] = useState();
  const { rolePermissions, userInfo } = usePermissions();
  const canCreate = rolePermissions?.Publisher_List?.can_create;
  const canEdit = rolePermissions?.Publisher_List?.can_edit;
  const canDelete = rolePermissions?.Publisher_List?.can_delete;

  const fetchRecords = async () => {
    const result = await schoolApi.getPublishersRecords();
    //-------- Updated By mohd. sarfraj | 16-sept-2024.
    if (result?.success) {
      setBody(result?.publishers);
    } else {
      setBody([]);
    }
  };

  useEffect(() => {
    fetchRecords();
  }, []);

  const labels = {
    beforeSelect: " ",
  };

  const header = [
    { title: "S.No.", prop: "serial", show: true, isFilterable: true },
    {
      title: "Name",
      prop: "name",
      show: true,
      isFilterable: true,
      cell: (row) => (
        <Link to={"/publisher/" + row.id} state={row}>
          {row.name}
        </Link>
      ),
    },
    { title: "Status", prop: "status", show: true, isFilterable: true },
    {
      title: "Actions",
      prop: "id",
      show:
        userInfo?.userrole === "SYS_ADMIN" ||
        userInfo?.userrole === "ADMIN" ||
        canEdit ||
        canDelete
          ? true
          : false,
      cell: (row) => {
        return (
          <>
            {(canEdit ||
              userInfo?.userrole === "SYS_ADMIN" ||
              userInfo?.userrole === "ADMIN") && (
              <Button
                className="btn-sm mx-2 edit-button"
                variant=""
                onClick={() => handleEdit(row)}
              >
                <i className="fa-regular fa-pen-to-square"></i>
              </Button>
            )}
            {(canDelete ||
              userInfo?.userrole === "SYS_ADMIN" ||
              userInfo?.userrole === "ADMIN") && (
              <button
                className="btn btn-sm btn-danger mx-2"
                onClick={() => handleDeleteButton(row)}
              >
                <i className="fa fa-trash"></i>
              </button>
            )}
          </>
        );
      },
    },
  ];

  const addPublisher = () => {
    setModalShow(true);
    setRowRecords([]);
  };

  const handleEdit = (row) => {
    setModalShow(true);
    setRowRecords(row);
  };

  const recordSaveSuccesfully = () => {
    setModalShow(false);
    fetchRecords();
  };

  const handleDeleteButton = (row) => {
    setShowDeleteModal(true);
    setRowDataId(row.id);
  };

  const handleDeleteRecord = async () => {
    if (rowDataId) {
      try {
        const result = await schoolApi.deletePublisher(rowDataId);
        if (result && result.message === "Successfully Deleted") {
          const deletePublisher = body?.filter((rec) => rec.id !== rowDataId);
          // PubSub.publish("RECORD_SAVED_TOAST", {
          //   title: "Record Deleted",
          //   message: "Record Deleted successfully",
          // });
          toast.success("Record Deleted successfully");
          setBody(deletePublisher);
          setShowDeleteModal(false);
        } else {
          console.error("deletion was not successfull", result);
        }
      } catch (error) {
        console.error("Errror deleteing ", error);
      }
    }
  };

  return (
    <>
    <Helmet>
        <title>{props?.tabName}</title>
      </Helmet>
      <Row className="g-0">
        <Col lg={2} className="mx-4">
          <Link className="nav-link" to="/">
            Home <i className="fa-solid fa-chevron-right"></i>{" "}
            <strong> Publisher</strong>
          </Link>
        </Col>
        <Col lg={12} className="p-lg-3">
          <DatatableWrapper
            body={body}
            headers={header}
            paginationOptionsProps={{
              initialState: {
                rowsPerPage: 15,
                options: [5, 10, 15, 20],
              },
            }}
          >
            <Row className="mb-4">
              <Col
                xs={12}
                lg={3}
                className="d-flex flex-col justify-content-end align-items-end"
              >
                <Filter />
              </Col>
              <Col
                xs={12}
                sm={6}
                lg={5}
                className="d-flex flex-col justify-content-start align-items-start"
              >
                <PaginationOptions labels={labels} />
                <div style={{ marginTop: "5px" }}>
                  <InfoPill left="Total Publishers" right={body?.length} />
                </div>
              </Col>
              <Col
                xs={12}
                sm={6}
                lg={4}
                className="d-flex flex-col justify-content-end align-items-end"
              >
                {(userInfo?.userrole === "SYS_ADMIN" ||
                  userInfo?.userrole === "ADMIN" ||
                  canCreate) && (
                  <Button
                    className="btn-light add-new-button"
                    variant=""
                    onClick={() => addPublisher(true)}
                  >
                    Add Publisher
                  </Button>
                )}
              </Col>
            </Row>
            <Table striped className="data-table custom-table">
              <TableHeader />
              {/*-------- Updated By mohd. sarfraj | 16-sept-2024 ------- */}
              {body.length > 0 ? (
                <TableBody />
              ) : (
                <TableBody>
                  <tr>
                    <td colSpan={7} className="text-center">
                      No Records Found!!!
                    </td>
                  </tr>
                </TableBody>
              )}
            </Table>
            <Pagination />
          </DatatableWrapper>
          {/* ) : (
            <ShimmerTable row={10} col={8} />
          )} */}
        </Col>
        <Col lg={2}></Col>
      </Row>

      {modalShow && (
        <AddPublisher
          show={modalShow}
          parent={rowRecords}
          onHide={() => setModalShow(false)}
          recordSaveSuccesfully={recordSaveSuccesfully}
        />
      )}
      {showDeleteModal && (
        <Confirm
          show={showDeleteModal}
          onHide={() => setShowDeleteModal(false)}
          handleDeleteButton={() => handleDeleteRecord()}
          title="Confirm delete?"
          message="You are going to delete the record. Are you sure?"
          table="deleteSyllabus"
        />
      )}
      <ToastContainer
        position="top-right"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </>
  );
};
export default PublisherList;
