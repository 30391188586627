import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";    //added  by muskan 
import {
  Button,
  Col,
  Row,
  Table,
  Dropdown,
  DropdownButton,
} from "react-bootstrap";
import { Link } from "react-router-dom";
import { ShimmerTable } from "react-shimmer-effects";
import InfoPill from "../InfoPill";
import schoolApi from "../../api/schoolApi";
import moment from "moment";
import { DatatableWrapper, Filter, Pagination, PaginationOptions, TableBody, TableHeader, } from "react-bs-datatable";
import AddIssueBook from "./AddIssueBook";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Confirm from "../Confirm";
import { usePermissions } from "../contexts/PermissionContext";

const IssueBookList = (props) => {
  const [body, setBody] = useState([]);
  const [modalShow, setModalShow] = useState(false);
  const [rowRecords, setRowRecords] = useState();
  const [statusRow, setStatusRow] = useState(undefined);
  const { rolePermissions, userInfo } = usePermissions();
  const canCreate = rolePermissions?.Issue_Book?.can_create;
  const canEdit = rolePermissions?.Issue_Book?.can_edit;

  const fetchRecords = async () => {
    const result = await schoolApi.getIssuesRecords();
    /*---------- Update by mohd. sarfraj | 16-sept-2024. -----------*/
    if (result?.success) {
      setBody(result?.issues);
    } else setBody([])
  };

  const handleEditStatus = async (val) => {
    setStatusRow(val);
  };

  useEffect(() => {
    fetchRecords();
  }, [statusRow]);

  const labels = {
    beforeSelect: " ",
  };
  const header = [
    {
      title: "Issue Number",
      prop: "book_issue_num",
      show: true,
      isFilterable: true,
      cell: (row) => (
        <Link to={"/issue_book/" + row.id} state={row}>
          {row.book_issue_num}
        </Link>
      ),
    },
    {
      title: "Name",
      prop: "parent_name",
      show: true,
      isFilterable: true,
    },
    {
      title: "Registration Id",
      prop: "parent_eid",
      show: true,
      isFilterable: true,
    },
    {
      title: "Book",
      prop: "book_title",
      show: true,
      isFilterable: true,
      cell: (row) => (
        <Link to={"/books/" + row.book_id} state={row}>
          {row.book_title}
        </Link>
      ),
    },
    {
      title: "Checkout Date",
      prop: "checkout_date",
      show: true,
      isFilterable: true,
      cell: (row) =>
        row.checkout_date
          ? moment(row.checkout_date).format("DD-MMM-YYYY")
          : "",
    },
    {
      title: "status",
      prop: "id",
      show: true,
      isFilterable: true,
      cell: (row) => {
        const handleStatusChange = (status) => {
          if (
            canEdit ||
            userInfo?.userrole === "SYS_ADMIN" ||
            userInfo?.userrole === "ADMIN"
          ) {
            handleEditStatus({ ...row, status });
          }
        };

        let currentStatus = "";
        let variant = "";

        if (row.status === "Issued") {
          currentStatus = "Issued";
          variant = "info";
        } else if (row.status === "Returned") {
          currentStatus = "Returned";
          variant = "outline-secondary";
        } else {
          currentStatus = "Missing";
          variant = "secondary";
        }

        return canEdit ||
          userInfo?.userrole === "SYS_ADMIN" ||
          userInfo?.userrole === "ADMIN" ? (
          <DropdownButton
            id="dropdown-status"
            title={currentStatus}
            variant={variant}
            size="sm"
            disabled={row.status === "Returned"}
            className="select-dropdown-btn"
          >
            <Dropdown.Item
              disabled
              onClick={() => handleStatusChange("Issued")}
            >
              Issued
            </Dropdown.Item>
            <Dropdown.Item onClick={() => handleStatusChange("Returned")}>
              Returned
            </Dropdown.Item>
            <Dropdown.Item onClick={() => handleStatusChange("Missing")}>
              Missing
            </Dropdown.Item>
          </DropdownButton>
        ) : (
          <Button variant={variant} size="sm" className="select-dropdown-btn">
            {currentStatus}
          </Button>
        );
      },
    },

    {
      title: "Actions",
      prop: "id",
      show:
        userInfo?.userrole === "SYS_ADMIN" || userInfo?.userrole === "ADMIN"
          ? true
          : canEdit,
      cell: (row) => {
        return (
          <>
            <Button
              className="btn-sm mx-2 edit-button"
              variant=""
              onClick={() => handleEdit(row)}
            >
              <i className="fa-regular fa-pen-to-square"></i>
            </Button>
          </>
        );
      },
    },
  ];

  const visibleHeader = header?.filter((col) => col.show);

  const addIssueBook = () => {
    setModalShow(true);
    setRowRecords([]);
  };

  const handleEdit = (row) => {
    setModalShow(true);
    setRowRecords(row);
  };

  const recordSaveSuccesfully = () => {
    setModalShow(false);
    fetchRecords();
  };

  const classListHandler = async () => {
    const currentDate = new Date();
    const year = currentDate.getFullYear();
    const month = String(currentDate.getMonth() + 1).padStart(2, "0");
    const date = String(currentDate.getDate()).padStart(2, "0");
    const formattedDate = `${year}-${month}-${date}`;
    const editRecord = {
      id: statusRow?.id,
      book_id: statusRow.book_id,
      parent_id: statusRow.parent_id,
      parent_type: statusRow.parent_type,
      checkout_date: statusRow.checkout_date,
      due_date: statusRow.due_date,
      remark: statusRow.remark,
      return_date: formattedDate,
      status: statusRow.status,
    };
    let response = {};
    response = await schoolApi.updateIssue(editRecord);
    if (response.success) {
      toast.success(response.message, { position: toast.POSITION.TOP_RIGHT });
      setStatusRow(undefined);
    } else {
      toast.error(response.message, { position: toast.POSITION.TOP_RIGHT });
    }
  };

  return (
    <>
      <Helmet>
        <title>{props?.tabName}</title>
      </Helmet>
      <Row className="g-0">
        <Col lg={2} className="mx-4">
          <Link className="nav-link" to="/">
            Home <i className="fa-solid fa-chevron-right"></i>{" "}
            <strong> Issue Book</strong>
          </Link>
        </Col>
        <Col lg={12} className="p-lg-3">
          <DatatableWrapper
            body={body}
            headers={visibleHeader}
            paginationOptionsProps={{
              initialState: {
                rowsPerPage: 15,
                options: [5, 10, 15, 20],
              },
            }}
          >
            <Row className="mb-4">
              <Col
                xs={12}
                lg={3}
                className="d-flex flex-col justify-content-end align-items-end"
              >
                <Filter />
              </Col>
              <Col
                xs={12}
                sm={6}
                lg={5}
                className="d-flex flex-col justify-content-start align-items-start"
              >
                <PaginationOptions labels={labels} />
                <div style={{ marginTop: "5px" }}>
                  <InfoPill left="Total Issues" right={body?.length} />
                </div>
              </Col>
              <Col
                xs={12}
                sm={6}
                lg={4}
                className="d-flex flex-col justify-content-end align-items-end"
              >
                {(userInfo?.userrole === "SYS_ADMIN" ||
                  userInfo?.userrole === "ADMIN" ||
                  canCreate) && (
                    <Button
                      className="btn-light add-new-button"
                      variant=""
                      onClick={() => addIssueBook(true)}
                    >
                      Issue Book
                    </Button>
                  )}
              </Col>
            </Row>
            <Table striped className="data-table custom-table">
              <TableHeader />
              {/* //------- Updated By mohd. sarfraj | 16-sept-2024 ------- */}
              {body.length > 0
                ? <TableBody />
                : <TableBody>
                  <tr>
                    <td colSpan={7} className="text-center">No Records Found!!!</td>
                  </tr>
                </TableBody>
              }
            </Table>
            <Pagination />
          </DatatableWrapper>
        </Col>
        <Col lg={2}></Col>
      </Row>
      {modalShow && (
        <AddIssueBook
          show={modalShow}
          parent={rowRecords}
          onHide={() => setModalShow(false)}
          recordSaveSuccesfully={recordSaveSuccesfully}
        />
      )}
      {statusRow && (
        <Confirm
          show={statusRow}
          onHide={() => setStatusRow(undefined)}
          changeClassStatus={classListHandler}
          title={`Confirm ${statusRow.status}`}
          message="You are going to update the status. Are you sure?"
          table="classList"
        />
      )}
      <ToastContainer />
    </>
  );
};
export default IssueBookList;
