import React, { useEffect, useState } from "react";
import schoolApi from "../../api/schoolApi";

import { Button, Card, Col, Form, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import PdfHeader from "../layout/common/PdfHeader";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import { Helmet } from "react-helmet";

const StudentBooksMonthlyReports = (props) => {
  const [reportData, setReportData] = useState({ session_id: "" });
  const [sessionOptions, setSessionOptions] = useState([]);
  const [showTable, setShowTable] = useState(false);
  const [bookMonthlyStudents, setBookMonthlyStudents] = useState([]);
  const [uniqueHeads, setUniqueHeads] = useState([]);
  const [dateRange, setDateRange] = useState("");

  useEffect(() => {
    const fetchAllSessions = async () => {
      try {
        const sessions = await schoolApi.fetchSessions();
        console.log("sessions =========>>>>", sessions);
        if (sessions) {
          const options = sessions.map((sess) => ({
            value: sess.id,
            label: sess.year,
          }));
          setSessionOptions(options);

          const currentSession = sessions.find((sess) => {
            const currentDate = new Date();
            const startDate = new Date(sess.startdate);
            const endDate = new Date(sess.enddate);
            return currentDate >= startDate && currentDate <= endDate;
          });
          console.log("current session year=====>>>???", currentSession);
          if (currentSession) {
            setReportData((prevState) => ({
              ...prevState,
              session_id: currentSession.id,
            }));
            const formattedStartDate = formatDate(currentSession.startdate);
            const formattedEndDate = formatDate(currentSession.enddate);
            setDateRange(`${formattedStartDate} to ${formattedEndDate}`);
          }
        }
      } catch (error) {
        // console.error("Error fetching sessions:", error);
        return toast.error(error);
      }
    };
    fetchAllSessions();
  }, []);

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = date.getDate();
    const month = date.toLocaleString("default", { month: "long" });
    const year = date.getFullYear();
    return `${day}${getOrdinal(day)} ${month} ${year}`;
  };

  const getOrdinal = (n) => {
    const s = ["th", "st", "nd", "rd"],
      v = n % 100;
    return s[(v - 20) % 10] || s[v] || s[0];
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setReportData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const onSearch = async () => {
    if (reportData.session_id) {
      try {
        const result = await schoolApi.fetchStudentBooksMontly(reportData);
        console.log("result--0000000", result);
        if (result && result.message === "No record found!!") {
          toast.success(result.message, {
            position: toast.POSITION.TOP_CENTER,
            hideProgressBar: true,
          });
          setShowTable(false);
          setBookMonthlyStudents([]);
        } else {
          setBookMonthlyStudents(result.data);
          if (result.data) {
            setShowTable(true);
          }

          const allHeads = result.data.flatMap((item) => item.heads);
          const uniqueHeadsSet = new Set(allHeads.map((head) => head.headname));
          setUniqueHeads([...uniqueHeadsSet]);
        }
      } catch (error) {
        setShowTable(false);
      }
    } else {
      toast.error("Please fill session year", {
        position: toast.POSITION.TOP_CENTER,
        hideProgressBar: true,
      });
      setShowTable(false);
      setBookMonthlyStudents([]);
    }
  };

  const handleDownload = async () => {
    const input = document.getElementById("reportContent");
    console.log("input", input);

    const canvas = await html2canvas(input, { scale: 2, allowTaint: true });
    console.log(canvas);
    const imgData = canvas.toDataURL("image/png");
    const pdf = new jsPDF("l", "mm", "a4");
    console.log("imgData", imgData);
    const pdfWidth = pdf.internal.pageSize.getWidth() - 20;
    const pdfHeight = (canvas.height * pdfWidth) / canvas.width;
    const imgProps = pdf.getImageProperties(imgData);
    const imgWidth = pdfWidth;
    const imgHeight = (imgProps.height * imgWidth) / imgProps.width;

    let heightLeft = imgHeight;
    let position = 10;

    while (heightLeft > 0) {
      pdf.addImage(imgData, "PNG", 10, position, imgWidth, imgHeight);
      heightLeft -= pdf.internal.pageSize.getHeight();
      if (heightLeft > 0) {
        pdf.addPage();
        position = 0;
      }
    }

    pdf.save("Student.pdf");
  };
  // Added by muskan khan 29-nov-24
  const formatNumberWithCommas = (number) => {
    console.log("number", number)
    return number ? number.toLocaleString("en-IN") : "0";
  };

  return (
    <>
      <Helmet><title>{props?.tabName}</title></Helmet>
      <Row className="g-0">
        <Col lg={2} className="mx-3">
          <Link className="nav-link mx-2" to="/">
            Home <i className="fa-solid fa-chevron-right"></i>Reports
          </Link>
        </Col>
      </Row>
      <Row className="mt-5 m-0 px-3">
        <Card className="px-0 py-3" style={{ margin: "-18px" }}>
          <Row className="m-0">
            <Col lg={12} className="px-3">
              <h5 className="fw-semibold">Filters</h5>
            </Col>
            <hr />
          </Row>
          <Row>
            <Col lg={4}>
              <Form.Group className="mx-3">
                <Form.Label className="form-view-label">Session</Form.Label>
                <Form.Select
                  name="session_id"
                  onChange={handleChange}
                  value={reportData.session_id}
                  required
                >
                  <option value="">-- Select Session --</option>
                  {sessionOptions.map((res) => (
                    <option key={res.value} value={res.value}>
                      {res.label}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>
            </Col>
            <Col lg={4} className="d-flex align-items-end">
              <div className="button-wrapper">
                <Button onClick={onSearch} variant="primary">
                  Search
                </Button>
                {bookMonthlyStudents && bookMonthlyStudents.length > 0 && (
                  <Button
                    onClick={handleDownload}
                    variant="primary"
                    className="mx-2"
                  >
                    Download
                  </Button>
                )}
              </div>
            </Col>
          </Row>
        </Card>
      </Row>

      {showTable && (
        <Row className="mt-5 m-0 px-4">
          <Card className="px-0 py-3">
            <Row className="flex-row mt-3 mb-2 px-4">
              <Col lg={12} className="p-0">
                <div id="reportContent">
                  <PdfHeader />
                  <div
                    style={{
                      borderTop: "2px solid rgb(0, 0, 0)",
                      margin: "10px 0px",
                    }}
                  ></div>
                  <Row className="mt-4">
                    <Col lg={12} className="px-3">
                      <h5
                        style={{ textAlign: "center" }}
                        className="fw-semibold"
                      >
                        (HDFC) Day Book Monthly Summary Sheet - {dateRange}
                      </h5>
                    </Col>
                    <hr />
                  </Row>
                  <table className="reportTable" id="reportTable">
                    <thead>
                      <tr style={{ height: "100px" }}>
                        <th
                          style={{ width: "110px" }}
                          className="text-wrap text-start  align-bottom"
                        >
                          <span
                            style={{
                              transformOrigin: "left",
                              transform: "translate(50%, 50%) rotate(-90deg)",
                              display: "block",
                            }}
                          >
                            Month
                          </span>
                        </th>
                        {uniqueHeads.map((headname, index) => (
                          console.log("first", headname),
                          <th
                            key={index}
                            className="text-wrap text-start align-bottom"
                          >
                            <span
                              style={{
                                transformOrigin: "left",
                                transform: "translate(50%, 50%) rotate(-90deg)",
                                display: "block",
                              }}
                            >
                              {typeof headname === "string" ? headname : ""}
                            </span>
                          </th>
                        ))}
                        <th className="text-wrap text-start align-bottom">
                          <span
                            style={{
                              transformOrigin: "left",
                              transform: "translate(50%, 50%) rotate(-90deg)",
                              display: "block",
                            }}
                          >
                            {" "}
                            Grand Total
                          </span>
                        </th>
                      </tr>
                    </thead>

                    <tbody>

                      {bookMonthlyStudents.map((data, index) => {
                        const rowTotal = data.heads.reduce((acc, head) => acc + head.amount, 0);
                        const formattedRowTotal = formatNumberWithCommas(rowTotal);
                        return (
                          <tr key={index}>
                            <td style={{ textAlign: "left" }}>{data.month}</td>
                            {uniqueHeads.map((headname, headIndex) => {
                              const head = data.heads.find((h) => h.headname === headname);
                              return (
                                <td style={{ textAlign: "right" }} key={headIndex}>
                                  {head ? formatNumberWithCommas(head.amount) : 0}
                                </td>
                              );
                            })}
                            <td style={{ textAlign: "right" }}>{formattedRowTotal}</td>
                          </tr>
                        );
                      })}
                      <tr>
                        <td style={{ textAlign: "right" }}>Total</td>
                        {uniqueHeads.map((headname, index) => (
                          <td style={{ textAlign: "right" }} key={index}>
                            {formatNumberWithCommas(
                              bookMonthlyStudents.reduce((acc, data) => {
                                const head = data.heads.find((h) => h.headname === headname);
                                return acc + (head ? head.amount : 0);
                              }, 0)
                            )}
                          </td>
                        ))}
                        <td>
                          {formatNumberWithCommas(
                            bookMonthlyStudents.reduce((totalAcc, data) => {
                              return (
                                totalAcc +
                                data.heads.reduce((acc, head) => acc + head.amount, 0)
                              );
                            }, 0)
                          )}
                        </td>
                      </tr>

                    </tbody>
                  </table>
                </div>
              </Col>
            </Row>
          </Card>
        </Row>
      )}
      <ToastContainer />
    </>
  );
};

export default StudentBooksMonthlyReports;

// import React, { useEffect, useState } from 'react';
// import schoolApi from '../../api/schoolApi';
// import Main from '../layout/Main';
// import { Button, Card, Col, Form, Row } from 'react-bootstrap';
// import { Link } from 'react-router-dom';
// import { toast, ToastContainer } from 'react-toastify';
// import PdfHeader from '../layout/common/PdfHeader';
// import jsPDF from "jspdf";
// import html2canvas from "html2canvas";

// const StudentBooksMonthlyReports = () => {
//   const [reportData, setReportData] = useState({ session_id: "" });
//   const [sessionOptions, setSessionOptions] = useState([]);
//   const [showTable, setShowTable] = useState(false);
//   const [bookMonthlyStudents, setBookMonthlyStudents] = useState([]);
//   const [uniqueHeads, setUniqueHeads] = useState([]);
//   const [dateRange, setDateRange] = useState("");

//   useEffect(() => {
//     const fetchAllSessions = async () => {
//       try {
//         const sessions = await schoolApi.fetchSessions();
//         console.log('sessions =========>>>>', sessions);
//         if (sessions) {
//           const options = sessions.map((sess) => ({
//             value: sess.id,
//             label: sess.year,
//           }));
//           setSessionOptions(options);

//           const currentSession = sessions.find(sess => {
//             const currentDate = new Date();
//             const startDate = new Date(sess.startdate);
//             const endDate = new Date(sess.enddate);
//             return currentDate >= startDate && currentDate <= endDate;
//           });
//           console.log('current session year=====>>>???', currentSession);
//           if (currentSession) {
//             setReportData(prevState => ({
//               ...prevState,
//               session_id: currentSession.id
//             }));
//             const formattedStartDate = formatDate(currentSession.startdate);
//             const formattedEndDate = formatDate(currentSession.enddate);
//             setDateRange(`${formattedStartDate} to ${formattedEndDate}`);
//           }
//         }
//       } catch (error) {
//         // console.error("Error fetching sessions:", error);
//         return toast.error(error);
//       }
//     };
//     fetchAllSessions();
//   }, []);

//   const formatDate = (dateString) => {
//     const date = new Date(dateString);
//     const day = date.getDate();
//     const month = date.toLocaleString('default', { month: 'long' });
//     const year = date.getFullYear();
//     return `${day}${getOrdinal(day)} ${month} ${year}`;
//   };

//   const getOrdinal = (n) => {
//     const s = ["th", "st", "nd", "rd"],
//           v = n % 100;
//     return (s[(v - 20) % 10] || s[v] || s[0]);
//   };

//   const handleChange = (event) => {
//     const { name, value } = event.target;
//     setReportData(prevState => ({
//       ...prevState,
//       [name]: value
//     }));
//   };

//   const onSearch = async () => {
//     if (reportData.session_id) {
//       try {
//         const result = await schoolApi.fetchStudentBooksMontly(reportData);
//         console.log('result--0000000', result);
//         if (result && result.message === "No record found!!") {
//           toast.success(result.message, {
//             position: toast.POSITION.TOP_CENTER,
//             hideProgressBar: true
//           });
//           setShowTable(false);
//           setBookMonthlyStudents([]);
//         } else {
//           setBookMonthlyStudents(result.data);
//           if(result.data){
//             setShowTable(true);
//           }

//           const allHeads = result.data.flatMap(item => item.heads);
//           const uniqueHeadsSet = new Set(allHeads.map(head => head.headname));
//           setUniqueHeads([...uniqueHeadsSet]);
//         }
//       } catch (error) {
//         setShowTable(false);
//       }
//     } else {
//       toast.error('Please fill session year', {
//         position: toast.POSITION.TOP_CENTER,
//         hideProgressBar: true,
//       });
//       setShowTable(false);
//       setBookMonthlyStudents([]);
//     }
//   };

//   const handleDownload = async () => {
//     const input = document.getElementById('reportContent');
//     const canvas = await html2canvas(input, { scale: 2 });
//     const imgData = canvas.toDataURL('image/png');
//     const pdf = new jsPDF('p', 'mm', 'a4');

//     const pdfWidth = pdf.internal.pageSize.getWidth() - 20;
//     const pdfHeight = (canvas.height * pdfWidth) / canvas.width;
//     const imgProps = pdf.getImageProperties(imgData);
//     const imgWidth = pdfWidth;
//     const imgHeight = (imgProps.height * imgWidth) / imgProps.width;

//     let heightLeft = imgHeight;
//     let position = 10;

//     while (heightLeft > 0) {
//         pdf.addImage(imgData, 'PNG', 10, position, imgWidth, imgHeight);
//         heightLeft -= pdf.internal.pageSize.getHeight();
//         if (heightLeft > 0) {
//             pdf.addPage();
//             position = 0;
//         }
//     }

//     pdf.save('Student.pdf');
//   };

//   return (
//     <>
//       <Row className="g-0">
//         <Col lg={2} className="mx-3">
//           <Link className="nav-link mx-2" to="/">Home <i className="fa-solid fa-chevron-right"></i>Reports</Link>
//         </Col>
//       </Row>
//       <Row className="mt-5 m-0 px-4">
//         <Card className="px-0 py-3">
//           <Row className="m-0">
//             <Col lg={12} className="px-3"><h5 className="fw-semibold">Filters</h5></Col>
//             <hr />
//           </Row>
//           <Row>
//             <Col lg={4}>
//               <Form.Group className="mx-3">
//                 <Form.Label className="form-view-label">Session</Form.Label>
//                 <Form.Select
//                   name="session_id"
//                   onChange={handleChange}
//                   value={reportData.session_id}
//                   required
//                 >
//                   <option value="">-- Select Session --</option>
//                   {sessionOptions.map((res) => (
//                     <option key={res.value} value={res.value}>
//                       {res.label}
//                     </option>
//                   ))}
//                 </Form.Select>
//               </Form.Group>
//             </Col>
//             <Col lg={4} className="d-flex align-items-end">
//               <div className="button-wrapper">
//                 <Button onClick={onSearch} variant="primary">Search</Button>
//                 {bookMonthlyStudents && bookMonthlyStudents.length > 0 && (
//                   <Button onClick={handleDownload} variant="primary" className="mx-2">Download</Button>
//                 )}
//               </div>
//             </Col>
//           </Row>
//         </Card>
//       </Row>

//       {showTable && (
//         <Row className="mt-5 m-0 px-4">
//           <Card className="px-0 py-3">
//             <Row className="flex-row mt-3 mb-2 px-4">
//               <Col lg={12} className="p-0">
//                 <div id="reportContent">
//                   <PdfHeader />
//                   <Row className="mt-4">
//                     <Col lg={12} className="px-3">
//                       <h5 style={{ textAlign: 'center' }} className="fw-semibold">(HDFC) Day Book Monthly Summary Sheet - {dateRange}</h5>
//                     </Col>
//                     <hr />
//                   </Row>
//                   <table className="reportTable" id="reportTable">
//                     <thead>
//                       <tr>
//                         <th style={{ width: "150px" }}>Month</th>
//                         {/* {uniqueHeads.map((headname, index) => (
//                           <th key={index}>{headname}</th>
//                         ))} */}
//                         {uniqueHeads.map((headname, index) => (
//   <th key={index}>{typeof headname === 'string' ? headname : ''}</th>
// ))}
//                         <th>Grand Total</th>
//                       </tr>
//                     </thead>
//                     {/* <tbody>
//                       {bookMonthlyStudents.map((data, index) => {
//                         const rowTotal = data.heads.reduce((acc, head) => acc + head.amount, 0);
//                         return (
//                           <tr key={index}>
//                             <td>{data.month}</td>
//                             {uniqueHeads.map((headname, headIndex) => {
//                               const head = data.heads.find(h => h.headname === headname);
//                               return <td key={headIndex}>{head ? head.amount : 0}</td>;
//                             })}
//                             <td>{rowTotal}</td>
//                           </tr>
//                         );
//                       })}
//                       {/ Add a totals row /}
//                       <tr>
//                         <td>Total</td>
//                         {uniqueHeads.map((headname, index) => (
//                           <td key={index}>
//                             {bookMonthlyStudents.reduce((acc, data) => {
//                               const head = data.heads.find(h => h.headname === headname);
//                               return acc + (head ? head.amount : 0);
//                             }, 0)}
//                           </td>
//                         ))}
//                         <td>
//                           {bookMonthlyStudents.reduce((totalAcc, data) => {
//                             return totalAcc + data.heads.reduce((acc, head) => acc + head.amount, 0);
//                           }, 0)}
//                         </td>
//                       </tr>
//                     </tbody> */}
//                     <tbody>
//   {bookMonthlyStudents.map((data, index) => {
//     const rowTotal = data.heads.reduce((acc, head) => acc + head.amount, 0);
//     return (
//       <tr key={index}>
//         <td>{data.month}</td>
//         {uniqueHeads.map((headname, headIndex) => {
//           const head = data.heads.find(h => h.headname === headname);
//           return <td key={headIndex}>{head ? head.amount : 0}</td>;
//         })}
//         <td>{rowTotal}</td>
//       </tr>
//     );
//   })}
//   <tr>
//     <td>Total</td>
//     {uniqueHeads.map((headname, index) => (
//       <td key={index}>
//         {bookMonthlyStudents.reduce((acc, data) => {
//           const head = data.heads.find(h => h.headname === headname);
//           return acc + (head ? head.amount : 0);
//         }, 0)}
//       </td>
//     ))}
//     <td>
//       {bookMonthlyStudents.reduce((totalAcc, data) => {
//         return totalAcc + data.heads.reduce((acc, head) => acc + head.amount, 0);
//       }, 0)}
//     </td>
//   </tr>
// </tbody>

//                   </table>
//                 </div>
//               </Col>
//             </Row>
//           </Card>
//         </Row>
//       )}
//       <ToastContainer/>
//       </>
//   );
// };

// export default StudentBooksMonthlyReports;
// import React, { useEffect, useState } from "react";
// import schoolApi from "../../api/schoolApi";

// import { Button, Card, Col, Form, Row } from "react-bootstrap";
// import { Link } from "react-router-dom";
// import { toast, ToastContainer } from "react-toastify";
// import PdfHeader from "../layout/common/PdfHeader";
// import jsPDF from "jspdf";
// import html2canvas from "html2canvas";
// import { Helmet } from "react-helmet";

// const StudentBooksMonthlyReports = (props) => {
//   const [reportData, setReportData] = useState({ session_id: "" });
//   const [sessionOptions, setSessionOptions] = useState([]);
//   const [showTable, setShowTable] = useState(false);
//   const [bookMonthlyStudents, setBookMonthlyStudents] = useState([]);
//   const [uniqueHeads, setUniqueHeads] = useState([]);
//   const [dateRange, setDateRange] = useState("");

//   useEffect(() => {
//     const fetchAllSessions = async () => {
//       try {
//         const sessions = await schoolApi.fetchSessions();
//         console.log("sessions =========>>>>", sessions);
//         if (sessions) {
//           const options = sessions.map((sess) => ({
//             value: sess.id,
//             label: sess.year,
//           }));
//           setSessionOptions(options);

//           const currentSession = sessions.find((sess) => {
//             const currentDate = new Date();
//             const startDate = new Date(sess.startdate);
//             const endDate = new Date(sess.enddate);
//             return currentDate >= startDate && currentDate <= endDate;
//           });
//           console.log("current session year=====>>>???", currentSession);
//           if (currentSession) {
//             setReportData((prevState) => ({
//               ...prevState,
//               session_id: currentSession.id,
//             }));
//             const formattedStartDate = formatDate(currentSession.startdate);
//             const formattedEndDate = formatDate(currentSession.enddate);
//             setDateRange(`${formattedStartDate} to ${formattedEndDate}`);
//           }
//         }
//       } catch (error) {
//         // console.error("Error fetching sessions:", error);
//         return toast.error(error);
//       }
//     };
//     fetchAllSessions();
//   }, []);

//   const formatDate = (dateString) => {
//     const date = new Date(dateString);
//     const day = date.getDate();
//     const month = date.toLocaleString("default", { month: "long" });
//     const year = date.getFullYear();
//     return `${day}${getOrdinal(day)} ${month} ${year}`;
//   };

//   const getOrdinal = (n) => {
//     const s = ["th", "st", "nd", "rd"],
//       v = n % 100;
//     return s[(v - 20) % 10] || s[v] || s[0];
//   };

//   const handleChange = (event) => {
//     const { name, value } = event.target;
//     setReportData((prevState) => ({
//       ...prevState,
//       [name]: value,
//     }));
//   };

//   const onSearch = async () => {
//     if (reportData.session_id) {
//       try {
//         const result = await schoolApi.fetchStudentBooksMontly(reportData);
//         console.log("result--0000000", result);
//         if (result && result.message === "No record found!!") {
//           toast.success(result.message, {
//             position: toast.POSITION.TOP_CENTER,
//             hideProgressBar: true,
//           });
//           setShowTable(false);
//           setBookMonthlyStudents([]);
//         } else {
//           setBookMonthlyStudents(result.data);
//           if (result.data) {
//             setShowTable(true);
//           }

//           const allHeads = result.data.flatMap((item) => item.heads);
//           const uniqueHeadsSet = new Set(allHeads.map((head) => head.headname));
//           setUniqueHeads([...uniqueHeadsSet]);
//         }
//       } catch (error) {
//         setShowTable(false);
//       }
//     } else {
//       toast.error("Please fill session year", {
//         position: toast.POSITION.TOP_CENTER,
//         hideProgressBar: true,
//       });
//       setShowTable(false);
//       setBookMonthlyStudents([]);
//     }
//   };

//   const handleDownload = async () => {
//     const input = document.getElementById("reportContent");
//     console.log("input", input);

//     const canvas = await html2canvas(input, { scale: 2, allowTaint: true });
//     console.log(canvas);
//     const imgData = canvas.toDataURL("image/png");
//     const pdf = new jsPDF("l", "mm", "a4");
//     console.log("imgData", imgData);
//     const pdfWidth = pdf.internal.pageSize.getWidth() - 20;
//     const pdfHeight = (canvas.height * pdfWidth) / canvas.width;
//     const imgProps = pdf.getImageProperties(imgData);
//     const imgWidth = pdfWidth;
//     const imgHeight = (imgProps.height * imgWidth) / imgProps.width;

//     let heightLeft = imgHeight;
//     let position = 10;

//     while (heightLeft > 0) {
//       pdf.addImage(imgData, "PNG", 10, position, imgWidth, imgHeight);
//       heightLeft -= pdf.internal.pageSize.getHeight();
//       if (heightLeft > 0) {
//         pdf.addPage();
//         position = 0;
//       }
//     }

//     pdf.save("Student.pdf");
//   };

//   return (
//     <>
//       <Helmet><title>{props?.tabName}</title></Helmet>
//       <Row className="g-0">
//         <Col lg={2} className="mx-3">
//           <Link className="nav-link mx-2" to="/">
//             Home <i className="fa-solid fa-chevron-right"></i>Reports
//           </Link>
//         </Col>
//       </Row>
//       <Row className="mt-5 m-0 px-3">
//         <Card className="px-0 py-3" style={{ margin: "-18px" }}>
//           <Row className="m-0">
//             <Col lg={12} className="px-3">
//               <h5 className="fw-semibold">Filters</h5>
//             </Col>
//             <hr />
//           </Row>
//           <Row>
//             <Col lg={4}>
//               <Form.Group className="mx-3">
//                 <Form.Label className="form-view-label">Session</Form.Label>
//                 <Form.Select
//                   name="session_id"
//                   onChange={handleChange}
//                   value={reportData.session_id}
//                   required
//                 >
//                   <option value="">-- Select Session --</option>
//                   {sessionOptions.map((res) => (
//                     <option key={res.value} value={res.value}>
//                       {res.label}
//                     </option>
//                   ))}
//                 </Form.Select>
//               </Form.Group>
//             </Col>
//             <Col lg={4} className="d-flex align-items-end">
//               <div className="button-wrapper">
//                 <Button onClick={onSearch} variant="primary">
//                   Search
//                 </Button>
//                 {bookMonthlyStudents && bookMonthlyStudents.length > 0 && (
//                   <Button
//                     onClick={handleDownload}
//                     variant="primary"
//                     className="mx-2"
//                   >
//                     Download
//                   </Button>
//                 )}
//               </div>
//             </Col>
//           </Row>
//         </Card>
//       </Row>

//       {showTable && (
//         <Row className="mt-5 m-0 px-4">
//           <Card className="px-0 py-3">
//             <Row className="flex-row mt-3 mb-2 px-4">
//               <Col lg={12} className="p-0">
//                 <div id="reportContent">
//                   <PdfHeader />
//                   <div
//                     style={{
//                       borderTop: "2px solid rgb(0, 0, 0)",
//                       margin: "10px 0px",
//                     }}
//                   ></div>
//                   <Row className="mt-4">
//                     <Col lg={12} className="px-3">
//                       <h5
//                         style={{ textAlign: "center" }}
//                         className="fw-semibold"
//                       >
//                         (HDFC) Day Book Monthly Summary Sheet - {dateRange}
//                       </h5>
//                     </Col>
//                     <hr />
//                   </Row>
//                   <table className="reportTable" id="reportTable">
//                     <thead>
//                       <tr style={{ height: "100px" }}>
//                         <th
//                           style={{ width: "110px" }}
//                           className="text-wrap text-start  align-bottom"
//                         >
//                           <span
//                             style={{
//                               transformOrigin: "left",
//                               transform: "translate(50%, 50%) rotate(-90deg)",
//                               display: "block",
//                             }}
//                           >
//                             Month
//                           </span>
//                         </th>
//                         {uniqueHeads.map((headname, index) => (
//                           <th
//                             key={index}
//                             className="text-wrap text-start align-bottom"
//                           >
//                             <span
//                               style={{
//                                 transformOrigin: "left",
//                                 transform: "translate(50%, 50%) rotate(-90deg)",
//                                 display: "block",
//                               }}
//                             >
//                               {typeof headname === "string" ? headname : ""}
//                             </span>
//                           </th>
//                         ))}
//                         <th className="text-wrap text-start align-bottom">
//                           <span
//                             style={{
//                               transformOrigin: "left",
//                               transform: "translate(50%, 50%) rotate(-90deg)",
//                               display: "block",
//                             }}
//                           >
//                             {" "}
//                             Grand Total
//                           </span>
//                         </th>
//                       </tr>
//                     </thead>

//                     <tbody>
//                       {bookMonthlyStudents.map((data, index) => {
//                         const rowTotal = data.heads.reduce(
//                           (acc, head) => acc + head.amount,
//                           0
//                         );
//                         return (
//                           <tr key={index}>
//                             <td style={{ textAlign: "left" }}>{data.month}</td>
//                             {uniqueHeads.map((headname, headIndex) => {
//                               const head = data.heads.find(
//                                 (h) => h.headname === headname
//                               );
//                               return (
//                                 <td
//                                   style={{ textAlign: "right" }}
//                                   key={headIndex}
//                                 >
//                                   {head ? head.amount : 0}
//                                 </td>
//                               );
//                             })}
//                             <td>{rowTotal}</td>
//                           </tr>
//                         );
//                       })}
//                       <tr>
//                         <td style={{ textAlign: "right" }}>Total</td>
//                         {uniqueHeads.map((headname, index) => (
//                           <td style={{ textAlign: "right" }} key={index}>
//                             {bookMonthlyStudents.reduce((acc, data) => {
//                               const head = data.heads.find(
//                                 (h) => h.headname === headname
//                               );
//                               return acc + (head ? head.amount : 0);
//                             }, 0)}
//                           </td>
//                         ))}
//                         <td>
//                           {bookMonthlyStudents.reduce((totalAcc, data) => {
//                             return (
//                               totalAcc +
//                               data.heads.reduce(
//                                 (acc, head) => acc + head.amount,
//                                 0
//                               )
//                             );
//                           }, 0)}
//                         </td>
//                       </tr>
//                     </tbody>
//                   </table>
//                 </div>
//               </Col>
//             </Row>
//           </Card>
//         </Row>
//       )}
//       <ToastContainer />
//     </>
//   );
// };

// export default StudentBooksMonthlyReports;

// // import React, { useEffect, useState } from 'react';
// // import schoolApi from '../../api/schoolApi';
// // import Main from '../layout/Main';
// // import { Button, Card, Col, Form, Row } from 'react-bootstrap';
// // import { Link } from 'react-router-dom';
// // import { toast, ToastContainer } from 'react-toastify';
// // import PdfHeader from '../layout/common/PdfHeader';
// // import jsPDF from "jspdf";
// // import html2canvas from "html2canvas";

// // const StudentBooksMonthlyReports = () => {
// //   const [reportData, setReportData] = useState({ session_id: "" });
// //   const [sessionOptions, setSessionOptions] = useState([]);
// //   const [showTable, setShowTable] = useState(false);
// //   const [bookMonthlyStudents, setBookMonthlyStudents] = useState([]);
// //   const [uniqueHeads, setUniqueHeads] = useState([]);
// //   const [dateRange, setDateRange] = useState("");

// //   useEffect(() => {
// //     const fetchAllSessions = async () => {
// //       try {
// //         const sessions = await schoolApi.fetchSessions();
// //         console.log('sessions =========>>>>', sessions);
// //         if (sessions) {
// //           const options = sessions.map((sess) => ({
// //             value: sess.id,
// //             label: sess.year,
// //           }));
// //           setSessionOptions(options);

// //           const currentSession = sessions.find(sess => {
// //             const currentDate = new Date();
// //             const startDate = new Date(sess.startdate);
// //             const endDate = new Date(sess.enddate);
// //             return currentDate >= startDate && currentDate <= endDate;
// //           });
// //           console.log('current session year=====>>>???', currentSession);
// //           if (currentSession) {
// //             setReportData(prevState => ({
// //               ...prevState,
// //               session_id: currentSession.id
// //             }));
// //             const formattedStartDate = formatDate(currentSession.startdate);
// //             const formattedEndDate = formatDate(currentSession.enddate);
// //             setDateRange(`${formattedStartDate} to ${formattedEndDate}`);
// //           }
// //         }
// //       } catch (error) {
// //         // console.error("Error fetching sessions:", error);
// //         return toast.error(error);
// //       }
// //     };
// //     fetchAllSessions();
// //   }, []);

// //   const formatDate = (dateString) => {
// //     const date = new Date(dateString);
// //     const day = date.getDate();
// //     const month = date.toLocaleString('default', { month: 'long' });
// //     const year = date.getFullYear();
// //     return `${day}${getOrdinal(day)} ${month} ${year}`;
// //   };

// //   const getOrdinal = (n) => {
// //     const s = ["th", "st", "nd", "rd"],
// //           v = n % 100;
// //     return (s[(v - 20) % 10] || s[v] || s[0]);
// //   };

// //   const handleChange = (event) => {
// //     const { name, value } = event.target;
// //     setReportData(prevState => ({
// //       ...prevState,
// //       [name]: value
// //     }));
// //   };

// //   const onSearch = async () => {
// //     if (reportData.session_id) {
// //       try {
// //         const result = await schoolApi.fetchStudentBooksMontly(reportData);
// //         console.log('result--0000000', result);
// //         if (result && result.message === "No record found!!") {
// //           toast.success(result.message, {
// //             position: toast.POSITION.TOP_CENTER,
// //             hideProgressBar: true
// //           });
// //           setShowTable(false);
// //           setBookMonthlyStudents([]);
// //         } else {
// //           setBookMonthlyStudents(result.data);
// //           if(result.data){
// //             setShowTable(true);
// //           }

// //           const allHeads = result.data.flatMap(item => item.heads);
// //           const uniqueHeadsSet = new Set(allHeads.map(head => head.headname));
// //           setUniqueHeads([...uniqueHeadsSet]);
// //         }
// //       } catch (error) {
// //         setShowTable(false);
// //       }
// //     } else {
// //       toast.error('Please fill session year', {
// //         position: toast.POSITION.TOP_CENTER,
// //         hideProgressBar: true,
// //       });
// //       setShowTable(false);
// //       setBookMonthlyStudents([]);
// //     }
// //   };

// //   const handleDownload = async () => {
// //     const input = document.getElementById('reportContent');
// //     const canvas = await html2canvas(input, { scale: 2 });
// //     const imgData = canvas.toDataURL('image/png');
// //     const pdf = new jsPDF('p', 'mm', 'a4');

// //     const pdfWidth = pdf.internal.pageSize.getWidth() - 20;
// //     const pdfHeight = (canvas.height * pdfWidth) / canvas.width;
// //     const imgProps = pdf.getImageProperties(imgData);
// //     const imgWidth = pdfWidth;
// //     const imgHeight = (imgProps.height * imgWidth) / imgProps.width;

// //     let heightLeft = imgHeight;
// //     let position = 10;

// //     while (heightLeft > 0) {
// //         pdf.addImage(imgData, 'PNG', 10, position, imgWidth, imgHeight);
// //         heightLeft -= pdf.internal.pageSize.getHeight();
// //         if (heightLeft > 0) {
// //             pdf.addPage();
// //             position = 0;
// //         }
// //     }

// //     pdf.save('Student.pdf');
// //   };

// //   return (
// //     <>
// //       <Row className="g-0">
// //         <Col lg={2} className="mx-3">
// //           <Link className="nav-link mx-2" to="/">Home <i className="fa-solid fa-chevron-right"></i>Reports</Link>
// //         </Col>
// //       </Row>
// //       <Row className="mt-5 m-0 px-4">
// //         <Card className="px-0 py-3">
// //           <Row className="m-0">
// //             <Col lg={12} className="px-3"><h5 className="fw-semibold">Filters</h5></Col>
// //             <hr />
// //           </Row>
// //           <Row>
// //             <Col lg={4}>
// //               <Form.Group className="mx-3">
// //                 <Form.Label className="form-view-label">Session</Form.Label>
// //                 <Form.Select
// //                   name="session_id"
// //                   onChange={handleChange}
// //                   value={reportData.session_id}
// //                   required
// //                 >
// //                   <option value="">-- Select Session --</option>
// //                   {sessionOptions.map((res) => (
// //                     <option key={res.value} value={res.value}>
// //                       {res.label}
// //                     </option>
// //                   ))}
// //                 </Form.Select>
// //               </Form.Group>
// //             </Col>
// //             <Col lg={4} className="d-flex align-items-end">
// //               <div className="button-wrapper">
// //                 <Button onClick={onSearch} variant="primary">Search</Button>
// //                 {bookMonthlyStudents && bookMonthlyStudents.length > 0 && (
// //                   <Button onClick={handleDownload} variant="primary" className="mx-2">Download</Button>
// //                 )}
// //               </div>
// //             </Col>
// //           </Row>
// //         </Card>
// //       </Row>

// //       {showTable && (
// //         <Row className="mt-5 m-0 px-4">
// //           <Card className="px-0 py-3">
// //             <Row className="flex-row mt-3 mb-2 px-4">
// //               <Col lg={12} className="p-0">
// //                 <div id="reportContent">
// //                   <PdfHeader />
// //                   <Row className="mt-4">
// //                     <Col lg={12} className="px-3">
// //                       <h5 style={{ textAlign: 'center' }} className="fw-semibold">(HDFC) Day Book Monthly Summary Sheet - {dateRange}</h5>
// //                     </Col>
// //                     <hr />
// //                   </Row>
// //                   <table className="reportTable" id="reportTable">
// //                     <thead>
// //                       <tr>
// //                         <th style={{ width: "150px" }}>Month</th>
// //                         {/* {uniqueHeads.map((headname, index) => (
// //                           <th key={index}>{headname}</th>
// //                         ))} */}
// //                         {uniqueHeads.map((headname, index) => (
// //   <th key={index}>{typeof headname === 'string' ? headname : ''}</th>
// // ))}
// //                         <th>Grand Total</th>
// //                       </tr>
// //                     </thead>
// //                     {/* <tbody>
// //                       {bookMonthlyStudents.map((data, index) => {
// //                         const rowTotal = data.heads.reduce((acc, head) => acc + head.amount, 0);
// //                         return (
// //                           <tr key={index}>
// //                             <td>{data.month}</td>
// //                             {uniqueHeads.map((headname, headIndex) => {
// //                               const head = data.heads.find(h => h.headname === headname);
// //                               return <td key={headIndex}>{head ? head.amount : 0}</td>;
// //                             })}
// //                             <td>{rowTotal}</td>
// //                           </tr>
// //                         );
// //                       })}
// //                       {/ Add a totals row /}
// //                       <tr>
// //                         <td>Total</td>
// //                         {uniqueHeads.map((headname, index) => (
// //                           <td key={index}>
// //                             {bookMonthlyStudents.reduce((acc, data) => {
// //                               const head = data.heads.find(h => h.headname === headname);
// //                               return acc + (head ? head.amount : 0);
// //                             }, 0)}
// //                           </td>
// //                         ))}
// //                         <td>
// //                           {bookMonthlyStudents.reduce((totalAcc, data) => {
// //                             return totalAcc + data.heads.reduce((acc, head) => acc + head.amount, 0);
// //                           }, 0)}
// //                         </td>
// //                       </tr>
// //                     </tbody> */}
// //                     <tbody>
// //   {bookMonthlyStudents.map((data, index) => {
// //     const rowTotal = data.heads.reduce((acc, head) => acc + head.amount, 0);
// //     return (
// //       <tr key={index}>
// //         <td>{data.month}</td>
// //         {uniqueHeads.map((headname, headIndex) => {
// //           const head = data.heads.find(h => h.headname === headname);
// //           return <td key={headIndex}>{head ? head.amount : 0}</td>;
// //         })}
// //         <td>{rowTotal}</td>
// //       </tr>
// //     );
// //   })}
// //   <tr>
// //     <td>Total</td>
// //     {uniqueHeads.map((headname, index) => (
// //       <td key={index}>
// //         {bookMonthlyStudents.reduce((acc, data) => {
// //           const head = data.heads.find(h => h.headname === headname);
// //           return acc + (head ? head.amount : 0);
// //         }, 0)}
// //       </td>
// //     ))}
// //     <td>
// //       {bookMonthlyStudents.reduce((totalAcc, data) => {
// //         return totalAcc + data.heads.reduce((acc, head) => acc + head.amount, 0);
// //       }, 0)}
// //     </td>
// //   </tr>
// // </tbody>

// //                   </table>
// //                 </div>
// //               </Col>
// //             </Row>
// //           </Card>
// //         </Row>
// //       )}
// //       <ToastContainer/>
// //       </>
// //   );
// // };

// // export default StudentBooksMonthlyReports;
