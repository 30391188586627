import React, { useState, useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { Link, useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import "react-bootstrap-typeahead/css/Typeahead.css";
import schoolApi from "../../api/schoolApi";
import moment from "moment";

import { Helmet } from "react-helmet";
import PageNavigations from "../breadcrumbs/PageNavigations";
import Select from "react-select";
import { ToastContainer, toast } from "react-toastify"; //add by shahir hussain 03-09-2024
import "react-toastify/dist/ReactToastify.css"; //add by shahir hussain 03-09-2024

const StudentAddmissionEdit = (props) => {
  const [validated, setValidated] = useState(false);
  const location = useLocation();
  console.log("location.state on view-->", location?.state);

  const navigate = useNavigate();
  const [studentaddmission, setStudentAddmission] = useState({
    fee_type_id: location?.state?.fee_type,
  });
  const [student, setStudent] = useState();
  const [classes, setClasses] = useState();
  const [sessions, setSession] = useState();
  const [parent, setParent] = useState();
  const [discountRecords, setDiscountRecords] = useState();
  const [discountOptions, setDiscountOptions] = useState();
  const [studentInstallments, setStudentInstallments] = useState([]);
  const [feeTypeOptions, setFeeTypeOptions] = useState([]);
  const [disableField, setDisableField] = useState(false);
  const [upcomingTypes, setUpcomingType] = useState({
    Monthly: true,
    "Bi-Monthly": false,
    Quarterly: false,
    "Half Yearly": false,
  });
  let classList;
  useEffect(() => {
    if (location?.state) {
      console.log("YYYY");
      setDisableField(true);
    }

    async function related() {
      const student = await schoolApi.fetchStudents();
      const classes = await schoolApi.getActiveClassRecords();
      const session = await schoolApi.fetchSessions();
      console.log("parent==>", parent);
      if (student.success) {
        console.log("student=>", student.students);
        let ar = [];
        student.students.map((item) => {
          var obj = {};
          obj.value = item.id;
          obj.label = item.firstname + " " + item.lastname;
          ar.push(obj);
        });
        setStudent(ar);
      }
      if (classes.success) {
        setClasses(classes.result);
      }
      if (session.length > 0) {
        setSession(session);
      }

      fetchDiscountLineItems();
    }
    studentFeeInstallment();
    related();
  }, []);

  console.log("upcomingTypes-1-->", upcomingTypes);

  const studentFeeInstallment = async () => {
    try {
      let feeMasterResult = await schoolApi.fetchFeeMasterByIdOrClassid(
        location?.state?.classid
      );
      console.log("feeMasterResult by classid-->", feeMasterResult);

      const currentSelectedType = feeMasterResult?.result?.find(
        (res) => res.id === studentaddmission?.fee_type_id
      )?.type;
      console.log("currentSelectedType-->", currentSelectedType);

      if (feeMasterResult.success) {
        let ar = [];
        feeMasterResult.result.map((item) => {
          //created this code by shahir hussain 04-09-2024
          if (item.sessionid == location?.state.session_id) {
            var obj = {};
            obj.value = item.id;
            obj.label = item.type;
            ar.push(obj);
          }
        });
        console.log("ar- fee master result->", ar);
        setFeeTypeOptions(ar);
      } else {
        setFeeTypeOptions([]);
      }
      let result = await schoolApi.fetchStudentFeeInstallments(
        location?.state?.student_addmission_id
      );
      console.log("result studentFeeInstallment ==>", result);

      const pendingInstallmentCount = result.result.filter(
        (res) => res.status === "pending"
      );
      console.log("pendingInstallmentCount-->", pendingInstallmentCount);

      if (currentSelectedType === "Half Yearly") {
        console.log("inside 1", currentSelectedType);
        if (pendingInstallmentCount.length === 1) {
          console.log("inside 11-->", currentSelectedType);
          setUpcomingType((prev) => {
            return {
              ...prev,
              Monthly: true,
              "Bi-Monthly": true,
              Quarterly: true,
              "Half Yearly": false,
            };
          });
        } else if (pendingInstallmentCount.length === 2) {
          console.log("inside 12-->", currentSelectedType);
          setUpcomingType((prev) => {
            return {
              ...prev,
              Monthly: true,
              "Bi-Monthly": true,
              Quarterly: true,
              "Half Yearly": false,
            };
          });
        }
      } else if (currentSelectedType === "Quarterly") {
        if (pendingInstallmentCount.length === 1) {
          setUpcomingType((prev) => {
            return {
              ...prev,
              Monthly: true,
              "Bi-Monthly": false,
              Quarterly: false,
              "Half Yearly": false,
            };
          });
        } else if (pendingInstallmentCount.length === 2) {
          setUpcomingType((prev) => {
            return {
              ...prev,
              Monthly: true,
              "Bi-Monthly": true,
              Quarterly: false,
              "Half Yearly": false,
            };
          });
        } else if (pendingInstallmentCount.length === 3) {
          setUpcomingType((prev) => {
            return {
              ...prev,
              Monthly: true,
              "Bi-Monthly": false,
              Quarterly: false,
              "Half Yearly": false,
            };
          });
        } else if (pendingInstallmentCount.length === 4) {
          setUpcomingType((prev) => {
            return {
              ...prev,
              Monthly: true,
              "Bi-Monthly": true,
              Quarterly: false,
              "Half Yearly": false,
            };
          });
        }
      } else if (currentSelectedType === "Bi-Monthly") {
        setUpcomingType((prev) => {
          return {
            ...prev,
            Monthly: true,
            "Bi-Monthly": false,
            Quarterly: false,
            "Half Yearly": false,
          };
        });
      }

      if (result.success) {
        setStudentInstallments(result.result);
      } else {
        setStudentInstallments([]);
      }
    } catch (error) {
      console.error("Error fetching studentFeeInstallment:", error);
    }
  };
  console.log("upcomingTypes-2-->", upcomingTypes);
  console.log("studentInstallments-->", studentInstallments);

  const fetchDiscountLineItems = async () => {
    console.log("fetchDiscountLineItemsData");
    //Add by Aamir khan
    try {
      // -------- Last updated by pooja on 14 august 2024---------
      // let result = await schoolApi.fetchFeeDiscountLineItemsBySt(location?.state?.student_addmission_id);
      let result = await schoolApi.fetchFeeDiscountLineItemsBySt(
        location.state.student_addmission_id
          ? location?.state?.student_addmission_id
          : location?.state?.id
      );
      console.log("Array result-->", result);
      console.log("object==>", !Array.isArray(result.Array));
      if (!Array.isArray(result)) {
        console.log("1@#result===>", result);
        result.result.forEach((res) => {
          console.log("resData==>", res);
          setDiscountOptions((prevOptions) => {
            console.log("Data Student");
            if (!prevOptions) {
              prevOptions = [];
            }
            if (!prevOptions.some((prev) => prev.label === res.discount_name)) {
              return [
                ...prevOptions,
                { value: res.discountid, label: res.discount_name },
              ];
            }
            return prevOptions;
          });
        });
      } else {
        console.log("Result Obj==>", result);
      }

      console.log("StuAddmission");
      try {
        let allDiscountResults = await schoolApi.fetchFeeDiscounts();
        console.log("@@#allDiscountResults==>", allDiscountResults);
        if (allDiscountResults[0].json_agg) {
          let ar = [];
          let data;
          allDiscountResults[0].json_agg.map((item) => {
            console.log("item for discount-->", item);
            var obj = {};
            obj.value = item.discount.id;
            obj.label = item.discount.name;
            ar.push(obj);
          });
          data = ar.filter((rec) => !rec.label.toLowerCase().includes("rte"));
          setDiscountRecords(data);
        } else {
          console.log("Not Data Found");
          setDiscountRecords([]);
        }
      } catch (error) {
        console.log("@#error==>", error);
      }
    } catch (error) {
      console.log("ErrorErrorPoint", error);
    }
  };

  const handleIsDefaulterChange = (e) => {
    console.log("e.target.name ======>>>", e.target.name, e.target.checked);
    setStudentAddmission({
      ...studentaddmission,
      [e.target.name]: e.target.checked,
    });
  };

  useEffect(() => {
    //Added by Farhan Khan This is Using to show Select Class Section on Edit Time.
    if (location.state?.id) {
      let temp2 = {};
      temp2.value = location?.state.classid;
      temp2.label = location?.state.classname;
    }
    if (location.state?.id) {
      let temp2 = {};
      temp2.value = location?.state.parentid;
      temp2.label = location?.state.parentname;
    }
    if (location.state?.id) {
      let temp2 = {};
      temp2.value = location?.state.vehicleid;
      temp2.label = location?.state.vehicle_number;
    }
    if (location.state?.id) {
      console.log("inn tj loa=>", location.state?.id);
      console.log("inn tj loa%$%=>", location.state);
      setStudentAddmission(location?.state);
    }
  }, []);

  const handleChange = (e) => {
    console.log("inside the handleChange", e.target.value);
    setStudentAddmission({
      ...studentaddmission,
      [e.target.name]: e.target.value,
    });
  };
  const handleDiscounts = (selectedOption) => {
    console.log("selectedOption-->", selectedOption);
    setDiscountOptions(selectedOption);
  };

  // const handleFeeChange = (e)=>{
  //  }
  console.log("discountOptions-->", discountOptions);
  const handleStudent = (e) => {
    console.log("inside the handleChange", e.target.value);
    setStudentAddmission({
      ...studentaddmission,
      [e.target.name]: e.target.value,
    });
  };

  const checkRequredFields = () => {
    if (
      // studentaddmission.student_addmission_id &&
      // studentaddmission.student_addmission_id.trim() !== ""
      studentaddmission.id &&
      studentaddmission.id.trim() !== ""
    ) {
      return false;
    }
    return true;
  };
  console.log("studentaddmission-1->", studentaddmission);

  const handleSubmit = async (e) => {
    console.log("studentaddmission-2->", studentaddmission);
    e.preventDefault();
    if (checkRequredFields()) {
      setValidated(true);
      return;
    }
    //create this functionillity by shahir hussain 03-09-2024
    if (
      !studentaddmission.status ||
      !studentaddmission.student_addmission_id ||
      !studentaddmission.fee_type
    ) {
      return toast.error("Fill all required values!!!");
    }
    //========= Logic to perform Create or Edit ======
    let result2 = {};
    // let updateDisRec = [];
    console.log("yffeh=>", studentaddmission);
    if (studentaddmission.id) {
      result2 = await schoolApi.saveStudentAddmission(studentaddmission);
      console.log("student addmission edit result-->", result2);

      const st = {
        id: studentaddmission.studentid,
        status: studentaddmission.status,
      };

      if (studentaddmission.status) {
        const updateStudent = await schoolApi.updateStudent(st);
        console.log("updateStudent", updateStudent);
      }

      if (discountOptions) {
        console.log("discountOptions-on handle submit->", discountOptions);
        let updateDisRec = {
          student_addmission_id: location?.state?.id,
          category: location?.state?.category,
          fee_type: location?.state?.fee_type,
          discounts: discountOptions,
        };
        console.log("updateDisRec-->", updateDisRec);
        let updatedResult = await schoolApi.updateFeeDiscountLineItems(
          updateDisRec
        );
        console.log("updatedResult-->", updatedResult);
      }

      if (result2.success) {
        toast.success("Record save successfully");
        setTimeout(() => {
          navigate(-1);
        }, 1000);

        // navigate(`/studentaddmissions/${studentaddmission.id}`, {
        //   state: studentaddmission,
        // });
      }
    } else {
      result2 = await schoolApi.createStudentAddmission(studentaddmission);

      if (result2) {
        toast.success("Record save successfully");
        setTimeout(() => {
          navigate(-1);
        }, 1000);

        //        navigate(`/studentaddmissions/${result2.id}`, { state: result2 });
      }
    }
  };

  console.log("studentaddmission--->", studentaddmission);

  const handleCancel = () => {
    navigate(-1);
  };

  const handleInputChange = (e, rowIndex, propName) => {
    const { value } = e.target;
    const updatedInstallments = [...studentInstallments];
    console.log("updatedInstallments-->", updatedInstallments);
    updatedInstallments[rowIndex][propName] = value;

    console.log("updatedInstallments-->", updatedInstallments);
  };

  return (
    <>
      <Helmet>
        <title>{props?.tabName}</title>
      </Helmet>
      <ToastContainer
        position="top-right"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />

      <Row>
        <Col lg={10}>
          <Link className="nav-link" to="/">Home <i className="fa-solid fa-chevron-right"></i> Admission List <i className="fa-solid fa-chevron-right"></i> Admission View </Link>
        </Col>
      </Row>
      <Container className="view-form">
        <Form>
          <Row>
            <Col lg={12}>
              <Form
                className="mt-3"
                onSubmit={handleSubmit}
                noValidate
                validated={validated}
              >
                <Row className="view-form-header align-items-center mx-3">
                  <Col lg={3}>Edit Student Addmission</Col>
                  <Col lg={9} className="d-flex justify-content-end">
                    <Button className="btn-sm mx-2" onClick={handleSubmit}>
                      Save
                    </Button>
                    <Button
                      className="btn-sm"
                      variant="danger"
                      onClick={handleCancel}
                    >
                      Cancel
                    </Button>
                  </Col>
                </Row>
                <Row className="mx-3">
                  <Col lg={4}>
                    <Form.Group className="mx-3">
                      <Form.Label className="form-view-label">
                        Student Name
                      </Form.Label>
                      <Form.Select
                        name="studentid"
                        onChange={handleStudent}
                        // required
                        value={studentaddmission.studentid}
                        disabled={disableField}
                      >
                        <option value="">-- Select Student --</option>
                        {student &&
                          student.map((res) => (
                            <option key={res.value} value={res.value}>
                              {res.label}
                            </option>
                          ))}
                      </Form.Select>
                    </Form.Group>
                  </Col>
                  <Col lg={4}>
                    <Form.Group className="mx-3">
                      <Form.Label className="form-view-label">
                        Class Name
                      </Form.Label>
                      <Form.Select
                        name="classid"
                        onChange={handleChange}
                        // required
                        value={studentaddmission.classid}
                        disabled={disableField}
                      >
                        <option value="">-- Select Class --</option>
                        {classes &&
                          classes.map((res) => (
                            <option key={res.id} value={res.id}>
                              {res.classname}
                            </option>
                          ))}
                      </Form.Select>
                    </Form.Group>
                  </Col>
                  <Col lg={4}>
                    <Form.Group className="mx-3">
                      <Form.Label
                        className="form-view-label"
                        htmlFor="formBasicAddmissionDate"
                      >
                        Form No
                      </Form.Label>
                      <Form.Control
                        type="text"
                        disabled={disableField}
                        name="formno"
                        placeholder="Enter Form Number"
                        value={studentaddmission.formno}
                        onChange={handleChange}
                      />
                    </Form.Group>
                  </Col>
                  <Col lg={4}>
                    <Form.Group className="mx-3">
                      <Form.Label className="form-view-label">
                        {" "}
                        Date Of Admission{" "}
                      </Form.Label>
                      <Form.Control
                        type="date"
                        name="dateofaddmission"
                        placeholder="Enter date of addmission"
                        value={
                          studentaddmission?.dateofaddmission
                            ? moment(
                              studentaddmission.dateofaddmission,
                              "DD-MM-YYYY"
                            ).format("YYYY-MM-DD")
                            : ""
                        }
                        onChange={handleChange}
                        disabled={disableField}
                      />
                    </Form.Group>
                  </Col>
                  <Col lg={4}>
                    <Form.Group className="mx-3">
                      <Form.Label
                        className="form-view-label"
                        htmlFor="formBasicAddmissionDate"
                      >
                        Session
                      </Form.Label>
                      <Form.Select
                        name="sessionid"
                        value={studentaddmission?.session_id}
                        disabled={disableField}
                      >
                        <option value="" disabled>
                          -- Select option --
                        </option>
                        {sessions &&
                          sessions.map((res) => (
                            <option key={res.id} value={res.id}>
                              {res.year}
                            </option>
                          ))}
                      </Form.Select>
                    </Form.Group>
                  </Col>
                  <Col lg={4}>
                    <Form.Group className="mx-3">
                      <Form.Label className="form-view-label">
                        Parent Name
                      </Form.Label>
                      <Form.Select
                        name="parentid"
                        // onChange={handleChange}
                        value={studentaddmission.parentid}
                        disabled={disableField}
                      >
                        <option value="">-- Select Parent --</option>
                        <option value={studentaddmission.parentid}>
                          {studentaddmission.parentname}
                        </option>
                        {/* {parent && parent.map((res) => (
                          <option key={res.value} value={res.value}>
                            {res.label}
                          </option>
                        ))} */}
                      </Form.Select>
                    </Form.Group>
                  </Col>
                  <Col lg={4}>
                    <Form.Group className="mx-3">
                      <Form.Label className="text-formatted">
                        Fee Installment Type
                      </Form.Label>
                      <Form.Select
                        required
                        name="fee_type"
                        value={studentaddmission?.fee_type}
                        onChange={handleChange}
                      >
                        <option value="">-- Select Type --</option>
                        {feeTypeOptions.map((res) => (
                          <option
                            key={res.value}
                            value={res.value}
                            disabled={!upcomingTypes[res.label]}
                          >
                            {res.label}
                          </option>
                        ))}
                      </Form.Select>
                    </Form.Group>
                  </Col>
                  <Col lg={4}>
                    <Form>
                      <Form.Group className="mx-3 fees">
                        <Form.Label
                          className="form-view-label"
                          htmlFor="formBasicClass"
                        >
                          Select Discounts
                        </Form.Label>
                        <Select
                          placeholder="Select discounts"
                          value={discountOptions}
                          onChange={handleDiscounts}
                          options={discountRecords}
                          isMulti={true}
                          name="disount"
                        ></Select>
                      </Form.Group>
                    </Form>
                  </Col>
                  <Col lg={4}>
                    <Form.Group className="mx-3 fees">
                      <Form.Label
                        className="form-view-label"
                        htmlFor="formBasicClass"
                      >
                        Defaulter
                      </Form.Label>
                      <Form.Check
                        type="checkbox"
                        style={{ fontSize: "20px" }}
                        name="is_defaulter"
                        checked={studentaddmission.is_defaulter}
                        onChange={handleIsDefaulterChange}
                      />
                    </Form.Group>
                  </Col>
                  <Col lg={4}>
                    <Form.Group className="mx-3">
                      <Form.Label>Status</Form.Label>
                      <Form.Select
                        name="status"
                        value={studentaddmission?.status}
                        onChange={handleChange}
                        required
                      >
                        <option value="">-- Select status --</option>
                        <option value="present">Present</option>
                        <option value="dropout">Dropout</option>
                      </Form.Select>
                    </Form.Group>
                  </Col>
                </Row>

              </Form>
            </Col>
          </Row>
        </Form>

      </Container>
    </>
  );
};

export default StudentAddmissionEdit;
