import React, { useState, useEffect } from "react";
import { Col, Container, Form, Row, Modal, Button } from "react-bootstrap";
import schoolApi from "../../api/schoolApi";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import jwt_decode from "jwt-decode";
import { useNavigate } from "react-router-dom";

const AddUser = (props) => {
  const token = sessionStorage.getItem("token");
  const decodedToken = jwt_decode(token);
  const companyid = decodedToken.companyid;
  const navigate = useNavigate();

  const [body, setBody] = useState([]);
  const [selectedEmployee, setSelectedEmployee] = useState("");
  const [roles, setRoles] = useState([]);
  const [rowRecord, setRowRecord] = useState({
    firstname: "",
    lastname: "",
    email: "",
    password: "",
    phone: "",
    role_id: "",
    companyid: "",
    userrole: ""
  });

  useEffect(() => {
    const fetchRoles = async () => {
      const result = await schoolApi.getAllRolesByCompanyId(companyid);
      if(result.success){
        setRoles(result.result);
      } else {
        setRoles([]);
      }
    };
    fetchRoles();
  }, [companyid]);

  useEffect(() => {
    if (props?.parent) {
      setRowRecord(props.parent);
      setSelectedEmployee(props.parent.employee); // Set the selected employee if editing
    }
  }, [props.parent]);

  useEffect(() => {
    const init = async () => {
      const result = await schoolApi.fetchStaffContacts();
      setBody(result.success ? result.contacts : []);
    };
    init();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setRowRecord((prevRecord) => ({ ...prevRecord, [name]: value }));

    if (name === "employee") {
      const selected = body.find((employee) => employee.id === value);
      if (selected) {
        setRowRecord((prevRecord) => ({
          ...prevRecord,
          firstname: selected.firstname,
          lastname: selected.lastname,
          email: selected.email,
          phone: selected.phone,
          password: prevRecord.password,
        }));
        setSelectedEmployee(value);
      }
    } else if (name === "role_id") {
      const selectedRole = roles.find((role) => role.id === value);
      if (selectedRole) {
        setRowRecord((prevRecord) => ({
          ...prevRecord,
          role_id: value, // Ensure role_id is correctly updated
          userrole: selectedRole.name,
          companyid: selectedRole.company_id,
        }));
      }
    }
  };

  const validateForm = () => {
    const { firstname, lastname, email, password, phone, role_id } = rowRecord;
    if (!firstname || !lastname || !email || (!props?.parent?.id && (!password || !phone)) || !role_id) {
      toast.error("All fields are required.");
      return false;
    }
    return true;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateForm()) return;

    let getAllUsers;
    try {
      getAllUsers = await schoolApi.fetchUsersbyCompanyId();
    } catch (error) {
      console.error("Error fetching users:", error);
      toast.error("Failed to fetch users. Please try again later.");
      return;
    }

    const editRecord = { ...rowRecord, companyid };

    if (props?.parent?.id) {
      editRecord.id = props.parent.id;

      const existingUser = getAllUsers?.userRec.find(
        (user) => user.firstname === editRecord.firstname && user.id !== editRecord.id
      );

      if (existingUser) {
        toast.error("User with this name already exists!");
        return;
      }

      // Update user
      try {
        const response = await schoolApi.updateUser(editRecord);
        if (response.success) {
          setRowRecord(response.updateBook)
          toast.success(response.message);
          
          recordSaveSuccesfully();
          setTimeout(() => navigate(`/userlist`), 1200);
        } else {
          toast.error(response.message);
        }
      } catch (error) {
        console.error("Error updating user:", error);
        toast.error("Internal server error");
      }
    } else {
      const existingUser = getAllUsers?.userRec.find(
        (user) => user.firstname === rowRecord.firstname
      );

      if (existingUser) {
        toast.error("User with this name already exists!");
        return;
      }

      // Create new user
      try {
        const response = await schoolApi.createUserbyCompanyId(editRecord);
        if (response.success) {
          toast.success("Record saved successfully!");
          recordSaveSuccesfully();
          setTimeout(() => navigate(`/userlist`), 1200);
        } else {
          toast.error(response.message || "An error occurred while saving the record.");
        }
      } catch (error) {
        console.error("Error creating user:", error);
        toast.error("Password must be at least 6 characters long");
      }
    }
  };

  const recordSaveSuccesfully = () => {
    props.recordSaveSuccesfully();
  };

  console.log('rowRecord-->',rowRecord)

  return (
    <Modal
      show={props.show}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      onHide={props.onHide}
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">User Record</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Container className="view-form">
          <Row>
            <Col lg={12}>
              <Form noValidate>
                <Row className="pb-4">
                  <Col lg={12}>
                    <Form.Group className="my-3">
                      <Form.Label htmlFor="formBasicEmployee">Employee</Form.Label>
                      <Form.Control
                        as="select"
                        name="employee"
                        value={selectedEmployee}
                        onChange={handleChange}
                      >
                        <option value="" disabled>Select an employee</option>
                        {body.map((employee) => (
                          <option key={employee.id} value={employee.id}>
                            {employee.firstname} {employee.lastname}
                          </option>
                        ))}
                      </Form.Control>
                    </Form.Group>
                  </Col>
                  <Col lg={6}>
                    <Form.Group className="my-3">
                      <Form.Label htmlFor="formBasicFirstName">First Name</Form.Label>
                      <Form.Control
                        required
                        type="text"
                        name="firstname"
                        placeholder="--First Name--"
                        value={rowRecord.firstname}
                        onChange={handleChange}
                      />
                    </Form.Group>
                  </Col>
                  <Col lg={6}>
                    <Form.Group className="my-3">
                      <Form.Label htmlFor="formBasicLastName">Last Name</Form.Label>
                      <Form.Control
                        required
                        type="text"
                        name="lastname"
                        placeholder="--Last Name--"
                        value={rowRecord.lastname}
                        onChange={handleChange}
                      />
                    </Form.Group>
                  </Col>
                  <Col lg={6}>
                    <Form.Group className="my-3">
                      <Form.Label htmlFor="formBasicEmail">Email</Form.Label>
                      <Form.Control
                        required
                        type="email"
                        name="email"
                        placeholder="--Email--"
                        value={rowRecord.email}
                        onChange={handleChange}
                      />
                    </Form.Group>
                  </Col>
                  {!props?.parent?.id && (
                    <Col lg={6}>
                      <Form.Group className="my-3">
                        <Form.Label htmlFor="formBasicPassword">Password</Form.Label>
                        <Form.Control
                          required
                          type="password"
                          name="password"
                          placeholder="--Password--"
                          value={rowRecord.password}
                          onChange={handleChange}
                        />
                      </Form.Group>
                    </Col>
                  )}
                  <Col lg={6}>
                    <Form.Group className="my-3">
                      <Form.Label htmlFor="formBasicPhone">Phone</Form.Label>
                      <Form.Control
                        required
                        maxLength={10}
                        type="text"
                        name="phone"
                        placeholder="--Phone--"
                        value={rowRecord.phone}
                        onChange={handleChange}
                      />
                    </Form.Group>
                  </Col>
                  <Col lg={6}>
                    <Form.Group className="my-3">
                      <Form.Label htmlFor="formBasicRoleId">Role</Form.Label>
                      <Form.Select
                        required
                        name="role_id"
                        value={rowRecord.role_id} // Use role_id directly from rowRecord
                        onChange={handleChange}
                      >
                        <option value="" disabled>Select a role</option>
                        {roles?.map((role) => (
                          <option key={role.id} value={role.id}>
                            {role.name}
                          </option>
                        ))}
                      </Form.Select>
                    </Form.Group>
                  </Col>
                </Row>
              </Form>
            </Col>
          </Row>
        </Container>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={props.onHide}>Close</Button>
        <Button variant="primary" onClick={handleSubmit}>
          {props.parent?.id ? "Update User" : "Add User"}
        </Button>
      </Modal.Footer>
      <ToastContainer />
    </Modal>
  );
};

export default AddUser;
