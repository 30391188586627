import React, { useEffect, useState } from "react";
import { Button, Col, Form, Row, Table } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import schoolApi from "../../api/schoolApi";
import { ShimmerTable } from "react-shimmer-effects";
import InfoPill from "../InfoPill";
import {
  DatatableWrapper,
  Filter,
  Pagination,
  PaginationOptions,
  TableBody,
  TableHeader,
} from "react-bs-datatable";
import { Link } from "react-router-dom";
import moment from "moment";
import { Helmet } from "react-helmet";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { usePermissions } from "../contexts/PermissionContext";

const StudentAddmissionList = (props) => {
  const { rolePermissions, userInfo } = usePermissions();   
  const navigate = useNavigate();
  const [body, setBody] = useState();
  const [students, setStudents] = useState();
  useEffect(() => {
    async function init() {
      try {
        let student_id = null;
        const result = await schoolApi.fetchStudentAddmission(student_id);
        console.log("addmission result-->", result);
        if (result.success) {
          setBody(result?.studentAddmision);
          setStudents(result?.studentAddmision);
        }
      } catch (error) {
        setBody([]);
        setStudents([]);
        return toast.error(error?.response?.data?.message);
      }
    }
    init();
  }, []);

  const header = [
    {
      title: "Student Name",
      prop: "studentname",
      isFilterable: true,
      cell: (row) => (
        <Link
          to={"/studentaddmissions/" + row.student_info.student_addmission_id}
          state={row}
        >
          {row.student_info.studentname}
        </Link>
      ),
    },
    { title: "Session", prop: "student_info.session", isFilterable: true },
    { title: "Parent", prop: "student_info.parentname", isFilterable: true },
    {
      title: "Class Name",
      prop: "",
      cell:(row)=> `${row.student_info.class_name} [${row.student_info.section_name}]`,
      isFilterable: true,
    },
    { title: "Phone", prop: "student_info.phone", isFilterable: true },
    { title: "Email", prop: "student_info.email", isFilterable: true },
    {
      title: "Date Of Birth",
      prop: "student_info.dateofbirth",
      isFilterable: true,
      cell: (row) => moment(row.student_info.dateofbirth).format("DD-MM-YYYY"), // Format here
    },

  ];

  const labels = {
    beforeSelect: " ",
  };

  const createStudentAddmission = () => {
    navigate(`/student/e`);
  };
  const StudentAddmissionregistration = () => {
    navigate(`/student/registration`);
  };

  return (
    <>
      <Helmet>
        {" "}
        <title>{props?.tabName}</title>{" "}
      </Helmet>
      {/* <Col lg={5} className="mx-4"> */}
      <Col lg={5} className="mx-4" style={{ marginTop: "18px" }}>
        <Link
          className="nav-link"
          to="/"
          style={{ marginLeft: "1px", marginRight: "1px" }}
        >
          {" "}
          {/* Ui Chnages By Aamir khan 26-09-2024 Start */}
          Home <i className="fa-solid fa-chevron-right"></i> StudentsAddmission
        </Link>
      </Col>
      <Row className="g-0">
        <Col lg={12} className="px-lg-4">
          {body ? (
            <DatatableWrapper
              body={body}
              headers={header}
              paginationOptionsProps={{
                initialState: {
                  rowsPerPage: 15,
                  options: [5, 10, 15, 20],
                },
              }}
            >
              <Row className="mb-4">
                <Col
                  xs={12}
                  lg={2}
                  className="d-flex flex-col justify-content-end align-items-end"
                >
                  <Filter />
                </Col>
                <Col
                  xs={12}
                  sm={5}
                  lg={6}
                  className="d-flex flex-col justify-content-start align-items-start"
                >
                  <PaginationOptions labels={labels} />
                  <div style={{ marginTop: "5px" }}>
                    <InfoPill left="Total Students" right={body?.length} />
                  </div>
                </Col>
                {/* <Col
                  xs={12}
                  sm={6}
                  lg={4}
                  className="mb-2 d-flex flex-col justify-content-end align-items-end"
                >
                  <Button
                    className="btn add-new-button"
                    variant=""
                    onClick={() => createStudentAddmission(true)}
                  >
                    New Admission
                  </Button>
                </Col> */}
                {
                  (userInfo?.userrole === "SYS_ADMIN" || userInfo?.userrole === "ADMIN" ) && 
                  <Col xs={12} sm={6} lg={4} className="mb-2 d-flex flex-col justify-content-end align-items-end">
                    <Button className="btn add-new-button" variant="" onClick={() => StudentAddmissionregistration(true)}>
                      New Admission
                    </Button>
                  </Col>
                }
              </Row>
              <Table striped className="data-table">
                <TableHeader />
                <TableBody />
              </Table>
              <Pagination />
            </DatatableWrapper>
          ) : (
            <ShimmerTable row={10} col={8} />
          )}
        </Col>
        <Col lg={2}></Col>
      </Row>
    </>
  );
};

export default StudentAddmissionList;
