import React, { useState, useEffect } from "react";
import { Table } from "react-bootstrap";
import { useLocation } from "react-router-dom";
import schoolApi from "../../../api/schoolApi";
import { ShimmerTable } from "react-shimmer-effects";
import "react-toastify/dist/ReactToastify.css";
import {DatatableWrapper, Pagination, TableBody, TableHeader, } from "react-bs-datatable";
import { Link } from "react-router-dom";
import moment from "moment";

const SupplierPurchaseDetail = (props) => {

  const location = useLocation();
  const [supplier, setSupplier] = useState(location.state ? location.state : {});
  const [body, setBody] = useState([])

  const header = [
    { title: "S.No.", prop: "serial", isFilterable: true },
    { title: "Book", prop: "book_title", isFilterable: true, cell: (row) => (
        <Link to={"/books/" + row.book_id} state={row}>
          {row.book_title}
        </Link>
      ), },
    { title: "quantity", prop: "quantity", isFilterable: true },
    {title: "Date", prop: "date", isFilterable: true, cell: (row) => moment(row.date).format("DD-MMM-YYYY"),},    
  ];
  
  const fetchsupplier = () => {
    if (location.hasOwnProperty("pathname")) {
      supplier.id = location.pathname.split("/")[2];
    }
    
    async function initBook() {
      let result = await schoolApi.getPurchasesRecordsBysupplierId(supplier?.id);
        // Update by mohd. sarfraj | 16-sept-2024.
        if(result?.success){
          setSupplier(result?.purchase);
          setBody(result?.purchase)
        } else {
          setBody([])
          setSupplier([]);
        }
    }
    initBook();
  };
 
  useEffect(() => {
    fetchsupplier();
  }, []);
  
  return (
    <div>
      <DatatableWrapper body={body} headers={header}>
          <Table striped className="data-table custom-table-subject-list">
            <TableHeader />
            {body.length > 0 ?  <TableBody /> : <TableBody><tr><td colSpan={4} className="text-center">No Records Found!!!</td></tr></TableBody>}
          </Table>
          <Pagination />
      </DatatableWrapper>
    </div>      
  );
};
export default SupplierPurchaseDetail;