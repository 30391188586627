import React, { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import jwt_decode from "jwt-decode";


const PdfHeader = () => {
    const [userInfo, setUserInfo] = useState({});

    useEffect(() => {
        try {
            if (sessionStorage.getItem('token')) {
                let user = jwt_decode(sessionStorage.getItem('token'));
                setUserInfo(user);
            }
        } catch (error) {
            console.log(error)
        }
    }, []);

    const currentDate = new Date().toLocaleDateString('en-GB', {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric'
    });

    return (
        <div className="pdf-header">
            {userInfo && (
                <table style={{ width: '100%' }}>
                    <tbody>
                        <tr>
                            <th colSpan="4" className="pdf-header-cell">
                                <Row className="align-items-center">
                                    <Col lg={4} className='d-flex justify-content-center align-items-center'>
                                        <img src='/comonLogoSchool.png' className="school-logo" alt="Company Logo" style={{ height: "120px", width: "120px" }} />
                                    </Col>
                                    <Col lg={4} className="text-center">
                                        <h2 className="school-name" style={{ fontSize: "30px" }}> {userInfo.companyname}</h2>
                                        <p className="school-address" style={{ fontSize: "15px" }}>
                                            {userInfo.companystreet},<br />{userInfo.companystate}-{userInfo.companypincode}, {userInfo.companycountry}
                                        </p>
                                    </Col>
                                    <Col lg={4} className='d-flex justify-content-center align-items-center'>
                                        Date - {currentDate}
                                    </Col>
                                </Row>
                            </th>
                        </tr>
                    </tbody>
                </table>
            )}
        </div>
    );
};

export default PdfHeader;
