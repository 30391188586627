/**
 * @author      Abdul Pathan
 * @date        Aug, 2024
 * @copyright   www.ibirdsservices.com
 */

import React, { useEffect, useState } from "react";
import { Button, Col, Container, Row, Table } from "react-bootstrap";

import schoolApi from "../../../api/schoolApi";

import {
  DatatableWrapper,
  Filter,
  Pagination,
  PaginationOptions,
  TableBody,
  TableHeader,
} from "react-bs-datatable";
import moment from "moment-timezone";
import { Link } from "react-router-dom";
import CampaignEdit from "./CampaignEdit";
import { ToastContainer, toast } from "react-toastify"; // npm i react-toastify --force
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";
import fileDownload from "js-file-download";
import { Helmet } from "react-helmet";

const Campaign = (props) => {
  const navigate = useNavigate();
  const [body, setBody] = useState([]);
  const [isSpinner, setIsSpinner] = useState(false);
  const [modalShowHide, setModalShowHide] = useState(false);
  const [rowData, setRowData] = useState();

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const result = await schoolApi.getCampaignData();

      if (result.success) {
        const formattedResult = result.records.map((record) => {
          const formattedStartDate = moment(record.start_date).format(
            "M/D/YYYY, h:mm:ss A"
          );
          return { ...record, start_date: formattedStartDate };
        });

        setBody(formattedResult);
      } else {
        setBody([]);
      }
    } catch (error) {
      setBody([]);
      console.error("Error fetching campaign data:", error);
    } finally {
      setIsSpinner(true);
    }
  };

  // Create table headers consisting of 4 columns.
  const labels = { beforeSelect: " " };
  const header = [
    {
      title: "Name",
      prop: "campaign_name",
      isFilterable: true,
      cell: (row) => (
        <Link to={"/campaign/view/" + row.campaign_id} state={row}>
          {row.campaign_name}
        </Link>
      ),
    },
    { title: "Type", prop: "campaign_type", isFilterable: true },
    { title: "Template Name", prop: "template_name", isFilterable: true },
    { title: "Start Date", prop: "start_date" },
    {
      title: "Status",
      prop: "campaign_status",
      isFilterable: true,
      cell: (row) => {
        return (
          <>
            {row.campaign_status === "Completed" ? (
              <Button
                className="btn-sm"
                variant=""
                style={{
                  borderRadius: "15px",
                  backgroundColor: "rgb(199, 216, 241)",
                  cursor: "default",
                }}
              >
                {row.campaign_status}
              </Button>
            ) : row.campaign_status === "Pending" ? (
              <Button
                className="btn-sm"
                variant=""
                style={{
                  borderRadius: "15px",
                  backgroundColor: "rgb(241 218 177)",
                  cursor: "default",
                }}
              >
                {row.campaign_status}
              </Button>
            ) : row.campaign_status === "Aborted" ? (
              <Button
                className="btn-sm"
                variant=""
                style={{
                  borderRadius: "15px",
                  backgroundColor: "rgb(248 195 200)",
                  cursor: "default",
                }}
              >
                {row.campaign_status}
              </Button>
            ) : row.campaign_status === "In Progress" ? (
              <Button
                className="btn-sm"
                variant=""
                style={{
                  borderRadius: "15px",
                  backgroundColor: "rgb(208 187 244)",
                  cursor: "default",
                }}
              >
                {row.campaign_status}
              </Button>
            ) : null}
          </>
        );
      },
    },
    {
      title: "Actions",
      prop: "id",
      cell: (row) => (
        <>
          {row.campaign_status !== "Completed" &&
            row.campaign_status !== "Aborted" &&
            row.campaign_status !== "In Progress" && (
              <Button
                className="btn-sm mx-2 "
                onClick={() => editRecord({ row })}
              >
                <i class="fa-solid fa-pen-to-square" title="Edit"></i>
              </Button>
            )}
          {/* <Button className='btn-sm mx-2' variant='danger' onClick={() => downloadFile(row)}><i class="fa-solid fa-download"></i></Button> */}
          <Button
            className="btn-sm"
            variant=""
            onClick={() => downloadFile(row)}
          >
            <i
              className="fa-solid fa-cloud-arrow-down"
              title="download file"
              style={{ fontSize: "20px", color: "rgb(64 119 237)" }}
            ></i>
          </Button>
        </>
      ),
    },
  ];

  const createCampaignRecord = () => {
    navigate("/campaign/add");
  };

  const editRecord = (data) => {
    setModalShowHide(true);
    setRowData(data?.row);
  };

  const onRefreshData = () => {
    toast.success("Record updated successfully.");
    fetchData();
    setModalShowHide(false);
  };

  const downloadFile = async (row) => {
    try {
      console.log("row ->", row);

      if (!row?.file_title) {
        toast.error("File name required.");
        return;
      }

      const result = await schoolApi.downloadFiles(row?.file_id);

      console.log("download file", result);

      if (result) {
        fileDownload(result, row?.file_title);

        toast.success("File download successfully.");
      } else {
        toast.warning("File not found.");
      }
    } catch (error) {
      console.error("Error downloading file:", error);
      if (error.message === "Unauthorized access. Please login.") {
        toast.error("Unauthorized access. Please login.");
      } else {
        toast.error("Failed to download file. Please try again.");
      }
    }
  };

  return (
    <>
      <Helmet>
        {" "}
        <title>{props?.tabName}</title>{" "}
      </Helmet>
      <Row className="g-0">
        <Col lg={2} className="mx-3">
          <Link className="nav-link mx-2" to="/">
            Home <i className="fa-solid fa-chevron-right"></i> Campaign
          </Link>
        </Col>
      </Row>
      {isSpinner ? (
        <Container className="mb-5">
          <Row className="mx-3 g-0">
            <Col lg={12} sm={12} xs={12} className="mb-3">
              <Row className="g-0">
                <Col lg={12} sm={12} xs={12}>
                  <DatatableWrapper
                    body={body}
                    headers={header}
                    paginationOptionsProps={{
                      initialState: {
                        rowsPerPage: 10,
                        options: [5, 10, 15, 20],
                      },
                    }}
                  >
                    <Row className="mb-2">
                      <Col
                        lg={4}
                        sm={10}
                        xs={10}
                        className="d-flex flex-col justify-content-end align-items-end"
                      >
                        <Filter />
                      </Col>
                      <Col
                        lg={4}
                        sm={2}
                        xs={2}
                        className="d-flex flex-col justify-content-start align-items-start"
                      >
                        <PaginationOptions labels={labels} />
                      </Col>

                      <Col
                        lg={4}
                        sm={12}
                        xs={12}
                        className="mt-2 d-flex flex-col justify-content-end align-items-end"
                      >
                        <Button
                          className="btn-light add-new-button"
                          style={{ color: "black" }}
                          variant=""
                          onClick={() => createCampaignRecord()}
                        >
                          Add New Campaign
                        </Button>
                      </Col>
                    </Row>
                    <Table striped className="data-table" responsive="sm">
                      <TableHeader />
                      <TableBody />
                    </Table>
                    <Pagination />
                  </DatatableWrapper>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      ) : (
        <div className="sk-cube-grid">
          <div className="sk-cube sk-cube1"></div>
          <div className="sk-cube sk-cube2"></div>
          <div className="sk-cube sk-cube3"></div>
          <div className="sk-cube sk-cube4"></div>
          <div className="sk-cube sk-cube5"></div>
          <div className="sk-cube sk-cube6"></div>
          <div className="sk-cube sk-cube7"></div>
          <div className="sk-cube sk-cube8"></div>
          <div className="sk-cube sk-cube9"></div>
        </div>
      )}

      {modalShowHide && (
        <CampaignEdit
          show={modalShowHide}
          onHide={() => {
            setModalShowHide(false);
          }}
          onRefreshData={onRefreshData}
          rowData={rowData}
          table="campaign"
        />
      )}

      <ToastContainer />
    </>
  );
};

export default Campaign;
