/**
 * @author: Shahir Hussain
 */
import React, { useState, useEffect } from "react";
import { Button, Col, Container, Row } from "react-bootstrap";

import { useLocation, useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import schoolApi from "../../api/schoolApi";

import { Helmet } from "react-helmet";
import PageNavigations from "../breadcrumbs/PageNavigations";
import AddSection from "./AddSection";
import Confirm from "../Confirm";
import { usePermissions } from "../contexts/PermissionContext";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const SectionView = (props) => {
  console.log("SectionView", props);
  const location = useLocation();
  console.log("location=========>", location);
  const { rolePermissions, userInfo } = usePermissions();
  console.log("rolePermissions class list=======>", rolePermissions);
  const navigate = useNavigate();
  const [classPath, setClassPath] = useState(
    location.state ? location.state.classpath : {}
  );
  const [section, setSection] = useState(location.state ? location.state : {});
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showSectionModal, setShowSectionModal] = useState(false);
  const [rowDataId, setRowDataId] = useState();
  const canCreate = rolePermissions?.Section_List?.can_create;
  const canEdit = rolePermissions?.Section_List?.can_edit;
  const canDelete = rolePermissions?.Section_List?.can_delete;

  useEffect(() => {
    if (section.section_id) {
      sectionRecords();
    }
  }, []);

  const sectionRecords = async () => {
    console.log("section records =", section.section_id);
    try {
      const result = await schoolApi.fetchRecordById(section.section_id);
      console.log("sec res data == ", result);

      // if (result && Array.isArray(result)) {
        if (result.success) {
        console.log("yes")
        const filteredResult = result.result.filter(
          (record) => record.section_id === section.section_id
        );
        console.log("filteredResult == ",filteredResult)
        if (filteredResult.length > 0) {
          setSection(filteredResult[0]);
        } else {
          setSection({});
        }
        console.log("Filtered Result:", filteredResult);
      } else {
        setSection({});
      }
    }
    catch (error) {
      console.error("Error fetching records:", error);
      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        toast.error(error.response.data.message);
      } else {
        toast.error("Unknown error occurred. Please try again.", {
          style: { backgroundColor: "#f7040f", color: "white" },
        });
      }
      setSection({});
    }
  };

  const handleCancel = async (e) => {
    console.log("classPath == ", classPath);
    if (classPath) navigate(classPath);
    else navigate("/section");
  };

  const editSection = () => {
    setShowSectionModal(true);
  };

  const handleDeleteButton = () => {
    setShowDeleteModal(true);
    setRowDataId(section.section_id);
  };

  const handleDeleteModuleRecord = async () => {
    console.log("setRowDataId=======>", rowDataId);
    if (rowDataId) {
      // updated code added by muskan khan 10 sept 2024
      try {
        const result = await schoolApi.deleteSection(rowDataId);
        console.log("RESULT... wdjsh",result)
        if (result.success === true && result.message === "Section deleted successfully") {
          setShowDeleteModal(false);
          toast.error("Record Deletd Succefully");
          setTimeout(() => {
            navigate("/section");
          }, 2000);
        } else {
          console.error("deletion was not successfull", result);
        }
      } catch (error) {
        if (
          error.response &&
          error.response.data &&
          error.response.data.errors
        ) {
          toast.error(error.response.data.errors);
        } else {
          toast.error("Unknown error occurred. Please try again.", {
            style: { backgroundColor: "#f7040f", color: "white" },
          });
        }
      }
    }
  };

  return (
    <>
    <ToastContainer
      position="top-right"
      autoClose={2000}
      hideProgressBar={false}
      newestOnTop={false}
      closeOnClick
      rtl={false}
      pauseOnFocusLoss
      draggable
      pauseOnHover
      theme="light"
      />
      {showSectionModal && (
        <AddSection
          show={showSectionModal}
          parent={section}
          onHide={() => setShowSectionModal(false)}
          sectionRecords={sectionRecords}
        />
      )}

      {showDeleteModal && (
        <Confirm
          show={showDeleteModal}
          onHide={() => setShowDeleteModal(false)}
          handleDelete={() => handleDeleteModuleRecord()}
          title="Confirm delete?"
          message="You are going to delete the record. Are you sure?"
          table="deleteSection"
        />
      )}
      <Helmet>
        {" "}
        <title>{props?.tabName}</title>{" "}
      </Helmet>
      <div>
        {section && (
          <Container>
            <PageNavigations
              listName={classPath ? "Class List" : "Section List"}
              listPath={classPath ? classPath : "/section"}
              viewName=""
              viewPath=""
              colLg={2}
              colClassName="d-flex mx-3 mb-3"
              extrColumn={12}
            />

            <Row className="view-form">
              <Col lg={12}>
                <Col className="mx-3">
                  <Col className="section-header my-3">
                    <span style={{ color: "black" }}>Section Information</span>
                  </Col>
                </Col>
                <Row className="view-form-header align-items-center mx-3">
                  <Col lg={10}>
                    <h5>
                      {section?.class_name} {section?.section_name}
                    </h5>
                  </Col>
                  <Col lg={2}>
                    {(userInfo?.userrole === "SYS_ADMIN" ||
                      userInfo?.userrole === "ADMIN" ||
                      canEdit) && (
                      <Button
                        className="btn-sm mx-2 edit-button"
                        onClick={() => editSection()}
                      >
                        <i className="fa-regular fa-pen-to-square"></i>
                      </Button>
                    )}

                    {(userInfo?.userrole === "SYS_ADMIN" ||
                      userInfo?.userrole === "ADMIN" ||
                      canDelete) && (
                      <Button
                        className="btn-sm"
                        variant="danger"
                        onClick={() => handleDeleteButton()}
                      >
                        Delete
                      </Button>
                    )}
                    <Button
                      className="btn-sm mx-2"
                      variant="danger"
                      onClick={handleCancel}
                    >
                      Cancel
                    </Button>
                  </Col>
                </Row>
                <Row className="mx-2 my-2">
                  <Col lg={6} className="my-2">
                    <label>Class Name</label>
                    <span>
                      {section?.class_name}
                      <br />
                    </span>
                  </Col>
                  <Col lg={6} className="my-2">
                    <label>Section Name</label>
                    <span>
                      {section?.section_name}
                      <br />
                    </span>
                  </Col>
                  <Col lg={6} className="my-2">
                    <label>Class Teacher</label>
                    <span>
                      {section?.contact_name}
                      <br />
                    </span>
                  </Col>
                  <Col lg={6} className="my-2">
                    <label>Is Active</label>
                    <span>
                      {section.isactive === true ? "true" : "false"}
                      <br />
                    </span>
                  </Col>
                  <Col lg={6} className="my-2">
                    <label>strength</label>
                    <span>
                      {section.strength}
                      <br />
                    </span>
                  </Col>
                </Row>
                <Row></Row>
              </Col>

              <Col></Col>
            </Row>
          </Container>
        )}
      </div>
    </>
  );
};
export default SectionView;
