/**
 * @author: Sarfraj Khan | 22-aug-2024
 */
import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import { DatatableWrapper, Filter, Pagination, PaginationOptions, TableBody, TableHeader,} from "react-bs-datatable";
import { Col, Row, Form, Table, Button } from "react-bootstrap";
import schoolApi from "../../api/schoolApi";
import InfoPill from "../InfoPill";
import { ToastContainer, toast } from "react-toastify";

const Promotion = ({ props }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [allClasses, setAllClasses] = useState([]);
  const [students, setStudents] = useState([]);
  const [classSection, setClassSection] = useState([]);
  const [sessions, setSessions] = useState([]);
  const [feeTypeOptions, setFeeTypeOptions] = useState([]);
  const [promoteSection, setPromotionSection] = useState([]);
  const [isNexButton, setIsNextBtton] = useState(true);
  const [promotionData, setPromotionData] = useState({
    current_class: location.state.class_id,
  });
  const [globalSessionId, setGlobalSessionId] = useState();
  const [feeStructure, setFeeStructure] = useState([]);
  const [sessionDates, setSessionDates] = useState({});
  const [showUpComingFields, setShowUpComingFields] = useState(false);   // added by sarfraj | 17-sept-2024.
  const [sectionByInlineClassId, setSectionByInlineClassId] = useState([]); // Added by sarfraj 18-sept-2024

  useEffect(() => {
    fetchSessions();
    if (allClasses.length === 0) {
      schoolApi.getActiveClassRecords().then((result) => {
          console.log("sarfraj track here classRecords", result);
          if (result.success) {
            setAllClasses(result.result);
          }
        })
        .catch((err) => {
          console.error(err);
        });
    }
    fetchSectionByClassId(location?.state?.class_id);
  }, []);

  useEffect(() => {
    setStudents([]); // Added By mohd. sarfraj | 14-sept.2024.  {when ever current class changed then student state should be empty. }
    setGlobalSessionId(""); // Added By mohd. sarfraj | 14-sept.2024.  {when ever current class changed then globalSessionId should be empty. }
    fetchSectionByClassId(promotionData.current_class); // fatch section according class id
  },[promotionData.current_class]);
  // }, [promotionData.current_class, promotionData.current_section, promotionData.current_session,]);

  /*-------- Added By mohd. sarfraj | 14-sept-2024. ---------*/
  const handleStudentRecordsGet = () => {
    if (promotionData.current_class && promotionData.current_section && promotionData.current_session) {
      schoolApi
        .getAllStudentByClassidFeeData({
          classid: promotionData.current_class,
          session_id: promotionData.current_session,
          section_id: promotionData.current_section,
        })
        .then((res) => {
          if (res.success) {
            console.log("studentAddmission-res-->>", res);
            setStudents(
              res?.result?.map((obj) => ({
                ...obj,
                isPromote: true,
              }))
            );
            setShowUpComingFields(true);  // added by sarfraj | 17-sept-2024.
          } else {
            setStudents([]);
            return toast.success("No Records Found");
          }
        })
        .catch((err) => {
          console.error("promotion error-->", err);
          setStudents([]);
          return toast.success('This class records not exist!',{icon: false});
        });
    } else {
      toast.error("Fill all required feild.");
    }
  }

  //--------- get class-All-section By class_id ----- | added by mohd. sarfraj -- 21-aug-2024
  const fetchSectionByClassId = async (id) => {
    try {
      const result = await schoolApi.getSectionRecordById(id);
      if (result.success) setClassSection(result.record);
    } catch (error) {
      console.log("error--during-get-classSection-byId--> ", error);
    }
  };

  const fetchSessions = async () => {
    const res = await schoolApi.getAllSession();
    const date = new Date();
    let currentyear = date.getFullYear();
    const sessions = [];
    for (let i = 0; i < res.length; i++) {
      if (
        currentyear == res[i].startdate.substring(0, 4) ||
        currentyear + 1 == res[i].startdate.substring(0, 4) ||
        currentyear - 1 == res[i].startdate.substring(0, 4)
      ) {
        sessions.push(res[i]);
      }
    }
    setSessions(sessions);
  };

  const handleChange1 = async (e) => {
    console.log("e.target.name-->", e.target.name);
    const name = e.target.name;
    // Added by mohd. sarfraj | 14-sept-2024.
    if(e.target.name === "current_class"){
      promotionData.current_section = "";
      // promotionData.current_session = "";
    }
    setPromotionData({ ...promotionData, [name]: e.target.value });
  };
  console.log("promoteddata-->", promotionData);

  /*------ Added by mohd. sarfraj | 12-sept-2024 ------*/
  const handleRadioChangeUpcomingSession = (row, promoteStatus) => {
    console.log("row==>>", row);
    console.log("promoteStatus==>>", promoteStatus);
    const updatedStudents = students.map((student) =>
      student?.student_admission_id === row?.student_admission_id
        ? { ...student, isPromote: promoteStatus }
        : student
    );
    setStudents(updatedStudents);
  };

  /*------ Added by mohd. sarfraj | 12-sept-2024 ------*/
  const handleRadioChangeResult = async (newResult, row) => {
    try {
      console.log("row==>>", row);
      // console.log('location?.state?.class_id==>>', location?.state?.class_id);
      console.log('newResult==>>', newResult);
      let updatedStudents = null;
      if (newResult === "fail") {
        const specific_class_section = await schoolApi.getSectionRecordById(promotionData?.current_class);
        console.log("Failed-student-section-->>", specific_class_section);

        let feeMasterResult = await schoolApi.fetchPromotionFeeMasterByIdOrClassid(promotionData?.current_class, globalSessionId);
        console.log("feeMasterResult", feeMasterResult);

        // if(!feeMasterResult.success){
        //   return toast.error('Create fee master record for selected class');
        // }

        const finalData = await schoolApi.getFinalDataByClassAndSesionId(promotionData?.current_class, globalSessionId);
        console.log("finalData-->>>", finalData);

        if (finalData?.record[0]?.feeStructure !== null) {
          //-----------------
          const promotedSessionRecord = sessions.find(
            (session) => session.id === globalSessionId
          );
          const startdate = promotedSessionRecord?.startdate;
          const enddate = promotedSessionRecord?.enddate;

          const startDate = new Date(startdate);
          const endDate = new Date(enddate);

          if (startDate && endDate) {
            generateInstallmentDates(startDate, endDate);
          }

          let pendingDueResult = [];
          const ResultSettings = await schoolApi.getSettingByKey("pending_due_day");
          if (ResultSettings.success) {
            pendingDueResult.push(ResultSettings.data[0]);
          } else {
            pendingDueResult([]);
          }

          const classWiseStructure = finalData?.record[0]?.fee_structure;
          let upcomingInstallments = [];

          classWiseStructure?.map((res) => {
            let typeWiseInstallments = [];
            res.installments.map((item) => {
              let splittedMonth = item?.month?.split(" ");
              let lastDate = new Date(sessionDates[splittedMonth[0]]);
              if (pendingDueResult[0].value) {
                lastDate.setDate(pendingDueResult[0].value);
              }
              let installmentDate = lastDate.toISOString().slice(0, 10);

              typeWiseInstallments.push({
                fee_master_installment_id: item.installment_id,
                month: item.month,
                general_fee: item.general_fee,
                obc_fee: item.obc_fee,
                sc_fee: item.sc_fee,
                st_fee: item.st_fee,
                due_date: installmentDate,
                line_items: item.line_items,
              });
            });

            console.log("typeWiseInstallments-->", typeWiseInstallments);
            upcomingInstallments.push({
              type: res.type,
              value: typeWiseInstallments,
            });
          });
          feeStructure.push({
            key: promotionData?.current_class,
            value: upcomingInstallments,
          });
        } else {
          return toast.error("First Create Fee Head-Masters..!!");
        }

        //start--------------code pawan -----------------------
        const newSt = await Promise.all(
          students.map(async (prevSt) => {
            console.log("prevSt-->>", prevSt);
            console.log(' prevSt.student_admission_id-->>>' ,  prevSt.student_admission_id);
            console.log(' row.student_admission_id' ,  row.student_admission_id);
            
            // const selectedSt = prevSt.find((st) => st.student_admission_id === row.student_admission_id);
            const selectedSt = prevSt.student_admission_id === row.student_admission_id && prevSt;
            console.log('selectedSt-->', selectedSt);

            if (selectedSt) {
              const feeMatch = feeMasterResult.result?.find((obj) => obj?.type === selectedSt?.fee_type);
              console.log("feeMatch-->>", feeMatch);

              if (!feeMatch) {
                // toast.error("Fee type doesn't match");
                toast.error("First Create Fee master of next session for current class!");
                return prevSt; // Return previous state if no match is found
              }

              // Determine the fee category and amount fields based on the student's category
              let feeCategory = "";
              let categoryAmount = "";
              switch (selectedSt?.category) {
                case "General":
                  feeCategory = "general_fee";
                  categoryAmount = "general_amount";
                  break;
                case "Obc":
                  feeCategory = "obc_fee";
                  categoryAmount = "obc_amount";
                  break;
                case "Sc":
                  feeCategory = "sc_fee";
                  categoryAmount = "sc_amount";
                  break;
                case "St":
                  feeCategory = "st_fee";
                  categoryAmount = "st_amount";
                  break;
                default:
                  feeCategory = "general_fee";
                  categoryAmount = "general_amount";
                  break;
              }

              // Fetch discount for the student
              const discount = await getdiscount(selectedSt.student_admission_id);
              console.log("discount-->>", discount);

              const studentInstallments = feeStructure.find((str) => {
                  return str.key === promotionData?.current_class;
              })?.value?.find((res) => res.type === selectedSt.fee_type)?.value || [];

              // Map the installments to apply the discount
              const new_installments = studentInstallments.map(
                (installment, i) => {
                  let discountedAmount = 0;

                  discount?.forEach((dis) => {
                    const discountFeeHeadId = dis?.fee_head_id;
                    const discountPercent = dis?.percent;
                    const discountAmount = dis?.amount;
                    let lineItemAmountByCategory = 0;

                    if (discountFeeHeadId) {
                      discountFeeHeadId.forEach((feeHeadId) => {
                        const matchedItem = installment?.line_items?.find((li) => li.fee_head_master_id === feeHeadId);
                        lineItemAmountByCategory += matchedItem ? parseInt(matchedItem[categoryAmount]) : 0;
                      });
                    }

                    if (discountAmount > 0) {
                      discountedAmount += parseInt(discountAmount);
                    } else {
                      discountedAmount += Math.round((parseInt(lineItemAmountByCategory) * parseInt(discountPercent)) / 100);
                    }
                  });

                  return {
                    orderno: i + 1,
                    amount: installment[feeCategory],
                    status: "pending",
                    due_date: installment.due_date,
                    month: installment.month,
                    fee_master_installment_id:installment.fee_master_installment_id,
                    session_id: globalSessionId,
                    installment_discount: discountedAmount,
                  };
                }
              );
              console.log("new_installments-->>", new_installments);

              return (prevSt.student_admission_id === row?.student_admission_id && row?.result === "fail")
              ? {
                  ...prevSt,
                  class_id: row?.class_id ? row?.class_id : promotionData?.current_class,
                  classname: row?.class_id ? row?.classname : allClasses?.find((classItem) => classItem?.id === promotionData?.current_class)?.class_name,
                  section_id: row?.section_id ? row?.section_id : location.state.section.find((section) => promotionData?.current_section === section?.section_id)?.section_id,
                  section_name: row?.section_id ? row?.section_name : location.state.section.find((section) => promotionData?.current_section === section?.section_id)?.section_name,
                  fee_id: feeMatch?.id,
                  fee_type: feeMatch?.type,
                  result: newResult,
                  installments: new_installments,
                  updated_row_section: specific_class_section?.record, // this key changes the section drop-down according to class_id.(in-line)
                }
              : prevSt;
          }
            return prevSt;
          })
        );
        console.log("newSt-->>", newSt);
        setStudents(newSt);
        updatedStudents = newSt;
        //end--------------code pawan -----------------------
      } 
      /*--- Commented by sarfraj | 02-oct-2024 ----*/
      // else {
      //   console.log("student ->",students);
      //   updatedStudents = students.map((student) =>
      //     student?.student_admission_id === row?.student_admission_id
      //       ? { ...student, result: newResult, classname:'', class_id:'', classid:'', section_id:'', section_name : ''}
      //       : student
      //   );
      // }
      console.log('updatedStudents----->>>>>' , updatedStudents);
      return updatedStudents;
    } catch (error) {
      console.log('error-sfff->',error);
      return toast.error(error.response.data.message);
    }
  };
  
  //------ added by mohd. sarfraj --------
  const handleSection = async (e, student_admission_id) => {
    console.log("sarfraj track in handle-Section-->>", e.target.value, student_admission_id);
    let updatedStudents = null;

    if (e.target.name === "prmotion_section") {
      updatedStudents = students.map((prev) => 
        prev?.result === "pass" ?
        {
          ...prev,
          section_id: e.target.value,
          section_name: promoteSection?.find((section) => e.target.value === section.id )?.name
                          || classSection?.find((section) => e.target.value === section.id)?.name, 
          updated_row_section: null,
        } : prev
      );
    } else {
      updatedStudents = students.map((prev) =>
        prev?.student_admission_id === student_admission_id
          ? {
              ...prev,
              section_id: e.target.value, 
              section_name : promoteSection?.find((section) => e.target.value === section.id )?.name 
                            ||  (sectionByInlineClassId?.find((section) => e.target.value === section.id)?.name || classSection?.find((section) => e.target.value === section.id)?.name)
            }
          : prev
      );
    }
    setStudents(updatedStudents);
  };

  const handleSession = (e) => {
    setGlobalSessionId(e.target.value);
    const updatesStudetnWithSessionId = students.map((prev) => ({
      ...prev,
      sessionid: e.target.value,
      isSessionId: true,
    }));
    console.log("sarfraj track here updatesStudetnWithSessionId-->>",updatesStudetnWithSessionId);
    setStudents(updatesStudetnWithSessionId);
  };

  //-------- added by mohd. sarfraj ----------
  const handleClass = async (obj, student_admission_id, target_name) => {
    try {
      
    if(!globalSessionId){
      return toast.error("Fill all required feild.");
    }
    console.log("student_admission_id-sf->>", student_admission_id, obj);

    const specific_class_section = await schoolApi.getSectionRecordById(obj?.id);
    setSectionByInlineClassId(specific_class_section?.record); // Added 18-sept-2024.
    console.log("ssarfraj track here pecific_class_section", specific_class_section);

    let feeMasterResult = await schoolApi.fetchPromotionFeeMasterByIdOrClassid(obj?.id, globalSessionId); // updated by mohd. sarfraj | 09-Aug-2024.
    console.log("feeMasterResult", feeMasterResult);

    const finalData = await schoolApi.getFinalDataByClassAndSesionId(obj?.id, globalSessionId);
    console.log("finalData-->>>", finalData);

    let updatedStudents = null;
    if (target_name === "each_class_id") {
      if (finalData?.record[0]?.feeStructure !== null) {
        //-----------------
        const promotedSessionRecord = sessions.find(
          (session) => session.id === globalSessionId
        );
        const startdate = promotedSessionRecord?.startdate;
        const enddate = promotedSessionRecord?.enddate;

        const startDate = new Date(startdate);
        const endDate = new Date(enddate);

        if (startDate && endDate) {
          generateInstallmentDates(startDate, endDate);
        }

        let pendingDueResult = [];
        const ResultSettings = await schoolApi.getSettingByKey(
          "pending_due_day"
        );
        if (ResultSettings.success) {
          pendingDueResult.push(ResultSettings.data[0]);
        } else {
          pendingDueResult([]);
        }

        const classWiseStructure = finalData?.record[0]?.fee_structure;
        let upcomingInstallments = [];

        classWiseStructure?.map((res) => {
          let typeWiseInstallments = [];
          res.installments.map((item) => {
            let splittedMonth = item?.month?.split(" ");
            let lastDate = new Date(sessionDates[splittedMonth[0]]);
            if (pendingDueResult[0].value) {
              lastDate.setDate(pendingDueResult[0].value);
            }
            let installmentDate = lastDate.toISOString().slice(0, 10);

            typeWiseInstallments.push({
              fee_master_installment_id: item.installment_id,
              month: item.month,
              general_fee: item.general_fee,
              obc_fee: item.obc_fee,
              sc_fee: item.sc_fee,
              st_fee: item.st_fee,
              due_date: installmentDate,
              line_items: item.line_items,
            });
          });

          console.log("typeWiseInstallments-->", typeWiseInstallments);
          upcomingInstallments.push({
            type: res.type,
            value: typeWiseInstallments,
          });
        });
        feeStructure.push({
          key: obj?.id,
          value: upcomingInstallments,
        });
      } else {
        return toast.error("First Create Fee Head-Masters..!!");
      }

      /*------------------------------------------------------------------------------------*/
      updatedStudents = await Promise.all(
        students.map(async (prev) => {
          const feeMatch = feeMasterResult?.result?.find(
            (obj) => obj?.type === prev?.fee_type
          );
          console.log("feeMatch-->>", feeMatch);

          if (!feeMatch) {
            return null;
          }

          let feeCategory = "";
          let categoryAmount = "";
          if (prev?.category === "General") {
            feeCategory = "general_fee";
            categoryAmount = "general_amount";
          } else if (prev?.category === "Obc") {
            feeCategory = "obc_fee";
            categoryAmount = "obc_amount";
          } else if (prev?.category === "Sc") {
            feeCategory = "sc_fee";
            categoryAmount = "sc_amount";
          } else if (prev?.category === "St") {
            feeCategory = "st_fee";
            categoryAmount = "st_amount";
          }

          const discount = await getdiscount(prev.student_admission_id);
          console.log("discount-->>", discount);

          const studentInstallments = feeStructure.find((str) => {
                if (str.key === obj.id) {
                  const matchedInstallment = str?.value?.find(
                    (res) => res.type === prev.fee_type
                  );
                  return matchedInstallment ? true : false;
                }
                return false;
              })
              ?.value?.find((res) => res.type === prev.fee_type)?.value || [];

          let new_installments = studentInstallments.map((installment, i) => {
            let discountedAmount = 0;
            discount?.forEach((dis) => {
              let discountFeeHeadId = dis?.fee_head_id;
              let discountPercent = dis?.percent;
              let discountAmount = dis?.amount;
              let lineItemAmountByCategory = 0;
              console.log("discountFeeHeadId-->>",discountFeeHeadId, "discountPercent-->>", discountPercent, "discountAmount-->>", discountAmount);

              if (discountFeeHeadId) {
                discountFeeHeadId.forEach((feeHeadId) => {
                  console.log("feeHeadId--->>>",feeHeadId,"installment--->>>",installment);

                  const matchedItem = installment?.line_items?.find(
                    (li) => (
                      console.log("li--->>>>", li),
                      li.fee_head_master_id === feeHeadId
                    )
                  );
                  console.log("matchedItem-->>", matchedItem);

                  lineItemAmountByCategory += matchedItem
                    ? parseInt(matchedItem[categoryAmount])
                    : 0;
                });
              }
              console.log(
                "lineItemAmountByCategory-->>",
                lineItemAmountByCategory
              );

              if (discountAmount > 0) {
                discountedAmount += parseInt(discountAmount);
              } else {
                discountedAmount += Math.round(
                  (parseInt(lineItemAmountByCategory) *
                    parseInt(discountPercent)) /
                    100
                );
              }
            });

            console.log("discountedAmount--->>>", discountedAmount);

            return {
              orderno: i + 1,
              amount: installment[feeCategory],
              status: "pending",
              due_date: installment.due_date,
              month: installment.month,
              fee_master_installment_id: installment.fee_master_installment_id,
              session_id: globalSessionId,
              installment_discount: discountedAmount,
            };
          });

          console.log("new_installments==>>>", new_installments);

          return prev?.isPromote === true &&
            prev?.student_admission_id === student_admission_id
            ? {
                ...prev,
                class_id: obj?.id,
                classname: obj?.class_name,
                // section_id: null,
                updated_row_section: specific_class_section?.record, // this key changes the section drop-down according to class_id.(in-line)
                fee_id: feeMatch?.id,
                fee_type: feeMatch?.type,
                // result: "Pass",        // commented by mohd. sarfraj | 13-sept-2024.
                installments: new_installments,
              }
            : prev;
        })
      );
    }

    console.log("sarfraj track here updatedStudents --> ", updatedStudents);
    if (updatedStudents.some(student => student === null)) { // replace .every() method with some by mohd. sarfraj | 14-sept-2024.
        // setIsNextBtton(true);
        setStudents(students); // Set old records as it is.
        return toast.error("Create fee master records first!!!");
      } else {
          setIsNextBtton(false);
          setStudents(updatedStudents);
      }
    } catch (error) {
      return toast.error(error.response.data.message);
    }
  };

  //------------- Funationlity For Comman Class Student Promote --------------
  const handleSetPromoteCls = async (val) => {
    try {
      //---------- get section by selected class id ------------
      const promoteSections = await schoolApi.getSectionRecordById(val?.id);
      console.log("promoteSections", promoteSections);
      if (promoteSections?.success) {
        setPromotionSection(promoteSections?.record);
      }

      console.log('pawan promotion-id->', val?.id)
      console.log('pawan promotion-globalSessionId->', globalSessionId)
      /*-------- fetch fee-Master-Result By class id ---------*/
      let feeMasterResult = await schoolApi.fetchPromotionFeeMasterByIdOrClassid(val?.id, globalSessionId); // true ka kaam , old fee type laane ke liye he.
      console.log("feeMasterResult", feeMasterResult);

      if (feeMasterResult.success) {
        setFeeTypeOptions(feeMasterResult.result);
      }
      else{
        return toast.error("Create fee master record first.");
      }

      const finalData = await schoolApi.getFinalDataByClassAndSesionId(val?.id, globalSessionId);
      console.log("finalData-->", finalData);

      if (finalData?.record[0]?.fee_structure !== null) {
        //-----------------
        const promotedSessionRecord = sessions.find(
          (session) => session.id === globalSessionId
        );
        const startdate = promotedSessionRecord?.startdate;
        const enddate = promotedSessionRecord?.enddate;

        const startDate = new Date(startdate);
        const endDate = new Date(enddate);

        if (startDate && endDate) {
          generateInstallmentDates(startDate, endDate);
        }

        let pendingDueResult = [];
        const ResultSettings = await schoolApi.getSettingByKey(
          "pending_due_day"
        );
        if (ResultSettings.success) {
          pendingDueResult.push(ResultSettings.data[0]);
        } else {
          pendingDueResult([]);
        }

        const classWiseStructure = finalData?.record[0]?.fee_structure;
        let upcomingInstallments = [];

        classWiseStructure?.map((res) => {
          let typeWiseInstallments = [];
          res.installments.map((item) => {
            let splittedMonth = item?.month?.split(" ");
            let lastDate = new Date(sessionDates[splittedMonth[0]]);
            if (pendingDueResult[0].value) {
              lastDate.setDate(pendingDueResult[0].value);
            }
            let installmentDate = lastDate.toISOString().slice(0, 10);

            typeWiseInstallments.push({
              fee_master_installment_id: item.installment_id,
              month: item.month,
              general_fee: item.general_fee,
              obc_fee: item.obc_fee,
              sc_fee: item.sc_fee,
              st_fee: item.st_fee,
              due_date: installmentDate,
              line_items: item.line_items,
            });
          });

          console.log("typeWiseInstallments-->", typeWiseInstallments);
          upcomingInstallments.push({
            type: res.type,
            value: typeWiseInstallments,
          });
        });
        feeStructure.push({
          key: val?.id,
          value: upcomingInstallments,
        });
      }
      // else { // added by mohd. sarfraj | 11-sept-2024
      //   return toast.error("Records Not Found...!!!!");
      // }
      console.log("fee-structure-->>", feeStructure);

      let updatedSelecteStudents = await Promise.all(
        students.map(async (prev) => {
          // if(prev?.result === "pass"){ 
            console.log("prev-->>", prev);
            console.log("feeMasterResult-->>", feeMasterResult);
  
            const feeMatch = feeMasterResult?.result?.find((obj) => obj?.type === prev?.fee_type);
            console.log("feeMatch-->>", feeMatch);
  
            if (!feeMatch) {
              return null;
              // toast.error("Create fee master first!");
            }
  
            let feeCategory = "";
            let categoryAmount = "";
            if (prev?.category === "General") {
              feeCategory = "general_fee";
              categoryAmount = "general_amount";
            } else if (prev?.category === "Obc") {
              feeCategory = "obc_fee";
              categoryAmount = "obc_amount";
            } else if (prev?.category === "Sc") {
              feeCategory = "sc_fee";
              categoryAmount = "sc_amount";
            } else if (prev?.category === "St") {
              feeCategory = "st_fee";
              categoryAmount = "st_amount";
            }
            console.log("categoryAmount-->>", categoryAmount);
  
            const discount = await getdiscount(prev.student_admission_id);
            console.log("discount-->>", discount);
  
            const studentInstallments = feeStructure.find((str) => {
                  if (str.key === val.id) {
                    const matchedInstallment = str?.value?.find((res) => res.type === prev.fee_type);
                    return matchedInstallment ? true : false;
                  }
                  return false;
                })?.value?.find((res) => res.type === prev.fee_type)?.value || [];
  
            let new_installments = studentInstallments.map((installment, i) => {
              let discountedAmount = 0;
              discount?.forEach((dis) => {
                let discountFeeHeadId = dis?.fee_head_id;
                let discountPercent = dis?.percent;
                let discountAmount = dis?.amount;
                let lineItemAmountByCategory = 0;
                console.log(
                  "discountFeeHeadId-->>",
                  discountFeeHeadId,
                  "discountPercent-->>",
                  discountPercent,
                  "discountAmount-->>",
                  discountAmount
                );
  
                if (discountFeeHeadId) {
                  discountFeeHeadId.forEach((feeHeadId) => {
                    console.log(
                      "feeHeadId--->>>",
                      feeHeadId,
                      "installment--->>>",
                      installment
                    );
  
                    const matchedItem = installment?.line_items?.find(
                      (li) => (
                        console.log("li--->>>>", li),
                        li.fee_head_master_id === feeHeadId
                      )
                    );
                    console.log("matchedItem-->>", matchedItem);
  
                    lineItemAmountByCategory += matchedItem
                      ? parseInt(matchedItem[categoryAmount])
                      : 0;
                  });
                }
                console.log(
                  "lineItemAmountByCategory-->>",
                  lineItemAmountByCategory
                );
  
                if (discountAmount > 0) {
                  discountedAmount += parseInt(discountAmount);
                } else {
                  discountedAmount += Math.round(
                    (parseInt(lineItemAmountByCategory) *
                      parseInt(discountPercent)) /
                      100
                  );
                }
              });
  
              console.log("discountedAmount--->>>", discountedAmount);
  
              return {
                orderno: i + 1,
                amount: installment[feeCategory],
                status: "pending",
                due_date: installment.due_date,
                month: installment.month,
                fee_master_installment_id: installment.fee_master_installment_id,
                session_id: globalSessionId,
                installment_discount: discountedAmount,
              };
            });

            console.log("new_installments==>>>", new_installments);

            return prev?.result === "pass" ? {
              ...prev,
              class_id: val.id,
              classname: val.class_name,
              prevClass: location?.state?.classname,
              prevClsId: location?.state?.class_id,
              // result: "pass",    // commented by mohd. sarfraj | 13-sept-2024
              fee_id: feeMatch?.id,
              fee_type: feeMatch?.type,
              installments: new_installments,
            } : prev;
          // }
        })
      );
      console.log("updatedSelecteStudents===>>>", updatedSelecteStudents);
      
      if (updatedSelecteStudents.some(student => student === null)) { // replace .every() method with some by mohd. sarfraj | 14-sept-2024.
          setIsNextBtton(true);
          setStudents(students); // Set old records as it is.
          return toast.error("Create fee master records first!!!");
      } else {
          setIsNextBtton(false);
          setStudents(updatedSelecteStudents);
      }
    } catch (error) {
      console.log("error-during-fatch-fee_master-by-clsID-->>", error);
      return toast.error(error.response.data.message);
    }
  };

  const generateInstallmentDates = (start, end) => {
    const dates = [];
    let currentDate = new Date(start);

    while (currentDate <= end) {
      dates.push(new Date(currentDate));
      const monthName = currentDate.toLocaleString("default", {
        month: "long",
      });
      const formattedDate = currentDate.toISOString().split("T")[0];
      sessionDates[monthName] = formattedDate;
      currentDate.setMonth(currentDate.getMonth() + 1);

      if (currentDate.getDate() !== start.getDate()) {
        currentDate.setDate(0);
      }
    }

    if (dates[dates.length - 1] < end) {
      dates.push(new Date(end));
      const monthName = end.toLocaleString("default", { month: "long" });
      const formattedDate = end.toISOString().split("T")[0];
      sessionDates[monthName] = formattedDate;
    }

    return dates;
  };

  const getdiscount = async (id) => {
    const { result } = await schoolApi.fetchFeeDiscountLineItemsBySt(id);
    // console.log("result discount ->",result)
    return result;
  };

  const handleNext = async () => {
    try {
      let updatedStudents = [];

      for (let i = 0; i < students.length; i++) {
        if (students[i]?.result === "fail") {
          const res = await handleRadioChangeResult(students[i]?.result, students[i]);
          console.log('result===@@@@---->>>>', res);
          updatedStudents.push(res?.find((student) => student?.student_admission_id === students[i]?.student_admission_id))
        }
      }
      console.log('updatedStudents===>>>>' , updatedStudents);

      // updated by sarfraj | 02-oct-2024.
      for (let i = 0; i < students.length; i++) {
        if(students[i]?.result === "fail"){
          // replace this current object with updatedStudents array's fail student object.
          students[i] = updatedStudents?.find((student) => student?.student_admission_id === students[i]?.student_admission_id);
        }
      }
      console.log('after-replacing-failed-updated-student------->>>>>>>>>>>>>>' , students);
      

      // Updated 01-oct.2024. by sarfraj
      let countError = 0;
      for (let i = 0; i <= students.length - 1; i++) {
        if (
          !(students[i]?.class_id || students[i]?.classid) ||
          !(students[i]?.section_id || students[i]?.sectionid) ||
          !students[i]?.result
        ) {
          countError += 1;
          console.log("students[" + i + "]?.class_id -->> ", students[i]?.class_id);
          console.log("students[" + i + "]?.section_id -->> ", students[i]?.section_id);
          console.log("students[" + i + "]?.result -->> ", students[i]?.result);
        }
      }
  
      if (countError > 0) {
        return toast.error("Please fill all the required field.");
      }
  
      // if (isNexButton) {
      //   return toast.error("Create fee master records first...!!!");
      // } else {
        console.log("All Students' Records Are ready to Promote");
        navigate("/promotionView", { state: students });
      // }
    } catch (error) {
      console.error("An error occurred during handleNext:", error);
      toast.error("An unexpected error occurred. Please try again.");
    }
  };

  const labels = {
    beforeSelect: " ",
  };

  const headers = [
    // Added by mohd. sarfraj | 13-sept-2024
    {
      title: "Form No-#",
      prop: "formno",
      isFilterable: true,
    },
    {
      title: "Student Name",
      prop: "student_name",
      isFilterable: true,
    },
    {
      title: "Parent Name",
      prop: "parent_name",
      isFilterable: true,
    },
    {
      title: "Promote Class",
      prop: "",
      cell: (row) => (
        <Form.Group>
          <Form.Select
            disabled={globalSessionId ? false : true}
            required
            name="each_class_id"
            value={( row?.result === "fail") ? row?.classid : ((row?.result === "pass" && row?.class_id ) ? row?.class_id : "")}
            onChange={(e) =>
              handleClass(
                allClasses.find((obj) => obj?.id === e.target.value),
                row?.student_admission_id,
                e.target.name
              )
            }
          >
            <option value=""  disabled >-- Select --</option>
            {allClasses.map((vl, key) => (
              <option key={vl.id} value={vl.id}>{vl.class_name}</option>
            ))}
          </Form.Select>
        </Form.Group>
      ),
    },
    {
      title: "Section",
      prop: "",
      cell: (row) => (
        <Form.Group>
          <Form.Select
            disabled={globalSessionId ? false : true}
            required
            name="sectionid"
            value={(row?.section_id && row?.result === "pass") ? row?.section_id : ((row?.section_id) ? row?.section_id : row?.sectionid)}
            onChange={(e) => handleSection(e, row?.student_admission_id)}
          >
            <option value="">-- Select Section --</option>
            {(row?.updated_row_section && row?.result === "pass")
              ? row?.updated_row_section?.map((vl, key) => (
                  <option key={vl.id} value={vl.id}>{vl.name}</option>
                ))
              : (
                  row?.result === "fail" 
                  ? classSection?.map((vl , key) => (
                      <option key={vl.id} value={vl.id}>{vl.name}</option>
                    ))
                  : promoteSection.map((vl, key) => (
                        <option key={vl.id} value={vl.id}>{vl.name}</option>
                    ))
                )
            }
          </Form.Select>
        </Form.Group>
      ),
    },
    // Updated by mohd. sarfraj | 12-sept-2024.
    {
      title: "Result",
      prop: "",
      cell: (row) => (
        /*-------- Updated By mohd. sarfraj | 12-sept-2024. ---------*/
        <Form.Group className="mx-3 d-flex">
          <Form.Check
            type="switch"
            className="student-result-switch"
            id={`student_result_toggle_${row?.studentid}`}
            label={row?.result === "pass" ? "Pass" : "Fail"}
            // disabled={globalSessionId ? false : true}
            disabled={true}
            checked={row?.result === "pass"}
          />
        </Form.Group>
      ),
    },
    // Updated By mohd. sarfraj | 12-sept-2024.
    {
      title: "Next Session Status",
      prop: "",
      cell: (row) => (
        <Form.Group className="d-flex">
          <Form.Check
            type="switch"
            className="student-result-switch"
            id={`upcoming_session_toggle_${row?.studentid}`}
            label={row?.isPromote ? "Continue" : "Leave"}
            disabled={globalSessionId ? false : true}
            checked={row?.isPromote === true} // Check if "Continue" is selected
            onChange={(e) => {
              const newStatus = e.target.checked ? true : false; // true for "Continue", false for "Leave"
              handleRadioChangeUpcomingSession(row, newStatus, null); // Pass the new status
            }}
          />
        </Form.Group>
      ),
    },
  ];

  // console.log("allClasses==>", allClasses);
  // console.log("classSection==>", classSection);
  // console.log("promoteSection==>", promoteSection);
  // console.log("sessionDates-->", sessionDates);
  // console.log("feeStructure-->", feeStructure);
  // console.log("sessionDates-->", sessionDates);
  // console.log("students-pawan->", students);
  
  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <Helmet><title>{props?.tabName}</title></Helmet>

      <Row className="g-0">
        <Col lg={2} className="mx-3">
          <Link className="nav-link mx-2" to="/">
            Home <i className="fa-solid fa-chevron-right"></i> Student Promotion
          </Link>
        </Col>
      </Row>

      <Row className="mx-3 d-flex mt-4">
        <Col lg={4}>
          <Form.Group className="w-75">
            <Form.Label>Current Class</Form.Label>
            <Form.Select
              required
              name="current_class"
              onChange={(e) => handleChange1(e)}
              value={promotionData?.current_class}
            >
              {allClasses.map((vl) => (
                <option key={vl.id} value={vl.id}>{vl.class_name}</option>
              ))}
            </Form.Select>
          </Form.Group>
        </Col>
        <Col lg={4}>
          <Form.Group className="w-75">
            <Form.Label>Current Section</Form.Label> 
            <Form.Select required name="current_section" onChange={(e) => handleChange1(e)} value={promotionData?.current_section}>
              <option value="" disabled selected>-- Select --</option>
              {classSection.map((vl) => (
                <option key={vl.id} value={vl.id}>{vl.name}</option>
              ))}
            </Form.Select>
          </Form.Group>
        </Col>
        <Col lg={3}>
          <Form.Group className="w-75">
            <Form.Label>Current Session</Form.Label>
            <Form.Select required name="current_session" onChange={(e) => handleChange1(e)} value={promotionData?.current_session}>
              <option value="" disabled selected> -- Select -- </option>
              {sessions.map((vl) => (
                <option key={vl.id} value={vl.id}>{vl.year}</option>
              ))}
            </Form.Select>
          </Form.Group>
        </Col>
        <Col lg={1}>
            <Button className="btn-light add-new-button" style={{marginTop : "24px"}} variant="" onClick={handleStudentRecordsGet}>Search</Button>
        </Col>
      </Row>

      <hr className="divider my-3"/>

      {
        showUpComingFields && <Row className="mx-3 d-flex">
          <Col lg={4}>
            <Form.Group className="w-75">
              <Form.Label>Upcoming Session</Form.Label>
              <Form.Select name="promotion_session" required onChange={(e) => handleSession(e)} value={globalSessionId}>
                <option value="" disabled selected>-- Select Session --</option>
                {sessions.map((vl) => (
                  <option key={vl.id} value={vl.id}>{vl.year}</option>
                ))}
              </Form.Select>
            </Form.Group>
          </Col>
          <Col lg={4}>
            <Form.Group className="w-75">
              <Form.Label>Promoted class</Form.Label>
              <Form.Select
                disabled={globalSessionId ? false : true}
                name="prmotd_class"
                onChange={(e) => handleSetPromoteCls(allClasses.find((obj) => obj.id === e.target.value))}
              >
                <option value="" disabled selected>-- Select --</option>
                {allClasses.map((vl) => (
                  <option key={vl.id} value={vl.id}>{vl.class_name}</option>
                ))}
              </Form.Select>
            </Form.Group>
          </Col>
          <Col lg={4}>
            <Form.Group className="w-75">
              <Form.Label>Promoted Section</Form.Label>
              <Form.Select
                disabled={globalSessionId ? false : true}
                name="prmotion_section"
                onChange={(e) => handleSection(e, null)}
              >
                <option value="">-- Select --</option>
                {promoteSection?.map((vl) => (
                  <option key={vl.id} value={vl.id}>{vl.name}</option>
                ))}
              </Form.Select>
            </Form.Group>
          </Col>
        </Row>
      }

      <Row
        className="mx-3 mt-4 py-2 px-3 text-light fw-bold"
        style={{ background: "grey" }}
      >
        <h5 className="mb-0">Student List</h5>
      </Row>

      <Row className="mx-2 mt-3">
        <DatatableWrapper
          body={students}
          headers={headers}
          paginationOptionsProps={{
            initialState: {
              rowsPerPage: 10,
              options: [5, 10, 15, 20],
            },
          }}
        >
          <Row className="mb-4">
            <Col xs={12} lg={4} className="d-flex flex-col justify-content-end align-items-end">
              <Filter />
            </Col>
            <Col
              xs={12}
              sm={6}
              lg={4}
              className="d-flex flex-col justify-content-lg-center align-items-center justify-content-sm-start mb-2 mb-sm-0"
            >
              <PaginationOptions labels={labels} />
              <div style={{ marginTop: "5px" }}>
                <InfoPill left="Total Student" right={students?.length} />
              </div>
            </Col>
            <Col xs={12} sm={6} lg={4} className="d-flex flex-col justify-content-end align-items-end">
              {<Button variant="" className="btn-light add-new-button" disabled={globalSessionId ? false : true} onClick={handleNext}>Next</Button>}
            </Col>
          </Row>
          <Table striped responsive="lg" className="coustume-header">
            <TableHeader />
            {students.length > 0 ?  <TableBody /> : <TableBody><tr><td colSpan={7} className="text-center">No Records Found!!!</td></tr></TableBody>}
          </Table>
          <Row>
            <div xs={12} sm={6} lg={4} className="d-flex flex-col justify-content-center align-items-center">
              <Pagination />
            </div>
          </Row>
        </DatatableWrapper>
      </Row>
    </>
  );
};
export default Promotion;