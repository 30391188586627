/**
 * @author      Abdul Pathan
 * @date        Aug, 2024
 * @copyright   www.ibirdsservices.com
 */
import React, { useEffect, useState } from "react";
import { Button, Col, Container, Row, Table } from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import WhatsAppAPI from "../../../api/schoolApi";
import {
  DatatableWrapper,
  Filter,
  Pagination,
  PaginationOptions,
  TableBody,
  TableHeader,
} from "react-bs-datatable";
import { useNavigate } from "react-router-dom";
import Confirm from '../../Confirm';

const Templates = (props) => {
  const navigate = useNavigate();
  const [body, setBody] = useState([]);
  const [templateDelete, setTemplateDelete] = useState(false);
  const [rowData, setRowData] = useState();
  const [isSpinner, setIsSpinner] = useState(false);

  useEffect(() => {
    fetchAllTemplates();
  }, []);

  const fetchAllTemplates = async () => {
    try {
      const result = await WhatsAppAPI.getTemplateData();

      //console.log("####fetch All Template", result.data);

      if (result.error) {
        // console.log('result.error', result.error)
        toast.error(result?.error?.message);
        setBody([]);
        setIsSpinner(true);
      } else {
        const transformedData = result?.data.map((row) => {
          const header =
            row.components.find((component) => component.type === "HEADER") ||
            {};
          const body =
            row.components.find((component) => component.type === "BODY") || {};
          const footer =
            row.components.find((component) => component.type === "FOOTER") ||
            {};
          const buttons =
            row.components.find((component) => component.type === "BUTTONS")
              ?.buttons || [];

          return {
            id: row.id,
            name: row.name,
            templatename: row.name.replace(/_/g, " "),
            language: row.language,
            status: row.status,
            category: row.category,
            header: header.format || "",

            header_text: header.format === "TEXT" ? header.text : "",
            header_image_url:
              header.format === "IMAGE"
                ? header.example?.header_handle?.[0] || ""
                : "",
            header_document_url:
              header.format === "DOCUMENT"
                ? header.example?.header_handle?.[0] || ""
                : "",
            header_video_url:
              header.format === "VIDEO"
                ? header.example?.header_handle?.[0] || ""
                : "",

            message_body: body.text || "",
            example_body_text: body.example?.body_text || [], // Extracting example body text
            footer: footer.text || "",

            add_security_recommendation:
              body.add_security_recommendation || false,
            code_expiration_minutes: footer.code_expiration_minutes || null,

            // Button details extraction
            buttons: buttons
              .map((button) => {
                if (button.type === "OTP") {
                  return {
                    type: button.type,
                    otp_type: button.otp_type,
                    supported_apps:
                      button.supported_apps?.map((app) => ({
                        package_name: app.package_name,
                        signature_hash: app.signature_hash,
                      })) || [],
                  };
                } else {
                  return {
                    type: button.type,
                    text: button.text,
                    ...(button.type === "PHONE_NUMBER" && {
                      phone_number: button.phone_number,
                    }),
                    ...(button.type === "URL" && { url: button.url }),
                  };
                }
              })
              .filter((button) => button.text && button.type),
          };
        });

        console.log("transformedData", transformedData);

        setBody(transformedData);
        setIsSpinner(true);
      }
    } catch (error) {
      console.log("error ->", error);
      setBody([]);
      setIsSpinner(true);
    }
  };

  // Create table headers consisting of 4 columns.
  const labels = { beforeSelect: " " };
  const header = [
    { title: "name", prop: "templatename", isFilterable: true },
    { title: "category", prop: "category", isFilterable: true },
    { title: "language", prop: "language", isFilterable: true },
    {
      title: "Status",
      prop: "status",
      isFilterable: true,
      cell: (row) => {
        return (
          <>
            {row.status === "APPROVED" ? (
              <Button
                className="btn-sm"
                variant=""
                style={{
                  borderRadius: "15px",
                  backgroundColor: "rgb(154 235 233)",
                  cursor: "default",
                }}
              >
                {row.status}
              </Button>
            ) : row.status === "PENDING" ? (
              <Button
                className="btn-sm"
                variant=""
                style={{
                  borderRadius: "15px",
                  backgroundColor: "rgb(241 218 177)",
                  cursor: "default",
                }}
              >
                {row.status}
              </Button>
            ) : row.status === "REJECTED" ? (
              <Button
                className="btn-sm"
                variant=""
                style={{
                  borderRadius: "15px",
                  backgroundColor: "rgb(248 195 200)",
                  cursor: "default",
                }}
              >
                {row.status}
              </Button>
            ) : null}
          </>
        );
      },
    },
    {
      title: "Actions",
      prop: "id",
      cell: (row) => (
        <>
          {row.status === "APPROVED" && (
            <Button
              className="btn-sm mx-2 "
              onClick={() => editTemplate({ row })}
            >
              <i class="fa-solid fa-pen-to-square" title="Edit"></i>
            </Button>
          )}
          <Button
            className="btn-sm mx-2"
            variant="danger"
            onClick={() => removeSelectedRow({ row })}
          >
            <i class="fa-regular fa-trash-can" title="Delete"></i>
          </Button>
        </>
      ),
    },
  ];

  // add new tempate
  const addNewTemplate = () => {
    navigate(`/whatsapp_template/add`);
  };

  const editTemplate = (data) => {
    navigate(`/whatsapp_template/add`, { state: data.row });
  };

  // delete template
  const removeSelectedRow = (data) => {
    setTemplateDelete(true);
    console.log("data.row", data.row);
    setRowData(data.row);
  };
  const deleteTemplate = async () => {
    if (rowData.id && rowData.name) {
      const result = await WhatsAppAPI.deleteTemplateRecord(
        rowData.id,
        rowData.name
      );

      if (result.success) {
        setTemplateDelete(false);
        toast.success("Template deleted successfully.");
        fetchAllTemplates();
      } else {
        setTemplateDelete(false);
        toast.error(result.error.message);
      }
    }
  };

  return (
    <>
      <Helmet>
        <title>{props?.tabName}</title>
      </Helmet>
      <Row className="g-0">
        <Col lg={2} className="mx-3">
          <Link className="nav-link mx-2" to="/">
            Home <i className="fa-solid fa-chevron-right"></i> Templates
          </Link>
        </Col>
      </Row>
      {isSpinner ? (
        <>
          <Container className="mb-5">
            <Row className="mx-3 g-0">
              <Col lg={12} sm={12} xs={12} className="mb-3">
                <Row className="g-0">
                  <Col lg={12} sm={12} xs={12}>
                    <DatatableWrapper
                      body={body}
                      headers={header}
                      paginationOptionsProps={{
                        initialState: {
                          rowsPerPage: 10,
                          options: [5, 10, 15, 20],
                        },
                      }}
                    >
                      <Row className="mb-2">
                        <Col
                          lg={4}
                          sm={10}
                          xs={10}
                          className="d-flex flex-col justify-content-end align-items-end"
                        >
                          <Filter />
                        </Col>
                        <Col
                          lg={4}
                          sm={2}
                          xs={2}
                          className="d-flex flex-col justify-content-start align-items-start"
                        >
                          <PaginationOptions labels={labels} />
                        </Col>
                        <Col
                          lg={4}
                          sm={12}
                          xs={12}
                          className="mt-2 d-flex flex-col justify-content-end align-items-end"
                        >
                         <Button
                            className="btn-light add-new-button"
                            style={{ color: "black" }}
                            variant=""
                            onClick={() => addNewTemplate()}
                          >
                            Add New Template
                          </Button>
                        </Col>
                      </Row>
                      <Table striped className="data-table" responsive="sm">
                        <TableHeader />
                        <TableBody />
                      </Table>
                      <Pagination />
                    </DatatableWrapper>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Container>
        </>
      ) : (
        <div className="sk-cube-grid">
          <div className="sk-cube sk-cube1"></div>
          <div className="sk-cube sk-cube2"></div>
          <div className="sk-cube sk-cube3"></div>
          <div className="sk-cube sk-cube4"></div>
          <div className="sk-cube sk-cube5"></div>
          <div className="sk-cube sk-cube6"></div>
          <div className="sk-cube sk-cube7"></div>
          <div className="sk-cube sk-cube8"></div>
          <div className="sk-cube sk-cube9"></div>
        </div>
      )}

      {templateDelete && (
        <Confirm
          show={templateDelete}
          onHide={() => setTemplateDelete(false)}
          deleteTemplate={deleteTemplate}
          title="Confirm Delete"
          message="You are going to delete the record. Are you sure?"
          table="template"
        />
      )}

      <ToastContainer />
    </>
  );
};

export default Templates;
