/**
 * @author: Pawan Singh Sisodiya
 */
import React, { useState, useEffect, useRef } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";
import schoolApi from "../../api/schoolApi";
import { useReactToPrint } from "react-to-print";
import { Helmet } from "react-helmet";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import InstallmentTable from "./InstallmentTable";
import FeeReceipt from "./FeeReceipt";
import moment from "moment";
import html2pdf from "html2pdf.js";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "react-toastify/dist/ReactToastify.css";
function loadScript(src) {
  return new Promise((resolve) => {
    const script = document.createElement("script");
    script.src = src;
    script.onload = () => {
      resolve(true);
    };
    script.onerror = () => {
      resolve(false);
    };
    document.body.appendChild(script);
  });
}
const FeeDepositeEdit = (props) => {
  const [selectedReceiptData, setSelectedReceiptData] = useState(null);
  const receiptRef = useRef();
  const location = useLocation();
  const [latejoineediscount, setLateJoineeDiscount] = useState(0);
  console.log(" location.state", location.state.row.student_info);
  const [readOnly, setReadOnly] = useState(
    location?.state?.readonly ? location?.state?.readonly : false
  );
  const [studentRecord, SetStudentRecord] = useState(
    location?.state?.row?.student_info ? location?.state?.row?.student_info : {}
  );
  const [studentInstallment, setStudentInstallment] = useState();
  const [feeDeposit, setFeeDeposit] = useState({});
  const [tempAmount, setTempAmount] = useState({});
  const [showTransaction, setShowTransaction] = useState(true);
  let [reportData, setReportData] = useState({
    payment_date: new Date(),
  });

  const handleDateChange = (date, name) => {
    console.log("date == ", date);
    if (date) {
      const day = String(date.getDate()).padStart(2, "0"); // Get day and pad with zero if needed
      const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-based in JavaScript, so add 1
      const year = date.getFullYear(); // Get full year

      const dateFormat = `${year}-${month}-${day}`;
      console.log("dateFormat == ", dateFormat);
      setReportData((prevState) => ({
        ...prevState,
        [name]: date,
      }));
      setFeeDeposit((prev) => {
        return { ...prev, [name]: dateFormat };
      });
    } else {
      setReportData((prevState) => ({
        ...prevState,
        [name]: "",
      }));
      setFeeDeposit((prev) => {
        return { ...prev, [name]: "" };
      });
    }
  };

  useEffect(() => {
    const rowData = location?.state?.row;
    if (rowData) {
      const studentData = rowData.student_info || rowData;
      if (studentData.studentname) {
        SetStudentRecord(studentData);
        sessionStorage.setItem(
          "temp",
          JSON.stringify(rowData.student_info || rowData)
        );
      } else {
        const tempData = JSON.parse(sessionStorage.getItem("temp"));
        if (tempData) {
          SetStudentRecord(tempData);
          console.log("studentrecords=>",studentRecord)
        }
      }
    }
  }, [location.state.row]);
  useEffect(() => {
    initClass();
  }, [studentRecord]);

  async function initClass() {
    try {
      let frequencySettings = 0;
      let lateFeeSettings = 0;
      const ResultSettings = await schoolApi.getAllSetting();
      console.log("ResultSettings-->", ResultSettings);
      if (ResultSettings.success) {
        frequencySettings = ResultSettings?.data?.filter(
          (res) => res.key === "frequency"
        )[0].value;
        lateFeeSettings = ResultSettings?.data?.filter(
          (res) => res.key === "late_fee"
        )[0].value;
      }
      console.log("tempFrequency-->", frequencySettings);
      console.log("tempsettings-->", lateFeeSettings);

      console.log("studentRecord ->", studentRecord);

      const insResult = await schoolApi.getallIntsallment(
        studentRecord?.student_addmission_id,
        studentRecord?.session_id
          ? studentRecord?.session_id
          : studentRecord?.sessionid,
        readOnly ? studentRecord?.fee_master_installment_id : undefined
      );

      console.log("insResult-->", insResult);

      const currentDate = new Date();
      const currentYear = currentDate.getFullYear();

      insResult.result = insResult?.result?.map((is) => {

        insResult.result = insResult?.result?.map((is) => {
          if (
            !is.late_fee > 0 &&
            is.status === "pending" &&
            !studentRecord.isrte
          ) {
            let latefee_is = 0;
            const dueDate = is?.due_date;
            console.log("dueDate", dueDate);

            if (dueDate) {
              const dueDateObj = new Date(dueDate);
              console.log("dueDateObj-->", dueDateObj);

              if (
                dueDateObj.getFullYear() === currentYear &&
                dueDateObj < currentDate
              ) {
                const differenceInDays = Math.ceil(
                  (currentDate - dueDateObj) / (1000 * 3600 * 24)
                );
                console.log("dueDate differenceInDays-->", differenceInDays);
                const netDueDays = parseInt(
                  (differenceInDays + parseInt(frequencySettings)) /
                  parseInt(frequencySettings)
                );
                console.log("dueDate netDueDays-->", netDueDays);
                latefee_is += Math.round(
                  netDueDays * parseInt(lateFeeSettings)
                );
              }
            }

            is.late_fee = latefee_is;
            console.log("latefee_is-->", latefee_is);
          }

          if (is.deposit_id && is.late_fee > 0) {
            let lateFeeHelper = insResult.result.filter(
              (ft) => ft.deposit_id === is.deposit_id
            );

            if (is.id !== lateFeeHelper[lateFeeHelper.length - 1].id) {
              return { ...is, late_fee: 0 };
            }
          }

          return { ...is };
        });
        if (is.deposit_id && is.late_fee > 0) {
          let lateFeeHelper = insResult.result.filter(
            (ft) => ft.deposit_id === is.deposit_id
          );

          if (is.id !== lateFeeHelper[lateFeeHelper.length - 1].id) {
            return { ...is, late_fee: 0 };
          }
        }

        return { ...is };
      });
      console.log("insResult ->", insResult);

      if (studentRecord?.edit) {
        insResult.result = insResult.result.map((res) => {
          if (res.deposit_id === studentRecord.deposit_id) {
            console.log("res.deposite_id",res)
            return { ...res, edit: true };
          } else {
            return { ...res, edit: false };
          }
        });
      }

      if (insResult.success) {
        console.log("hear");
        console.log("insResult===>",insResult.result)
        setStudentInstallment(insResult.result);
        // console.log("insa",installment_amount)
      }

      console.log("studentInstallment->", studentInstallment);
    } catch (error) {
      console.log("error ->", error);
    }
  }

  const handleCloseModal = () => {
    setFeeDeposit({});
    setTempAmount({});
  };

  const feeDepositHandler = (installments) => {
    console.log("parent installments-->", installments);

    const totals = {
      totalAmount: 0,
      discount: 0,
      previous_due: 0,
      due_amount: 0,
      totalTransportFee: 0,
      grossPayableAmount: 0,
      late_fee: 0,
      netPayableAmount: 0,
      amount: 0,
      payment_date: new Date(),
      late_joinee_discount: 0,
      installments: [],
      deposit_id: null,
    };

    const parseAmount = (amount) => parseInt(amount) || 0;

    installments?.forEach((res, index) => {
    
      totals.installments.push({ id: res?.id });
      totals.late_joinee_discount = parseAmount(res?.late_joinee_discount);
      totals.totalAmount +=
        parseAmount(res?.installment_amount) + parseAmount(res?.transport_fee);
      totals.discount += parseAmount(res?.discounted_amount);
      totals.previous_due += parseAmount(res?.previous_due);
      totals.totalTransportFee += parseAmount(res?.transport_fee);
      totals.grossPayableAmount += parseAmount(res?.grossPayableAmount);
      totals.netPayableAmount += parseAmount(res?.net_payable_amount);
      totals.deposit_id = res?.deposit_id ? res?.deposit_id : null;
      totals.due_amount = res?.due_amount ? res?.due_amount : 0;
      totals.late_fee += res?.late_fee ? res?.late_fee : 0;
    });

    if (installments[0]?.edit) {
      setFeeDeposit({
        totalAmount: totals?.totalAmount ? totals?.totalAmount : 0,
        late_joinee_discount: totals.late_joinee_discount
          ? totals.late_joinee_discount
          : 0,
        grossPayableAmount: totals?.grossPayableAmount
          ? totals?.grossPayableAmount
          : 0,
        amount: studentRecord?.amount ? studentRecord?.amount : 0,
        late_fee: parseInt(studentRecord?.late_fee)
          ? parseInt(studentRecord?.late_fee)
          : 0,
        payment_date: new Date(studentRecord?.payment_date),
        payment_method: studentRecord?.payment_method,
        deposit_id: studentRecord?.deposit_id,
        discount: totals.discount ? totals.discount : 0,
        netPayableAmount: totals.netPayableAmount,
        installments: totals?.installments,
        totalTransportFee: totals.totalTransportFee,
        transaction_no: installments[0]?.transaction_no,
      });

      setTempAmount({
        totalAmount: totals?.totalAmount ? totals?.totalAmount : 0,
        grossPayableAmount: totals?.grossPayableAmount
          ? totals?.grossPayableAmount
          : 0,
        amount: studentRecord?.amount ? studentRecord?.amount : 0,
        late_fee: parseInt(studentRecord?.late_fee)
          ? parseInt(studentRecord?.late_fee)
          : 0,
        payment_date: studentRecord.payment_date
          ? new Date(studentRecord.payment_date)
          : new Date(),
        payment_method: studentRecord?.payment_method,
        deposit_id: studentRecord?.deposit_id,
        discount: totals.discount ? totals.discount : 0,
        late_joinee_discount: totals.late_joinee_discount
          ? totals.late_joinee_discount
          : 0,
        netPayableAmount: totals.netPayableAmount,
        installments: totals?.installments,
        totalTransportFee: totals.totalTransportFee,
      });
    } else {
      setFeeDeposit(totals);
      setTempAmount(totals);
      console.log("parent totals", totals);
    }
  };

  const handleChange = (e) => {
    e.preventDefault();
    if (e.target.name === "amount") {
      if (isNaN(e.target.value)) {
        return;
      }

      const enteredValue = e.target.value;
      console.log("enteredValue-->", enteredValue);

      if (enteredValue != "" && enteredValue <= feeDeposit?.netPayableAmount) {
        const amt = feeDeposit?.netPayableAmount;
        const dueAmount = amt - enteredValue;

        setFeeDeposit({
          ...feeDeposit,
          [e.target.name]: enteredValue,
          due_amount: Math.round(dueAmount),
        });
      } else if (
        enteredValue == "" &&
        enteredValue <= feeDeposit?.netPayableAmount
      ) {
        setFeeDeposit({
          ...feeDeposit,
          [e.target.name]: "0",
          due_amount: feeDeposit?.netPayableAmount,
        });
      } else {
        toast.error("Entered amount exceeds total amount!");
      }
    } else if (e.target.name === "late_fee") {
      if (isNaN(e.target.value) || e.target.value === "") {
        e.target.value = 0;
      }

      let late = e.target.value;
      let amt = feeDeposit?.amount;
      let ntp = feeDeposit?.netPayableAmount;
      let dueAmount = 0;

      if (late != "") {
        ntp =
          parseInt(tempAmount?.netPayableAmount) +
          parseInt(late) -
          parseInt(tempAmount?.late_fee);
        amt = ntp;

        dueAmount = ntp - amt;
      } else {
        amt = parseInt(tempAmount?.amount) - 0;
        ntp =
          parseInt(tempAmount?.netPayableAmount) -
          parseInt(tempAmount?.late_fee);

        dueAmount = 0;
      }

      setFeeDeposit({
        ...feeDeposit,
        [e.target.name]: parseInt(late),
        amount: amt,
        netPayableAmount: ntp,
        due_amount: Math.round(dueAmount),
      });
    } else if (e.target.name === "payment_method") {
      if (e.target.value !== "Cash") {
        setShowTransaction(false); // Change by Abhishek
      } else {
        setShowTransaction(true); // Add by Abhishek
      }
      setFeeDeposit({ ...feeDeposit, [e.target.name]: e.target.value });
    } else {
      setFeeDeposit({ ...feeDeposit, [e.target.name]: e.target.value });
    }
  };

  const amountManager = () => {
    if (feeDeposit.amount <= 0) {
      console.log("amount manager called");
      feeDeposit.amount = feeDeposit.netPayableAmount;
    }
  };

  const handlePrint = useReactToPrint({
    content: () => receiptRef.current,
    documentTitle: "FeeReceipt",
    onAfterPrint: () => {
      setSelectedReceiptData(null);
    },
  });

  const saveAsPdf = async (data) => {
    console.log("data ->", data);

    const element = receiptRef.current;
    const options = {
      margin: 0,
      filename: "FeeReceipt.pdf",
      image: { type: "jpeg", quality: 0.98 },
      html2canvas: { scale: 2 },
      jsPDF: { unit: "in", format: "a4", orientation: "landscape" },
    };

    const pdfBlob = await html2pdf()
      .set(options)
      .from(element)
      .toPdf()
      .output("blob");
    const formData = new FormData();
    formData.append("file", pdfBlob, "FeeReceipt.pdf");

    const result = await schoolApi.createFile(data.deposit_id, formData);
    console.log("result ->", result);
  };

  const handlePrintClick = async (data) => {
    try {
      setSelectedReceiptData(data);
      setTimeout(async () => {
        await saveAsPdf(data);
      }, 200);
      setTimeout(async () => {
        handlePrint();
      }, 200);
    } catch (error) {
      console.error("Error creating file or printing:", error);
    }
  };

  async function payonline() {
    console.log("feeDeposit?.amount ->", feeDeposit?.amount);

    const amount = feeDeposit?.amount
      ? feeDeposit?.amount
      : feeDeposit?.netPayableAmount
        ? feeDeposit?.netPayableAmount
        : 0;
    try {
      if (amount > 0) {
        const res = await loadScript(
          "https://checkout.razorpay.com/v1/checkout.js"
        );
        if (!res) {
          alert("Razorpay failed to load!!");
          return;
        }

        const data = await schoolApi.FeeDepositOnline(amount);
        console.log("data->", data);

        const options = {
          key: "rzp_live_sDV9Ntu7wI10KJ",
          amount: amount * 100,
          currency: "INR",
          name: "Acme Corp",
          description: "Test Transaction",
          image: "https://example.com/your_logo",
          order_id: data.result.id,
          notes: {
            address: "Razorpay Corporate Office",
          },
          theme: {
            color: "#3399cc",
          },
          handler: async function (response) {
            try {
              console.log("Payment success response:", response);

              const paymentData = {
                order_id: response.order_id,
                payment_id: response.razorpay_payment_id,
                signature: response.razorpay_signature,
                student_addmission_id: studentRecord?.student_addmission_id,
                amount: amount,
                payment_date: new Date(),
                payment_method: "Online (Razorpay)",
                transaction_no: response.razorpay_payment_id,
                status: "Success",
              };
              console.log("paymentData->", paymentData);
              if (response.razorpay_payment_id) {
                feeDeposit.payment_method = "razorpay";
                feeDeposit.transaction_no = response.razorpay_payment_id;
                handleSaveDeposites();
              } else {
                toast.error("payment not acctepable");
              }
            } catch (error) {
              console.log("Error occurred during payment handler:", error);
              toast.error("Something went wrong while saving payment.");
            }
          },
          prefill: {
            name: feeDeposit?.student_name,
            email: feeDeposit?.student_email,
            contact: feeDeposit?.student_contact,
          },
          modal: {
            ondismiss: function () {
              console.log("Payment modal was dismissed");
            },
          },
        };

        const paymentObject = new window.Razorpay(options);
        paymentObject.open();
      } else {
        toast.error("Enter amount gerterthan zero");
      }
    } catch (error) {
      console.log("error ->", error);
    }
  }

  const handleSaveDeposites = async () => {
    try {
      if (
        (feeDeposit.amount === null || feeDeposit.amount === undefined || feeDeposit.amount === "") ||
        !feeDeposit.payment_date ||
        !feeDeposit.payment_method
      ) {
        return toast.error("Missing required fields!!");
      }


      if (feeDeposit.amount > feeDeposit.netPayableAmount) {
        return toast.error("Amount is greater then net payable amount");
      }
      const arr = {
        totalAmount: feeDeposit?.totalAmount,
        netPayableAmount: feeDeposit?.netPayableAmount,
        student_addmission_id: studentRecord?.student_addmission_id,
        discount: feeDeposit?.discount,
        installments: feeDeposit?.installments,
        // amount: feeDeposit?.amount,
        amount: feeDeposit?.amount ? feeDeposit?.amount : feeDeposit.netPayableAmount, // updated
        late_joinee_discount: feeDeposit?.late_joinee_discount,
        sessionid: studentRecord?.session_id,
        payment_date: feeDeposit?.payment_date,
        payment_method: feeDeposit?.payment_method,
        late_fee: feeDeposit?.late_fee ? feeDeposit?.late_fee : 0,
        remark: feeDeposit?.remark,
        due_amount: feeDeposit?.due_amount,
        transaction_no: feeDeposit?.transaction_no,
      };

      console.log("arr value->", arr);

      if (studentRecord.edit) {
        const feeDepositEditResult = await schoolApi.updateFeeDepositsById(
          studentRecord?.deposit_id,
          arr
        );
        console.log("feeDepositEditResult-->", feeDepositEditResult);

        if (feeDepositEditResult) {
          //   toast.success("Fees updated successfully!!");
          arr.deposit_id = feeDepositEditResult.result.newFeeDeposite.id;

          toast.success("Fees deposite Updated successfully!!");
          await handlePrintClick(arr);
          setTimeout(() => {
            handleCloseModal();
            initClass();
          }, 2000);
        } else {
          // toast.error('Something went wrong!!');
        }
      } else {
        if (arr) {
          console.log("arr-", arr);

          const feeDepositResult = await schoolApi.createFeeDeposit(arr);
          console.log("feeDepositResult ->", feeDepositResult);

          if (feeDepositResult) {
            arr.deposit_id = feeDepositResult.deposit.id;
            toast.success("Fees deposited successfully!!");
            await handlePrintClick(arr);
            setTimeout(() => {
              handleCloseModal();
              initClass();
            }, 2000);
          } else {
            // toast.error("Something went wrong!!");
          }
        }
      }
    } catch (error) {
      console.log("error occured", error);
    }
  };

  console.log("feeDeposit", feeDeposit);
  console.log("studentInstallment-->", studentInstallment);

  // Added by muskan khan 29-nov-24
  const formatNumberWithCommas = (number) => {
    return number ? number.toLocaleString("en-IN") : "0";
  };
  return (
    <>
      <Helmet>
        <title>{props?.tabName}</title>
      </Helmet>
      <Row className="g-0">
        <Col lg={2} className="mx-3">
          <Link className="nav-link mx-2" to="/">
            Home <i className="fa-solid fa-chevron-right"></i> FeeDepositCreate
          </Link>
        </Col>
        <ToastContainer
          position="top-right"
          autoClose={2000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
        />

        <Row className="view-form">
          <Row className="section-header my-2">
            <Col lg={9}>
              <span>Fee Deposit Edit</span>
            </Col>
            <Col lg={2} className="d-flex justify-content-end"></Col>
          </Row>
          <Row
            className="mx-3"
            style={{ backgroundColor: "white", borderRadius: "10px" }}
          >
            <Row className="pb-3">
              <Col lg={1}></Col>
              <Col lg={5}>
                <label>Student Name</label>
                <span>
                  {/* {studentRecord.firstname} {studentRecord.lastname} */}
                  {studentRecord?.studentname}
                </span>
              </Col>
              <Col lg={5}>
                <label>Email</label>
                <span>{studentRecord?.email}</span>
              </Col>
              <Col lg={1}></Col>
              <Col lg={1}></Col>
              <Col lg={5}>
                <label>Dob</label>
                <span>
                  {moment(studentRecord.dateofbirth).format("DD-MM-YYYY")}
                  {/* {studentRecord?.dateofbirth} */}
                </span>
              </Col>
              <Col lg={5}>
                <label>Category</label>
                <span>{studentRecord?.category}</span>
              </Col>

              <Col lg={1}></Col>
              <Col lg={1}></Col>
              <Col lg={5}>
                <label>Phone</label>
                <span>{studentRecord?.phone}</span>
              </Col>
              <Col lg={5}>
                <label>Total Fees (₹)</label>
                {/* <span>{studentRecord?.total_fees}</span> */}
                <span>{formatNumberWithCommas(
                          parseInt(studentRecord?.total_fees))}</span>

              </Col>

              <Col lg={1}></Col>
              <Col lg={1}></Col>
              {/* <Col lg={5}> */}
              {/* <label>Discount (%)</label> */}
              {/* <span>{studentDiscount}</span> */}
              {/* </Col> */}
              {/* <Col lg={5}>
                <label>Total Fees</label>
                <span>{studentRecord.total_fees}</span>
              </Col> */}

              <Col lg={1}></Col>

              <Col></Col>
            </Row>
          </Row>
          <Col lg={2}></Col>
        </Row>

        {studentInstallment && (
          <InstallmentTable
            data={studentInstallment}
            edit={studentRecord?.edit}
            feeDepositHandler={feeDepositHandler}
          />
        )}

        {feeDeposit?.totalAmount > 0 && (
          <>
            <Row>
              <Col lg={1}></Col>
              <Col lg={5} className="mb-3">
                <Row>
                  <Form.Group className="mx-3">
                    <Form.Label
                      className="form-view-label"
                      htmlFor="formBasicEmail"
                    >
                      Total Amount
                    </Form.Label>
                    <Form.Control
                      type="number"
                      name="totalAmount"
                      placeholder="Enter Duration"
                      value={feeDeposit?.totalAmount}
                      onChange={(e) => handleChange(e)}
                      disabled
                    />
                  </Form.Group>
                </Row>
                {feeDeposit?.late_joinee_discount > 0 && (
                  <Row>
                    <Form.Group className="mt-3 mx-3">
                      <Form.Label
                        className="form-view-label"
                        htmlFor="formBasicFirstName"
                      >
                        Late Joinee Discount
                      </Form.Label>
                      <Form.Control
                        type="number"
                        value={feeDeposit?.late_joinee_discount}
                        name="late_joinee_discount"
                        placeholder="0"
                        onChange={(e) => handleChange(e)}
                      />
                    </Form.Group>
                  </Row>
                )}

                <Row>
                  <Form.Group className="mt-3 mx-3">
                    <Form.Label
                      className="form-view-label"
                      htmlFor="formBasicFirstName"
                    >
                      Discount
                    </Form.Label>
                    <Form.Control
                      type="number"
                      disabled
                      value={feeDeposit?.discount}
                      name="discount"
                      placeholder="Enter Discount"
                      onChange={(e) => handleChange(e)}
                    />
                  </Form.Group>
                </Row>
                <Row>
                  <Form.Group className="mt-3 mx-3">
                    <Form.Label
                      className="form-view-label"
                      htmlFor="formBasicFirstName"
                    >
                      Gross Payable
                    </Form.Label>
                    <Form.Control
                      type="number"
                      disabled
                      value={feeDeposit?.grossPayableAmount}
                      name="gross_payable"
                      placeholder="Enter Discount"
                      onChange={(e) => handleChange(e)}
                    />
                  </Form.Group>
                </Row>
                <Row>
                  <Form.Group className=" mt-3 mx-3">
                    <Form.Label
                      className="form-view-label"
                      htmlFor="formBasicEmail"
                    >
                      Prevoius Dues
                    </Form.Label>
                    <Form.Control
                      type="number"
                      name="previous_due"
                      placeholder="Total Dues"
                      value={feeDeposit?.previous_due}
                      // value={studentRecord.edit && studentRecord.previous_due ? studentRecord.previous_due : feeDeposit?.previous_due}
                      onChange={(e) => handleChange(e)}
                      disabled
                    />
                  </Form.Group>
                </Row>

                <Row>
                  <Form.Group className="mt-3 mx-3">
                    <Form.Label
                      className="form-view-label"
                      htmlFor="formBasicFirstName"
                    >
                      Late Fee
                    </Form.Label>
                    <Form.Control
                      // type="number"
                      type="numeric"
                      value={feeDeposit?.late_fee}
                      name="late_fee"
                      placeholder="Enter Late Fee"
                      onChange={(e) => handleChange(e)}
                    />
                  </Form.Group>
                </Row>
                <Row>
                  <Form.Group className="mt-3 mx-3">
                    <Form.Label
                      className="form-view-label"
                      htmlFor="formBasicEmail"
                    >
                      Net Payable Amount
                    </Form.Label>
                    <Form.Control
                      type="number"
                      name="net_payable_amount"
                      placeholder="Net Payable Amount"
                      value={feeDeposit?.netPayableAmount}
                      onChange={(e) => handleChange(e)}
                      disabled
                    />
                  </Form.Group>
                </Row>
              </Col>
              <Col lg={5}>
                <Row>
                  <Form.Group className=" mx-3">
                    <Form.Label
                      className="form-view-label"
                      htmlFor="formBasicFirstName"
                    >
                      Enter Amount
                    </Form.Label>
                    <Form.Control
                      required
                      // type="number"
                      type="numeric"
                      inputMode="numeric"
                      value={
                        feeDeposit?.amount
                          ? feeDeposit?.amount
                          : feeDeposit?.netPayableAmount
                            ? feeDeposit?.netPayableAmount
                            : 0
                      }
                      name="amount"
                      placeholder="Enter Deposite Amount"
                      onChange={(e) => handleChange(e)}
                    />
                  </Form.Group>
                </Row>
                <Row>
                  <Form.Group className="mt-3 mx-3">
                    <Form.Label
                      className="form-view-label"
                      htmlFor="formBasicFirstName"
                    >
                      Due Amount
                    </Form.Label>
                    <Form.Control
                      disabled
                      type="number"
                      value={feeDeposit?.due_amount}
                      name="due_amount"
                      placeholder="Enter Due Amount"
                      onChange={(e) => handleChange(e, null, null)}
                    />
                  </Form.Group>
                </Row>
                <Row>
                  <Form.Group className="mt-3 mx-3">
                    <Form.Label
                      className="form-view-label"
                      htmlFor="formBasicLastName"
                    >
                      Deposite Date
                    </Form.Label>
                    {/* <input
                      type="text"
                      id="feeDeposite_date"
                      name="dateofbirth"
                      readOnly
                      autoComplete="off"
                      className="form-control"
                      placeholder="Select Date"
                    /> */}
                    <DatePicker
                      required
                      selected={reportData.payment_date}
                      onChange={(date) =>
                        handleDateChange(date, "payment_date")
                      }
                      dateFormat="dd-MM-yyyy"
                      showMonthDropdown
                      showYearDropdown
                      dropdownMode="select"
                      placeholderText="Select a date"
                      className="form-control"
                      style={{ width: "300px" }}
                      onKeyDown={(e) => e.preventDefault()}
                      highlightDates={[new Date()]}
                    />
                  </Form.Group>
                </Row>
                <Row>
                  <Form.Group className="mt-3 mx-3">
                    <Form.Label
                      className="form-view-label"
                      htmlFor="formBasicClass"
                    >
                      Payment Method
                    </Form.Label>
                    <Form.Select
                      required
                      name="payment_method"
                      value={feeDeposit?.payment_method}
                      onChange={(e) => handleChange(e)}
                    >
                      <option value="">-- Select Type --</option>
                      <option value="Cash">Cash</option>
                      <option value="NEFT">NEFT</option>
                      <option value="Cheque">Cheque</option>
                      <option value="Paytm">Paytm</option>
                    </Form.Select>
                  </Form.Group>
                </Row>
                {/* {showTransaction &&  */}
                <Row>
                  <Form.Group className="mt-3 mx-3">
                    <Form.Label
                      className="form-view-label"
                      htmlFor="formBasicFirstName"
                    >
                      Transaction Number
                    </Form.Label>
                    <Form.Control
                      type="text"
                      value={feeDeposit?.transaction_no}
                      name="transaction_no"
                      placeholder="Enter Transaction No"
                      onChange={(e) => handleChange(e)}
                      disabled={showTransaction && !feeDeposit?.transaction_no}
                    />
                  </Form.Group>
                </Row>
                {/* } */}
                <Row
                  // style={{ height: "24%" }}
                  className="mt-3 ms-2"
                >
                  <Form.Group>
                    <Form.Label
                      className="form-view-label"
                      htmlFor="formBasicFirstName"
                    >
                      Remark
                    </Form.Label>
                    <Form.Control
                      as="textarea"
                      value={feeDeposit?.remark}
                      name="remark"
                      placeholder="Enter Remark"
                      onChange={(e) => handleChange(e, null, null)}
                    // style={{ height: "100%", width: "103%" }}
                    />
                  </Form.Group>
                </Row>
              </Col>
              <Col lg={1}></Col>
            </Row>
            <Row className={"justify-content-center"}>
              <Col lg={2}>
                <Button
                  className="mx-3 mt-3 mb-3"
                  variant="secondary"
                  onClick={handleCloseModal}
                >
                  Close
                </Button>
                <Button
                  className="mx-3 mt-3 mb-3"
                  variant="primary"
                  onClick={handleSaveDeposites}
                >
                  Save
                </Button>

                <Button
                  className="mx-3 mt-3 mb-3"
                  variant="primary"
                  onClick={payonline}
                >
                  Pay Online
                </Button>
              </Col>
            </Row>
            {/* Add By Abhishek */}
            {selectedReceiptData && (
              <div style={{ display: "none" }}>
                <FeeReceipt
                  ref={receiptRef}
                  receiptData={selectedReceiptData}
                />
              </div>
            )}
          </>
        )}
      </Row>
    </>
  );
};

export default FeeDepositeEdit;