
import React, { useEffect, useState } from "react";

import { Button, Col, Row, Table } from "react-bootstrap";
import { Link } from "react-router-dom";
import {
  DatatableWrapper,
  Filter,
  Pagination,
  PaginationOptions,
  TableBody,
  TableHeader,
} from "react-bs-datatable";
import InfoPill from "../InfoPill";
import { ShimmerTable } from "react-shimmer-effects";
import schoolApi from "../../api/schoolApi";
import CreateModuleModal from "./CreateModuleModal";
import PubSub from "pubsub-js";
import Confirm from "../Confirm";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import authApi from "../../api/authApi";
import { useOutletContext } from "react-router-dom";
import { Helmet } from "react-helmet";

const ModuleList = (props) => {
  const [body, setBody] = useState();
  const [modalShow, setModalShow] = useState(false);
  const [btnName, setBtnName] = useState("Save");
  const [parentModules, setParentModules] = useState([]);
  const [subModules, setSubModules] = useState([]);
  const [moduleData, setModuleData] = useState();
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [rowDataId, setRowDataId] = useState();
  const [moduleRecords, setModuleRecords] = useOutletContext();

  useEffect(() => {
    fetchAllModules();
  }, []);

  async function fetchAllModules() {
    try {
      let selectedParentModules = [];
      let selectedSubModules = [];
      if (authApi.companyDetail().companyid) {
        const result = await schoolApi.getAllModules(authApi.companyDetail().companyid);
        if(result.success){
            setBody(result?.result);
            result?.result.forEach((element) => {
              if (element.parent_module === null) {
                selectedParentModules.push(element);
              } else if (element.parent_module !== null) {
                selectedSubModules.push(element);
              }
            });
            setSubModules(selectedSubModules);
            setParentModules(selectedParentModules);

            result?.result?.forEach((element) => {
              if (element.parent_module == null) {
                let subModule = [];
                result?.result?.forEach((res) => {
                  if (res.parent_module === element.id) {
                    subModule.push(res);
                  }
                  element.subModule = subModule;
                });
              }
            });
            setModuleRecords(result);
        } else{
          setBody([]);
          toast.error(result?.message)
        }
      }
    } catch (error) {
      console.log('SERVER_ERROR-->>' , error);
      toast.error(error?.response?.data?.message)
    }
  }

  const handleEditButton = (row) => {
    setModalShow(true);
    setModuleData(row);
    setBtnName("Update");
  };

  const handleDeleteButton = (row) => {
    setShowDeleteModal(true);
    setRowDataId(row.id);
  };

  const labels = {
    beforeSelect: " ",
  };

  const header = [
    { title: "Name", prop: "name", isFilterable: true },
   // { title: "Status", prop: "status", isFilterable: true },
   {
    title: "Status",
    prop: "status",
    isFilterable: true,
    cell: (row) => {
      return row.status === "Active" ? "Active" : "InActive";
    },
  },
    {
      title: "Action",
      prop: "",
      isFilterable: true,
      cell: (row) => {
        return (
          <>
            <Button
              className="btn-sm mx-2 edit-button"
              variant=""
              onClick={() => handleEditButton(row)}
            >
              <i className="fa-regular fa-pen-to-square"></i>
            </Button>
            <button
              className="btn btn-sm btn-danger mx-2"
              onClick={() => handleDeleteButton(row)}
            >
              <i className="fa fa-trash"></i>
            </button>
          </>
        );
      },
    },
  ];

  const handleDeleteModuleRecord = async () => {
    if (rowDataId) {
      try {
        const result = await schoolApi.deleteModule(rowDataId);
        console.log("Result after deletion:", result);

        if (result && result.success) {
          const deleteModule = body.filter((rec) => rec.id !== rowDataId);
          if (result?.result?.parent_module === null) {
            console.log("hear parentmodule");
            const deleteParentModules = parentModules.filter((rec) => rec.id !== rowDataId);
            const newmodules = deleteParentModules.map((record, index) => {
              return { ...record, order_no: index + 1 };
            });
            const upateresult = await schoolApi.updateAllParentmodule(newmodules);
          } else {
            console.log("hear submodule ->", subModules);
            const deletesubModules = subModules.filter((rec) => rec.parent_module === result?.result?.parent_module);
            const deleteaftersubModules = deletesubModules.filter((rec) => rec.id !== rowDataId);
            console.log("deletesubModules ->", deleteaftersubModules);
            const newmodules = deleteaftersubModules.map((record, index) => {
              return { ...record, order_no: index + 1 };
            });
            const upateresult = await schoolApi.updateAllParentmodule(newmodules);
            if (!upateresult.success) {
              toast.error(upateresult.message);
            }
          }
          console.log("Updated body after deletion:", deleteModule);
          fetchAllModules();
          toast.success("Record deleted successfully");
          // setBody(deleteModule);
          setShowDeleteModal(false);
        } else {
          console.error("deletion was not successful", result);
        }
      } catch (error) {
        if ( error.response && error.response.data && error.response.data.errors) {
          toast.error(error.response.data.errors);
        } else {
          toast.error("Unknown error occurred. Please try again.", {
            style: { backgroundColor: "#f7040f", color: "white" },
          });
        }
      }
    }
  };

  const createModule = () => {
    setModalShow(true);
    setModuleData(null);
    setBtnName("Save");
  };

  const handleCloseModal = () => {
    setModalShow(false);
  };

  const [paginationOptions, setPaginationOptions] = useState({
    rowsPerPage: 5,
    options: [5, 10, 15, 20],
  });

  const handlePaginationChange = (newPaginationOptions) => {
    setPaginationOptions(newPaginationOptions);
  };

  return (
    <>
      <Helmet><title>{props?.tabName}</title></Helmet>
      {showDeleteModal && (
        <Confirm
          show={showDeleteModal}
          onHide={() => setShowDeleteModal(false)}
          handleDelete={() => handleDeleteModuleRecord()}
          title="Confirm delete?"
          message="You are going to delete the record. Are you sure?"
          table="deleteModule"
        />
      )}

      { 
      modalShow &&
        <CreateModuleModal
          modalShow={modalShow}
          handleCloseModal={handleCloseModal}
          fetchAllModules={fetchAllModules}
          moduleData={moduleData}
          btnName={btnName}
        />
      }

      <Col lg={2} className="mx-4">
        <Link className="nav-link" to="/">
          Home <i className="fa-solid fa-chevron-right"></i> Module
        </Link>
      </Col>

      <Row className="g-0">
        <Col lg={12} className="px-lg-4">
          {body ? (
            <DatatableWrapper
              body={body ? body : ""}
              headers={header}
              paginationOptionsProps={{
                initialState: paginationOptions,
                onChange: handlePaginationChange,
              }}
            >
              <Row className="mb-4">
                <Col
                  xs={12}
                  lg={3}
                  className="d-flex flex-col justify-content-end align-items-end"
                >
                  <Filter />
                </Col>
                <Col
                  xs={12}
                  sm={6}
                  lg={5}
                  className="d-flex flex-col justify-content-start align-items-start"
                >
                  <PaginationOptions labels={labels} />
                  <div style={{ marginTop: "5px" }}>
                    <InfoPill left="Total Modules" right={body?.length} />
                  </div>
                </Col>
                <Col
                  xs={12}
                  sm={6}
                  lg={4}
                  className="d-flex flex-col justify-content-end align-items-end"
                >
                  <Button
                    className="btn-light add-new-button"            //added by muskan khan
                    style={{ color: "black" }}  
                    variant=""
                    onClick={() => createModule()}
                  >
                    New Module
                  </Button>
                </Col>
              </Row>
              <Table striped className="data-table">
                <TableHeader />
                <TableBody />
              </Table>
              <Pagination />
            </DatatableWrapper>
          ) : (
            <ShimmerTable row={10} col={8} />
          )}
        </Col>
        <Col lg={2}></Col>
      </Row>
      <ToastContainer
      position="top-right"
      autoClose={2000}
      hideProgressBar={false}
      newestOnTop={false}
      closeOnClick
      rtl={false}
      pauseOnFocusLoss
      draggable
      pauseOnHover
      theme="light"
      />
    </>
  );
};
export default ModuleList;

// import React, { useEffect, useState } from "react";
// import { Button, Col, Row, Table } from "react-bootstrap";
// import { Link } from "react-router-dom";
// import {
//   DatatableWrapper,
//   Filter,
//   Pagination,
//   PaginationOptions,
//   TableBody,
//   TableHeader,
// } from "react-bs-datatable";
// import InfoPill from "../InfoPill";
// import { ShimmerTable } from "react-shimmer-effects";
// import schoolApi from "../../api/schoolApi";
// import CreateModuleModal from "./CreateModuleModal";
// import PubSub from "pubsub-js";
// import Confirm from "../Confirm";
// import { ToastContainer, toast } from "react-toastify";
// import "react-toastify/dist/ReactToastify.css";
// import authApi from "../../api/authApi";
// import { useOutletContext } from "react-router-dom";
// const ModuleList = () => {
//   const [body, setBody] = useState();
//   const [modalShow, setModalShow] = useState(false);
//   const [btnName, setBtnName] = useState("Save");

//   const [parentModules, setParentModules] = useState([]);
//   const [subModules, setSubModules] = useState([]);
//   const [moduleData, setModuleData] = useState();
//   const [showDeleteModal, setShowDeleteModal] = useState(false);
//   const [rowDataId, setRowDataId] = useState();
//   const [moduleRecords, setModuleRecords] = useOutletContext();

//   useEffect(() => {
//     fetchAllModules();
//   }, []);

//   async function fetchAllModules() {
//     try {
//       let selectedParentModules = [];
//       let selectedSubModules = [];
//       if (authApi.companyDetail().companyid) {
//         const result = await schoolApi.getAllModules(authApi.companyDetail().companyid);
//         if(result.success){
//             setBody(result?.result);
//             result?.result.forEach((element) => {
//               if (element.parent_module === null) {
//                 selectedParentModules.push(element);
//               } else if (element.parent_module !== null) {
//                 selectedSubModules.push(element);
//               }
//             });
//             setSubModules(selectedSubModules);
//             setParentModules(selectedParentModules);

//             result?.result?.forEach((element) => {
//               if (element.parent_module == null) {
//                 let subModule = [];
//                 result?.result?.forEach((res) => {
//                   if (res.parent_module === element.id) {
//                     subModule.push(res);
//                   }
//                   element.subModule = subModule;
//                 });
//               }
//             });
//             setModuleRecords(result);
//         } else{
//           setBody([]);
//           toast.error(result?.message)
//         }
//       }
//     } catch (error) {
//       console.log('SERVER_ERROR-->>' , error);
//       toast.error(error?.response?.data?.message)
//     }
//   }

//   const handleEditButton = (row) => {
//     setModalShow(true);
//     setModuleData(row);
//     setBtnName("Update");
//   };

//   const handleDeleteButton = (row) => {
//     setShowDeleteModal(true);
//     setRowDataId(row.id);
//   };

//   const header = [
//     { title: "Name", prop: "name", isFilterable: true },
//     { title: "Status", prop: "status", isFilterable: true },
//     {
//       title: "Action",
//       prop: "",
//       isFilterable: true,
//       cell: (row) => {
//         return (
//           <>
//             <Button
//               className="btn-sm mx-2 edit-button"
//               variant=""
//               onClick={() => handleEditButton(row)}
//             >
//               <i className="fa-regular fa-pen-to-square"></i>
//             </Button>
//             <button
//               className="btn btn-sm btn-danger mx-2"
//               onClick={() => handleDeleteButton(row)}
//             >
//               <i className="fa fa-trash"></i>
//             </button>
//           </>
//         );
//       },
//     },
//   ];

//   const labels = {
//     beforeSelect: " ",
//   };

//   const handleDeleteModuleRecord = async () => {
//     if (rowDataId) {
//       try {
//         const result = await schoolApi.deleteModule(rowDataId);
//         console.log("Result after deletion:", result);

//         if (result && result.success) {
//           const deleteModule = body.filter((rec) => rec.id !== rowDataId);
//           if (result?.result?.parent_module === null) {
//             console.log("hear parentmodule");
//             const deleteParentModules = parentModules.filter((rec) => rec.id !== rowDataId);
//             const newmodules = deleteParentModules.map((record, index) => {
//               return { ...record, order_no: index + 1 };
//             });
//             const upateresult = await schoolApi.updateAllParentmodule(newmodules);
//           } else {
//             console.log("hear submodule ->", subModules);
//             const deletesubModules = subModules.filter((rec) => rec.parent_module === result?.result?.parent_module);
//             const deleteaftersubModules = deletesubModules.filter((rec) => rec.id !== rowDataId);
//             console.log("deletesubModules ->", deleteaftersubModules);
//             const newmodules = deleteaftersubModules.map((record, index) => {
//               return { ...record, order_no: index + 1 };
//             });
//             const upateresult = await schoolApi.updateAllParentmodule(newmodules);
//             if (!upateresult.success) {
//               toast.error(upateresult.message);
//             }
//           }
//           console.log("Updated body after deletion:", deleteModule);
//           fetchAllModules();
//           toast.success("Record deleted successfully");
//           // setBody(deleteModule);
//           setShowDeleteModal(false);
//         } else {
//           console.error("deletion was not successful", result);
//         }
//       } catch (error) {
//         if ( error.response && error.response.data && error.response.data.errors) {
//           toast.error(error.response.data.errors);
//         } else {
//           toast.error("Unknown error occurred. Please try again.", {
//             style: { backgroundColor: "#f7040f", color: "white" },
//           });
//         }
//       }
//     }
//   };

//   const createModule = () => {
//     setModalShow(true);
//     setModuleData(null);
//     setBtnName("Save");
//   };

//   const handleCloseModal = () => {
//     setModalShow(false);
//   };

//   const [paginationOptions, setPaginationOptions] = useState({
//     rowsPerPage: 5,
//     options: [5, 10, 15, 20],
//   });

//   const handlePaginationChange = (newPaginationOptions) => {
//     setPaginationOptions(newPaginationOptions);
//   };

//   return (
//     <>
//       {showDeleteModal && (
//         <Confirm
//           show={showDeleteModal}
//           onHide={() => setShowDeleteModal(false)}
//           handleDelete={() => handleDeleteModuleRecord()}
//           title="Confirm delete?"
//           message="You are going to delete the record. Are you sure?"
//           table="deleteModule"
//         />
//       )}

//       <CreateModuleModal
//         modalShow={modalShow}
//         handleCloseModal={handleCloseModal}
//         fetchAllModules={fetchAllModules}
//         moduleData={moduleData}
//         btnName={btnName}
//       />

//       <Col lg={2} className="mx-4">
//         <Link className="nav-link" to="/">
//           Home <i className="fa-solid fa-chevron-right"></i> Module
//         </Link>
//       </Col>

//       <Row className="g-0">
//         <Col lg={12} className="px-lg-4">
//           {body ? (
//             <DatatableWrapper
//               body={body ? body : ""}
//               headers={header}
//               paginationOptionsProps={{
//                 initialState: paginationOptions,
//                 onChange: handlePaginationChange,
//               }}
//             >
//               <Row className="mb-4">
//                 <Col
//                   xs={12}
//                   lg={3}
//                   className="d-flex flex-col justify-content-end align-items-end"
//                 >
//                   <Filter />
//                 </Col>
//                 <Col
//                   xs={12}
//                   sm={6}
//                   lg={5}
//                   className="d-flex flex-col justify-content-start align-items-start"
//                 >
//                   <PaginationOptions labels={labels} />
//                   <div style={{ marginTop: "5px" }}>
//                     <InfoPill left="Total Modules" right={body?.length} />
//                   </div>
//                 </Col>
//                 <Col
//                   xs={12}
//                   sm={6}
//                   lg={4}
//                   className="d-flex flex-col justify-content-end align-items-end"
//                 >
//                   <Button
//                     className="btn-light add-new-button"            //added by muskan khan
//                     style={{ color: "black" }}  
//                     variant=""
//                     onClick={() => createModule()}
//                   >
//                     New Module
//                   </Button>
//                 </Col>
//               </Row>
//               <Table striped className="data-table">
//                 <TableHeader />
//                 <TableBody />
//               </Table>
//               <Pagination />
//             </DatatableWrapper>
//           ) : (
//             <ShimmerTable row={10} col={8} />
//           )}
//         </Col>
//         <Col lg={2}></Col>
//       </Row>
//       <ToastContainer
//       position="top-right"
//       autoClose={2000}
//       hideProgressBar={false}
//       newestOnTop={false}
//       closeOnClick
//       rtl={false}
//       pauseOnFocusLoss
//       draggable
//       pauseOnHover
//       theme="light"
//       />
//     </>
//   );
// };
// export default ModuleList;