import React, { useEffect, useState } from "react";
import { Button, Col, Form, Row, Table } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import schoolApi from "../../api/schoolApi";
import { ShimmerTable } from "react-shimmer-effects";
import moment from "moment";
import InfoPill from "../InfoPill";
import {
  DatatableWrapper,
  Filter,
  Pagination,
  PaginationOptions,
  TableBody,
  TableHeader,
} from "react-bs-datatable";
import { Link } from "react-router-dom";

import { Helmet } from "react-helmet";
import PageNavigations from "../breadcrumbs/PageNavigations";
import Confirm from "../Confirm";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer, toast } from "react-toastify";

const StudentList = (props) => {
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [rowDataId, setRowDataId] = useState();
  const navigate = useNavigate();
  const [leads, setLeads] = React.useState([]);
  const [body, setBody] = useState();

  useEffect(() => {
    init();
  }, []);

  async function init() {
    try {
      const result = await schoolApi.fetchlead();
      console.log("resulttlead=>", result?.leads);
      if (result.success) {
        setBody(result?.leads);
        setLeads(result?.leads);
      }
    } catch (error) {
      // toast.error(error?.response?.data?.message);
      setBody([]);
      setLeads([]);
    }
  }

  const handleEditButton = (row) => {
    console.log("row=======>", row);
    navigate(`/studentenquiry/${row.id}/e`, { state: row });
  };

  const handleDeleteButton = (row) => {
    console.log("Curent Row", row);
    if (row.id && row.status === "Registered") {
      toast.error("Record is registered, you can't delete this record!");
    } else {
      setShowDeleteModal(true);
      setRowDataId(row);
    }
  };

  const handleDeleteModuleRecord = async () => {
    console.log("handleDeleteModuleRecord =>", rowDataId);
    if (rowDataId) {
      try {
        console.log("rowDataId ??????????=>", rowDataId);
        if (rowDataId.id && rowDataId.status === "Not Registered") {
          const result = await schoolApi.deletelead(rowDataId.id);
          console.log("Data Result after deletion:", result);
          if (result && result.message) {
            const deleteStudent = body.filter((rec) => rec.id !== rowDataId.id);
            init();
            toast.success(result.message);
            setBody(deleteStudent);
            setShowDeleteModal(false);
          } else {
            console.error("deletion was not successful");
          }
        }
      } catch (error) {
        console.log("Delate==>", error);
        toast.error(error?.response?.data?.message);
      }
    }
  };

  const handleReg = async (row) => {    
    if (row.status === "Registered") {
      toast.error("Lead is already regiestered!!!");
    } else {
      console.log("row ->", row);

      navigate(`/student/e`, { state: row });
    }
  };

  const onFilterType = (event) => {
    if (event.target.value === "") {
      console.log("inside the ifff");
      setBody(leads);
    } else {
      console.log("inside the elsee");
      setBody(
        leads.filter((data) => {
          if (
            (data.recordtype || "").toLowerCase() ===
            (event.target.value || "").toLowerCase()
          ) {
            return data;
          }
          if (
            (data.location || "").toLowerCase() ===
            (event.target.value || "").toLowerCase()
          ) {
            return data;
          }
          if (
            (data.religion || "").toLowerCase() ===
            (event.target.value || "").toLowerCase()
          ) {
            return data;
          }
        })
      );
    }
  };

  const header = [
    {
      title: "Student Name",
      prop: "firstname",
      isFilterable: true,
      cell: (row) => (
        <Link to={"/students/" + row.id} state={row}>
          {row.firstname + " " + row.lastname}
        </Link>
      ),
    },
    { title: "Class Name", prop: "classname", isFilterable: true },
    { title: "Phone", prop: "phone", isFilterable: true },
    { title: "Email", prop: "email", isFilterable: true },
    {
      title: "Address",
      prop: "address",
      isFilterable: true,
      cell: (row) => (
        <span>
          {[row.street, row.city, row.state, row.country]
            .filter(Boolean)
            .join(", ")}
        </span>
      ),
    },
    { title: "Status", prop: "status", isFilterable: true },
    {
      title: "Action",
      prop: "isactive",
      isFilterable: true,
      cell: (row) => (
        <>
          <button
            className="btn btn-sm btn-danger mx-2"
            onClick={() => handleDeleteButton(row)}
          >
            <i className="fa fa-trash"></i>
          </button>
           {/* ------------ code 19-nov-24 sarfraj -------------- */}
          {row.status === "Not Registered" && (
            <>
              <Button
                className="btn-sm mx-2 edit-button"
                variant=""
                onClick={() => handleEditButton(row)}
                >
                <i className="fa-regular fa-pen-to-square"></i>
              </Button>
              
              <Button
                className="btn-sm mx-2 btnHoverAtt"
                variant="primary"
                onClick={() => handleReg(row)}
              >
                <i className="fa-solid fa-users"></i>
                <span class="tooltiptextAtt">Registration</span>
              </Button>
            </>
        )}
        </>
      ),
    },
  ];

  const labels = {
    beforeSelect: " ",
  };

  const createStudent = () => {
    console.log("createStudent list");
    navigate(`/studentenquiry`);
  };

  return (
    <>
       <Helmet>
        <title>{props?.tabName}</title>
      </Helmet>
      
      {showDeleteModal && (
        <Confirm
          show={showDeleteModal}
          onHide={() => setShowDeleteModal(false)}
          deleteLead={() => handleDeleteModuleRecord()}
          title="Confirm delete?"
          message="You are going to delete the record. Are you sure?"
          table="lead"
        />
      )}
      <Row className="g-0">
        <Col lg={5} className="mx-4" style={{ marginTop: "7px" }}>
          <Link className="nav-link" to="/">
            Home{" "}
            <i
              className="fa-solid fa-chevron-right"
              style={{ marginLeft: "1px", marginRight: "1px" }}
            ></i>{" "}
            Students Enquiry
          </Link>
        </Col>

        <Col lg={12} className="px-lg-4">
          {body ? (
            <DatatableWrapper
              body={body}
              headers={header}
              paginationOptionsProps={{
                initialState: {
                  rowsPerPage: 15,
                  options: [5, 10, 15, 20],
                },
              }}
            >
              <Row className="mb-4">
                <Col
                  xs={12}
                  lg={3}
                  className="d-flex flex-col justify-content-end align-items-end"
                >
                  <Filter />
                </Col>
                <Col
                  xs={12}
                  sm={5}
                  lg={5}
                  className="d-flex flex-col justify-content-start align-items-start"
                >
                  <Form.Group className="mt-4 mx-2" controlId="formBasicStatus">
                    <Form.Select
                      aria-label="Enter status"
                      name="religion"
                      onChange={onFilterType}
                    >
                      <option value="">--Select Religion--</option>
                      <option value="Muslim">Muslim</option>
                      <option value="Hindu">Hindu</option>
                      <option value="Christian">Christian</option>
                      <option value="Sikh">Sikh</option>
                      <option value="Jain">Jain</option>
                      <option value="Other">Other</option>
                    </Form.Select>
                  </Form.Group>
                  <PaginationOptions labels={labels} />
                  <div style={{ marginTop: "5px" }}>
                    <InfoPill left="Total Enquiries" right={body?.length} />
                  </div>
                </Col>
                <Col
                  xs={12}
                  sm={6}
                  lg={4}
                  className="mb-2 d-flex flex-col justify-content-end align-items-end"
                >
                  <Button
                    className="btn-sm add-new-button"
                    variant=""
                    onClick={() => createStudent(true)}
                  >
                    New Enquiry
                  </Button>
                </Col>
              </Row>
              <Table striped className="data-table">
                <TableHeader />
                <TableBody />
              </Table>
              <Pagination />
            </DatatableWrapper>
          ) : (
            <ShimmerTable row={10} col={8} />
          )}
        </Col>
        <Col lg={2}></Col>
      </Row>
      <ToastContainer
        position="top-right"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </>
  );
};
export default StudentList;
