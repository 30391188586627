// /**
//  * @author: Pawan Singh Sisodiya
//  */

// ----Last updated by pankaj on 31 Aug 2024----
import React, { useEffect, useState } from "react";

import schoolApi from "../../api/schoolApi";
import "./FeeReceipt.css";
import "react-toastify/dist/ReactToastify.css";
import { Col, Row } from "react-bootstrap";

const FeeReceipt = React.forwardRef(({ receiptData }, receiptRefs) => {
  const [studentRecord, setStudentRecord] = useState();

  console.log("studentRecord-->", studentRecord);
  const [headRecords, setHeadRecords] = useState([]);
  const [depoMonths, setMonths] = useState([]);
  const [depositRecords, setdepositRecords] = useState([]);

  useEffect(() => {
    if (receiptData) {
      async function init() {
        try {
          let session = receiptData?.sessionid
            ? receiptData?.sessionid
            : receiptData?.session_id;
          const result = await schoolApi.fetchFeeDepositsByStudentId(
            receiptData?.student_addmission_id,
            session,
            receiptData?.deposit_id
          );
          console.log("deposit by student Admission id-->", result);

          if (!result || result.success === false) {
            return;
          }

          const depoRecords = result?.result[0];
          setdepositRecords(result?.result[0]);
          let netPayableAmount = 0;
          let headArray = [];
          let totalTransportfee = 0;
          let installmentTotalDueAmount = 0;
          let months = [];
          console.log(
            "depoRecords?.installments ->",
            depoRecords?.installments
          );

          depoRecords?.installments?.forEach((res) => {
            if (!depoMonths.some((depo) => depo.month === res.month)) {
              depoMonths.push(res.month);
            }

            res.heads.forEach((head) => {
              console.log("head-pp->", head);
              const existingHead = headArray.find(
                (prev) => prev.headid === head.headid
              );
              console.log("existingHead-->", existingHead);

              if (existingHead) {
                existingHead.head_amount += parseInt(head.head_amount);

                head?.concession?.forEach((heds) => {
                  const existingDiscount = existingHead.discount.find(
                    (exds) => exds.discountid === heds.discountid
                  );
                  if (existingDiscount) {
                    existingDiscount.concession_value += heds.concession_value;
                  } else {
                    existingHead.discount.push(heds);
                  }
                });
              } else {
                headArray.push({
                  ...head,
                  head_amount: parseInt(head.head_amount),
                  month: depoMonths,
                  discount: head?.concession,
                });
              }
            });

            months.push({ month: res.month });
            installmentTotalDueAmount += parseInt(res.installment_due_amount);
            totalTransportfee += parseInt(res.transport_fee);
            netPayableAmount +=
              parseInt(res.installment_amount) +
              parseInt(res.transport_fee) +
              parseInt(depoRecords?.late_fee) +
              parseInt(depoRecords?.previous_due);
          });

          console.log("months-->", months);
          console.log("headArray-->", headArray);

          headArray.push({
            headname: "Transport Fee",
            head_amount: totalTransportfee,
          });
          headArray.push({
            headname: "Late Fee",
            head_amount: depoRecords?.late_fee,
          });
          headArray.push({
            headname: "Previous Due",
            head_amount: depoRecords?.previous_due,
          });

          depoRecords.due_amount = installmentTotalDueAmount;
          depoRecords.netPayableAmount = netPayableAmount;

          setMonths(months);
          setStudentRecord(depoRecords);
          setHeadRecords(headArray);
        } catch (error) {
          console.log("error ->", error);
        }
      }
      init();
    }
  }, [receiptData]);

  const convertNumberToWords = (num) => {
    const a = [
      "",
      "One",
      "Two",
      "Three",
      "Four",
      "Five",
      "Six",
      "Seven",
      "Eight",
      "Nine",
      "Ten",
      "Eleven",
      "Twelve",
      "Thirteen",
      "Fourteen",
      "Fifteen",
      "Sixteen",
      "Seventeen",
      "Eighteen",
      "Nineteen",
    ];
    const b = [
      "",
      "",
      "Twenty",
      "Thirty",
      "Forty",
      "Fifty",
      "Sixty",
      "Seventy",
      "Eighty",
      "Ninety",
    ];
    const g = ["", "Thousand", "Million", "Billion", "Trillion"];

    if ((num = num.toString()).length > 15) return "too large";
    let n = ("000000000000000" + num).substr(-15).match(/.{3}/g);
    let str = "";
    for (let i = 0; i < n.length; i++) {
      let hundreds = parseInt(n[i][0]);
      let tens = parseInt(n[i][1]);
      let units = parseInt(n[i][2]);
      let word = "";

      if (hundreds > 0) {
        word += a[hundreds] + " hundred ";
      }

      if (tens > 1) {
        word += b[tens] + " " + a[units];
      } else {
        word += a[10 * tens + units];
      }

      if (word.trim()) {
        str += word + " " + g[n.length - 1 - i] + " ";
      }
    }
    return str.replace(/\s+/g, " ").trim();
  };
  console.log("headRecords ->", headRecords);

  const totalFees = headRecords?.reduce(
    (acc, item) => acc + item?.head_amount,
    0
  );
  const totalDiscount = headRecords.reduce((total, ele) => {
    if (ele.concession) {
      console.log("ele ->", ele);

      const amount = ele?.concession?.reduce(
        (acc, item) => acc + parseInt(item?.concession_value) || 0, // Ensure we handle NaN
        0
      );

      console.log("amount -> ", amount);
      return total + amount; // Accumulate the total
    }
    return total; // If there's no headid, return the total unchanged
  }, 0);

  console.log("Total Discount ->", totalDiscount);
  console.log("depositRecords Discount ->", depositRecords.discount);
  console.log("headRecords->", headRecords);

  return (
    <>
      <div className="fee-receipt-container-receipt">
        {studentRecord ? (
          <div
            id="reportContent"
            ref={receiptRefs}
            className="fee-receipt-receipt"
          >
            {["Office Copy", "Parent Copy"].map((copyType, idx) => (
              <div key={idx} className="receipt-copy-receipt">
                <header className="header-receipt">
                  <Row>
                    <Col lg={3}>
                      <img
                        src="/comonLogoSchool.png"
                        style={{ width: "100px", height: "100px" }}
                        alt=""
                      />
                    </Col>
                    <Col lg={6}>
                      <h1>{studentRecord.school_name}</h1>
                      <p>
                        {studentRecord.school_address},<br />
                        State - {studentRecord.state}, {studentRecord.country},
                        <br />
                        Pin code - {studentRecord.pincode}
                      </p>
                    </Col>
                    <Col lg={3}>
                      <p className="float-end">{copyType}</p>
                    </Col>
                  </Row>
                </header>
                {/* <hr /> */}
                <h4 class="text-center border-dark border-top border-bottom container-fluid">
                  Fee Receipt ({studentRecord.session})
                </h4>
                {/* <hr /> */}
                <div className="receipt-info-receipt">
                  <div className="info-left-receipt">
                    <p>
                      <strong>Receipt No :</strong>{" "}
                      {studentRecord.receipt_number}
                    </p>
                    <p>
                      <strong>Student Name :</strong>{" "}
                      {studentRecord.studentname}
                    </p>
                    <p>
                      <strong>Registration No :</strong>{" "}
                      {studentRecord.registration_id}
                    </p>
                    <p>
                      <strong>Mobile No :</strong> {studentRecord.phone}
                    </p>
                  </div>
                  <div className="info-right-receipt">
                    <p>
                      <strong>Receipt Date :</strong>{" "}
                      {studentRecord.payment_date}
                    </p>
                    <p>
                      <strong>Parent's Name :</strong>{" "}
                      {studentRecord.parent_name}
                    </p>
                    <p>
                      <strong>Class :</strong> {studentRecord.classname} (
                      {studentRecord.sectionname})
                    </p>
                    <p>
                      <strong>Parent's Contact :</strong>{" "}
                      {studentRecord.parentphone}
                    </p>
                  </div>
                </div>
                <table className="table-receipt">
                  <thead>
                    <tr>
                      <th>S. No.</th>
                      <th>Particulars</th>
                      <th>Due Month</th>
                      <th>Due Amount</th>
                      <th>Concession Amount</th>
                      <th>Paid Amount</th>
                    </tr>
                  </thead>
                  <tbody>
                    {headRecords?.map((record, index) =>
                      record.head_amount !== 0 ? (
                        <tr key={index}>
                          <td>{index + 1}</td>
                          <td>{record.headname}</td>
                          {/* <td>{record.month}</td> */}
                          <td className="month">
                            {record?.month?.map((mon, idx) => (
                              <span key={idx}>{`${mon} `}</span>
                            ))}
                          </td>
                          <td className="amount">{record.head_amount}</td>
                          <td>
                            {record?.discount?.map((res, idx) => (
                              <span
                                key={idx}
                              >{`${res.discount_name}(${res.concession_value}) `}</span>
                            ))}
                          </td>
                          <td className="amount">
                            {record?.discount
                              ? record?.discount?.map((res, idx) => (
                                  <span key={idx}>
                                    {record.head_amount - res.concession_value}
                                  </span>
                                ))
                              : record.head_amount}
                          </td>
                        </tr>
                      ) : null
                    )}
                  </tbody>
                  <tfoot>
                    {/* <tr>
                      <td colSpan="4" className="text-right">
                        <strong>TOTAL Amount (in Rs):</strong>
                      </td>
                      <td className="amount">
                        <strong>{totalDiscount}</strong>
                      </td>
                      <td className="amount">
                        <strong>{totalFees}</strong>
                      </td>
                    </tr> */}
                    <tr>
                      <td colSpan="3" className="text-right">
                        <strong>Total Amount (in Rs):</strong>
                      </td>
                      <td className="amount text-right">
                        <strong>{totalFees}</strong>
                      </td>
                      <td className="amount text-right">
                        <strong>{totalDiscount}</strong>
                      </td>
                      <td className="amount">
                        <strong>{totalFees - totalDiscount}</strong>
                      </td>
                    </tr>
                    <tr>
                      <td colSpan="5" className="text-right">
                        <strong>Deposited Amount (in Rs):</strong>
                      </td>
                      <td className="amount">
                        <strong>{studentRecord.amount}</strong>
                      </td>
                    </tr>
                    {totalFees - totalDiscount - studentRecord.amount ? (
                      <tr>
                        <td colSpan="5" className="text-right">
                          <strong>Due Amount (in Rs):</strong>
                        </td>
                        <td className="amount">
                          <strong>
                            {totalFees - totalDiscount - studentRecord.amount}
                          </strong>
                        </td>
                      </tr>
                    ) : null}
                    <tr>
                      <td colSpan="6" className="text-right">
                        <strong className="text-capitalize">
                          Amount In Words: Rupees{" "}
                          {convertNumberToWords(studentRecord.amount)} Only.
                        </strong>
                      </td>
                    </tr>
                    <tr>
                      <td colSpan="6" className="text-right">
                        <strong>Payment Mode:</strong>{" "}
                        {studentRecord.payment_method} |
                        {studentRecord.transaction_no ? (
                          <>
                            <strong>Cheque No. / Transaction Id:</strong>{" "}
                            {studentRecord.transaction_no} |
                          </>
                        ) : null}
                        <strong>Cheque Date / Transaction Date:</strong>{" "}
                        {studentRecord.transaction_date}
                      </td>
                    </tr>
                  </tfoot>
                </table>

                <div className="remarks-receipt">
                  <p>
                    <strong>Remark:</strong> {studentRecord.remark}
                  </p>
                </div>
                <div className="footer-receipt">
                  <p>
                    Generated by {studentRecord.generated_by} on{" "}
                    {studentRecord.generated_date}
                  </p>
                  <p>
                    <strong>NOTE:</strong> FEE IS NOT REFUNDABLE (EXCEPT CAUTION
                    FEE)
                  </p>
                </div>
              </div>
            ))}
            <div className="dotted-line"></div>
          </div>
        ) : (
          <p>No Receipt Found</p>
        )}
      </div>
    </>
  );
});

export default FeeReceipt;
