import React, { useState, useEffect } from "react";
import { ShimmerTable } from "react-shimmer-effects";
import moment from "moment";
import { Table } from "react-bootstrap";
import schoolApi from "../../../api/schoolApi";
import { DatatableWrapper, Pagination, TableBody, TableHeader, } from "react-bs-datatable";

function BookRelatedIssueDetail(props) {
  const [parentBody, setParentBody] = useState([]);

  const header = [
    { title: "Issued By", prop: "parent_name", isFilterable: true },
    { title: "Registration Id", prop: "parent_eid", isFilterable: true },
    { title: "Type", prop: "parent_type" },
    { title: "Status", prop: "status", isFilterable: true },
    {
      title: "Issued Date",
      prop: "checkout_date",
      isFilterable: true,
      cell: (row) => moment(row.checkout_date).format("DD-MMM-YYYY"),
    },
    {
      title: "Due Date",
      prop: "due_date",
      isFilterable: true,
      cell: (row) => moment(row.due_date).format("DD-MMM-YYYY"),
    },
  ];

  useEffect(() => {
    fetchBook();
  }, []);

  const fetchBook = async () => {
    /*------ Update by mohd. sarfraj | 16-sept-2024. | Fixed bug 500 error | 18-sept-2024. ------*/
    try {
      const result = await schoolApi.getIssueRecordsByBookId(props?.bookId);
      console.log('result-inBookRelatedIssueDetail-->>' , result);
      if(result.success){
        setParentBody(result?.issue);
      } else {
        setParentBody([]);
      }
    } catch (error) {
      console.log('Server-Error-->>' , error);
    }
    
  };

  return (
    <div>
      {parentBody.length > 0 ? (
        <DatatableWrapper body={parentBody} headers={header}>
          <Table striped className="data-table custom-table-subject-list">
            <TableHeader />
            <TableBody />
          </Table>
          <Pagination />
        </DatatableWrapper>
      ) : (
         //------- Updated By mohd. sarfraj | 16-sept-2024 -------
         <div>
         <DatatableWrapper body={parentBody} headers={header}>
           <Table striped className="data-table custom-table-subject-list">
             <TableHeader />
             <TableBody>
               <tr>
                 <td colSpan={6} className="text-center">No Records Found!!!</td>
               </tr>
             </TableBody>
           </Table>
           <Pagination />
         </DatatableWrapper>
       </div>
      )}
    </div>
  );
}
export default BookRelatedIssueDetail;