import React, { createContext, useState, useContext, useEffect } from "react";
import schoolApi from "../../api/schoolApi";
import jwt_decode from "jwt-decode";

const PermissionContext = createContext();

export const usePermissions = () => {
  return useContext(PermissionContext);
};

export const PermissionProvider = ({ children }) => {
  const [rolePermissions, setRolePermissions] = useState(null);
  const [loading, setLoading] = useState(true);

  const fetchPermissions = async () => {
    console.log("fetchPermissions");
    try {
      const moduleIdsStringresult = await schoolApi.getRolePermissionByIds();
      console.log("fetchPermissions-->>>>", moduleIdsStringresult);
      if (moduleIdsStringresult.success) {
        setRolePermissions(moduleIdsStringresult);
      }
    } catch (error) {
      console.error("Error fetching permissions:", error);
    } finally {
      setLoading(false);
    }
  };

  const getUserInfoFromToken = () => {
    const token = sessionStorage.getItem("token");
    console.log(token, "WAIT ## 2");
    if (token) {
      try {
        const decodedToken = jwt_decode(token);
        return decodedToken;
      } catch (error) {
        console.error("Error decoding token:", error);
        return null;
      }
    } else {
      return null;
    }
  };

  useEffect(() => {
    const handleLocationChange = () => {
      if (window.location.pathname.endsWith("/login")) {
        console.log(
          "Skipping fetchPermissions because the URL ends with /login"
        );
        return;
      }
      fetchPermissions();
    };

    // Fetch permissions on component mount if not /login
    if (!window.location.pathname.endsWith("/login")) {
      fetchPermissions();
    }

    // Add event listener for location changes
    window.addEventListener("popstate", handleLocationChange);

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener("popstate", handleLocationChange);
    };
  }, []); // Empty dependency array ensures this runs only once when the component mounts

  const userInfo = getUserInfoFromToken();

  console.log(rolePermissions, "WAIT ## 1");

  return (
    <PermissionContext.Provider value={{ rolePermissions, loading, userInfo }}>
      {children}
    </PermissionContext.Provider>
  );
};
