import React, { useEffect, useState } from 'react';
import Main from '../layout/Main';
import { Alert, Button, Col, Row, Table } from "react-bootstrap";
import schoolApi from '../../api/schoolApi';
import { ShimmerTable } from 'react-shimmer-effects';
import { DatatableWrapper, Filter, Pagination, PaginationOptions, TableBody, TableHeader, } from "react-bs-datatable";
import { useNavigate, Link } from 'react-router-dom';
import Confirm from '../Confirm';
import LocationMaster from "./LocationMaster";
import InfoPill from '../InfoPill';
// import PubSub from "pubsub-js"
// import { Toast , ToastContainer } from "react-bootstrap";
// import { toast } from "react-toastify";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { usePermissions } from "../contexts/PermissionContext"; //Add
import { Helmet } from 'react-helmet';
const LocationMasterList = (props) => {
  const navigate = useNavigate();
  const { rolePermissions, userInfo } = usePermissions();
  console.log('rolePermissions-->',rolePermissions);
  const [modalShow, setModalShow] = useState(false);
  const [locations, setLocations] = useState([]);
  const [successMessage, setSuccessMessage] = useState(null);
  const [isModal, setIsModal] = useState(false);
  const [btnName, setBtnName] = useState('Create Location');
  const [title, setTitle] = useState('Create Location');
  const [locationToUpdate, setlocationToUpdate] = useState(null);
  const [selectedLocationId, setSelectedLocationId] = useState(null); // Add state to track the selected location ID
  const [isUpdateMode, setIsUpdateMode] = useState(false);

  const canCreate = rolePermissions?.Location_List?.can_create;
  const canEdit = rolePermissions?.Location_List?.can_edit;
  const canDelete = rolePermissions?.Location_List?.can_delete;

  const fetchUpdatedLocations = async () => {
    try {
      const locationData = await schoolApi.getAllLocation();
      if (locationData.success) {
        setLocations(locationData.result);
      }
    } catch (error) {
      return toast.error(error.response.data.message)
    }
  };

  useEffect(() => {
    fetchUpdatedLocations()
  }, []);


  const handleEditButton = (row) => {
    setlocationToUpdate(row);
    setIsModal(true);

    setBtnName('Update');
    setTitle('Update Location');


  }
  const handleDeleteButton = (row) => {
    setSelectedLocationId(row.id);

    setModalShow(true);

  }
  const handleDeleteAndNavigate = async () => {
    if (selectedLocationId) {
      try {
        const response = await schoolApi.deleteLocation(selectedLocationId);
        console.log("deleteLocation == ",response)
        if (response && response.message === "Location deleted successfully") {
          const updatedLocations = locations.filter(location => location.id !== selectedLocationId);
          setLocations(updatedLocations);

          setModalShow(false);
          navigate('/transportation/locationlist');
          toast.success("Record delete successfully");
        } else {
          console.error('Deletion was not successful:', response);
        }
      } catch (error) {
        return toast.error(error.response.data.message)
    }
    }
  };

  const header = [
    // { title: "ID", prop: "id", isFilterable: true },
    { title: "Locations", prop: "location", isFilterable: true },
    { title: "Distance(km)", prop: "distance", isFilterable: true },
    {
      title: "Status",
      prop: "status",
      isFilterable: true,
      cell: (row) => {
        return row.status === "Active" ? "Active" : "InActive";
      },
    },
    {
      title: 'Action', prop: '', isFilterable: true,
      show: userInfo?.userrole === "SYS_ADMIN" || userInfo?.userrole === "ADMIN"? true: canEdit || canDelete,
      cell: (row) => (
        <div>
          {(userInfo?.userrole === "SYS_ADMIN" || userInfo?.userrole === "ADMIN" || canEdit) &&
          <button className="btn btn-sm edit-button mx-2" onClick={() => handleEditButton(row)}>
            <i className="fa-regular fa-pen-to-square"></i>
          </button>
    }
    {(userInfo?.userrole === "SYS_ADMIN" || userInfo?.userrole === "ADMIN" || canDelete) &&
          <button className="btn btn-sm btn-danger mx-2" onClick={() => handleDeleteButton(row)}>
            <i className="fa-regular fa-trash-can"></i>
          </button>
    }
        </div>
      )
    },
  ];
  const labels = {
    beforeSelect: " ",
  };

  const handleShowModal = () => {
    setIsUpdateMode(false); // Set isUpdateMode to false
    setBtnName('Create Location');
    setTitle('Create Location');
    setIsModal(true);
  };

  const handleCloseModal = () => {
    setIsModal(false);
    fetchUpdatedLocations();

  };

  const handleLocationCreated = () => {
    // Close the modal and navigate to the farelist
    setIsModal(false);
    fetchUpdatedLocations();
    // navigate('/transportation/locationlist');
    // window.location.reload();
  };



  return (
    <>
    <Helmet>
        <title>{props?.tabName}</title>
      </Helmet>
      <ToastContainer
        position="top-right"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      {modalShow && (
        <Confirm
          show={modalShow}
          onHide={() => setModalShow(false)}
          handleDeleteButton={() => handleDeleteAndNavigate()}
          title="Confirm delete?"
          message="You are going to delete the record. Are you sure?"
          table="locationMaster"
        />
      )}

      {isModal && (
        <LocationMaster
          show={isModal}
          handleCloseModal={handleCloseModal}
          onLocationCreated={handleLocationCreated}
          locationData={locationToUpdate} // Pass the fare data to update
          isUpdateMode={isUpdateMode}
          btnName={btnName}
          title={title}
        ></LocationMaster>
      )
      }



      {successMessage && (
        <Alert variant="success" onClose={() => setSuccessMessage(null)} dismissible>
          {successMessage}
        </Alert>
      )}

      <Row className="g-0">
        <Col lg={3} className="mx-3">
          <Link className="nav-link mx-2" to="/">Home <i className="fa-solid fa-chevron-right"></i> LocationList</Link>
        </Col>
        <Col lg={12} className="px-lg-4">
          {locations ? (
            <DatatableWrapper
              body={locations}
              headers={header}
              paginationOptionsProps={{
                initialState: {
                  rowsPerPage: 15,
                  options: [5, 10, 15, 20],
                },
              }}

            >
              <Row className="mb-4">
                <Col
                  xs={12}
                  lg={3}
                  className="d-flex flex-col justify-content-end align-items-end"
                >
                  <Filter />
                </Col>
                <Col
                  xs={12}
                  sm={6}
                  lg={5}
                  className="d-flex flex-col justify-content-start align-items-start"
                >
                  <PaginationOptions labels={labels} />
                  <div style={{ "marginTop": "5px" }}>
                    <InfoPill left="Total Locations" right={locations?.length} />

                  </div>

                </Col>
                <Col
                  xs={12}
                  sm={6}
                  lg={4}
                  className="d-flex flex-col justify-content-end align-items-end"
                >
                  {(userInfo?.userrole === "SYS_ADMIN" || userInfo?.userrole === "ADMIN" || canCreate) &&
                    <Button
                      className="btn add-new-button"
                      variant=""
                      onClick={() => handleShowModal()}
                    >
                      New Location
                    </Button>
                  }
                </Col>
              </Row>
              <Table striped className="data-table">
                <TableHeader />
                <TableBody />
              </Table>
              <Pagination />
            </DatatableWrapper>
          ) : (
            <ShimmerTable row={10} col={8} />
          )}
        </Col>
        <Col lg={2}></Col>
      </Row>
    </>
  );
};

export default LocationMasterList;