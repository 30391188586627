import React, { useEffect, useState } from 'react';
import { Button, Col, Form, Modal, Row, Container } from 'react-bootstrap';
import Select from 'react-select';
import schoolApi from '../../api/schoolApi';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


const VehicleUpdate = ({ show, onHide, onSuccess, selectedVehicle, onUpdateRec }) => {
    
    console.log("selectedVehicleId", selectedVehicle);

    console.log("selectedVehicleId", selectedVehicle.id);
    const id = selectedVehicle.id
    const [formData, setFormData] = useState({
        driver_id: null,
        vehicle_no: '',
        type: '',
        seating_capacity: '',
        status: 'Active',
        end_point: null,
    });

    const [driverOptions, setDriverOptions] = useState([]);
    const [locationOptions, setLocationOptions] = useState([]);


    useEffect(() => {
        if (selectedVehicle) {

            setFormData({
                driver_id: {
                    value: selectedVehicle.driver_id || "",
                    label: selectedVehicle.driver_name || "",
                },
                vehicle_no: selectedVehicle.vehicle_no || "",
                type: selectedVehicle.type || "",
                seating_capacity: selectedVehicle.seating_capacity || "",
                status: selectedVehicle.status || "",
                end_point: {
                    value: selectedVehicle.end_point|| "",
                    label: selectedVehicle.location_name || "",
                },
            });
        }
    }, [selectedVehicle]);
    console.log('setFormData=======????', formData);

    useEffect(() => {
        async function fetchDrivers() {
            try {
                const drivers = await schoolApi.fetchDrivers();
                console.log("Drivers == ",drivers)
                if(drivers.success){
                const driverOptions = drivers.drivers.map((driver) => ({//changes by shahir hussain 01-10-2024
                    value: driver.id,
                    label: driver.firstname,
                }));
                setDriverOptions(driverOptions);
            }
            } catch (error) {
                console.error('Error fetching drivers:', error);
                setDriverOptions([]);
                return toast.error(error.response.data.message)
            }
        }

        fetchDrivers();
    }, []);

    useEffect(() => {
        async function fetchLocations() {
            try {
                const locations = await schoolApi.getAllLocation();
                console.log("Locations == ",locations)
                if(locations.success){
                    const locationOptions = locations.result.map((location) => ({
                        value: location.id,
                        label: location.location,
                    }));
                    locationOptions.unshift({ value: null, label: 'Select Driver' });
                    setLocationOptions(locationOptions);
                }
            } catch (error) {
                console.error('Error fetching locations:', error);
                return toast.error(error.response.data.message)
            }
        }

        fetchLocations();
    }, []);

    const handleSubmit = async (e) => {
        e.preventDefault();
        console.log("formData update == ",formData.driver_id)
        console.log("formData update id == ",formData.driver_id.value)

        if (!formData.type || !formData.vehicle_no) {
            toast.error('Missing required fields');
            return;
        }

        try {
            const result =   await schoolApi.updateVehicle(id, {
                driver_id: formData.driver_id.value ? formData.driver_id.value : formData.driver_id,
                vehicle_no: formData.vehicle_no,
                type: formData.type,
                seating_capacity: formData.seating_capacity,
                status: formData.status,
                end_point: formData.end_point.value ? formData.end_point.value : formData.end_point,
            });
            console.log("result update == ",result)
            if (result.success) {
                toast.success(result.message);
                    onSuccess();
                    onHide();
                    onUpdateRec();
                }
        } catch (error) {
            console.log("error == ",error.response.data.message)
            return toast.error(error.response.data.message)
        }
        };

    const handleChange = (e) => {
        console.log("form data == ",e.target.value)
        console.log("form data == ",e.target.name)
        console.log("form data == ",e.target)
        
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    return (
        <Modal show={show} aria-labelledby="contained-modal-title-vcenter" centered onHide={onHide}>
            <Modal.Header closeButton>
                <Modal.Title>Update Vehicle</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Container className="view-form">
                    <Form onSubmit={handleSubmit} noValidate>
                        <Row>
                            <Col lg={6}>
                                <Form.Group>
                                    <Form.Label>Vehicle Type</Form.Label>
                                    <Form.Select
                                        required
                                        placeholder="Select Driver"
                                        name="type"
                                        value={formData.type}
                                        onChange={handleChange}
                                    >
                                        <option value="">-- Select --</option>
                                        <option value="bus">Bus</option>
                                        <option value="van">Van</option>
                                    </Form.Select>
                                </Form.Group>
                            </Col>
                            <Col lg={6}>
                                <Form.Group>
                                        <Form.Label>Vehicle Driver</Form.Label>
                                        <Form.Select
                                            placeholder="Select Driver"
                                            name="driver_id"
                                            onChange={handleChange}
                                            value={formData.driver_id?.value !== null ? formData.driver_id?.value : formData.driver_id}>
                                            {driverOptions.map((res) => (
                                                <option key={res.value} value={res.value}>
                                                    {res.label ? res.label :formData.driver_id  }
                                                </option>
                                            ))}
                                        </Form.Select>
                                    </Form.Group>

                            </Col>
                        </Row>
                        <Row>
                            <Col lg={6}>
                                <Form.Group>
                                    <Form.Label>Vehicle Number</Form.Label>
                                    <Form.Control
                                        required
                                        type="text"
                                        name="vehicle_no"
                                        placeholder="Enter Vehicle Number"
                                        value={formData.vehicle_no}
                                        onChange={handleChange}
                                    />
                                </Form.Group>
                            </Col>
                            <Col lg={6}>
                                {/* <Form.Group>
                                    <Form.Label>End Point</Form.Label>
                                    <Select
                                        placeholder="Select End point"
                                        name="end_point"
                                        value={formData.end_point}
                                        onChange={(selectedOption) =>
                                            setFormData({ ...formData, end_point: selectedOption })
                                        }
                                        options={locationOptions}
                                    />
                                </Form.Group> */}
                                <Form.Group>
                                        <Form.Label>End Point</Form.Label>
                                        <Form.Select
                                            placeholder="Select End point"
                                            name="end_point"
                                            onChange={handleChange}
                                            value={formData.end_point?.value !== null ? formData.end_point?.value : formData.end_point}>
                                            {locationOptions.map((res) => (
                                                <option key={res.value} value={res.value}>
                                                    {res.label ? res.label :formData.end_point  }
                                                </option>
                                            ))}
                                        </Form.Select>
                                    </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg={6}>
                                <Form.Group>
                                    <Form.Label>Vehicle Capacity</Form.Label>
                                    <Form.Control
                                        type="number"
                                        name="seating_capacity"
                                        placeholder="Vehicle Capacity"
                                        value={formData.seating_capacity}
                                        onChange={handleChange}
                                    />
                                </Form.Group>
                            </Col>
                            <Col lg={6}>
                                <Form.Group>
                                    <Form.Label>Status</Form.Label>
                                    <Form.Select
                                        name="Status"
                                        value={formData.status}
                                        onChange={(e) =>
                                            setFormData({ ...formData, status: e.target.value })
                                        }
                                        // required
                                    >
                                        <option value="">--select status--</option>
                                        <option value="Active">Active</option>
                                        <option value="Inactive">InActive</option>
                                    </Form.Select>
                                </Form.Group>
                            </Col>
                        </Row>
                    </Form>
                </Container>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="primary" type="submit" onClick={handleSubmit}>
                    Update
                </Button>
            </Modal.Footer>
        </Modal>

    );
};

export default VehicleUpdate;