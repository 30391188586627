import React, { useEffect, useState } from "react";
import { Button, Card, Col, Row, Form } from "react-bootstrap";
import schoolApi from "../../api/schoolApi";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Link } from "react-router-dom";
import Confirm from "../Confirm";
import { usePermissions } from "../contexts/PermissionContext";
import { Helmet } from "react-helmet";

function SettingList(props) {
  const [isedit, setisEdit] = useState(true);
  const [buttonvalue, setButtonvalue] = useState("Edit Settings");
  const { rolePermissions, userInfo } = usePermissions();
  const [body, setBody] = useState();
  const [feeFrequency, setfeefrequency] = useState(0);
  const [lateFees, setlateFees] = useState(0);
  const [feesPandingDays, setfeesPandingDays] = useState(0);
  const [attendanceMode, setattendanceMode] = useState("daily");
  const [showUpdateModal, setShowUpdateModal] = useState(false);
  const canCreate = rolePermissions?.session_list?.can_create;
  const canEdit = rolePermissions?.session_list?.can_edit;
  const canDelete = rolePermissions?.session_list?.can_delete;
  console.log("canCreate canEdit canDelete", canCreate, canEdit, canDelete);
  console.log(body, "body");
  useEffect(() => {
    fetchAllSettings();
  }, []);

  async function fetchAllSettings() {
    try {
      const result = await schoolApi.getAllSetting();
      console.log("result======>>>>>>?????//////>", result);
      if (result && result.data) {
        setBody(
          result.data.map((element) => ({ ...element, isupdate: false }))
        );
        result.data.forEach((element) => {
          switch (element.key) {
            case "frequency":
              setfeefrequency(element.value);
              break;
            case "Attendentce_Mode":
              setattendanceMode(element.value);
              break;
            case "pending_due_day":
              setfeesPandingDays(element.value);
              break;
            case "late_fee":
              setlateFees(element.value);
              break;
            default:
              break;
          }
        });
      } else {
        setfeefrequency(0);
        setfeesPandingDays(0);
        setlateFees(0);
        setBody([]);
      }
    } catch (error) {
      console.error("Error during fetchAllSettings:", error);
      if (
        error.response.data.message &&
        error.response.data &&
        error.response
      ) {
        toast.error(error.response.data.message);
      } else {
        toast.error("Something went wrong");
      }
    }
  }

  const EditSetting = () => {
    setisEdit(!isedit);
    console.log("buttonvalue ->", buttonvalue, "   ", showUpdateModal);
    console.log("body ->", body);

    if (buttonvalue === "Edit Setting") {
      setButtonvalue("Save Settings");
    } else {
      console.log("hear");

      setShowUpdateModal(true);

      setButtonvalue("Edit Settings");
    }
  };
  const updaterecord = async () => {
    try {
      let updatedata = body.filter((value) => value.isupdate);
      console.log("updatedata ->", updatedata);

      const result = await schoolApi.updateallSettings(updatedata);
      console.log("result -> ", result);
      if (result.success) {
        props.setAttendanceType(attendanceMode);
        toast.success("Record update Successfully");
        handleCloseModal();
      } else {
        toast.success("Record not update");
        handleCloseModal();
      }
    } catch (error) {
      console.error("Error during fetchAllSettings:", error);
      if (error.response.data.message && error.response.data && error.response)
        toast.error(error.response.data.message);
      else toast.error("Something went wrong");
    }
  };

  const changevalues = (e) => {
    setBody((prev) =>
      prev.map((element) =>
        element.key === e.target.name
          ? { ...element, value: e.target.value, isupdate: true }
          : element
      )
    );
    switch (e.target.name) {
      case "frequency":
        setfeefrequency(e.target.value);
        break;
      case "Attendentce_Mode":
        setattendanceMode(e.target.value);
        break;
      case "pending_due_day":
        setfeesPandingDays(e.target.value);
        break;
      case "late_fee":
        setlateFees(e.target.value);
        break;
      default:
        break;
    }

    console.log(e.target.name);
  };
  const handleCloseModal = () => {
    setShowUpdateModal(false);
    fetchAllSettings();
    setButtonvalue("Edit Setting");
  };

  return (
    <>

<Helmet>
        <title>{props?.tabName}</title>
      </Helmet>
      {showUpdateModal && (
        <Confirm
          show={showUpdateModal}
          onHide={() => handleCloseModal()}
          handleDelete={() => updaterecord()}
          title="Confirm Update?"
          message="You are going to update the record. Are you sure?"
          table="deleteSession"
        />
      )}

      <Row className="g-0">
        <Row lg={12} className="p-lg-4" style={{ marginTop: "-20px" }}>
        <Col lg={2} className="mt-2">
            <Link className="nav-link" to="/">
              Home <i className="fa-solid fa-chevron-right"></i>{" "} Settings
            </Link>
          </Col>
            <Col
              xs={12}
              sm={6}
              lg={10}
              className="d-flex flex-col justify-content-end align-items-end"
            >
              {(userInfo?.userrole === "SYS_ADMIN" ||
                userInfo?.userrole === "ADMIN" ||
                canCreate) && (
                <Button
                  className="btn-light add-new-button"
                  variant=""
                  onClick={() => EditSetting()}
                >
                  {buttonvalue}
                </Button>
              )}
            </Col>
          </Row>

          <Row className=" g-2 mt-2">
            <Card bg="white" key="white" text="dark" className="mb-2" style={{marginTop: "-18px"}}>
              <Card.Header className="bg-dark text-white">
                <h4>Fees Settings</h4>
              </Card.Header>
              <Card.Body>
                <Row className="g-2">
                  <Col lg={6} className="gx-5">
                    <Row className="mt-2">
                      <Col lg={6} className="" sm={8}>
                        <Card.Title>Fee Frequency</Card.Title>
                        <small>Specify the number of days after which the fee amount will increase.</small>
                      </Col>
                      <Col lg={6} sm={4} className="d-flex align-items-center">
                        <Form.Control
                          type="number"
                          min="0"
                          name="frequency"
                          onChange={(e) => changevalues(e)}
                          value={feeFrequency}
                          disabled={isedit}
                        />
                      </Col>
                    </Row>
                  </Col>
                  <Col lg={6} className="gx-5">
                    <Row className="mt-2">
                      <Col lg={6} className="" sm={8}>
                        <Card.Title>Late Fee</Card.Title>
                        <small>
                        Specify the penalty amount applied for late payments after the due date.
                        </small>
                      </Col>
                      <Col lg={6} sm={4} className="d-flex align-items-center">
                        <Form.Control
                          type="number"
                          min="0"
                          name="late_fee"
                          onChange={(e) => changevalues(e)}
                          value={lateFees}
                          disabled={isedit}
                        />
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <Row className="g-2">
                  <Col lg={6} className="gx-5">
                    <Row className="mt-2">
                      <Col lg={6} className="" sm={8}>
                        <Card.Title>Fees Due Date</Card.Title>
                        <small>
                        Specify the date to be considered as the due date for each installment.
                        </small>
                      </Col>
                      <Col lg={6} sm={4} className="d-flex align-items-center">
                        <Form.Control
                          type="number"
                          min="1"
                          max="31"
                          name="pending_due_day"
                          onChange={(e) => changevalues(e)}
                          value={feesPandingDays}
                          disabled={isedit}
                        />
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
            <Card>
              <Card.Header className="bg-dark text-white">
                <h4>Other Settings</h4>
              </Card.Header>
              <Card.Body>
                <Row className="g-2">
                  <Col lg={6} className="gx-5">
                    <Row className="mt-2">
                      <Col lg={6} className="" sm={8}>
                        <Card.Title>Attendance Mode</Card.Title>
                        <small>
                        Specify whether student attendance will be tracked on a 'Daily' or 'Monthly' basis.
                        </small>
                      </Col>
                      <Col lg={6} sm={4} className="d-flex align-items-center">
                        <Form.Select
                          name="Attendentce_Mode"
                          disabled={isedit}
                          onChange={(e) => changevalues(e)}
                        >
                          <option value="" disabled selected>
                            --Select--
                          </option>
                          <option
                            value="monthly"
                            selected={
                              attendanceMode === "monthly" ? true : false
                            }
                          >
                            Monthly
                          </option>
                          <option
                            value="daily"
                            selected={attendanceMode === "daily" ? true : false}
                          >
                            Daily
                          </option>
                        </Form.Select>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Row>
      </Row>
      <ToastContainer
        position="top-right"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </>
  );
}

export default SettingList;
