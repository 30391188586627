import React, { useState, useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import schoolApi from "../../api/schoolApi";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const AddSection = (props) => {
  const [optionClass, setOptionClass] = useState([]);
  const [selectedClassName, setSelectedClassName] = useState(null);
  const [optionTeachers, setOptionTeachers] = useState([]);
  const [selectedTeacher, setSelectedTeacher] = useState(null);
  const [editRecord, setEditRecord] = useState({
    section_id: "",
    section_name: "",
    strength: "",
    class_id: "",
    classname: "",
    contact_id: null,
    contactname: "",
  });

  useEffect(() => {
   try{
      async function init() {
        if (props?.parent?.section_id) {
          setEditRecord(props.parent);

          setSelectedTeacher({
            value: props.parent.contact_id,
            label: props.parent.contact_name,
          });

          setSelectedClassName({
            value: props.parent.class_id,
            label: props.parent.class_name,
          });
        } else if (props?.parent?.class_id) {
          setEditRecord({
            ...editRecord,
            classname: props.parent.classname,
            class_id: props.parent.class_id,
          });

          setSelectedClassName({
            value: props.parent.class_id,
            label: props.parent.classname,
          });
        }

        const classResult = await schoolApi.getActiveClassRecords();
        console.log('classResult-->',classResult);
        if (classResult.success) {
          setOptionClass(
            classResult.result.map((item) => ({
              value: item.id,
              label: item.classname,
            }))
          );
        }
        let category=null;
        const teacherResult = await schoolApi.getTeacherRecords(category);
        console.log("Teacher :",teacherResult)
        if (teacherResult?.teacherData) {
          setOptionTeachers(
            teacherResult?.teacherData?.map((item) => ({
              value: item.id,
              label: item.teachername,
            }))
          );
        }
      }
      init();
    }catch (error) {
    console.error("Error fetching data:", error);
  }
  }, [props]);

  const handleChange = (e) => {
    setEditRecord({
      ...editRecord,
      [e.target.name]: e.target.value,
    });
  };

  const handleSelectClassName = (e) => {
    setSelectedClassName(e);
    setEditRecord({ ...editRecord, class_id: e.value });
  };

  const handleSelectTeachers = (e) => {
    setSelectedTeacher(e);
    setEditRecord({ ...editRecord, contact_id: e.value });
  };

 
  const handleSubmit = async (e) => {
    e.preventDefault();
try {

    // Check for empty fields before making the API call
    if (!editRecord.class_id ||!editRecord.section_name.trim() ||!editRecord.strength.trim() ||editRecord.strength < 0) {
      toast.error("Required field missing!");
      return;
    }
    // try {
      if (props?.parent?.section_id) {
      
        const updateObj = {
          id: props.parent.section_id,
          class_id: editRecord.class_id,
          strength: editRecord.strength,
          name: editRecord.section_name.toUpperCase(),
          contact_id: editRecord.contact_id,
        };

        const res = await schoolApi.updateSectionRecord(updateObj);
        if (res.success) {
          console.log("res.message", res.message);
          toast.success(res.message);
        } 
        // else {
        //   toast.error(res.message);
        // }
      } else {
        // Add new section
        const addSection = {
          class_id: editRecord.class_id,
          name: editRecord.section_name.toUpperCase(),
          strength: editRecord.strength,
          contact_id: editRecord.contact_id || null,
        };

        const response = await schoolApi.addSectionRecord(addSection);
        if (response.success) {
          toast.success("Record saved successfully");
        }
        //  else {
        //   toast.error("Record saving failed, possibly due to duplicate entry");
        // }
      }

      setTimeout(() => {
        props.onHide();
        props.sectionRecords();
      }, 1000);
    // } catch (error) {
    //   console.error("Error:", error);
    //   toast.error("An error occurred while saving the record");
    // }
      
} catch (error) {
  toast.error(error.response.data.message);
}
  };	
  return (
    <Modal
      show={props.show}
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton onClick={props.onHide}>
        <Modal.Title id="contained-modal-title-vcenter">
          Class Section
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Container className="view-form">
          <Row>
            <Col lg={12}>
              <Form className="mt-3" noValidate onSubmit={handleSubmit}>
                <Row>
                  <Col lg={6}>
                    {optionClass && (
                      <Form.Group className="mx-3">
                        <Form.Label
                          className="form-view-label"
                          htmlFor="formBasicClass"
                        >
                          Name
                        </Form.Label>
                        <Form.Select
                          name="class_id"
                          value={selectedClassName?.value || ""}
                          onChange={(e) =>
                            handleSelectClassName({ value: e.target.value })
                          }
                          required
                          disabled={props.parent.class_id}
                        >
                          <option value="">-- Select Class --</option>
                          {optionClass.map((cls) => (
                            <option key={cls.value} value={cls.value}>
                              {cls.label}
                            </option>
                          ))}
                        </Form.Select>
                      </Form.Group>
                    )}
                  </Col>
                  <Col lg={6}>
                    <Form.Group className="mx-3">
                      <Form.Label
                        className="form-view-label"
                        htmlFor="formBasicStrength"
                      >
                        Strength
                      </Form.Label>
                      <Form.Control
                        required
                        min={1}
                        type="number"
                        name="strength"
                        placeholder="--Strength--"
                        value={editRecord.strength}
                        onChange={handleChange}
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row className="pb-4 pt-3">
                  <Col lg={6}>
                    <Form.Group className="mx-3">
                      <Form.Label
                        className="form-view-label"
                        htmlFor="formBasicSection"
                      >
                        Section
                      </Form.Label>
                      <Form.Control
                        required
                        maxLength={1}
                        type="text"
                        name="section_name"
                        placeholder="--Section Name--"
                        value={editRecord.section_name}
                        onChange={handleChange}
                      />
                    </Form.Group>
                  </Col>
                  <Col lg={6}>
                    <Form.Group className="mx-3">
                      <Form.Label className="form-view-label">
                        Class Teacher
                      </Form.Label>
                      <Form.Select
                        name="contact_id"
                        value={selectedTeacher?.value || ""}
                        onChange={(e) =>
                          handleSelectTeachers({ value: e.target.value })
                        }
                      >
                        <option value="">-- Select Teacher --</option>
                        {optionTeachers.map((teacher) => (
                          <option key={teacher.value} value={teacher.value}>
                            {teacher.label}
                          </option>
                        ))}
                      </Form.Select>
                    </Form.Group>
                  </Col>
                </Row>
              </Form>
            </Col>
          </Row>
        </Container>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="success" onClick={handleSubmit}>
          {props?.parent?.section_id ? "Update" : "Save"}
        </Button>
        <Button onClick={props.onHide} variant="light">
          Close
        </Button>
      </Modal.Footer>
      <ToastContainer />
    </Modal>
  );
};

export default AddSection;
