import React, { useState, useEffect } from "react";
import { ShimmerTable } from "react-shimmer-effects";
import moment from "moment";
import { Table } from "react-bootstrap";
import schoolApi from "../../../api/schoolApi";
import {DatatableWrapper, Pagination, TableBody, TableHeader,} from "react-bs-datatable";

function BookRelatedPurchaseDetail(props) {
  const [parentBody, setParentBody] = useState([]);

  const header = [
    { title: "Supplier Name", prop: "supplier_name", isFilterable: true },
    { title: "Quantity", prop: "quantity", isFilterable: true },
    {
      title: "Date",
      prop: "date",
      isFilterable: true,
      cell: (row) => moment(row.date).format("DD-MMM-YYYY"),
    },
    {
      title: "Contact Person",
      prop: "supplier_contact_person",
      isFilterable: true,
    },
    { title: "Phone", prop: "supplier_phone", isFilterable: true },
    { title: "Email", prop: "supplier_email", isFilterable: true },
    { title: "Address", prop: "supplier_address", isFilterable: true },
    { title: "Status", prop: "supplier_status", isFilterable: true },
  ];

  useEffect(() => {
    fetchPurchase();
  }, []);

  const fetchPurchase = async () => {
    try {
      //--- Update By mohd. sarfraj | 16-sept-2024. | added try-catch 18-sept-24.  ----
      const result = await schoolApi.getPurchasesRecordsByBookId(props?.bookId);
      if(result.success){
        setParentBody(result?.purchase);
      } else setParentBody([]);
    } catch (error) {
      console.log('Server error ! ---->>>>' , error.message);
    }
  };

  return (
    <div>
      {parentBody.length > 0 ? (
        <DatatableWrapper body={parentBody} headers={header}>
          <Table striped className="data-table custom-table-subject-list">
            <TableHeader />
            <TableBody />
          </Table>
          <Pagination />
        </DatatableWrapper>
      ) : (
        //------- Updated By mohd. sarfraj | 16-sept-2024 -------
        <div>
          <DatatableWrapper body={parentBody} headers={header}>
            <Table striped className="data-table custom-table-subject-list">
              <TableHeader />
              <TableBody>
                <tr>
                  <td colSpan={8} className="text-center">No Records Found!!!</td>
                </tr>
              </TableBody>
            </Table>
            <Pagination />
          </DatatableWrapper>
        </div>
      )}
    </div>
  );
}
export default BookRelatedPurchaseDetail;