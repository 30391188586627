import React, { useEffect, useState } from "react";
import { Button, Form, Row, Col, Container } from "react-bootstrap";
import schoolApi from "../../api/schoolApi";
import { useLocation } from "react-router-dom";
import { Helmet } from "react-helmet";
import "../../resources/css/Student.css";
import { parseISO } from "date-fns";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import CityState from "../../constants/CityState.json";
import "react-datepicker/dist/react-datepicker.css";
import "react-toastify/dist/ReactToastify.css";
import $ from "jquery";
import "jquery-ui-dist/jquery-ui.css"; // jQuery UI CSS
import "jquery-ui-dist/jquery-ui"; // jQuery UI

const StudentEnquiry = (props) => {
  const maxDate = moment().subtract(3.5, "years").format("YYYY-MM-DD");
  const [showInput, setShowInput] = useState(false);
  const navigate = useNavigate();
  const [optionClasses, setOptionClasses] = useState([]);
  const [lead, setlead] = useState({});
  const location = useLocation();
  const [validated, setValidated] = useState(false);
  const [states, setStates] = useState([]);
  const [selectedState, setSelectedState] = useState(null);
  const [selectedCity, setSelectedCity] = useState(null);
  const [cities, setCities] = useState([]);
  const [disableField, setDisableField] = useState(false);
  const [emailError, setEmailError] = useState("");

  const [optionSection, setOptionSection] = useState([
    location?.state?.sectionid
      ? {
          value: location?.state?.sectionid,
          label: location?.state?.section_name,
        }
      : { value: "", label: "" },
  ]);

  console.log('location---->>>>' , location);

  useEffect(() => {
    console.log("location?.state22=>", location?.state);
    if (location?.state?.status === "Registered") {
      setDisableField(true);
    }
    if (location?.state) {
      setlead(location?.state);
    } else {
      if (location.hasOwnProperty("pathname")) {
        setlead({});
      }
    }

    const uniqueStates = Array.from(
      new Set(CityState.map((item) => item.state))
    )
      .map((state) => ({ label: state, value: state }))
      .sort((a, b) => a.label.localeCompare(b.label));

    setStates(uniqueStates);
  }, [location, CityState]);

  useEffect(() => {
    async function init() {
      try {
        let classList = await schoolApi.getActiveClassRecords();
        console.log("classList212`1221->", classList);
        if (classList?.result) {
          let ar = [];
          classList?.result.map((item) => {
            var obj = {};
            obj.value = item.id;
            obj.label = item.classname;
            ar.push(obj);
          });
          setOptionClasses(ar);
        } else {
          setOptionClasses([]);
        }
      } catch (error) {
        console.log("error121=>", error);
        toast.error(error?.response?.data?.message);
      }
    }
    init();
  }, []);

  // Add by Aamir khan 08-05-2024
  useEffect(() => {
    console.log("sdvsd");
    if (location?.state?.id) {
      console.log("Enter tha code");
      const selectedState = states.find((state) => state.value === location.state.state);
      console.log("hear");
      setSelectedState(selectedState);

      const filteredCities = CityState.filter(
        (item) => item.state === location.state.state
      ).map((city) => ({ label: city.name, value: city.name }))
        .sort((a, b) => a.label.localeCompare(b.label));

      setCities(filteredCities);

      const selectedCity = filteredCities.find((city) => city.value === location.state.city);

      setSelectedCity(selectedCity);
    } else {
      setSelectedState(null);
      setSelectedCity(null);
      setCities([]);
    }
  }, [location, states]);

  /* START  Date work with React J-Query  (Date-Picker)  */
  $(document).ready(() => {
    const formatDateForPicker = (dateString) => {
      if (!dateString) return "";
      console.log('dateString----->>>>>>>' , dateString);
      // dateString = dateString.split('T')[0]; // "YYYY-MM-DD" format
      
      const parts = dateString.includes("-") ? dateString.split("-") : [];
      if (parts.length === 3) {
        if (parts[0].length === 4) {
          const [y, m, d] = parts; 
          return `${d}/${m}/${y}`; 
        } else {
          const [d, m, y] = parts; 
          return `${d}/${m}/${y}`;
        }
      }
      return "";
    };

    let date = formatDateForPicker(lead?.dob) || ""

    $("#enquiry_dob").datepicker({
        dateFormat: "dd/mm/yy",
        changeMonth: true,
        changeYear: true,
        yearRange: "1920:2024",
        maxDate: '-3Y' ,
        onSelect: function (dateText) {
          handleDateChange(dateText, "dateofbirth");
          $(this).val(dateText);
        },
      }).datepicker("setDate", date);
  });

  const handleDateChange = (date, name) => {
    console.log("Selected date: ", date);
    const [day, month, year] = date.split("/");
    const formattedDate = `${year}-${month}-${day}`;

    setlead((lead) => {
      console.log('previous-lead-->>' , lead);
      
      return { ...lead, [name]: formattedDate , dob : formattedDate } // dob also chnage couse we use dob while update date of enquiry of student.
    });
  };
  /*  END J-Query Date  */

  const handleChange = async (e) => {
    if (e.target.name === "religion" && e.target.value === "Other") {
      setShowInput(true);
    } else if (e.target.name === "religion" && e.target.value !== "Other") {
      setShowInput(false);
    }
    setlead({ ...lead, [e.target.name]: e.target.value });
  };

  const handleOtherReligion = async (e) => {
    setlead({ ...lead, [e.target.name]: e.target.value });
  };

  const handleAadharNum = (event) => {
    const inputValue = event.target.value.replace(/\D/g, "").slice(0, 12);

    handleChange({ target: { name: "adharnumber", value: inputValue } });
  };

  const handleClasses = async (e) => {
    console.log("e.target.value@@@+>", e.target.value);
    const selectedClass = optionClasses.find(
      (cls) => cls.value === e.target.value
    );
    setlead({
      ...lead,
      classid: e.target.value,
      classname: selectedClass ? selectedClass.label : "",
    });
    //setlead({ ...lead, classid: e.target.value,classname:e.label });
    if (e.target.value) {
      try {
        let sectionList = await schoolApi.getSectionRecordById(e.target.value);
        console.log("sectionList@@+>", sectionList);
        if (sectionList?.record && sectionList?.record?.length > 0) {
          let sectionOptions = sectionList?.record.map((item) => ({
            value: item.id,
            label: item.name,
          }));
          setOptionSection(sectionOptions);
        } else {
          setOptionSection([]);
        }
      } catch (error) {
        console.log("error=>", error);
        toast.error(error?.response?.data?.message);
      }
    }
  };
  console.log("leadrr=>", lead);
  const handleSection = (e) => {
    setlead({ ...lead, sectionid: e.target.value });
  };

  console.log("lead-->", lead);

  //Add by Aamir khan 08-05-2024
  const handleStateChange = (e) => {
    setSelectedState(e.target.value);
    console.log("heat");

    setCities(
      CityState.filter((item) => item.state === e.target.value)
        .map((city) => ({
          label: city.name,
          value: city.name,
        }))
        .sort((a, b) => a.label.localeCompare(b.label))
    );

    console.log("@#Cities==>", cities);
    setlead({ ...lead, state: e.target.value });
  };

  //Add by Aamir khan 08-05-2024
  const handleCityChange = (e) => {
    const selectedCity = e.target.value;
    console.log("selectedCity==>", selectedCity);

    setlead({ ...lead, city: selectedCity });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log("first");
    console.log("lead record while submit-->", lead);

    if (lead.firstname && lead.lastname && lead.phone && lead.classid && lead.sectionid) {
      let result = {};
      if (lead.id) {
        console.log("lead.id====>", lead);
        try {
          const result = await schoolApi.savelead(lead);
          console.log("result@!12=>", result);
          if (result?.lead) {
            toast.success(result.message);
            setTimeout(() => {
              navigate(`/students/${result.lead.id}`, {
                state: result.lead,
              });
            }, 2000);
          }
        } catch (error) {
          console.log("error=>", error);
          toast.error(error?.response?.data?.message);
        }
      } else {
        if (Object.keys(lead).length > 0) {
          console.log("lead before create-->", lead);
          try {
            const result = await schoolApi.createlead(lead);
            console.log("createlead=====-------->", result);
            if (result?.success === true) {
              toast.success(result?.message);
              setTimeout(() => {
                navigate(`/students/${result?.leadRec?.id}`, {
                  state: result?.leadRec,
                });
              }, 2000);
            }
          } catch (error) {
            console.log("error=>", error);
            toast.error(error?.response?.data?.message);
          }
        }
      }
    } else {
      return toast.error("Missing required feild");
    }
  };

  const handleEmailChange = (event) => {
    const enteredEmail = event.target.value;
    handleChange({ target: { name: "email", value: enteredEmail } });

    if (
      !/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(enteredEmail)
    ) {
      if (enteredEmail === "") {
        setEmailError("");
      } else setEmailError("Invalid email format");
    } else {
      setEmailError("");
    }
  };

  const handleCancel = async (e) => {
    if (location?.state?.id) {
      navigate("/students/" + lead.id, { state: lead });
    } else {
      navigate("/students");
    }
  };

  const handlePhoneChange = (e) => {
    const inputPhone = e.target.value.replace(/\D/g, "").slice(0, 10);
    handleChange({ target: { name: "phone", value: inputPhone } });
  };

  return (
    <>
      <>
        <Helmet>
          <title>{props?.tabName}</title>
        </Helmet>
        <Container className="view-form">
          <Row>
            <Col></Col>
            <Col lg={8}>
              <Form
                className="mt-3"
                onSubmit={handleSubmit}
                noValidate
                validated={validated}
              >
                <Row className="view-form-header align-items-center">
                  <Col lg={3}>
                    {location?.state?.id ? "Edit" : "New"} Student Enquiry
                  </Col>
                  <Col lg={9} className="d-flex justify-content-end">
                    <Button className="btn-sm mx-2" onClick={handleSubmit}>
                      Save
                    </Button>
                    <Button
                      className="btn-sm"
                      variant="danger"
                      onClick={handleCancel}
                    >
                      Cancel
                    </Button>
                  </Col>
                </Row>
                <Row>
                  <Col lg={6}>
                    <Form.Group className="mx-3">
                      <Form.Label
                        className="form-view-label"
                        htmlFor="formBasicFirstName"
                      >
                        First Name
                      </Form.Label>
                      <Form.Control
                        required
                        type="text"
                        name="firstname"
                        placeholder="Enter First Name"
                        value={lead.firstname}
                        onChange={handleChange}
                      />
                    </Form.Group>
                  </Col>
                  <Col lg={6}>
                    <Form.Group className="mx-3">
                      <Form.Label
                        className="form-view-label"
                        htmlFor="formBasicLastName"
                      >
                        Last Name
                      </Form.Label>
                      <Form.Control
                        type="text"
                        name="lastname"
                        placeholder="Enter Last Name"
                        value={lead.lastname}
                        onChange={handleChange}
                        required
                      />
                    </Form.Group>
                  </Col>
                  <Col lg={6}>
                    <Form.Group className="mx-3">
                      <Form.Label
                        className="form-view-label"
                        htmlFor="formBasicPhone"
                      >
                        Phone
                      </Form.Label>
                      <Form.Control
                        type="phone"
                        name="phone"
                        placeholder="Enter phone"
                        value={lead.phone}
                        onChange={handlePhoneChange}
                        required
                      />
                    </Form.Group>
                  </Col>

                  <Col lg={6}>
                    <Form.Group className="mx-3">
                      <Form.Label className="form-view-label" htmlFor="formBasicBirthdate">Date of Birth</Form.Label>
                      <Form.Control
                        type="text"
                        id="enquiry_dob"
                        name="dateofbirth"
                        // readOnly
                        autoComplete="off"
                        placeholder="Select Date"
                      />
                    </Form.Group>
                  </Col>
                  <Col lg={6}>
                    <Form.Group className="mx-3">
                      <Form.Label className="form-view-label" name="classid">
                        Class Name
                      </Form.Label>
                      <Form.Select
                        required
                        name="classid"
                        onChange={handleClasses}
                        value={lead.classid}
                      >
                        <option value="">--Select Class--</option>
                        {optionClasses.map((cls) => (
                          <option key={cls.value} value={cls.value}>
                            {cls.label}
                          </option>
                        ))}
                      </Form.Select>
                    </Form.Group>
                  </Col>
                  <Col md={6}>
                    <Form>
                      <Form.Group className="mx-3" controlId="formClassName">
                        <Form.Label className="text-formatted">
                          Section Name
                        </Form.Label>
                        <Form.Select
                          name="sectionid"
                          required
                          onChange={handleSection}
                          value={lead?.sectionid}
                        >
                          <option value="">Select Section</option>
                          {optionSection.map((sec, index) => (
                            <option key={index} value={sec.value}>
                              {sec.label}
                            </option>
                          ))}
                        </Form.Select>
                      </Form.Group>
                    </Form>
                  </Col>
                  <Col lg={6}>
                    <Form.Group className="mx-3">
                      <Form.Label className="form-view-label">
                        Gender
                      </Form.Label>
                      <Form.Select
                        name="gender"
                        onChange={handleChange}
                        value={lead.gender}
                      >
                        <option value="">-- Select Gender --</option>
                        <option value="Male">Male</option>
                        <option value="Female">Female</option>
                        <option value="Other">Other</option>
                      </Form.Select>
                    </Form.Group>
                  </Col>

                  <Col lg={6}>
                    <Form>
                      <Form.Group className="mx-3">
                        <Form.Label className="form-view-label">
                          Email
                        </Form.Label>
                        <Form.Control
                          type="email"
                          placeholder="Enter Email"
                          name="email"
                          value={lead.email}
                          onChange={handleEmailChange}
                          isInvalid={!!emailError}
                        />
                        <Form.Control.Feedback type="invalid">
                          {emailError}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Form>
                  </Col>
                  <Col lg={6}>
                    <Form.Group className="mx-3">
                      <Form.Label
                        className="form-view-label"
                        htmlFor="formBasicAdharnumber"
                      >
                        Aadhaar Number
                      </Form.Label>
                      <Form.Control
                        type="text"
                        name="adharnumber"
                        placeholder="Enter Aadhaar Number"
                        value={lead.adharnumber}
                        onChange={handleAadharNum}
                      />
                    </Form.Group>
                  </Col>
                  <Col lg={6}>
                    <Form.Group
                      className="mx-2"
                      controlId="formBasicsalutation"
                    >
                      <Form.Label
                        className="form-view-label"
                        htmlFor="formBasicsalutation"
                      >
                        Religion
                      </Form.Label>
                      <Form.Select
                        name="religion"
                        value={lead.religion}
                        onChange={handleChange}
                      >
                        <option value="">--Select Religion--</option>
                        <option value="Christian">Christian</option>
                        <option value="Hindu">Hindu</option>
                        <option value="Jain">Jain</option>
                        <option value="Muslim">Muslim</option>
                        <option value="Sikh">Sikh</option>
                        <option value="Other">Other</option>
                      </Form.Select>
                    </Form.Group>
                  </Col>
                  {showInput && (
                    <Col lg={6}>
                      <Form.Group className="mx-3">
                        <Form.Label
                          className="form-view-label"
                          htmlFor="formBasicother"
                        >
                          Other Religion
                        </Form.Label>
                        <Form.Control
                          type="text"
                          name="otherreligion"
                          placeholder="Enter Other Religion"
                          value={lead.otherreligion}
                          onChange={handleOtherReligion}
                        />
                      </Form.Group>
                    </Col>
                  )}
                  <Row />
                  <Row
                    className="view-form-header align-items-center mt-4"
                    style={{ marginLeft: "4px" }}
                  >
                    <Col lg={3}>Address</Col>
                  </Row>
                  <Col lg={6}>
                    <Form.Group className="mx-3">
                      <Form.Label
                        className="form-view-label"
                        htmlFor="formBasicStreet"
                      >
                        Street
                      </Form.Label>
                      <Form.Control
                        type="text"
                        name="street"
                        placeholder="Enter Street"
                        value={lead.street}
                        onChange={handleChange}
                      />
                    </Form.Group>
                  </Col>
                  <Col lg={6}>
                    <Form.Group className="mx-3">
                      <Form.Label
                        className="form-view-label"
                        htmlFor="formBasicCountry"
                      >
                        Country
                      </Form.Label>
                      <Form.Control
                        style={{ height: "36px" }}
                        type="text"
                        name="country"
                        placeholder="Enter Country"
                        value={lead.country}
                        onChange={handleChange}
                      />
                    </Form.Group>
                  </Col>

                  {/* Add By Aamir khan 03-05-202   State */}
                  {
                    <Col lg={6}>
                      <Form.Group className="mx-3">
                        <Form.Label
                          className="form-view-label"
                          htmlFor="formBasicstate"
                        >
                          State
                        </Form.Label>

                        <Form.Select
                          name="state"
                          value={lead?.state}
                          onChange={handleStateChange}
                        >
                          <option value="">-- Select State --</option>
                          {states.map((state) => (
                            <option key={state.label} value={state.value}>
                              {state.label}
                            </option>
                          ))}
                        </Form.Select>
                      </Form.Group>
                    </Col>
                  }

                  {/* Add By Aamir khan 08-05-2024*/}
                  <Col lg={6}>
                    <Form.Group className="mx-3">
                      <Form.Label
                        className="form-view-label"
                        htmlFor="formBasicCity"
                      >
                        City
                      </Form.Label>
                      <Form.Select
                        name="city"
                        value={lead?.city}
                        onChange={handleCityChange}
                      >
                        <option value="">-- Select City --</option>
                        {cities.map((city) => (
                          <option key={city.label} value={city.value}>
                            {city.label}
                          </option>
                        ))}
                      </Form.Select>
                    </Form.Group>
                  </Col>

                  <Col lg={6}>
                    <Form.Group className="mx-3">
                      <Form.Label
                        className="form-view-label"
                        htmlFor="formBasicPin"
                      >
                        Pincode
                      </Form.Label>
                      <Form.Control
                        type="text"
                        name="pincode"
                        placeholder="Enter pincode"
                        value={lead.pincode}
                        onChange={handleChange}
                      />
                    </Form.Group>
                  </Col>

                  <Row
                    className="view-form-header align-items-center mt-4"
                    style={{ marginLeft: "4px" }}
                  >
                    <Col lg={3}>Parent's Information</Col>
                  </Row>

                  <Col lg={6}>
                    <Form.Group className="mx-3">
                      <Form.Label
                        className="form-view-label"
                        htmlFor="formBasicCountry"
                      >
                        Father Name
                      </Form.Label>
                      <Form.Control
                        style={{ height: "36px" }}
                        type="text"
                        name="father_name"
                        placeholder="Enter Father Name"
                        value={lead.father_name}
                        onChange={handleChange}
                      />
                    </Form.Group>
                  </Col>
                  <Col lg={6}>
                    <Form.Group className="mx-3">
                      <Form.Label
                        className="form-view-label"
                        htmlFor="formBasicCountry"
                      >
                        Mother Name
                      </Form.Label>
                      <Form.Control
                        style={{ height: "36px" }}
                        type="text"
                        name="mother_name"
                        placeholder="Enter Mother Name"
                        value={lead.mother_name}
                        onChange={handleChange}
                      />
                    </Form.Group>
                  </Col>
                  <Col lg={6}>
                    <Form.Group className="mx-3">
                      <Form.Label
                        className="form-view-label"
                        htmlFor="formBasicCountry"
                      >
                        Father Qualification
                      </Form.Label>
                      <Form.Control
                        style={{ height: "36px" }}
                        type="text"
                        name="father_qualification"
                        placeholder="Enter Father Qualification"
                        value={lead.father_qualification}
                        onChange={handleChange}
                      />
                    </Form.Group>
                  </Col>
                  <Col lg={6}>
                    <Form.Group className="mx-3">
                      <Form.Label
                        className="form-view-label"
                        htmlFor="formBasicCountry"
                      >
                        Mother Qualification
                      </Form.Label>
                      <Form.Control
                        style={{ height: "36px" }}
                        type="text"
                        name="mother_qualification"
                        placeholder="Enter Mother Qualification"
                        value={lead.mother_qualification}
                        onChange={handleChange}
                      />
                    </Form.Group>
                  </Col>
                  <Col lg={6}>
                    <Form.Group className="mx-3">
                      <Form.Label
                        className="form-view-label"
                        htmlFor="formBasicCountry"
                      >
                        Father Occupation
                      </Form.Label>
                      <Form.Control
                        style={{ height: "36px" }}
                        type="text"
                        name="father_occupation"
                        placeholder="Enter Father Occupation"
                        value={lead.father_occupation}
                        onChange={handleChange}
                      />
                    </Form.Group>
                  </Col>
                  <Col lg={6}>
                    <Form.Group className="mx-3">
                      <Form.Label
                        className="form-view-label"
                        htmlFor="formBasicCountry"
                      >
                        Mother Occupation
                      </Form.Label>
                      <Form.Control
                        style={{ height: "36px" }}
                        type="text"
                        name="mother_occupation"
                        placeholder="Enter Mother Occupation"
                        value={lead.mother_occupation}
                        onChange={handleChange}
                      />
                    </Form.Group>
                  </Col>

                  <Col lg={6}>
                    <Form.Group className="mx-3">
                      <Form.Label
                        className="form-view-label"
                        htmlFor="formBasicQualification"
                      >
                        Description
                        <Form.Control
                          as="textarea"
                          rows={3}
                          placeholder="Enter your description here"
                          name="description"
                          value={lead.description}
                          onChange={handleChange}
                        />
                      </Form.Label>
                    </Form.Group>
                  </Col>
                </Row>
              </Form>
            </Col>
            <Col></Col>
          </Row>
          <ToastContainer
            position="top-right"
            autoClose={2000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="light"
          />
        </Container>
      </>
    </>
  );
};
export default StudentEnquiry;
