//------- new code shahir -------

/**
 * @author: Pawan Singh Sisodiya
 */

import React, { useState, useEffect, useRef  } from "react";
import { Button, Col, Modal, Container, Row, Table } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import schoolApi from "../../api/schoolApi";
import Form from "react-bootstrap/Form";
import Select from "react-select";

import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import {
  DatatableWrapper,
  TableBody,
  TableHeader,
} from "react-bs-datatable";
import { RxCross2 } from "react-icons/rx";
import ConfirmationDialog from "../modal/confirmation_dialog";
import {usePermissions} from "../contexts/PermissionContext";
import { ShimmerTable } from "react-shimmer-effects";

const FeeMasterEdit = () => {
  const location = useLocation();
  console.log("location-->", location?.state);

  const initialState =
    location?.state && location?.state?.id
      ? {
          apply_to_all: "false",
          classid: location?.state?.classid,
          fee_structure: location?.state?.fee_structure,
          sessionid: location?.state?.sessionid,
          status: location?.state?.status,
          type: location?.state?.type,
          fee_master_id: location?.state?.id,
          fee_head_master_id: [
            { general_fee: 0, obc_fee: 0, sc_fee: 0, st_fee: 0 },
          ],
        }
      : { sessionid: "" };
  const navigate = useNavigate();
  const [optionClasses, setOptionClasses] = useState([]);
  const [optionFeeHeadMaster, setFeeHeadMaster] = useState([]);
  const [selectedFeeHeadMaster, setSelectedFeeHeadMaster] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [sessionYear, setSessionYear] = useState([]);
  const [feeMaster, setFeeMastrer] = useState(initialState);
  const [selectedMonths, setSelectedMonths] = useState([]);
  const [showInstallment, setShowInstallment] = useState(false);
  const [currentSession, setCurrentSession] = useState();
  let [finalData, setFinalData] = useState([]);
  const [applyToAllChecked, setApplyToAllChecked] = useState(false);
  const [formatedSelectedMonth, setFormatedSelectedMonths] = useState([]);
  const [fixedmonths, setFixedMonths] = useState([]);
  const [deleteFeeHeads, setDeleteFeeHeads] = useState(false);
  const [checkFeeHeads, setCheckFeeHeads] = useState(false);
  const [selectCheckBox, setSelectCheckBox] = useState(false);
  const [selectedRow, setSelectedRow] = useState();
  const [monthsToBeRemoved, setMonthsToBeRemoved] = useState([]);
  const [optionMonths, setOptionMonths] = useState([]);
  const [activeTab, setActiveTab] = useState(fixedmonths[0]?.value);
  const [isUpdate, setisUpdate] = useState(false);
  const [removeTab, setRemoveTab] = useState(false);
  const [removeMonth, setRemoveMonth] = useState(false); // last updated by shahir on 19-07-2024
  const [deletedMonths, setDeletedMonths] = useState([]);
  const { rolePermissions, userInfo } = usePermissions();
  const canCreate = rolePermissions?.Fee_Master_List?.can_create
  const canEdit = rolePermissions?.Fee_Master_List?.can_edit
  const prevLocationStateRef = useRef();

  console.log("feeMaster using location-->", feeMaster);
  // while edit------------------------location.state.id-------------------------
  
  useEffect(() => {
    fetchData(location?.state?.id);
    handleInstallmentType({sessionid : location?.state?.sessionid, type : location?.state?.type});
  }, [JSON.stringify(location?.state), sessionYear]);

  console.log("isUpdate-->", isUpdate);

  const fetchData = async () => {
    try {
      const masterInstalments = await schoolApi.fetchRelatedInstallments(
        location?.state?.classid,
        location?.state?.type,
        location?.state?.fee_structure
      );
      console.log("masterInstalments:", masterInstalments);
      
      masterInstalments?.forEach((res) => {
        setFinalData((prevData) => {
          setFixedMonths((prevMonths) => {
            if (!prevMonths.some((item) => item.label === res.month)) {
              return [...prevMonths, { label: res.month, value: res.month }];
            }
            return prevMonths;
          });

          if (!prevData) {
            return [
              {
                ...feeMaster,
                month: res?.month,
                fee_master_id: res?.fee_master_id,
                sessionid:
                  feeMaster?.sessionid === ""
                    ? currentSession?.id
                    : feeMaster?.sessionid,
                fee_head_master_id: {
                  line_items_id: res.id,
                  name: res.name,
                  head_master_id: res?.fee_head_master_id,
                  general_fee: res?.general_amount,
                  obc_fee: res?.obc_amount,
                  sc_fee: res?.sc_amount,
                  st_fee: res?.st_amount,
                  month: res?.month,
                  fee_master_installment_id: res?.fee_master_installment_id,
                },
              },
            ];
          } else {
            const existingMonths = new Set(prevData.map((item) => item.month));
            if (existingMonths.has(res.month)) {
              const existingData = prevData.find(
                (item) => item.month === res.month
              );
              if (
                !existingData.fee_head_master_id.some(
                  (item) => item.name === res.name
                )
              ) {
                const newData = {
                  ...existingData,
                  fee_head_master_id: [
                    ...existingData.fee_head_master_id,
                    {
                      line_items_id: res.id,
                      name: res.name,
                      head_master_id: res.fee_head_master_id,
                      general_fee: res.general_amount,
                      obc_fee: res.obc_amount,
                      sc_fee: res.sc_amount,
                      st_fee: res.st_amount,
                      month: res.month,
                      fee_master_installment_id: res?.fee_master_installment_id,
                    },
                  ],
                };
                return prevData.map((item) =>
                  item.month === res.month ? newData : item
                );
              }
            } else {
              return [
                ...prevData,
                {
                  ...feeMaster,
                  month: res.month,
                  fee_master_id: res?.fee_master_id,
                  sessionid:
                    feeMaster.sessionid === ""
                      ? currentSession.id
                      : feeMaster.sessionid,
                  fee_head_master_id: [
                    {
                      line_items_id: res.id,
                      name: res.name,
                      head_master_id: res.fee_head_master_id,
                      general_fee: res.general_amount,
                      obc_fee: res.obc_amount,
                      sc_fee: res.sc_amount,
                      st_fee: res.st_amount,
                      month: res.month,
                      fee_master_installment_id: res?.fee_master_installment_id,
                    },
                  ],
                },
              ];
            }
          }
          return prevData;
        });
      });

      if (Array.isArray(masterInstalments)) {
        setisUpdate(true);
        // setFeeMasterInstallment(masterInstalments);
      } else {
        console.error("API response is:", masterInstalments);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  // while edit------------------------location.state.id-------------------------

  const handleFeeChange = (e) => {
    console.log("handle Change Called-->", e.target.value);
    console.log("handle Change Called name -->", e.target.value);

    setShowInstallment(false);
    if(e.target.name === "sessionid"){
      // setFeeMastrer({ ...feeMaster, [e.target.name]: e.target.value });
      handleInstallmentType({sessionid : e.target.value,type : feeMaster.type});
    }
    if (e.target.name === "type") {

      // handleInstallmentType(e.target.value);
      handleInstallmentType({sessionid : feeMaster.sessionid,type : e.target.value});
      setFeeMastrer({ ...feeMaster, [e.target.name]: e.target.value });

    } else {
      setFeeMastrer({ ...feeMaster, [e.target.name]: e.target.value });
      if (finalData && finalData.length > 0) {
        setFinalData((prevVal) => {
          return prevVal.map((res) => {
            if (res[e.target.name] !== e.target.value) {
              return { ...res, [e.target.name]: e.target.value };
            }
            return res;
          });
        });
      }
    }
  };

  console.log("currentSession-->", currentSession);

  // const handleInstallmentType = async (typeValue) => {
  const handleInstallmentType = async (typeValue) => {

    if(typeValue.sessionid == ''){
      typeValue.sessionid = currentSession ? currentSession.id : ''
    }
    let startdate = ''
    let enddate = ''
    
    console.log('sh@ sessionYear == ',sessionYear)
    let sessions = sessionYear
    if(location?.state?.id){//Create By Shahir Hussain 30-07-2024

      // const result = sessions.find(item=> item.year === location?.state?.session)
      const selectSession = sessions.find(item=> item.id === typeValue.sessionid)
      console.log('sh@ selectSession edit == ',selectSession)

      startdate = selectSession?.startdate;
      enddate = selectSession?.enddate;

      console.log('sh@ startdate ---',startdate)
      console.log('sh@ enddate ---',enddate)

    }
    else{
      const selectSession = sessions.find(item=> item.id === typeValue.sessionid)
      console.log('sh@ selectSession create == ',selectSession)
      startdate = selectSession?.startdate;
      enddate = selectSession?.enddate;
    }
    console.log('No startdate == ',startdate)
    console.log('No enddate == ',enddate)

    const startDate = new Date(startdate);
    const endDate = new Date(enddate);

    console.log('startdate again == ',startDate)
    console.log('enddate again == ',endDate)

    let startMonth = startDate.getMonth();
    let endMonth = endDate.getMonth();

    console.log('Start Month == ',startMonth)
    console.log('End Month == ',endMonth)

    console.log('Start Month == ',startMonth)
    console.log('End Month == ',endMonth)

    const currentDate = new Date();
    let months = [];
    setFormatedSelectedMonths([]);
    //Changes by shahir hussain 26-07-2024
    if (typeValue.type === "Monthly") {
      if(startMonth > endMonth){ endMonth += 12} //Created by shahir hussain 26-07-2024
      // for (let i = 3; i <= 14; i++) { //comment by shahir hussain
      for (let i = startMonth; i <= endMonth; i++) {
        const month = (i % 12) + 1;
        console.log("no of month-rechecking->", month);
        const year = currentDate.getFullYear() + Math.floor((i - 1) / 12);
        console.log("year-rechecking->", year);
        months.push(
          `${new Date(year, month - 1, 1).toLocaleString("en-US", {
            month: "long",
          })}`
        );
        console.log("months-rechecking->", months);
        setSelectedMonths(months);

        setFormatedSelectedMonths((prevSelectedMonths) => [
          ...prevSelectedMonths,
          {
            label: `${new Date(year, month - 1, 1).toLocaleString("en-US", {
              month: "long",
            })}`,
            value: `${new Date(year, month - 1, 1).toLocaleString("en-US", {
              month: "long",
            })}`,
          },
        ]);
      }
    } else if (typeValue.type === "Bi-Monthly") {
      if(startMonth > endMonth){ endMonth += 12} //Created by shahir hussain 26-07-2024
      // for (let i = 3; i <= 14; i += 2){ //comment by shahir hussain 26-07-2024
      for (let i = startMonth; i <= endMonth; i += 2) {//Changes By Shahir Hussain
        const month1 = (i % 12) + 1;
        const month2 = i + 1;
        const year = currentDate.getFullYear() + Math.floor((i - 1) / 12);
        months.push(
          `${new Date(year, month1 - 1, 1).toLocaleString("en-US", {
            month: "long",
          })} to ${new Date(year, month2, 1).toLocaleString("en-US", {
            month: "long",
          })}`
        );
        setSelectedMonths(months);
        setFormatedSelectedMonths((prevSelectedMonths) => [
          ...prevSelectedMonths,
          {
            label: `${new Date(year, month1 - 1, 1).toLocaleString("en-US", {
              month: "long",
            })} to ${new Date(year, month2, 1).toLocaleString("en-US", {
              month: "long",
            })}`,
            value: `${new Date(year, month1 - 1, 1).toLocaleString("en-US", {
              month: "long",
            })} to ${new Date(year, month2, 1).toLocaleString("en-US", {
              month: "long",
            })}`,
          },
        ]);
      }
    } else if (typeValue.type === "Quarterly") {
      let quarterStartMonth = startMonth+1;//Created By Shahir Hussain 29-07-2024
      for (let quarter = 1; quarter <= 4; quarter++) {
        // const startMonth = (quarter - 1) * 3 + 4;//comment by shahir hussain 29-07-2024
        const startMonth = (quarter - 1) * 3 + quarterStartMonth;
        const endMonth = startMonth + 2;

        const year = currentDate.getFullYear();

        const startDate = new Date(year, startMonth - 1, 1);
        const endDate = new Date(year, endMonth, 0);

        const startMonthName = startDate.toLocaleString("en-US", {
          month: "long",
        });
        const endMonthName = endDate.toLocaleString("en-US", {
          month: "long",
        });

        months.push(`${startMonthName} to ${endMonthName}`);
        setSelectedMonths(months);

        setFormatedSelectedMonths((prevSelectedMonths) => [
          ...prevSelectedMonths,
          {
            label: `${startMonthName} to ${endMonthName}`,
            value: `${startMonthName} to ${endMonthName}`,
          },
        ]);
      }
    } else if (typeValue.type === "Half Yearly") {
      let quarterStartMonth = startMonth+1;//Created By Shahir Hussain 29-07-2024
      for (let halfYear = 1; halfYear <= 2; halfYear++) {
        // const startMonth = (halfYear - 1) * 6 + 4;
        const startMonth = (halfYear - 1) * 6 + quarterStartMonth;
        const endMonth = startMonth + 5;

        const year = currentDate.getFullYear();

        const adjustedYear = halfYear === 1 ? year : year + 1;

        const startDate = new Date(adjustedYear, startMonth - 1, 1);
        const endDate = new Date(adjustedYear, endMonth, 0);

        const startMonthName = startDate.toLocaleString("en-US", {
          month: "long",
        });
        const endMonthName = endDate.toLocaleString("en-US", {
          month: "long",
        });

        months.push(`${startMonthName} to ${endMonthName}`);
        setSelectedMonths(months);

        setFormatedSelectedMonths((prevSelectedMonths) => [
          ...prevSelectedMonths,
          {
            label: `${startMonthName} to ${endMonthName}`,
            value: `${startMonthName} to ${endMonthName}`,
          },
        ]);
      }
    } else if (typeValue.type === "Yearly") {
      // const startMonth = 4;//comment by shahir hussain 29-07-2024
      // const endMonth = 3;//comment by shahir hussain 29-07-2024
      startMonth = startMonth+1
      endMonth = endMonth+1
      const year = currentDate.getFullYear();

      const adjustedYear = endMonth === 1 ? year - 1 : year;

      const startDate = new Date(adjustedYear, startMonth - 1, 1);
      const endDate = new Date(adjustedYear + 1, endMonth, 0);

      const startMonthName = startDate.toLocaleString("en-US", {
        month: "long",
      });
      const endMonthName = endDate.toLocaleString("en-US", { month: "long" });

      months.push(`${startMonthName} to ${endMonthName}`);
      setSelectedMonths(months);

      setFormatedSelectedMonths((prevSelectedMonths) => [
        ...prevSelectedMonths,
        {
          label: `${startMonthName} to ${endMonthName}`,
          value: `${startMonthName} to ${endMonthName}`,
        },
      ]);
    }

    setFeeMastrer({
      ...feeMaster,
      // type: typeValue,
      type:typeValue.type,
      month: months[0],
      apply_to_all: "false",
      fee_head_master_id: [],
    });
  
    if(typeValue !== feeMaster.type){
      console.log('come inside')
      setFixedMonths([]);
      setFinalData([]);
      setSelectedFeeHeadMaster([]);
      setOptionMonths([]);
    }
  };

  useEffect(() => {
    if (
      feeMaster.classid &&
      feeMaster.type &&
      feeMaster.fee_structure &&
      feeMaster.status
    ) {
      setShowInstallment(true);
    } else {
      setShowInstallment(false);
    }
  }, [feeMaster]);

  useEffect(() => {
    async function initClass() {
      const sessions = await schoolApi.fetchSessions();
      console.log('sh@ sessions dataaa==',sessions)
      sessions.map(rec => {
        let startDate = new Date(rec?.startdate)
        let endDate = new Date(rec?.enddate)

        console.log('sh@ startDate == ',startDate)
        console.log('sh@ endDate == ',endDate)

        let startMonth = startDate.getMonth();
        let endMonth = endDate.getMonth();

        if(startMonth === endMonth){
          console.log('sh@ startMonth == ',startMonth)
          console.log('sh@ startMonth == ',endMonth)
          if(startMonth === endMonth){
            console.log('start and end is eqauls == ')
            if(startMonth > 0)
              endMonth -= 1
            else if(startMonth == 0)
              endMonth += 11
          }
          rec.startdate = formatDate(new Date(startDate.getFullYear(), startMonth, 1)); // Start of the month
          rec.enddate = formatDate(new Date(endDate.getFullYear(), endMonth + 1, 0)); // End of the month (last day)
          
          // console.log("sh@ Updated startdate:", rec.startdate);
          // console.log("sh@ Updated enddate:", rec.enddate);
        }
      })
      
      console.log('data session ==',sessions)
      if (sessions) {
        setSessionYear(sessions);

        const currentYear = new Date().getFullYear();
        const nextYear = currentYear + 1;
        setCurrentSession(
          sessions.find((res) => {
            return res.year === `${currentYear}-${nextYear}`;
          })
        );
      } else {
        setSessionYear([]);
      }

      const classList = await schoolApi.getActiveClassRecords();
      if (classList.success) {
        let ar = [];
        classList?.result?.map((item) => {
          var obj = {};
          obj.value = item.id;
          obj.label = item.classname;
          ar.push(obj);
        });
        setOptionClasses(ar);
      } else {
        setOptionClasses([]);
      }
    }
    async function initFeeHeadMaster() {
      const feeHeadMaster = await schoolApi.fetchFeesHeadMaster();

      let selectedHeadIds = [];
      if (finalData?.length > 0) {
        finalData.forEach((res) => {
          if (feeMaster.month === res.month) {
            res.fee_head_master_id.forEach((itm) => {
              selectedHeadIds.push(itm.head_master_id);
            });
          }
        });
      }

      if (selectedHeadIds.length > 0 && feeHeadMaster.success) {
        setFeeHeadMaster(
          feeHeadMaster?.result?.filter((itm) => !selectedHeadIds.includes(itm.id)).map((itm) => ({ value: itm.id, label: itm.name }))
        );
      } else {
        if (feeHeadMaster.success) {
          let ar = feeHeadMaster?.result?.map((item) => ({
            value: item.id,
            label: item.name,
          }));
          setFeeHeadMaster(ar);
        } else {
          setFeeHeadMaster([]);
        }
      }
    }

    initClass();
    initFeeHeadMaster();
  }, [showModal]);
  function formatDate(date) {
    console.log('sh@ date data =',date)
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-based in JavaScript, so add 1
    const day = String(date.getDate()).padStart(2, "0"); // Ensure the day is two digits
    // console.log()
    return `${year}-${month}-${day}`;
  }
  const handleFeeHeadMaster = (selectedOption) => {
    selectedOption.forEach((val) => {
      if (!val?.data) {
        return (val.data = {
          general_fee: "",
          obc_fee: "",
          sc_fee: "",
          st_fee: "",
        });
      }
    });
    setFeeMastrer({ ...feeMaster, fee_head_master_id: selectedOption });
    setSelectedFeeHeadMaster(selectedOption);
    // setAllFeesMaster(selectedOption);

    if (finalData && finalData.length > 0) {
      setFinalData((prevData) => {
        if (prevData && prevData.length > 0) {
          const newData = prevData.map((element) => {
            if (optionMonths.some((option) => option.label === element.month)) {
              const existingHeads = new Set(
                element?.fee_head_master_id.map((head) => head.name)
              );
              console.log("existingHeads-->", existingHeads);

              const newFees = selectedOption
                .filter((option) => !existingHeads.has(option.label))
                .map((option) => ({
                  name: option.label,
                  head_master_id: option.value,
                  general_fee: 0,
                  obc_fee: 0,
                  sc_fee: 0,
                  st_fee: 0,
                  month: element.month,
                }));

              const updatedFeeHeadMasterId = [
                ...element.fee_head_master_id,
                ...newFees,
              ];

              console.log("new Element-->", {
                ...element,
                fee_head_master_id: updatedFeeHeadMasterId,
              });

              return { ...element, fee_head_master_id: updatedFeeHeadMasterId };
            } else {
              return element;
            }
          });

          console.log("newData-->", newData);
          return newData;
        }
      });
    }
  };

  console.log("formatedSelectedMonth-->", formatedSelectedMonth);
  const handleMonthMaster = (selectedOption) => {
    console.log("month master called::::::::", selectedOption);
    setOptionMonths(selectedOption);
    setFinalData((prevData) => {
      if (!prevData) {
        return [
          {
            ...feeMaster,
            month: selectedOption[0]?.value,
            sessionid:
              feeMaster.sessionid === ""
                ? currentSession.id
                : feeMaster.sessionid,
            //    fee_master_id : masterInstalments[0].fee_master_id,
            fee_head_master_id: feeMaster.fee_head_master_id.map((res) => ({
              name: res.label,
              head_master_id: res.value,
              general_fee: "",
              obc_fee: "",
              sc_fee: "",
              st_fee: "",
              month: selectedOption[0]?.value,
            })),
          },
        ];
      }
      console.log("prevData--->", prevData);

      let existingMonths = new Set(prevData.map((item) => item.month));

      let newMonths = selectedOption.filter(
        (option) => !existingMonths.has(option.label)
      );

      if (newMonths.length > 0) {
        console.log("newMonths-->", newMonths);

        const newData = newMonths.map((option) => ({
          ...feeMaster,
          month: option.label,
          sessionid:
            feeMaster.sessionid === ""
              ? currentSession.id
              : feeMaster.sessionid,
          fee_head_master_id: feeMaster.fee_head_master_id.map((res) => ({
            name: res.label,
            head_master_id: res.value,
            general_fee: 0,
            obc_fee: 0,
            sc_fee: 0,
            st_fee: 0,
            month: option.label,
          })),
        }));

        setFixedMonths((prevFixedMonths) => [...prevFixedMonths, ...newMonths]);
        return [...prevData, ...newData];
      } else {
        return prevData;
      }
    });
  };

  const handleform = (e, row) => {
    if (
      (e.target.name === "general_fee" ||
        e.target.name === "obc_fee" ||
        e.target.name === "sc_fee" ||
        e.target.name === "st_fee") &&
      isNaN(e.target.value)
    ) {
      return;
    }

    setFinalData((oldval) =>
      oldval.map((res) => ({
        ...res,
        fee_head_master_id: res.fee_head_master_id.map((itm) => {
          if (itm.name === row.name && itm.month === row.month) {
            if (e.target.name === "general_fee") {
              return {
                ...itm,
                general_fee: e.target.value,
                obc_fee: e.target.value,
                sc_fee: e.target.value,
                st_fee: e.target.value,
              };
            } else if (e.target.name === "obc_fee") {
              return { ...itm, [e.target.name]: e.target.value };
            } else if (e.target.name === "sc_fee") {
              return { ...itm, [e.target.name]: e.target.value };
            } else if (e.target.name === "st_fee") {
              return { ...itm, [e.target.name]: e.target.value };
            }
          }
          return itm;
        }),
      }))
    );
  };

  const getBody = (month) => {
    const filteredData = finalData?.filter((vl) => month === vl.month);
    const feeHeadIds = filteredData?.map((vl) => vl.fee_head_master_id);
    // return feeHeadIds[0];
    return feeHeadIds && feeHeadIds?.length ? feeHeadIds[0]:[]//Add this line by shahir hussain
  };

  console.log("finalData final-test-->", finalData);

  const handleSaveLineItems = async () => {
    try {
      if (!finalData.length) {
        return toast.error("Please select months!!");
      }

      console.log('month master called::::::::2',formatedSelectedMonth)
      let arr = []
      let index = 0
      //creat this loop by shahir hussain 30-07-2024
      formatedSelectedMonth.map(month =>{
        finalData.map(item => {
          if(month.label === item.month){
              console.log(month.label,' == ',item.month)
              arr[index] = item;
              index ++ ;
          }
        })      
      })
      setFinalData(arr)
      finalData = arr

      console.log('finalData == ',finalData)

      let showToastZeroFee = false;
      let showToastNoHeads = false;
      let hasZeroFee = [];
      let noHeads = [];

      finalData.forEach((fd) => {
        let noFeeHeads;
        if (!fd.fee_head_master_id.length > 0) {
          noFeeHeads = fd;
        }

        if (noFeeHeads) {
          noHeads.push(noFeeHeads);
        }
        const zeroFee = fd.fee_head_master_id.find(
          (fhm) =>
            fhm.general_fee === 0 ||
            fhm.obc_fee === 0 ||
            fhm.sc_fee === 0 ||
            fhm.st_fee === 0 ||
            fhm.general_fee === "" ||
            fhm.obc_fee === "" ||
            fhm.sc_fee === "" ||
            fhm.st_fee === "" || 
            fhm.general_fee === '0' ||
            fhm.obc_fee === '0' ||
            fhm.sc_fee === '0' ||
            fhm.st_fee === '0'
        );
        if (zeroFee) {
          hasZeroFee.push(zeroFee);
        }
      });

      console.log("hasZeroFee-->", hasZeroFee);

      if (hasZeroFee.length > 0) {
        showToastZeroFee = true;
      }

      if (noHeads.length > 0) {
        showToastNoHeads = true;
      }

      if (showToastNoHeads) {
        return toast.error(`Select fee heads at "${noHeads[0]?.month}"`);
      }

      if (showToastZeroFee) {
        return toast.error(
          `Enter greater than zero fees at "${hasZeroFee[0]?.month}"`
        );
      }

      if (isUpdate && !location?.state?.isClone) {      // mohd. sarfraj | 23-sept.24.
        // if(oldFeetype !== newFeetype){
        //   we will delete all fee_master_installments and line_items
        // }

      if(deletedMonths.length){
      console.log('i am here to delete the  month-->', deletedMonths);

      for(let i=0;i<=deletedMonths.length -1;i++){
      
       const resultInstallmentdelete = await schoolApi.deleteFeeMasterInstallment(deletedMonths[i].masterInstallmentId);
       console.log("resultInstallmentdelete-->", resultInstallmentdelete);


        if (resultInstallmentdelete.false) {
          toast.error('Something went wrong while remove');
          // navigate("/feesmasterlist");
          setTimeout(() => {
            navigate("/feesmasterlist");
          }, 2000);
          // navigate("/feesmasterlist");
        }
        }
      }
     
        
        const resultFeeMasterupdate = await schoolApi.updateFeeInstallmentLineItems(finalData);
        console.log("resultFeeMaster", resultFeeMasterupdate);

        if (resultFeeMasterupdate.success) {
          console.log('update success')
          toast.success('Record saved successfully');
          setTimeout(() => {
            navigate("/feesmasterlist");
          }, 2000);
        }
      } else {
        if (finalData.length > 0) {
          // Added by pawan singh sisodiya. | 23-sept-24.
          if(location?.state?.isClone){
            console.log('finalData step1-->',finalData);
            if (finalData?.length > 0) {
              finalData.map((fm)=>{
                if(fm.fee_head_master_id){
                  fm?.fee_head_master_id?.map((fh)=>{
                    delete fh.fee_master_installment_id
                    delete fh.line_items_id
                    return fh
                  })
                  delete fm.fee_master_id
                  return fm
                }
              })
              console.log('finalData step2-->',finalData);
            }
          }

          const resultFeeMaster = await schoolApi.createFeeMaster(finalData);
          console.log("resultFeeMaster", resultFeeMaster);

          if (resultFeeMaster.success) {
          toast.success('Record saved successfully');
            // navigate("/feesmasterlist");
            setTimeout(() => {
              navigate("/feesmasterlist");
            }, 2000);
          }
        }
      }
    } catch (error) {
      if (error.response && error.response.status === 409) {
        return toast.error("Duplicate records are not allowed!!");
      } else {
        console.error("Request failed:", error);
      }
    }
  };

  const header = [
    {
      title: "Name",
      prop: "name",
      isFilterable: true,
    },
    {
      title: "Month",
      prop: "month",
    },
    {
      title: "General Fee",
      prop: "general_fee",
      cell: (row) => (
        <Form.Group>
          <Form.Control
            // type="number"
            type="numeric"
            required
            name="general_fee"
            placeholder="Enter General Fee"
            value={row.general_fee}
            onChange={(e) => handleform(e, row)}
          />
        </Form.Group>
      ),
    },
    {
      title: "Obc Fee",
      prop: "obc_fee",
      cell: (row) => (
        <Form.Group>
          <Form.Control
            // type="number"
            type="numeric"
            name="obc_fee"
            placeholder="Enter OBC Fee"
            value={row.obc_fee}
            onChange={(e) => handleform(e, row)}
          />
        </Form.Group>
      ),
    },
    {
      title: "Sc Fee",
      prop: "sc_fee",
      cell: (row) => (
        <Form.Group>
          <Form.Control
            // type="number"
            type="numeric"
            name="sc_fee"
            placeholder="Enter Sc Fee"
            value={row.sc_fee}
            onChange={(e) => handleform(e, row)}
          />
        </Form.Group>
      ),
    },
    {
      title: "St Fee",
      prop: "st_fee",
      cell: (row) => (
        <Form.Group>
          <Form.Control
            // type="number"
            type="numeric"
            name="st_fee"
            placeholder="Enter St Fee"
            value={row.st_fee}
            onChange={(e) => handleform(e, row)}
          />
        </Form.Group>
      ),
    },
    {
      title: "Sync",
      prop: "name",
      isFilterable: true,
      cell: (row) => (
        <Form.Check
          type="checkbox"
          name="sync"
          //value={row.is_sync}
          onClick={(e) => handleSync(e, row)}
          checked={row.is_sync}
        />
      ),
    },
    {
      title: "Action",
      prop: "",
      cell: (row) => (
        <div>
          <button
            className="btn btn-sm btn-danger mx-2"
            onClick={(e) => deleteSelectedFeeHeads(e, row)}
          >
            <i className="fa fa-trash"></i>
          </button>
        </div>
      ),
    },
  ];

  const handleSync = (e, row) => {
    console.log("sync checkbox-->", row.is_sync);
    console.log("event checkbox-->", e.target.checked);

    console.log("row == ", row);
    setSelectedRow(row);
    console.log("select row == ", selectedRow);
    const currectMonth = row.month;
    console.log("current month == ", currectMonth);
    if (!currectMonth) {
      return toast.error("Please select Month!!");
    }
    //Created By Shahir Hussain
    setFinalData((prevData) => {
      console.log("prevData == ", prevData);
      console.log("monthsToBeRemoved == ", monthsToBeRemoved);
      
      if (prevData && currectMonth) {
        console.log("if inside");
        
        const updatedData = prevData.map((res) => {
          const headData = res.fee_head_master_id.find(
            (head) => head.head_master_id === row.head_master_id
          );
          let flag = false;

          if (headData) {
            console.log("Updating existing head...");
            res.fee_head_master_id = res.fee_head_master_id.map((rec) => {
              if (rec.head_master_id === row.head_master_id) {
                flag = true;
                  if(e.target.checked === true ){
                  return {
                    ...rec,
                    general_fee: row.general_fee,
                    obc_fee: row.obc_fee,
                    sc_fee: row.sc_fee,
                    st_fee: row.st_fee,
                    is_sync: e.target.checked,
                  };
                }
                else{
                  return {...rec,is_sync: e.target.checked}
                }
              } else {
                return rec;
              }
            });
          }

          if (!flag) {
            console.log("Adding a new head...");
            if(e.target.checked === true ){
              res.fee_head_master_id.push({
                general_fee: row.general_fee,
                obc_fee: row.obc_fee,
                sc_fee: row.sc_fee,
                st_fee: row.st_fee,
                is_sync: e.target.checked,
                month: res.month,
                head_master_id: row.head_master_id,
                name: row.name,
              });
            }
          }

          console.log("Updated res = ", res);
          return res;
        });

        console.log("updatedData == ", updatedData);
        return updatedData;
      }
      
      return prevData;
    });
  };
  const deleteSelectedFeeHeads = (e, row) => {
    e.preventDefault();
    setDeleteFeeHeads(true);
    setSelectedRow(row);
    monthsToBeRemoved.push(row.month);
  };

  const handleTabSelect = (selectedTab) => {
    setFeeMastrer({ ...feeMaster, month: selectedTab });
    setActiveTab(selectedTab);
  };

  const removeSelectedTab = async (month) => {
    if (location?.state) {
      const masterInstallmentIds = finalData.filter((res) =>res.fee_head_master_id.some((item) => item.month === month))
        .map((res) =>
          res.fee_head_master_id
            .filter((item) => item.month === month)
            .map((item) => item.fee_master_installment_id)
        )
        .flat();

      console.log("masterInstallmentIds-->", masterInstallmentIds);

      if (masterInstallmentIds[0]) {
      console.log('masterInstallmentIds[0]--->',masterInstallmentIds[0]);
        setDeletedMonths((prev) => {
          if (!prev.includes(masterInstallmentIds[0])) {
            return [...prev, { masterInstallmentId: masterInstallmentIds[0] }];
          }
          return prev;
        });
      }

      setOptionMonths((prevMonths) => {
        const indexToRemove = prevMonths.findIndex(
          (res) => res.label === month
        );
        if (indexToRemove !== -1) {
          const updatedMonths = [...prevMonths];
          updatedMonths.splice(indexToRemove, 1);
          return updatedMonths;
        }
        return prevMonths;
      });

      setFixedMonths((prevMonths) => {
        const indexToRemove = prevMonths.findIndex(
          (res) => res.label === month
        );
        if (indexToRemove !== -1) {
          const updatedMonths = [...prevMonths];
          updatedMonths.splice(indexToRemove, 1);
          const newActiveTab =
            updatedMonths.length > 0 ? updatedMonths[0].value : null;
          setActiveTab(newActiveTab);
          return updatedMonths;
        }
        return prevMonths;
      });

      setFinalData((prevData) => {
        const indexToRemove = prevData.findIndex(
          (item) => item.month === month
        );
        if (indexToRemove !== -1) {
          const updatedData = [...prevData];
          updatedData.splice(indexToRemove, 1);
          return updatedData;
        }
        return prevData;
      });
    } else {
      setOptionMonths((prevMonths) => {
        const indexToRemove = prevMonths.findIndex(
          (res) => res.label === month
        );
        if (indexToRemove !== -1) {
          const updatedMonths = [...prevMonths];
          updatedMonths.splice(indexToRemove, 1);
          return updatedMonths;
        }
        return prevMonths;
      });

      setFixedMonths((prevMonths) => {
        const indexToRemove = prevMonths.findIndex(
          (res) => res.label === month
        );
        if (indexToRemove !== -1) {
          const updatedMonths = [...prevMonths];
          updatedMonths.splice(indexToRemove, 1);
          const newActiveTab =
            updatedMonths.length > 0 ? updatedMonths[0].value : null;
          setActiveTab(newActiveTab);
          return updatedMonths;
        }
        return prevMonths;
      });

      setFinalData((prevData) => {
        const indexToRemove = prevData.findIndex(
          (item) => item.month === month
        );
        if (indexToRemove !== -1) {
          const updatedData = [...prevData];
          updatedData.splice(indexToRemove, 1);
          return updatedData;
        }
        return prevData;
      });
    }
    setRemoveTab(false);
  }
  console.log('deltedMonths-->',deletedMonths);

  const closeDeleteSelectedFeeHeads = () => {
    setDeleteFeeHeads(false);
    setSelectedRow();
    setMonthsToBeRemoved([]);
  };

  const handleRemoveSelectedFeeHeads = () => {
    if (!monthsToBeRemoved.length > 0) {
      return toast.error("Please select Month!!");
    }
    // Commect By Shahir Hussain 23-07-2024
    setFinalData((prevData) => {
      console.log("prevData == ", prevData);
      console.log("monthsToBeRemoved == ", monthsToBeRemoved);

      if (prevData && monthsToBeRemoved.length > 0) {
        const updatedData = prevData.map((res) => {
          if (monthsToBeRemoved.includes(res.month)) {
            let tempArray = [...res.fee_head_master_id];
            const indexToRemove = tempArray.findIndex(
              (item) => item.name === selectedRow?.name
            );
            if (indexToRemove !== -1) {
              tempArray.splice(indexToRemove, 1);
            }
            return { ...res, fee_head_master_id: tempArray };
          }
          return res;
        });
        return updatedData;
      }
      return prevData;
    });
    console.log("The End");
    setCheckFeeHeads(false);
    setDeleteFeeHeads(false);
    setMonthsToBeRemoved([]);
  };

  const applyToAllMonthHandler = (month) => {
    console.log("month to be removed clicked!!!");
    if (!monthsToBeRemoved.includes(month)) {
      setMonthsToBeRemoved((prev) => [...prev, month]);
    } else {
      setMonthsToBeRemoved((prev) => prev.filter((item) => item !== month));
    }
  };

  console.log("finalData-->", finalData);
  console.log("fixedmonths-->", fixedmonths);
  console.log("selectedMonths->", selectedMonths);
  console.log("optionMonths-->", optionMonths);

  const handleCancel = () => {
    navigate("/feesmasterlist");
  };

  console.log("sessionYear-->", sessionYear);

  const removeTabHandler = (month) => {
    // last updated by shahir 19-07-2024
    console.log("removeTabHandler == ", month);
    setRemoveTab(true);
    setRemoveMonth(month);
  };

  console.log("fixedmonths order before == ",fixedmonths)
  console.log("formatedSelectedMonth order before == ",formatedSelectedMonth)
  const monthOrder = formatedSelectedMonth.map(month => month.value);
  console.log("month order before == ",monthOrder)
  const sortedMonths = fixedmonths.sort((a, b) => {
    console.log('a.value == ',a.value)
    console.log('b.value == ',b.value)
    
    return monthOrder.indexOf(a.value) - 
           monthOrder.indexOf(b.value);
  });
  console.log('sortedMonths == ',sortedMonths)

  return (
    <>
    {sessionYear.length > 0 ?
    <>
      <Container>
        <Form>
          <Row>
            <Col lg={12}>
              <Row className="view-form-header align-items-center">
                {/* <Col lg={3}>Fee Master</Col> */}
                <Col lg={3}>Fee Master {location?.state?.isClone && "Clone"}</Col>
                <Col lg={9} className="d-flex justify-content-end">                  
                {location?.state?.isClone 
                  ? <Button
                      className="mx-3"
                      onClick={handleSaveLineItems}
                      style={{ textAlign: "center" }}
                    >
                      Save Clone
                    </Button>
                  :
                    <Button
                      className="mx-3"
                      onClick={handleSaveLineItems}
                      style={{ textAlign: "center" }}
                    >
                      Save
                    </Button>
                }
                  
                  <Button
                    className="btn-sm"
                    variant="danger"
                    onClick={handleCancel}
                  >
                    Cancel
                  </Button>
                </Col>
              </Row>
            </Col>
          </Row>
         
          <Row lg={12} className="attandanceDate">
            <Row>
              <Col lg={12} className="mx-3">
                <Row>
                  <Col lg={4}>
                    <Form.Group className="my-3 mx-2">
                      <Form.Label
                        className="form-view-label"
                        htmlFor="formBasicClass"
                      >
                        Class Name
                      </Form.Label>
                      <Form.Select
                        required
                        name="classid"
                        value={feeMaster?.classid}
                        onChange={handleFeeChange}
                      >
                        <option value="">-- Select Class Name --</option>
                        {optionClasses.map((res) => (
                          <option key={res.value} value={res.value}>
                            {res.label}
                          </option>
                        ))}
                      </Form.Select>
                    </Form.Group>
                  </Col>
                  <Col lg={4}>
                    <Form.Group className=" my-3 mx-2">
                      <Form.Label
                        className="form-view-label"
                        htmlFor="formBasicClass"
                      >
                        Type
                      </Form.Label>
                      <Form.Select
                        required
                        name="type"
                        value={feeMaster?.type}
                        onChange={handleFeeChange}
                      >
                        <option value="">-- Select Type --</option>
                        <option value="Monthly">Monthly</option>
                        <option value="Bi-Monthly">Bi-Monthly</option>
                        <option value="Quarterly">Quarterly</option>
                        <option value="Half Yearly">Half Yearly</option>
                        <option value="Yearly">Yearly</option>
                      </Form.Select>
                    </Form.Group>
                  </Col>
                  <Col lg={4}>
                    <Form.Group className="my-3 mx-2">
                      <Form.Label
                        className="form-view-label"
                        htmlFor="formBasicFirstName"
                      >
                        Session
                      </Form.Label>
                      <Form.Select
                        name="sessionid"
                        value={
                          feeMaster.sessionid === ""
                            ? currentSession && currentSession.id
                            : feeMaster.sessionid && feeMaster.sessionid
                        }
                        onChange={handleFeeChange}
                        
                       >
                        <option value="">-- Select Session --</option>
                        {sessionYear.map((session) => (
                          <option key={session.id} value={session.id}>
                            {session.year}
                          </option>
                        ))}
                      </Form.Select>
                    </Form.Group>
                  </Col>
                  <Col lg={4}>
                    <Form.Group className="my-3 mx-2">
                      <Form.Label
                        className="form-view-label"
                        htmlFor="formBasicClass"
                      >
                        Structure Type<span style={{ color: "red" }}>*</span>
                      </Form.Label>
                      <Form.Select
                        required
                        name="fee_structure"
                        value={feeMaster?.fee_structure}
                        onChange={handleFeeChange}
                      >
                        <option value="">-- Select Structure Type --</option>
                        <option value="New">New</option>
                        <option value="Old">Old</option>
                      </Form.Select>
                    </Form.Group>
                  </Col>

                  <Col lg={4}>
                    <Form.Group className="my-3 mx-2">
                      <Form.Label
                        className="form-view-label"
                        htmlFor="nameInput"
                      >
                        Status<span style={{ color: "red" }}>*</span>
                      </Form.Label>
                      <Form.Select
                        required
                        name="status"
                        value={feeMaster?.status}
                        onChange={handleFeeChange}
                      >
                        <option value="">-- Select Status --</option>
                        <option value="Active">Active</option>
                        <option value="InActive">InActive</option>
                      </Form.Select>
                    </Form.Group>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row className="mt-3">
              <Col lg={4}></Col>
              <Col lg={4}>
              </Col>
              <Col lg={4}></Col>
            </Row>
          </Row>
          <Row>
            <Col lg={3}></Col>
          </Row>
          {showInstallment && (
            <>
              <Row>
                <Col lg={12}>
                  <Col className="mx-3">
                    <Col className="section-header my-3">
                      <span style={{ color: "black" }}>Fee Configuration</span>
                    </Col>
                  </Col>
                </Col>

                {/* start--------------------------------- ReWork ---------------------------- */}
                <Row>
                  <Col lg={5}>
                    <Form.Group className="mx-3 fees">
                      <Form.Label
                        className="form-view-label"
                        htmlFor="formBasicClass"
                      >
                        Select fee head
                      </Form.Label>
                      <Select
                        placeholder="Select fee head"
                        value={selectedFeeHeadMaster}
                        onChange={handleFeeHeadMaster}
                        options={optionFeeHeadMaster}
                        isMulti={true}
                        name="fee_head_master_id"
                      ></Select>
                    </Form.Group>
                  </Col>
                  <Col lg={5}>
                    <Form.Group className="mx-3 fees">
                      <Form.Label
                        className="form-view-label"
                        htmlFor="formBasicClass"
                      >
                        Select Months
                      </Form.Label>
                      <Select
                        placeholder="Select month"
                        value={optionMonths}
                        onChange={handleMonthMaster}
                        options={formatedSelectedMonth}
                        isMulti={true}
                        name="month"
                      ></Select>
                    </Form.Group>
                  </Col>
                  <Col lg={2}>
                    <Row style={{ textAlign: "center", marginTop: "24px" }}>
                    </Row>
                  </Col>
                </Row>

                <Row className="my-3">
                <ConfirmationDialog
                                open={removeTab}
                                onClose={() => setRemoveTab(false)}
                                onConfirm={() => removeSelectedTab(removeMonth)}
                                message={`Are you sure you want to remove the ${removeMonth}?`}
                              />
                  <Form.Group className="text-center mx-3">
                    <Tabs
                      id="month-tab"
                      activeKey={activeTab ? activeTab : sortedMonths.month}
                      onSelect={handleTabSelect}
                    >
                      {sortedMonths ? sortedMonths.map((month, index) => (
                        <Tab
                          eventKey={month.value}
                          title={
                            <span>
                              {month.value}
                              <RxCross2
                                style={{
                                  marginLeft: "5px",
                                  verticalAlign: "middle",
                                }}
                                onClick={() => removeTabHandler(month.value)}
                              />
                              {/* // last updated by shahir on 19-07-2024 */}

                              {/* </div> */}
                            </span>
                          }
                          key={index}
                        >
                          <Col lg={12} className="mt-3">
                            {finalData && finalData.length ? (
                              <DatatableWrapper
                                body={getBody(month?.value)}
                                headers={header}
                                paginationOptionsProps={{
                                  initialState: {
                                    rowsPerPage: 15,
                                    options: [5, 10, 15, 20],
                                  },
                                }}
                              >
                                <Table striped className="data-table">
                                  <TableHeader />
                                  <TableBody />
                                </Table>
                                {/* <Pagination /> */}
                              </DatatableWrapper>
                            ) : (
                              <DatatableWrapper
                                body={[]}
                                headers={header}
                                paginationOptionsProps={{
                                  initialState: {
                                    rowsPerPage: 15,
                                    options: [5, 10, 15, 20],
                                  },
                                }}
                              >
                                <Table striped className="data-table">
                                  <TableHeader />
                                  <TableBody />
                                </Table>
                                {/* <Pagination /> */}
                              </DatatableWrapper>
                            )}
                          </Col>
                        </Tab>
                      )):<ShimmerTable row={10} col={5} />}
                    </Tabs>
                  </Form.Group>
                </Row>

                {/* end--------------------------------- ReWork ------------------------------- */}
              </Row>
            </>
          ) }
        </Form>
      </Container>
</> 
:
<ShimmerTable row={10} col={5} />

}
      <Modal
        show={deleteFeeHeads}
        backdrop="static"
        centered
        aria-labelledby="contained-modal-title-vcenter"
        onHide={closeDeleteSelectedFeeHeads}
      >
        <Modal.Header>
          <Modal.Title>
            Are you sure you want to remove {selectedRow?.name}?
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form className="mt-3">
            <Row>
              {fixedmonths.map((res) => (
                <Col key={res.label} lg={6}>
                  <Form.Check
                    inline
                    type="checkbox"
                    label={res.label}
                    style={{ fontSize: "18px" }}
                    checked={monthsToBeRemoved.includes(res.label)}
                    onChange={() => applyToAllMonthHandler(res.label)}
                  />
                </Col>
              ))}
            </Row>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="light" onClick={closeDeleteSelectedFeeHeads}>
            Close
          </Button>
          <Button variant="danger" onClick={handleRemoveSelectedFeeHeads}>
            Remove
          </Button>
        </Modal.Footer>
      </Modal>
      <ToastContainer />

    </>
  );
};

export default FeeMasterEdit;