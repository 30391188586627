import React, { useEffect, useState } from "react";
import "./App.css";
import "./resources/css/Sidebar.css";
import "bootstrap/dist/css/bootstrap.min.css";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import Login from "./components/Login";
import Error from "./components/error/Error";
import Home from "./components/home/Home";
import AssignmentList from "./components/homework/AssignmentList";
import * as constants from "./constants/CONSTANT";
import StudentEditDetails from "./components/studentAdmission/studentEditDetails";
import {
  StudentList,
  StudentView,
  StudentEdit,
  StudentEnquiry,
  StudentSibling,
} from "./components/student";
import {
  StudentAddmissionEdit,
  StudentAddmissionList,
  StudentAddmissionView,
} from "./components/studentAdmission";
import PubSub from "pubsub-js";
import { Toast, ToastContainer } from "react-bootstrap";
import { PATHS } from "./constants/WebRouting";
import Auth from "./components/Auth/Auth";
import NoAccess from "./components/common/NoAccess";
import jwt_decode from "jwt-decode";
import {
  AddAssignSubjectClass,
  AssignSubjectClassList,
  EditAssignSubjectClass,
} from "./components/assignSubjectClass"; // Added By Abdul Pathan
import { SubjectList } from "./components/subject"; // Added By Abdul Pathan || 11 August 2023
import { SectionList } from "./components/section"; // Added By Abdul Pathan
import ClassList from "./components/classes/ClassList"; // Added By Abdul Pathan
import {
  StaffList,
  StaffView,
  StaffEdit,
  StaffAttendanceView,
} from "./components/employee"; // Added by Pawan Singh Sisodiya
import {
  LocationMaster,
  LocationMasterList,
  FareMasterList,
  FareMaster,
  VehicleAdd,
  VehicleList,
  VehicleUpdate,
  RouteList,
  RouteAdd,
} from "./components/transport"; // Added by Shivam Shrivastava
import {
  StudentRteList,
  StudentRteView,
  StudentRteEdit,
} from "./components/rte";
import {
  ExamSchedule,
  ExamScheduleEdit,
  ExamScheduleView,
} from "./components/exam"; //Added by Pawan Singh Sisodiya
import ExamList from "./components/exam/ExamList"; //Added by Pawan Singh Sisodiya
import AddEvent from "./components/event/AddEvent"; //Added by Shakib Khan
import Event from "./components/event/Event"; //Added by Shakib Khan
import { AttendanceMaster } from "./components/attendance_master";
import {
  AttendanceLineItem,
  AddAttendanceLineItem,
  ViewAttendanceLineItem,
} from "./components/attendance_line_item";
import FeesHeadMasterList from "./components/fee/FeesHeadMasterList ";
import FeeMasterList from "./components/fee/FeeMasterList";
import FeeMasterEdit from "./components/fee/FeeMasterEdit";
import FeeMasterLineItems from "./components/fee/FeeMasterLineItems";
import FeeMasterView from "./components/fee/FeeMasterView";
import FeeInstallmentLineItemsView from "./components/fee/FeeInstallmentLineItemsView";
import FeeDeposite from "./components/fee/FeeDeposite";
import FeeDepositeEdit from "./components/fee/FeeDepositeEdit";
import FeeDepositeHistory from "./components/fee/FeeDepositeHistory";
import FeeDiscount from "./components/fee/FeeDiscount";
import SessionList from "./components/session/SessionList";
import AssignTransport from "./components/transport/AssignTransport";
import {
  // createRolePermissionModal,
  ModuleList,
  RoleList,
} from "./components/permisssion";
import PermissionList from "./components/permisssion/PermissionList";
import RolePermissionList from "./components/permisssion/RolePermissionList";
import { SyllabusList } from "./components/syllabus";
import SyllabusViewPage from "./components/syllabus/SyllabusViewPage";
import { TimeTable, PrintTimeTable } from "./components/timetable";
import { TimeSlotList } from "./components/timetableSlot";
import { QuickLauncher } from "./components/quickLauncher";
import {
  ListOfBook,
  PublisherList,
  SupplierList,
  AuthorLIst,
  CategoryList,
  LanguageList,
  PurchaseList,
  IssueBookList,
} from "./components/library";
import BookView from "./components/library/BookView";
import IssueView from "./components/library/IssueView";
import ViewLanguage from "./components/library/ViewLanguage";
import SupplierView from "./components/library/SupplierView";
import CategoryView from "./components/library/CategoryView";
import AuthorView from "./components/library/AuthorView";
import PublisherView from "./components/library/PublisherView";
import SectionView from "./components/section/SectionView";
import Company from "./components/company/index";
import { Promotion } from "./components/promotion";
import PromotionView from "./components/promotion/promotionView"; // added by mohd. sarfraj |23-Aug-2024
//import ReportsDetails from "./components/Reports/ReportsDetails";
import WarningPage from "./components/WarningPage";

import { PermissionProvider } from "./components/contexts/PermissionContext";
import ProtectedRoute from "./components/ProtectedRoute";
import UserList from "./components/user/UserList";
import Profile from "./components/Profile";
import FeeReceipt from "./components/fee/FeeReceipt";
import ResultList from "./components/exam/ResultList";
import ReportFeeDeposit from "./components/Reports/ReportFeeDeposit";
import FeeCollectionReport from "./components/Reports/FeeCollectionReport";
import StaffReport from "./components/Reports/StaffReport";
import ReportsDetails from "./components/Reports/ReportsDetails";
import DueFeeReport from "./components/Reports/DueFeeReport";
import SettingList from "./components/settings/SettingList";
import FeeReconciliation from "./components/Reports/FeeReconciliation";
import StudentBooksMonthlyReports from "./components/Reports/StudentBooksMonthlyReports";
import Main from "./components/layout/Main";
import DailyAttendance from "./components/markAttendance/dailyAttendance";
import MonthlyAttendance from "./components/markAttendance/monthlyAttendance";
import schoolApi from "./api/schoolApi";
import DriverList from "./components/transport/DriverList";
import StudentMainTab from "./components/studentAdmission/StudentMainTab";
import Anouncement from "./components/anouncement/Anouncement";
import Campaign from "./components/Whatsapp/campaign/Campaign";
import Groups from "./components/Whatsapp/groups/Groups";
import ResponseMessage from "./components/Whatsapp/response_message/ResponseMessage";
import WhatsAppSetting from "./components/Whatsapp/whatsapp_setting/WhatsAppSetting";
import WhatsAppMessenger from "./components/Whatsapp/whatsapp_messenger/WhatsAppMessenger";
import Templates from "./components/Whatsapp/whatsapp_template/Templates";
import CampaignAdd from "./components/Whatsapp/campaign/CampaignAdd";
import TemplateAdd from "./components/Whatsapp/whatsapp_template/TemplateAdd";
import CampaignView from "./components/Whatsapp/campaign/CampaignView";
import GroupView from "./components/Whatsapp/groups/GroupView";
// import ForgetPassword from "./components/ForgetPassword";
// import ResetPassword from "./components/ResetPassword";
import ForgetPassword from "./components/ForgotPassword";
import ResetPassword from "./components/ResetPassword";
import io from "socket.io-client";
import ManageAttendanceMode from "./components/markAttendance/ManageAttendanceMode";

const ENDPOINT = 'https://login.idigischool.com/' || 'https://demo.idigischool.com/' || 'https://mpsbherunda.idigischool.com/'|| 'http://localhost:4010';


function App() {
  const rteUrl = "rte";
  const [typeOfattendance, setTypeOfattendance] = useState(
    localStorage.getItem("typeOfattendance") || ""
  );
  const [modalShow, setModalShow] = useState(false);
  const [title, setTitle] = useState("Confirmation");
  const [message, setMessage] = useState("");

  const [variant, setVariant] = useState("");
  const [userInfo, setUserInfo] = useState({});
  const [permissions, setPermissions] = useState();
  const mySubscriber = (msg, data) => {
    switch (msg) {
      case "RECORD_SAVED_TOAST":
        setTitle(data.title);
        setMessage(data.message);
        setModalShow(true);
        setVariant("success");
        break;
      case "RECORD_ERROR_TOAST":
        setTitle(data.title);
        setMessage(data.message);
        setModalShow(true);
        setVariant("danger");
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (sessionStorage.getItem("permissiontoken")) {
          //let user = jwt_decode(sessionStorage.getItem("token"));
          let user = jwt_decode(sessionStorage.getItem("permissiontoken"));
          setUserInfo(user);
          var perm = user.permissions
            .map(function (obj) {
              return obj.name;
            })
            .join(";");
          setPermissions(perm);
        }
      } catch (error) {
        console.log(error);
      }
    };

    fetchData(); // Call fetchData inside useEffect

    // Subscribe to PubSub events
    PubSub.subscribe("RECORD_SAVED_TOAST", mySubscriber);
    PubSub.subscribe("RECORD_ERROR_TOAST", mySubscriber);

    // Cleanup function to unsubscribe from PubSub events
    return () => {
      PubSub.unsubscribe("RECORD_SAVED_TOAST");
      PubSub.unsubscribe("RECORD_ERROR_TOAST");
    };
  }, []); // Empty dependency array to run only once

  const handleSetAttendanceMode = (value) => {
    console.log("handleSetAttendanceMode@@=>", value);
    setTypeOfattendance(value);
    localStorage.setItem("typeOfattendance", value);
  };

  const [connectedSocket, setConnectedSocket] = useState();

  useEffect(() => {
    try {

      const token = sessionStorage.getItem('token');
      console.log('###token', token)
      if (token) {
        let user = jwt_decode(token);
        setUserInfo(user);
        console.log('###user', user)
        // const perm = user.permissions.map((obj) => obj.name).join(';');
        // setPermissions(perm);

        let socket = io(ENDPOINT, {
          path: '/ibs/socket.io',
          transports: ['websocket'],
          reconnection: true,
          // reconnectionAttempts: 5,
          // reconnectionDelay: 1000,
        });

        socket.on("connect", () => {
          console.log('Socket connected:', socket.id);
          socket.emit("setup", user);
          setConnectedSocket(socket);
        });

        socket.on("connected", () => {
          console.log('Socket setup complete');
        });

        socket.on("receivedwhatsappmessage", (item) => {
          console.log('#Received WhatsApp item:', item);
          console.log('##socket', socket)
        });

        socket.on("disconnect", (reason) => {
          console.log('Socket disconnected:', reason);
        });

        socket.on("connect_error", (err) => {
          console.error('Connection error:', err);
        });

        setConnectedSocket(socket);

        return () => {
          if (connectedSocket) {
            connectedSocket.disconnect();
            console.log('Socket disconnected on cleanup');
          }
        }
      } else {
        console.log('No token found');
      }
    } catch (error) {
      console.error('Error in useEffect:', error);
    }
  }, []);

  return (
    <React.Fragment key={Math.random()}>
      <ToastContainer className="p-3" position="top-center">
        <Toast
          show={modalShow}
          onClose={() => setModalShow(false)}
          delay={3000}
          bg={variant}
          className="text-white"
          autohide
        >
          {variant === "success" ? (
            <div
              className="p-1 m-1"
              style={{ backgroundColor: "#198754", color: "white" }}
            >
              <i className="fa-regular fa-circle-check text-white mx-2"></i>
              <strong className="me-auto">{title}</strong>
              <i
                className="fa-solid fa-xmark text-white float-right"
                style={{ float: "right" }}
                role="button"
                onClick={() => setModalShow(false)}
              ></i>
            </div>
          ) : (
            <div
              className="p-1 m-1"
              style={{ backgroundColor: "#DC3545", color: "white" }}
            >
              <i className="fa-regular fa-circle-check text-white mx-2"></i>
              <strong className="me-auto">{title}</strong>
              <i
                className="fa-solid fa-xmark text-white float-right"
                style={{ float: "right" }}
                role="button"
                onClick={() => setModalShow(false)}
              ></i>
            </div>
          )}

          <Toast.Body>
            {message instanceof Array ? (
              message.map((item) => {
                return <span>{item.msg}</span>;
              })
            ) : (
              <span>{message}</span>
            )}
          </Toast.Body>
        </Toast>
      </ToastContainer>
      <PermissionProvider>
        <Router>
          <Routes>
            <Route path="/login" element={<Login />} />
            <Route path="/forget-password" element={<ForgetPassword />} />
            <Route path="/reset-password/:email" element={<ResetPassword />} />
            <Route
              element={
                <Auth
                  allowedRoles={[constants.VIEW_CONTACT, constants.MODIFY_ALL]}
                />
              }
            ></Route>
            <Route path="/no-access" element={<NoAccess />} />

            <Route element={<Main socket={connectedSocket} />}>
              <Route
                path={PATHS.HOME}
                element={
                  <ProtectedRoute
                    element={<Home tabName="Home" />}
                    permissionKey="Home"
                    can="read"
                  />
                }
              />
              {/* *********** Permission Module By Shakib Khan and shivam start********** */}
              <Route
                path="/permissionlist"
                
                element={
                  <PermissionList tabName={constants.TAB_NAME.rolepermissionList} />
                }
              />
              <Route
                path="/quick-launcher"
                
                element={<QuickLauncher tabName={constants.TAB_NAME.QuickLauncher} />}
              />
              {/* *********** Session Module By Shakib Khan start********** */}
              <Route
                path="/sessionlist"
                
                element={
                  <ProtectedRoute
                    element={
                      <SessionList tabName={constants.TAB_NAME.session} />
                    }
                    permissionKey="session_list"
                    can="read"
                  />
                }
              />

              <Route
                path="/rolelist"
                
                element={
                  <ProtectedRoute
                    element={<RoleList tabName={constants.TAB_NAME.roleList} />}
                    permissionKey="Role_List"
                    can="read"
                  />
                }
              />
              <Route
                path="/rolepermissionlist"
                
                element={
                  <ProtectedRoute
                    element={
                      <RolePermissionList
                        tabName={constants.TAB_NAME.rolepermissionList}
                      />
                    }
                    permissionKey="Role_Permission_List"
                    can="read"
                  />
                }
              />
              <Route
                path="/section/:id"
                
                // element={<SectionView />}
                element={
                  <ProtectedRoute
                    element={<SectionView />}
                    permissionKey="Section_List"
                    can="read"
                  />
                }
              />
              <Route
                path="/modulelist"
                
                element={
                  <ProtectedRoute
                    element={
                      <ModuleList tabName={constants.TAB_NAME.moduleList} />
                    }
                    permissionKey="Module_List"
                    can="read"
                  />
                }
              />
              <Route
                path="/addrolepermission"
                
                element={
                  <createRolePermissionModal
                    tabName={constants.TAB_NAME.addRolePermission}
                  />
                }
              />

              {/* ******* Syllabus Module added by Shakib ******* */}
              <Route
                path="/syllabuslist"
                
                element={
                  <ProtectedRoute
                    element={
                      <SyllabusList tabName={constants.TAB_NAME.syllabus} />
                    }
                    permissionKey="Syllabus_List"
                    can="read"
                  />
                }
              />

              <Route
                path="/syllabusview/:id"
                
                element={
                  <ProtectedRoute
                    element={
                      <SyllabusViewPage
                        tabName={constants.TAB_NAME.syllabusView}
                      />
                    }
                    permissionKey="Syllabus_List"
                    can="read"
                  />
                }
              />

              {/************ Student Module By : Pooja Vaishnav ************/}
              <Route
                path="/studentenquiry"
                
                // element={<StudentEnquiry />} //"Create Student"
                element={
                  <ProtectedRoute
                    element={<StudentEnquiry />}
                    permissionKey="Students_Enquiry_List"
                    can="create"
                  />
                }
              />
              <Route
                path="/student/:id"
                
                element={
                  <StudentSibling
                    tabName={constants.TAB_NAME.viewStudentSibling}
                  />
                }
              />

              <Route
                path="/student/registration"
                element={
                  <ProtectedRoute
                    element={
                      <StudentMainTab
                        tabName={constants.TAB_NAME.createStudent}
                      />
                    }
                    permissionKey="Students_Registration_List"
                    can="create"
                  />
                }
              />
              <Route
                path="studentaddmissions/:id/e"
                element={
                  <ProtectedRoute
                    element={
                      <StudentAddmissionEdit
                        tabName={constants.TAB_NAME.editStudentAddmission}
                      />
                    }
                    permissionKey="Students_Registration_List"
                    can="edit"
                  />
                }
              />

              {/* Added by Pooja Vaishnav(01-07-2024) */}
              <Route
                path="studentEditDeatil/:id/e"
                element={
                  <ProtectedRoute
                    element={
                      <StudentEditDetails
                        tabName={constants.TAB_NAME.editStudentDetails}
                      />
                    }
                    //  permissionKey="Students_Registration_List"
                    can="edit"
                  />
                }
              />

              {/** Show All Students Addmission **/}

              <Route
                path="/studentaddmissions/"
                
                element={
                  <ProtectedRoute
                    element={
                      <StudentAddmissionList
                        tabName={constants.TAB_NAME.studentAddmissionList}
                      />
                    }
                    permissionKey="Students_Registration_List"
                    can="read"
                  />
                }
              />
              {/** Show a Student Addmission By Id **/}
              <Route
                path="studentaddmissions/:id"
                
                element={
                  <ProtectedRoute
                    element={
                      <StudentAddmissionView
                        tabName={constants.TAB_NAME.viewStudentAddmission}
                      />
                    }
                    permissionKey="Students_Registration_List"
                    can="read"
                  />
                }
              />
              {/* Contact Student */}
              {/******** Crate a Contact Student *******/}
              {/* <Route
                path="/student/e"
                
                element={
                  <ProtectedRoute
                    element={
                      <StudentEdit tabName={constants.TAB_NAME.createStudent} />
                    }
                    permissionKey="Students_Registration_List"
                    can="create"
                  />
                }
              /> */}
              {/******** Edit Student Contact By Id *******/}
              <Route
                path="/student/e"
                
                element={
                  // <StudentEdit tabName={constants.TAB_NAME.editStudent} />
                  <StudentMainTab tabName={constants.TAB_NAME.createStudent} />
                } //"Edit Student"
                permissionKey="Students_Registration_List"
                can="create"
              />

              {/******** Show All Students Contacts *******/}
              <Route
                path="/students"
                
                element={
                  <ProtectedRoute
                    element={
                      <StudentList tabName={constants.TAB_NAME.studentsList} />
                    }
                    permissionKey="Students_Enquiry_List"
                    can="read"
                  />
                }
              />

              <Route
                path="/assigntransport"
                
                element={
                  <AssignTransport
                    tabName={constants.TAB_NAME.assigntransport}
                  />
                }
              />

              {/******** Show a Student Contact By Id *******/}
              <Route
                path="/students/:id"
                
                element={
                  <ProtectedRoute
                    element={
                      <StudentView tabName={constants.TAB_NAME.viewStudent} />
                    }
                    permissionKey="Students_Enquiry_List"
                    can="read"
                  />
                }
              />
              {/******** Edit Student Contact By Id *******/}
              {/* <Route
                path="/student/:id/e"
                
                element={
                  <StudentEdit tabName={constants.TAB_NAME.editStudent} />
                } //"Edit Student"
              />
               */}
              {/******** Edit Student Contact By Id *******/}
              <Route
                path="/student/:id/e"
                
                element={
                  // <StudentEdit tabName={constants.TAB_NAME.editStudent} />
                  <StudentMainTab tabName={constants.TAB_NAME.editStudent} />
                } //"Edit Student"
              />

              {/********Abdul Pathan***********************/}
              {/* ******* Subjects Module Added by Abdul Pathan ****** */}
              <Route
                path="/subjects"
                
                element={
                  <ProtectedRoute
                    element={<SubjectList tabName={constants.TAB_NAME.subjectList} />}
                    permissionKey="Subject_List"
                    can="read"
                  />
                }
              />
              <Route
                path="/assignsubjectclass" //assignsubjectclass
                
                element={
                  <ProtectedRoute
                    element={<AssignSubjectClassList tabName={constants.TAB_NAME.assignedSubjectsList} />}
                    permissionKey="Assign_Subject_Class"
                    can="read"
                  />
                }
              />

              <Route
                path="/assignsubjectclass/e" //add assignsubjectclass
                
                element={
                  <ProtectedRoute
                    element={<AddAssignSubjectClass tabName={constants.TAB_NAME.assignSubjectsToClass} />}
                    permissionKey="Assign_Subject_Class"
                    can="create"
                  />
                }
              />

              {/* ******* Section Module Added by Abdul Pathan ****** */}
              <Route
                path="/section"
                
                element={
                  <ProtectedRoute
                    element={<SectionList tabName={constants.TAB_NAME.SectionList} />}
                    permissionKey="Section_List"
                    can="read"
                  />
                }
              />

              {/* ******* Classes Module Added by Abdul Pathan ****** */}
              <Route
                path="/classes"
                
                element={
                  <ProtectedRoute
                    element={<ClassList tabName={constants.TAB_NAME.classList} />}
                    permissionKey="Class_List"
                    can="read"
                  />
                }
              />

              {/* ********** Employee Module Added by Pawan Singh Sisodiya ********** */}
              <Route
                path={"/staff/e"}
                
                element={
                  <ProtectedRoute
                    element={
                      <StaffEdit
                        selectedRecordType="staff"
                        tabName={constants.TAB_NAME.createStaff}
                      />
                    }
                    permissionKey="Employee_List"
                    can="create"
                  />
                }
              //Create a Staff Contact
              />
              <Route
                path="/staffs"
                
                element={
                  <ProtectedRoute
                    element={
                      <StaffList tabName={constants.TAB_NAME.staffList} />
                    }
                    permissionKey="Employee_List"
                    can="read"
                  />
                }
              />
              <Route
                path="/staff/:id"
                
                element={
                  <ProtectedRoute
                    element={
                      <StaffView tabName={constants.TAB_NAME.viewStaff} />
                    }
                    permissionKey="Employee_List"
                    can="read"
                  />
                }
              />
              <Route
                path="/staff/:id/e"
                element={
                  <ProtectedRoute
                    element={
                      <StaffEdit tabName={constants.TAB_NAME.editStaff} />
                    }
                    permissionKey="Employee_List"
                    can="edit"
                  />
                }
              />

              <Route
                path="/attendances"
                
                element={
                  <StaffAttendanceView
                    tabName={constants.TAB_NAME.Attendance}
                  />
                } //Staff Attendance
              />

              {/************ Transport Module Added By Shivam Shrivastava ***********/}
              <Route
                path="/transportation/locationmaster"
                element={
                  <LocationMaster tabName={constants.TAB_NAME.locationMaster} />
                }
              />
              <Route
                path="/transportation/locationlist"
                element={
                  <ProtectedRoute
                    element={
                      <LocationMasterList tabName={constants.TAB_NAME.locationList}/>
                    }
                    permissionKey="Transport"
                    can="read"
                  />
                }
              />
              <Route
                path="/transportation/faremaster"
                
                element={<FareMaster tabName={constants.TAB_NAME.FareMasterList} />}
              />
              <Route
                path="/transportation/farelist"
                
                element={
                  <ProtectedRoute
                    element={
                      <FareMasterList tabName={constants.TAB_NAME.FareMasterList} />
                    }
                    permissionKey="Transport"
                    can="read"
                  />
                }
              />
              {/* vehicle */}
              <Route
                path="/transportation/vehicles"
                
                element={
                  <ProtectedRoute
                    element={
                      <VehicleList tabName={constants.TAB_NAME.VehicleList} />
                    }
                    permissionKey="Transport"
                    can="read"
                  />
                }
              />

              {/* driver  | Updated StaffEdit to DriverList by Mohd. Sarfraj - 31-Aug.-2024. */}
              <Route
                path={"/driver"}
                
                element={
                  <ProtectedRoute
                    element={
                      <DriverList
                        selectedRecordType="driver"
                        tabName={constants.TAB_NAME.DriverList}
                      />
                    }
                    permissionKey="Driver_List"
                    can="read"
                  />
                }
              />

              {/*--------- Added by Mohmmad Sarfraj | 31-Aug-2024. ----------*/}
              <Route
                path={"/driver/e"}
                
                element={
                  <ProtectedRoute
                    element={
                      <StaffEdit
                        selectedRecordType="driver"
                        tabName={constants.TAB_NAME.registerDriver}
                      />
                    }
                    permissionKey="Employee_List"
                    can="create"
                  />
                }
              />
              <Route
                path="/transportation/vehicle/add"
                
                element={
                  <VehicleAdd tabName={constants.TAB_NAME.vehiclesadd} />
                }
              />
              <Route
                path="/transportation/vehicle/update/:id"
                
                element={
                  <VehicleUpdate tabName={constants.TAB_NAME.vehiclesupdate} />
                }
              />
              {/* driver */}
              {/* <Route
                path={"/driver/e"}
                
                element={<ProtectedRoute element={<StaffEdit selectedRecordType="driver" tabName={constants.TAB_NAME.registerDriver} />}
                  permissionKey="Employee_List"
                  can="create"
                />}
              /> */}
              <Route
                path={"/driver/:id"}
                
                element={<StaffView />}
              />
              <Route
                path={"/driver/:id/e"}
                
                element={<StaffEdit selectedRecordType="driver" />}
              />

              {/* *********** RTE Students Module By Shakib Khan ********** */}
              <Route
                path="/rte"
                
                element={
                  <ProtectedRoute
                    element={
                      <StudentRteList tabName={constants.TAB_NAME.rte} />
                    }
                    permissionKey="RTE_Student_List"
                    can="read"
                  />
                }
              />

              <Route
                path="/rte/:id"
                
                element={
                  <ProtectedRoute
                    element={
                      <StudentRteView
                        tabName={constants.TAB_NAME.viewStudent}
                      />
                    }
                    permissionKey="RTE_Student_List"
                    can="read"
                  />
                }
              />

              <Route
                path="studentRtes/:id/e"
                
                // element={
                //   <StudentRteEdit tabName={constants.TAB_NAME.StudentRteEdit} /> //"Edit Student Addmission"
                // }
                element={
                  <ProtectedRoute
                    element={
                      <StudentRteEdit
                        tabName={constants.TAB_NAME.StudentRteEdit}
                      />
                    }
                    permissionKey="RTE_Student_List"
                    can="read"
                  />
                }
              />
              <Route
                path="/rte/e"
                
                element={
                  <ProtectedRoute
                    element={
                      <StudentEdit
                        tabName={constants.TAB_NAME.createStudent}
                        rteUrlName={rteUrl}
                      />
                    }
                    permissionKey="RTE_Student_List"
                    can="create"
                  />
                }
              />
              {/* <Route
              path="/rte/:id"
              
              element={<StudentView tabName={constants.TAB_NAME.viewStudent} />}//"View rte Student"
            /> */}
              {/* <Route
              path="/rte/:id/e"
              
              element={<StudentEdit tabName={constants.TAB_NAME.editStudent} />}//"Edit Student"
            /> */}

              {/* *********** Event Module By Shakib Khan ********** */}
              <Route
                path="/addevent"
                
                element={
                  <ProtectedRoute
                    element={<AddEvent tabName={constants.TAB_NAME.AddEvent} />}
                    permissionKey="Add_Events"
                    can="create"
                  />
                }
              />
              <Route
                path="/eventscalender"
                
                element={
                  <ProtectedRoute
                    element={
                      <Event tabName={constants.TAB_NAME.Event} />
                    }
                    permissionKey="Events_Calendar"
                    can="read"
                  />
                }
              />

              {/* *********** Exam Module By Pawan Singh Sisodiya ********** */}
              <Route
                path="/examlist"
                
                element={
                  <ProtectedRoute
                    element={<ExamList tabName={constants.TAB_NAME.examTitle} />}
                    permissionKey="Exam_List"
                    can="read"
                  />
                }
              />

              <Route
                path="/examschedule"
                
                element={
                  <ProtectedRoute
                    element={
                      <ExamSchedule tabName={constants.TAB_NAME.examSchedule} />
                    }
                    permissionKey="Exam_Schedule"
                    can="read"
                  />
                }
              />

              <Route
                path="/resultlist"
                
                element={
                  <ProtectedRoute
                    element={
                      <ResultList tabName={constants.TAB_NAME.examTerm} />
                    }
                    permissionKey="resultlist"
                    can="read"
                  />
                }
              />

              <Route
                path="/reportfeedeposit"
                
                element={
                  <ProtectedRoute
                    element={
                      <ReportFeeDeposit tabName={constants.TAB_NAME.feeDepositReport} />
                    }
                    permissionKey="resultlist"
                    can="read"
                  />
                }
              />
              {/*Added BY Pooja Vaishnav(05-07-2024) */}
              <Route
                path="/staffreport"
                
                element={
                  <ProtectedRoute
                    element={
                      <StaffReport tabName={constants.TAB_NAME.staffReport} />
                    }
                    permissionKey="Reports"
                    can="read"
                  />
                }
              />

              {/*Added BY Shakib (06-Aug-24)*/}
              <Route
                path="/studentbooksmonthlyreports"
                
                element={
                  <ProtectedRoute
                    element={
                      <StudentBooksMonthlyReports
                        tabName={constants.TAB_NAME.monthlySheet}
                      />
                    }
                    permissionKey="Reports"
                    can="read"
                  />
                }
              />

              <Route
                path="/feereconciliation"
                
                element={
                  <ProtectedRoute
                    element={
                      <FeeReconciliation
                        tabName={constants.TAB_NAME.reportFeeReconcilation}
                      />
                    }
                    permissionKey="Reports"
                    can="read"
                  />
                }
              />

              {/*Added BY Shakib*/}
              <Route
                path="/studentreport"
                
                element={
                  <ProtectedRoute
                    element={
                      <ReportsDetails tabName={constants.TAB_NAME.studentReport} />
                    }
                    permissionKey="Reports"
                    can="read"
                  />
                }
              />

              <Route
                path="/duefeereport"
                
                element={<DueFeeReport tabName={constants.TAB_NAME.DueFeeReport} />}
              />

              {/* ================================ Shakib Setting work ====================================  */}
              <Route
                path="/settings"
                
                element={
                  <ProtectedRoute
                    element={
                      <SettingList tabName={constants.TAB_NAME.Settings}
                        setAttendanceType={handleSetAttendanceMode}
                      />
                    }
                    permissionKey="Settings"
                    can="read"
                  />
                }
              />

              {/* added by shivam 5 july 2024 for report fee */}
              <Route
                path="/getfeereport"
                
                element={
                  <ProtectedRoute
                    element={
                      <FeeCollectionReport
                        tabName={constants.TAB_NAME.reportData}
                      />
                    }
                    permissionKey="Reports"
                    can="read"
                  />
                }
              />

              <Route
                path="/examschedule/e"
                
                element={
                  <ProtectedRoute
                    element={
                      <ExamScheduleEdit
                        tabName={constants.TAB_NAME.examSchedule}
                      />
                    }
                    permissionKey="Exam_Schedule"
                    can="create"
                  />
                }
              />

              <Route
                path="/examschedule/:id/e"
                
                element={
                  <ProtectedRoute
                    element={
                      <ExamScheduleEdit
                        tabName={constants.TAB_NAME.examSchedule}
                      />
                    }
                    permissionKey="Exam_Schedule"
                    can="edit"
                  />
                }
              />

              <Route
                path="/examscheduleview/:id"
                
                element={
                  <ProtectedRoute
                    element={
                      <ExamScheduleView
                        tabName={constants.TAB_NAME.examSchedule}
                      />
                    }
                    permissionKey="Exam_Schedule"
                    can="read"
                  />
                }
              />

              {/* add this route by shahir hussain 26-11-2-24 */}
              <Route
                path="/list_attendance"
                
                element={
                  <ManageAttendanceMode tabName={constants.TAB_NAME.Attendance}/>
                }
              />

              {/******** Edit Student Contact By Id *******/}
              <Route
                path="/studentenquiry/:id/e"
                
                element={
                  <ProtectedRoute
                    element={
                      <StudentEnquiry
                        tabName={constants.TAB_NAME.editStudent}
                      />
                    }
                    permissionKey="Students_Enquiry_List"
                    can="edit"
                  />
                }
              />
              {/* ATTENDANCE  END*/}
              <Route
                path="/transportation/routelist"
                
                element={
                  <ProtectedRoute
                    element={
                      <RouteList tabName={constants.TAB_NAME.RouteList} />
                    }
                    permissionKey="Transport"
                    can="read"
                  />
                }
              />
              <Route
                path="/transportation/route/add"
                
                element={<RouteAdd tabName={constants.TAB_NAME.routeadd} />}
              />
              {/********  Route Assignment Module(Shakib) *******/}
              <Route
                path="/assignmentlist"
                
                // element={<AssignmentList tabName={constants.TAB_NAME.assignment} />}
                element={
                  <ProtectedRoute
                    element={
                      <AssignmentList tabName={constants.TAB_NAME.assignment} />
                    }
                    permissionKey="Assignment_List"
                    can="read"
                  />
                }
              />
              {/********  Fee Module By Pooja Vaishnav & Pawan Singh Sisodiya *******/}
              <Route
                path="/feesheadmasterlist"
                
                element={
                  <ProtectedRoute
                    element={
                      <FeesHeadMasterList
                        tabName={constants.TAB_NAME.feeHeadMasterList}
                      />
                    }
                    permissionKey="Fee_Head_Master_List"
                    can="read"
                  />
                }
              />

              <Route
                path="/feesmasterlist"
                
                element={
                  <ProtectedRoute
                    element={
                      <FeeMasterList
                        tabName={constants.TAB_NAME.feeMasterList}
                      />
                    }
                    permissionKey="Fee_Master_List"
                    can="read"
                  />
                }
              />
              <Route
                path="/feesmasterAdd"
                
                element={
                  <FeeMasterEdit tabName={constants.TAB_NAME.feeMasterAdd} />
                }
              />
              <Route
                path="/fmasterlineitem"
                
                element={
                  <FeeMasterLineItems
                    tabName={constants.TAB_NAME.feeMasterLineItem}
                  />
                }
              />
              {/* ---------------------- ** Added by Pawan Singh Sisodiya 08 Dec 2023 ------------------------ */}
              <Route
                path="/feemasterview/:id"
                
                element={
                  <FeeMasterView tabName={constants.TAB_NAME.feeMasterList} />
                }
              />
              {/* ---------------------- ** Added by Pawan Singh Sisodiya 12 Dec 2023 ------------------------ */}
              <Route
                path="/feeinstallmentlineitems/:id"
                
                element={
                  <FeeInstallmentLineItemsView
                    tabName={constants.TAB_NAME.feeMasterList}
                  />
                }
              />
              {/* ---------------------- ** Added by Pawan Singh Sisodiya 23 Jan 2023 ------------------------ */}
              <Route
                path="/feedeposite"
                
                element={
                  <FeeDeposite tabName={constants.TAB_NAME.feeDeposite} />
                }
              />
              {/* ---------------------- ** Added by Pawan Singh Sisodiya 24 Jan 2023 ------------------------ */}
              <Route
                path="/feedepositeedit/e"
                
                element={
                  <FeeDepositeEdit
                    tabName={constants.TAB_NAME.feeDepositeEdit}
                  />
                }
              />
              {/* ---------------------- ** Added by Pawan Singh Sisodiya 24 Jan 2023 ------------------------ */}
              <Route
                path="/feedepositehistory/e"
                
                element={
                  <FeeDepositeHistory
                    tabName={constants.TAB_NAME.feeDepositeHistory}
                  />
                }
              />
              <Route
                path="/feereceipt"
                
                element={<FeeReceipt tabName={constants.TAB_NAME.FeeReceipt} />}
              />
              <Route
                path="/feesmastercreate"
                
                element={
                  <ProtectedRoute
                    element={
                      <FeeMasterEdit
                        tabName={constants.TAB_NAME.feeMasterAdd}
                      />
                    }
                    permissionKey="Fee_Master_List"
                    can="create"
                  />
                }
              // element={
              //   <FeeMasterEdit tabName={constants.TAB_NAME.feeMasterAdd} />
              // } // --- while add ---
              />
              <Route
                path="/feesmasteredit/:id"
                
                element={
                  <ProtectedRoute
                    element={
                      <FeeMasterEdit
                        tabName={constants.TAB_NAME.feeMasterAdd}
                      />
                    }
                    permissionKey="Fee_Master_List"
                    can="edit"
                  />
                }
              // element={
              //   <FeeMasterEdit tabName={constants.TAB_NAME.feeMasterAdd} />
              // } // --- while edit ---
              />
              <Route
                path="/feesdiscount"
                
                element={
                  <ProtectedRoute
                    element={
                      <FeeDiscount tabName={constants.TAB_NAME.feeDiscount} />
                    }
                    permissionKey="Fee_Master_List"
                    can="read"
                  />
                }
              //element={<FeeDiscount tabName={constants.TAB_NAME.feeDiscount} />}
              />
              <Route
                path="/studentsiblings"
                
                element={
                  <StudentEdit tabName={constants.TAB_NAME.viewStudent} />
                }
              />

              {/*Timetable slot Added By Pooja Vaishnav */}
              <Route
                path="/timeslot"
                
                element={
                  <TimeSlotList tabName={constants.TAB_NAME.TimeSlotList} />
                }
              />
              {/*Timetable Added By Pooja Vaishnav */}
              <Route
                path="/classtimetable"
                
                element={
                  <TimeTable tabName={constants.TAB_NAME.classtimetable} />
                }
              />
              {/*Printtimetable Added By Pooja Vaishnav */}
              <Route
                path="/printtimetable"
                
                element={
                  <PrintTimeTable tabName={constants.TAB_NAME.printtimetable} />
                }
              />

              {/* Start Added by Yamini Mishra */}

              <Route
                path="/books"
                
                element={
                  <ProtectedRoute
                    element={
                      <ListOfBook tabName={constants.TAB_NAME.booklist} />
                    }
                    permissionKey="Book_List"
                    can="read"
                  />
                }
              />

              <Route
                path="/books/:id"
                
                element={
                  <ProtectedRoute
                    element={<BookView tabName={constants.TAB_NAME.viewBook} />}
                    permissionKey="Book_List"
                    can="read"
                  />
                }
              />

              <Route
                path="/publisher"
                
                element={
                  <ProtectedRoute
                    element={
                      <PublisherList tabName={constants.TAB_NAME.publishersList} />
                    }
                    permissionKey="Publisher_List"
                    can="read"
                  />
                }
              />

              <Route
                path="/publisher/:id"
                
                element={
                  <ProtectedRoute
                    element={
                      <PublisherView tabName={constants.TAB_NAME.viewAuthor} />
                    }
                    permissionKey="Publisher_List"
                    can="read"
                  />
                }
              />

              <Route
                path="/supplier"
                
                element={
                  <ProtectedRoute
                    element={
                      <SupplierList tabName={constants.TAB_NAME.supplierList} />
                    }
                    permissionKey="Supplier_List"
                    can="read"
                  />
                }
              />

              <Route
                path="/supplier/:id"
                
                element={
                  <ProtectedRoute
                    element={
                      <SupplierView tabName={constants.TAB_NAME.viewSupplier} />
                    }
                    permissionKey="Supplier_List"
                    can="read"
                  />
                }
              />

              <Route
                path="/author"
                
                element={
                  <ProtectedRoute
                    element={
                      <AuthorLIst tabName={constants.TAB_NAME.authorList} />
                    }
                    permissionKey="Author_LIst"
                    can="read"
                  />
                }
              />

              {/* Add by Abhishek 07-05-2024 */}
              <Route
                path="/author/:id"
                
                element={
                  <ProtectedRoute
                    element={
                      <AuthorView tabName={constants.TAB_NAME.viewAuthor} />
                    }
                    permissionKey="Author_LIst"
                    can="read"
                  />
                }
              />

              <Route
                path="/category"
                
                element={
                  <ProtectedRoute
                    element={
                      <CategoryList tabName={constants.TAB_NAME.categoryList} />
                    }
                    permissionKey="Category_List"
                    can="read"
                  />
                }
              />

              <Route
                path="/category/:id"
                
                element={
                  <ProtectedRoute
                    element={
                      <CategoryView tabName={constants.TAB_NAME.viewCategory} />
                    }
                    permissionKey="Category_List"
                    can="read"
                  />
                }
              />

              <Route
                path="/language"
                
                element={
                  <ProtectedRoute
                    element={
                      <LanguageList tabName={constants.TAB_NAME.languageList} />
                    }
                    permissionKey="Language_List"
                    can="read"
                  />
                }
              />

              <Route
                path="/language/:id"
                
                element={
                  <ProtectedRoute
                    element={
                      <ViewLanguage tabName={constants.TAB_NAME.viewLanguage} />
                    }
                    permissionKey="Language_List"
                    can="read"
                  />
                }
              />

              <Route
                path="/purchase"
                
                element={
                  <ProtectedRoute
                    element={
                      <PurchaseList tabName={constants.TAB_NAME.purchaseList} />
                    }
                    permissionKey="Purchase_List"
                    can="read"
                  />
                }
              />
              <Route
                path="/issue_book"
                
                element={
                  <ProtectedRoute
                    element={
                      <IssueBookList tabName={constants.TAB_NAME.issueBook} />
                    }
                    permissionKey="Issue_Book"
                    can="read"
                  />
                }
              />

              <Route
                path="/issue_book/:id"
                
                element={
                  <ProtectedRoute
                    element={
                      <IssueView tabName={constants.TAB_NAME.viewIssueBook} />
                    }
                    permissionKey="Issue_Book"
                    can="read"
                  />
                }
              />

              <Route
                path="/userlist"
                
                element={
                  <ProtectedRoute
                    element={<UserList tabName={constants.TAB_NAME.UserList} />}
                    permissionKey="User"
                    can="read"
                  />
                }
              />

              {/* End Added by Yamini Mishra */}

              <Route
                path="/company"
                
                element={<Company tabName={constants.TAB_NAME.viewTimetable} />}
              />

              <Route
                path="/promotion"
                
                element={
                  <Promotion tabName={constants.TAB_NAME.viewPromotion} />
                }
              />
              {/*-------- Added By mohd. sarfraj | 23-Aug-2024 ---------*/}
              <Route
                path="/promotionView"
                
                element={
                  <PromotionView tabName={constants.TAB_NAME.viewPromotion} />
                }
              />
              <Route
                path="/myprofile"
                
                element={<Profile tabName={constants.TAB_NAME.Profile}/>}
              />
              <Route
                path="/announcements"
                
                element={
                  <Anouncement tabName={constants.TAB_NAME.anouncement} />
                }
              />

              {/* ******* WhatsApp Module added by Pankaj Dadhich 21/10/2024******* */}
              <Route
                path="/campaign"
                
                element={<Campaign tabName={constants.TAB_NAME.campaign} />}
              />
              <Route
                path="/campaign/add"
                
                element={
                  <CampaignAdd tabName={constants.TAB_NAME.campaignadd} />
                }
              />
              <Route
                path="/campaign/view/:id"
                
                element={
                  <CampaignView tabName={constants.TAB_NAME.CampaignView} />
                }
              />
              <Route
                path="/whatsappgroups"
                
                element={
                  <Groups tabName={constants.TAB_NAME.WhatsappgroupMessage} />
                }
              />
              <Route
                path="/responsemessage"
                
                element={
                  <ResponseMessage
                    tabName={constants.TAB_NAME.ResponseMessage}
                  />
                }
              />
              <Route
                path="/WhatsAppSetting"
                
                element={
                  <WhatsAppSetting
                    tabName={constants.TAB_NAME.WhatsAppSetting}
                  />
                }
              />
              <Route
                path="/WhatsAppMessenger"
                
                element={
                  <WhatsAppMessenger socket={connectedSocket}
                    tabName={constants.TAB_NAME.WhatsAppMessenger}
                  />
                }
              />
              <Route
                path="/Templates"
                
                element={<Templates tabName={constants.TAB_NAME.Templates} />}
              />
              <Route
                path="/whatsapp_template/add"
                
                element={
                  <TemplateAdd tabName={constants.TAB_NAME.TemplateAdd} />
                }
              />
              <Route
                path="/groups/:id"
                
                element={<GroupView tabName={constants.TAB_NAME.GroupView} />}
              />
            </Route>

            <Route path="/warning" element={<WarningPage />} />
            <Route path="/404" element={<Error />} />
            <Route path="*" element={<Navigate to="/404" />} />
          </Routes>
        </Router>
      </PermissionProvider>
    </React.Fragment>
  );
}

export default App;