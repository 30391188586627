/**
 * @author: Pawan Singh Sisodiya
 */

import React, { useEffect, useState } from "react";
import { Button, Col, Form, Modal, Row, Table } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import {DatatableWrapper, Filter, Pagination, PaginationOptions, TableBody, TableHeader} from "react-bs-datatable";
import { ShimmerTable } from "react-shimmer-effects";
import schoolApi from "../../api/schoolApi";
import InfoPill from "../InfoPill";
import Dropdown from "react-bootstrap/Dropdown";

const FeeDeposite = (props) => {
  const [body, setBody] = useState([]);
  const [classRecords, setClassRecords] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    async function init() {
      try {
        const result = await schoolApi.getActiveClassRecords();
        const result2 = await schoolApi.fetchStudentAddmission();
        console.log("result2-->", result2);

        if (result2.success) {
          setBody(result2.studentAddmision);
        } else {
          setBody([]);
        }
        console.log("feesHead==>", result);
        if (result.success) {
          setClassRecords(result.result);
        } else {
          setClassRecords([]);
        }
      } catch (error) {
        console.log("error ->", error);
      }
    }
    init();
  }, []);

  console.log("classRecords-->", classRecords);
  console.log("body->", body);

// Added by muskan khan 29-nov-24
const formatNumberWithCommas = (number) => {
  return number ? number.toLocaleString("en-IN") : "0";
};
  const header = [
    {
      title: "Student Name",
      prop: "student_info.studentname",
      isFilterable: true,
      show: true,
      // cell: (row) => (
      //   <Link to={"/examscheduleview/" + row.id}>
      //     {row.subject_name}
      //   </Link>
      // ),
    },
    // {
    //   title: "LastName",
    //   prop: "lastname",
    //   isFilterable: true,
    // },
    {
      title: "Class Name",
      prop: "student_info.class_name",
      isFilterable: true,
      show: true,
    },
    {
      title: "Category",
      prop: "student_info.category",
      isFilterable: true,
      show: true,
    },
    {
      title: "Fee Type",
      prop: "student_info.fee_master_type",
      isFilterable: true,
      show: true,
    },
    {
      title: "Total Fees",
      prop: "student_info.total_fees",
      cell: (row) => formatNumberWithCommas(parseInt(row.student_info.total_fees)), 

      isFilterable: true,
      show: true,
    },
    {
      title: "Total Dues",
      prop: "student_info.total_dues",
      cell: (row) => formatNumberWithCommas(parseInt(row.student_info.total_dues)), 

      isFilterable: true,
      show: true,
    },
    {
      title: "Date Of Birth",
      prop: "student_info.dateofbirth",
      isFilterable: true,
      show: true,
    },
    {
      title: "Phone",
      prop: "student_info.phone",
      isFilterable: true,
      show: true,
    },
    {
      title: "Action",
      prop: "",
      isFilterable: true,
      cell: (row) => (
        <div>
          <Dropdown>
            <Dropdown.Toggle variant="success" id="dropdown-basic">
              Action
            </Dropdown.Toggle>

            <Dropdown.Menu>
              <Dropdown.Item onClick={() => handleFeesClick(row)}>
                Fees
              </Dropdown.Item>
              <Dropdown.Item onClick={() => handleDepositeHistoryClick(row)}>
                Deposit History
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>
      ),
    },
  ];

  const labels = {
    beforeSelect: " ",
  };

  const handleFeesClick = (row) => {
    navigate("/feedepositeedit/e", { state: { row } });
  };

  const handleDepositeHistoryClick = (row) => {
    navigate("/feedepositehistory/e", { state: { row } });
  };

  console.log("body-->", body);

  return (
    <>
      <Helmet>
        <title>{props?.tabName}</title>
      </Helmet>
      
      <Row className="g-0">
        <Col lg={2} className="mx-3">
          <Link className="nav-link mx-2" to="/">
            Home <i className="fa-solid fa-chevron-right"></i> FeesDepositList
          </Link>
        </Col>

        <Col lg={12} className="p-lg-4">
          {body ? (
            <DatatableWrapper
              body={body}
              headers={header}
              paginationOptionsProps={{
                initialState: {
                  rowsPerPage: 15,
                  options: [5, 10, 15, 20],
                },
              }}
            >
              <Row className="mb-4">
                <Col lg={3} className="mt-3">
                  <Filter />
                </Col>
                <Col lg={1} style={{ "margin-top": "-6px" }}>
                  <PaginationOptions labels={labels} />
                </Col>
                {/* <Col lg={3}>
                <Form.Group  className='mt-3'>
                      <Form.Select
                        required
                        name="classid"
                        value={classRecords.id}
                        onChange={handleClassChange}
                      >
                        <option value="">-- Select Class Name --</option>
                        {classRecords.map((res) => (
                          <option key={res.id} value={res.id}>
                            {res.classname}
                          </option>
                        ))}
                      </Form.Select>
                    </Form.Group>
                </Col> */}
                <Col lg={5}>
                  <div>
                    <InfoPill left="Total Students" right={body?.length} />
                  </div>
                </Col>
              </Row>
              <Table striped className="data-table">
                <TableHeader />
                <TableBody />
              </Table>
              <Pagination />
            </DatatableWrapper>
          ) : (
            <ShimmerTable row={10} col={4} />
          )}
        </Col>
        <Col lg={2}></Col>
      </Row>
    </>
  );
};

export default FeeDeposite;

// /**
//  * @author: Pawan Singh Sisodiya
//  */

// import React, { useEffect, useState } from "react";
// import { Button, Col, Form, Modal, Row, Table } from "react-bootstrap";
// import { useNavigate } from "react-router-dom";
// import { Helmet } from "react-helmet";
// import { Link } from "react-router-dom";
// import "react-toastify/dist/ReactToastify.css";
// import {DatatableWrapper, Filter, Pagination, PaginationOptions, TableBody, TableHeader} from "react-bs-datatable";
// import { ShimmerTable } from "react-shimmer-effects";
// import schoolApi from "../../api/schoolApi";
// import InfoPill from "../InfoPill";
// import Dropdown from "react-bootstrap/Dropdown";

// const FeeDeposite = (props) => {
//   const [body, setBody] = useState([]);
//   const [classRecords, setClassRecords] = useState([]);
//   const navigate = useNavigate();

//   useEffect(() => {
//     async function init() {
//       try {
//         const result = await schoolApi.getActiveClassRecords();
//         const result2 = await schoolApi.fetchStudentAddmission();
//         console.log("result2-->", result2);

//         if (result2.success) {
//           setBody(result2.studentAddmision);
//         } else {
//           setBody([]);
//         }
//         console.log("feesHead==>", result);
//         if (result.success) {
//           setClassRecords(result.result);
//         } else {
//           setClassRecords([]);
//         }
//       } catch (error) {
//         console.log("error ->", error);
//       }
//     }
//     init();
//   }, []);

//   console.log("classRecords-->", classRecords);
//   console.log("body->", body);

//   const header = [
//     {
//       title: "Student Name",
//       prop: "student_info.studentname",
//       isFilterable: true,
//       show: true,
//       // cell: (row) => (
//       //   <Link to={"/examscheduleview/" + row.id}>
//       //     {row.subject_name}
//       //   </Link>
//       // ),
//     },
//     // {
//     //   title: "LastName",
//     //   prop: "lastname",
//     //   isFilterable: true,
//     // },
//     {
//       title: "Class Name",
//       prop: "student_info.class_name",
//       isFilterable: true,
//       show: true,
//     },
//     {
//       title: "Category",
//       prop: "student_info.category",
//       isFilterable: true,
//       show: true,
//     },
//     {
//       title: "Fee Type",
//       prop: "student_info.fee_master_type",
//       isFilterable: true,
//       show: true,
//     },
//     {
//       title: "Total Fees",
//       prop: "student_info.total_fees",
//       isFilterable: true,
//       show: true,
//     },
//     {
//       title: "Total Dues",
//       prop: "student_info.total_dues",
//       isFilterable: true,
//       show: true,
//     },
//     {
//       title: "Date Of Birth",
//       prop: "student_info.dateofbirth",
//       isFilterable: true,
//       show: true,
//     },
//     {
//       title: "Phone",
//       prop: "student_info.phone",
//       isFilterable: true,
//       show: true,
//     },
//     {
//       title: "Action",
//       prop: "",
//       isFilterable: true,
//       cell: (row) => (
//         <div>
//           <Dropdown>
//             <Dropdown.Toggle variant="success" id="dropdown-basic">
//               Action
//             </Dropdown.Toggle>

//             <Dropdown.Menu>
//               <Dropdown.Item onClick={() => handleFeesClick(row)}>
//                 Fees
//               </Dropdown.Item>
//               <Dropdown.Item onClick={() => handleDepositeHistoryClick(row)}>
//                 Deposit History
//               </Dropdown.Item>
//             </Dropdown.Menu>
//           </Dropdown>
//         </div>
//       ),
//     },
//   ];

//   const labels = {
//     beforeSelect: " ",
//   };

//   const handleFeesClick = (row) => {
//     navigate("/feedepositeedit/e", { state: { row } });
//   };

//   const handleDepositeHistoryClick = (row) => {
//     navigate("/feedepositehistory/e", { state: { row } });
//   };

//   console.log("body-->", body);

//   return (
//     <>
//       <Helmet>
//         <title>{props?.tabName}</title>
//       </Helmet>
      
//       <Row className="g-0">
//         <Col lg={2} className="mx-3">
//           <Link className="nav-link mx-2" to="/">
//             Home <i className="fa-solid fa-chevron-right"></i> FeesDepositList
//           </Link>
//         </Col>

//         <Col lg={12} className="p-lg-4">
//           {body ? (
//             <DatatableWrapper
//               body={body}
//               headers={header}
//               paginationOptionsProps={{
//                 initialState: {
//                   rowsPerPage: 15,
//                   options: [5, 10, 15, 20],
//                 },
//               }}
//             >
//               <Row className="mb-4">
//                 <Col lg={3} className="mt-3">
//                   <Filter />
//                 </Col>
//                 <Col lg={1} style={{ "margin-top": "-6px" }}>
//                   <PaginationOptions labels={labels} />
//                 </Col>
//                 {/* <Col lg={3}>
//                 <Form.Group  className='mt-3'>
//                       <Form.Select
//                         required
//                         name="classid"
//                         value={classRecords.id}
//                         onChange={handleClassChange}
//                       >
//                         <option value="">-- Select Class Name --</option>
//                         {classRecords.map((res) => (
//                           <option key={res.id} value={res.id}>
//                             {res.classname}
//                           </option>
//                         ))}
//                       </Form.Select>
//                     </Form.Group>
//                 </Col> */}
//                 <Col lg={5}>
//                   <div>
//                     <InfoPill left="Total Students" right={body?.length} />
//                   </div>
//                 </Col>
//               </Row>
//               <Table striped className="data-table">
//                 <TableHeader />
//                 <TableBody />
//               </Table>
//               <Pagination />
//             </DatatableWrapper>
//           ) : (
//             <ShimmerTable row={10} col={4} />
//           )}
//         </Col>
//         <Col lg={2}></Col>
//       </Row>
//     </>
//   );
// };

// export default FeeDeposite;
