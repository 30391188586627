import React, { useState, useEffect } from "react";
import schoolApi from "../../api/schoolApi";
import { Col, Row,Button,Container,Form } from "react-bootstrap";

import { Helmet } from "react-helmet";
import { ToastContainer } from "react-toastify";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import CityState from "../../constants/CityState.json";

const StudentEditDetails = (props) => {
  console.log('StudentEditDetails@@=>',props)
  const location = useLocation();
  const navigate = useNavigate();
  const [studentDetail, setStudentDetail] = useState([]);
  const [optionContacts, setOptionContacts] = useState([]);
  const [optionVehicals, setOptionVehicles] = useState([]);
  const [optionClasses, setOptionClasses] = useState([]);
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);

  useEffect(() => {
    // Create an array of unique states
    const uniqueStates = Array.from(
      new Set(CityState.map((item) => item.state))
    ).map((state) => ({ label: state, value: state }));
    setStates(uniqueStates);
  }, [CityState]);


  useEffect(() => {
    console.log('Location.state==>', location.state);
    
    if (location?.state) {
        setStudentDetail(location?.state);
        const filteredCities = CityState.filter(
            (item) => item.state === location.state.state
          ).map((city) => ({ label: city.name, value: city.name }));
    
          console.log('@#filteredCities==>', filteredCities);
          setCities(filteredCities);
        console.log("setContact useEffect==>", studentDetail);
      }
    async function init() {
        let classList = await schoolApi.fetchClasses();
      console.log('classListclassList-->',classList);
      if (classList) {
        let ar = [];
        classList.map((item) => {
          var obj = {};
          obj.value = item.class_id;
          obj.label = item.classname;
          ar.push(obj);
        })
        setOptionClasses(ar);
      } else {
        setOptionClasses([]);
      }
      let contList = await schoolApi.fetchParentContacts();
      console.log('contList',contList)
      if (contList) {
        let ar = [];
        contList.map((item) => {
          var obj = {};
          obj.value = item.id;
          obj.label = item.firstname + ' ' + item.lastname;
          ar.push(obj);
        })
        setOptionContacts(ar);
      } else {
        setOptionContacts([]);
      }

    let vehicleList = await schoolApi.getAllVehicles();
    console.log('vehicleList',vehicleList)
    if (vehicleList) {
      let ar = [];
      vehicleList.map((item) => {
        var obj = {};
        obj.value = item.id;
        obj.label = item.type +' ' + item.vehicle_no;
        ar.push(obj);
      })
      setOptionVehicles(ar);
    } else {
      setOptionVehicles([]);
    }
    }
    init();
    fetchStudents(); // Call fetchStudents when props.studentId changes
  }, []);

  const fetchStudents = async () => {
    console.log('props.studnet->', props?.studentid)
    if (props?.studentid !== null && props?.studentid !== undefined) {
      const result = await schoolApi.fetchStudentbyId(props?.studentid);
      console.log('result==>', result)
      if (result.success === true) {
        setStudentDetail(result);
      }
      else {
        setStudentDetail([]);
      }
    }
  }

  const handleCancel = () =>{

  }
  const handleChange = (e)=>{
    setStudentDetail({})
    console.log('e.target.value ==>', e.target.value, ' e.target.name ==> ', e.target.name);
    setStudentDetail(({...studentDetail,[e.target.name]: e.target.value}));
  }

  const handleSubmit = async(e) =>{
    e.preventDefault(); 
    console.log('studentDetail==>',studentDetail)
    const updatedStudent = await schoolApi.updateStudent(studentDetail);
    console.log('updatedStudent@@=>', updatedStudent?.result)
    navigate(`/studentaddmissions/${studentDetail.student_addmission_id}`, { state: updatedStudent?.result });
  }

  return (
    <>
      <>
                <Helmet> <title>{props?.tabName}</title> </Helmet>
                <Container className="view-form">
                    <Row>
                        <Col></Col>
                        <Col lg={8}>
                            <Form className="mt-3" onSubmit={handleSubmit}>
                                <Row className="view-form-header align-items-center">
                                    <Col lg={3}>
                                        Edit Student
                                    </Col>
                                    <Col lg={9} className="d-flex justify-content-end">
                                        <Button className="btn-sm mx-2" onClick={handleSubmit}>
                                            Save
                                        </Button>
                                        <Button
                                            className="btn-sm"
                                            variant="danger"
                                            onClick={handleCancel}
                                        >
                                            Cancel
                                        </Button>
                                    </Col>
                                </Row>
                                <Row>
                                <Col lg={6}>
                                        <Form.Group className="mx-3">
                                            <Form.Label
                                                className="form-view-label"
                                                htmlFor="formBasicFirstName"
                                            >
                                               Serial No
                                            </Form.Label>
                                            <Form.Control type="text" name="srno" placeholder="Enter Serial No" onChange={handleChange} value={studentDetail?.srno}/>
                                        </Form.Group>
                                    </Col>
                                    <Col lg={6}>
                                        <Form.Group className="mx-3">
                                            <Form.Label
                                                className="form-view-label"
                                                htmlFor="formBasicFirstName"
                                            >
                                                First Name
                                            </Form.Label>
                                            <Form.Control type="text" name="firstname" placeholder="Enter First Name" onChange={handleChange} value={studentDetail?.firstname}/>
                                        </Form.Group>
                                    </Col>
                                    <Col lg={6}>
                                        <Form.Group className="mx-3">
                                            <Form.Label
                                                className="form-view-label"
                                                htmlFor="formBasicLastName"
                                            >
                                                Last Name
                                            </Form.Label>
                                            <Form.Control type="text" name="lastname" placeholder="Enter Last Name" onChange={handleChange}  value={studentDetail?.lastname}/>
                                        </Form.Group>
                                    </Col>
                                    <Col lg={6}>
                                        <Form.Group className="mx-2" controlId="formBasicsalutation">
                                            <Form.Label className="form-view-label" htmlFor="formBasicsalutation">
                                                Religion
                                            </Form.Label>
                                            <Form.Control style={{ height: "36px" }} as="select" type="text" name="religion" placeholder="Select your Religion" onChange={handleChange} value={studentDetail?.religion}>
                                                <option value="">--Select Religion--</option>
                                                <option value="Muslim">Muslim</option>
                                                <option value="Hindu">Hindu</option>
                                                <option value="Christian">Christian</option>
                                                <option value="Sikh">Sikh</option>
                                                <option value="Jain">Jain</option>
                                                <option value="Other">Other</option>
                                            </Form.Control>
                                        </Form.Group>
                                    </Col>
                                    <Col lg={6}>
                                        <Form.Group className="mx-3">
                                            <Form.Label
                                                className="form-view-label"
                                                htmlFor="formBasicBirthdate"
                                            >
                                                Date of Birth
                                            </Form.Label>
                                            <Form.Control
                                                type="date"
                                                name="dateofbirth"
                                                placeholder="Enter Date of Birth"
                                                value={studentDetail ? moment(studentDetail?.dateofbirth).format('YYYY-MM-DD') : ''}
                                                onChange={handleChange} />
                                        </Form.Group>
                                    </Col>
                                    <Col lg={6}>
                                        <Form.Group className="mx-3">
                                            <Form.Label
                                                className="form-view-label"
                                            >
                                                Gender
                                            </Form.Label>
                                            <Form.Select
                                                name="gender"
                                                onChange={handleChange}
                                                value={studentDetail?.gender}
                                            >
                                                <option value="">-- Select --</option>
                                                <option value="Male">Male</option>
                                                <option value="Female">Female</option>
                                                <option value="Other">Other</option>
                                            </Form.Select>
                                        </Form.Group>
                                    </Col>

                                    <Col lg={6}>
                                        <Form.Group className="mx-3">
                                            <Form.Label
                                                className="form-view-label"
                                                htmlFor="formBasicEmail"
                                            >
                                                Email
                                            </Form.Label>
                                            <Form.Control
                                                type="email"
                                                name="email"
                                                placeholder="Enter Email"
                                                value={studentDetail?.email}
                                                onChange={handleChange}
                                            />
                                        </Form.Group>
                                    </Col>
                                    <Col lg={6}>
                                        <Form.Group className="mx-3">
                                            <Form.Label
                                                className="form-view-label"
                                                htmlFor="formBasicAdharnumber"
                                            >
                                                Aadhar Number
                                            </Form.Label>
                                            <Form.Control
                                                type="number"
                                                name="adharnumber"
                                                placeholder="Enter Adhar Number"
                                                value={studentDetail?.adharnumber}
                                                onChange={handleChange}
                                            />
                                        </Form.Group>
                                    </Col>
                                    <Col lg={6}>
                                        <Form.Group className="mx-3">
                                            <Form.Label
                                                className="form-view-label"
                                                htmlFor="formBasicPhone"
                                            >
                                                Phone
                                            </Form.Label>
                                            <Form.Control type="phone" name="phone" placeholder="Enter phone" onChange={handleChange} value={studentDetail?.phone} />
                                        </Form.Group>
                                    </Col>
                                    <Col lg={6}>
                                        <Form.Group className="mx-3">
                                            <Form.Label
                                                className="form-view-label"
                                                htmlFor="formBasicPin"
                                            >
                                                Pincode
                                            </Form.Label>
                                            <Form.Control
                                                type="text"
                                                name="pincode"
                                                placeholder="Enter pincode"
                                                value={studentDetail?.pincode}
                                                onChange={handleChange}
                                            />
                                        </Form.Group>
                                    </Col>
                                    <Col lg={6}>
                                        <Form.Group className="mx-3">
                                            <Form.Label
                                                className="form-view-label"
                                                htmlFor="formBasicStreet"
                                            >
                                                Street
                                            </Form.Label>
                                            <Form.Control
                                                type="text"
                                                name="street"
                                                placeholder="Enter Street"
                                                value={studentDetail?.street}
                                                onChange={handleChange}
                                            />
                                        </Form.Group>
                                    </Col> 
                                    <Col lg={6}>
                                        <Form.Group className="mx-3" >
                                            <Form.Label
                                                className="form-view-label"
                                                htmlFor="formBasicCountry"
                                            >
                                                Country
                                            </Form.Label>
                                            <Form.Control
                                                style={{ height: "36px" }}
                                                type="text"
                                                name="country"
                                                placeholder="Enter Country"
                                                value={studentDetail?.country}
                                                onChange={handleChange}
                                            />
                                        </Form.Group>
                                    </Col>

                                    {/* Add By Aamir khan 03-05-202   State */}
                                    {<Col lg={6}>
                                        <Form.Group className="mx-3">
                                            <Form.Label
                                                className="form-view-label"
                                                htmlFor="formBasicstate"
                                            >
                                                State
                                            </Form.Label>

                                            <Form.Select
                                                name="state"
                                                value={studentDetail?.state}
                                                onChange={handleChange}
                                            >
                                                <option value="">-- Select State --</option>
                                                {states.map((state) => (
                                                    <option key={state.label} value={state.value}>
                                                        {state.label}
                                                    </option>
                                                ))}
                                            </Form.Select>
                                        </Form.Group>
                                    </Col>}

                                    <Col lg={6}>
                                        <Form.Group className="mx-3">
                                            <Form.Label className="form-view-label" htmlFor="formBasicCity">
                                                City
                                            </Form.Label>
                                            <Form.Select
                                                name="city"
                                                value={studentDetail?.city}
                                                onChange={handleChange}
                                            >
                                                <option value="">-- Select City --</option>
                                                {cities.map(city => (
                                                    <option key={city.label} value={city.value}>
                                                        {city.label}
                                                    </option>
                                                ))}
                                            </Form.Select>
                                        </Form.Group>
                                    </Col>
                                    <Col md={6} >
                                        <Form>
                                        <Form.Group className="mx-3" controlId="formStreet">
                                            <Form.Label className="form-view-label">Parmanent Street</Form.Label>
                                            <Form.Control type="text" placeholder="Enter Street" name="permanentstreet" onChange={handleChange} value={studentDetail?.permanentstreet}/>
                                        </Form.Group>
                                        </Form>
                                    </Col>    
                                    <Col md={6}>
                                        <Form>
                                        <Form.Group className="mx-3" controlId="formCountry">
                                            <Form.Label className="form-view-label">Permanent Country</Form.Label>
                                            <Form.Control type="text" placeholder="Enter Country" name="permanentcountry" onChange={handleChange} value={studentDetail?.permanentcountry}/>
                                        </Form.Group>
                                        </Form>
                                    </Col>   
                                    <Col md={6}>
                                        <Form>
                                        <Form.Group className="mx-3" controlId="formState">
                                            <Form.Label className="form-view-label">Permanent State</Form.Label>
                                            <Form.Control type="text" placeholder="Enter State" name="permanentstate" onChange={handleChange} value={studentDetail?.permanentstate}/>
                                        </Form.Group>
                                        </Form>
                                    </Col> 
                                    <Col md={6}>
                                        <Form>
                                        <Form.Group className="mx-3" controlId="formCity">
                                            <Form.Label className="form-view-label">Permanent City</Form.Label>
                                            <Form.Control type="text" placeholder="Enter City" name="permanentcity" onChange={handleChange}  value={studentDetail?.permanentcity}/>
                                        </Form.Group>
                                        </Form>
                                    </Col>
                                    <Col md={6}>
                                        <Form>
                                        <Form.Group className="mx-3" controlId="formPostalCode">
                                            <Form.Label className="form-view-label">Postal Code</Form.Label>
                                            <Form.Control type="text" placeholder="Enter Postal Code" name="permanentpostalcode" onChange={handleChange} value={studentDetail?.permanentpostalcode}/>
                                        </Form.Group>
                                        </Form>
                                    </Col>
                                    <Col md={6}>
                                        <Form>
                                        <Form.Group className="mx-3" controlId="formClassName">
                                            <Form.Label
                                            className="form-view-label"
                                            >
                                            Category
                                            </Form.Label>
                                            <Form.Select name="category"  onChange={handleChange} value={studentDetail?.category}>
                                            <option value="">--Select Category--</option>
                                            <option value="General">General</option>
                                            <option value="Obc">Obc</option>
                                            <option value="Sc">Sc</option>
                                            <option value="St">St</option>
                                            </Form.Select>
                                        </Form.Group>
                                        </Form>
                                    </Col>  
                                    <Col md={6}>
                                        <Form>
                                        <Form.Group className="mx-3" controlId="formClassName">
                                            <Form.Label
                                            className="form-view-label"
                                            >
                                            Class Name
                                            </Form.Label>
                                            <Form.Select name="classid" onChange={handleChange} value={studentDetail?.classid}>
                                            <option value="">Select Class</option>
                                            {optionClasses.map((className, index) => (
                                                console.log('className.value@@=>',className.value),
                                                <option key={index} value={className.value}>
                                                {console.log('classNameclassName-->',className)}
                                                {className.label}
                                                </option>
                                            ))}
                                            </Form.Select>
                                        </Form.Group>
                                        </Form>
                                    </Col>    
                                    <Col md={6}>
                                        <Form>
                                        <Form.Group className="mx-3" controlId="formClassName">
                                            <Form.Label
                                            className="form-view-label"
                                            >
                                            Parent Name
                                            </Form.Label>
                                            <Form.Select name="parentid" onChange={handleChange}>
                                            <option value="">Select Contact</option>
                                            {optionContacts.map((cont, index) => (
                                                <option key={index} value={cont.value}>
                                                {cont.label}
                                                </option>
                                            ))}
                                            </Form.Select>
                                        </Form.Group>
                                        </Form>
                                    </Col>          
                                    <Col md={6}>
                                        <Form>
                                        <Form.Group className="mx-3" controlId="formClassName">
                                            <Form.Label
                                            className="form-view-label"
                                            >
                                            Vehicle Name
                                            </Form.Label>
                                            <Form.Select name="vehicleid" onChange={handleChange}>
                                            <option value="">Select Vehicle</option>
                                            {optionVehicals.map((vehicle, index) => (
                                                <option key={index} value={vehicle.value}>
                                                {vehicle.label}
                                                </option>
                                            ))}
                                            </Form.Select>
                                        </Form.Group>
                                        </Form>
                                    </Col>                   
                                </Row>
                            </Form>
                        </Col >
                        <Col></Col>
                    </Row >
                    <ToastContainer />
                </Container >
      </>
    </>
  );
};
export default StudentEditDetails;