import React, { useState } from "react";
import { Alert, Col, Container, Row } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { useNavigate } from "react-router-dom";
import Card from "react-bootstrap/Card";
import authApi from "../api/authApi";
import home from "../resources/img/home.png";
import login from "../resources/img/login.png";
import { Link } from 'react-router-dom';
import schoolApi from "../api/schoolApi";
import { ToastContainer, toast } from 'react-toastify';
import { useLocation  } from 'react-router-dom';
// import { useLocation } from 'react-router-dom';

const ResetPassword = () => {
  const navigate = useNavigate();
  const [credentials, setCredentials] = useState({newPassword:"",confirmPassword:""});
  const [show, setShow] = React.useState(false);
  const [errorMessage, setErrorMessage] = useState();
  let [confirmMatch, setConfirmMatch] = useState(false);
  // const { email,school_code  } = useParams();
  const location = useLocation ();
  const email = location.pathname.split('/').pop(); // Extract email from the path
  const school_code = location.state?.school_code;
  
  console.log("email by parems == ",email)
  console.log("school_code by parems == ",school_code)

  const handleSubmit = async (e) => {
    try{
    
    e.preventDefault();
    console.log('handleSubmit == ',credentials);
    const result = await schoolApi.ResetPassword(email,credentials.newPassword,school_code)
    console.log("ResetPassword result == ",result)
    if(result.success) {
        toast.success(result.message);
        setTimeout(() => {
            navigate('/');
        }, 2000);
    }
    else{
        toast.error(result.message)
        setTimeout(() => {
            navigate('/forget-password');
        }, 2000);
    }
    }catch (error) {
        return toast.error(error.response.data.message)
    }
  };
  const handleChange = (e) => {
    setCredentials({ ...credentials, [e.target.name]: e.target.value });
    console.log('credentials == ',credentials.newPassword)

        // if(credentials.newPassword === credentials.confirmPassword){
        //     setConfirmMatch(true)} 
        // else{
        //      setConfirmMatch(false)
        // }
  };

  //created by shahir hussain 29-10-2024
  return (
    <Container>
    {/* <span className="box1"></span>
    <span className="box2"></span> */}
    <div className="login-form-section">
      <div className="forget-form-content">
        <div className="p-4">
          <Form className="mt-2" onSubmit={handleSubmit}>
            
              <div className="">
                <h3 className="text-center ">
                  {" "}
                  <img src='/indicrm.png' className="mb-2" />
                </h3>
              </div>
            
            <Alert variant="danger" show={show}>
              {errorMessage}
            </Alert>
            <Form.Group
              className="mb-3 mt-2"
              controlId="formBasicusername"
            >
              <Row className="mt-3">
                <Col lg={12}>
                  <Form.Label>Create New Password</Form.Label>
                     <Form.Control
                       type="password"
                       name="newPassword"
                       onChange={handleChange}
                       placeholder="Enter New Password Password"
                       value={credentials.newPassword}
                     />
                </Col>
              </Row>
                <Row className="mt-3">
                  <Col lg={12}>
                    <Form.Label>Confirm New Password</Form.Label>
                      <Form.Control
                         type="password"
                         name="confirmPassword"
                         onChange={handleChange}
                         placeholder="Confirm New Password Password"
                         value={credentials.confirmPassword}
                      />
                      <b className="mt-3">
                         {credentials.newPassword && credentials.confirmPassword ?
                         (credentials.newPassword === credentials.confirmPassword ?
                           "Password Match" : "Password Not Match") : ""}
                      </b>
                    <div className="mt-2">
                    </div>
                  </Col>
                </Row>
                <Row className="mt-3">
                <Col lg={12}>
                  <Button
                    className="btn btn-lg w-100 submit"
                    variant="primary"
                    type="submit"
                    disabled={credentials.newPassword !== credentials.confirmPassword}
                  >
                    Create
                  </Button>
                </Col>
              </Row>
            </Form.Group>
            <center className="mt-3">
              <strong><Link to="/forget-password" style={{ textDecoration: 'none' }}>Go Back</Link></strong>
            </center>
          </Form>

          <center className="indicrm">
          </center>
        </div>
      </div>
    </div>
    <ToastContainer />
    </Container>
  );
};
export default ResetPassword;