import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import { Link, useNavigate } from "react-router-dom";
import Badge from "react-bootstrap/Badge";
import jwt_decode from "jwt-decode";
import schoolApi from "../../../api/schoolApi";
import authApi from "../../../api/authApi";
import Draggable from "react-draggable";
import "../../../App.css";
const Header = ({ allLauncherData, setAllLauncherData }) => {
  const navigate = useNavigate();
  const [userInfo, setUserInfo] = useState({});

  useEffect(() => {
    if (!sessionStorage.getItem("token")) navigate("/login");
    try {
      if (sessionStorage.getItem("token")) {
        let user = jwt_decode(sessionStorage.getItem("token"));
        setUserInfo(user);
      }
    } catch (error) {
      console.log(error);
    }
  }, []);
  useEffect(() => {
    schoolApi.getAllQuickLauncher().then((result) => {
      if (result?.success) {
        setAllLauncherData(result.records);
      } else {
        setAllLauncherData([]);
      }
    });
  }, []);

  const [sidebar, setSidebar] = useState(false);
  const logout = () => {
    navigate("/login");
    sessionStorage.removeItem("token");
  
  
  };

  const toggleSidebar = () => {
    if (sidebar === false) {
      setSidebar(true);
      document.querySelector("#sidebar").classList.toggle("active");
    } else {
      setSidebar(false);
      document.querySelector("#sidebar").classList.toggle("active");
    }
  };

  const [open_Ql, setopen_Ql] = useState(false);
  const QuickLauncher = () => {
    setopen_Ql(!open_Ql);
  };

  return (
    <>
      <Navbar className="header px-2 mt-1" bg="" expand="lg" variant=""  >

        <button
          type="button"
          id="sidebarCollapse"
          className="btn btn-primary"
          onClick={toggleSidebar}
        >
          <i className="fas fa-align-left"></i>
        </button>
        <Navbar.Brand href="#home"></Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto">
            <Nav.Link href="#" className="p-0" style={{ fontSize: ".9rem" }}>
              <img
                src={sessionStorage.getItem("myimage")}
                className="rounded-circle"
                style={{ height: "30px" }}
                alt=""
              />
            </Nav.Link>

            <Nav.Link href="#" className="p-0" style={{ fontSize: ".9rem" }}>
              {/* <img src={'/img_avatar.png'} className="rounded-circle" style={{height: "30px"}}/>  */}
              <img
                // src={userImage}
                className="rounded-circle"
                id="profileimage"
                style={{ height: "30px" }}
                alt=""
              />
              <Badge style={{ fontSize: ".9rem" }} bg="light" text="dark">
                {userInfo.firstname} {userInfo.lastname}
              </Badge>
              <Badge
                bg="success"
                style={{
                  fontSize: ".9rem",
                  paddingTop: "7px",
                  paddingBottom: "4px",
                  paddingLeft: "12px",
                  paddingRight: "12px",
                }}
              >
                {userInfo.userrole}
              </Badge>
            </Nav.Link>
            <Nav.Link
              href="#"
              className="d-flex p-0"
              style={{ alignItems: "center" }}
            >
              <span className="mx-2" style={{ fontSize: ".9rem" }}>
                School
              </span>{" "}
              <Badge
                style={{
                  fontSize: ".9rem",
                  paddingTop: "7px",
                  paddingBottom: "4px",
                  paddingLeft: "12px",
                  paddingRight: "12px",
                }}
                bg="secondary"
              >
                {userInfo.companyname}
              </Badge>{" "}
            </Nav.Link>
          </Nav>

          <Nav className="ml-auto">
            <Nav.Link href="/about">
              <i className="fa-solid fa-bell"></i>
            </Nav.Link>
            {sessionStorage.getItem("token") ? (
              <Button variant="btn btn-primary" onClick={logout} title="Logout">
                <i className="fa-solid fa-right-from-bracket"></i>
              </Button>
            ) : (
              <></>
            )}
          </Nav>
        </Navbar.Collapse>
      </Navbar>

      {/* ------Code Muskan khan 24-aug-2024------- */}
      {allLauncherData.length > 0 && (
        <Draggable
          className="text-dark"
          bounds={{ left: -1300, top: -540, right: 10, bottom: 20 }}
        >
          <div
            style={{
              position: "fixed",
              zIndex: 1000,
              bottom: 20,
              right: 10,
            }}
          >
            <button
              type="button"
              style={{
                position: "fixed",
                background: "#c5597d",
                height: "50px",
                width: "50px",
                borderRadius: "100%",
                border: "1px ",
              }}
              className="btn btn-secondary appLauncher px-2"
              onClick={() => QuickLauncher()}
            >
              <i id="dragIcon" className="fa-solid fa-jet-fighter-up"></i>
            </button>
            {open_Ql && (
              <div className="all_Quick_launcher ">
                {allLauncherData?.map((vl, ky) => (
                  <div className="card  bg-light">
                    <Link
                      className="card-body text-decoration-none"
                      to={vl.sub_module_url}
                      state={{ [vl?.name]: true }}
                      data-toggle="tooltip"
                      data-placement="left"
                      title={vl?.name}
                      // style={{position: "absolute",   zIndex: 100,}}
                    >
                      <i className={vl.icon} />
                    </Link>
                  </div>
                ))}
              </div>
            )}
          </div>
        </Draggable>
      )}
    </>
  );
};

export default Header;