import React, { useEffect, useState } from "react";
import { Form, Row, Col } from "react-bootstrap";
import schoolApi from "../../api/schoolApi";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
const ParentTab = (props) => {
  console.log("ParentTab props=>", props);
  console.log("isRequiredqqww@222=>", props?.isParent);
  // console.log('location.state=>',location?.state)
  const [parentFather, setParentFather] = useState("");
  const [parentMother, setParentMother] = useState("");
  const [ParentGuardian, setParentGuardian] = useState("");
  const [validatedForParent, setValidatedForParent] = useState(false);
  const [disablePar, setDisablePar] = useState(false);
  const [disableMoth, setDisableMoth] = useState(false);
  const [disableGuar, setDisableGuar] = useState(false);
  const [parentChild, setParentChild] = useState([]);
  const [requireFather, setRequireFather] = useState(false);
  const [requireMother, setRequireMother] = useState(false);
  const [requireGuardian, setRequireGuardian] = useState(false);
  const [guar_fstname, setGuar_fstname] = useState(
    props.isParent ? props?.isParent : ""
  );
  const [guar_lstname, setGuar_lstname] = useState(
    props.isParent ? props?.isParent : ""
  );
  const [guar_phone, setGuar_phone] = useState(
    props.isParent ? props?.isParent : ""
  );

  const [parentIds, setParentIds] = useState([]);
  let parentArray = [];
  //Added By Pooja Vaishnav(07-07-2024)
  useEffect(() => {
    setGuar_fstname(props.isParent);
    setGuar_lstname(props.isParent);
    setGuar_phone(props.isParent);
  }, [props.isParent]);

  useEffect(() => {
    async function init() {
      if(props?.familyInfo?.primarypar === true || props?.familyInfo?.primarymoth === true || props?.familyInfo?.primaryguar === true){
        setDisablePar(true);
        setDisableMoth(true);
        setDisableGuar(true);
        console.log('I m inside correct code')
      }
      console.log("props?.student?.parentid=>", props?.student?.parentid);
      let updatedFamilyInfo = {};
      if (props?.student?.parentid !== null && props?.student?.parentid !== undefined) {
        const childParent = await schoolApi.fetchChildByParentId(props?.student?.parentid);
        console.log("childParent", childParent);
        if (Array.isArray(childParent?.data)) { // chnage by sarfraj | 28-sept-2024.
          console.log("first7888");
          childParent?.data?.forEach((res) => { // // chnage by sarfraj | 28-sept-2024.
            console.log("res==>", res);
            if (res.recordtype === "Parent_Father") {
              if (res.is_primary === false) {
                console.log("disable");
                setDisablePar(true);
              }
              updatedFamilyInfo = {
                ...updatedFamilyInfo,
                father_id: res.id,
                fatherfirstname: res.firstname,
                fatherlastname: res.lastname,
                fatherqualification: res.qualification,
                fatherprofession: res.profession,
                fatherphone: res.phone,
                fatheremail: res.email,
                recordtype: res.recordtype,
                primarypar: res.is_primary,
              };
            } else if (res.recordtype === "Parent_Mother") {
              if (res.is_primary === false) {
                setDisableMoth(true);
              }
              if(disableGuar === false){
                setDisableGuar(true);
              }
              updatedFamilyInfo = {
                ...updatedFamilyInfo,
                mother_id: res.id,
                motherfirstname: res.firstname,
                motherlastname: res.lastname,
                motherqualification: res.qualification,
                motherprofession: res.profession,
                motherphone: res.phone,
                motheremail: res.email,
                recordtype: res.recordtype,
                primarymoth: res.is_primary,
              };
            } else if (res.recordtype === "Parent_Guardian") {
              if (res.is_primary === false) {
                setDisableGuar(true);
              }
              updatedFamilyInfo = {
                ...updatedFamilyInfo,
                guardian_id: res.id,
                guardianfirstname: res.firstname,
                guardianlastname: res.lastname,
                guardianqualification: res.qualification,
                guardianprofession: res.profession,
                guardianphone: res.phone,
                guardianemail: res.email,
                recordtype: res.recordtype,
                primaryguar: res.is_primary,
              };
            }
          });
        }
        setFamilyInfo(updatedFamilyInfo);
        /// console.log("childParent?.parent", childParent?.parent.email);
        props.handleParentTab(updatedFamilyInfo);
      }
    }
    init();
  }, [props?.student?.parentid]);

  // console.log("ParebtsChild=>", parentChild);
  const [familyInfo, setFamilyInfo] = useState({});
  useEffect(() => {
    console.log('JKJHGHNMNN',props?.familyInfo)
    if(props?.previousVal === 'Edit' && props?.familyInfo?.primarypar !== true){
      setDisablePar(true)
    }else if(props?.previousVal === 'Edit' && props?.familyInfo?.primarymoth !== true){
      setDisableMoth(true);
      setDisableGuar(true);
    }
    if (props?.familyInfo !== null) {
      setFamilyInfo(props?.familyInfo);
    }
    if (props.validatedForParent) {
      if (checkRequredFields()) {
        setValidatedForParent(true);
      }
    }
  }, [props.validatedForParent]);

  const checkRequredFields = () => {
    if (
      familyInfo.fatherfirstname &&
      familyInfo.fatherfirstname.trim() !== ""
    ) {
      return false;
    }
    return true;
  };

  const handlePimaryParent = async(e)=>{
    if(e.target.value === 'Parent_Father'){
      setRequireFather(true);
      setRequireMother(false);
      setRequireGuardian(false);

      // handleParentChange({
      //   target: { name: "primaryparent", value: e.target.value },
      // });
      props.handleInsertedParents({
        ...familyInfo,
        [e.target.name]: e.target.value,
      });
    }else if(e.target.value === 'Parent_Mother'){
      setRequireFather(false);
      setRequireMother(true);
      setRequireGuardian(false);    
        handleParentChange({
        target: { name: "primaryparent", value: e.target.value },
      });
      props.handleInsertedParents({
        ...familyInfo,
        [e.target.name]: e.target.value,
      });
    }else if(e.target.value === 'Parent_Guardian'){
      setRequireFather(false);
      setRequireMother(false);
      setRequireGuardian(true);    
       handleParentChange({
        target: { name: "primaryparent", value: e.target.value },
      });
      props.handleInsertedParents({
        ...familyInfo,
        [e.target.name]: e.target.value,
      });
    }
  }

  // const handleSearch = async (e) => {
  //   try{
  //     let contPhone = e.target.value;
  //   let searchCont = [];
  //   let allParentContact = await schoolApi.fetchParentContacts();
  //   if (allParentContact) {
  //     let datafilterarray = allParentContact.filter(
  //       (value) => value.phone === contPhone
  //     );
  //     setFamilyInfo({});
  //     if (datafilterarray.length > 0) {
  //       console.log('datafilterarray')
  //       parentArray.push(datafilterarray[0].id);
  //       setParentIds((parentValues) => [...parentValues, ...parentArray]);
  //     }else{
  //       console.log('In the elseee',familyInfo)
  //       setParentFather('');
  //       setParentMother('');
  //       setParentGuardian('');
  //     }
  //     let data = allParentContact?.filter((value)=>
  //       (((datafilterarray[0]?.id === value.id && datafilterarray[0]?.id !== null) ||
  //           (datafilterarray[0]?.id === value.parentid && datafilterarray[0]?.id !== null)) ||
  //           ((datafilterarray[0]?.parentid === value.id && datafilterarray[0]?.parentid !== null) ||
  //           datafilterarray[0]?.parentid === value.parentid && datafilterarray[0]?.parentid !== null))
  //   )
  //     let parentFatherInfo = data.filter(
  //       (value) => value.recordtype === "Parent_Father"
  //     );
  //     console.log('parentFatherInfo@@=>',parentFatherInfo)
  //     if (parentFatherInfo.length > 0) {
  //       setParentFather(parentFatherInfo);
  //     }
  //     let parentMotherInfo = data.filter(
  //       (value) => value.recordtype === "Parent_Mother"
  //     );
  //     console.log('parentMotherInfo@!~1=>',parentMotherInfo)
  //     if (parentMotherInfo.length > 0) {
  //       setParentMother(parentMotherInfo);
  //     }
  //     let parentGuardianInfo = data.filter(
  //       (value) => value.recordtype === "Parent_Guardian"
  //     );
  //     if (parentGuardianInfo.length > 0) {
  //       setParentGuardian(parentGuardianInfo);
  //     }
  //     if(parentFatherInfo.length>0 && parentFatherInfo[0].recordtype === 'Parent_Father'){
  //       let obj1 = {
  //         id : parentFatherInfo[0].id,
  //         fatherfirstname: parentFatherInfo[0].firstname,
  //         fatherlastname: parentFatherInfo[0].lastname,
  //         fatherphone: parentFatherInfo[0].phone,
  //         fatheremail: parentFatherInfo[0].email,
  //         recordtype: parentFatherInfo[0].recordtype,
  //         primarypar: parentFatherInfo[0].is_primary
  //       };
  //       searchCont.push(obj1);
  //     }
  //     if(parentMotherInfo.length>0 && parentMotherInfo[0].recordtype === 'Parent_Mother'){
  //       let obj2 = {
  //         id : parentMotherInfo[0].id,
  //         motherfirstname: parentMotherInfo[0].firstname,
  //         motherlastname: parentMotherInfo[0].lastname,
  //         motherphone: parentMotherInfo[0].phone,
  //         motheremail: parentMotherInfo[0].email,
  //         recordtype: parentMotherInfo[0].recordtype,
  //         primarymoth: parentMotherInfo[0].is_primary
  //       };
  //       searchCont.push(obj2);
  //     }
  //     if(parentGuardianInfo.length>0 && parentGuardianInfo[0]?.recordtype === 'Parent_Guardian'){
  //       let obj3 = {
  //         id : parentGuardianInfo[0].id,
  //         guardianfirstname: parentGuardianInfo[0].firstname,
  //         guardianastname: parentGuardianInfo[0].lastname,
  //         guardianphone: parentGuardianInfo[0].phone,
  //         guardianemail: parentGuardianInfo[0].email,
  //         recordtype: parentGuardianInfo[0].recordtype,
  //         primaryguar: parentGuardianInfo[0].is_primary
  //       };
  //       searchCont.push(obj3);
  //     }
  //     props.handleParentTab(searchCont);
  //   }
  //   }catch(error){
  //     console.log('error22=>',error)
  //     toast.error(error?.response?.data?.message)
  //   }
   
  // };
  const handleSearch = async (e) => {
    try{
      console.log('e.target.value@@!@=>', e.target.value)
      let contPhone = e.target.value;
      let searchCont = [];
      let allParentContact = await schoolApi.fetchParentContacts();
      console.log('allParentContact@@=>',allParentContact?.contacts)

      if (allParentContact?.contacts) {
        let datafilterarray = allParentContact?.contacts?.filter(
          (value) => value.phone === contPhone
        );
        setFamilyInfo({});
        if (datafilterarray.length > 0) {
          console.log('datafilterarray')
          parentArray.push(datafilterarray[0].id);
          setParentIds((parentValues) => [...parentValues, ...parentArray]);
        }else{
          console.log('In the elseee',familyInfo)
          setParentFather('');
          setParentMother('');
          setParentGuardian('');
        }
        let data = allParentContact?.contacts?.filter((value)=>
          (((datafilterarray[0]?.id === value.id && datafilterarray[0]?.id !== null) ||
              (datafilterarray[0]?.id === value.parentid && datafilterarray[0]?.id !== null)) ||
              ((datafilterarray[0]?.parentid === value.id && datafilterarray[0]?.parentid !== null) ||
              datafilterarray[0]?.parentid === value.parentid && datafilterarray[0]?.parentid !== null))
      )
        let parentFatherInfo = data.filter(
          (value) => value.recordtype === "Parent_Father"
        );
        console.log('parentFatherInfo@@=>',parentFatherInfo)
        if (parentFatherInfo.length > 0) {
          setParentFather(parentFatherInfo);
        }
        let parentMotherInfo = data.filter(
          (value) => value.recordtype === "Parent_Mother"
        );
        console.log('parentMotherInfo@!~1=>',parentMotherInfo)
        if (parentMotherInfo.length > 0) {
          setParentMother(parentMotherInfo);
        }
        let parentGuardianInfo = data.filter(
          (value) => value.recordtype === "Parent_Guardian"
        );
        if (parentGuardianInfo.length > 0) {
          setParentGuardian(parentGuardianInfo);
        }
        if(parentFatherInfo.length>0 && parentFatherInfo[0].recordtype === 'Parent_Father'){
          let obj1 = {
            id : parentFatherInfo[0].id,
            fatherfirstname: parentFatherInfo[0].firstname,
            fatherlastname: parentFatherInfo[0].lastname,
            fatherphone: parentFatherInfo[0].phone,
            fatheremail: parentFatherInfo[0].email,
            recordtype: parentFatherInfo[0].recordtype,
            primarypar: parentFatherInfo[0].is_primary,
          };
          searchCont.push(obj1);
          if(parentFatherInfo[0].is_primary === true){
            props.familyInfo.primaryparent = parentFatherInfo[0].recordtype;
          }
        }
        if(parentMotherInfo.length>0 && parentMotherInfo[0].recordtype === 'Parent_Mother'){
          let obj2 = {
            id : parentMotherInfo[0].id,
            motherfirstname: parentMotherInfo[0].firstname,
            motherlastname: parentMotherInfo[0].lastname,
            motherphone: parentMotherInfo[0].phone,
            motheremail: parentMotherInfo[0].email,
            recordtype: parentMotherInfo[0].recordtype,
            primarymoth: parentMotherInfo[0].is_primary
          };
          if(parentMotherInfo[0].is_primary === true){
            props.familyInfo.primaryparent = parentMotherInfo[0].recordtype;
          }
          searchCont.push(obj2);
        }
        if(parentGuardianInfo.length>0 && parentGuardianInfo[0]?.recordtype === 'Parent_Guardian'){
          let obj3 = {
            id : parentGuardianInfo[0].id,
            guardianfirstname: parentGuardianInfo[0].firstname,
            guardianastname: parentGuardianInfo[0].lastname,
            guardianphone: parentGuardianInfo[0].phone,
            guardianemail: parentGuardianInfo[0].email,
            recordtype: parentGuardianInfo[0].recordtype,
            primaryguar: parentGuardianInfo[0].is_primary
          };
          if(parentGuardianInfo[0].is_primary === true){
            props.familyInfo.primaryparent = parentGuardianInfo[0].recordtype;
          }
          searchCont.push(obj3);
        }
        console.log('searchCont@!@!=>',searchCont)
        props.handleParentTab(searchCont);
      }
    }catch(error){
      console.log('error22=>',error)
      toast.error(error?.response?.data?.message)
    }
  };

  const handleParentChange = async (e) => {
    setFamilyInfo({ ...familyInfo, [e.target.name]: e.target.value });
    props.handleInsertedParents({
      ...familyInfo,
      [e.target.name]: e.target.value,
    });
  };

  const handlePhoneChange = (event) => {
    const inputPhone = event.target.value.replace(/\D/g, "").slice(0, 10);
    if (event.target.name === "fatherphone") {
      handleParentChange({
        target: { name: "fatherphone", value: inputPhone },
      });
      props.handleInsertedParents({
        ...familyInfo,
        [event.target.name]: inputPhone,
      });
    } else if (event.target.name === "motherphone") {
      handleParentChange({
        target: { name: "motherphone", value: inputPhone },
      });
      props.handleInsertedParents({
        ...familyInfo,
        [event.target.name]: inputPhone,
      });
    } else if (event.target.name === "guardianphone") {
      handleParentChange({
        target: { name: "guardianphone", value: inputPhone },
      });
      props.handleInsertedParents({
        ...familyInfo,
        [event.target.name]: inputPhone,
      });
    }
  };

  const handleEmailChange = (event) => {
    const enteredEmail = event.target.value;
    if (event.target.name === "fatheremail") {
      handleParentChange({
        target: { name: "fatheremail", value: enteredEmail },
      });
      props.handleInsertedParents({
        ...familyInfo,
        [event.target.name]: enteredEmail,
      });
    } else if (event.target.name === "motheremail") {
      handleParentChange({
        target: { name: "motheremail", value: enteredEmail },
      });
      props.handleInsertedParents({
        ...familyInfo,
        [event.target.name]: enteredEmail,
      });
    } else if (event.target.name === "guardianemail") {
      handleParentChange({
        target: { name: "guardianemail", value: enteredEmail },
      });
      props.handleInsertedParents({
        ...familyInfo,
        [event.target.name]: enteredEmail,
      });
    }
  };

  const handleCheckbox = (event) => {
    console.log("event.target.checked@@+>", event.target.checked);
    if (event.target.checked === true) {
      if (event.target.name === "primarypar") {
        setDisableMoth(true);
        setDisableGuar(true);
        handleParentChange({
          target: { name: "primarypar", value: true },
        });
        props.handleInsertedParents({
          ...familyInfo,
          [event.target.name]: true,
        });
      } else if (event.target.name === "primarymoth") {
        setDisablePar(true);
        setDisableGuar(true);
        handleParentChange({
          target: { name: "primarymoth", value: true },
        });
        props.handleInsertedParents({
          ...familyInfo,
          [event.target.name]: true,
        });
      } else if (event.target.name === "primaryguar") {
        setDisableMoth(true);
        setDisablePar(true);
        handleParentChange({
          target: { name: "primaryguar", value: true },
        });
        props.handleInsertedParents({
          ...familyInfo,
          [event.target.name]: true,
        });
        props.handleCheckGuar({ [event.target.name]: event.target.checked });
      }
    } else {
      setDisableMoth(false);
      setDisableGuar(false);
      setDisablePar(false);
      console.log('famulyInfo=>',familyInfo)
      if(familyInfo?.primarypar === true){
        console.log('first')
        handleParentChange({
          target: { name: "primarypar", value: '' },
        });
      }else if(familyInfo?.primarymoth === true){
        handleParentChange({
          target: { name: "primarymoth", value: '' },
        });
      }else if(familyInfo?.primaryguar === true){
        handleParentChange({
          target: { name: "primaryguar", value: '' },
        });
      }
     
      props.handleCheckGuar({ [event.target.name]: event.target.checked });
    }
  };

console.log('familyInfo=',familyInfo)
  return (
    <>
       <ToastContainer
        position="top-right"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <Row>
        <Col md={4}></Col>
        <Col md={4}>
          <div className="w-60" style={{marginLeft:"220px"}}>
            <Form>
              <Form.Group controlId="formDropdown">
                <Form.Select size="lg" name="primaryparent" onChange={handlePimaryParent} required value={props?.familyInfo?.recordtype ? props?.familyInfo?.recordtype : props?.familyInfo?.primaryparent}> {/* value={familyInfo?.recordtype} change by mohd. sarfraj | 28-sept-2024. */}
                  <option value="none">--Select Primary--</option>
                  <option value="Parent_Father">Father</option>
                  <option value="Parent_Mother">Mother</option>
                  <option value="Parent_Guardian">Guardian</option>
                </Form.Select>
              </Form.Group>
            </Form>
          </div>
        </Col>
        <Col md={4}>
          <div className="w-70" style={{marginLeft:"94px"}}>
            <Form>
              <Form.Group className="text-right" controlId="parentPhone">
                <Form.Control
                  type="text"
                  placeholder="Search Phone Number"
                  size="lg"
                  name="phone"
                  onChange={handleSearch}
                />
              </Form.Group>
            </Form>
          </div>
        </Col>
      </Row>
      {parentFather ? (
        <>
          {/* <Row>
            <Col md={12}>
              <h3>Father Details:</h3>
              <hr />
            </Col>
          </Row> */}
          <Row>
            <Col md={3}>
              <h3>Father Details :</h3>
            </Col>

            {/* <Col md={9}>
              <Form.Group
                className="mb-3 large-checkbox"
                controlId="fatherIsRetired"
                style={{ display: "inline-block" }}
              >
                <Form.Check
                  type="checkbox"
                  //label="Primary Parent"

                  onChange={handleCheckbox}
                  name="primarypar"
                  disabled={disablePar}
                  checked={parentFather[0].is_primary}
                  value={parentFather[0].is_primary}
                />
              </Form.Group>
            </Col> */}

            <hr />
          </Row>
          <Row>
            <Col md={6}>
              <Form>
                <Form.Group className="mb-3" controlId="fatherFirstName">
                  <Form.Label className="text-formatted">First Name</Form.Label>
                  <Form.Control
                    type="text"
                    name="fatherfirstname"
                    placeholder="Enter Father's First Name"
                    size="lg"
                    value={parentFather[0].firstname}
                    onChange={handleParentChange}
                  />
                </Form.Group>
              </Form>
            </Col>
            <Col md={6}>
              <Form>
                <Form.Group className="mb-3" controlId="fatherLastName">
                  <Form.Label className="text-formatted">Last Name</Form.Label>
                  <Form.Control
                    type="text"
                    name="fatherlastname"
                    placeholder="Enter Father's Last Name"
                    size="lg"
                    value={parentFather[0].lastname}
                    onChange={handleParentChange}
                  />
                </Form.Group>
              </Form>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <Form>
                <Form.Group className="mb-3" controlId="fatherQualification">
                  <Form.Label className="text-formatted">
                    Qualification
                  </Form.Label>
                  <Form.Control
                    type="text"
                    name="fatherqualificatin"
                    placeholder="Enter Qualification"
                    size="lg"
                    value={parentFather[0].qualification}
                    onChange={handleParentChange}
                  />
                </Form.Group>
              </Form>
            </Col>
            <Col md={6}>
              <Form>
                <Form.Group className="mb-3" controlId="fatherOccupation">
                  <Form.Label className="text-formatted">Occupation</Form.Label>
                  <Form.Control
                    type="text"
                    name="fatherprofession"
                    placeholder="Enter Occupation"
                    size="lg"
                    value={parentFather[0].profession}
                    onChange={handleParentChange}
                  />
                </Form.Group>
              </Form>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <Form>
                <Form.Group className="mb-3" controlId="fatherPhone">
                  <Form.Label className="text-formatted">
                    Mobile Phone
                  </Form.Label>
                  <Form.Control
                    type="text"
                    name="fatherphone"
                    placeholder="Enter Phone No.."
                    size="lg"
                    value={parentFather[0].phone}
                    onChange={handleParentChange}
                  />
                </Form.Group>
              </Form>
            </Col>
            <Col md={6}>
              <Form>
                <Form.Group className="mb-3" controlId="fatherEmail">
                  <Form.Label className="text-formatted">Email</Form.Label>
                  <Form.Control
                    type="email"
                    name="fatheremail"
                    placeholder="Enter Email"
                    size="lg"
                    value={parentFather[0].email}
                    onChange={handleParentChange}
                  />
                </Form.Group>
              </Form>
            </Col>
          </Row>
        </>
      ) : (
        <>
          <Form noValidate validated={validatedForParent}>
            <Row>
              <Col md={3}>
                <h3>Father Details :</h3>
              </Col>
              <Col md={9}>
                {console.log("familyInfo@@121=>", familyInfo)}
                <Form.Group
                  className="mb-3 large-checkbox"
                  controlId="fatherIsRetired"
                  style={{ display: "inline-block" }}
                >
                  {/* <Form.Check
                    type="checkbox"
                    //label="Primary Parent"
                    onChange={handleCheckbox}
                    name="primarypar"
                    disabled={disablePar}
                    checked={familyInfo.primarypar}
                    value={familyInfo.primarypar}
                  /> */}
                </Form.Group>
              </Col>
              <hr />
            </Row>
            <Row>
              {console.log("parentChild@@=>", parentChild)}
              <Col md={6}>
                <Form.Group className="mb-3" controlId="FirstName">
                  <Form.Label className="text-formatted">First Name</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter Father's First Name"
                    size="lg"
                    onChange={handleParentChange}
                    name="fatherfirstname" required={requireFather}
                   
                    value={familyInfo.fatherfirstname}
                  />
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group className="mb-3" controlId="fatherLastName">
                  <Form.Label className="text-formatted">Last Name</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter Father's Last Name"
                    size="lg"
                    onChange={handleParentChange}
                    name="fatherlastname"
                    value={familyInfo.fatherlastname}
                    required={requireFather}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <Form.Group className="mb-3">
                  <Form.Label className="text-formatted">
                    Qualification
                  </Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter Qualification"
                    size="lg"
                    onChange={handleParentChange}
                    name="fatherqualification"
                    value={familyInfo.fatherqualification}
                  />
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group className="mb-3" controlId="fatherOccupation">
                  <Form.Label className="text-formatted">Occupation</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter Occupation"
                    size="lg"
                    onChange={handleParentChange}
                    name="fatherprofession"
                    value={familyInfo.fatherprofession}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <Form.Group className="mb-3" controlId="fatherPhone">
                  <Form.Label className="text-formatted">
                    Mobile Phone
                  </Form.Label>
                  <Form.Control
                    type="phone"
                    placeholder="Enter Phone No.."
                    size="lg"
                    onChange={handlePhoneChange}
                    name="fatherphone"
                    value={familyInfo.fatherphone}
                    required={requireFather}
                  />
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group className="mb-3" controlId="fatherEmail">
                  <Form.Label className="text-formatted">Email</Form.Label>
                  <Form.Control
                    type="email"
                    placeholder="Enter Email"
                    size="lg"
                    onChange={handleEmailChange}
                    name="fatheremail"
                    value={familyInfo.fatheremail}
                  />
                </Form.Group>
              </Col>
            </Row>
            {/* <Row>
                <Col md={6}>
                  <Form.Group className="mb-3" controlId="fatherIsRetired">
                    <Form.Check
                      type="checkbox"
                      label="Primary Parent"
                      onChange={handleCheckbox}
                      name="primarypar"
                      disabled={disablePar}
                      value={familyInfo.primarypar}
                    />
                  </Form.Group>
                </Col>
              </Row> */}
          </Form>
        </>
      )}

      {parentMother ? (
        <>
          {/* Mother Details */}
          {/* <Row>
            <Col md={12}>
              <h3>Mother Details:</h3>
              <hr />
            </Col>
          </Row> */}
          <Row>
            <Col md={3}>
              <h3>Mother Details:</h3>
            </Col>

            <Col md={9}>
              <Form.Group
                className="mb-3 large-checkbox"
                controlId="fatherIsRetired"
                style={{ display: "inline-block", marginLeft: "10px" }}
              >
                <Form.Check
                  type="checkbox"
                  // label="Primary Parent"

                  onChange={handleCheckbox}
                  name="primarymoth"
                  checked={parentMother[0].is_primary}
                  disabled={disableMoth}
                  value={parentMother[0].is_primary}
                />
              </Form.Group>
            </Col>

            <hr />
          </Row>
          <Row>
            <Col md={6}>
              <Form>
                <Form.Group className="mb-3" controlId="motherFirstName">
                  <Form.Label className="text-formatted">First Name</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter Mother's First Name"
                    name="motherfirstname"
                    size="lg"
                    value={parentMother[0].firstname}
                    onChange={handleParentChange}
                  />
                </Form.Group>
              </Form>
            </Col>
            <Col md={6}>
              <Form>
                <Form.Group className="mb-3" controlId="motherLastName">
                  <Form.Label className="text-formatted">Last Name</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter Mother's Last Name"
                    name="motherlastname"
                    size="lg"
                    value={parentMother[0].lastname}
                    onChange={handleParentChange}
                  />
                </Form.Group>
              </Form>
            </Col>
            <Row>
              <Col md={6}>
                <Form>
                  <Form.Group className="mb-3" controlId="motherQualification">
                    <Form.Label className="text-formatted">
                      Qualification
                    </Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter Qualification"
                      name="motherqualification"
                      size="lg"
                      value={parentMother[0].qualification}
                      onChange={handleParentChange}
                    />
                  </Form.Group>
                </Form>
              </Col>
              <Col md={6}>
                <Form>
                  <Form.Group className="mb-3" controlId="motherOccupation">
                    <Form.Label className="text-formatted">
                      Occupation
                    </Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter Occupation"
                      name="motherprofession"
                      size="lg"
                      value={parentMother[0].profession}
                      onChange={handleParentChange}
                    />
                  </Form.Group>
                </Form>
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <Form>
                  <Form.Group className="mb-3" controlId="motherPhone">
                    <Form.Label className="text-formatted">
                      Mobile Phone
                    </Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter Phone No.."
                      name="motherphone"
                      size="lg"
                      value={parentMother[0].phone}
                      onChange={handleParentChange}
                    />
                  </Form.Group>
                </Form>
              </Col>
              <Col md={6}>
                <Form>
                  <Form.Group className="mb-3" controlId="motherEmail">
                    <Form.Label className="text-formatted">Email</Form.Label>
                    <Form.Control
                      type="email"
                      placeholder="Enter Email"
                      size="lg"
                      name="motheremail"
                      value={parentMother[0].email}
                      disableMoth
                    />
                  </Form.Group>
                </Form>
              </Col>
            </Row>
          </Row>
        </>
      ) : (
        <>
          <Form noValidate validated={validatedForParent}>
            {/* Mother Details */}
            <Row>
              <Col md={3}>
                <h3>Mother Details:</h3>
              </Col>
              <Col md={9}>
                <Form.Group
                  className="mb-3 large-checkbox"
                  controlId="fatherIsRetired"
                  style={{ display: "inline-block", marginLeft: "10px" }}
                >
                  {/* <Form.Check
                    type="checkbox"
                    // label="Primary Parent"
                    onChange={handleCheckbox}
                    name="primarymoth"
                    checked={familyInfo.primarymoth}
                    disabled={disableMoth}
                    value={familyInfo.primarymoth}
                  /> */}
                </Form.Group>
              </Col>
              <hr />
            </Row>

            <Row>
              <Col md={6}>
                <Form>
                  <Form.Group className="mb-3" controlId="motherFirstName">
                    <Form.Label className="text-formatted">
                      First Name
                    </Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter Mother's First Name"
                      name="motherfirstname"
                      size="lg"
                      onChange={handleParentChange}
                      value={familyInfo.motherfirstname}
                      required={requireMother}
                    />
                  </Form.Group>
                </Form>
              </Col>
              <Col md={6}>
                <Form>
                  <Form.Group className="mb-3" controlId="motherLastName">
                    <Form.Label className="text-formatted">
                      Last Name
                    </Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter Mother's Last Name"
                      name="motherlastname"
                      size="lg"
                      onChange={handleParentChange}
                      value={familyInfo.motherlastname}
                      required={requireMother}
                    />
                  </Form.Group>
                </Form>
              </Col>
              <Row>
                <Col md={6}>
                  <Form>
                    <Form.Group
                      className="mb-3"
                      controlId="motherQualification"
                    >
                      <Form.Label className="text-formatted">
                        Qualification
                      </Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter Qualification"
                        size="lg"
                        name="motherqualification"
                        onChange={handleParentChange}
                        value={familyInfo.motherqualification}
                      />
                    </Form.Group>
                  </Form>
                </Col>
                <Col md={6}>
                  <Form>
                    <Form.Group
                      className="mb-3 mx-2"
                      controlId="motherOccupation"
                    >
                      <Form.Label className="text-formatted">
                        Occupation
                      </Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter Occupation"
                        size="lg"
                        name="motherprofession"
                        onChange={handleParentChange}
                        value={familyInfo.motherprofession}
                      />
                    </Form.Group>
                  </Form>
                </Col>
              </Row>
              <Row>
                <Col md={6}>
                  <Form>
                    <Form.Group className="mb-3" controlId="motherPhone">
                      <Form.Label className="text-formatted">
                        Mobile Phone
                      </Form.Label>
                      <Form.Control
                        type="phone"
                        placeholder="Enter Phone No.."
                        name="motherphone"
                        size="lg"
                        onChange={handlePhoneChange}
                        value={familyInfo.motherphone}
                        required={requireMother}
                      />
                    </Form.Group>
                  </Form>
                </Col>
                <Col md={6}>
                  <Form>
                    <Form.Group className="mb-3 mx-2" controlId="motherEmail">
                      <Form.Label className="text-formatted">Email</Form.Label>
                      <Form.Control
                        type="email"
                        placeholder="Enter Email"
                        size="lg"
                        name="motheremail"
                        onChange={handleEmailChange}
                        value={familyInfo?.motheremail}
                      />
                    </Form.Group>
                  </Form>
                </Col>
              </Row>
              {/* <Row>
              <Col md={6}>
                <Form.Group className="mb-3" controlId="fatherIsRetired">
                  <Form.Check
                    type="checkbox"
                    label="Primary Parent"
                    onChange={handleCheckbox}
                    name="primarymoth"
                   // checked={props.familyInfo.fatherIsRetired}
                    disabled={disableMoth}
                    value={familyInfo.primarymoth}
                  />
                </Form.Group>
              </Col>
            </Row> */}
            </Row>
          </Form>
        </>
      )}
      {ParentGuardian ? (
        <>
          {/* Guardian Details */}
          <Row>
            <Col md={12}>
              <h3>Guardian Details:</h3>
              <hr />
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <Form>
                <Form.Group className="mb-3" controlId="guardianFirstName">
                  <Form.Label className="text-formatted">First Name</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter Guardian's First Name"
                    name="guardianfirstname"
                    size="lg"
                    value={ParentGuardian[0].firstname}
                    onChange={handleParentChange}
                  />
                </Form.Group>
              </Form>
            </Col>
            <Col md={6}>
              <Form>
                <Form.Group className="mb-3" controlId="guardianLastName">
                  <Form.Label className="text-formatted">Last Name</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter Guardian's Last Name"
                    name="guardianlastname"
                    size="lg"
                    value={ParentGuardian[0].lastname}
                    onChange={handleParentChange}
                  />
                </Form.Group>
              </Form>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <Form>
                <Form.Group className="mb-3" controlId="guardianQualification">
                  <Form.Label className="text-formatted">
                    Qualification
                  </Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter Qualification"
                    size="lg"
                    name="guardianqualification"
                    value={ParentGuardian[0].qualification}
                    onChange={handleParentChange}
                  />
                </Form.Group>
              </Form>
            </Col>
            <Col md={6}>
              <Form>
                <Form.Group className="mb-3" controlId="guardianOccupation">
                  <Form.Label className="text-formatted">Occupation</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter Occupation"
                    name="guardianprofession"
                    size="lg"
                    value={ParentGuardian[0].profession}
                    onChange={handleParentChange}
                  />
                </Form.Group>
              </Form>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <Form>
                <Form.Group className="mb-3" controlId="guardianPhone">
                  <Form.Label className="text-formatted">
                    Mobile Phone
                  </Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter Phone No.."
                    name="guardianphone"
                    size="lg"
                    value={ParentGuardian[0].phone}
                    onChange={handleParentChange}
                  />
                </Form.Group>
              </Form>
            </Col>
            <Col md={6}>
              <Form>
                <Form.Group className="mb-3" controlId="guardianEmail">
                  <Form.Label className="text-formatted">Email</Form.Label>
                  <Form.Control
                    type="email"
                    placeholder="Enter Email"
                    size="lg"
                    name="guardianemail"
                    value={ParentGuardian[0].email}
                    onChange={handleParentChange}
                  />
                </Form.Group>
              </Form>
            </Col>
          </Row>
        </>
      ) : (
        <>
          {/* Guardian Details */}
          <Form noValidate validated={validatedForParent}>
            <Row>
              <Col md={3}>
                <h3>Guardian Details :</h3>
              </Col>
              {/* <Col md={9}>
                <Form.Group
                  className="mb-3 large-checkbox"
                  controlId="fatherIsRetired"
                  style={{ display: "inline-block", marginLeft: "10px" }}
                >
                  <Form.Check
                    type="checkbox"
                    //label="Primary Parent"
                    onChange={handleCheckbox}
                    name="primaryguar"
                    disabled={disableGuar}
                    checked={familyInfo.primaryguar}
                    value={familyInfo.primaryguar}
                  />
                </Form.Group>
              </Col> */}
              <hr />
            </Row>
            {console.log("guar_fstname@=>", guar_fstname)}
            <Row>
              <Col md={6}>
                <Form>
                  <Form.Group className="mb-3" controlId="guardianFirstName">
                    <Form.Label className="text-formatted">
                      First Name
                    </Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter Guardian's First Name"
                      name="guardianfirstname"
                      size="lg"
                      onChange={handleParentChange}
                      value={guar_fstname === false?'':familyInfo.guardianfirstname}
                     // required={guar_fstname === true ? true : false}
                     required={requireGuardian}
                    />
                  </Form.Group>
                </Form>
              </Col>
              <Col md={6}>
                <Form>
                  <Form.Group className="mb-3" controlId="guardianLastName">
                    <Form.Label className="text-formatted">
                      Last Name
                    </Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter Guardian's Last Name"
                      name="guardianlastname"
                      size="lg"
                      onChange={handleParentChange}
                      value={guar_lstname === false?'':familyInfo.guardianlastname}
                      //required={guar_lstname === true ? true : false}
                      required={requireGuardian}
                    />
                  </Form.Group>
                </Form>
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <Form>
                  <Form.Group
                    className="mb-3"
                    controlId="guardianQualification"
                  >
                    <Form.Label className="text-formatted">
                      Qualification
                    </Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter Qualification"
                      size="lg"
                      name="guardianqualification"
                      onChange={handleParentChange}
                      value={familyInfo.guardianqualification}
                    />
                  </Form.Group>
                </Form>
              </Col>
              <Col md={6}>
                <Form>
                  <Form.Group className="mb-3" controlId="guardianOccupation">
                    <Form.Label className="text-formatted">
                      Occupation
                    </Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter Occupation"
                      size="lg"
                      name="guardianprofession"
                      onChange={handleParentChange}
                      value={familyInfo.guardianprofession}
                    />
                  </Form.Group>
                </Form>
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <Form>
                  <Form.Group className="mb-3" controlId="guardianPhone">
                    <Form.Label className="text-formatted">
                      Mobile Phone
                    </Form.Label>
                    <Form.Control
                      type="phone"
                      placeholder="Enter Phone No.."
                      size="lg"
                      name="guardianphone"
                      onChange={handlePhoneChange}
                      value={guar_phone === false?'':familyInfo.guardianphone}
                      //required={guar_phone === true ? true : false}
                      required={requireGuardian}
                    />
                  </Form.Group>
                </Form>
              </Col>
              <Col md={6}>
                <Form>
                  <Form.Group className="mb-3" controlId="guardianEmail">
                    <Form.Label className="text-formatted">Email</Form.Label>
                    <Form.Control
                      type="email"
                      placeholder="Enter Email"
                      size="lg"
                      name="guardianemail"
                      onChange={handleEmailChange}
                      value={familyInfo.guardianemail}
                    />
                  </Form.Group>
                </Form>
              </Col>
            </Row>
          </Form>
        </>
      )}
    </>
  );
};
export default ParentTab;