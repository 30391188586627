/**
 * @author: Abdul Pathan
 */

import React, { useEffect, useState } from "react";
import { Button, Col, Row, Table } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { ShimmerTable } from "react-shimmer-effects";
import InfoPill from "../InfoPill";
import schoolApi from "../../api/schoolApi";
// import PubSub from "pubsub-js";
import {
  DatatableWrapper,
  Filter,
  Pagination,
  PaginationOptions,
  TableBody,
  TableHeader,
} from "react-bs-datatable";
import AddClass from "./AddClass";
import AddSection from "../section/AddSection";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Confirm from "../Confirm";
import { usePermissions } from "../contexts/PermissionContext";
import { Helmet } from "react-helmet";

const ClassList = (props) => {
  const navigate = useNavigate();
  const { rolePermissions, userInfo } = usePermissions();
  console.log("rolePermissions class list=======>", rolePermissions);
  const [body, setBody] = useState([]);
  const [sectionBody, setSectionBody] = useState([]);
  const [modalShow, setModalShow] = useState(false);
  const [rowRecords, setRowRecords] = useState();
  const [statusRow, setStatusRow] = useState(undefined);
  const [addModalShow, setAddModalShow] = useState(false);
  const canCreate = rolePermissions?.Class_List?.can_create;
  const canEdit = rolePermissions?.Class_List?.can_edit;
  const canDelete = rolePermissions?.Class_List?.can_delete;
  console.log("canCreate canEdit canDelete", canCreate, canEdit, canDelete);

  const fetchClassRecords = async () => {
    try {
      const result = await schoolApi.getClassRecords();
    if(result.success){
      setBody(result?.fullData);
    }
    else{
      toast.error(result.message);
      setBody([]);
    }
    } catch (error) {
      setBody([]);
      return toast.error(error.response.data.message);
    }

  };

  useEffect(() => {
    fetchClassRecords();
  }, [statusRow]);

  const statusHandler = (val) => {
    if (
      canEdit ||
      userInfo?.userrole === "SYS_ADMIN" ||
      userInfo?.userrole === "ADMIN"
    ) {
      val.classname = val.classname.split(" ")[0];
      setStatusRow(val);
    }
  };

  //Randomize data of the table columns.Note that the fields are all using the `prop` field of the headers.
  const labels = {
    beforeSelect: " ",
  };
  // Create add Section by Shahir Hussain 10-05-2024
  const handleSection = (row) => {
    console.log("handleEdit@@@@=>", row);
    setAddModalShow(true);
    setRowRecords(row);
  };

  /*------ Promotion Handler ------*/
  const promoteHandler = (obj) => {
    navigate("/promotion", { state: obj });
  };

  //table headers
  const header = [
    { title: "Class Name", prop: "classname", isFilterable: true, show: true },
    {
      /*Create This Section By Shahir Hussain 13-05-2024*/ title: "Section",
      prop: "section",
      isFilterable: true,
      show: true,
      cell: (row) => (
        <>
          {row.section.map((sec, index) => (
            <React.Fragment key={sec.section_id}>
              <Link
                to={{
                  pathname: "/section/" + sec.section_id,
                  state: { section: sec },
                }}
                state={{
                  section_id: sec.section_id,
                  section_name: sec.section_name,
                  classpath: "/classes",
                }}
              >
                {sec.section_name}
              </Link>
              {index < row.section.length - 1 && ", "}
            </React.Fragment>
          ))}
        </>
      ),
    },
    { title: "Category", prop: "category", isFilterable: true, show: true,
     },
    {
      title: "Status",
      prop: "id",
      show: true,
      cell: (row) => {
        let myBoolean = row.status === "active" ? "Active" : "InActive";
        return (
          <Button
            className="btn-sm mx-2"
            onClick={() => statusHandler(row)}
            style={{ width: "80px" }}
            variant={myBoolean === "Active" ? "success" : "danger"}
          >
            {myBoolean}
          </Button>
        );
      },
    },
    {
      title: "Actions",
      prop: "id",
      show: userInfo?.userrole === "SYS_ADMIN" || userInfo?.userrole === "ADMIN"? true: canEdit,
      cell: (row) => {
        return (
          <>
            <Button
              className="btn-sm mx-2 edit-button"
              variant=""
              onClick={() => handleEdit(row)}
            >
              <i className="fa-regular fa-pen-to-square"></i>
            </Button>
            <Button
              className="btn-sm mx-2"
              variant="primary"
              onClick={() => handleSection(row)}
              disabled={row.status !== "active" ? true : false}
            >
              <i className="fa-regular fa-plus"></i>
            </Button>
          </>
        );
      },
    },
    {
      title: "Promotion",
      prop: "",
      isFilterable: true,
      show: userInfo?.userrole === "SYS_ADMIN" || userInfo?.userrole === "ADMIN"? true: canEdit,
      cell: (row) => {
        return (
          <>
            <Button
              className="btn-light add-new-button"
                      variant=""
              onClick={() => promoteHandler(row)}
            >
              Promote
            </Button>
          </>
        );
      },
    },
  ];

  const visibleHeader = header?.filter((col) => col.show);

  //add record
  const addClass = () => {
    setModalShow(true);
    setRowRecords([]);
  };

  const handleEdit = (row) => {
    console.log("handleEdit =>", row);
    setModalShow(true);
    // const firstClassName = row.classname.split(" ")[0];
    setRowRecords({ ...row, classname: row.classname.split(" ")[0] });
  };

  const recordSaveSuccesfully = () => {
    setModalShow(false);
    fetchClassRecords();
  };

  const classListHandler = async () => {
    console.log("statusRow=====----->>>", statusRow);
    const editRecord = {
      id: statusRow?.class_id,
      classname: statusRow.classname,
      aliasname: statusRow.aliasname,
      status: statusRow?.status === "active" ? "inactive" : "active",
    };
    let response = {};
    response = await schoolApi.updateClassRecord(editRecord);
    if (response.success) {
      toast.success(response.message);
      setStatusRow(undefined);
    } else {
      toast.error(response.message);
    }
  };
  const recordSaveSuccesfullySection = () => {
    setAddModalShow(false);
    fetchClassRecords();
  };

  return (
    <>

      <Helmet>																
        <title>{props?.tabName}</title>			
      </Helmet>
      <Row className="g-0">
      <Col lg={12} className="p-lg-4" style={{ marginTop: "-28px" }}>
        
          {body ? (
            <DatatableWrapper
              body={body}
              headers={visibleHeader}
              paginationOptionsProps={{
                initialState: {
                  rowsPerPage: 15,
                  options: [5, 10, 15, 20],
                },
              }}
            >
              {/* Add by Aamir khan 14-05-2024 */}
              {/* <Col lg={2} className="mt-2"> */}
              <Col lg={12} className="mt-4 p-lg-10" style={{ marginTop: "-27px" }}>
             <Link className="nav-link" to="/" style={{ marginLeft: "1px", marginRight: "1px" }}>  {/* Ui Chnages By Aamir khan 26-09-2024 Start */}
                  Home <i className="fa-solid fa-chevron-right"></i> Classes
                </Link>
              </Col>
              <Row className="mb-4">
                <Col
                  xs={12}
                  lg={3}
                  className="d-flex flex-col justify-content-end align-items-end"
                >
                  <Filter />
                </Col>
                <Col
                  xs={12}
                  sm={6}
                  lg={5}
                  className="d-flex flex-col justify-content-start align-items-start"
                >
                  <PaginationOptions labels={labels} />
                  <div style={{ marginTop: "5px" }}>
                    <InfoPill left="Total Classes " right={body?.length} />
                  </div>
                </Col>
                <Col
                  xs={12}
                  sm={6}
                  lg={4}
                  className="d-flex flex-col justify-content-end align-items-end"
                >
                  {(userInfo?.userrole === "SYS_ADMIN" ||
                    userInfo?.userrole === "ADMIN" ||
                    canCreate) && (
                    <Button
                      className="btn-light add-new-button"
                      variant=""
                      onClick={() => addClass(true)}
                    >
                      Add Class
                    </Button>
                  )}
                </Col>
              </Row>
              <Table striped className="data-table custom-table">
                <TableHeader />
                <TableBody />
              </Table>
              <Pagination />
            </DatatableWrapper>
          ) : (
            <ShimmerTable row={10} col={8} />
          )}
        </Col>
        <Col lg={2}></Col>
      </Row>
      {/*create by shahir hussain 10-05-2024*/}
      {addModalShow && (
        <AddSection
          show={addModalShow}
          parent={rowRecords}
          onHide={() => setAddModalShow(false)}
          sectionRecords={recordSaveSuccesfullySection}
          // sectionRecords={sectionRecords} //---- commented by amir----
          //  recordSaveSuccesfullySection={recordSaveSuccesfullySection} //--- added by amir ---------
          // table="section"
        />
      )}

      {modalShow && (
        <AddClass
          show={modalShow}
          parent={rowRecords}
          onHide={() => setModalShow(false)}
          recordSaveSuccesfully={recordSaveSuccesfully}
        />
      )}
      {statusRow && (
        <Confirm
          show={statusRow}
          onHide={() => setStatusRow(undefined)}
          changeClassStatus={classListHandler}
          title={`Confirm ${
            statusRow?.status === "active" ? "inActive ?" : "Active ?"
          }`}
          message="You are going to update the status. Are you sure?"
          table="classList"
        />
      )}
      <ToastContainer />
    </>
  );
};

export default ClassList;