import React, { useEffect, useState } from "react";
import { Button, Col, Modal, Row, Form } from "react-bootstrap";
import schoolApi from "../../api/schoolApi";
import { useNavigate } from "react-router";
import authApi from "../../api/authApi";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Sidebar from "../layout/common/Sidebar";
import { useOutletContext } from "react-router-dom";

const CreateModuleModal = (props) => {
  console.log('props-in-Module-Modal--->>>' , props);
  
  const [moduleRecords, setModuleRecords] = useOutletContext();
  const navigate = useNavigate();
  const [allModules, setAllModules] = useState([]);
  const [SubModules, setSubModules] = useState([]);
  const [moduleData, setModuleData] = useState({
    name: "",
    status: "",
    api_name: "",
    icon: "",
    url: "",
    parent_module: null,
    icon_type: "",
    order_no: null,
  });

  useEffect(() => {
    fetchAllModules();
  }, []);

  async function fetchAllModules() {
    try {
      let selectedAllModules = [];
      let selectedSubModules = [];
      const result = await schoolApi.getAllModules(authApi.companyDetail().companyid);
      // console.log('result-->>', result);
      if (result.success) {
        result?.result?.forEach((element) => {
          if (element.parent_module === null) {
            selectedAllModules.push(element);
          } else if (element.parent_module !== null) {
            selectedSubModules.push(element);
          }
        });
        setSubModules(selectedSubModules);
        setAllModules(selectedAllModules);

        result?.result?.forEach((element) => {
          if (element.parent_module == null) {
            let subModule = [];
            result?.result?.forEach((res) => {
              if (res.parent_module === element.id) {
                subModule.push(res);
              }
              element.subModule = subModule;
            });
          }
        });
        setModuleRecords(result);
      } else {
        setAllModules([]);
      }
    } catch (error) {
      console.log('SERVER-ERROR-->>', error);
    }
  }

  useEffect(() => {
    // fetchAllModules();
    if (props.moduleData) {
      setModuleData({
        name: props.moduleData.name,
        status: props.moduleData.status,
        api_name: props.moduleData.api_name,
        icon: props.moduleData.icon,
        url: props.moduleData.url,
        parent_module: props.moduleData.parent_module,
        icon_type: props.moduleData.icon_type,
        order_no: props.moduleData.order_no,
      });
    }
  }, [props?.moduleData, props?.moduleData?.id]);

  const handleChange = (e) => {
    moduleData.CompanyId = authApi.companyDetail().companyid;
    const { name, value } = e.target;
    let updatedModuleData = { ...moduleData, [name]: value };

    if (name === "name") {
      const apiName = value.replace(/ /g, "_");
      updatedModuleData = { ...updatedModuleData, api_name: apiName };
    }
    setModuleData(updatedModuleData);
  };

  const handleSubmit = async () => {
    try {
      if (moduleData.name && moduleData.status && moduleData.icon_type && moduleData.icon &&((moduleData.parent_module && moduleData.url) ||(!moduleData.parent_module && moduleData.order_no))) {
        if (!moduleData.status) {
          return toast.error("Please select status", {
            position: toast.POSITION.TOP_RIGHT,
            theme: "colored",
            hideProgressBar: true,
          });
        }

        if (moduleData.icon_type === "url" && !moduleData.icon.startsWith("http")) {
          return toast.error("Please enter a valid URL for the icon.",{
            className: 'toast-error'
          });
        }

        if (
          moduleData.icon_type === "className" &&
          !moduleData.icon.startsWith("fa")
        ) {
          return toast.error(
            "Please enter a valid FontAwesome class name for the icon.",{
              className: 'toast-error'
            });
        }

        if (moduleData.parent_module && moduleData.url === "") {
          return toast.error("Please enter url.",{
            className: 'toast-error'
          });
        }
        
        if (moduleData.parent_module && moduleData.url === "") {
          return toast.error("Please enter url.",{
            className: 'toast-error'
          });
        }
        //added by pankaj 27-08-2024
        if (moduleData.parent_module == null && moduleData.name !== "Home") {
          if (
            parseInt(moduleData.order_no) <= 1 ||
            parseInt(moduleData.order_no) >= allModules.length + 1
          ) {
            return toast.error(
              "Order no is not appropriate",{
                className: 'toast-error'
              });
          }

          if (props.moduleData?.id) {
            if (
              parseInt(moduleData.order_no) > allModules.length ||
              parseInt(moduleData.order_no) < 1
            ) {
              return toast.error(
                "Order no is not appropriate",{
                  className: 'toast-error'
                });
            }
          }
        }

        const submodulesdata = [];
        const pastsubmodulesdata = [];
        
          let count = 0;
          if (!moduleData.id) {
            allModules.forEach((element) =>
              element.name === moduleData.name ? count++ : null
            );
          }
          if (count > 0) {
            toast.error("duplicate record")
          } else {
            if (props?.moduleData?.id) {
              if (props.moduleData.parent_module && SubModules.length > 0) {
                if (moduleData.parent_module !== props.moduleData.parent_module) {
                  SubModules.forEach((element) => {
                    if (element.parent_module === props.moduleData.parent_module) {
                      pastsubmodulesdata.push(element);
                    }
                  });
                  if (pastsubmodulesdata.length > 0)
                    pastsubmodulesdata.splice(props.moduleData.order_no - 1, 1);
                }
                SubModules.forEach((element) => {
                  if (element.parent_module === moduleData.parent_module) {
                    submodulesdata.push(element);
                  }
                });
                if (submodulesdata.length > 0) {
                  if (moduleData.parent_module === props.moduleData.parent_module)
                    submodulesdata.splice(props.moduleData.order_no - 1, 1);
                  moduleData.id = props.moduleData.id;
                  submodulesdata.splice(
                    parseInt(moduleData.order_no) - 1,
                    0,
                    moduleData
                  );
                }
              } else {
                allModules.splice(props.moduleData.order_no - 1, 1);
                moduleData.id = props.moduleData.id;
                allModules.splice(parseInt(moduleData.order_no) - 1, 0, moduleData);
              }
            } else {
              if ((moduleData.parent_module !== "" && moduleData.parent_module) && SubModules.length > 0) {
                const hasMatchingParentModule = SubModules.some(
                  (res) => res.parent_module === moduleData.parent_module
                );
                if (hasMatchingParentModule) {
                  SubModules.forEach((element) => {
                    if (element.parent_module === moduleData.parent_module) {
                      submodulesdata.push(element);
                    }
                  });
                  if (submodulesdata.length > 0) {
                    moduleData.id = null;
                    moduleData.isnew = true;
                    submodulesdata.splice(
                      parseInt(moduleData.order_no) - 1,
                      0,
                      moduleData
                    );
                  }
                } else {
                  moduleData.id = null;
                  moduleData.isnew = true;
                  submodulesdata.splice(parseInt(moduleData.order_no), 0, moduleData);
                }
              } else {
                moduleData.id = null;
                moduleData.isnew = true;
                allModules.splice(parseInt(moduleData.order_no) - 1, 0, moduleData);
              }
            }
    
            let newmodules = [];
            if ((moduleData.parent_module !== "" && moduleData.parent_module) && submodulesdata.length > 0) {
              newmodules = submodulesdata.map((record, index) => {
                return { ...record, order_no: index + 1 };
              });
              if (pastsubmodulesdata.length > 0)
                newmodules = newmodules.concat(
                  pastsubmodulesdata.map((record, index) => {
                    return { ...record, order_no: index + 1 };
                  })
                );
            } else {
              newmodules = allModules.map((record, index) => {
                return { ...record, parent_module : null , order_no: index + 1 };
              });
            }
          
            const result = await schoolApi.updateAllParentmodule(newmodules);
    
            if (result.success) {
              toast.success(result.message);
              props.handleCloseModal();
              fetchAllModules();
              props.fetchAllModules();
              setModuleData({
                name: "",
                status: "",
                api_name: "",
                icon: "",
                url: "",
                parent_module: null,
                icon_type: "",
                order_no: null,
              });
            } else {
              console.log(result);
              toast.error(result.message);
            }
          }
      } else {
        return toast.error("Missing required fields",{
          className: 'toast-error'
        })
      }
    } catch (error) {
      console.log('Server-Error-->>' , error);
      return toast.error(error.response.data.message,{
        className: 'toast-error'
      });
    }
  };
  console.log("SubModules-->", SubModules);

  return (
    <Modal
      show={props.modalShow}
      centered
      backdrop="static"
      aria-labelledby="contained-modal-title-vcenter"
      onHide={() => {
        props.handleCloseModal();
        setModuleData({
          name: "",
          status: "",
          api_name: "",
          icon: "",
          url: "",
          parent_module: null,
          icon_type: "",
          order_no: null,
        });
      }}
    >
      <Modal.Header closeButton style={{ maxHeight: "" }}>
        <Modal.Title>
          {props.moduleData ? "Update Module" : "Create Module"}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form className="mt-3">
          <Row>
            <Col lg={6}>
              <Form.Group className="mx-3">
                <Form.Label className="form-view-label">Module Name</Form.Label>
                <Form.Control
                  type="text"
                  name="name"
                  placeholder="Enter Module name"
                  value={moduleData.name}
                  onChange={handleChange}
                  required
                />
              </Form.Group>
            </Col>
            <Col lg={6} className="mt-1">
              <Form.Group className="mx-3">
                <Form.Label>Status</Form.Label>
                <Form.Select
                  name="status"
                  required
                  value={moduleData?.status}
                  onChange={(e) =>
                    setModuleData({ ...moduleData, status: e.target.value })
                  }
                >
                  <option value="" >-- Select Status--</option>
                  <option value="Active">Active</option>
                  <option value="Inactive">InActive</option>
                </Form.Select>
              </Form.Group>
            </Col>
          </Row>
          <Row style={{ paddingTop: "10px" }}>
            <Col lg={6}>
              <Form.Group className="mx-3">
                <Form.Label className="form-view-label">Icon Type</Form.Label>
                <Form.Select
                  required
                  placeholder="Select icon type"
                  name="icon_type"
                  onChange={handleChange}
                  value={moduleData.icon_type}
                >
                  <option value="">Select Icon Type</option>
                  <option value="className">Class Name</option>
                  <option value="url">Url</option>
                </Form.Select>
              </Form.Group>
            </Col>
            <Col lg={6}>
              <Form.Group className="mx-3">
                <Form.Label className="form-view-label">Icon</Form.Label>
                <Form.Control
                  type="text"
                  name="icon"
                  placeholder="Enter Icon name"
                  value={moduleData.icon}
                  onChange={handleChange}
                  required
                />
              </Form.Group>
            </Col>
          </Row>
          <Row style={{ paddingTop: "10px" }}>
            <Col lg={6}>
              <Form.Group className="mx-3">
                <Form.Label className="form-view-label">
                  Parent Module
                </Form.Label>
                <Form.Select
                  name="parent_module"
                  onChange={handleChange}
                  value={moduleData.parent_module}
                // required
                >
                  <option key="default" value="">
                    -- Select Parent id --
                  </option>
                  {allModules &&
                    allModules.map((res) => (
                      <option key={res.id} value={res.id}>
                        {res.name}
                      </option>
                    ))}
                </Form.Select>
              </Form.Group>
            </Col>

            {moduleData.parent_module ? (
              <Col lg={6}>
                <Form.Group className="mx-3">
                  <Form.Label className="form-view-label">URL</Form.Label>
                  <Form.Control
                    type="text"
                    name="url"
                    placeholder="Enter url"
                    value={moduleData.url}
                    onChange={handleChange}
                    required={moduleData.parent_module}
                  />
                </Form.Group>
              </Col>
            ) : (
              ""
            )}
          </Row>
          <Row style={{ paddingTop: "10px" }}>
            <Col lg={6}>
              <Form.Group className="mx-3">
                <Form.Label className="form-view-label">Api Name</Form.Label>
                <Form.Control
                  type="text"
                  name="api_name"
                  placeholder="Enter Api name"
                  value={moduleData.api_name}
                  onChange={handleChange}
                // required
                />
              </Form.Group>
            </Col>
            <Col lg={6}>
              <Form.Group className="mx-3">
                <Form.Label className="form-view-label">Order No.</Form.Label>
                {/* ------------ code 19-nov-24 sarfraj -------------- */}
                {/* <Form.Control
                  type="number"
                  name="order_no"
                  min={1}
                  placeholder="Enter order no."
                  // disabled={moduleData.parent_module}
                  value={moduleData.order_no}
                  onChange={handleChange}
                  required={moduleData.parent_module ? false : true}
                /> */}
                  <Form.Control
          type="text"
          name="order_no"
          placeholder="Enter order no."
          value={moduleData.order_no}
          onChange={(e) => {
            const value = e.target.value.replace(/[^0-9]/g, '');
            // handleChange({ target: { name: e.target.name, value } });
            setModuleData((prevData) => ({
              ...prevData,
              [e.target.name]: value,
            }));
          }}
          // min={1}
          required={!moduleData.parent_module}
        />
              </Form.Group>
            </Col>
          </Row>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="success" onClick={handleSubmit}>
          {props.btnName}
        </Button>
        <Button
          variant="light"
          onClick={() => {
            props.handleCloseModal();
            setModuleData({
              name: "",
              status: "",
              api_name: "",
              icon: "",
              url: "",
              parent_module: null,
              icon_type: "",
              order_no: null,
            });
          }}
        >
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
export default CreateModuleModal;

// import React, { useEffect, useState } from "react";
// import { Button, Col, Modal, Row, Form } from "react-bootstrap";
// import schoolApi from "../../api/schoolApi";
// import PubSub from "pubsub-js";
// import { useNavigate } from "react-router";
// import authApi from "../../api/authApi";
// import { toast } from "react-toastify";
// import "react-toastify/dist/ReactToastify.css";
// import Sidebar from "../layout/common/Sidebar";

// import { useOutletContext } from "react-router-dom";
// const CreateModuleModal = (props) => {
//   const [moduleRecords, setModuleRecords] = useOutletContext();
//   const navigate = useNavigate();
//   const [allModules, setAllModules] = useState([]);
//   const [SubModules, setSubModules] = useState([]);
//   const [moduleData, setModuleData] = useState({
//     name: "",
//     status: "",
//     api_name: "",
//     icon: "",
//     url: "",
//     parent_module: null,
//     icon_type: "",
//     order_no: null,
//   });

//   useEffect(() => {
//     fetchAllModules();
//   }, []);

//   async function fetchAllModules() {
//     try {
//       let selectedAllModules = [];
//       let selectedSubModules = [];
//       const result = await schoolApi.getAllModules(authApi.companyDetail().companyid);
//       // console.log('result-->>', result);
//       if (result.success) {
//         result?.result?.forEach((element) => {
//           if (element.parent_module === null) {
//             selectedAllModules.push(element);
//           } else if (element.parent_module !== null) {
//             selectedSubModules.push(element);
//           }
//         });
//         setSubModules(selectedSubModules);
//         setAllModules(selectedAllModules);

//         result?.result?.forEach((element) => {
//           if (element.parent_module == null) {
//             let subModule = [];
//             result?.result?.forEach((res) => {
//               if (res.parent_module === element.id) {
//                 subModule.push(res);
//               }
//               element.subModule = subModule;
//             });
//           }
//         });
//         setModuleRecords(result);
//       } else {
//         setAllModules([]);
//       }
//     } catch (error) {
//       console.log('SERVER-ERROR-->>', error);
//     }
//   }

//   useEffect(() => {
//     fetchAllModules();
//     if (props.moduleData) {
//       setModuleData({
//         name: props.moduleData.name,
//         status: props.moduleData.status,
//         api_name: props.moduleData.api_name,
//         icon: props.moduleData.icon,
//         url: props.moduleData.url,
//         parent_module: props.moduleData.parent_module,
//         icon_type: props.moduleData.icon_type,
//         order_no: props.moduleData.order_no,
//       });
//     } else {
//       setModuleData({
//         name: "",
//         status: "",
//         api_name: "",
//         icon: "",
//         url: "",
//         parent_module: null,
//         icon_type: "",
//         order_no: null,
//       });
//     }
//   }, [props.moduleData]);

//   const handleChange = (e) => {
//     moduleData.CompanyId = authApi.companyDetail().companyid;
//     const { name, value } = e.target;
//     let updatedModuleData = { ...moduleData, [name]: value };

//     if (name === "name") {
//       const apiName = value.replace(/ /g, "_");
//       updatedModuleData = { ...updatedModuleData, api_name: apiName };
//     }

//     setModuleData(updatedModuleData);
//   };

//   const handleSubmit = async () => {
// try {
//     if (moduleData.name &&moduleData.status &&moduleData.icon_type &&moduleData.icon &&((moduleData.parent_module && moduleData.url) ||(!moduleData.parent_module && moduleData.order_no))) {
//       if (!moduleData.status) {
//         return toast.error("Please select status", {
//           position: toast.POSITION.TOP_RIGHT,
//           theme: "colored",
//           hideProgressBar: true,
//         });
//       }

//       if (
//         moduleData.icon_type === "url" &&
//         !moduleData.icon.startsWith("http")
//       ) {
//         return toast.error("Please enter a valid URL for the icon.",{
//           className: 'toast-error'
//         });
//       }

//       if (
//         moduleData.icon_type === "className" &&
//         !moduleData.icon.startsWith("fa")
//       ) {
//         return toast.error(
//           "Please enter a valid FontAwesome class name for the icon.",{
//             className: 'toast-error'
//           });
//       }

//       if (moduleData.parent_module && moduleData.url === "") {
//         return toast.error("Please enter url.",{
//           className: 'toast-error'
//         });
//       }
//       if (moduleData.parent_module && moduleData.url === "") {
//         return toast.error("Please enter url.",{
//           className: 'toast-error'
//         });
//       }
//       //added by pankaj 27-08-2024
//       if (moduleData.parent_module == null && moduleData.name !== "Home") {
//         if (
//           parseInt(moduleData.order_no) <= 1 ||
//           parseInt(moduleData.order_no) >= allModules.length + 1
//         ) {
//           return toast.error(
//             "Order no is not appropriate",{
//               className: 'toast-error'
//             });
//         }

//         if (props.moduleData?.id) {
//           if (
//             parseInt(moduleData.order_no) > allModules.length ||
//             parseInt(moduleData.order_no) < 1
//           ) {
//             return toast.error(
//               "Order no is not appropriate",{
//                 className: 'toast-error'
//               });
//           }
//         }
//       }

//       const submodulesdata = [];
//       const pastsubmodulesdata = [];
//       if (props?.moduleData?.id) {
//         if (props.moduleData.parent_module && SubModules.length > 0) {
//           if (moduleData.parent_module !== props.moduleData.parent_module) {
//             SubModules.forEach((element) => {
//               if (element.parent_module === props.moduleData.parent_module) {
//                 pastsubmodulesdata.push(element);
//               }
//             });
//             if (pastsubmodulesdata.length > 0)
//               pastsubmodulesdata.splice(props.moduleData.order_no - 1, 1);
//           }
//           SubModules.forEach((element) => {
//             if (element.parent_module === moduleData.parent_module) {
//               submodulesdata.push(element);
//             }
//           });
//           if (submodulesdata.length > 0) {
//             if (moduleData.parent_module === props.moduleData.parent_module)
//               submodulesdata.splice(props.moduleData.order_no - 1, 1);
//             moduleData.id = props.moduleData.id;
//             submodulesdata.splice(
//               parseInt(moduleData.order_no) - 1,
//               0,
//               moduleData
//             );
//           }
//         } else {
//           allModules.splice(props.moduleData.order_no - 1, 1);
//           moduleData.id = props.moduleData.id;
//           allModules.splice(parseInt(moduleData.order_no) - 1, 0, moduleData);
//         }
//       } else {
//         if (moduleData.parent_module && SubModules.length > 0) {
//           const hasMatchingParentModule = SubModules.some(
//             (res) => res.parent_module === moduleData.parent_module
//           );
//           if (hasMatchingParentModule) {
//             SubModules.forEach((element) => {
//               if (element.parent_module === moduleData.parent_module) {
//                 submodulesdata.push(element);
//               }
//             });
//             if (submodulesdata.length > 0) {
//               moduleData.id = null;
//               moduleData.isnew = true;
//               submodulesdata.splice(
//                 parseInt(moduleData.order_no) - 1,
//                 0,
//                 moduleData
//               );
//             }
//           } else {
//             moduleData.id = null;
//             moduleData.isnew = true;
//             submodulesdata.splice(parseInt(moduleData.order_no), 0, moduleData);
//           }
//         } else {
//           moduleData.id = null;
//           moduleData.isnew = true;
//           allModules.splice(parseInt(moduleData.order_no) - 1, 0, moduleData);
//         }
//       }
//       let newmodules = [];
//       if (moduleData.parent_module && submodulesdata.length > 0) {
//         newmodules = submodulesdata.map((record, index) => {
//           return { ...record, order_no: index + 1 };
//         });
//         if (pastsubmodulesdata.length > 0)
//           newmodules = newmodules.concat(
//             pastsubmodulesdata.map((record, index) => {
//               return { ...record, order_no: index + 1 };
//             })
//           );
//       } else {
//         newmodules = allModules.map((record, index) => {
//           return { ...record, order_no: index + 1 };
//         });
//       }
//       const result = await schoolApi.updateAllParentmodule(newmodules);

//       if (result.success) {
//         toast.success(result.message);
//         props.handleCloseModal();
//         fetchAllModules();
//         props.fetchAllModules();
//         setModuleData({
//           name: "",
//           status: "",
//           api_name: "",
//           icon: "",
//           url: "",
//           parent_module: null,
//           icon_type: "",
//           order_no: null,
//         });
//       } else {
//         console.log(result);
//         toast.error(result.message);
//       }
//     } else {
//       return toast.error("Missing required fields",{
//         className: 'toast-error'
//       })
//       // return toast.error("Please fill all the required fields.", {
//       //   position: toast.POSITION.TOP_CENTER,
//       //   theme: "colored",
//       //   hideProgressBar: true,
//       // });
//     }
//   } catch (error) {
//     return toast.error(error.response.data.message,{
//       className: 'toast-error'
//     });
//   }
//   };
//   console.log("SubModules-->", SubModules);

//   return (
//     <Modal
//       show={props.modalShow}
//       centered
//       backdrop="static"
//       aria-labelledby="contained-modal-title-vcenter"
//       onHide={() => {
//         props.handleCloseModal();
//         setModuleData({
//           name: "",
//           status: "",
//           api_name: "",
//           icon: "",
//           url: "",
//           parent_module: null,
//           icon_type: "",
//           order_no: null,
//         });
//       }}
//     >
//       <Modal.Header closeButton style={{ maxHeight: "" }}>
//         <Modal.Title>
//           {props.moduleData ? "Update Module" : "Create Module"}
//         </Modal.Title>
//       </Modal.Header>
//       <Modal.Body>
//         <Form className="mt-3">
//           <Row>
//             <Col lg={6}>
//               <Form.Group className="mx-3">
//                 <Form.Label className="form-view-label">Module Name</Form.Label>
//                 <Form.Control
//                   type="text"
//                   name="name"
//                   placeholder="Enter Module name"
//                   value={moduleData.name}
//                   onChange={handleChange}
//                   required
//                 />
//               </Form.Group>
//             </Col>
//             <Col lg={6} className="mt-1">
//               <Form.Group className="mx-3">
//                 <Form.Label>Status</Form.Label>
//                 <Form.Select
//                   name="status"
//                   required
//                   value={moduleData?.status}
//                   onChange={(e) =>
//                     setModuleData({ ...moduleData, status: e.target.value })
//                   }
//                 >
//                   <option value="" >-- Select Status--</option>
//                   <option value="Active">Active</option>
//                   <option value="Inactive">Inactive</option>
//                 </Form.Select>
//               </Form.Group>
//             </Col>
//           </Row>
//           <Row style={{ paddingTop: "10px" }}>
//             <Col lg={6}>
//               <Form.Group className="mx-3">
//                 <Form.Label className="form-view-label">Icon Type</Form.Label>
//                 <Form.Select
//                   required
//                   placeholder="Select icon type"
//                   name="icon_type"
//                   onChange={handleChange}
//                   value={moduleData.icon_type}
//                 >
//                   <option value="">Select Icon Type</option>
//                   <option value="className">Class Name</option>
//                   <option value="url">Url</option>
//                 </Form.Select>
//               </Form.Group>
//             </Col>
//             <Col lg={6}>
//               <Form.Group className="mx-3">
//                 <Form.Label className="form-view-label">Icon</Form.Label>
//                 <Form.Control
//                   type="text"
//                   name="icon"
//                   placeholder="Enter Icon name"
//                   value={moduleData.icon}
//                   onChange={handleChange}
//                   required
//                 />
//               </Form.Group>
//             </Col>
//           </Row>
//           <Row style={{ paddingTop: "10px" }}>
//             <Col lg={6}>
//               <Form.Group className="mx-3">
//                 <Form.Label className="form-view-label">
//                   Parent Module
//                 </Form.Label>
//                 <Form.Select
//                   name="parent_module"
//                   onChange={handleChange}
//                   value={moduleData.parent_module}
//                 // required
//                 >
//                   <option key="default" value="">
//                     -- Select Parent id --
//                   </option>
//                   {allModules &&
//                     allModules.map((res) => (
//                       <option key={res.id} value={res.id}>
//                         {res.name}
//                       </option>
//                     ))}
//                 </Form.Select>
//               </Form.Group>
//             </Col>

//             {moduleData.parent_module ? (
//               <Col lg={6}>
//                 <Form.Group className="mx-3">
//                   <Form.Label className="form-view-label">URL</Form.Label>
//                   <Form.Control
//                     type="text"
//                     name="url"
//                     placeholder="Enter url"
//                     value={moduleData.url}
//                     onChange={handleChange}
//                     required={moduleData.parent_module}
//                   />
//                 </Form.Group>
//               </Col>
//             ) : (
//               ""
//             )}
//           </Row>
//           <Row style={{ paddingTop: "10px" }}>
//             <Col lg={6}>
//               <Form.Group className="mx-3">
//                 <Form.Label className="form-view-label">Api Name</Form.Label>
//                 <Form.Control
//                   type="text"
//                   name="api_name"
//                   placeholder="Enter Api name"
//                   value={moduleData.api_name}
//                   onChange={handleChange}
//                 // required
//                 />
//               </Form.Group>
//             </Col>
//             <Col lg={6}>
//               <Form.Group className="mx-3">
//                 <Form.Label className="form-view-label">Order No.</Form.Label>
//                 <Form.Control
//                   type="number"
//                   name="order_no"
//                   min={1}
//                   placeholder="Enter order no."
//                   // disabled={moduleData.parent_module}
//                   value={moduleData.order_no}
//                   onChange={handleChange}
//                   required={moduleData.parent_module ? false : true}
//                 />
//               </Form.Group>
//             </Col>
//           </Row>
//         </Form>
//       </Modal.Body>
//       <Modal.Footer>
//         <Button variant="success" onClick={handleSubmit}>
//           {props.btnName}
//         </Button>
//         <Button
//           variant="light"
//           onClick={() => {
//             props.handleCloseModal();
//             setModuleData({
//               name: "",
//               status: "",
//               api_name: "",
//               icon: "",
//               url: "",
//               parent_module: null,
//               icon_type: "",
//               order_no: null,
//             });
//           }}
//         >
//           Close
//         </Button>
//       </Modal.Footer>
//     </Modal>
//   );
// };
// export default CreateModuleModal;