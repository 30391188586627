/**
 * @author: Pawan Singh Sisodiya
 */
import React, { useEffect, useState } from "react";
import { Button, Col, Form, Modal, Row, Table } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import Confirm from "../Confirm";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  DatatableWrapper,
  Filter,
  Pagination,
  PaginationOptions,
  TableBody,
  TableHeader,
} from "react-bs-datatable";
import { ShimmerTable } from "react-shimmer-effects";
import InfoPill from "../InfoPill";
import schoolApi from "../../api/schoolApi";
import { usePermissions } from "../contexts/PermissionContext";
import Select from "react-select";

const FeeDiscount = (props) => {
  const [body, setBody] = useState();
  const [discountRecords, setDiscountRecords] = useState({});
  const [showModal, setShowModal] = useState(false);
  const [reload, setReload] = useState(false);
  const [deleteId, setDeleteId] = useState();
  const [rowRecords, setRowRecords] = useState([]);
  const [updateStatus, setUpdateStatus] = useState(false);
  const [sessionYear, setSessionYear] = useState([]);
  const [feeHeads, setFeeHeads] = useState([{}]);
  const { rolePermissions, userInfo } = usePermissions();
  const [optionFeeHeadMaster, setFeeHeadMaster] = useState([]); // added by Mohd. Sarfraj | 16-aug-2024
  const [selectedFeeHeadMaster, setSelectedFeeHeadMaster] = useState([]); // added by Mohd. Sarfraj | 16-aug-2024 | added for multi fee Discount select.
  console.log("rolePermissions-->", rolePermissions);
  const canCreate = rolePermissions?.Fee_Discount_Master?.can_create;
  const canEdit = rolePermissions?.Fee_Discount_Master?.can_edit;

  useEffect(() => {
    async function init() {
      try {
        const result = await schoolApi.fetchFeeDiscounts();
        console.log("result-->", result);
        let newObj = {
          discount: result[0].json_agg,
        };

        let arr = newObj.discount?.map((element) => {
          return {
            // discount: element.discount,
            id: element.discount.id,
            name: element.discount.name,
            percent: element.discount.percent,
            amount: element.discount.amount,
            fee_head_id: element.discount.fee_head_id,
            status: element.discount.status,
            sessionid: element.discount.sessionid,
            heads: element.heads,
            session: element.session,
          };
        });

        console.log(`arr =====> ${JSON.stringify(arr)}`);

        if (arr) {
          setBody(arr);
        } else {
          setBody([]);
        }
      } catch (error) {
        console.log("error ->", error);
      }
    }
    init();
  }, [reload]);

  useEffect(() => {
    async function getSessions() {
      try {
        const sessions = await schoolApi.fetchSessions();
        const feeHeadsResult = await schoolApi.fetchFeesHeadMaster();

        if (feeHeadsResult.success) {
          const updatedFeeHeads = feeHeadsResult?.result?.map((res) => ({
            label: res.id,
            value: res.name,
          }));

          console.log("updatedFeeHeads-->", updatedFeeHeads);
          setFeeHeads(updatedFeeHeads);
        } else {
          setFeeHeads([]);
        }
        console.log("sessions-->", sessions);
        if (sessions) {
          setSessionYear(sessions);
          console.log("setSessionYear--->", sessionYear);
        } else {
          setSessionYear([]);
        }
      } catch (error) {
        console.log("error ->", error);
      }
    }

    async function initFeeHeadMaster() {
      try {
        const feeHeadMaster = await schoolApi.fetchFeesHeadMaster();
        if (feeHeadMaster.success) {
          let ar = feeHeadMaster?.result?.map((item) => ({
            value: item.id,
            label: item.name,
          }));
          setFeeHeadMaster(ar);
        } else {
          setFeeHeadMaster([]);
        }
      } catch (error) {
        console.log("error ->", error);
      }
    }

    getSessions();
    initFeeHeadMaster();
  }, [showModal]);

  const labels = {
    beforeSelect: " ",
  };

  const header = [
    {
      title: "Name",
      prop: "name",
      isFilterable: true,
      show: true,
    },
    {
      title: "Percent (%)",
      prop: "percent",
      isFilterable: true,
      show: true,
    },
    {
      title: "Amount",
      prop: "amount",
      isFilterable: true,
      show: true,
    },
    {
      title: "Session",
      prop: "session",
      isFilterable: true,
      show: true,
    },
    {
      title: "Fee Head",
      prop: "heads",
      show: true,
      isFilterable: true,
      // cell: (row) =>
      cell: (row) =>
        // console.log('header--row--heads-->' , row.heads),
        row?.heads?.map((headname) => headname.headname).join(", "),
    },
    {
      title: "Status",
      prop: "status",
      isFilterable: true,
      show: true,
      cell: (row) => (
        <Button
          className="btn-sm mx-2"
          style={{ width: "80px" }}
          variant={row.status === "Active" ? "success" : "danger"}
          onClick={() => toggleStatus(row)}
        >
          {row.status}
        </Button>
      ),
    },
    {
      title: "Action",
      prop: "",
      isFilterable: true,
      show:
        userInfo?.userrole === "SYS_ADMIN" || userInfo?.userrole === "ADMIN"
          ? true
          : canEdit,
      cell: (row) => (
        <div>
          <button
            className="btn btn-sm edit-button mx-2"
            onClick={() => handleEditButton(row)}
          >
            <i className="fa-regular fa-pen-to-square"></i>
          </button>
        </div>
      ),
    },
  ];

  const handleEditButton = (row) => {
    console.log("handle edit button clicked", row);
    setDiscountRecords(row);
    setShowModal(true);
    let obj = row?.heads?.map((ele) => {
      return {
        value: ele.fee_head_id,
        label: ele.headname,
      };
    });
    console.log("obj=====>", obj);

    setSelectedFeeHeadMaster(obj);
  };

  const toggleStatus = (row) => {
    if (
      canEdit ||
      userInfo?.userrole === "SYS_ADMIN" ||
      userInfo?.userrole === "ADMIN"
    ) {
      setUpdateStatus(true);
      setRowRecords(row);
    }
  };
  const handleCloseModal = () => {
    setShowModal(false);
    setSelectedFeeHeadMaster([]);
    setDiscountRecords({});
  };
 

  const handleChange = (e) => {
    
    console.log("handleChange ==",e.target.value)
    if (e.target.value.startsWith(" ")) {
      setDiscountRecords((prevState) => ({
        ...prevState,
        [e.target.name]: '',
      }));
      return;
    }

    if ((e.target.name === "percent" || e.target.name === "amount") && isNaN(e.target.value)) {
      console.log("come")
      return;
    }
    console.log("after")
    if(e.target.name === "amount"){
      if(e.target.value.includes(" ")){
        console.log("includes")
        return;
      }
    }
    if(e.target.name === "percent"){
      if(e.target.value.includes(" ")){
        console.log("includes")
        return;
      }
      if(e.target.value <= 100 && e.target.value >= 0){
        setDiscountRecords((prevState) => ({
          ...prevState,
          [e.target.name]: e.target.value,
        }));
      }
      return;
    }
    setReload(false);
    setDiscountRecords((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  const handleSaveNewDiscount = async () => {
    try {
      // setSelectedFeeHeadMaster([]); 
      console.log("update--Discounts-->", discountRecords);
      if (!discountRecords.name || !discountRecords.status || !discountRecords.sessionid) {
        // setSelectedFeeHeadMaster([]); 
        return toast.error("Missing required fields");
      }

      if(!discountRecords.percent && !discountRecords.amount){
        // setSelectedFeeHeadMaster([]); 
        return toast.error("Enter Amount or Percent!!!");
      }
      setReload(false);

      if (discountRecords.id) {
        const id = discountRecords.id;
        // delete discountRecords.id;//comment by shahir hussain 08-11-2024
        delete discountRecords.heads;

        discountRecords.amount = discountRecords.amount
          ? discountRecords.amount
          : 0;
        discountRecords.percent = discountRecords.percent
          ? discountRecords.percent
          : 0;

        const result2 = await schoolApi.updateFeeDiscounts(id, discountRecords);
        console.log("result after successfully record update-->", result2);
        if (result2?.success) {
          setReload(true);
          setShowModal(false);
          setDiscountRecords({});
          setSelectedFeeHeadMaster([]); 
          return toast.success("Record updated successfully");
        } else {
          console.log("result2 == ",result2)
          return toast.error(result2.message);
        }
      } else {
        const result = await schoolApi.createFeeDiscounts(discountRecords);
        console.log("result after successfully record save-->", result);
        if (result?.success) {
          setReload(true);
          setShowModal(false);
          setDiscountRecords({});
          setSelectedFeeHeadMaster([]); 
          return toast.success("Record saved successfully");
          
        } else {
          // console.log("error====>", result.message);
          return toast.error(result?.message);
        }
      }
    } catch (error) {
      console.log("error ->", error);
    }
  };

  const changeDiscountStatus = async () => {
    try {
      if (rowRecords.status == "Active") {
        rowRecords.status = "InActive"; 
      } else {
        rowRecords.status = "Active"; 
      }

      const recordinrow = { ...rowRecords };
      delete recordinrow.heads;

      const result = await schoolApi.updateFeeDiscounts(
        recordinrow.id,
        recordinrow
      );
      console.log("result after status change", result);

      if (result) {
        setUpdateStatus(false);
      }
    } catch (error) {
      console.error("Error during changeDiscountStatus:", error);
      toast.error(error.response.data.message);
    }
  };
  // const changeDiscountStatus = async () => {
  //   try {
  //     const recordinrow = { ...rowRecords };
  //     delete recordinrow.heads;

  //     if (rowRecords.status === "Active") {
  //       rowRecords.status = "InActive"; 
  //     } else {
  //       rowRecords.status = "Active"; 
  //     }

  //     console.log("rowRecords", recordinrow); 
  //     const result = await schoolApi.updateFeeDiscounts(
  //       recordinrow.id,
  //       recordinrow
  //     );
  //     console.log("result after status change", result);

  //     if (result) {
  //       setUpdateStatus(false);
  //     }
  //   } catch (error) {
  //     console.error("Error during changeDiscountStatus:", error);
  //     toast.error(error.response.data.message);
  //   }
  // };


  console.log("feeHeads-->", feeHeads);
  console.log("sessions-->", sessionYear);

  const handleFeeHeadMaster = (selectedOption) => {
    console.log("selectedOption-->", selectedOption);

    setSelectedFeeHeadMaster(selectedOption);

    setDiscountRecords((prevRecords) => {
      console.log("prevRecords ---> ", prevRecords);

      const updatedFeeHeads = Array.isArray(prevRecords.fee_head_id)
        ? [...prevRecords.fee_head_id]
        : [];

      // fiter feeHeads for update record...
      const newUpdatedFeeHeads = selectedOption.map((ele, index) => {
        return ele.value === updatedFeeHeads[index] ? ele?.value : ele.value;
      });

      console.log("updatedFeeHeads", updatedFeeHeads);
      console.log("newUpdatedFeeHeds", newUpdatedFeeHeads);

      const selectedLabel = selectedOption[selectedOption.length - 1]?.value;

      if (selectedLabel && !newUpdatedFeeHeads.includes(selectedLabel)) {
        newUpdatedFeeHeads.push(selectedLabel);
      }

      return {
        ...prevRecords,
        fee_head_id: newUpdatedFeeHeads,
      };
    });
  };

  console.log("discount records-->", discountRecords);

  return (
    <>
      <Helmet>
        <title>{props?.tabName}</title>
      </Helmet>

      <Row className="g-0">
        <Col lg={3} className="mx-3">
          <Link className="nav-link mx-2" to="/">
            Home <i className="fa-solid fa-chevron-right"></i> FeesDiscountList
          </Link>
        </Col>

        <ToastContainer
          position="top-right"
          autoClose={2000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
        />

        {updateStatus && (
          <Confirm
            show={updateStatus}
            onHide={() => setUpdateStatus(false)}
            changeDiscountStatus={changeDiscountStatus}
            title="Confirm update?"
            message="You are going to update the status. Are you sure?"
            table="discount"
          />
        )}

        <Col lg={12} className="p-lg-4">
          {body ? (
            <DatatableWrapper
              body={body}
              headers={header}
              paginationOptionsProps={{
                initialState: {
                  rowsPerPage: 15,
                  options: [5, 10, 15, 20],
                },
              }}
            >
              <Row className="mb-4">
                <Col lg={3}>
                  <Filter />
                </Col>
                <Col lg={1} style={{ "margin-top": "-18px" }}>
                  <PaginationOptions labels={labels} />
                </Col>
                <Col lg={4} style={{ "margin-top": "-13px" }}>
                  <InfoPill left="Total Fees Discount" right={body?.length} />
                </Col>
                <Col
                  lg={4}
                  style={{ "margin-top": "2px" }}
                  className="d-flex flex-col justify-content-end align-items-end"
                >
                  {(userInfo?.userrole === "SYS_ADMIN" ||
                    userInfo?.userrole === "ADMIN" ||
                    canCreate) && (
                    <Button
                      className="btn-light add-new-button"                //added by muskan khan
                      style={{ color: "black" }}
                      variant=""
                      onClick={() => setShowModal(true)}
                    >
                      New Fees Discount
                    </Button>
                  )}
                </Col>
              </Row>
              <Table striped className="data-table">
                <TableHeader />
                <TableBody />
              </Table>
              <Pagination />
            </DatatableWrapper>
          ) : (
            <ShimmerTable row={10} col={4} />
          )}
        </Col>
        <Col lg={2}></Col>
      </Row>
      {/*---------------------- New Exam Schedule Modal -----------------------------------*/}

      <Modal
        show={showModal}
        backdrop="static"
        centered
        aria-labelledby="contained-modal-title-vcenter"
        onHide={handleCloseModal}
        size="md"
      >
        <Modal.Header closeButton>
          <Modal.Title className="text-center w-100">Fee Discount</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <Form className="mt-3">
            <Row>
              <Col lg={6} className="mt-3">
                <Form.Group className="mx-3 fees">
                  <Form.Label className="form-view-label" htmlFor="nameInput">
                    Name
                  </Form.Label>
                  <Form.Control
                    required
                    type="text"
                    name="name"
                    value={discountRecords.name}
                    placeholder="Enter fee discount name"
                    onChange={handleChange}
                  />
                </Form.Group>
              </Col>
              <Col lg={6} className="mt-3">
                <Form.Group className="mx-3">
                  <Form.Label
                    className="form-view-label"
                    htmlFor="formBasicFirstName"
                  >
                    Session
                  </Form.Label>
                  <Form.Select
                    required
                    name="sessionid"
                    value={discountRecords.sessionid}
                    onChange={handleChange}
                  >
                    <option value="">-- Select Session --</option>
                    {sessionYear.map((session) => (
                      <option key={session.id} value={session.id}>
                        {session.year}
                      </option>
                    ))}
                  </Form.Select>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col lg={12} className="mt-3">
                <Form.Group className="mx-3">
                  <Form.Label
                    className="form-view-label"
                    htmlFor="formBasicClass"
                  >
                    Select fee head
                  </Form.Label>
                  <Select
                    placeholder="Select fee head"
                    value={selectedFeeHeadMaster}
                    onChange={handleFeeHeadMaster}
                    options={optionFeeHeadMaster}
                    isMulti={true}
                    name="fee_head_id"
                  ></Select>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col lg={6} className="mt-3">
                <Form.Group className="mx-3 fees">
                  <Form.Label className="form-view-label" htmlFor="nameInput">
                    Percent
                  </Form.Label>
                  <Form.Control
                    required={discountRecords.amount ? false : true}
                    type="numeric"
                    id="nameInput"
                    name="percent"
                    value={discountRecords.percent}
                    placeholder="Enter percent"
                    onChange={handleChange}
                    disabled={discountRecords.amount ? true : false}
                  />
                </Form.Group>
              </Col>
              <Col lg={6} className="mt-3">
                <Form.Group className="mx-3 fees">
                  <Form.Label className="form-view-label" htmlFor="nameInput">
                    Amount
                  </Form.Label>
                  <Form.Control
                    required={discountRecords.percent ? false : true}
                    type="numeric"
                    id="nameInput"
                    name="amount"
                    value={discountRecords.amount}
                    placeholder="Enter amount"
                    onChange={handleChange}
                    disabled={discountRecords.percent ? true : false}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col lg={6} className="mt-3">
                <Form.Group className="mx-3">
                  <Form.Label
                    className="form-view-label"
                    htmlFor="formBasicFirstName"
                  >
                    Status
                  </Form.Label>
                  <Form.Select
                    required
                    name="status"
                    value={discountRecords.status}
                    onChange={handleChange}
                  >
                    <option value="">-- Select Status --</option>
                    <option value="Active">Active</option>
                    <option value="InActive">InActive</option>
                  </Form.Select>
                </Form.Group>
              </Col>
            </Row>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            Close
          </Button>
          <Button variant="primary" onClick={handleSaveNewDiscount}>
            Save
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default FeeDiscount;

// /**
//  * @author: Pawan Singh Sisodiya
//  */
// import React, { useEffect, useState } from "react";
// import { Button, Col, Form, Modal, Row, Table } from "react-bootstrap";
// import { useNavigate } from "react-router-dom";
// import { Helmet } from "react-helmet";
// import { Link } from "react-router-dom";
// import Confirm from "../Confirm";
// import { ToastContainer, toast } from "react-toastify";
// import "react-toastify/dist/ReactToastify.css";
// import {
//   DatatableWrapper,
//   Filter,
//   Pagination,
//   PaginationOptions,
//   TableBody,
//   TableHeader,
// } from "react-bs-datatable";
// import { ShimmerTable } from "react-shimmer-effects";
// import InfoPill from "../InfoPill";
// import schoolApi from "../../api/schoolApi";
// import { usePermissions } from "../contexts/PermissionContext";
// import Select from "react-select";

// const FeeDiscount = (props) => {
//   const [body, setBody] = useState();
//   const [discountRecords, setDiscountRecords] = useState({});
//   const [showModal, setShowModal] = useState(false);
//   const [reload, setReload] = useState(false);
//   const [deleteId, setDeleteId] = useState();
//   const [rowRecords, setRowRecords] = useState([]);
//   const [updateStatus, setUpdateStatus] = useState(false);
//   const [sessionYear, setSessionYear] = useState([]);
//   const [feeHeads, setFeeHeads] = useState([{}]);
//   const { rolePermissions, userInfo } = usePermissions();
//   const [optionFeeHeadMaster, setFeeHeadMaster] = useState([]); // added by Mohd. Sarfraj | 16-aug-2024
//   const [selectedFeeHeadMaster, setSelectedFeeHeadMaster] = useState([]); // added by Mohd. Sarfraj | 16-aug-2024 | added for multi fee Discount select.
//   console.log("rolePermissions-->", rolePermissions);
//   const canCreate = rolePermissions?.Fee_Discount_Master?.can_create;
//   const canEdit = rolePermissions?.Fee_Discount_Master?.can_edit;

//   useEffect(() => {
//     async function init() {
//       try {
//         const result = await schoolApi.fetchFeeDiscounts();
//         console.log("result-->", result);
//         let newObj = {
//           discount: result[0].json_agg,
//         };

//         let arr = newObj.discount?.map((element) => {
//           return {
//             // discount: element.discount,
//             id: element.discount.id,
//             name: element.discount.name,
//             percent: element.discount.percent,
//             amount: element.discount.amount,
//             fee_head_id: element.discount.fee_head_id,
//             status: element.discount.status,
//             sessionid: element.discount.sessionid,
//             heads: element.heads,
//             session: element.session,
//           };
//         });

//         console.log(`arr =====> ${JSON.stringify(arr)}`);

//         if (arr) {
//           setBody(arr);
//         } else {
//           setBody([]);
//         }
//       } catch (error) {
//         console.log("error ->", error);
//       }
//     }
//     init();
//   }, [reload]);

//   useEffect(() => {
//     async function getSessions() {
//       try {
//         const sessions = await schoolApi.fetchSessions();
//         const feeHeadsResult = await schoolApi.fetchFeesHeadMaster();

//         if (feeHeadsResult.success) {
//           const updatedFeeHeads = feeHeadsResult?.result?.map((res) => ({
//             label: res.id,
//             value: res.name,
//           }));

//           console.log("updatedFeeHeads-->", updatedFeeHeads);
//           setFeeHeads(updatedFeeHeads);
//         } else {
//           setFeeHeads([]);
//         }
//         console.log("sessions-->", sessions);
//         if (sessions) {
//           setSessionYear(sessions);
//           console.log("setSessionYear--->", sessionYear);
//         } else {
//           setSessionYear([]);
//         }
//       } catch (error) {
//         console.log("error ->", error);
//       }
//     }

//     async function initFeeHeadMaster() {
//       try {
//         const feeHeadMaster = await schoolApi.fetchFeesHeadMaster();
//         if (feeHeadMaster.success) {
//           let ar = feeHeadMaster?.result?.map((item) => ({
//             value: item.id,
//             label: item.name,
//           }));
//           setFeeHeadMaster(ar);
//         } else {
//           setFeeHeadMaster([]);
//         }
//       } catch (error) {
//         console.log("error ->", error);
//       }
//     }

//     getSessions();
//     initFeeHeadMaster();
//   }, [showModal]);

//   const labels = {
//     beforeSelect: " ",
//   };

//   const header = [
//     {
//       title: "Name",
//       prop: "name",
//       isFilterable: true,
//       show: true,
//     },
//     {
//       title: "Percent (%)",
//       prop: "percent",
//       isFilterable: true,
//       show: true,
//       // cell: (row) => row.discount.percent,
//     },
//     {
//       title: "Amount",
//       prop: "amount",
//       isFilterable: true,
//       show: true,
//       // cell: (row) => row.discount.amount,
//     },
//     {
//       title: "Session",
//       prop: "session",
//       isFilterable: true,
//       show: true,
//       // cell: (row) => row.session,
//     },
//     {
//       title: "Fee Head",
//       prop: "heads",
//       show: true,
//       isFilterable: true,
//       // cell: (row) =>
//       cell: (row) =>
//         // console.log('header--row--heads-->' , row.heads),
//         row?.heads?.map((headname) => headname.headname).join(", "),
//     },
//     {
//       title: "Status",
//       prop: "status",
//       isFilterable: true,
//       show: true,
//       cell: (row) => (
//         <Button
//           className="btn-sm mx-2"
//           style={{ width: "80px" }}
//           variant={row.status === "Active" ? "success" : "danger"}
//           onClick={() => toggleStatus(row)}
//         >
//           {row.status}
//         </Button>
//       ),
//     },
//     {
//       title: "Action",
//       prop: "",
//       isFilterable: true,
//       show:
//         userInfo?.userrole === "SYS_ADMIN" || userInfo?.userrole === "ADMIN"
//           ? true
//           : canEdit,
//       cell: (row) => (
//         <div>
//           <button
//             className="btn btn-sm edit-button mx-2"
//             onClick={() => handleEditButton(row)}
//           >
//             <i className="fa-regular fa-pen-to-square"></i>
//           </button>
//         </div>
//       ),
//     },
//   ];

//   const handleEditButton = (row) => {
//     console.log("handle edit button clicked", row);
//     setDiscountRecords(row);
//     setShowModal(true);
//     let obj = row?.heads?.map((ele) => {
//       return {
//         value: ele.fee_head_id,
//         label: ele.headname,
//       };
//     });
//     console.log("obj=====>", obj);

//     setSelectedFeeHeadMaster(obj);
//   };

//   const toggleStatus = (row) => {
//     if (
//       canEdit ||
//       userInfo?.userrole === "SYS_ADMIN" ||
//       userInfo?.userrole === "ADMIN"
//     ) {
//       setUpdateStatus(true);
//       setRowRecords(row);
//     }
//   };
//   const handleCloseModal = () => {
//     setShowModal(false);
//     setSelectedFeeHeadMaster([]);
//     setDiscountRecords({});
//   };
 
//   // const handleCloseModal = () => {
//   //   setShowModal(false);
//   //   setDiscountRecords({});
//   // };
//   const handleChange = (e) => {
//     if (e.target.name === "percent" && isNaN(e.target.value)) {
//       return;
//     }

//     setReload(false);
//     setDiscountRecords((prevState) => ({
//       ...prevState,
//       [e.target.name]: e.target.value,
//     }));
//   };

//   //comment by shahir hussain 11-11-2024
//   // const handleSaveNewDiscount = async () => {
//   //   try {
//   //     setSelectedFeeHeadMaster([]); 
//   //     console.log("update--Discounts-->", discountRecords);
//   //     if (!discountRecords.name || !discountRecords.status || !discountRecords.sessionid) {
//   //       setSelectedFeeHeadMaster([]); 
//   //       return toast.error("Missing required fields");
//   //     }

//   //     if(!discountRecords.percent && !discountRecords.amount){
//   //       setSelectedFeeHeadMaster([]); 
//   //       return toast.error("Enter Amount or Percent!!!");
//   //     }
//   //     setReload(false);

//   //     if (discountRecords.id) {
//   //       const id = discountRecords.id;
//   //       delete discountRecords.id;
//   //       delete discountRecords.heads;

//   //       discountRecords.amount = discountRecords.amount
//   //         ? discountRecords.amount
//   //         : 0;
//   //       discountRecords.percent = discountRecords.percent
//   //         ? discountRecords.percent
//   //         : 0;

//   //       const result2 = await schoolApi.updateFeeDiscounts(id, discountRecords);
//   //       console.log("result after successfully record update-->", result2);
//   //       if (result2?.success) {
//   //         setReload(true);
//   //         setShowModal(false);
//   //         setDiscountRecords({});
//   //         setSelectedFeeHeadMaster([]); 
//   //         return toast.success("Record updated successfully");
//   //       } else {
//   //         return toast.error(result2.message);
//   //       }
//   //     } else {
//   //       const result = await schoolApi.createFeeDiscounts(discountRecords);
//   //       console.log("result after successfully record save-->", result);
//   //       if (result?.success) {
//   //         setReload(true);
//   //         setShowModal(false);
//   //         setDiscountRecords({});
//   //         setSelectedFeeHeadMaster([]); 
//   //         return toast.success("Record saved successfully");
          
//   //       } else {
//   //         // console.log("error====>", result.message);
//   //         return toast.error(result?.message);
//   //       }
//   //     }
//   //   } catch (error) {
//   //     console.log("error ->", error);
//   //   }
//   // };

//   //updated by shahir Hussin 11-11-2024
//   const handleSaveNewDiscount = async () => {
//     try {
//       // setSelectedFeeHeadMaster([]); 
//       console.log("update--Discounts-->", discountRecords);
//       if (!discountRecords.name || !discountRecords.status || !discountRecords.sessionid) {
//         // setSelectedFeeHeadMaster([]); 
//         return toast.error("Missing required fields");
//       }

//       if(!discountRecords.percent && !discountRecords.amount){
//         // setSelectedFeeHeadMaster([]); 
//         return toast.error("Enter Amount or Percent!!!");
//       }
//       setReload(false);

//       if (discountRecords.id) {
//         const id = discountRecords.id;
//         // delete discountRecords.id;//comment by shahir hussain 08-11-2024
//         delete discountRecords.heads;

//         discountRecords.amount = discountRecords.amount
//           ? discountRecords.amount
//           : 0;
//         discountRecords.percent = discountRecords.percent
//           ? discountRecords.percent
//           : 0;

//         const result2 = await schoolApi.updateFeeDiscounts(id, discountRecords);
//         console.log("result after successfully record update-->", result2);
//         if (result2?.success) {
//           setReload(true);
//           setShowModal(false);
//           setDiscountRecords({});
//           setSelectedFeeHeadMaster([]); 
//           return toast.success("Record updated successfully");
//         } else {
//           console.log("result2 == ",result2)
//           return toast.error(result2.message);
//         }
//       } else {
//         const result = await schoolApi.createFeeDiscounts(discountRecords);
//         console.log("result after successfully record save-->", result);
//         if (result?.success) {
//           setReload(true);
//           setShowModal(false);
//           setDiscountRecords({});
//           setSelectedFeeHeadMaster([]); 
//           return toast.success("Record saved successfully");
          
//         } else {
//           // console.log("error====>", result.message);
//           return toast.error(result?.message);
//         }
//       }
//     } catch (error) {
//       console.log("error ->", error);
//     }
//   };

//   const changeDiscountStatus = async () => {
//     try {
//       const recordinrow = { ...rowRecords };
//       delete recordinrow.heads;

//       if (rowRecords.status === "Active") {
//         rowRecords.status = "InActive"; 
//       } else {
//         rowRecords.status = "Active"; 
//       }

//       console.log("rowRecords", recordinrow); 
//       const result = await schoolApi.updateFeeDiscounts(
//         recordinrow.id,
//         recordinrow
//       );
//       console.log("result after status change", result);

//       if (result) {
//         setUpdateStatus(false);
//       }
//     } catch (error) {
//       console.error("Error during changeDiscountStatus:", error);
//       toast.error(error.response.data.message);
//     }
//   };


//   console.log("feeHeads-->", feeHeads);
//   console.log("sessions-->", sessionYear);

//   const handleFeeHeadMaster = (selectedOption) => {
//     console.log("selectedOption-->", selectedOption);

//     setSelectedFeeHeadMaster(selectedOption);

//     setDiscountRecords((prevRecords) => {
//       console.log("prevRecords ---> ", prevRecords);

//       const updatedFeeHeads = Array.isArray(prevRecords.fee_head_id)
//         ? [...prevRecords.fee_head_id]
//         : [];

//       // fiter feeHeads for update record...
//       const newUpdatedFeeHeads = selectedOption.map((ele, index) => {
//         return ele.value === updatedFeeHeads[index] ? ele?.value : ele.value;
//       });

//       console.log("updatedFeeHeads", updatedFeeHeads);
//       console.log("newUpdatedFeeHeds", newUpdatedFeeHeads);

//       const selectedLabel = selectedOption[selectedOption.length - 1]?.value;

//       if (selectedLabel && !newUpdatedFeeHeads.includes(selectedLabel)) {
//         newUpdatedFeeHeads.push(selectedLabel);
//       }

//       return {
//         ...prevRecords,
//         fee_head_id: newUpdatedFeeHeads,
//       };
//     });
//   };

//   console.log("discount records-->", discountRecords);

//   return (
//     <>
//       <Helmet>
//         {" "}
//         <title>{props?.tabName}</title>{" "}
//       </Helmet>

//       <Row className="g-0">
//         <Col lg={3} className="mx-3">
//           <Link className="nav-link mx-2" to="/">
//             Home <i className="fa-solid fa-chevron-right"></i> FeesDiscountList
//           </Link>
//         </Col>

//         <ToastContainer
//       position="top-right"
//       autoClose={2000}
//       hideProgressBar={false}
//       newestOnTop={false}
//       closeOnClick
//       rtl={false}
//       pauseOnFocusLoss
//       draggable
//       pauseOnHover
//       theme="light"
//       />

//         {updateStatus && (
//           <Confirm
//             show={updateStatus}
//             onHide={() => setUpdateStatus(false)}
//             changeDiscountStatus={changeDiscountStatus}
//             title="Confirm update?"
//             message="You are going to update the status. Are you sure?"
//             table="discount"
//           />
//         )}

//         <Col lg={12} className="p-lg-4">
//           {body ? (
//             <DatatableWrapper
//               body={body}
//               headers={header}
//               paginationOptionsProps={{
//                 initialState: {
//                   rowsPerPage: 15,
//                   options: [5, 10, 15, 20],
//                 },
//               }}
//             >
//               <Row className="mb-4">
//                 <Col lg={3}>
//                   <Filter />
//                 </Col>
//                 <Col lg={1} style={{ "margin-top": "-18px" }}>
//                   <PaginationOptions labels={labels} />
//                 </Col>
//                 <Col lg={4} style={{ "margin-top": "-13px" }}>
//                   <InfoPill left="Total Fees Discount" right={body?.length} />
//                 </Col>
//                 <Col
//                   lg={4}
//                   style={{ "margin-top": "2px" }}
//                   className="d-flex flex-col justify-content-end align-items-end"
//                 >
//                   {(userInfo?.userrole === "SYS_ADMIN" ||
//                     userInfo?.userrole === "ADMIN" ||
//                     canCreate) && (
//                     <Button
//                       className="btn-light add-new-button"                //added by muskan khan
//                       style={{ color: "black" }}
//                       variant=""
//                       onClick={() => setShowModal(true)}
//                     >
//                       New Fees Discount
//                     </Button>
//                   )}
//                 </Col>
//               </Row>
//               <Table striped className="data-table">
//                 <TableHeader />
//                 <TableBody />
//               </Table>
//               <Pagination />
//             </DatatableWrapper>
//           ) : (
//             <ShimmerTable row={10} col={4} />
//           )}
//         </Col>
//         <Col lg={2}></Col>
//       </Row>
//       {/*---------------------- New Exam Schedule Modal -----------------------------------*/}

//       <Modal
//         show={showModal}
//         backdrop="static"
//         centered
//         aria-labelledby="contained-modal-title-vcenter"
//         onHide={handleCloseModal}
//         size="md"
//       >
//         <Modal.Header closeButton>
//           <Modal.Title className="text-center w-100">Fee Discount</Modal.Title>
//         </Modal.Header>
//         <Modal.Body>
//         <Form className="mt-3">
//             <Row>
//               <Col lg={6} className="mt-3">
//                 <Form.Group className="mx-3 fees">
//                   <Form.Label className="form-view-label" htmlFor="nameInput">
//                     Name
//                   </Form.Label>
//                   <Form.Control
//                     required
//                     type="text"
//                     name="name"
//                     value={discountRecords.name}
//                     placeholder="Enter Fee head master"
//                     onChange={handleChange}
//                   />
//                 </Form.Group>
//               </Col>
//               <Col lg={6} className="mt-3">
//                 <Form.Group className="mx-3">
//                   <Form.Label
//                     className="form-view-label"
//                     htmlFor="formBasicFirstName"
//                   >
//                     Session
//                   </Form.Label>
//                   <Form.Select
//                     required
//                     name="sessionid"
//                     value={discountRecords.sessionid}
//                     onChange={handleChange}
//                   >
//                     <option value="">-- Select Session --</option>
//                     {sessionYear.map((session) => (
//                       <option key={session.id} value={session.id}>
//                         {session.year}
//                       </option>
//                     ))}
//                   </Form.Select>
//                 </Form.Group>
//               </Col>
//             </Row>
//             <Row>
//               <Col lg={12} className="mt-3">
//                 <Form.Group className="mx-3">
//                   <Form.Label
//                     className="form-view-label"
//                     htmlFor="formBasicClass"
//                   >
//                     Select fee head
//                   </Form.Label>
//                   <Select
//                     placeholder="Select fee head"
//                     value={selectedFeeHeadMaster}
//                     onChange={handleFeeHeadMaster}
//                     options={optionFeeHeadMaster}
//                     isMulti={true}
//                     name="fee_head_id"
//                   ></Select>
//                 </Form.Group>
//               </Col>
//             </Row>
//             <Row>
//               <Col lg={6} className="mt-3">
//                 <Form.Group className="mx-3 fees">
//                   <Form.Label className="form-view-label" htmlFor="nameInput">
//                     Percent
//                   </Form.Label>
//                   <Form.Control
//                     required={discountRecords.amount ? false : true}
//                     type="numeric"
//                     id="nameInput"
//                     name="percent"
//                     value={discountRecords.percent}
//                     placeholder="Enter percent"
//                     onChange={handleChange}
//                     disabled={discountRecords.amount ? true : false}
//                   />
//                 </Form.Group>
//               </Col>
//               <Col lg={6} className="mt-3">
//                 <Form.Group className="mx-3 fees">
//                   <Form.Label className="form-view-label" htmlFor="nameInput">
//                     Amount
//                   </Form.Label>
//                   <Form.Control
//                     required={discountRecords.percent ? false : true}
//                     type="numeric"
//                     id="nameInput"
//                     name="amount"
//                     value={discountRecords.amount}
//                     placeholder="Enter percent"
//                     onChange={handleChange}
//                     disabled={discountRecords.percent ? true : false}
//                   />
//                 </Form.Group>
//               </Col>
//             </Row>
//             <Row>
//               <Col lg={6} className="mt-3">
//                 <Form.Group className="mx-3">
//                   <Form.Label
//                     className="form-view-label"
//                     htmlFor="formBasicFirstName"
//                   >
//                     Status
//                   </Form.Label>
//                   <Form.Select
//                     required
//                     name="status"
//                     value={discountRecords.status}
//                     onChange={handleChange}
//                   >
//                     <option value="">-- Select Status --</option>
//                     <option value="Active">Active</option>
//                     <option value="InActive">InActive</option>
//                   </Form.Select>
//                 </Form.Group>
//               </Col>
//             </Row>
//           </Form>
//         </Modal.Body>
//         <Modal.Footer>
//           <Button variant="secondary" onClick={handleCloseModal}>
//             Close
//           </Button>
//           <Button variant="primary" onClick={handleSaveNewDiscount}>
//             Save
//           </Button>
//         </Modal.Footer>
//       </Modal>
//     </>
//   );
// };

// export default FeeDiscount;