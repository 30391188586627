import React, { useEffect, useState } from 'react'

import { Alert, Button, Col, Row, Table } from "react-bootstrap";
import schoolApi from '../../api/schoolApi';
import { ShimmerTable } from 'react-shimmer-effects';
import {
    DatatableWrapper, Filter, Pagination,
    PaginationOptions, TableBody, TableHeader,
} from "react-bs-datatable";
import InfoPill from '../InfoPill';
import RouteAdd from './RouteAdd';
import Confirm from '../Confirm';
import { useNavigate, Link } from 'react-router-dom';
import RouteUpdate from './RouteUpdate';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { usePermissions } from "../contexts/PermissionContext"; //Add
import { Helmet } from 'react-helmet';

const RouteList = (props) => {
    const navigate = useNavigate();
    const [Route, setRoute] = useState([]);
    const [addModalShow, setAddModalShow] = useState(false);
    const [modalShow, setModalShow] = useState(false);
    const [successMessage, setSuccessMessage] = useState(null);
    const [selectedRouteId, setSelectedRouteId] = useState(null);
    const [selectedRoute, setSelectedRoute] = useState({});
    const { rolePermissions, userInfo } = usePermissions();
    console.log('rolePermissions-->', rolePermissions);
    const canCreate = rolePermissions?.Route_List?.can_create;
    const canEdit = rolePermissions?.Route_List?.can_edit;
    const canDelete = rolePermissions?.Route_List?.can_delete;


    const fetchRoute = async () => {
        try {
            const routeData = await schoolApi.getAllRoute();
            if (routeData.success) {
                setRoute(routeData.result);
            }
        } catch (error) {
            return toast.error(error.response.data.message)
        }
    };

    useEffect(() => {
        fetchRoute()
    }, []);

    const handleDeleteAndNavigate = async () => {
        if (selectedRouteId) {
            try {
                const response = await schoolApi.deleteRoute(selectedRouteId);

                if (response && response.message === "Route deleted successfully") {
                    const updatedRoute = Route.filter(fare => fare.id !== selectedRouteId);
                    setRoute(updatedRoute);

                    setModalShow(false);

                    fetchRoute();
                    toast.success(response.message);
                }
            } catch (error) {
                return toast.error(error.response.data.message)
            }
        }
    };
    const header = [
        { title: "Location Name", prop: "location_name", isFilterable: true },
        { title: "Vehicle No.", prop: "transport_name", isFilterable: true },
        { title: "Order No.", prop: "order_no", isFilterable: true },
        {
            title: 'Action', prop: '', isFilterable: true,
            show: userInfo?.userrole === "SYS_ADMIN" || userInfo?.userrole === "ADMIN" ? true : canEdit || canDelete,
            cell: (row) => (
                <div>
                    {(userInfo?.userrole === "SYS_ADMIN" || userInfo?.userrole === "ADMIN" || canEdit) &&
                        <button className="btn btn-sm edit-button mx-2"
                            onClick={() => handleEditButton(row)}

                        >
                            <i className="fa-regular fa-pen-to-square"></i>
                        </button>
                    }
                    {(userInfo?.userrole === "SYS_ADMIN" || userInfo?.userrole === "ADMIN" || canDelete) &&
                        <button className="btn btn-sm btn-danger mx-2"
                            onClick={() => handleDeleteButton(row)}
                        >
                            <i className="fa fa-trash"></i>
                        </button>
                    }
                </div>
            )
        },

    ];

    const handleDeleteButton = (row) => {
        setSelectedRouteId(row.id);
        setModalShow(true);
    };

    const handleEditButton = (row) => {
        setSelectedRoute(row);
        setAddModalShow(true);
    };

    const headerStyle = {
        backgroundColor: 'lightblue',
    };

    const labels = {
        beforeSelect: " ",
    };
    const openModal = () => {
        setSelectedRoute({});
        setAddModalShow(true);
    };

    return (
        <>

<Helmet>
        <title>{props?.tabName}</title>
      </Helmet>
            {addModalShow && <RouteAdd
                show={addModalShow}
                handleCloseModal={() => setAddModalShow(false)}
                fetchRoute={fetchRoute}
                selectedRoute={selectedRoute}
            />}

            {modalShow && (
                <Confirm
                    show={modalShow}
                    onHide={() => setModalShow(false)}
                    handleDeleteButton={() => handleDeleteAndNavigate()}
                    title="Confirm delete?"
                    message="You are going to delete the record. Are you sure?"
                    table="RouteDelete"
                />
            )}
            {successMessage && (
                <Alert variant="success" onClose={() => setSuccessMessage(null)} dismissible>
                    {successMessage}
                </Alert>
            )}
            <Row className="g-0">
                <Col lg={3} className="mx-3">
                    <Link className="nav-link mx-2" to="/">Home <i className="fa-solid fa-chevron-right"></i> RouteList</Link>
                </Col>
                <Col lg={12} className="px-lg-4">
                    {Route ? (
                        <DatatableWrapper
                            body={Route}
                            headers={header}
                            paginationOptionsProps={{
                                initialState: {
                                    rowsPerPage: 15,
                                    options: [5, 10, 15, 20],
                                },
                            }}
                        >
                            <Row className="mb-4">
                                <Col
                                    xs={12}
                                    lg={3}
                                    className="d-flex flex-col justify-content-end align-items-end"
                                >
                                    <Filter />
                                </Col>
                                <Col
                                    xs={12}
                                    sm={6}
                                    lg={5}
                                    className="d-flex flex-col justify-content-start align-items-start"
                                >
                                    <PaginationOptions labels={labels} />
                                    <div style={{ "marginTop": "5px" }}>
                                        <InfoPill left="Total Routes" right={Route?.length} />

                                    </div>

                                </Col>
                                <Col
                                    xs={12}
                                    sm={6}
                                    lg={4}
                                    className="d-flex flex-col justify-content-end align-items-end"
                                >
                                    {(userInfo?.userrole === "SYS_ADMIN" || userInfo?.userrole === "ADMIN" || canCreate) &&
                                        <Button
                                            className="btn add-new-button"
                                            variant=""
                                            onClick={openModal}

                                        >
                                            New Route
                                        </Button>
                                    }
                                </Col>
                            </Row>
                            <Table striped className="data-table">
                                <TableHeader headerStyles={headerStyle} />
                                <TableBody />
                            </Table>
                            <Pagination />
                        </DatatableWrapper>
                    ) : (
                        <ShimmerTable row={10} col={8} />
                    )}
                </Col>
                <Col lg={2}></Col>
            </Row>
            <ToastContainer />
        </>
    )
}

export default RouteList