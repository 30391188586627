/**
 * @author      Abdul Pathan
 * @date        Oct, 2024
 * @copyright   www.ibirdsservices.com
 */

import React, { useEffect, useState } from "react";
import { Button, Col, Container, Row, Table } from "react-bootstrap";
import {
  DatatableWrapper,
  Filter,
  Pagination,
  PaginationOptions,
  TableBody,
  TableHeader,
} from "react-bs-datatable";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ResponseMessageAdd from "./ResponseMessageAdd";
import Confirm from "../../Confirm";
import { Link } from "react-router-dom";
import schoolApi from "../../../api/schoolApi";
import { Helmet } from "react-helmet";

const ResponseMessage = (props) => {
  const [body, setBody] = useState([]);
  const [isSpinner, setIsSpinner] = useState(false);
  const [modalShowHide, setModalShowHide] = useState(false);
  const [rowData, setRowData] = useState();

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    const result = await schoolApi.getResponseMessageData();

    if (result.success) {
      setBody(result.records);
    } else {
      setBody([]);
    }
    setIsSpinner(true);
  };


  const labels = { beforeSelect: " " };
  const header = [
    { title: "Type", prop: "type", isFilterable: true },
    { title: "Message", prop: "message" },
    {
      title: "Actions",
      prop: "id",
      cell: (row) => (
        <>
          <Button className="btn-sm mx-2 " onClick={() => editRecord({ row })}>
            <i class="fa-solid fa-pen-to-square" title="Edit"></i>
          </Button>
          <Button
            className="btn-sm mx-2"
            variant="danger"
            onClick={() => deleteRecord({ row })}
          >
            <i class="fa-regular fa-trash-can" title="Delete"></i>
          </Button>
        </>
      ),
    },
  ];

  const addRecord = () => {
    setModalShowHide(true);
    setRowData();
  };

  const editRecord = (data) => {
    setModalShowHide(true);
    setRowData(data?.row);
  };

  const onRefreshData = () => {
    fetchData();
    setModalShowHide(false);
  };

  const [showHideModel, setShowHideModel] = useState(false);

  const deleteRecord = (data) => {
    setShowHideModel(true);
    setRowData(data.row);
  };

  const deleteResponseRecord = async () => {
    if (rowData.id) {
      const result = await schoolApi.deleteResponseMessageRecord(rowData.id);

      if (result.success) {
        fetchData();
        setShowHideModel(false);
      } else {
        setShowHideModel(false);
        toast.error(result.error.message);
      }
    }
  };

  return (
    <>
      <Helmet><title>{props?.tabName}</title></Helmet>
      <Row className="g-0">
        <Col lg={10} className="mx-4">
          <Link className="nav-link" to="/assignsubjectclass">
            Home <i className="fa-solid fa-chevron-right"></i> Response Message
          </Link>
        </Col>
      </Row>
      <Container className="mb-5">
        <Row className="mx-3 g-0">
          <Col lg={12} sm={12} xs={12} className="mb-3">
            <Row className="g-0">
              <Col lg={12} sm={12} xs={12}>
                <DatatableWrapper
                  body={body}
                  headers={header}
                  paginationOptionsProps={{
                    initialState: {
                      rowsPerPage: 10,
                      options: [5, 10, 15, 20],
                    },
                  }}
                >
                  <Row className="mb-2">
                    <Col
                      lg={3}
                      sm={10}
                      xs={10}
                      className="d-flex flex-col justify-content-end align-items-end"
                    >
                      <Filter />
                    </Col>
                    <Col
                      lg={4}
                      sm={2}
                      xs={2}
                      className="d-flex flex-col justify-content-start align-items-start"
                    >
                      <PaginationOptions labels={labels} />
                    </Col>

                    <Col
                      lg={5}
                      sm={12}
                      xs={12}
                      className="mt-2 d-flex flex-col justify-content-end align-items-end"
                    >
                      <Button
                        className="btn-light add-new-button" //added by muskan khan
                        style={{ color: "black" }}
                        variant=""
                        onClick={() => addRecord()}
                      >
                        Add New Message
                      </Button>
                    </Col>
                  </Row>
                  <Table striped className="data-table" responsive="sm">
                    <TableHeader />
                    <TableBody />
                  </Table>
                  <Pagination />
                </DatatableWrapper>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
      <ToastContainer />
      {modalShowHide && (
        <ResponseMessageAdd
          show={modalShowHide}
          onHide={() => {
            setModalShowHide(false);
          }}
          onRefreshData={onRefreshData}
          rowData={rowData}
          table="response_message"
        />
      )}
      {showHideModel && (
        <Confirm
          show={showHideModel}
          onHide={() => setShowHideModel(false)}
          handleDeleteButton={() => deleteResponseRecord()}
          title="Confirm delete?"
          message="You are going to delete the record. Are you sure?"
          table="deleteAssignment"
        />
      )}
    </>
  );
};

export default ResponseMessage;
