import React, { useState, useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import jwt_decode from "jwt-decode";
import "../template/custom.css";
import schoolApi from "../../../api/schoolApi";
import authApi from "../../../api/authApi";
import { toast } from "react-toastify";

const Sidebar = ({ moduleRecords, setModuleRecords }) => {
  const [sidebar, setSidebar] = useState(true);
  const [userInfo, setUserInfo] = useState({});
  const [permissions, setPermissions] = useState();
  const [activeModule, setActiveModule] = useState(null);
  const [openModule, setOpenModule] = useState(null);
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    try {
      if (sessionStorage.getItem("permissiontoken")) {
        sessionStorage.getItem("token");
        const user = jwt_decode(sessionStorage.getItem("token"));
        const userPermission = jwt_decode(sessionStorage.getItem("permissiontoken"));
        setUserInfo(user);

        const perm = userPermission.permissions.map((obj) => obj.name).join(";");
        setPermissions(perm);
      }
      fetchAllModules();
    } catch (error) {
      console.error(error);
    }
  }, []);

  async function fetchAllModules() {
    try {
      const token = sessionStorage.getItem("token");
      if (authApi?.companyDetail()?.companyid) {
        const result = await schoolApi.getAllModules(await authApi.companyDetail()?.companyid);
        console.log('result--->>>' , result.result);
        
        const user = jwt_decode(token);
        //user.userrole !== "ADMIN" &&
        if ( user.userrole !== "SYS_ADMIN") {
          if (user?.companyid) {
            const permissionResult = await schoolApi.getRolePermissionByIds();
            console.log('permissionResult---->>>>>' , permissionResult);

            let filteredModules = null;
            if(user.userrole !== "ADMIN") {
              filteredModules = Object.entries(permissionResult.result)
              ?.filter(([moduleName, Permission]) => Permission.can_read === false)
              ?.map(([moduleName, permissions]) => moduleName);
              
              filteredModules?.forEach((moduleName) => delete permissionResult.result[moduleName]);
            }
            delete permissionResult.result['Module_List']; // this module only visibale for SYS_ADMIN

            console.log('permissionResult-after-delete--->>>' ,permissionResult);

            if (permissionResult?.success) {
              if(result.success){
                const filteredModules = result?.result?.filter((module) => {
                  const permissionKey = module.name.replace(/ /g, "_");
                  return permissionResult?.result.hasOwnProperty(permissionKey);
                });

                console.log('filteredModules--->>>' , filteredModules);

                if (filteredModules !== "No Data Found") {
                  filteredModules?.forEach((element) => {
                    if (element.parent_module == null) {
                      let subModule = [];
                      filteredModules?.forEach((res) => {
                        if (res.parent_module === element.id) {
                          subModule.push(res);
                        }
                        element.subModule = subModule;
                      });
                    }
                  });
                  setModuleRecords(filteredModules);
                } else {
                  setModuleRecords([]);
                }
              } else{
                return toast.error(result.message)
              }
            }
          }
        } else {
          if (result !== "No Data Found") {
            result?.result?.forEach((element) => {
              if (element.parent_module == null) {
                let subModule = [];
                result?.result?.forEach((res) => {
                  if (res.parent_module === element.id) {
                    subModule.push(res);
                  }
                  element.subModule = subModule;
                });
              }
            });
            setModuleRecords(result);
          } else {
            setModuleRecords([]);
          }
        }
      }
    } catch (error) {
      console.log('SERVER-ERROR-->>' , error); 
    }

    // try {
    //   const token = sessionStorage.getItem("token");
    //   if (authApi?.companyDetail()?.companyid) {
    //     const result = await schoolApi.getAllModules(await authApi.companyDetail()?.companyid);
    //     console.log('result-->>' , result);
        
    //     const user = jwt_decode(token);

    //     if (user.userrole !== "ADMIN" && user.userrole !== "SYS_ADMIN") {
    //       if (user?.companyid) {
    //         const permissionResult = await schoolApi.getRolePermissionByIds();
    //         console.log("permissionResult---->>>>>", permissionResult);

    //         const filteredModules = Object.entries(permissionResult)
    //           ?.filter(
    //             ([moduleName, permissions]) => permissions.can_read === false
    //           )
    //           ?.map(([moduleName, permissions]) => moduleName);

    //         filteredModules?.forEach(
    //           (moduleName) => delete permissionResult[moduleName]
    //         );

    //         if (permissionResult) {
    //           if (result.success) {
    //             const filteredModules = result?.result?.filter((module) => {
    //               const permissionKey = module.name.replace(/ /g, "_");
    //               return permissionResult.hasOwnProperty(permissionKey);
    //             });

    //             if (filteredModules !== "No Data Found") {
    //               filteredModules?.forEach((element) => {
    //                 if (element.parent_module == null) {
    //                   let subModule = [];
    //                   filteredModules?.forEach((res) => {
    //                     if (res.parent_module === element.id) {
    //                       subModule.push(res);
    //                     }
    //                     element.subModule = subModule;
    //                   });
    //                 }
    //               });
    //               setModuleRecords(filteredModules);
    //             } else {
    //               setModuleRecords([]);
    //             }
    //           } else {
    //             return toast.error(result.message);
    //           }
    //         }
    //       }
    //     } else {
    //       if (result !== "No Data Found") {
    //         result?.result?.forEach((element) => {
    //           if (element.parent_module == null) {
    //             let subModule = [];
    //             result?.result?.forEach((res) => {
    //               if (res.parent_module === element.id) {
    //                 subModule.push(res);
    //               }
    //               element.subModule = subModule;
    //             });
    //           }
    //         });
    //         setModuleRecords(result);
    //       } else {
    //         setModuleRecords([]);
    //       }
    //     }
    //   }
    // } catch (error) {
    //   console.log("SERVER-ERROR-->>", error);
    // }
  }

  const toggleSidebar = (moduleId) => {
    setSidebar(!sidebar);
  };

  const handleModuleClick = (module) => {
    if (module.subModule && module.subModule.length === 0) {
      setOpenModule(openModule === module.name ? null : module.name);
      navigate(module.url);
    }
    setActiveModule(module.name);
    if (module.subModule && module.subModule.length > 0) {
      setOpenModule(openModule === module.name ? null : module.name);
    }
  };
    
  const accordinglyUserRoleModulesList = (userInfo?.userrole !== "SYS_ADMIN") ? moduleRecords : moduleRecords.result;
  
  return (
    <nav id="sidebar" className="sidebar card py-2 mb-4">
      <div
        className="sidebar-header text-center"
        style={{ paddingLeft: "0", paddingRight: "0", paddingTop: "10px" }}
      >
        <div className="pb-3 sidebar-img">
          <img
            src="/ibirds-sidebar-logo.png"
            style={{ width: "170px", height: "170px" }}
            alt=""
            title="Sanskriti The School"
            onClick={() => {
              navigate("/");
            }}
          />
        </div>
      </div>

      <div className="sidebar-list mt-2">
        <ul
          className="nav flex-column list-unstyled components mb-4"
          id="tabs_navigation"
          style={{ fontWeight: "bold" }}
        >
          {accordinglyUserRoleModulesList?.map(
            (module) =>
              module &&
              module.parent_module === null &&
              module.status !== "Inactive" && (
                <li
                  key={module.name}
                  className={activeModule === module.name ? "active " : ""}
                >
                  <a onClick={() => handleModuleClick(module)}>
                    <i className={module.icon} aria-hidden="true"></i>
                    {module.name}
                    <i
                      className={
                        module.subModule.length > 0
                          ? `fas fa-angle-${
                              openModule === module.name ? "down" : "right"
                            } float-end`
                          : ""
                      }
                      aria-hidden="true"
                    ></i>
                  </a>

                  {module.subModule && module.subModule.length > 0 && (
                    <ul
                      className={`collapse list-unstyled ${
                        openModule === module.name ? "show" : ""
                      }`}
                    >
                      {module.subModule.map(
                        (subModule) =>
                          subModule.status !== "Inactive" && (
                            <li
                              key={subModule.name}
                              className={`${
                                location.pathname.includes(subModule.url)
                                  ? "active"
                                  : ""
                              }`}
                            >
                              <Link
                                to={subModule.url}
                                onClick={() =>
                                  toggleSidebar(
                                    `${module.name.replace(/\s+/g, "_")}menu`
                                  )
                                }
                              >
                                <div style={{ marginLeft: "20px" }}>
                                  <i className={subModule.icon}></i>
                                  {subModule.name}
                                </div>
                              </Link>
                            </li>
                          )
                      )}
                    </ul>
                  )}
                </li>
              )
          )}
        </ul>
        <div className="sidefooter"></div>
      </div>
    </nav>
  );
};

export default Sidebar;


// import React, { useState, useEffect } from "react";
// import { Link, useLocation, useNavigate } from "react-router-dom";
// import jwt_decode from "jwt-decode";
// import "../template/custom.css";
// import schoolApi from "../../../api/schoolApi";
// import authApi from "../../../api/authApi";
// import { toast } from "react-toastify";

// const Sidebar = ({ moduleRecords, setModuleRecords }) => {
//   const [sidebar, setSidebar] = useState(true);
//   const [userInfo, setUserInfo] = useState({});
//   const [permissions, setPermissions] = useState();
//   const [activeModule, setActiveModule] = useState(null);
//   const [openModule, setOpenModule] = useState(null);
//   const location = useLocation();
//   const navigate = useNavigate();

//   useEffect(() => {
//     try {
//       if (sessionStorage.getItem("permissiontoken")) {
//         const user = jwt_decode(sessionStorage.getItem("permissiontoken"));
//         setUserInfo(user);

//         const perm = user.permissions.map((obj) => obj.name).join(";");
//         setPermissions(perm);
//       }
//       fetchAllModules();
//     } catch (error) {
//       console.error(error);
//     }
//   }, []);

//   async function fetchAllModules() {
//     try {
//       const token = sessionStorage.getItem("token");
//       if (authApi?.companyDetail()?.companyid) {
//         const result = await schoolApi.getAllModules(
//           await authApi.companyDetail()?.companyid
//         );

//         const user = jwt_decode(token);

//         if (user.userrole !== "ADMIN" && user.userrole !== "SYS_ADMIN") {
//           if (user?.companyid) {
//             const permissionResult = await schoolApi.getRolePermissionByIds();
//             console.log("permissionResult---->>>>>", permissionResult);

//             const filteredModules = Object.entries(permissionResult)
//               ?.filter(
//                 ([moduleName, permissions]) => permissions.can_read === false
//               )
//               ?.map(([moduleName, permissions]) => moduleName);

//             filteredModules?.forEach(
//               (moduleName) => delete permissionResult[moduleName]
//             );

//             if (permissionResult) {
//               if (result.success) {
//                 const filteredModules = result?.result?.filter((module) => {
//                   const permissionKey = module.name.replace(/ /g, "_");
//                   return permissionResult.hasOwnProperty(permissionKey);
//                 });

//                 if (filteredModules !== "No Data Found") {
//                   filteredModules?.forEach((element) => {
//                     if (element.parent_module == null) {
//                       let subModule = [];
//                       filteredModules?.forEach((res) => {
//                         if (res.parent_module === element.id) {
//                           subModule.push(res);
//                         }
//                         element.subModule = subModule;
//                       });
//                     }
//                   });
//                   setModuleRecords(filteredModules);
//                 } else {
//                   setModuleRecords([]);
//                 }
//               } else {
//                 return toast.error(result.message);
//               }
//             }
//           }
//         } else {
//           if (result !== "No Data Found") {
//             result?.result?.forEach((element) => {
//               if (element.parent_module == null) {
//                 let subModule = [];
//                 result?.result?.forEach((res) => {
//                   if (res.parent_module === element.id) {
//                     subModule.push(res);
//                   }
//                   element.subModule = subModule;
//                 });
//               }
//             });
//             setModuleRecords(result);
//           } else {
//             setModuleRecords([]);
//           }
//         }
//       }
//     } catch (error) {
//       console.log("SERVER-ERROR-->>", error);
//     }
//   }

//   const toggleSidebar = (moduleId) => {
//     setSidebar(!sidebar);
//   };

//   const handleModuleClick = (module) => {
//     if (module.subModule && module.subModule.length === 0) {
//       setOpenModule(openModule === module.name ? null : module.name);
//       navigate(module.url);
//     }
//     setActiveModule(module.name);
//     if (module.subModule && module.subModule.length > 0) {
//       setOpenModule(openModule === module.name ? null : module.name);
//     }
//   };

//   return (
//     <nav id="sidebar" className="sidebar card py-2 mb-4">
//       <div
//         className="sidebar-header text-center"
//         style={{ paddingLeft: "0", paddingRight: "0", paddingTop: "10px" }}
//       >
//         <div className="pb-3 sidebar-img">
//           <img
//             src="/ibirds-sidebar-logo.png"
//             style={{ width: "170px", height: "170px" }}
//             alt=""
//             title="Sanskriti The School"
//             onClick={() => {
//               navigate("/");
//             }}
//           />
//         </div>
//       </div>

//       <div className="sidebar-list mt-2">
//         <ul
//           className="nav flex-column list-unstyled components mb-4"
//           id="tabs_navigation"
//           style={{ fontWeight: "bold" }}
//         >
//           {moduleRecords?.result?.map(
//             (module) =>
//               module &&
//               module.parent_module === null &&
//               module.status !== "Inactive" && (
//                 <li
//                   key={module.name}
//                   className={activeModule === module.name ? "active " : ""}
//                 >
//                   <a onClick={() => handleModuleClick(module)}>
//                     <i className={module.icon} aria-hidden="true"></i>
//                     {module.name}
//                     <i
//                       className={
//                         module.subModule.length > 0
//                           ? `fas fa-angle-${
//                               openModule === module.name ? "down" : "right"
//                             } float-end`
//                           : ""
//                       }
//                       aria-hidden="true"
//                     ></i>
//                   </a>

//                   {module.subModule && module.subModule.length > 0 && (
//                     <ul
//                       className={`collapse list-unstyled ${
//                         openModule === module.name ? "show" : ""
//                       }`}
//                     >
//                       {module.subModule.map(
//                         (subModule) =>
//                           subModule.status !== "Inactive" && (
//                             <li
//                               key={subModule.name}
//                               className={`${
//                                 location.pathname.includes(subModule.url)
//                                   ? "active"
//                                   : ""
//                               }`}
//                             >
//                               <Link
//                                 to={subModule.url}
//                                 onClick={() =>
//                                   toggleSidebar(
//                                     `${module.name.replace(/\s+/g, "_")}menu`
//                                   )
//                                 }
//                               >
//                                 <div style={{ marginLeft: "20px" }}>
//                                   <i className={subModule.icon}></i>
//                                   {subModule.name}
//                                 </div>
//                               </Link>
//                             </li>
//                           )
//                       )}
//                     </ul>
//                   )}
//                 </li>
//               )
//           )}
//         </ul>
//         <div className="sidefooter"></div>
//       </div>
//     </nav>
//   );
// };

// export default Sidebar;
