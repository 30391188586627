import React from 'react';
import { Navigate } from 'react-router-dom';
import { usePermissions } from './contexts/PermissionContext';

const ProtectedRoute = ({ element, permissionKey, permission, can  }) => {

  console.log('permissionKey-->>' , permissionKey);
  
    const { rolePermissions, loading, userInfo } = usePermissions();
    console.log('role-permission--->>>' , rolePermissions);
   
    if (loading || !userInfo) {
      return <div>Loading...</div>;
    }
  
    const userRole = userInfo.userrole;
  
    if (userRole === 'SYS_ADMIN' || userRole === 'ADMIN') {
      return element;
    }
  
    if (rolePermissions && rolePermissions.result[permissionKey] && can ==="read" && rolePermissions.result[permissionKey].can_read ) {
      return element;
    }

    if (rolePermissions && rolePermissions.result[permissionKey] && can ==="create" && rolePermissions.result[permissionKey].can_create ) {
      return element;
    }

    if (rolePermissions && rolePermissions.result[permissionKey] && can ==="edit" && rolePermissions.result[permissionKey].can_edit ) {
      return element;
    }

    return <Navigate to="/warning" />;
  };
  
  export default ProtectedRoute;

// ------------ code 19-nov-24 sarfraj----------

// import React from 'react';
// import { Navigate } from 'react-router-dom';
// import { usePermissions } from './contexts/PermissionContext';

// const ProtectedRoute = ({ element, permissionKey, permission, can  }) => {

//     const { rolePermissions, loading, userInfo } = usePermissions();
   
  
//     if (loading || !userInfo) {
//       return <div>Loading...</div>;
//     }
  
//     const userRole = userInfo.userrole;
  
//     if (userRole === 'SYS_ADMIN' || userRole === 'ADMIN') {
//       return element;
//     }
  
//     if (rolePermissions && rolePermissions[permissionKey] && can ==="read" && rolePermissions[permissionKey].can_read ) {
//       return element;
//     }

//     if (rolePermissions && rolePermissions[permissionKey] && can ==="create" && rolePermissions[permissionKey].can_create ) {
//       return element;
//     }

//     if (rolePermissions && rolePermissions[permissionKey] && can ==="edit" && rolePermissions[permissionKey].can_edit ) {
//       return element;
//     }


  
//     return <Navigate to="/warning" />;
//   };
  
//   export default ProtectedRoute;
