import React, { useEffect, useState } from "react";
import { Button, Col, Modal, Row, Form } from "react-bootstrap";
import schoolApi from "../../api/schoolApi";
import PubSub from "pubsub-js";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";
import "../../App.css";
// Add by Aamir khan 10-10-2024
import { 
  BtnBold, 
  BtnItalic, 
  createButton, 
  Editor, 
  EditorProvider, 
  Toolbar
} from 'react-simple-wysiwyg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faListUl, faListOl, faAlignLeft, faAlignRight, faAlignCenter } from '@fortawesome/free-solid-svg-icons';

const CreateSyllabusModel = (props) => {
  console.log("props-on create syllabus-======>", props);
  const navigate = useNavigate();
  const [classOptions, setClassOptions] = useState([]);
  const [subjectOptions, setSubjectOptions] = useState([]);
  const [sectionOptions, setSectionOptions] = useState([]);
  const [sessionOptions, setSessionOptions] = useState([]);
  const [syllabusData, setSyllabusData] = useState({
    class_id: "",
    section_id: "",
    subject_id: "",
    session_id: "",
    isactive: "",
    description: "",
  });

  
  //Add by Aamir khan
  const BtnAlignCenter =  createButton('Align center',<FontAwesomeIcon icon={faAlignCenter} />, 'justifyCenter');
  const BtnAlignLeft   =  createButton('Align left',<FontAwesomeIcon icon={faAlignLeft} />, 'justifyLeft');
  const BtnAlignRight  =  createButton('Align right',<FontAwesomeIcon icon={faAlignRight}/>, 'justifyRight');
  const BtnUl          =  createButton('Unordered List',<FontAwesomeIcon icon={faListUl} />, 'insertUnorderedList');
  const BtnLi          =  createButton('Ordered List',<FontAwesomeIcon icon={faListOl} />, 'insertOrderedList');


  // console.log('AmirNew@#activeRecords==>',activeRecords);
  useEffect(() => {
    async function fetchAllClasses() {
      try {
        const result = await schoolApi.getActiveClassRecords();          // Add by Aamir khan 15-05-2024
        console.log('@@#classes==>',result);
        let ar = [];
        result?.result?.map((item) => {
          console.log("@#@##itemRecord==>",item);
          try{
              var obj = {};
              obj.value = item.id;
              obj.label = item.class_name;
              ar.push(obj);
          }
          catch (e){
            console.log('Error Pring',e);
            console.log('Print this Error');
          }
          console.log('ArrayPush==>',ar);
        });
        
        setClassOptions(ar);
      } catch (error) {
        console.error("Error fetching classes:", error);
      }
    }
    fetchAllClasses();
  }, []);

  useEffect(() => {
    async function fetchAllSubjects() {
      try {
        const subjects = await schoolApi.fetchSubject();
        console.log("@#@subjectsSubjects==>", subjects);
          if(subjects.success){
            const subjectOptions = subjects.result.map((sub) => ({
              value: sub.id,
              label: sub.name,
            }));        
          setSubjectOptions(subjectOptions);
        }
        else{
          setSubjectOptions([]);

        }

      } catch (error) {
        console.error("Error fetching subjects:", error);
      }
    }
    fetchAllSubjects();
  }, []);

  useEffect(() => {
    async function fetchAllSessions() {
      try {
        const sessions = await schoolApi.fetchSessions();
        const sessionOptions = sessions.map((sess) => ({
          value: sess.id,
          label: sess.year,
        }));
        setSessionOptions(sessionOptions);
      } catch (error) {
        console.error("Error fetching subjects:", error);
      }
    }
    fetchAllSessions();
  }, []);

  useEffect(() => {
    console.log("props new data == ",props.syllabusData)
    async function fetchData() {
    if (props.syllabusData) {
      setSyllabusData({
        class_id: props.syllabusData.class_id,
        section_id: props.syllabusData.section_id,
        subject_id: props.syllabusData.subject_id,
        session_id: props.syllabusData.session_id,
        isactive: props.syllabusData.isactive,
        description: props.syllabusData.description,
      });
    console.log("props class id new data == ",props.syllabusData.class_id)

   if(props.syllabusData.class_id){
        const result = await schoolApi.getSectionRecordById(props.syllabusData.class_id);
  
        console.log('result fech class section ',result)
        let ar = [];
        result.success && result?.record?.map((item) => {
          console.log("@@##itemRecord==>", item);
            if (item.isactive != null && item.isactive == true) {
            if (item.class_id === props.syllabusData.class_id) {
              var obj = {};
              obj.value = item.id;
              obj.label = item.name;
              console.log('@#obj==>',obj);
              ar.push(obj);
            }
            else {
              var obj = {};
              obj.value = "";
              obj.label = "";
              ar.push(obj);
            }
          }
        });
        setSectionOptions(ar)
      }
    } else {
      setSyllabusData({
        class_id: "",
        section_id: "",
        subject_id: "",
        session_id: "",
        isactive: "",
        description: "",
      });
      setSectionOptions([])
    }
  }
  fetchData();
  }, [props.syllabusData]);  
  
  const handleChange = async (e) => {

    console.log("Class Id == ",e.target.value)
    setSyllabusData({ ...syllabusData, [e.target.name]: e.target.value });

    if (e.target.name === "class_id") {
      const result = await schoolApi.getSectionRecordById(e.target.value);

      console.log('result fech class section ',result)
      let ar = [];
      result && result?.record?.map((item) => {
        console.log("@@##itemRecord==>", item);
          if (item.isactive != null && item.isactive == true) {
          if (item.class_id === e.target.value) {
            var obj = {};
            obj.value = item.id;
            obj.label = item.name;
            console.log('@#obj==>',obj);
            ar.push(obj);
          }
          else {
            var obj = {};
            obj.value = "";
            obj.label = "No Records Found!!";
            ar.push(obj);
          }
        }
      });
      setSectionOptions(ar)
      // setActiveRecords(ar);
    }
  };

  const handleSubmit = async () => {
    console.log('syllabusData',syllabusData);
    if (
      syllabusData.class_id &&
      syllabusData.section_id &&
      syllabusData.subject_id &&
      syllabusData.session_id
    ) {
      console.log('if')
      if (!syllabusData.isactive) {
        return toast.error("Please select status");
          
      }
      if (props.syllabusData) {
        try {
          const result = await schoolApi.updateSyllabus(
            props.syllabusData.id,
            syllabusData
          );
          if (result) {
            toast.success('Syllabus update successfully',);
                          props.handleCloseModal();
              props.fetchAllSyllabus();
              navigate('/syllabuslist');
              setSyllabusData({
                class_id: "",
                section_id: "",
                subject_id: "",
                session_id: "",
                isactive: "",
                description: "",
              });
          }
        } catch (error) {
          console.error("Error updating assignment:", error);
        }
      } else {
      
        try {
          console.log('EntersyllabusData',syllabusData);
          const result = await schoolApi.createSyllabus(syllabusData);

          console.log('@#result===>',result);
          if (result.success !== false) {
            toast.success('Syllabus create successfully');
              props.handleCloseModal();
              props.fetchAllSyllabus();
              setSyllabusData({
                class_id: "",
                section_id: "",
                subject_id: "",
                session_id: "",
                isactive: "",
                description: "",
              });
          }
          else{
            toast.error('Record Is Already Exist');
          }
        } catch (error) {
          console.log('ErrorRecord==>',error);
          console.log('printError==>',error);
          console.error("Error creating assignment:",error);
        }
      }
    } else {
      console.log('else')
      toast.error("Please fill all the required fields.");
      return;
    }
  };

  return (
    <Modal
      show={props.modalShow}
      centered
      backdrop="static"
      aria-labelledby="contained-modal-title-vcenter"
      onHide={() => {
        console.log("onHide == ",props)
        console.log("handleCloseModal")
        props.handleCloseModal();
        // setSectionOptions([])
        setSyllabusData({
          class_id: props.syllabusData?.class_id
            ? props.syllabusData?.class_id
            : "",
          section_id: props.syllabusData?.section_id
            ? props.syllabusData?.section_id
            : "",
          subject_id: props.syllabusData?.subject_id
            ? props.syllabusData?.subject_id
            : "",
          session_id: props.syllabusData?.session_id
            ? props.syllabusData?.session_id
            : "",
          isactive: props.syllabusData?.isactive
            ? props.syllabusData?.isactive
            : "",
          description: props.syllabusData?.description
            ? props.syllabusData?.description
            : "",
        });
      }}
    >
      <Modal.Header
        closeButton
        style={{
          // width: "20",
          maxHeight: "",
        }}
      >
        <Modal.Title>
          {props.syllabusData ? "Update Syllabus" : "Create Syllabus"}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form className="mt-3">
          <Row>
            <Col lg={6}>
              <Form.Group className="mx-3">
                <Form.Label className="form-view-label">Class</Form.Label>
                <Form.Select
                  name="class_id"
                  onChange={handleChange}
                  value={syllabusData.class_id}
                  required
                >
                  <option key="default" value="">
                    -- Select Class --
                  </option>
                  {classOptions &&
                    classOptions.map((res) => (
                      <option key={res.value} value={res.value}>
                        {res.label}
                      </option>
                    ))}
                </Form.Select>
              </Form.Group>
            </Col>
            <Col lg={6}>
              <Form.Group className="mx-3">
                <Form.Label className="form-view-label">Subject</Form.Label>
                <Form.Select
                  name="subject_id"
                  onChange={handleChange}
                  value={syllabusData.subject_id}
                  required
                >
                  <option key="default" value="">
                    -- Select Subject --
                  </option>
                  {subjectOptions &&
                    subjectOptions.map((sub) => (
                      <option key={sub.value} value={sub.value}>
                        {sub.label}
                      </option>
                    ))}
                </Form.Select>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col lg={6} className="mt-3">
              <Form.Group className="mx-3">
                <Form.Label className="form-view-label">Section</Form.Label>
                <Form.Select
                  name="section_id"
                  onChange={handleChange}
                  value={syllabusData.section_id}
                  required
                >
                  <option value="">-- Select Section --</option>

                    {sectionOptions &&
                    sectionOptions.map((res) => (
                      <option key={res.label} value={res.value}>
                        {res.label}
                      </option>
                    ))}
                </Form.Select>
              </Form.Group>
            </Col>
            <Col lg={6} className="mt-3">
              <Form.Group className="mx-3">
                <Form.Label className="form-view-label">Session</Form.Label>
                <Form.Select
                  name="session_id"
                  onChange={handleChange}
                  value={syllabusData.session_id}
                  required
                >
                  <option value="">-- Select Session --</option>
                  {sessionOptions &&
                    sessionOptions.map((res) => (
                      <option key={res.label} value={res.value}>
                        {res.label}
                      </option>
                    ))}
                </Form.Select>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col lg={6} className="mt-3">
              <Form.Group className="mx-3">
                <Form.Label>Status</Form.Label>
                <Form.Select
                        required
                        name="isactive"
                        value={syllabusData.isactive}
                        onChange={handleChange}
                      >
                        <option value="">-- Select Status --</option>
                        <option value="Active">Active</option>
                        <option value="InActive">InActive</option>
                      </Form.Select>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col lg={12} className="mt-3">
            <EditorProvider  className="resizable-editor">
              <Editor name="description"
               placeholder="Enter description"
               onChange={handleChange}
                value={syllabusData.description} 
                >
                <Toolbar>
                  <BtnBold />
                  <BtnItalic />
                  <BtnAlignLeft />
                  <BtnAlignCenter />
                  <BtnAlignRight />
                  <BtnLi/>
                  <BtnUl/>
                </Toolbar>
              </Editor>
            </EditorProvider>
            </Col>
          </Row>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="success" onClick={handleSubmit}>
          {props.btnName}
        </Button>
        <Button
          variant="light"
          onClick={() => {
            props.handleCloseModal();
            setSyllabusData({
              class_id: props.syllabusData?.class_id
                ? props.syllabusData?.class_id
                : "",
              section_id: props.syllabusData?.section_id
                ? props.syllabusData?.section_id
                : "",
              subject_id: props.syllabusData?.subject_id
                ? props.syllabusData?.subject_id
                : "",
              session_id: props.syllabusData?.session_id
                ? props.syllabusData?.session_id
                : "",
              isactive: props.syllabusData?.isactive
                ? props.syllabusData?.isactive
                : "",
              description: props.syllabusData?.description
                ? props.syllabusData?.description
                : "",
            });
          }}
        >
          Close
        </Button>
      </Modal.Footer>
          </Modal>
  );
};

export default CreateSyllabusModel;