import React, { useEffect, useState } from "react";
import { Button, Col, Form, Modal, Row, Table } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import schoolApi from "../../api/schoolApi";
import moment from "moment";
import InfoPill from "../InfoPill";
import {
  DatatableWrapper,
  Filter,
  Pagination,
  PaginationOptions,
  TableBody,
  TableHeader,
} from "react-bs-datatable";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import Confirm from "../Confirm";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { usePermissions } from "../contexts/PermissionContext";
const ExamList = (props) => {
  const { rolePermissions, userInfo } = usePermissions();
  const navigate = useNavigate();
  const [body, setBody] = useState([]);
  const [sessionYear, setSessionYear] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [reload, setReload] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const [rowRecords, setRowRecords] = useState({});
  const [updateStatus, setUpdateStatus] = useState(false);
  const [isFinal, setIsFinal] = useState(false);
  const [newTitle, setNewTitle] = useState({
    status: "Active",
  });
  const [error, setError] = useState(null);

  const canCreate = rolePermissions?.Exam_List?.can_create;
  const canEdit = rolePermissions?.Exam_List?.can_edit;
  const canDelete = rolePermissions?.Exam_List?.can_delete;

  useEffect(() => {
    async function init() {
      try {
        const result = await schoolApi.fetchExamTitles();
        console.log('###exam list', result);

        if (result.success) {
          console.log("WOKRIEWIE")
          console.log("object", result?.records)
          setBody(result?.records);
        } else {
          setBody([]);
        }
      } catch (err) {
        console.error("Error fetching exam titles:", err);
        setBody([]);
      }
    }
    init();
  }, [reload]);

  useEffect(() => {
    async function getSessions() {
      try {
        const sessions = await schoolApi.fetchSessions();
        console.log("SESIONS", sessions)
        if (sessions) {
          setSessionYear(sessions);
        } else {
          setSessionYear([]);
        }
      } catch (err) {
        console.error("Error fetching sessions:", err);
        toast.error("An error occurred while fetching sessions.");
        setSessionYear([]);
      }
    }
    getSessions();
  }, [showModal]);

  const header = [
    {
      title: "Exam Title",
      prop: "name",
      isFilterable: true,
      show: true,
    },
    {
      title: "Final Result",
      prop: "isfinal",
      isFilterable: true,
      show: true,
      cell: (row) => (
        <Form.Check type="checkbox" disabled checked={row.isfinal} />
      ),
    },
    {
      title: "Status",
      prop: "status",
      isFilterable: true,
      show: true,
      cell: (row) => (
        <Button
          className="btn-sm mx-2"
          style={{ width: "80px" }}
          variant={row.status === "Active" ? "success" : "danger"}
          onClick={() => toggleStatus(row)}
        >
          {row.status}
        </Button>
      ),
    },
    {
      title: "Session",
      prop: "session",
      isFilterable: true,
      show: true,
    },
    {
      title: "Action",
      prop: "",
      isFilterable: true,
      show: true,
      cell: (row) => (
        <div>
          {(userInfo?.userrole === "SYS_ADMIN" ||
            userInfo?.userrole === "ADMIN" ||
            canEdit) && (
              <Button
                className="btn btn-sm edit-button mx-2"
                onClick={() => handleEditButton(row)}
              >
                <i className="fa-regular fa-pen-to-square"></i>
              </Button>
            )}
          {(userInfo?.userrole === "SYS_ADMIN" ||
            userInfo?.userrole === "ADMIN" ||
            canDelete) && (
              <button
                className="btn btn-sm btn-danger mx-2"
                onClick={() => handleDeleteButton(row)}
              >
                <i className="fa fa-trash"></i>

              </button>
            )}
        </div>
      ),
    },
  ];

  const visibleHeader = header.filter((col) => col.show);


  const toggleStatus = (row) => {
    if (
      canEdit ||
      userInfo?.userrole === "SYS_ADMIN" ||
      userInfo?.userrole === "ADMIN"
    ) {
      setUpdateStatus(true);
      setRowRecords(row);
    }
  };

  const handleEditButton = (row) => {
    setNewTitle(row);
    setIsEdit(true);
    setShowModal(true);
    setDeleteModal(false);
  };

  const handleDeleteButton = (row) => {
    setDeleteId(row.id);
    setDeleteModal(true);
  };
  const deleteTitle = async () => {
    try {
      const result = await schoolApi.deleteTitle(deleteId);
      if (result.success) {
        toast.success("Record deleted successfully.");
        setReload(true);
        setDeleteModal(false);
        setNewTitle({});
      }
    } catch (error) {
      return toast.error(error.response.data.message);
    }
  };


  const changeStatus = async () => {
    try {
      rowRecords.status =
        rowRecords.status === "Active" ? "InActive" : "Active";
      const result = await schoolApi.updateExamTitle(rowRecords.id, rowRecords);
      if (result) {
        toast.success("Record updated successfully.");
        setUpdateStatus(false);
        setReload(true);
      } else {
        toast.error("Failed to update record.");
      }
    } catch (error) {
      console.error("Error updating status:", error);
      toast.error("An error occurred while updating the record.");
    }
  };

  const handleChange = (e) => {
    const { name, value, selectedIndex } = e.target;
    if (name === "sessionid") {
      console.log("sessionId ->", name);
      console.log("sessionId ->", value);
      console.log("sessionYear ->", sessionYear);

      const sessionId =
        value && sessionYear.length > 0 && selectedIndex > 0
          ? sessionYear[selectedIndex - 1].id
          : "";
      setNewTitle({
        ...newTitle,
        sessionid: sessionId,
        session: value || "",
      });
    } else {
      setNewTitle({
        ...newTitle,
        [name]: value,
      });
    }
  };
  const handleSaveNewTitle = async () => {
    if (!newTitle.name || !newTitle.status || !newTitle.sessionid) {
      toast.error("Missing required fields");
      return;
    }

    try {
      let result;
      if (newTitle.id) {
        result = await schoolApi.updateExamTitle(newTitle.id, { ...newTitle });
        if (result.success) {
          toast.success("Record updated successfully.");
          setBody((prev) =>
            prev.map((item) => (item.id === newTitle.id ? { ...newTitle } : item))
          );
        } else {
          toast.error("Failed to update record.");
        }
      } else {
        result = await schoolApi.createExamTitle({ ...newTitle });
        if (result.success) {
          toast.success("Record saved successfully.");
          setBody((prev) => [...prev, { ...result.record }]); // Add the new record to the body
        } else {
          toast.error("Duplicate Record or failed to save.");
        }
      }
      setReload((prev) => !prev);
      setShowModal(false);
      setNewTitle({ status: "Active" });
    } catch (error) {
      console.error("Error saving title:", error);
      toast.error("An error occurred while saving the record.");
    }
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setNewTitle({});
  };

  const handleCheckboxChange = (event) => {
    setIsFinal(event.target.checked);
    setNewTitle({ ...newTitle, [event.target.name]: event.target.checked });
  };
  console.log("newTitle ->", newTitle);

  const labels = {
    beforeSelect: " ",
  };

  return (
    <>
      <Helmet>
        {" "}
        <title>{props?.tabName}</title>{" "}
      </Helmet>
      <Row className="g-0">
        <Col lg={2} className="mx-3">
          <Link className="nav-link mx-2" to="/">
            Home <i className="fa-solid fa-chevron-right"></i> ExamList
          </Link>
        </Col>
        <ToastContainer
          position="top-right"
          autoClose={2000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
        />

        {deleteModal && (
          <Confirm
            show={deleteModal}
            onHide={() => setDeleteModal(false)}
            deleteTitle={deleteTitle}
            title="Confirm delete?"
            message="You are going to delete the record. Are you sure?"
            table="exam_title"
          />
        )}

        {updateStatus && (
          <Confirm
            show={updateStatus}
            onHide={() => setUpdateStatus(false)}
            changeStatus={changeStatus}
            title={`Confirm ${rowRecords?.status === "Active" ? "InActive" : "Active"
              }?`}
            message="You are going to update the status. Are you sure?"
            table="exam_title_status_update"
          />
        )}
        <Col lg={12} className="p-lg-4">
          <DatatableWrapper
            body={body}
            headers={visibleHeader}
            paginationOptionsProps={{
              initialState: {
                rowsPerPage: 15,
                options: [5, 10, 15, 20],
              },
            }}
          >
            <Row className="mb-4">
              <Col lg={3}>
                <Filter />
              </Col>
              <Col lg={2} style={{ marginTop: "-18px" }}>
              <PaginationOptions labels={labels} />
              </Col>
              <Col lg={3} style={{ marginTop: "-13px" }}>
                <div>
                  <InfoPill left="Total Exams" right={body?.length} />
                </div>
              </Col>
              <Col lg={4} style={{ marginTop: "2px" }} className="d-flex flex-col justify-content-end align-items-end">
                {(userInfo?.userrole === "SYS_ADMIN" || userInfo?.userrole === "ADMIN" || canCreate) && (
                  <Button
                    className="btn-light add-new-button"
                    variant=""
                    onClick={() => setShowModal(true)}
                  >
                    New Exam
                  </Button>
                )}
              </Col>
            </Row>
            <Table striped className="data-table">
              <TableHeader />
              {body.length > 0 ? (
                <TableBody />
              ) : (
                <tbody>
                  <tr>
                    <td className="text-left">
                      <span>No results to be shown.</span>
                    </td>
                  </tr>
                </tbody>
              )}
            </Table>
            <Pagination />
          </DatatableWrapper>
        </Col>

        <Col lg={2}></Col>
      </Row>

      <Modal
        show={showModal}
        backdrop="static"
        centered
        aria-labelledby="contained-modal-title-vcenter"
        onHide={handleCloseModal}
      >
        <Modal.Header centered closeButton>
          <Modal.Title>New Exam Title</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form className="mt-3">
            <Row>
              <Col lg={6}>
                <Form.Group className="mx-3">
                  <Form.Label
                    className="form-view-label"
                    htmlFor="formBasicFirstName"
                  >
                    Title Name
                  </Form.Label>
                  <Form.Control
                    required
                    type="text"
                    value={newTitle.name || ""}
                    name="name"
                    placeholder="--Enter Title Name--"
                    onChange={handleChange}
                  />
                </Form.Group>
              </Col>

              <Col lg={6}>
                <Form.Group className="mx-3">
                  <Form.Label
                    className="form-view-label"
                    htmlFor="formBasicFirstName"
                  >
                    Status
                  </Form.Label>
                  <Form.Select
                    required
                    name="status"
                    value={newTitle.status || ""}
                    onChange={handleChange}
                  >
                    <option value="">-- Select Status --</option>
                    <option value="Active">Active</option>
                    <option value="InActive">InActive</option>
                  </Form.Select>
                </Form.Group>
              </Col>

              <Col lg={6} className="mt-3">
                <Form.Group className="mx-3">
                  <Form.Label
                    className="form-view-label"
                    htmlFor="formBasicFirstName"
                  >
                    Session
                  </Form.Label>
                  <Form.Select
                    required
                    name="sessionid"
                    value={newTitle.session}
                    onChange={handleChange}
                  >
                    <option value="">-- Select Session --</option>
                    {sessionYear.map((session) => (
                      <option key={session.id} value={session.year}>
                        {session.year}
                      </option>
                    ))}
                  </Form.Select>
                </Form.Group>
              </Col>

              <Col lg={6} className="mt-3">
                <Form.Group className="mx-3">
                  <Form.Label
                    className="form-view-label"
                    htmlFor="formBasicIsFinal"
                  >
                    Final Result
                  </Form.Label>
                  <Form.Check
                    type="checkbox"
                    name="isfinal"
                    checked={newTitle.isfinal || false}
                    onChange={handleCheckboxChange}
                    id="formBasicIsFinal"
                  />
                </Form.Group>
              </Col>
            </Row>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            Close
          </Button>
          <Button variant="primary" onClick={handleSaveNewTitle}>
            Save
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ExamList;
