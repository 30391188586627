/**
 * @author: Mohmmad Sarfraj | 31-aug-2024
 */

import React, { useState, useEffect } from "react";
import { Button, Col, Form, Row, Table } from "react-bootstrap";
import { useNavigate , Link } from "react-router-dom";
import { usePermissions } from "../contexts/PermissionContext";
import schoolApi from "../../api/schoolApi";
import { DatatableWrapper, Filter, Pagination, PaginationOptions, TableBody, TableHeader } from 'react-bs-datatable';
import InfoPill from "../InfoPill";
import { ShimmerTable } from "react-shimmer-effects";
import { Helmet } from "react-helmet";

const DriverList = (props) => {
  const { rolePermissions, userInfo } = usePermissions();
  const navigate = useNavigate();
  const [body, setBody] = useState();

  //==========    Fatch All Driver's List    =============
  useEffect(() => {
    init();
  }, []);

  async function init() {
    try {
    const result = await schoolApi.fetchDrivers();
    if (result.success) {
      setBody(result.drivers);
    } else {
      setBody([]);
    }
  } catch (error) {
    setBody([]);
  }
  }

  const canCreate = rolePermissions?.Employee_List?.can_create;
  const canEdit = rolePermissions?.Employee_List?.can_edit;
  const canDelete = rolePermissions?.Employee_List?.can_delete;

  const createContact = () => {
    if (canEdit || userInfo?.userrole === "SYS_ADMIN" || userInfo?.userrole === "ADMIN") {
      navigate(`/driver/e`);
    }

  };

  const labels = {
    beforeSelect: " ",
  };

  const header = [
    {
      title: "Driver Name",
      prop: "staffname",
      isFilterable: true,
      show: true,
      cell: (row) => (
        <Link to={"/driver/" + row.id} state={row}>
          {row.staffname}
        </Link>
      ),
    },
    { title: "Street", prop: "street", show: true, isFilterable: true },
    { title: "City", prop: "city", show: true, isFilterable: true },
    { title: "Phone", prop: "phone", show: true, isFilterable: true },
    { title: "Email", prop: "email", show: true, isFilterable: true },
    { title: "Employee Type", prop: "recordtype", show: true, isFilterable: true },
  ];

  const visibleHeader = header?.filter(col => col.show);

  return (
    <>
      <Helmet><title>{props?.tabName}</title></Helmet>
      <Col lg={3} className="mx-3">
        <Link className="nav-link mx-2" to="/">Home <i className="fa-solid fa-chevron-right"></i> DriverList</Link>
      </Col>
      <Row className="g-0">
        <Col lg={12} className="p-lg-4">

          {body ?
            <DatatableWrapper body={body} headers={visibleHeader} paginationOptionsProps={{
              initialState: {
                rowsPerPage: 15,
                options: [5, 10, 15, 20]
              }
            }}>
              <Row className="mb-4">
                <Col lg={4}>
                  <Filter />
                </Col>
                <Col lg={2} style={{ 'margin-top': '-18px' }}>
                  <PaginationOptions labels={labels} />
                </Col>
                <Col lg={4} style={{ 'margin-top': '-13px' }} >
                  <div >
                    <InfoPill left="Total Drivers" right={body?.length} />
                  </div>
                </Col>
                <Col className="d-flex flex-col justify-content-end align-items-end">
                  {(userInfo?.userrole === "SYS_ADMIN" || userInfo?.userrole === "ADMIN" || canCreate) &&
                    <Button className="btn add-new-button" variant="" onClick={() => createContact(true)}>New Driver</Button>}
                </Col>  
              </Row>
              <Row>
                <Col>
                  <Table striped className="data-table">
                    <TableHeader />
                    <TableBody />
                  </Table>
                  <Pagination />
                </Col>
              </Row>
            </DatatableWrapper> : <ShimmerTable row={10} col={8} />}
        </Col>
        <Col lg={2}></Col>
      </Row>
    </>
  );
};

export default DriverList;