import React, { useState, useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import "react-bootstrap-typeahead/css/Typeahead.css";
import schoolApi from "../../api/schoolApi";
import moment from "moment";
import CityState from "../../constants/CityState.json";
import { format, parseISO } from "date-fns";
import { Helmet } from "react-helmet";
import PageNavigations from "../breadcrumbs/PageNavigations";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import $ from "jquery";
import "jquery-ui-dist/jquery-ui.css"; // jQuery UI CSS
import "jquery-ui-dist/jquery-ui"; // jQuery UI

const StaffEdit = (props) => {
  console.log("StaffEdit props", props);
  const [validated, setValidated] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const [contact, setContact] = useState({});
  const [cities, setCities] = useState([]);
  const [selectedState, setSelectedState] = useState(null);
  const [selectedCity, setSelectedCity] = useState(null);
  const [states, setStates] = useState([]);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [isValid, setIsValid] = useState(true);

  console.log("StaffEdit==>", cities);
  useEffect(() => {
    // Create an array of unique states
    const uniqueStates = Array.from(
      new Set(CityState.map((item) => item.state))
    ).map((state) => ({ label: state, value: state }));

    setStates(uniqueStates);
  }, [CityState]);

  /* START  Date work with React J-Query  (Date-Picker)  */
  $(document).ready(() => {
    // Get today's date
    const today = new Date();
    const day = String(today.getDate()).padStart(2, "0");
    const month = String(today.getMonth() + 1).padStart(2, "0");
    const year = today.getFullYear();
    const formattedDate = `${day}/${month}/${year}`;

    $("#emp_dob").datepicker({
      dateFormat: "dd/mm/yy",
      changeMonth: true,
      changeYear: true,
      yearRange: "1920:2024",
      defaultDate: formattedDate,
      onSelect: function (dateText) {
        handleDateChangeJquery(dateText, "dateofbirth");
      },
    });
  });

  const handleDateChangeJquery = (date, name) => {
    console.log("Selected date: ", date);
    const [day, month, year] = date.split("/");
    const formattedDate = `${year}-${month}-${day}`;

    setContact((contact) => ({ ...contact, dateofbirth: formattedDate }));
  };
  /*  END J-Query Date  */

  console.log("props?.selectedRecordType = ", props?.selectedRecordType);

  useEffect(() => {
    console.log("Location.state==>", location.state);
    if (location?.state) {
      
      $('#emp_dob').datepicker("setDate" , parseISO(location?.state?.dateofbirth)) // <<<--- set in date DOB field
      setContact(location?.state);
      
    } else {
      if (location.hasOwnProperty("pathname")) {
        if (props?.selectedRecordType === "driver") {
          setContact({
            ...contact,
            recordtype: "Driver",
            department: "driver",
          });
        }
      }
    }

    if (location?.state?.id) {
      console.log("Amirkhan");
      const selectedState = states.find(
        (state) => state.value === location.state.state
      );
      console.log("selectedState==>", selectedState);
      setSelectedState(selectedState);

      const filteredCities = CityState.filter(
        (item) => item.state === location.state.state
      ).map((city) => ({ label: city.name, value: city.name }));

      console.log("@#filteredCities==>", filteredCities);
      setCities(filteredCities);

      const selectedCity = filteredCities.find(
        (city) => city.value === location.state.city
      );

      setSelectedCity(selectedCity);
    } else {
      console.log("Else Part Run");
      setSelectedState(null);
      setSelectedCity(null);
      setCities([]);
    }
  }, [location, states]);

  console.log('hello-sarfraj--->>>' , contact);
  

  //Add By Aamir khan 03-05-2024
  const handleStateChange = (e) => {
    setSelectedState(e.target.value);
    setSelectedCity(null);
    setCities(
      CityState.filter((item) => item.state === e.target.value).map((city) => ({
        label: city.name,
        value: city.name,
      }))
    );
    console.log("@Cities==>", cities);
    setContact({ ...contact, state: e.target.value });
  };

  //Add by Aamir khan 07-05-2024
  const handleCityChange = (e) => {
    const selectedCity = e.target.value;
    console.log("1@#selectedCity==>", selectedCity);
    // (selectedCity);
    setContact({ ...contact, city: selectedCity });
  };

  const handleChange = (e) => {

    if(e.target.name === "adharnumber"){
      let value = e.target.value;
      const regex = /^\d{0,12}$/;

      if(regex.test(value)){
        console.log( "e.target.value ==>", e.target.value, " e.target.name ==> ", e.target.name);
        setContact((prevContact) => ({
          ...prevContact,
          [e.target.name]: e.target.value,
        }));
      } else if(value.length <= 10){
        setContact((prevContact) => ({
          ...prevContact,
          [e.target.name]: "",
        }));
      }
    }
    else {
      setContact((prevContact) => ({
        ...prevContact,
        [e.target.name]: e.target.value,
      }));
    }
  };

  console.log("contact field > ", contact);

  const handleSubmit = async (e) => {
    e.preventDefault();

    // chage date formate full date only date type()
    

    console.log("Employe Page" , contact);
    if (!contact.firstname || !contact.lastname || !contact.recordtype || !contact.phone) {
      toast.error("Missing required fields!!");
      return;
    }

    if (!contact.dateofbirth) {
      toast.error("Enter date of birth!!!");
      return;
    }

    if (contact.email) {
      let emailRegex = /[a-z0-9]+@[a-z]+\.[a-z]{2,3}/;
      if (!emailRegex.test(contact.email)) {
        toast.error("Enter valid Email!!!");
        return;
      }
    }

    console.log("contact.dateofbirth@@@@=>", contact.dateofbirth);
    const dob = new Date(contact.dateofbirth);

    if (isNaN(dob) || dob > new Date()) {
      toast.error("Enter a valid date of birth (in the past).");
      return;
    }

    if (contact.adharnumber) {
      if (contact.adharnumber.length !== 12) {
        toast.error("Enter valid aadhar number!!!");
        return;
      }
    }

    //Add by Aamir khan  03-05-2024
    if (contact.phone) {
      if (contact.phone.length !== 10) {
        toast.error("Enter ten digit phone number!!!");
        return;
      }
    }

    console.log("Contact record while save", contact);

    //========= Logic to perform Create or Edit ======
    let result2 = {};
    if (contact.id) {
      console.log("contact==>", contact);
      try {
        contact.dateofbirth = moment(contact.dateofbirth).format("YYYY-MM-DD");
        console.log("StaffEdit==>", contact);
        result2 = await schoolApi.saveContact(contact);
        console.log("result2==>", contact);

        if (result2.success) {
          toast.success("Record updated succesfully");
          setTimeout(() => {
            if (contact.recordtype === "Driver") {
              navigate(`/driver/${contact.id}`, { state: result2.contactRec });
            } else {
              navigate(`/staff/${contact.id}`, { state: result2.contactRec });
            }
          }, 2000);
        } else {
          toast.error(result2.message);
        }
      } catch (error) {
        if (error.response.data.message && error.response.data)
          toast.error(error.response.data.message);
        else toast.error("Something went wrong!!");
      }
    } else {
      console.log("contact create ", contact);
      try {
        result2 = await schoolApi.createContact(contact);
        console.log("Contact Result =>", result2);
        if (result2.success) {
          toast.success("Record created succesfully");
          setTimeout(() => {
            if (result2.contactRec.recordtype === "Driver") {
              navigate(`/driver/${result2.contactRec.id}`, {
                state: result2.contactRec,
              });
            } else {
              navigate(`/staff/${result2.contactRec.id}`, {
                state: result2.contactRec,
              });
            }
          }, 2000);
        } else {
          toast.error(result2.message);
        }
      } catch (error) {
        if (error.response.data.message && error.response.data)
          toast.error(error.response.data.message);
        else toast.error("Something went wrong!!");
      }
    }
    if (isValid) {
      // handle form submission logic for a valid phone number
      console.log("Phone number is valid:", phoneNumber);
    } else {
      // handle form submission logic for an invalid phone number
      console.log("Invalid phone number");
    }
  };

  const handleCancel = () => {
    console.log("location.state ->", location.state);

    if (location?.state?.id) {
      if (contact.recordtype === "Driver") {
        navigate("/driver/" + contact.id, { state: contact });
      } else {
        navigate("/staff/" + contact.id, { state: contact });
      }
    } else {
      if (location?.state?.recordtype === "Driver") {
        navigate("/driver");
      } else {
        navigate("/staffs");
      }
    }
  };

  const validatePhoneNumber = (phoneNumber) => {
    // regular expression to match a valid phone number
    const phoneRegex = /^[2-9]{1}[0-9]{9}$/;
    return phoneRegex.test(phoneNumber);
  };

  const handleInputChange = (e) => {
    const value = e.target.value;
    const regex = /^\d{0,10}$/;
    
    if(regex.test(value) && e.target.value !== "."){      
      setPhoneNumber(value);
      setIsValid(validatePhoneNumber(value));
      setContact((prevContact) => ({
        ...prevContact,
        [e.target.name]: e.target.value,
      }));
    } 
    else if(!regex.test(value) && value.length <= 10){
      setContact((prevContact) => ({
        ...prevContact,
        [e.target.name]: "",
      }));
    }
  };

  console.log("pawan-contact-->>", contact);
  return (
    <>
      <>
        <Helmet>
          {" "}
          <title>{props?.tabName}</title>{" "}
        </Helmet>
        <PageNavigations
          id={location.state?.id}
          listName={
            props?.selectedRecordType === "staff"
              ? "Create New Employee"
              : props?.selectedRecordType === "driver"
              ? "Create New Driver"
              : ""
          }
          listPath={
            location?.pathname?.split("/")[1] === "/staffs" ||
            location?.pathname?.split("/")[1] === "/driver"
              ? ""
              : "/staffs"
          }
          viewName={
            props?.selectedRecordType === "driver"
              ? "Staff View"
              : "Employee Edit"
          }
          viewPath={"/staff/" + location.state?.id}
          colLg={10}
          colClassName="d-flex px-3 py-2"
          extrColumn={2}
        />

        <Container className="view-form mb-5">
          <Row>
            <Col></Col>
            <Col lg={8}>
              <Form
                className="mt-3"
                onSubmit={handleSubmit}
                noValidate
                validated={validated}
              >
                <Row
                  className="view-form-header align-items-center"
                  style={{ marginLeft: "1.1%", marginRight: "1.1%" }}
                >
                  <Col lg={3}>Employee Details</Col>
                  <Col lg={9} className="d-flex justify-content-end">
                    <Button className="btn-sm mx-2" onClick={handleSubmit}>
                      Save
                    </Button>

                    <ToastContainer
                      position="top-right"
                      autoClose={2000}
                      hideProgressBar={false}
                      newestOnTop={false}
                      closeOnClick
                      rtl={false}
                      pauseOnFocusLoss
                      draggable
                      pauseOnHover
                      theme="light"
                      />

                    <Button
                      className="btn-sm"
                      variant="danger"
                      onClick={handleCancel}
                    >
                      Cancel
                    </Button>
                  </Col>
                </Row>
                <Row>
                  <Col lg={6}>
                    <Form.Group className="mx-3">
                      <Form.Label className="form-view-label">
                        Employee Type
                      </Form.Label>
                      <Form.Select
                        required
                        name="recordtype"
                        onChange={handleChange}
                        value={contact.recordtype}
                        disabled={location.pathname === "/driver/e"}
                      >
                        <option value="">--Select Employee Type--</option>
                        <option value="Principal">Principal</option>
                        <option value="Parent">Parent</option>
                        <option value="Teacher">Teacher</option>
                        <option value="Admin">Admin</option>
                        <option value="Librarian">Librarian</option>
                        <option value="Accountant">Accountant</option>
                        <option value="Driver">Driver</option>
                        <option value="Peon">Peon</option>
                        <option value="Security Guard">Security Guard</option>
                        <option value="PTI">PTI</option>
                      </Form.Select>
                    </Form.Group>
                  </Col>
                  <Col lg={6}>
                    <Form.Group className="mx-3">
                      <Form.Label className="form-view-label">
                        Salutation
                      </Form.Label>
                      <Form.Select
                        name="salutation"
                        onChange={handleChange}
                        value={contact.salutation}
                      >
                        <option value="">-- Select Salutation--</option>
                        <option value="Mr">Mr.</option>
                        <option value="Ms">Ms.</option>
                        <option value="Mrs">Mrs.</option>
                        <option value="Dr">Dr.</option>
                        <option value="Prof">Prof.</option>
                      </Form.Select>
                    </Form.Group>
                  </Col>
                  <Col lg={6}>
                    <Form.Group className="mx-3">
                      <Form.Label
                        className="form-view-label"
                        htmlFor="formBasicFirstName"
                      >
                        First Name
                      </Form.Label>
                      <Form.Control
                        required
                        type="text"
                        name="firstname"
                        placeholder="Enter First Name"
                        value={contact.firstname}
                        onChange={handleChange}
                      />
                    </Form.Group>
                  </Col>
                  <Col lg={6}>
                    <Form.Group className="mx-3">
                      <Form.Label
                        className="form-view-label"
                        htmlFor="formBasicLastName"
                      >
                        Last Name
                      </Form.Label>
                      <Form.Control
                        required
                        type="text"
                        name="lastname"
                        placeholder="Enter Last Name"
                        value={contact.lastname}
                        onChange={handleChange}
                      />
                    </Form.Group>
                  </Col>

                  {/* Add by Aamir khan  03-05-2024   Date of Birth*/}
                  {/* <Col lg={6}>
                    <Form.Group className="mx-3">
                      <Form.Label className="form-view-label">Date of Birth</Form.Label>
                      <input
                        type="text"
                        id="emp_dob"
                        name="dateofbirth"
                        readOnly
                        autoComplete="off"
                        className="form-control"
                        placeholder="Select Date"
                      />
                    </Form.Group>
                  </Col> */}
                  <Col lg={6}>
                    <Form.Group className="mx-3">
                      <Form.Label className="form-view-label">Date of Birth</Form.Label>
                      <input
                        type="text"
                        id="emp_dob"
                        name="dateofbirth"
                        readOnly
                        required
                        autoComplete="off"
                        className="form-control"
                        placeholder="Select Date"
                      />
                    </Form.Group>
                  </Col>

                  <Col lg={6}>
                    <Form.Group className="mx-3">
                      <Form.Label
                        className="form-view-label"
                        htmlFor="formBasicPhone"
                      >
                        Phone
                      </Form.Label>
                      <Form.Control
                        required
                        type="text"
                        name="phone"
                        placeholder="Enter Phone"
                        value={contact.phone}
                        onChange={handleInputChange}
                      />
                      {/* {!isValid && <p style={{color:"red"}}>Please enter a valid phone number.</p>} */}
                    </Form.Group>
                  </Col>
                  <Col lg={6}>
                    <Form.Group className="mx-3">
                      <Form.Label
                        className="form-view-label"
                        htmlFor="formBasicEmail"
                      >
                        Email
                      </Form.Label>
                      <Form.Control
                        type="email"
                        name="email"
                        placeholder="Enter Email"
                        value={contact.email}
                        onChange={handleChange}
                      />
                    </Form.Group>
                  </Col>
                  <Col lg={6}>
                    <Form.Group className="mx-3">
                      <Form.Label className="form-view-label">
                        Gender
                      </Form.Label>
                      <Form.Select
                        name="gender"
                        onChange={handleChange}
                        value={contact.gender}
                      >
                        <option value="">-- Select Gender--</option>
                        <option value="Male">Male</option>
                        <option value="Female">Female</option>
                        <option value="Other">Other</option>
                      </Form.Select>
                    </Form.Group>
                  </Col>
                  {/* Add by Aamir khan 06-05-2024 */}
                  <Col lg={6}>
                    <Form.Group className="mx-3">
                      <Form.Label className="form-view-label">
                        Religion
                      </Form.Label>
                      <Form.Select
                        name="religion"
                        onChange={handleChange}
                        value={contact.religion}
                      >
                        <option value="">--Select Religion--</option>
                        <option value="Muslim">Muslim</option>
                        <option value="Hindu">Hindu</option>
                        <option value="Christian">Christian</option>
                        <option value="Sikh">Sikh</option>
                        <option value="Jain">Jain</option>
                        <option value="Other">Other</option>
                      </Form.Select>
                    </Form.Group>
                  </Col>
                  <Col lg={6}>
                    <Form.Group className="mx-3">
                      <Form.Label
                        className="form-view-label"
                        htmlFor="formBasicQualification"
                      >
                        Qualification
                      </Form.Label>
                      <Form.Control
                        type="text"
                        name="qualification"
                        placeholder="Enter Your Qualification"
                        value={contact.qualification}
                        onChange={handleChange}
                      />{" "}
                    </Form.Group>
                  </Col>
                  <Col lg={6}>
                    <Form.Group className="mx-3">
                      <Form.Label
                        className="form-view-label"
                        htmlFor="formBasicAdharnumber"
                      >
                        Aadhar Number
                      </Form.Label>
                      <Form.Control
                        type="text"
                        name="adharnumber"
                        placeholder="Enter Aadhar Number"
                        value={contact.adharnumber}
                        onChange={handleChange}
                      />
                    </Form.Group>
                  </Col>
                  <Col lg={6}>
                    <Form.Group className="mx-3">
                      <Form.Label
                        className="form-view-label"
                        htmlFor="formBasicStreet"
                      >
                        Street
                      </Form.Label>
                      <Form.Control
                        type="text"
                        name="street"
                        placeholder="Enter Street"
                        value={contact.street}
                        onChange={handleChange}
                      />
                    </Form.Group>
                  </Col>
                  {/* Add By Aamir khan 03-05-202   State */}
                  <Col lg={6}>
                    <Form.Group className="mx-3">
                      <Form.Label
                        className="form-view-label"
                        htmlFor="formBasicstate"
                      >
                        State
                      </Form.Label>

                      <Form.Select
                        name="sessionid"
                        value={contact?.state}
                        onChange={handleStateChange}
                      >
                        <option value="">-- Select State --</option>
                        {states.map((state) => (
                          <option key={state.label} value={state.value}>
                            {state.label}
                          </option>
                        ))}
                      </Form.Select>
                    </Form.Group>
                  </Col>
                  {/* Add By Aamir khan 03-05-202   City */}
                  <Col lg={6}>
                    <Form.Group className="mx-3">
                      <Form.Label
                        className="form-view-label"
                        htmlFor="formBasicCity"
                      >
                        City
                      </Form.Label>
                      <Form.Select
                        name="city"
                        value={contact?.city}
                        onChange={handleCityChange}
                      >
                        <option value="">-- Select City --</option>
                        {cities.map((city) => (
                          <option key={city.label} value={city.value}>
                            {city.label}
                          </option>
                        ))}
                      </Form.Select>
                    </Form.Group>
                  </Col>
                  <Col lg={6}>
                    <Form.Group className="mx-3">
                      <Form.Label
                        className="form-view-label"
                        htmlFor="formBasicPin"
                      >
                        Pincode
                      </Form.Label>
                      <Form.Control
                        type="number"
                        name="pincode"
                        placeholder="Enter Pincode"
                        value={contact.pincode}
                        onChange={handleChange}
                      />
                    </Form.Group>
                  </Col>
                  <Col lg={6}>
                    <Form.Group className="mx-3">
                      <Form.Label
                        className="form-view-label"
                        htmlFor="formBasicCountry"
                      >
                        Country
                      </Form.Label>
                      <Form.Control
                        type="text"
                        name="country"
                        placeholder="Enter your Country"
                        value={contact.country}
                        onChange={handleChange}
                      />
                    </Form.Group>
                  </Col>
                </Row>
              </Form>
            </Col>
            <Col></Col>
          </Row>
        </Container>
      </>
    </>
  );
};

export default StaffEdit;
