import React, { useState, useEffect } from "react";
import { Button, Col, Container, Row, Table } from "react-bootstrap";
import Confirm from "../Confirm";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import schoolApi from "../../api/schoolApi";
import { Helmet } from "react-helmet";
import PageNavigations from "../breadcrumbs/PageNavigations";
import StudentAddRelatedList from "./studentAddRelatedList";
import { DatatableWrapper, TableBody, TableHeader } from "react-bs-datatable";
const StudentAddmissionView = (props) => {
  console.log("StudentAddmissionView", props);
  const location = useLocation();
  console.log("location.state on view-->", location?.state);
  const navigate = useNavigate();
  const [studentaddmission, setStudentAddmission] = useState(
    location?.state ? location.state.student_info : []
  );
  const [modalShow, setModalShow] = useState(false);
  const [discountRecords, setDiscountRecords] = useState();
  const [discountOptions, setDiscountOptions] = useState();
  const [studentInstallments, setStudentInstallments] = useState([]);

  const handleCancel = async (e) => {
    navigate("/studentaddmissions");
  };

  console.log("studentaddmission#@@!#=>", studentaddmission);

  console.log("discountOptions-->", discountOptions);
  const deleteStudentAddmission = async () => {
    const result = await schoolApi.deleteStudentAddmission(
      studentaddmission.student_addmission_id
    );
    if (result) navigate(`/studentaddmissions/`);
  };

  const editStudent = () => {
    console.log("editStudent" , location.state);
    console.log("studentaddmission@@=>", studentaddmission);
    navigate(`/student/${studentaddmission.student_addmission_id}/e`, {
      state: location.state,
    });
  };

  const handleValueFromChild = (value) => {
    setStudentAddmission(value);
  };

  const handlePreviewClick = () => {
    const row = location?.state;
    navigate("/feedepositeedit/e", { state: { row } });
  };
  return (
    <>
      <Helmet>
        {" "}
        <title>{props?.tabName}</title>{" "}
      </Helmet>
      <PageNavigations
        listName="Admissions List"
        listPath="/studentaddmissions"
        colLg={2}
        colClassName="d-flex mx-5 mb-4"
        extrColumn={12}
      />
      <div>
        {studentaddmission && (
          <Container>
            {modalShow && (
              <Confirm
                show={modalShow}
                onHide={() => setModalShow(false)}
                deleteStudent={deleteStudentAddmission}
                title="Confirm delete?"
                message="You are going to delete the record. Are you sure?"
                table="student"
              />
            )}
            <Row className="view-form">
              <Col lg={12}>
                <Row className="view-form-header align-items-center mx-3">
                  <Col lg={8}>
                    <h5>
                      {studentaddmission?.studentname
                        ? studentaddmission.studentname
                        : studentaddmission.firstname +
                          " " +
                          studentaddmission.lastname}{" "}
                      ({studentaddmission.classname}{" "}
                      {studentaddmission.section_name})
                    </h5>
                  </Col>
                  <Col lg={4} className="d-flex justify-content-end">
                    <Button
                      className="btn-sm mr-2 mx-2"
                      onClick={() => editStudent(true)}
                    >
                      Edit Student Admission
                    </Button>
                    <Button
                      className="btn-sm mr-2 mx-2"
                      onClick={() => handlePreviewClick()}
                    >
                      Fee Deposit
                    </Button>
                    <Button
                      className="btn-sm"
                      variant="danger"
                      onClick={handleCancel}
                    >
                      Cancel
                    </Button>
                  </Col>
                </Row>
              </Col>
              <Col></Col>
              <div className="px-4 mt-2">
                <StudentAddRelatedList
                  studentaddmission={studentaddmission}
                  onValueChange={handleValueFromChild}
                />
              </div>
            </Row>
          </Container>
        )}
      </div>
    </>
  );
};
export default StudentAddmissionView;