/**
 * @author: Pooja Vaishnav
 */

import React, { useEffect, useState } from "react";
import Card from "react-bootstrap/Card";
import { Button, Col, Row, Table, Form } from "react-bootstrap";
import schoolApi from "../../api/schoolApi";
import { ShimmerTable } from "react-shimmer-effects";
import InfoPill from "../InfoPill";
import { Link, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import {
  DatatableWrapper,
  Filter,
  Pagination,
  PaginationOptions,
  TableBody,
  TableHeader,
} from "react-bs-datatable";

const MonthlyAttendance = () => {
  const navigate = useNavigate();
  const [body, setBody] = useState();
  const [rowRecords, setRowRecords] = useState();
  const [btnName, setBtnName] = useState("Save");
  const [showStudent, setShowStudent] = useState(false);
  const [readOnly, setReadOnly] = useState(false);
  const [optionClasses, setOptionClasses] = useState([]);
  const [optionSection, setOptionSection] = useState([]);
  const [attendance, setAttendance] = useState({});
  const [studentList, setStudentList] = useState([]);
  const months = [
    { value: "01", label: "January" },
    { value: "02", label: "February" },
    { value: "03", label: "March" },
    { value: "04", label: "April" },
    { value: "05", label: "May" },
    { value: "06", label: "June" },
    { value: "07", label: "July" },
    { value: "08", label: "August" },
    { value: "09", label: "September" },
    { value: "10", label: "October" },
    { value: "11", label: "November" },
    { value: "12", label: "December" },
  ];
  const curentmonth = new Date().getMonth();
  console.log("curentmonth ->", curentmonth);

  useEffect(() => {
    try {
      async function init() {
        console.log("gujgkugkjg");
        let classList = await schoolApi.getActiveClassRecords();
        console.log("classListclassList-->", classList?.result);
        if (classList?.result) {
          let ar = [];
          classList?.result.map((item) => {
            var obj = {};
            obj.value = item.id;
            obj.label = item.classname;
            ar.push(obj);
          });
          setOptionClasses(ar);
          const sessions = await schoolApi.fetchSessions();
          if (sessions) {
            const currentYear = new Date().getFullYear();
            const nextYear = currentYear + 1;

            const fetchSelectedSessionResult = sessions.find((res) => {
              return res.year === `${currentYear}-${nextYear}`;
            });
            setAttendance({
              ...attendance,
              month: months[curentmonth].label,
              sessions: fetchSelectedSessionResult.id,
            });
          }
        } else {
          setOptionClasses([]);
        }
      }
      init();
    } catch (error) {
      toast.error(error?.response?.data?.message);
    }
  }, []);

  const handleStudents = async () => {
    try {
      console.log("attendance in save->", attendance);

      if (
        attendance.classid &&
        attendance.sectionid &&
        attendance.month &&
        attendance.sessions
      ) {
        const result = await schoolApi.getStudentAdmissionRecords(attendance);
        if (result.success) {
          console.log("attendance ->", attendance);
          console.log("result ->", result);
          const attendanceRec = await schoolApi.getAttendanceByMonthly(
            attendance.classid,
            attendance.sectionid,
            attendance.month,
            attendance.sessions
          );
          console.log("attendanceRec ->", attendanceRec);

          if (attendanceRec?.success) {
            console.log("attendanceRec ->", attendanceRec);
            setAttendance({
              ...attendance,
              total_lectures: attendanceRec?.result?.[0].total_lectures
                ? attendanceRec?.result?.[0].total_lectures
                : 0,
            });
            console.log("hear");
            result.studentRecords.forEach((record) => {
              let found = false;
              attendanceRec.result.forEach((ele) => {
                console.log("record ->", record);
                console.log("ele ->", ele);

                if (record.student_add_id === ele.studentadmission_id) {
                  record.present = ele.present;
                  record.id = ele.id;
                  found = true;
                }
              });
              if (!found) {
                record.present = 0;
              }

              record.month = attendance.month;
            });

            // Update student list
            setStudentList(result.studentRecords);

            setShowStudent(true);
            setBtnName("Update");
          } else {
            setAttendance({
              ...attendance,
              total_lectures: attendanceRec?.result?.[0].total_lectures
                ? attendanceRec?.result?.[0].total_lectures
                : 0,
            });
            result.studentRecords.forEach((record) => {
              record.month = attendance.month;
              record.present = 0;
            });
            setStudentList(result.studentRecords);
            setShowStudent(true);
            setBtnName("Save");
          }
        }
      } else {
        toast.error("fill all Filds");
      }
    } catch (error) {
      if (error?.response?.data?.message) {
        setShowStudent(false);
        setStudentList([]);
        toast.error(error?.response?.data?.message);
      } else toast.error("Something went wrong");
    }

  };

  const handleClasses = async (e) => {
    try {
      console.log("e.target.value@@@+>", e.target.value);
      setAttendance({ ...attendance, classid: e.target.value });
      if (e.target.value) {
        let sectionList = await schoolApi.getSectionRecordById(e.target.value);
        console.log("sectionList@@+>", sectionList);
        if (sectionList?.record && sectionList?.record?.length > 0) {
          let sectionOptions = sectionList?.record.map((item) => ({
            value: item.id,
            label: item.name,
          }));
          setOptionSection(sectionOptions);
        } else {
          setOptionSection([{ value: "", label: "No Records Found!!" }]);
        }
      }
    } catch (error) {
      toast.error(error.message);
    }
  };

  const handleSection = (e) => {
    setAttendance({ ...attendance, sectionid: e.target.value });
  };

  const handleMonth = async (e) => {
    try {
      console.log("e.target.value@@=>", e.target.value);
      setAttendance({ ...attendance, month: e.target.value });
      console.log("attdnnaa=>", attendance);
      setShowStudent(false);
      setStudentList([]);
    } catch (error) {
      toast.error(error.message);
    }
  };

  const handleSave = async () => {
    try {
      console.log("studntLIstt=>", studentList);
      console.log("attedcbe=>", attendance);
      let attendanceRecords = [];

      for (let i = 0; i < studentList.length; i++) {
        let student = studentList[i];
        const sessions = await schoolApi.fetchSessions();
        if (sessions) {
          const currentYear = new Date().getFullYear();
          const nextYear = currentYear + 1;

          const fetchSelectedSessionResult = sessions.find((res) => {
            return res.year === `${currentYear}-${nextYear}`;
          });
          let attendanceRecord = {
            id: student.id,
            class_id: student.classid,
            section_id: student.section_id,
            studentadmission_id: student.student_add_id,
            total_lectures: attendance.total_lectures,
            type: "monthly",
            present: student.present,
            absent: attendance.total_lectures - student.present,
            month: student.month,
            session_id: fetchSelectedSessionResult?.id,
            year: fetchSelectedSessionResult?.year,
          };
          attendanceRecords.push(attendanceRecord);
        }
      }

      console.log("Attendance Records =>", attendanceRecords);
      if (attendanceRecords) {
        console.log("atendanceRecord@@@=>", attendanceRecords);
        let attRecord = await schoolApi.addAttendanceRecords(attendanceRecords);
        console.log("attRecord@!@2=>", attRecord);
        if (attRecord?.success === true) {
          toast.success("Record save successfully");
          setShowStudent(true);
          handleStudents();
          setBtnName("Update");
        } else if (btnName === "Update") {
          console.log("btnName@@!#=>");
          console.log("studentList=>", studentList);
          let updatedAtt = await schoolApi.updateMultiAttRec(attendanceRecords);
          console.log("updatedAtt@!@2=>", updatedAtt);
          if (updatedAtt) {
            toast.success("Record update successfully");
          }
          setBtnName("Update");
        }
      }
    } catch (error) {
      toast.error(error.message);
    }
  };

  const handleTotalPresent = (e, index) => {
    let { value } = e.target;
    console.log("value ->", value);
    if (value === "") {
      value = 0;
    }
    console.log("attendance.total_lectures ->", attendance.total_lectures);

    if (parseInt(attendance.total_lectures) >= parseInt(value)) {
      setStudentList((prevList) =>
        prevList.map((student, idx) =>
          idx === index
            ? { ...student, present: parseFloat(value) || 0 }
            : student
        )
      );
    } else {
      toast.error("The present total must be less than the total lecture.");
    }
  };

  const handleTotalLectures = (e) => {
    console.log("attendance.month ->", attendance.month);

    if (
      [
        "January",
        "March",
        "May",
        "July",
        "August",
        "October",
        "December",
      ].includes(attendance.month)
    ) {
      console.log("e.target.value ->", e.target.value);

      if (parseInt(e.target.value) > 31)
        return toast.error("fill lasethan 31 value");
    } else if (
      ["April", "June", "September", "November"].includes(attendance.month)
    ) {
      console.log("hear in fer 30");
      if (parseInt(e.target.value) > 30)
        return toast.error("fill lasethan 30 value");
    } else if (attendance.month === "February") {
      console.log("hear in fer 28");
      const Year = new Date().getFullYear();
      if (Year % 4 !== 0) {
        if (parseInt(e.target.value) > 28)
          return toast.error("fill lasethan 28 value");
      } else {
        if (parseInt(e.target.value) > 29)
          return toast.error("fill lasethan 29 value");
      }
    }
    setAttendance({ ...attendance, total_lectures: e.target.value });
  };

  return (
    <>
      <Row className="g-0">
        <Col lg={10} className="mx-4">
          <Link className="nav-link" to="/">
            Home <i className="fa-solid fa-chevron-right"></i>
            <strong> Attendance</strong>{" "}
          </Link>
        </Col>

        <Row>
          <Col lg={12} className="p-lg-4">
            <Card>
              <Card.Body>
                <h4 className="atten_header">Monthly Attendance</h4>
                <hr className="hrstyle" />

                <Row lg={12} className="mt-5">
                  <Col>
                    <Form>
                      <Form.Group controlId="formClassName">
                        <Form.Label className="text-formatted">
                          Class Name
                        </Form.Label>
                        <Form.Select
                          required
                          size="lg"
                          name="class_id"
                          onChange={handleClasses}
                          value={attendance?.classid}
                        >
                          <option value="">Select Class</option>
                          {optionClasses.map(
                            (className, index) => (
                              console.log(
                                "className.value@@=>",
                                className.value
                              ),
                              (
                                <option key={index} value={className.value}>
                                  {console.log(
                                    "classNameclassName-->",
                                    className
                                  )}
                                  {className.label}
                                </option>
                              )
                            )
                          )}
                        </Form.Select>
                      </Form.Group>
                    </Form>
                  </Col>
                  <Col>
                    <Form>
                      <Form.Group className="mb-3" controlId="formClassName">
                        <Form.Label className="text-formatted">
                          Section Name
                        </Form.Label>
                        <Form.Select
                          required
                          disabled={attendance?.classid ? false : true}
                          size="lg"
                          name="sectionid"
                          onChange={handleSection}
                          value={attendance?.sectionid}
                        >
                          <option value="">Select Section</option>
                          {optionSection.map((sec, index) => (
                            <option key={index} value={sec.value}>
                              {sec.label}
                            </option>
                          ))}
                        </Form.Select>
                      </Form.Group>
                    </Form>
                  </Col>
                  <Col>
                    <Form>
                      <Form.Group controlId="formClassName">
                        <Form.Label className="text-formatted">
                          Month
                        </Form.Label>
                        <Form.Select
                          required
                          disabled={attendance?.sectionid ? false : true}
                          size="lg"
                          name="month"
                          onChange={handleMonth}
                          value={attendance?.month} // Update this to use the correct state property
                        >
                          <option value="">Select Month</option>
                          {months.map((month, index) => (
                            <option key={index} value={month.label}>
                              {month.label}
                            </option>
                          ))}
                        </Form.Select>
                      </Form.Group>
                    </Form>
                  </Col>
                  <Col>
                    <Button
                      className="btn btnHover"
                      variant="primary"
                      size="lg"
                      onClick={() => handleStudents()}
                      style={{ marginTop: "28px" }}
                    >
                      Search
                    </Button>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Col>
        </Row>

        {showStudent && (
          <Row>
            <Col lg={12} className="p-lg-4">
              <Card>
                <Card.Body>
                  <h3 className="headingstyle">Students Information</h3>
                  <Form className="mb-4">
                    <Form.Group
                      controlId="formClassName"
                      className="d-flex justify-content-end align-items-center"
                    >
                      <Form.Label className="text-formatted me-2">
                        Total Lectures :
                      </Form.Label>
                      <Form.Control
                        type="text"
                        name="total_lectures"
                        onChange={(e) => handleTotalLectures(e)}
                        value={attendance?.total_lectures}
                        size="lg"
                        className="w-20"
                      />
                    </Form.Group>
                  </Form>

                  <table className="table table-striped table-bordered table-hover">
                    <thead style={{ textAlign: "center" }}>
                      <tr>
                        <th>S.No.</th>
                        <th>Student Name</th>
                        <th>Total Present</th>
                      </tr>
                    </thead>
                    <tbody style={{ textAlign: "center" }}>
                      {console.log("studentList########=>", studentList)}
                      {studentList && studentList.length > 0 ? (
                        studentList.map((item, index) => (
                          <tr key={item.student_id}>
                            <td>{index + 1}</td>
                            <td>{item.studentname}</td>
                            <td>
                              <Form>
                                <Form.Group controlId="formClassName">
                                  <Form.Control
                                    type="text"
                                    name="present"
                                    onChange={(e) =>
                                      handleTotalPresent(e, index)
                                    }
                                    style={{ textAlign: "right" }}
                                    value={item?.present}
                                    //readOnly={readOnly === true ? true : false}
                                  />
                                </Form.Group>
                              </Form>
                            </td>
                          </tr>
                        ))
                      ) : (
                        <tr>
                          <td colSpan="3">No records found</td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                  <div className="d-flex justify-content-end">
                    <Button
                      className="btn btnHover mt-5 mb-4"
                      variant="primary"
                      onClick={() => handleSave()}
                      size="lg"
                    >
                      {btnName}
                    </Button>
                  </div>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        )}
        <Col lg={2}></Col>
        <ToastContainer
          position="top-right"
          autoClose={2000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
        />
      </Row>
    </>
  );
};

export default MonthlyAttendance;
