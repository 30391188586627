import React, { useState, useEffect } from 'react';
import { Form, Row, Col } from 'react-bootstrap';
import '../../resources/css/Student.css';
import schoolApi from "../../api/schoolApi";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function PreviousSchoolingTab(props) {
  console.log('propsprops@=>',props)
  const [optionClasses, setOptionClasses] = useState([]);
  const [previousSchool, setPreviousSchool] = useState([]);
  const [validatedForPreSchool, setValidatedForPreSchool] = useState(false);
  useEffect(() => {
    async function init() {
      try{
        if(props?.student?.previous_school_id){
          let preSchool = await schoolApi.fetchPreviousSchoolById(props?.student?.previous_school_id);
          console.log('preSchool@@@=>',preSchool)
          setPreviousSchool(preSchool)
        }
        let classList = await schoolApi.getActiveClassRecords();
        console.log('classList12@@+>',classList)
        if (classList?.result) {
          let ar = [];
          classList?.result?.map((item) => {
            var obj = {};
            obj.value = item.id;
            obj.label = item.classname;
            ar.push(obj);
          })
          setOptionClasses(ar);
        } else {
          setOptionClasses([]);
        }
      }catch(error){
        toast.error(error?.response?.data?.message)
      }
    }
    init();
  }, []);
  useEffect(() => {
  }, [props.validatedForPreSchool]);
  const handleChange = (event) => {
    setPreviousSchool({ ...previousSchool, [event.target.name]: event.target.value });
    props.handlePreviousSchool({ ...previousSchool, [event.target.name]: event.target.value })
  }

  const handleClasses = (e) => {
    setPreviousSchool({ ...previousSchool, class: e.target.value });
  }
  const handlePhoneChange = (event) => {
    const inputPhone = event.target.value.replace(/\D/g, '').slice(0, 10);
    handleChange({ target: { name: 'phone', value: inputPhone } });
  };
  return (
    <>
     <ToastContainer
      position="top-right"
      autoClose={2000}
      hideProgressBar={false}
      newestOnTop={false}
      closeOnClick
      rtl={false}
      pauseOnFocusLoss
      draggable
      pauseOnHover
      theme="light"
      />
      <Form
        noValidate
        validated={validatedForPreSchool}
      >
        <Row>
          <Col md={4}>
            <Form.Group className="mb-3" controlId="formFirstName">
              <Form.Label
                className="text-formatted"
                htmlFor="formBasicClass"
              >
                Class Name
              </Form.Label>
              <Form.Select size="lg" name="class" onChange={handleClasses} value={previousSchool?.class}>
                <option value="">Select Class</option>
                {optionClasses.map((className, index) => (
                  <option key={index} value={className.value}>
                    {className.label}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>
          </Col>
          <Col md={4}>
            <Form.Group className="mb-3" controlId="formLastName">
              <Form.Label className="text-formatted">Grade</Form.Label>
              <Form.Control type="text" placeholder="Enter Grade" size="lg" name="grade" onChange={handleChange} value={previousSchool?.grade}/>
            </Form.Group>
          </Col>
          <Col md={4}>
            <Form.Group className="mb-3" controlId="formLastName">
              <Form.Label className="text-formatted">Passed Year</Form.Label>
              <Form.Control type="text" placeholder="Enter Passed Year" size="lg" name="passed_year" onChange={handleChange} value={previousSchool?.passed_year} maxLength={4}/>
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <Form.Group className="mb-3" controlId="formCity">
              <Form.Label className="text-formatted">School Name</Form.Label>
              <Form.Control type="text" placeholder="Enter School Name" size="lg" name="school_name" onChange={handleChange} value={previousSchool?.school_name}/>
            </Form.Group>
          </Col>
          <Col md={6}>
            <Form.Group className="mb-3" controlId="formPostalCode">
              <Form.Label className="text-formatted">Phone</Form.Label>
              <Form.Control type="phone" placeholder="Enter Phone" size="lg" name="phone" onChange={handlePhoneChange} value={previousSchool?.phone}/>
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <Form.Group className="mb-3 " controlId="formStreet">
              <Form.Label className="text-formatted">School Address</Form.Label>
              <Form.Control type="text" placeholder="Enter School Address" size="lg" name="school_address" onChange={handleChange} value={previousSchool?.school_address}/>
            </Form.Group>
          </Col>
        </Row>
      </Form>
    </>
  );
}
export default PreviousSchoolingTab;