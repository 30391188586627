/**
 * @author: Pawan Singh Sisodiya
 */

import React, { useEffect, useState, useRef } from "react";
import { Button, Col, Row, Table } from "react-bootstrap";
import { useLocation } from "react-router-dom";
import { DatatableWrapper, TableBody, TableHeader } from "react-bs-datatable";

import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import schoolApi from "../../api/schoolApi";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Confirm from "../Confirm";
import { FaReceipt } from "react-icons/fa";
import FeeReceipt from "./FeeReceipt.jsx";
import { useReactToPrint } from "react-to-print";

const FeeDepositeHistory = (props) => {
  const location = useLocation();
  const [studentRecord, SetStudentRecord] = useState(
    location.state.row.student_info ? location.state.row.student_info : {}
  );
  const [feeHistrory, setFeeHistory] = useState([]);
  const [cancelledHistory, setCancelledHistrory] = useState([]);
  const [confirmation, setConfirmation] = useState(false);
  // Start: Add by Abhishek
  const [selectedReceiptData, setSelectedReceiptData] = useState(null);
  const receiptRef = useRef();
  // End: Add by Abhishek

  console.log("studentRecord-->", studentRecord);
  useEffect(() => {
    init();
  }, []);

  async function init() {
    try {
      setFeeHistory([]);
      setCancelledHistrory([]);
      const depositHistoryRecords = await schoolApi.fetchFeeDepositsByStudentId(
        studentRecord?.student_addmission_id,
        studentRecord?.session_id
      );

      if (depositHistoryRecords.success) {
        depositHistoryRecords?.result?.forEach((res) => {
          if (res.status === "cancelled") {
            setCancelledHistrory((prev) => [...prev, res]);
          } else {
            setFeeHistory((prev) => [...prev, res]);
          }
        });
      }
    } catch (error) {
      //console.log("error ->", error);
      setFeeHistory([]);
      setCancelledHistrory([]);
    }
  }

  const handleCancelReceipt = async (row) => {
    try {
      console.log("receipt cancel is called!!!", row);
      const result = await schoolApi.cancelFeeReceipt(
        row?.deposit_id,
        row?.student_addmission_id
      );
      console.log("deleted result => ", result);

      if (result.success === false) {
        return toast.error(result.message);
      } else if (result.success === true) {
        setConfirmation(false);

        toast.success(result.message);
        init();
        return;
      }
    } catch (error) {
      return toast.error(error.response.data.message);
    }
  };

  const header = [
    {
      title: "Receipt No",
      prop: "receipt_number",
      isFilterable: true,
    },
    {
      title: "Class Name",
      prop: "classname",
      isFilterable: true,
    },
    {
      title: "Amount",
      prop: "amount",
      isFilterable: true,
    },
    {
      title: "Payment Date",
      prop: "payment_date",
      isFilterable: true,
    },
    {
      title: "Late Fee",
      prop: "late_fee",
      isFilterable: true,
    },
    {
      title: "Discount",
      prop: "discount",
      isFilterable: true,
    },
    {
      title: "Status",
      prop: "status",
      cell: (row) => (row.status === "deposit" ? "Deposit" : "Cancelled"),
    },
    {
      title: "Session",
      prop: "session",
      isFilterable: true,
    },
    {
      title: "Action",
      prop: "",
      isFilterable: true,
      cell: (row) => {
        const isLatest = row.deposit_id === feeHistrory[0]?.deposit_id;
        return isLatest ? (
          <div>
            <Link
              to="#"
              title="Fee Receipt"
              state={{ row }}
              onClick={() => handlePrintClick(row)}
              className="btn btn-sm btn-primary btnHoverAtt"
            >
              <FaReceipt />
              <span class="tooltiptextAtt">Fee Receipt</span>
            </Link>

            <Link
              to={"/feedepositeedit/e"}
              state={{ row }}
              className="btn btn-sm edit-button mx-2 btnHoverAtt"
            >
              <i className="fa-regular fa-pen-to-square"></i>
              <span class="tooltiptextAtt">Edit</span>
            </Link>

            <Button
              className="btn-sm btnHoverAtt"
              variant="danger"
              onClick={() => {
                setConfirmation(true);
              }}
            >
              <i className="fa-solid fa-cancel"></i>
              <span class="tooltiptextAtt">Cancel Receipt</span>
            </Button>

            {confirmation && (
              <Confirm
                show={confirmation}
                onHide={() => setConfirmation(false)}
                handleCancelReceipt={() => handleCancelReceipt(row)}
                title="Cancel Receipt"
                message="You are going to cancel the receipt. Are you sure?"
              />
            )}
          </div>
        ) : null;
      },
    },
  ];

  const header1 = [
    {
      title: "Receipt Number",
      prop: "receipt_number",
      isFilterable: true,
    },
    {
      title: "Class Name",
      prop: "classname",
      isFilterable: true,
    },
    {
      title: "Amount",
      prop: "amount",
      isFilterable: true,
    },
    {
      title: "Payment Date",
      prop: "payment_date",
      isFilterable: true,
    },
    {
      title: "Late Fee",
      prop: "late_fee",
      isFilterable: true,
    },
    {
      title: "Discount",
      prop: "discount",
      isFilterable: true,
    },
    {
      title: "Status",
      prop: "status",
      cell: (row) => (row.status === "deposit" ? "Deposit" : "Cancelled"),
    },
    {
      title: "Session",
      prop: "session",
      isFilterable: true,
    },
  ];

  console.log("feeHistrory-->", feeHistrory);
  console.log("cancelledHistory-->", cancelledHistory);
  console.log("confirmation-->", confirmation);

  const handlePrint = useReactToPrint({
    content: () => receiptRef.current,
    documentTitle: "FeeReceipt",
    onAfterPrint: () => {
      setSelectedReceiptData(null);
    },
  });

  const handlePrintClick = (data) => {
    setSelectedReceiptData(data);
    const timer = setTimeout(() => {
      handlePrint();
    }, 200);
    return () => clearTimeout(timer);
  };

  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <Helmet>
        {" "}
        <title>{props?.tabName}</title>{" "}
      </Helmet>
      <Row className="g-0">
        <Col lg={2} className="mx-3">
          <Link className="nav-link mx-2" to="/">
            Home <i className="fa-solid fa-chevron-right"></i>{" "}
            FeeDepositHistory
          </Link>
        </Col>
      </Row>

      <Row>
        <Col lg={12}>
          <Col className="section-header mx-3 my-3">
            <span style={{ color: "black" }}>Fee Deposit History</span>
          </Col>
        </Col>
      </Row>

      <Row className="mx-3">
        <Col lg={12}>
          {feeHistrory ? (
            <DatatableWrapper body={feeHistrory} headers={header}>
              <Table striped className="data-table">
                <TableHeader />
                <TableBody />
              </Table>
            </DatatableWrapper>
          ) : (
            <p>No record found</p>
          )}
        </Col>
      </Row>

      {cancelledHistory.length ? (
        <>
          <Row>
            <Col lg={12}>
              <Col className="section-header mx-3 my-3">
                <span style={{ color: "black" }}>Cancelled Receipts</span>
              </Col>
            </Col>
          </Row>
          <Row className="mx-3">
            <Col lg={12}>
              {feeHistrory ? (
                <DatatableWrapper body={cancelledHistory} headers={header1}>
                  <Table striped className="data-table">
                    <TableHeader />
                    <TableBody />
                  </Table>
                </DatatableWrapper>
              ) : (
                <p>No record found</p>
              )}
            </Col>
          </Row>
        </>
      ) : (
        ""
      )}

      {/* Add By Abhishek */}
      {selectedReceiptData && (
        <div style={{ display: "none" }}>
          <FeeReceipt ref={receiptRef} receiptData={selectedReceiptData} />
        </div>
      )}
    </>
  );
};

export default FeeDepositeHistory;
