import React, { useEffect, useState } from "react";
import { Button, Col, Form, Row, Table } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import schoolApi from "../../api/schoolApi";
import { ShimmerTable } from "react-shimmer-effects";
import moment from "moment";
import InfoPill from "../InfoPill";
import {
  DatatableWrapper,
  Filter,
  Pagination,
  PaginationOptions,
  TableBody,
  TableHeader,
} from "react-bs-datatable";
import { Link } from "react-router-dom";

import { Helmet } from "react-helmet";
import { usePermissions } from "../contexts/PermissionContext";
import { MdFilterAlt } from "react-icons/md";
import FilterComponent from "../subject/filter_component";
import { toast, ToastContainer } from "react-toastify";

const StaffList = (props) => {
  const { rolePermissions, userInfo } = usePermissions();
  const navigate = useNavigate();
  const [body, setBody] = useState();
  const [contacts, setContacts] = useState();

  const [colSize, setColSize] = useState({ tableSize: 12, filterSize: 0 });
  const [isFilter, setIsFilter] = useState(false);
  const [addSearchRecShow, setAddSearchRecShow] = useState(false);

  useEffect(() => {
    async function init() {
      try {
        const result = await schoolApi.fetchStaffContacts();
        console.log('result-->',result);
        if (result.success) {
          setBody(result.contacts);
          setContacts(result.contacts);
        } else {
          setBody([]);
          setContacts([]);
        }
      } catch (error) {
        setBody([]);
        setContacts([]);
      }
    }
    init();
  }, []);

  const canCreate = rolePermissions?.Employee_List?.can_create;
  const canEdit = rolePermissions?.Employee_List?.can_edit;
  const canDelete = rolePermissions?.Employee_List?.can_delete;

  const header = [
    {
      title: "Employee Name",
      show: true,
      prop: "staffname",
      isFilterable: true,
      show: true, 
      cell: (row) => (
        <Link to={"/staff/" + row.id} state={row}>
          {row.staffname}
        </Link>
      ),
    },
    { title: "Street", prop: "street", show: true, isFilterable: true },
    { title: "City", prop: "city", show: true, isFilterable: true },
    {
      title: "Date of Birth",
      prop: "dateofbirth",
      // show: true,
      isFilterable: true,
      show:
        userInfo?.userrole === "SYS_ADMIN" || userInfo?.userrole === "ADMIN"
          ? true
          : canCreate, // Add by Aamir khan 05-06-2024
      cell: (row) => moment(row.dateofbirth).format("DD-MM-YYYY"),
    },
    { title: "Phone", prop: "phone", show: true, isFilterable: true },
    { title: "Email", prop: "email", show: true, isFilterable: true },
    {
      title: "Employee Type",
      prop: "recordtype",
      show: true,
      isFilterable: true,
    },

  ];

  const visibleHeader = header?.filter((col) => col.show);

  // Randomize data of the table columns.
  // Note that the fields are all using the `prop` field of the headers.
  const labels = {
    beforeSelect: " ",
  };

  const createContact = () => {
    if (
      canEdit ||
      userInfo?.userrole === "SYS_ADMIN" ||
      userInfo?.userrole === "ADMIN"
    ) {
      navigate(`/staff/e`);
    }
  };

  const addFilter = () => {
    setAddSearchRecShow(!addSearchRecShow);
    setIsFilter(!isFilter);
    if (!addSearchRecShow) {
      setColSize({ tableSize: 9, filterSize: 3 });
    } else {
      console.log("ElsePart-->", isFilter);
      setColSize({ tableSize: 12, filterSize: 0 });
    }
  };

  return (
    <>
      <Helmet>
        {" "}
        <title>{props?.tabName}</title>{" "}
      </Helmet>
      <Col lg={2} className="mx-3">
        <Link className="nav-link mx-2" to="/">Home <i className="fa-solid fa-chevron-right"></i>{" "}EmployeeList</Link>
      </Col>
      <Row className="g-0">
        <Col lg={12} className="p-lg-4">
          {body ? (
            <DatatableWrapper
              body={body}
              headers={visibleHeader}
              paginationOptionsProps={{
                initialState: {
                  rowsPerPage: 15,
                  options: [5, 10, 15, 20],
                },
              }}
            >
              <Row className="mb-4">
                <Col lg={3}>
                  <Filter />
                </Col>
                {/* <Col lg={3}>
                  <Form.Select
                    aria-label="Employee Type"
                    name="employeetype"
                    onChange={onFilterType}
                  >
                    <option value="">--Select Employee Type--</option>
                    <option value="Principal">Principal</option>
                    <option value="Teacher">Teacher</option>
                    <option value="Admin">Admin</option>
                    <option value="Librarian">Librarian</option>
                    <option value="Accountant">Accountant</option>
                    <option value="Driver">Driver</option>
                    <option value="Peon">Peon</option>
                    <option value="Security Guard">Security Guard</option>
                    <option value="PTI">PTI</option>
                  </Form.Select>
                </Col> */}
                <Col lg={1} style={{ "margin-top": "-18px" }}>
                  <PaginationOptions labels={labels} />
                </Col>
                <Col lg={6} style={{ "margin-top": "-13px" }}>
                  <div>
                    <InfoPill left="Total Employees" right={body?.length} />
                  </div>
                </Col>
                <Col
                  lg={2}
                  className="d-flex flex-col justify-content-end align-items-end"
                >
                  {(userInfo?.userrole === "SYS_ADMIN" ||
                    userInfo?.userrole === "ADMIN" ||
                    canCreate) && (
                    <Button
                      className="btn-light add-new-button btn"
                      variant=""
                      onClick={() => createContact(true)}
                    >
                      New Employee
                    </Button>
                  )}
                  <Button
                    className="btn"
                    variant="outline-secondary"
                    onClick={addFilter}
                    style={{ marginLeft: "10px" }}
                  >
                    <span className="d-inline-flex align-items-center">
                      <MdFilterAlt
                        style={{
                          marginRight: "0px",
                          paddingTop: "2px",
                          fontSize: "15px",
                        }}
                      />
                    </span>
                  </Button>
                </Col>
              </Row>
              {/* Add by Aamir khan 14-06-24*/}
              <Row>
                <Col lg={`${colSize.tableSize}`}>
                  <Table striped className="data-table">
                    <TableHeader />
                    <TableBody />
                  </Table>
                  <Pagination />
                </Col>
                <Col lg={colSize.filterSize}>
                  {addSearchRecShow && (
                    <FilterComponent
                      show={addSearchRecShow}
                      // parent={rowData}
                      onHide={() => setAddSearchRecShow(false)}
                      setBody={setBody}
                      // recordSavedSuccessfully={recordSavedSuccessfully}
                    />
                  )}
                </Col>
              </Row>
            </DatatableWrapper>
          ) : (
            <ShimmerTable row={10} col={8} />
          )}
        </Col>
        <Col lg={2}></Col>
        <ToastContainer
          position="top-right"
          autoClose={2000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
        />
      </Row>
    </>
  );
};

export default StaffList;
