import React, { useEffect, useState } from "react";
import moment from "moment";
import { DatatableWrapper, TableBody, TableHeader } from "react-bs-datatable";
import { Table } from "react-bootstrap";
import schoolApi from "../../api/schoolApi";
import "../../resources/css/Student.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const SiblingInfo = (props) => {
  console.log("props-at sibling-->>>", props);

  const [student, setStudent] = useState([]); // store student-sibling-records
  const [showStudentTable, setshowStudentTable] = useState(false);

  useEffect(() => {
    async function init() {
      try {
        if (props?.studentrecord) {
          console.log("hello brother---->>>>>", props?.studentrecord);

          let primaryContactId = null;
          for (const [key, obj] of Object.entries(props?.studentrecord)) {
            console.log("obj");
            if (obj?.is_primary) {
              primaryContactId = obj?.id;
            }
          }

          if (primaryContactId) {
            let siblingRecords = await schoolApi.fetchStudentByParentId(
              primaryContactId
            ); // find a student already exist with same parent_record.
            console.log("siblingRecords--->>>>>", siblingRecords);
            console.log(
              "siblingRecords.length=>",
              siblingRecords?.studentRec?.length
            );

            if (siblingRecords?.success) {
              console.log("In the if", siblingRecords?.studentRec);
              console.log(
                "props.studentinforecord.id",
                props.studentinforecord.id
              );
              siblingRecords.studentRec = siblingRecords?.studentRec.filter(
                (ele) => ele.id !== props.studentinforecord.id
              );
              console.log(
                "siblingRecords.studentRec -",
                siblingRecords.studentRec
              );

              setStudent(siblingRecords?.studentRec);
              setshowStudentTable(true);
              props?.updateStudentRecord(siblingRecords?.studentRec);
            } else {
              setshowStudentTable(false);
              setStudent([]);
            }
          }
        }
      } catch (error) {
        toast.error(error?.response?.data?.message);
      }
    }
    init();
  }, []);

  const headerStudent = [
    { title: "S.NO", prop: "srno", isFilterable: true },
    { title: "Student Name", prop: "studentname" },
    { title: "Parent Name", prop: "parentname" },
    { title: "Parent Phone", prop: "contactphone" },
    { title: "Class", prop: "classname" },
    {
      title: "Date of Birth",
      prop: "dateofbirth",
      cell: (row) => moment(row.dateofbirth).format("DD-MMM-YYYY"),
    },
    { title: "City", prop: "city" },
  ];

  return (
    <>
      {showStudentTable ? (
        <DatatableWrapper body={student} headers={headerStudent}>
          <Table striped className="data-table">
            <TableHeader />
            <TableBody />
          </Table>
        </DatatableWrapper>
      ) : (
        <div style={{ fontSize: "20px", textAlign: "center" }}>
          No Siblings Found!!
        </div>
      )}
    </>
  );
};

export default SiblingInfo;
