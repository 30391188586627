/**
 * @author: Abdul Pathan
 */

import React, { useEffect, useState } from "react";

import { Button, Col, Row, Table } from "react-bootstrap";
import schoolApi from "../../api/schoolApi";
import { ShimmerTable } from "react-shimmer-effects";
import InfoPill from "../InfoPill";
import {
  DatatableWrapper,
  Filter,
  Pagination,
  PaginationOptions,
  TableBody,
  TableHeader,
} from "react-bs-datatable";
import { Link, useNavigate } from "react-router-dom";
import AddSection from "./AddSection";
import ActiveandIncative from "./ActiveandIncative";
import { usePermissions } from "../contexts/PermissionContext";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Helmet } from "react-helmet";            //added by muskan khan
const SectionList = (props) => {
  const { rolePermissions, userInfo } = usePermissions();
  const navigate = useNavigate();
  const [body, setBody] = useState();
  const [addModalShow, setAddModalShow] = useState(false);
  const [rowRecords, setRowRecords] = useState([]);
  const [showModalShow, setShowModalShow] = useState(false);
  const canCreate = rolePermissions?.Section_List?.can_create;
  const canEdit = rolePermissions?.Section_List?.can_edit;
  const canDelete = rolePermissions?.Section_List?.can_delete;

  // const sectionRecords = async () => {
  //   const result = await schoolApi.getSectionRecords();
  //   if (result) {
  //     setBody(result);
  //   } else {
  //     setBody([]);
  //   }
  // }

  const sectionRecords = async () => {
    try {
      const result = await schoolApi.getSectionRecords();
      if (result.success) {
        setBody(result.result);
      } else {
        toast.error(result.message);
        setBody([]);
      }
    } catch (error) {
      console.log("catchh", error);
      setBody([]);
      return toast.error(error.response.data.message);
      // toast.error(result.message);
    }
  };

  // const sectionRecords = async () => {
  //   try{
  //     const result = await schoolApi.getSectionRecords();
  //     if (result.success) {
  //       setBody(result.result);
  //     } else {
  //       toast.error(result.message);
  //       setBody([]);
  //     }
  //     } catch (error) {
  //       console.log("catchh",error)
  //       setBody([]);
  //       return toast.error(error.response.data.message)
  //       // toast.error(result.message);
  //     }
  // }

  useEffect(() => {
    sectionRecords();
  }, []);

  const promoteHandler = (obj) => {
    navigate("/promotion", { state: obj });
  };

  // Create table headers consisting of 4 columns.
  const header = [
    {
      title: "Class Name",
      prop: "class_name",
      isFilterable: true,
      show: true,
      cell: (row) => (
        <Link to={"/section/" + row.section_id} state={row}>
          {" "}
          {row.class_name}{" "}
        </Link>
      ),
    },
    {
      title: "Section Name",
      prop: "section_name",
      isFilterable: true,
      show: true,
    },
    { title: "Strength", prop: "strength", isFilterable: true, show: true },
    {
      title: "Class Teacher",
      prop: "contact_name",
      isFilterable: true,
      show: true,
    },
    {
      title: "Actions",
      prop: "id",
      isFilterable: true,
      show: true,
      cell: (row) => {
        let myBoolean = row.isactive === true ? "Active" : "InActive";
        return (
          <>
            {(userInfo?.userrole === "SYS_ADMIN" ||
              userInfo?.userrole === "ADMIN" ||
              canEdit) && (
                <Button
                  className="btn-sm mx-2 edit-button"
                  variant=""
                  onClick={() => handleEdit(row)}
                >
                  <i className="fa-regular fa-pen-to-square"></i>
                </Button>
              )}

            <Button
              className="btn-sm mx-2"
              style={{ width: "80px" }}
              variant={myBoolean === "Active" ? "success" : "danger"}
              onClick={() => acctiveRecord(row)}
            >
              {myBoolean}
            </Button>
          </>
        );
      },
    },
  ];
  const visibleHeader = header?.filter((col) => col.show);

  const labels = {
    beforeSelect: " ",
  };

  const acctiveRecord = (row) => {
    if (
      canEdit ||
      userInfo?.userrole === "SYS_ADMIN" ||
      userInfo?.userrole === "ADMIN"
    ) {
      setShowModalShow(true);
      setRowRecords(row);
    }
  };

  const addSection = () => {
    setAddModalShow(true);
    setRowRecords([]);
  };

  const handleEdit = (row) => {
    console.log("handleEdit@@@=>", row);
    setAddModalShow(true);
    setRowRecords(row);
  };

  return (
    <>
      <Helmet>
        <title>{props?.tabName}</title>    
      </Helmet>
      <Row className="g-0">
        <Col lg={12} className="p-lg-3" style={{ marginTop: "-8px" }}>
          <Col className="mx-1">
            <Link className="nav-link" to="/">
              Home{" "}
              <i
                className="fa-solid fa-chevron-right"
                style={{ marginLeft: "1px", marginRight: "1px" }}
              ></i>{" "}
              Sections
            </Link>
          </Col>
          {body ? (
            <DatatableWrapper
              body={body}
              headers={visibleHeader}
              paginationOptionsProps={{
                initialState: {
                  rowsPerPage: 15,
                  options: [5, 10, 15, 20],
                },
              }}
            >
              <Row className="mb-4">
                <Col
                  xs={12}
                  sm={6}
                  lg={3}
                  className="d-flex flex-col justify-content-end align-items-end"
                >
                  <Filter />
                </Col>
                <Col
                  xs={12}
                  sm={6}
                  lg={5}
                  className="d-flex flex-col justify-content-start align-items-start"
                >
                  <PaginationOptions labels={labels} />
                  <div style={{ marginTop: "5px" }}>
                  <InfoPill left="Total Sections" right={body?.length} />
                  </div>
                </Col>
                <Col
                  xs={12}
                  sm={6}
                  lg={4}
                  className="d-flex flex-col justify-content-end align-items-end"
                >
                  {(userInfo?.userrole === "SYS_ADMIN" ||
                    userInfo?.userrole === "ADMIN" ||
                    canCreate) && (
                      <Button
                        className="add-new-button"
                        variant=""
                        onClick={addSection}
                      >
                        Add Section
                      </Button>
                    )}
                </Col>
              </Row>
              <Table striped className="data-table custom-table">
                <TableHeader />
                <TableBody />
              </Table>
              <Pagination />
            </DatatableWrapper>
          ) : (
            <ShimmerTable row={10} col={8} />
          )}
        </Col>
        <Col lg={2}></Col>
      </Row>

      {addModalShow && (
        <AddSection
          show={addModalShow}
          parent={rowRecords}
          onHide={() => setAddModalShow(false)}
          sectionRecords={sectionRecords}
        />
      )}

      {showModalShow && (
        <ActiveandIncative
          show={showModalShow}
          parent={rowRecords}
          onHide={() => setShowModalShow(false)}
          sectionRecords={sectionRecords}
          title="Confirm Active?"
          message="You are going to active the record. Are you sure?"
          table="section"
        />
      )}
      <ToastContainer />
    </>
  );
};

export default SectionList;
