// last updated by pooja on 23-07-2024--------start-

import React from "react";
import { Container, Row, Col, Card, ListGroup } from "react-bootstrap";
import { Doughnut} from "react-chartjs-2";
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  BarElement,
} from "chart.js";
import { Helmet } from "react-helmet";
import DuesShow from "./FeeDuesShow"
import FeesCards from "./FeesCards";
import DemandReports from "../Reports/DemandReports";
import ShowEvent from "./ShowEvent";


// Register Chart.js components
ChartJS.register(
  ArcElement,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  BarElement
);

const Home = (props) => {
  const studentGenderData = {
    labels: ["Registered", "Enquiry"],
    datasets: [
      {
        data: [1500, 2000],
        backgroundColor: ["#36A2EB", "#FF6384"],
        hoverBackgroundColor: ["#36A2EB", "#FF6384"],
      },
    ],
  };

  return (
    <>
      <Container fluid className="">
      <Helmet>																
        <title>{props?.tabName}</title>			
      </Helmet>
      <Row className="">
          <FeesCards />
      </Row>
{/* -------Work stared by pawan--------- */}

        <Row>
          <Col lg={5}>
            <Row className="ms-1">
              <Card className="p-3 shadow-sm d-flex justify-content-between align-items-center">
                <Card.Title className="text-center">Student Admission Chart</Card.Title>
                <div style={{height:"300px"}}>
                <Doughnut data={studentGenderData} />
                </div>
              </Card>
            </Row>
            <Row  className="ms-1"> 
              <Card className="shadow-sm mt-3">
                <Card.Header>Notice Board</Card.Header>
                <ListGroup variant="flush">
                  <ListGroup.Item>
                    <div className="d-flex justify-content-between align-items-center">
                      <div>
                        <h6>School annual sports day celebration 2023</h6>
                        <p>20 July, 2023</p>
                      </div>
                    </div>
                  </ListGroup.Item>
                  <ListGroup.Item>
                    <div className="d-flex justify-content-between align-items-center">
                      <div>
                        <h6>Annual Function celebration 2023-24</h6>
                        <p>05 July, 2023</p>
                      </div>
                    </div>
                  </ListGroup.Item>
                </ListGroup>
              </Card>
            </Row>
          </Col>
          <Col lg={7}>
          <Row className="mx-1">
          <Card className="p-3 shadow-sm">
                <Card.Header className="text-center">Demand Report</Card.Header>
                <DemandReports/>
              </Card>
          </Row>
            <Row className="mx-1 mt-3">
              <Card className="p-3 shadow-sm">
                <Card.Header className="text-center">Fee Pending Report</Card.Header>
                <DuesShow />
              </Card>
            </Row>
            <Row className="mx-1 mb-3">
              <Card className="shadow-sm p-0 mt-3">
                <Card.Header className="text-center">Event Calendar</Card.Header>
              <ShowEvent/>
              </Card>
            </Row>
          </Col>
        </Row>
{/* -------Work ended by pawan--------- */}
      </Container>
    </>
  );
};
export default Home;
