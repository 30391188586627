import * as constants from "../constants/CONSTANT";
import jwtDecode from "jwt-decode";

const authApi = {
  async login(username, password) {
    try {
      let response = await fetch(`${constants.API_BASE_URL}/api/auth/login`, {
        method: "POST",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ username, password }),
      });

      console.log("response on auth api --->", response);
      const result = await response.json();
      if (result.success) {
        sessionStorage.setItem("token", result.authToken);
      }
      return result;
    } catch (error) {
      console.error("Error during login:", error);
      return { success: false, error: error.message };
    }
  },

  async fetchMyImage() {
    try {
      const token = sessionStorage.getItem("token");
      if (!token) {
        throw new Error("No token found in session storage");
      }

      let response = await fetch(`${constants.API_BASE_URL}/api/auth/myimage`, {
        method: "GET",
        headers: {
          Authorization: token,
        },
      });

      if (response.ok) {
        const fileBody = await response.blob();
        return fileBody;
      } else {
        throw new Error("Failed to fetch image");
      }
    } catch (error) {
      console.error("Error fetching image:", error);
      return null;
    }
  },

  logout() {
    sessionStorage.removeItem("token");
    sessionStorage.removeItem("permissions");
    window.location.href = "/login";
  },

  companyDetail() {
    try {
      const token = sessionStorage.getItem("token");
      if (!token) {
        throw new Error("No token found in session storage");
      }
      const decodedToken = jwtDecode(token);
      return decodedToken;
    } catch (error) {
      console.error("Error decoding token:", error);
      return null;
    }
  },
};

export default authApi;
