import React, { useState, useEffect } from "react";
import { ShimmerTable } from "react-shimmer-effects";
import moment from "moment";
import { Table, Button, Row, Col } from "react-bootstrap";
import schoolApi from "../../api/schoolApi";
import {
  DatatableWrapper,
  Pagination,
  TableBody,
  TableHeader,
} from "react-bs-datatable";
import { toast } from "react-toastify";

function Parents(parentId) {
  console.log("parentId@@+>", parentId);
  const [parentBody, setParentBody] = useState([]);
  const header = [
    {
      title: "PARENT NAME",
      prop: "contactname",
      isFilterable: true,
    },
    { title: "Profession", prop: "profession" },
    { title: "Qualification", prop: "qualification", isFilterable: true },
    // {
    //   title: "Date of Birth",
    //   prop: "dateofbirth",
    //   isFilterable: true,
    //   cell: (row) => moment(row.dob).format("DD-MM-YYYY"),
    // },
    { title: "Phone", prop: "phone", isFilterable: true },
    { title: "Email", prop: "email", isFilterable: true },
  ];

  useEffect(() => {
    fetchContact();
  }, []);

  const fetchContact = async () => {
    let parent_id = parentId.parentid;
    console.log("letparent_id@@=>", parent_id);
    if (parent_id !== null) {
      try {
        const result = await schoolApi.fetchContact(parent_id);
        console.log("result ==> fetch ", result?.contactRec);
        if (result?.contactRec) {
          setParentBody([result?.contactRec]);
        } else {
          setParentBody([]);
        }
      } catch (error) {
        toast.error(error?.response?.data?.message);
      }
    }
  };

  console.log("contact@@+>", parentBody);
  return (
    <div>
      <Row className="mt-2">
        <Col lg={12}>
          {parentBody ? (
            parentBody.length > 0 ? (
              <DatatableWrapper body={parentBody} headers={header}>
                <Table striped className="data-table custom-table-subject-list">
                  <TableHeader />
                  <TableBody />
                </Table>
                <Pagination />
              </DatatableWrapper>
            ) : (
              <div>
                {console.log("No records found")}
                <DatatableWrapper body={parentBody} headers={header}>
                  <Table
                    striped
                    className="data-table custom-table-subject-list"
                  >
                    <TableHeader />
                    <TableBody>
                      <tr>
                        <td colSpan={header.length} className="text-center">
                          No Records Found!!!
                        </td>
                      </tr>
                    </TableBody>
                  </Table>
                  <Pagination />
                </DatatableWrapper>
              </div>
            )
          ) : (
            <ShimmerTable row={10} col={8} />
          )}
        </Col>
      </Row>
    </div>
  );
}

export default Parents;
