import React, { useEffect, useState } from "react";
import { Table } from "react-bootstrap";
import { Link } from "react-router-dom";
import moment from "moment";
import { DatatableWrapper, TableBody, TableHeader, } from "react-bs-datatable";
import schoolApi from "../../api/schoolApi";
import "../../resources/css/Student.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
const SiblingTab = (props) => {
  console.log('SiblingTab@@=>', props)
  const [student, setStudent] = useState([]);
  const [showStudentTable, setshowStudentTable] = useState(false);
  useEffect(() => {
    async function init() {
      try{
        if ((props?.isParent.length > 0)) {
          console.log('first')
          let allParentStudent = await schoolApi.fetchStudentByParentId(props?.isParent);
          console.log('first222=>', allParentStudent)
          console.log('allParentStudent.length=>', allParentStudent.length)
          if (allParentStudent?.StudentRec?.length > 0) {
            console.log('In the if')
            setStudent(allParentStudent?.StudentRec);
            setshowStudentTable(!showStudentTable);
          } else {
            setshowStudentTable(false)
            setStudent([]);
          }
        }
      }catch(error){
        toast.error(error?.response?.data?.message)
      }
    }
    init();
  }, []);
  const headerStudent = [
    {
      title: 'S.NO', prop: 'srno', isFilterable: true,
    },
    { title: 'Student Name', prop: 'studentname', isFilterable: true },
    { title: 'City', prop: 'city', isFilterable: true },
    { title: 'Date of Birth', prop: 'dateofbirth', isFilterable: true, cell: (row) => (moment(row.dateofbirth).format('DD-MMM-YYYY')) },
    { title: 'Class', prop: 'classname', isFilterable: true },
    { title: 'Parent Name', prop: 'parentname', isFilterable: true }
  ];
  return (
    <>
     <ToastContainer
      position="top-right"
      autoClose={2000}
      hideProgressBar={false}
      newestOnTop={false}
      closeOnClick
      rtl={false}
      pauseOnFocusLoss
      draggable
      pauseOnHover
      theme="light"
      />
      {showStudentTable ? (
        <DatatableWrapper body={student} headers={headerStudent}>
          <Table striped className="data-table">
            <TableHeader />
            <TableBody />
          </Table>
        </DatatableWrapper>
      ) : (
        <div style={{ fontSize: "20px", textAlign: "center" }}>
          No Siblings Found!!
        </div>
      )}
    </>
  );
}
export default SiblingTab;