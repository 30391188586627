import React, { useState, useEffect } from "react";
import { Form, Row, Col } from "react-bootstrap";
import "../../resources/css/Student.css";
import schoolApi from "../../api/schoolApi";
import Select from "react-select";
import { toast } from "react-toastify";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "react-toastify/dist/ReactToastify.css";
import "../../App.css";
import $ from "jquery";
import "jquery-ui-dist/jquery-ui.css"; // jQuery UI CSS
import "jquery-ui-dist/jquery-ui"; // jQuery UI
import { parseISO } from "date-fns";
import { Helmet } from "react-helmet";

function StudentInfo({ studentrecord, updateStudentRecord, errors }) {
  console.log("studentrecord-->>", studentrecord);

  const [optionClasses, setOptionClasses] = useState([]);

  const [optionSection, setOptionSection] = useState([]);
  const [isChecked, setIsChecked] = useState();
  const [discountRecords, setDiscountRecords] = useState();
  const [discountOptions, setDiscountOptions] = useState(
    studentrecord.discounts ? studentrecord.discounts : []
  );
  const [feeTypeOptions, setFeeTypeOptions] = useState([]);
  const [discountAllRecords, setDiscountAllRecords] = useState();
  const [emailError, setEmailError] = useState("");
  const [currentMonthName] = useState(() => {
    const monthNames = [
      "january",
      "february",
      "march",
      "april",
      "may",
      "june",
      "july",
      "august",
      "september",
      "october",
      "november",
      "december",
    ];
    return monthNames[new Date().getMonth()];
  });

  $(document).ready(() => {
    const formatDateForPicker = (dateString) => {
      if (!dateString) return "";

      if (dateString instanceof Date) {
        dateString = dateString.toISOString().split("T")[0];
      }

      const parts = dateString.includes("-") ? dateString.split("-") : [];
      if (parts.length === 3) {
        if (parts[0].length === 4) {
          const [y, m, d] = parts;
          return `${d}/${m}/${y}`;
        } else {
          const [d, m, y] = parts;
          return `${d}/${m}/${y}`;
        }
      }
      return "";
    };

    let date;
    console.log("studentrecord?.dateofbirth  ->", studentrecord?.dateofbirth);

    if (studentrecord?.dateofbirth == "Invalid Date") {
      date = "";
    } else {
      date = formatDateForPicker(studentrecord?.dateofbirth) || "";
    }

    $("#student_dob")
      .datepicker({
        dateFormat: "dd/mm/yy",
        changeMonth: true,
        changeYear: true,
        yearRange: "1920:2024",
        maxDate: "-3Y",
        // defaultDate: date,
        onSelect: function (dateText) {
          handleDateChanges(dateText, "dateofbirth");
          $(this).val(dateText);
        },
      })
      .datepicker("setDate", date);
  });

  const handleDateChanges = (date, name) => {
    console.log("Selected date: ", date);
    const [day, month, year] = date.split("/");
    const formattedDate = `${year}-${month}-${day}`;

    updateStudentRecord({ dateofbirth: formattedDate, dob: formattedDate });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    updateStudentRecord({
      [name]: value,
    });
    if (["country", "state", "street", "pincode", "city"].includes(name)) {
      if (isChecked) {
        updateStudentRecord({
          [`permanent${name}`]: value, 
        });
      }
    }
  };

  useEffect(() => {
    fetchFeeMasters();
  }, [studentrecord.classid, studentrecord.islateadmission]);

  useEffect(() => {
    async function init() {
      //  studentrecord.lead = false;
      try {
        // let classList = await schoolApi.fetchClasses();
        fatchsession();
        let classList = await schoolApi.getActiveClassRecords();
        console.log("classListclassList-->", classList);
        if (classList?.result) {
          let ar = [];
          classList?.result.forEach((item) => {
            var obj = {};
            obj.value = item.id;
            obj.label = item.classname;
            ar.push(obj);
          });
          setOptionClasses(ar);
        } else {
          setOptionClasses([]);
        }
        console.log("hear", studentrecord);

        let discountResults = await schoolApi.fetchFeeDiscounts();
        console.log("discountResults-->", discountResults);

        if (discountResults[0].json_agg) {
          let ar = [];
          let data;
          discountResults[0].json_agg.forEach((item) => {
            console.log("item for discount-->", item);
            if(item.discount.status != 'InActive'){
            var obj = {};
            obj.value = item.discount.id;
            obj.label = item.discount.name;
            ar.push(obj);
            }
          });

          // data = ar.filter((rec) => rec.label !== "RTE");
          data = ar.filter((rec) => !rec.label.toLowerCase().includes("rte"));
          console.log("data 0>", data);

          setDiscountRecords(data);
          setDiscountAllRecords(ar);
        } else {
          setDiscountAllRecords([]);
          setDiscountRecords([]);
        }
      } catch (error) {
        toast.error(error?.response?.data?.message);
      }
    }
    init();
  }, []);

  console.log("props student", studentrecord);
  console.log("discountRecords-->", discountRecords);

  const handlePhoneChange = (event) => {
    const inputPhone = event.target.value.replace(/\D/g, "").slice(0, 10);
    handleChange({ target: { name: "phone", value: inputPhone } });
  };

  const handleEmailChange = (event) => {
    const enteredEmail = event.target.value;
    handleChange({ target: { name: "email", value: enteredEmail } });

    if (
      !/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(enteredEmail)
    ) {
      if (enteredEmail === "") {
        setEmailError("");
      } else setEmailError("Invalid email format");
    } else {
      setEmailError(""); // Clear the error if valid
    }
  };

  const handleAadharNum = (event) => {
    const inputValue = event.target.value.replace(/\D/g, "").slice(0, 12);
    handleChange({ target: { name: "adharnumber", value: inputValue } });
  };

  const fatchsession = async () => {
    const sessions = await schoolApi.fetchSessions();
    if (sessions) {
      const currentYear = new Date().getFullYear();
      const nextYear = currentYear + 1;

      const fetchSelectedSessionResult = sessions.find((res) => {
        return res.year === `${currentYear}-${nextYear}`;
      });
      if (fetchSelectedSessionResult) {
        // let sessionsyear = fetchSelectedSessionResult.year;
        // return fetchSelectedSessionResult.id;
        updateStudentRecord({
          sessionyear: fetchSelectedSessionResult.year,
          sessionId: fetchSelectedSessionResult.id,
        });
      }
    }
  };

  const handleClasses = async (e) => {
    //This Code Added By Shahir Hussain 27-08-2024
    console.log("call huea ");

    //
    try {
      const selectedClass = optionClasses.find(
        (option) => option.value === e.target.value
      );
      const className = selectedClass ? selectedClass.label : "";
      console.log(
        "studentrecord.student_addmission_idc ->",
        studentrecord.student_addmission_id
      );

      if (!studentrecord.student_addmission_id) {
        updateStudentRecord({
          classid: e.target.value,
          class_name: className,
          sectionid: "",
          section_name: "",
          category: "",
          islateadmission: false,
          fee_type: "",
        });
      }
    } catch (error) {
      toast.error(error?.response?.data?.message);
    }
  };

  const handleSection = (e) => {
    //setStudent({ sectionid: e.target.value });
    const classId = e.target.value;
    const selectedSection = optionSection.find(
      (option) => option.value === classId
    );
    const section_name = selectedSection ? selectedSection.label : "";
    updateStudentRecord({
      sectionid: e.target.value,
      section_name: section_name,
      category: "",
      islateadmission: false,
      fee_type: "",
    });
    updateStudentRecord({ sectionid: e.target.value });
  };

  const handleDiscounts = (selectedOption) => {
    console.log("selectedOption-->", selectedOption);
    setDiscountOptions(selectedOption);
    updateStudentRecord({ discounts: selectedOption });
  };

  const handleCategory = (e) => {
    console.log("i am on handleCategory-->", e.target.value);
    updateStudentRecord({ category: e.target.value });
  };

  const handleFeeChange = (e) => {
    updateStudentRecord({ fee_type: e.target.value });
  };

  const handleCheckbox = (event) => {
    setIsChecked(!isChecked);
    if (!isChecked === true) {
      studentrecord.permanentcountry = studentrecord.country;
      studentrecord.permanentstate = studentrecord.state;
      studentrecord.permanentcity = studentrecord.city;
      studentrecord.permanentpostalcode = studentrecord.pincode;
      studentrecord.permanentstreet = studentrecord.street;
      updateStudentRecord(studentrecord);
    } else {
      studentrecord.permanentcountry = "";
      studentrecord.permanentstate = "";
      studentrecord.permanentcity = "";
      studentrecord.permanentpostalcode = "";
      studentrecord.permanentstreet = "";
      updateStudentRecord(studentrecord);
    }
  };

  const handleRteChange = (e) => {
    let data;
    if (e.target.checked === true) {
      console.log("check rte", discountAllRecords);
      data = discountAllRecords.filter((rec) =>
        rec.label.toLowerCase().includes("rte")
      );
      setDiscountRecords(data);
      setDiscountOptions(data);

      updateStudentRecord({ discounts: data });
      updateStudentRecord({ isrte: e.target.checked, islateadmission: false });
    } else {
      console.log("check non rte");
      data = discountAllRecords.filter(
        (rec) => !rec.label.toLowerCase().includes("rte")
      );
      console.log("discount 2 ==", data);
      setDiscountRecords(data);
      setDiscountOptions();

      updateStudentRecord({ discounts: [] });
      updateStudentRecord({ isrte: false });
    }
    console.log("discountRecords == ", data);
    console.log("studentrecord new data == ", studentrecord);
  };
  const fetchFeeMasters = async () => {
    try {
      let classId = studentrecord.classid;
      console.log("call hai");
      //handleChange({ target: { name: "classid", value: classId } });
      if (classId) {
        let sectionList = await schoolApi.getSectionRecordById(classId);
        console.log("sectionList@@+>", sectionList);
        if (sectionList?.record && sectionList?.record?.length > 0) {
          let sectionOptions = sectionList?.record.map((item) => ({
            value: item.id,
            label: item.name,
          }));
          setOptionSection(sectionOptions);
          console.log("ha aayer");
        } else {
          console.log("hearrr");

          setOptionSection([{ value: "", label: "No Records Found!!" }]);
        }
      }

      let res = await schoolApi.fetchFeeMasterByIdOrClassid(
        studentrecord.classid
      );
      console.log("re ->", res);

      let currentYear = new Date().getFullYear();
      let feeMasterResult = res?.result?.filter(
        (item) => item?.session?.split("-")[0] === currentYear.toString()
      );

      if (feeMasterResult) {
        let isLateAdmission = studentrecord.islateadmission;
        let isRTE = studentrecord.isrte;
        console.log("isLateAdmission ->", isLateAdmission);

        let ar = feeMasterResult.map((item) => {
          const isDisabled = isLateAdmission && item.type !== "Monthly";
          return {
            value: item.id,
            label: item.type,
            disabled: isDisabled,
          };
        });

        setFeeTypeOptions(ar);
      } else {
        setFeeTypeOptions([]);
      }
    } catch (error) {
      console.log("hear hii ");

      updateStudentRecord({
        classid: "",
        class_name: "",
        sectionid: "",
        section_name: "",
        category: "",
        islateadmission: false,
        fee_type: "",
      });
      console.log("error -> ", error.response);
      if (error.response.status === 404) {
        return toast.error("Create fee master record first!!");
      } else {
        toast.error(error?.response?.data?.message);
      }
    }
  };

  const handlelatestudentChange = (e) => {
    const isLateAdmission = e.target.checked;
    updateStudentRecord({ islateadmission: isLateAdmission });
  };

  /*------  Student profile | Add by Mohd. Sarfraj | 09-Nov-2024  -------*/
  const handleStudentProfile = (event) => {
    updateStudentRecord({ studentProfileImage: event.target.files[0] });
  };

  return (
    <>
      <Helmet>																
        <title>Student New Registration</title>			
      </Helmet>
      <Form noValidate>
        <Row>
          <Col md={6}>
            <Form.Group className="mb-3" controlId="formFirstName">
              <Form.Label className="text-formatted">First Name</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter First Name"
                size="lg"
                name="firstname"
                required
                onChange={handleChange}
                value={studentrecord.firstname}
              />
            </Form.Group>
          </Col>
          <Col md={6}>
            <Form.Group className="mb-3" controlId="formLastName">
              <Form.Label className="text-formatted">Last Name</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter Last Name"
                size="lg"
                name="lastname"
                required
                onChange={handleChange}
                value={studentrecord.lastname}
              />
            </Form.Group>
          </Col>
        </Row>

        <Row>
          <Col md={12}>
            <h3>Present Address :</h3>
            <hr className="mt-3" />
          </Col>
        </Row>
        <Row className="mt-3">
          <Col md={6}>
            <Form.Group className="mb-3" controlId="formCountry">
              <Form.Label className="text-formatted">Country</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter Country"
                size="lg"
                name="country"
                onChange={handleChange}
                value={studentrecord.country}
              />
            </Form.Group>
          </Col>
          <Col md={6}>
            <Form.Group className="mb-3" controlId="formState">
              <Form.Label className="text-formatted">State</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter State"
                size="lg"
                name="state"
                onChange={handleChange}
                value={studentrecord.state}
              />
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <Form.Group className="mb-3" controlId="formCity">
              <Form.Label className="text-formatted">City</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter City"
                size="lg"
                name="city"
                onChange={handleChange}
                value={studentrecord.city}
              />
            </Form.Group>
          </Col>
          <Col md={6}>
            <Form.Group className="mb-3" controlId="formPostalCode">
              <Form.Label className="text-formatted">Pin Code</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter Pin Code"
                size="lg"
                name="pincode"
                onChange={handleChange}
                value={studentrecord.pincode}
              />
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <Form.Group className="mb-3" controlId="formStreet">
              <Form.Label className="text-formatted">Street</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter Street"
                size="lg"
                name="street"
                onChange={handleChange}
                value={studentrecord.street}
              />
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col md={7}>
            <h3>Permanent Address - check if address same :</h3>
          </Col>
          <Col md={5}>
            <Form.Group>
              <Form.Check
                type="checkbox"
                name="checkVal"
                style={{
                  marginLeft: "-19px",
                  fontSize: "1.3rem",
                  marginTop: "0.3rem",
                }}
                onClick={handleCheckbox}
              />
            </Form.Group>
          </Col>
        </Row>
        <hr className="mt-3"></hr>
        <Row className="mt-3">
          <Col md={6}>
            <Form.Group className="mb-3" controlId="formCountry">
              <Form.Label className="text-formatted">Country</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter Country"
                size="lg"
                name="permanentcountry"
                onChange={handleChange}
                value={studentrecord.permanentcountry}
              />
            </Form.Group>
          </Col>
          <Col md={6}>
            <Form.Group className="mb-3" controlId="formState">
              <Form.Label className="text-formatted">State</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter State"
                size="lg"
                name="permanentstate"
                onChange={handleChange}
                value={studentrecord.permanentstate}
              />
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <Form.Group className="mb-3" controlId="formCity">
              <Form.Label className="text-formatted">City</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter City"
                size="lg"
                name="permanentcity"
                onChange={handleChange}
                value={studentrecord.permanentcity}
              />
            </Form.Group>
          </Col>
          <Col md={6}>
            <Form.Group className="mb-3" controlId="formPostalCode">
              <Form.Label className="text-formatted">Pin Code</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter Pin Code"
                size="lg"
                name="permanentpostalcode"
                onChange={handleChange}
                value={studentrecord.permanentpostalcode}
              />
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <Form.Group className="mb-3" controlId="formStreet">
              <Form.Label className="text-formatted">Street</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter Street"
                size="lg"
                name="permanentstreet"
                onChange={handleChange}
                value={studentrecord.permanentstreet}
              />
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col md={3}>
            <h3>Other Information :</h3>
          </Col>

          <Col md={9}></Col>
          <hr className="mt-3" />
        </Row>
        <Row className="mt-3">
          <Col lg={4}>
            <Form.Group className="mb-3" controlId="formDob">
              <Form.Label className="text-formatted">Dob</Form.Label>
              <Form.Control
                required
                type="text"
                id="student_dob"
                name="dateofbirth"
                readOnly
                autoComplete="off"
                placeholder="Select Date"
                style={{ padding: "8px" }}
              />
            </Form.Group>
          </Col>
          <Col md={4}>
            <Form.Group className="mb-3" controlId="formGender">
              <Form.Label className="text-formatted">Gender</Form.Label>
              <Form.Select
                size="lg"
                name="gender"
                onChange={handleChange}
                value={studentrecord.gender}
              >
                <option value="">-- Select Gender --</option>
                <option value="Male">Male</option>
                <option value="Female">Female</option>
                <option value="Other">Other</option>
              </Form.Select>
            </Form.Group>
          </Col>
          <Col md={4}>
            <Form.Group className="mb-3" controlId="formEmail">
              <Form.Label className="text-formatted">Email</Form.Label>
              <Form.Control
                type="email"
                placeholder="Enter Email"
                size="lg"
                name="email"
                value={studentrecord.email}
                onChange={handleEmailChange}
                isInvalid={!!emailError}
              />
              <Form.Control.Feedback type="invalid">
                {emailError}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col md={4}>
            <Form.Group className="mb-3" controlId="formClassName">
              <Form.Label className="text-formatted">Class Name</Form.Label>
              <Form.Select
                size="lg"
                name="classid"
                required
                onChange={handleClasses}
                disabled={studentrecord.student_addmission_id ? true : false}
                value={studentrecord.classid}
              >
                <option value="">Select Class</option>
                {optionClasses.map((className, index) => (
                  <option key={index} value={className.value}>
                    {className.label}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>
          </Col>
          <Col md={4}>
            <Form.Group className="mb-3" controlId="formClassName">
              <Form.Label className="text-formatted">Section Name</Form.Label>
              <Form.Select
                size="lg"
                name="sectionid"
                required
                disabled={
                  studentrecord.classid
                    ? studentrecord.student_addmission_id
                      ? true
                      : false
                    : true
                }
                onChange={handleSection}
                value={studentrecord.sectionid}
              >
                <option value="">Select Section</option>
                {optionSection.map((sec, index) => (
                  <option key={index} value={sec.value}>
                    {sec.label}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>
          </Col>
          <Col md={4}>
            <Form.Group className="mb-3" controlId="formClassName">
              <Form.Label className="text-formatted">Category</Form.Label>
              <Form.Select
                size="lg"
                name="category"
                required
                onChange={handleCategory}
                disabled={
                  studentrecord.sectionid
                    ? studentrecord.student_addmission_id
                      ? true
                      : false
                    : true
                }
                value={studentrecord.category}
              >
                <option value="">--Select Category--</option>
                <option value="General">GENERAL</option>
                <option value="Obc">OBC</option>
                <option value="Sc">SC</option>
                <option value="St">ST</option>
              </Form.Select>
            </Form.Group>
          </Col>
          <Col md={4}>
            <Form.Group className="mb-3" controlId="formAadharNumber">
              <Form.Label className="text-formatted">Aadhar Number</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter Aadhar Number"
                size="lg"
                name="adharnumber"
                onChange={handleAadharNum}
                value={studentrecord.adharnumber}
              />
            </Form.Group>
          </Col>
          <Col md={4}>
            <Form.Group className="mb-3" controlId="formPhone">
              <Form.Label className="text-formatted">Phone</Form.Label>
              <Form.Control
                type="phone"
                placeholder="Enter Phone"
                size="lg"
                value={studentrecord.phone}
                name="phone"
                required
                onChange={handlePhoneChange}
              />
            </Form.Group>
          </Col>
          <Col md={4}>
            <Form.Group className="mb-3" controlId="formReligion">
              <Form.Label className="text-formatted">Religion</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter Religion"
                size="lg"
                value={studentrecord.religion}
                name="religion"
                onChange={handleChange}
              />
            </Form.Group>
          </Col>
          <Col lg={4}>
            <Form.Group className="mx-3 fees">
              <Form.Label className="form-view-label" htmlFor="formBasicClass">
                RTE
              </Form.Label>
              <Form.Check
                type="checkbox"
                style={{ fontSize: "20px" }}
                name="isrte"
                disabled={studentrecord.student_addmission_id ? true : false}
                checked={studentrecord.isrte}
                onChange={handleRteChange}
              />
            </Form.Group>
          </Col>
          <Col lg={4}>
            <Form.Group className="mx-3 fees">
              <Form.Label className="form-view-label" htmlFor="formBasicClass">
                Late Admission
              </Form.Label>
              <Form.Check
                type="checkbox"
                style={{ fontSize: "20px" }}
                name="islateadmission"
                disabled={
                  studentrecord.category
                    ? studentrecord.isrte || studentrecord.student_addmission_id
                      ? true
                      : false
                    : true
                }
                checked={studentrecord.islateadmission}
                onChange={handlelatestudentChange}
              />
            </Form.Group>
          </Col>
          <Col lg={4}>
            <Form.Group className="mx-3 fees">
              <Form.Label className="form-view-label">Student Image</Form.Label>
              <Form.Control
                type="file"
                // style={{ fontSize: "20px" }}
                name="student_image"
                onChange={handleStudentProfile}
                accept="jpg , png, jpeg"
              />
              {studentrecord?.file_parent_id && (
                <div>
                  {studentrecord?.file_id}.{studentrecord.file_type}
                </div>
              )}
              {/* // disabled={
                //   studentrecord.category
                //     ? studentrecord.isrte || studentrecord.student_addmission_id
                //       ? true
                //       : false
                //     : true
                // }
                // checked={studentrecord.islateadmission} */}
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <Form.Group className="mb-3 pb-1" controlId="formDescription">
              <Form.Label className="text-formatted">Description</Form.Label>
              <textarea
                className="form-control"
                placeholder="Enter Description"
                name="description"
                onChange={handleChange}
                value={studentrecord.description}
              ></textarea>
            </Form.Group>
          </Col>
        </Row>

        {!studentrecord.student_addmission_id && (
          <>
            <Row>
              <Col md={12}>
                <h3>Fees And Discount :</h3>
                <hr />
              </Col>
            </Row>

            <Row className="mt-3">
              <Col md={4}>
                <Form.Group className=" mx-2 custom-dropdown">
                  <Form.Label className="text-formatted">
                    Fee Installment Type
                  </Form.Label>
                  <Form.Select
                    size="lg"
                    required
                    name="fee_type"
                    disabled={studentrecord.category ? false : true}
                    value={studentrecord.fee_type}
                    onChange={handleFeeChange}
                  >
                    <option value="">-- Select Type --</option>
                    {feeTypeOptions.length > 0 ? (
                      feeTypeOptions.map((res) => (
                        <option
                          key={res.value}
                          value={res.value}
                          disabled={res.disabled}
                        >
                          {res.label}
                        </option>
                      ))
                    ) : (
                      <option>No records found!!</option>
                    )}
                  </Form.Select>
                </Form.Group>
              </Col>
              <Col md={4}>
                <Form.Group
                  className="mx-3 fees"
                  style={{ marginBottom: "70px" }}
                >
                  <Form.Label className="text-formatted">
                    Select Discounts
                  </Form.Label>
                  <Select
                    placeholder="Select discounts"
                    value={discountOptions}
                    onChange={handleDiscounts}
                    options={discountRecords}
                    isMulti={true}
                    name="discount_id"
                    isDisabled={studentrecord.isrte === true}
                  ></Select>
                </Form.Group>
              </Col>
            </Row>
          </>
        )}
      </Form>
    </>
  );
}
export default StudentInfo;
