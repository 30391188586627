import React, { useEffect, useState } from 'react'
import schoolApi from '../../api/schoolApi';

import { Button, Col, Row, Table } from "react-bootstrap";
import { Link } from "react-router-dom";
import { ShimmerTable } from "react-shimmer-effects";
import InfoPill from "../InfoPill";
import {
  DatatableWrapper,
  Filter,
  Pagination,
  PaginationOptions,
  TableBody,
  TableHeader,
} from "react-bs-datatable";
import AddUser from './AddUser';
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { usePermissions } from "../contexts/PermissionContext"; //added by muskan khan
import { Helmet } from 'react-helmet'; //added by muskan khan
const UserList = (props) => {

  const [body, setBody] = useState([]);
  const [modalShow, setModalShow] = useState(false);
  const [rowRecords, setRowRecords] = useState();
  const { rolePermissions, userInfo } = usePermissions(); //added by muskan khan
  console.log('rolePermissions-->',rolePermissions);

  const canCreate = rolePermissions?.UserList?.can_create; //added by muskan khan
  const canEdit = rolePermissions?.UserList?.can_edit; //added by muskan khan
  const canDelete = rolePermissions?.UserList?.can_delete; //added by muskan khan

  useEffect(() => {

    fetchRecords();
  }, []);

  const fetchRecords = async () => {
    const result = await schoolApi.fetchUsersbyCompanyId();
    console.log('result', result.userRec)
    if (result.success) {
      setBody(result.userRec);
    } else {
      setBody([]);
    }
  };
  const labels = {
    beforeSelect: " ",
  };
  const header = [
    { title: "First Name", prop: "firstname", isFilterable: true },
    { title: "Last Name", prop: "lastname", isFilterable: true },
    { title: "email", prop: "email", isFilterable: true },
    { title: "phone", prop: "phone", isFilterable: true },
    { title: "role", prop: "userrole", isFilterable: true },
    {
      title: "Actions",
      prop: "id",
      show :     userInfo?.userrole === "SYS_ADMIN" || userInfo?.userrole === "ADMIN" || canEdit,
      cell: (row) => {
        return (
          <>
              {(userInfo?.userrole === "SYS_ADMIN" ||
                    userInfo?.userrole === "ADMIN" ||
                    canEdit) && (
            <Button
              className="btn-sm mx-2 edit-button"
              variant=""
              onClick={() => handleEdit(row)}
            >
              <i className="fa-regular fa-pen-to-square"></i>
            </Button>
      )}
          </>
        );
      },
    },
  ];

  const addUser = () => {
    setModalShow(true);
    setRowRecords([]);
  };

  const handleEdit = (row) => {
    setModalShow(true);
    setRowRecords(row);
  };

  const recordSaveSuccesfully = () => {
    setModalShow(false);
    fetchRecords();
  };


  return (
    <>
     <Helmet>
    <title>{props?.tabName}</title>
  </Helmet>
      <Row className="g-0">
        <Col lg={2} className="mx-4">
          <Link className="nav-link" to="/">
            Home <i className="fa-solid fa-chevron-right"></i>{" "}
             User
          </Link>
        </Col>
        <Col lg={12} className="p-lg-3">
          {body ? (
            <DatatableWrapper
              body={body}
              headers={header}
              paginationOptionsProps={{
                initialState: {
                  rowsPerPage: 15,
                  options: [5, 10, 15, 20],
                },
              }}
            >
              <Row className="mb-4">
                <Col
                  xs={12}
                  lg={3}
                  className="d-flex flex-col justify-content-end align-items-end"
                >
                  <Filter />
                </Col>
                <Col
                  xs={12}
                  sm={6}
                  lg={5}
                  className="d-flex flex-col justify-content-start align-items-start"
                >
                  <PaginationOptions labels={labels} />
                  <div style={{ marginTop: "5px" }}>
                    <InfoPill left="Total Users" right={body?.length} />
                  </div>
                </Col>
                <Col
                  xs={12}
                  sm={6}
                  lg={4}
                  className="d-flex flex-col justify-content-end align-items-end"
                >
                      {(userInfo?.userrole === "SYS_ADMIN" ||
                    userInfo?.userrole === "ADMIN" ||
                    canCreate) && (
                  <Button
                    className="btn-light add-new-button"
                    style ={{color : "black"}}                    
                    variant="outline-primary"
                    onClick={() => addUser(true)}
                  >
                    Add User
                  </Button>
)}
                </Col>
              </Row>
              <Table striped className="data-table custom-table">
                <TableHeader />
                <TableBody />
              </Table>
              <Pagination />
            </DatatableWrapper>
          ) : (
            <ShimmerTable row={10} col={8} />
          )}
        </Col>
        <Col lg={2}></Col>
      </Row>

      {modalShow && (
        <AddUser
          show={modalShow}
          parent={rowRecords}
          onHide={() => setModalShow(false)}
          recordSaveSuccesfully={recordSaveSuccesfully}
        />
      )}
      <ToastContainer />
    </>
  );
}

export default UserList
