/**
 * @author: Pooja Vaishnav
 */

import React, { useEffect, useState } from "react";
import Card from "react-bootstrap/Card";
import { Button, Col, Row, Form } from "react-bootstrap";
import schoolApi from "../../api/schoolApi";
import { Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import DatePicker from "react-datepicker";
import { parseISO } from "date-fns";
import "react-datepicker/dist/react-datepicker.css";
import { Padding } from "@mui/icons-material";

const DailyAttendance = () => {
  const [optionClasses, setOptionClasses] = useState([]);
  const [optionSection, setOptionSection] = useState([]);
  const [attendance, setAttendance] = useState({});
  const [studentList, setStudentList] = useState([]);
  const [showStudent, setShowStudent] = useState(false);
  // const [btnName, setBtnName] = useState("Save");
  const [reportData, setReportData] = useState({
    date: null,
  });

  /* =====code added by muskan khan 6 sept 2024 friaday =====*/
  const handleDateChange = (date, name) => {
    if (date) {
      console.log("date = ", date);
      console.log("name = ", name);

      const day = String(date.getDate()).padStart(2, "0"); // Get day and pad with zero if needed
      const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-based in JavaScript, so add 1
      const year = date.getFullYear(); // Get full year

      console.log("day = ", day);
      console.log("month = ", month);
      console.log("year = ", year);

      const dateFormat = `${year}-${month}-${day}`;
      console.log("dateFormat == ", dateFormat);
      setShowStudent(false);
      setStudentList([]);
      setReportData((prevState) => ({
        ...prevState,
        [name]: parseISO(dateFormat),
      }));
      const monthNames = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ];
      const selectedMonth = monthNames[month - 1];
      setAttendance({
        ...attendance,
        [name]: dateFormat,
        month: selectedMonth,
      });
    }
  };
  useEffect(() => {
    async function init() {
      try {
        let classList = await schoolApi.getActiveClassRecords();
        if (classList?.result) {
          let ar = classList?.result.map((item) => ({
            value: item.id,
            label: item.classname,
          }));
          setOptionClasses(ar);
          const sessions = await schoolApi.fetchSessions();
          if (sessions) {
            const currentYear = new Date().getFullYear();
            const nextYear = currentYear + 1;

            const fetchSelectedSessionResult = sessions.find((res) => {
              return res.year === `${currentYear}-${nextYear}`;
            });
            setAttendance({
              ...attendance,
              sessions: fetchSelectedSessionResult.id,
              sessionsyear: fetchSelectedSessionResult.year,
            });
          }
        } else {
          setOptionClasses([]);
        }
      } catch (error) {
        if (error?.response?.data?.message)
          toast.error(error?.response?.data?.message);
        else {
          toast.error("something went wrong");
          console.log("error ->", error);
        }
      }
    }
    init();
  }, []);

  const handleStudents = async () => {
    try {
      console.log("attendance in save->", attendance);
      if (
        attendance.classid &&
        attendance.sectionid &&
        attendance.month &&
        attendance.sessions
      ) {
        const result = await schoolApi.getStudentAdmissionRecords(attendance);
        console.log("result ->", result);
        if (result.success) {
          console.log("attendance ->", attendance);
          const attendanceRec = await schoolApi.getAttendanceLineItemRecords(
            attendance
          );
          console.log("attendanceRec ->", attendanceRec);

          if (attendanceRec?.success) {
            console.log("attendanceRec ->", attendanceRec);
            console.log("result ->", result);

            result.studentRecords.forEach((record) => {
              let found = false;
              console.log("record ->", record);

              attendanceRec.records.forEach((ele) => {
                console.log("record ->", record);
                console.log("ele ->", ele);

                if (record.student_add_id === ele.studentadmission_id) {
                  record.date = attendance.date;
                  record.month = attendance.month;
                  record.status = ele.status ? ele.status : "present";
                  record.id = ele.id;
                  record.attendance_id = ele.attendance_id;
                  found = true;
                }
              });
              if (!found) {
                record.status = "present";
              }
            });
            console.log("result.studentRecords =>", result.studentRecords);

            setStudentList(result.studentRecords);
            setShowStudent(true);
            //  setBtnName("Update");
          } else {
            const attendanceRec = await schoolApi.getAttendanceByMonthly(
              attendance.classid,
              attendance.sectionid,
              attendance.month,
              attendance.sessions
            );
            console.log("attendanceRec ->", attendanceRec);

            if (attendanceRec?.success) {
              console.log("attendanceRec ->", attendanceRec);
              result.studentRecords.forEach((record) => {
                let found = false;
                console.log("record ->", record);

                attendanceRec.result.forEach((ele) => {
                  console.log("record ->", record);
                  console.log("ele ->", ele);

                  if (record.student_add_id === ele.studentadmission_id) {
                    record.date = attendance.date;
                    record.month = attendance.month;
                    record.status = ele.status ? ele.status : "present";
                    record.id = null;
                    record.attendance_id = ele.id;
                    found = true;
                  }
                });
                if (!found) {
                  record.status = "present";
                }
              });
              console.log("result.studentRecords =>", result.studentRecords);

              setStudentList(result.studentRecords);
              setShowStudent(true);
              //setBtnName("Update");
            } else {
              result.studentRecords.forEach((record) => {
                record.date = attendance.date;
                record.month = attendance.month;
                record.status = "present";
              });
              setStudentList(result.studentRecords);
              setShowStudent(true);
              //setBtnName("Save");
            }
          }
        } else {
          setShowStudent(false);
          setStudentList([]);
        }
        // const attendanceRec = await schoolApi.getAttendanceLineItemRecords(
        //   attendance
        // );
        // console.log("attendanceRec ->", attendanceRec);
      } else {
        toast.error("fill all Filds");
      }
    } catch (error) {
      if (error?.response?.data?.message) {
        setShowStudent(false);
        setStudentList([]);
        toast.error(error?.response?.data?.message);
      } else {
        toast.error("Something went wrong");
        console.log("error ->", error);
      }
    }
  };

  const handleClasses = async (e) => {
    try {
      const classId = e.target.value;
      setAttendance({ ...attendance, classid: classId });
      if (classId) {
        let sectionList = await schoolApi.getSectionRecordById(classId);
        if (sectionList?.record && sectionList.record.length > 0) {
          setOptionSection(
            sectionList.record.map((item) => ({
              value: item.id,
              label: item.name,
            }))
          );
        } else {
          setOptionSection([{ value: "", label: "No Records Found!!" }]);
        }
      }
    } catch (error) {
      if (error?.response?.data?.message)
        toast.error(error?.response?.data?.message);
      else {
        toast.error("Something went wrong");
        console.log("error ->", error);
      }
    }
  };

  const handleSection = (e) => {
    setAttendance({ ...attendance, sectionid: e.target.value });
  };

  const handleChangeStudentAttendance = (index, student_id, target_value) => {
    const updatedStudentList = [...studentList];
    updatedStudentList[index].status = target_value ? "present" : "absent";
    setStudentList(updatedStudentList);
  };

  const formatDate = (dateString) => {
    if (!dateString) return "";
    const date = new Date(dateString);
    return `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(
      2,
      "0"
    )}-${String(date.getDate()).padStart(2, "0")}`;
  };
  console.log("studentList-->", studentList);

  const handleSave = async () => {
    try {
      let attendanceRecords = [];
      console.log("studentList ->", studentList);
      for (let student of studentList) {
        console.log(" student ->", student);
        attendanceRecords.push({
          id: student.attendance_id,
          class_id: student.classid,
          section_id: student.section_id,
          studentadmission_id: student.student_add_id,
          total_lectures: student.total_lectures,
          type: "daily",
          session_id: attendance?.sessions,
          month: attendance?.month,
          year: attendance?.sessionsyear,
        });
      }
      if (attendanceRecords) {
        console.log("attendanceRecords ->", attendanceRecords);

        let attRecord = await schoolApi.updateMultiAttRec(attendanceRecords);
        console.log("attRecord ->", attRecord);
        if (attRecord.results) {
          attRecord.results.forEach((attend) => {
            studentList.forEach((student) => {
              if (
                attend.response[0].studentadmission_id ===
                student.student_add_id
              ) {
                student.attendance_id = attend.response[0].id;
              }
            });
          });
          let attLineItemRecords = studentList.map((student) => ({
            ...student,
            status: student.status ? student.status : "absent",
            date: attendance.date,
          }));
          console.log("attLineItemRecords ->", attLineItemRecords);

          let updatedLineItm = await schoolApi.updateMultiAttLineItmRec(
            attLineItemRecords
          );
          if (updatedLineItm) {
            handleStudents();
            toast.success("Record updated successfully");
          }
        }
      }
    } catch (error) {
      if (error?.response?.data?.message)
        toast.error(error?.response?.data?.message);
      else {
        toast.error("Something went wrong");
        console.log("error ->", error);
      }
    }
  };

  const handleCurrentSelectedDate = (event) => {
    const selectedDate = event.target.value;
    const monthNames = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    const selectedMonth = monthNames[new Date(selectedDate).getMonth()];
    setAttendance({
      ...attendance,
      [event.target.name]: selectedDate,
      month: selectedMonth,
    });
  };

  return (
    <>
      <Row className="g-0">
        <Col lg={10} className="mx-4">
          <Link className="nav-link" to="/">
            Home <i className="fa-solid fa-chevron-right"></i>
            <strong> Attendance</strong>{" "}
          </Link>
        </Col>

        <Row>
          <Col lg={12} className="p-lg-4">
            <Card>
              <Card.Body>
                <h4 className="atten_header">Daily Attendance</h4>
                <hr className="hrstyle" />
                <Row className="mt-3">
                  <Col lg={3}>
                    <Form.Group controlId="formClassName">
                      <Form.Label className="text-formatted">
                        Class Name
                      </Form.Label>
                      <Form.Select
                        size="lg"
                        name="classid"
                        onChange={handleClasses}
                        value={attendance?.classid}
                      >
                        <option value="">Select Class</option>
                        {optionClasses.map((className, index) => (
                          <option key={index} value={className.value}>
                            {className.label}
                          </option>
                        ))}
                      </Form.Select>
                    </Form.Group>
                  </Col>
                  <Col lg={3}>
                    <Form.Group className="mb-3" controlId="formClassName">
                      <Form.Label className="text-formatted">
                        Section Name
                      </Form.Label>
                      <Form.Select
                        size="lg"
                        name="sectionid"
                        disabled={attendance?.classid ? false : true}
                        onChange={handleSection}
                        value={attendance?.sectionid}
                      >
                        <option value="">Select Section</option>
                        {optionSection.map((sec, index) => (
                          <option key={index} value={sec.value}>
                            {sec.label}
                          </option>
                        ))}
                      </Form.Select>
                    </Form.Group>
                  </Col>
                  <Col lg={3}>
                    <Form.Group className="mb-3" controlId="formDob">
                      <Form.Label className="text-formatted">
                        Attendance Date
                      </Form.Label>
                      {/* <Form.Control
                        type="date"
                        className="attendance-date"
                        name="date"
                        onChange={handleCurrentSelectedDate}
                        value={formatDate(attendance?.date)}
                        size="lg"
                      /> */}
                      <DatePicker
                        required
                        selected={reportData.date}
                        onChange={(date) => handleDateChange(date, "date")}
                        dateFormat="dd-MM-yyyy"
                        className="form-control"
                        disabled={attendance.sectionid ? false : true}
                        maxDate={new Date()}
                        showMonthDropdown
                        showYearDropdown
                        autoComplete="off"
                        placeholderText="Enter attendance date"
                        id="DatePickerpadding"
                      />
                    </Form.Group>
                  </Col>
                  <Col lg={3} className="text-end">
                    <div>
                      <Button
                        className="btn btnHover"
                        variant="primary"
                        size="lg"
                        onClick={handleStudents}
                        style={{ marginTop: "30px" }}
                      >
                        <i className="fa fa-search my-2"></i> Search
                      </Button>
                    </div>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Col>
        </Row>

        {showStudent && (
          <Row>
            <Col lg={12} className="p-lg-4">
              <Card>
                <Card.Body>
                  <h4 className="headingstyle">Student Attendance Status</h4>
                  <table className="table table-striped table-bordered table-hover">
                    <thead style={{ textAlign: "center" }}>
                      <tr>
                        <th>S.No.</th>
                        <th>Student Name</th>
                        <th>Present/Absent</th>
                      </tr>
                    </thead>
                    <tbody style={{ textAlign: "center" }}>
                      {studentList && studentList.length > 0 ? (
                        studentList.map((item, index) => (
                          <tr key={item.student_id}>
                            <td>{index + 1}</td>
                            <td>{item.studentname}</td>
                            <td>
                              <Form>
                                <Form.Check
                                  type="switch"
                                  id={item.id}
                                  name="status"
                                  checked={item.status === "present"}
                                  onChange={(e) =>
                                    handleChangeStudentAttendance(
                                      index,
                                      item.student_id,
                                      e.target.checked
                                    )
                                  }
                                />
                              </Form>
                            </td>
                          </tr>
                        ))
                      ) : (
                        <tr>
                          <td colSpan="3">No students found.</td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                  <Row className="d-flex justify-content-end">
                    <Col lg="auto">
                      <Button
                        type="button"
                        variant="primary"
                        size="lg"
                        className="mt-2"
                        onClick={handleSave}
                      >
                        Submit
                      </Button>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        )}
      </Row>
      <ToastContainer
        position="top-right"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </>
  );
};

export default DailyAttendance;