import React, { useEffect, useState } from "react";
import { Modal, Button, Form, Row, Col } from "react-bootstrap";
// import WhatsAppAPI from "../../api/WhatsAppAPI";
import { ToastContainer, toast } from "react-toastify";
import schoolApi from "../../../api/schoolApi";
import { MultiSelect } from "react-multi-select-component";

const AddGroupModal = ({ show, onHide, fetchGroupRecords, selectedGroup }) => {
  const [groupName, setGroupName] = useState("");
  const [selectedClasses, setSelectedClasses] = useState([]);
  const [selectedSection, setselectedSection] = useState([]);
  const [selectedContacts, setSelectedContacts] = useState([]);
  const [selectedLeads, setSelectedLeads] = useState([]);
  const [classOptions, setClassOptions] = useState([]);
  const [contactOptions, setContactOptions] = useState([]);
  const [leadOptions, setLeadOptions] = useState([]);
  const [typeOptions, settypeOptions] = useState([
    { label: "Parent", value: "parent" },
    { label: "Student", value: "student" },
  ]);
  const [optionSection, setOptionSection] = useState([]);
  const [selectedType, setselectedType] = useState([]);
  // const typeOptions = [
  //   { label: "Parent", value: "parent" },
  //   { label: "Student", value: "student" },
  // ];
  const fatchstudetnsparent = async () => {
    const sessions = await schoolApi.fetchSessions();
    let fetchSelectedSessionResult = null;
    if (sessions) {
      const currentYear = new Date().getFullYear();
      const nextYear = currentYear + 1;

      fetchSelectedSessionResult = sessions.find((res) => {
        return res.year === `${currentYear}-${nextYear}`;
      });
    }
    console.log("fetchSelectedSessionResult ->", fetchSelectedSessionResult);
    console.log("selectedType ->", selectedType);

    try {
      if (selectedClasses.length > 0 && selectedType.length > 0) {
        const listcontact = await schoolApi.getAllcontactwithstudentname(
          selectedType,
          //  type,
          selectedClasses,
          selectedSection,
          fetchSelectedSessionResult.id
        );
        console.log("listcontact ->", listcontact);

        if (listcontact.success) {
          const listofcontact = listcontact.data.map((contact) => ({
            label: `${contact.name} ${contact.whatsapp_number}`,
            value: {
              member_id: contact.id,
              name: contact.name,
              whatsapp_number: contact.whatsapp_number,
              recordtypename: "contact",
            },
          }));
          setLeadOptions(listofcontact);
          setSelectedLeads([]);
        } else {
          toast.error("Failed to fetch lead data.");
          setSelectedLeads([]);
          setLeadOptions([]);
        }
      }
    } catch (error) {
      setLeadOptions([]);
      setSelectedLeads([]);

      console.log("error ->", error);
    }
  };
  useEffect(() => {
    fatchstudetnsparent();
  }, [selectedType]);
  useEffect(() => {
    fetchAllData();
  }, []);

  useEffect(() => {
    if (selectedGroup.length !== 0) {
      setGroupName(selectedGroup.name);
    } else {
      resetForm();
    }
  }, [selectedGroup]);

  const fetchAllData = async () => {
    try {
      let classList = await schoolApi.getActiveClassRecords();
      console.log("classListclassList-->", classList);
      if (classList?.result) {
        let ar = [];
        classList?.result.forEach((item) => {
          var obj = {};
          obj.value = item.id;
          obj.label = item.classname;
          ar.push(obj);
        });
        setClassOptions(ar);
      }

      const employeeResult = await schoolApi.getallemploye();
      console.log("contactsResult ->", employeeResult);
      if (employeeResult.success) {
        const contactOptions = employeeResult.data.map((employee) => ({
          label: `${employee.name} ${employee.whatsapp_number} (${employee.recordtype})`,
          value: {
            member_id: employee.id,
            name: employee.name,
            whatsapp_number: employee.whatsapp_number,
            recordtypename: "employee",
          },
        }));
        console.log("contactOptions->", contactOptions);

        setContactOptions(contactOptions);
      } else {
        toast.error("Failed to fetch contact data.");
      }
    } catch (error) {
      console.log("error", error);

      toast.error("Error fetching data.");
    }
  };
  const resetForm = () => {
    setGroupName("");
    setSelectedContacts([]);
    setSelectedLeads([]);
  };
  const resetdata = () => {
    //setselectedType("");
    setselectedType([]);
    setLeadOptions([]);
    setSelectedLeads([]);
    setLeadOptions([]);
  };

  const setclass = (e) => {
    setSelectedClasses(e);
    resetdata();
  };
  const setSection = (e) => {
    setselectedSection(e);
    resetdata();
  };
  const handleSubmit = async (e) => {
    console.log("hear nw");
    e.preventDefault();
    if (
      !groupName ||
      (!selectedContacts.length && !selectedLeads.length) ||
      !selectedGroup
    ) {
      if (selectedGroup.id) {
        toast.error("Please add atleast one member.");
      } else {
        toast.error("Please fill in all fields.");
      }
      return;
    } else {
      console.log("hear");
    }

    const members = [
      ...selectedContacts.map((contact) => ({
        member_id: contact.value.member_id,
        name: contact.value.name,
        whatsapp_number: contact.value.whatsapp_number,
        recordtypename: "contact",
      })),
      ...selectedLeads.map((lead) => ({
        member_id: lead.value.member_id,
        name: lead.value.name,
        whatsapp_number: lead.value.whatsapp_number,
        recordtypename: "lead",
      })),
    ];

    const formData = new FormData();
    formData.append("group_name", groupName);
    formData.append("members", JSON.stringify(members));

    try {
      let result;
      if (selectedGroup.id) {
        result = await schoolApi.addMoreMembers(selectedGroup.id, formData);
      } else {
        result = await schoolApi.createGroup(formData);
      }
      if (result.success) {
        toast.success(
          selectedGroup
            ? "Group updated successfully!"
            : "Group added successfully!"
        );
        fetchGroupRecords();
        onHide();
      } else {
        toast.error(result.error);
      }
    } catch (error) {
      toast.error("Error saving group.");
    }
  };
  const isFormValid = () => {
    const totalMembers = selectedContacts.length + selectedLeads.length;
    return groupName && totalMembers >= 2;
  };

  console.log("selectedClasses ->", selectedClasses);
  useEffect(() => {
    fatchsection();
  }, [selectedClasses]);
  const fatchsection = async () => {
    if (selectedClasses.length === 1) {
      let sectionList = await schoolApi.getSectionRecordById(
        selectedClasses[0].value
      );
      console.log("sh section list == ", sectionList);

      if (sectionList.success) {
        let ar = [];
        sectionList?.record.forEach((item) => {
          var obj = {};
          obj.value = item.id;
          obj.label = item.name;
          ar.push(obj);
        });
        setOptionSection(ar);
      } else {
        setOptionSection([]);
      }
    } else {
      setOptionSection([]);
      setselectedSection([]);
    }
  };
  return (
    <Modal show={show} onHide={onHide} animation={false} size="lg" centered>
      <Modal.Header closeButton>
        <Modal.Title>
          {selectedGroup.length !== 0 ? "Add More Members" : "Add Group"}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleSubmit} noValidate>
          <Row className="p-2 mb-3">
            <Col lg={6} sm={12}>
              <Form.Group className="mb-3" controlId="formGroupName">
                <Form.Label>Group Name</Form.Label>
                <Form.Control
                  type="text"
                  value={groupName}
                  onChange={(e) => setGroupName(e.target.value)}
                  required
                  placeholder="Enter Group Name"
                  disabled={selectedGroup.length !== 0}
                />
              </Form.Group>
            </Col>

            {/* MultiSelect for Users */}
            <Col lg={6} sm={12}>
              <Form.Group className="mb-3" controlId="formSelectUsers">
                <Form.Label>Select Class</Form.Label>
                <MultiSelect
                  options={classOptions}
                  value={selectedClasses}
                  onChange={setclass}
                  labelledBy="Select Class"
                />
              </Form.Group>
            </Col>
            {optionSection.length > 0 && (
              <Col lg={6} sm={12}>
                <Form.Group className="mb-3" controlId="formClassName">
                  <Form.Label className="text-formatted">
                    Section Name
                  </Form.Label>
                  <MultiSelect
                    options={optionSection}
                    value={selectedSection}
                    onChange={setSection}
                    labelledBy="Select Section"
                  />
                </Form.Group>
              </Col>
            )}
            <Col lg={6} sm={12}>
              <Form.Group className="mb-3" controlId="formSelectContacts">
                <Form.Label>Select Type</Form.Label>
                {/* <Form.Select
                  size="lg"
                  name="type"
                  onChange={typeChange}
                  value={selectedType}
                >
                  <option value="" disabled selected>
                    -- Select Type --
                  </option>
                  <option value="Parent">Parents</option>
                  <option value="Student">Students</option>
                </Form.Select> */}
                <MultiSelect
                  options={typeOptions}
                  value={selectedType}
                  onChange={setselectedType}
                  labelledBy="Select Type"
                />
              </Form.Group>
            </Col>

            {/* MultiSelect for Leads */}
            <Col lg={6} sm={12}>
              <Form.Group className="mb-3" controlId="formSelectLeads">
                <Form.Label>Select Contact</Form.Label>
                <MultiSelect
                  options={leadOptions}
                  value={selectedLeads}
                  onChange={setSelectedLeads}
                  labelledBy="Select Contact"
                />
              </Form.Group>
            </Col>
            {/* MultiSelect for Leads */}
            <Col lg={6} sm={12}>
              <Form.Group className="mb-3" controlId="formSelectLeads">
                <Form.Label>Select Employee</Form.Label>
                <MultiSelect
                  options={contactOptions}
                  value={selectedContacts}
                  onChange={setSelectedContacts}
                  labelledBy="Select Employe"
                />
              </Form.Group>
            </Col>
          </Row>
        </Form>
      </Modal.Body>
      {/* <Modal.Footer>
        <Button variant="light" onClick={onHide}>
          Close
        </Button>
        <Button
          variant="outline-primary"
          onClick={handleSubmit}
          disabled={selectedGroup.length === 0 && !isFormValid()}
        >
          Save
        </Button>
      </Modal.Footer> */}
      <Modal.Footer>
        <Button variant="secondary" onClick={onHide}>
          Close
        </Button>
        <Button
          variant="primary"
          onClick={handleSubmit}
          disabled={selectedGroup.length === 0 && !isFormValid()}
        >
          Save
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default AddGroupModal;
