import React, { useState, useEffect } from "react";
import { Form, Row, Col } from "react-bootstrap";
import "../../resources/css/Student.css";
import schoolApi from "../../api/schoolApi";
import Select from "react-select";
import AssignTransport from "../transport/AssignTransport";
import { toast } from "react-toastify";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "react-toastify/dist/ReactToastify.css";
import moment from "moment";
import "../../App.css";

function StudentTab(props) {
  console.log("propds student edit=>", props);
  if (props?.student?.section_id) {
    props.student.sectionid = props?.student?.section_id;
  }
  const [optionClasses, setOptionClasses] = useState([]);
  const [optionSection, setOptionSection] = useState([
    props.student.sectionid
      ? { value: props.student.sectionid, label: props.student.section_name }
      : { value: "", label: "No Records Found!!!" },
  ]);
  // const [optionVehicals, setoptionVehicals] = useState([]);
  const [optionSession, setOptionSession] = useState([]);
  const [validated, setValidated] = useState(false);
  const [isChecked, setIsChecked] = useState();
  const [discountRecords, setDiscountRecords] = useState();
  const [discountOptions, setDiscountOptions] = useState(
    props.discountRecords ? props.discountRecords : []
  );

  const [assignTransportRecord, setAssignTransportRecord] = useState({});
  const [feeTypeOptions, setFeeTypeOptions] = useState([]);
  const [student, setStudent] = useState({});
  const [discountAllRecords, setDiscountAllRecords] = useState();
  const maxDate = moment().subtract(3.5, "years").format("YYYY-MM-DD");
  //Added By Shahir Hussain 27-08-2024
  const [currentMonthName] = useState(() => {
    const monthNames = [
      "january",
      "february",
      "march",
      "april",
      "may",
      "june",
      "july",
      "august",
      "september",
      "october",
      "november",
      "december",
    ];
    return monthNames[new Date().getMonth()];
  });
  const [currentMonth, setCurrentMonth] = useState(
    () => new Date().getMonth() + 1
  );
  //added by shahir hussain
  const [currentYear] = useState(() => new Date().getFullYear());
  const [currentDate] = useState(() => new Date().getDate() + 1);
  const [currentSessionData, setCurrentSessionData] = useState();
  const [reportData, setReportData] = useState({
    classid: "",
    session_id: "",
    date: null,
  }); // added by muskan 11-sept-24

  const handleDateChange = (date, name, e) => {
    console.log("student data == ", student);
    console.log("date = ", date);
    console.log("name = ", name);

    const day = String(date.getDate()).padStart(2, "0"); // Get day and pad with zero if needed
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-based in JavaScript, so add 1
    const year = date.getFullYear(); // Get full year

    console.log("day = ", day);
    console.log("month = ", month);
    console.log("year = ", year);

    const dateFormat = `${year}-${month}-${day}`;
    console.log("dateFormat == ", dateFormat);

    setReportData((prevState) => ({
      ...prevState,
      [name]: date,
    }));
    setStudent({ ...student, [name]: dateFormat });
    props.handleStudentTab({ ...student, [name]: dateFormat });
  };
  //End By Shahir Hussain 27-08-2024

  useEffect(() => {
    async function init() {
      try {
        const sessions = await schoolApi.fetchSessions();
        console.log("sessions@!@!=>", sessions);
        if (sessions) {
          const currentYear = new Date().getFullYear();
          const nextYear = currentYear + 1;
          sessions.find((res) => {
            return res.year === `${currentYear}-${nextYear}`;
          });
        } else {
        }

        console.log("Yesy");
        if (props?.student?.classid) {
          console.log("props?.lead?.class_id=>", props?.student?.classid);
          let feeMasterResult = await schoolApi.fetchFeeMasterByIdOrClassid(
            props?.student?.classid
          );
          console.log("feeMasterResult by classid-->", feeMasterResult);
          if (feeMasterResult?.success) {
            let ar = [];
            feeMasterResult.result.forEach((item) => {
              var obj = {};
              obj.value = item.id;
              obj.label = item.type;
              ar.push(obj);
            });
            console.log("ar- fee master result->", ar);
            setFeeTypeOptions(ar);
          } else {
            setFeeTypeOptions([]);
          }
        }
      } catch (error) {
        toast.error(error?.response?.data?.message);
      }
    }
    init();
  }, [props?.student?.class_id]);

  useEffect(() => {
    async function init() {
      //  props.student.lead = false;
      try {
        if (props?.student !== null) {
          setStudent(props?.student);
        }
        // let classList = await schoolApi.fetchClasses();
        let classList = await schoolApi.getActiveClassRecords();
        console.log("classListclassList-->", classList);
        if (classList?.result) {
          let ar = [];
          classList?.result.map((item) => {
            var obj = {};
            obj.value = item.id;
            obj.label = item.classname;
            ar.push(obj);
          });
          setOptionClasses(ar);
        } else {
          setOptionClasses([]);
        }
        console.log("sh Class id == ", props?.student?.classid);
        if (props?.student?.classid) {
          let sectionList = await schoolApi.getSectionRecordById(
            props?.student?.classid
          );
          console.log("sh section list == ", sectionList);
          // let sectionList = await schoolApi.fetchSections();
          if (sectionList.success) {
            let ar = [];
            sectionList?.record.map((item) => {
              var obj = {};
              obj.value = item.id;
              obj.label = item.name;
              ar.push(obj);
            });
            setOptionSection(ar);
          } else {
            setOptionSection([]);
          }
        }
        //changes by shahir hussain 27-08-2024

        let sessionList = await schoolApi.fetchSessions();
        console.log("sessionList on student tab", sessionList);
        if (sessionList) {
          let ar = [];
          let sessionYear = []; //add by shahir hussain
          sessionList.map((item) => {
            var obj = {};
            obj.value = item.id;
            obj.label = item.year;

            //create this funtionallity by shahir hussain 27-08-2024
            sessionYear = item.year.split("-");
            console.log("sh@@@ session year == ", sessionYear);
            if (sessionYear[0] == currentYear) {
              setCurrentSessionData(item);
              console.log("success year == ", item);
            }

            //End By Shahir hussain 27-08-2024
            ar.push(obj);
          });
          setOptionSession(ar);
        } else {
          setOptionSession([]);
        }
        // let vehicalList = await schoolApi.fetchVehicles();
        // if (vehicalList) {
        //   let ar = [];
        //   vehicalList.map((item) => {
        //     var obj = {};
        //     obj.value = item.id;
        //     obj.label = item.vehicle_no + " " + item.type;
        //     ar.push(obj);
        //   });
        //   setoptionVehicals(ar);
        // } else {
        //   setoptionVehicals([]);
        // }

        let discountResults = await schoolApi.fetchFeeDiscounts();
        console.log("discountResults-->", discountResults);

        if (discountResults[0].json_agg) {
          let ar = [];
          let data;
          discountResults[0].json_agg.map((item) => {
            console.log("item for discount-->", item);
            var obj = {};
            obj.value = item.discount.id;
            obj.label = item.discount.name;
            ar.push(obj);
          });

          data = ar.filter((rec) => rec.label !== "RTE");
          setDiscountRecords(data);
          setDiscountAllRecords(ar);
        } else {
          setDiscountAllRecords([]);
          setDiscountRecords([]);
        }
      } catch (error) {
        toast.error(error?.response?.data?.message);
      }
    }
    init();
  }, []);

  console.log("props student", props.student);
  console.log("discountRecords-->", discountRecords);

  useEffect(() => {
    if (props.validated) {
      if (checkRequredFields()) {
        setValidated(true);
      }
    }
    if (typeof props.student.isLateAdmission == "undefined") {
      setStudent((student) => ({
        ...student,
        isLateAdmission: false,
      }));
      props.handleStudentTab((student) => ({
        ...student,
        isLateAdmission: false,
      }));
    }
  }, [props.validated]);
  const handleCurrentSelectedDate = (event) => {
    setStudent({ ...student, [event.target.name]: event.target.value });
    props.handleStudentTab({
      ...student,
      [event.target.name]: event.target.value,
    });
  };
  const handleChange = (event) => {
    console.log("event.target.value", event.target.value);
    setStudent({ ...student, [event.target.name]: event.target.value });
    props.handleStudentTab({
      ...student,
      [event.target.name]: event.target.value,
    });
  };
  const handlePhoneChange = (event) => {
    const inputPhone = event.target.value.replace(/\D/g, "").slice(0, 10);
    handleChange({ target: { name: "phone", value: inputPhone } });
  };
  const handleEmailChange = (event) => {
    const enteredEmail = event.target.value;
    handleChange({ target: { name: "email", value: enteredEmail } });
  };
  const handleAadharNum = (event) => {
    const inputValue = event.target.value.replace(/\D/g, "").slice(0, 12);
    handleChange({ target: { name: "adharnumber", value: inputValue } });
  };

  const handleClasses = async (e) => {
    //This Code Added By Shahir Hussain 27-08-2024
    try {
      console.log("e.target.value@@@+>", e.target.value);
      setStudent({ ...student, classid: e.target.value, sectionid: "" });
      props.handleStudentTab({
        ...student,
        classid: e.target.value,
        sectionid: "",
        category : "",
      });
      if (e.target.value) {
        let sectionList = await schoolApi.getSectionRecordById(e.target.value);
        console.log("sectionList@@+>", sectionList);
        if (sectionList?.record && sectionList?.record?.length > 0) {
          let sectionOptions = sectionList?.record.map((item) => ({
            value: item.id,
            label: item.name,
          }));
          setOptionSection(sectionOptions);
        } else {
          setOptionSection([{ value: "", label: "No Records Found!!" }]);
        }
      }
    } catch (error) {
      toast.error(error?.response?.data?.message);
    }
  };

  const handleSection = (e) => {
    setStudent({ ...student, sectionid: e.target.value });
    props.handleStudentTab({ ...student, sectionid: e.target.value });
  };
  const handleSession = (e) => {
    setStudent({ ...student, session_id: e.target.value });
    props.handleStudentTab({ ...student, session_id: e.target.value });
  };
  const handleDiscounts = (selectedOption) => {
    console.log("selectedOption-->", selectedOption);
    setDiscountOptions(selectedOption);
    props.handleStudentTab({ ...student, discounts: selectedOption });
  };
  const handleCategory = (e) => {
    setStudent({ ...student, category: e.target.value });
    console.log("i am on handleCategory-->", e.target.value);
    props.handleStudentTab({ ...student, category: e.target.value });
    fetchFeeMasters("category");
  };

  const handleAssignTransport = (record) => {
    setAssignTransportRecord(record);
    console.log("recordsSSS=>", record);
    props.handleStudentTab({ ...student, assign_transport: record });
  };
  console.log("assignTransportRecord--> on parent-->", assignTransportRecord);

  const handleFeeChange = (e) => {
    console.log("student ->", student);
    setStudent({ ...student, fee_type: e.target.value });
    // setFeeType(e.target.value);
    props.handleStudentTab({ ...student, fee_type: e.target.value });
  };

  const handleCheckbox = (event) => {
    setIsChecked(!isChecked);
    if (!isChecked === true) {
      student.permanentcountry = student.country;
      student.permanentstate = student.state;
      student.permanentcity = student.city;
      student.permanentpostalcode = student.pincode;
      student.permanentstreet = student.street;
      props.handleStudentTab(student);
    } else {
      student.permanentcountry = "";
      student.permanentstate = "";
      student.permanentcity = "";
      student.permanentpostalcode = "";
      student.permanentstreet = "";
      props.handleStudentTab(student);
    }
  };
  const checkRequredFields = () => {
    if (student.firstname && student.firstname.trim() !== "") {
      return false;
    }
    return true;
  };

  const formatDate = (dateString) => {
    if (!dateString) return "";
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are 0-based
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  const handleRteChange = (e) => {
    let data;
    if (e.target.checked === true) {
      console.log("check rte", discountAllRecords);
      // data = discountAllRecords.filter(rec => rec.label.includes('RTE'));
      data = discountAllRecords.filter((rec) =>
        rec.label.toLowerCase().includes("rte")
      );
      setDiscountRecords(data);
      setDiscountOptions(data);
      setStudent({
        ...student,
        [e.target.name]: e.target.checked,
      });
      props.handleStudentTab({ ...student, discounts: data });
      props.handleStudentTab({ ...student, isrte: e.target.checked });
    } else {
      console.log("check non rte");
      data = discountAllRecords.filter(
        (rec) => !rec.label.toLowerCase().includes("rte")
      );
      console.log("discount 2 ==", data);
      setDiscountRecords(data);
      setDiscountOptions();
      setStudent({
        ...student,
        [e.target.name]: false,
      });
      props.handleStudentTab({ ...student, discounts: data });
      props.handleStudentTab({ ...student, isrte: false });
    }
    console.log("discountRecords == ", data);
    console.log("student new data == ", student);
  };
  const fetchFeeMasters = async (callfrom) => {
    try {
      let res = await schoolApi.fetchFeeMasterByIdOrClassid(student.classid);
      console.log("re ->", res);
      let currentYear = new Date().getFullYear();
      let feeMasterResult = res?.result?.filter(
        (item) => item?.session?.split("-")[0] === currentYear.toString()
      );

      if (feeMasterResult) {
        let ar = feeMasterResult.map((item) => {
          console.log("student.isLateAdmission ->", student.isLateAdmission);

          let isLateAdmission = student.isLateAdmission;
          console.log("isLateAdmission ->", isLateAdmission);
          if (callfrom === "category") {
            isLateAdmission = isLateAdmission ? false : true;
          }
          if (!isLateAdmission) {
            setStudent((prevState) => ({
              ...prevState,
              fee_type: "",
            }));
          }

          return {
            value: item.id,
            label: item.type,
            disabled: isLateAdmission !== true && item.type !== "Monthly",
            selected: isLateAdmission !== true && item.type === "Monthly",
          };
        });
        console.log(
          "props.student.isLateAdmission ->",
          props.student.isLateAdmission
        );

        setFeeTypeOptions(ar);
      } else {
        setFeeTypeOptions([]);
      }
    } catch (error) {
      console.log("error -> ", error.response);
      if (error.response.status === 404) {
        return toast.error("Create fee master record first!!");
      } else toast.error(error?.response?.data?.message);
    }
  };
  const handlelatestudentChange = (e) => {
    const isLateAdmission = e.target.checked;
    console.log("isLateAdmission ->", isLateAdmission);
    props.handleStudentTab((prevState) => ({
      ...prevState,
      isLateAdmission: isLateAdmission,
    }));
    setStudent((prevState) => ({
      ...prevState,
      isLateAdmission: isLateAdmission,
    }));
    console.log("student ->", student);

    fetchFeeMasters();
  };

  return (
    <>
      <Form noValidate validated={validated}>
        <Row>
          <Col md={6}>
            <Form>
              <Form.Group className="mb-3" controlId="formFirstName">
                <Form.Label className="text-formatted">First Name</Form.Label>
                {console.log(
                  "props?.lead?.firstname@@=>",
                  props?.lead?.firstname
                )}
                {console.log(
                  "props.student.firstname",
                  props?.student?.firstname
                )}
                <Form.Control
                  type="text"
                  placeholder="Enter First Name"
                  size="lg"
                  name="firstname"
                  required
                  onChange={handleChange}
                  value={student?.firstname}
                />
              </Form.Group>
            </Form>
          </Col>
          <Col md={6}>
            <Form>
              <Form.Group className="mb-3" controlId="formLastName">
                <Form.Label className="text-formatted">Last Name</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter Last Name"
                  size="lg"
                  name="lastname"
                  onChange={handleChange}
                  value={student?.lastname}
                />
              </Form.Group>
            </Form>
          </Col>
        </Row>

        <Row>
          <Col md={12}>
            <h3>Present Address :</h3>
            <hr className="mt-3" />
          </Col>
        </Row>
        <Row className="mt-3">
          <Col md={6}>
            <Form>
              <Form.Group className="mb-3" controlId="formCountry">
                <Form.Label className="text-formatted">Country</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter Country"
                  size="lg"
                  name="country"
                  onChange={handleChange}
                  value={student?.country}
                />
              </Form.Group>
            </Form>
          </Col>
          <Col md={6}>
            <Form>
              <Form.Group className="mb-3" controlId="formState">
                <Form.Label className="text-formatted">State</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter State"
                  size="lg"
                  name="state"
                  onChange={handleChange}
                  value={student?.state}
                />
              </Form.Group>
            </Form>
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <Form>
              <Form.Group className="mb-3" controlId="formCity">
                <Form.Label className="text-formatted">City</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter City"
                  size="lg"
                  name="city"
                  onChange={handleChange}
                  value={student?.city}
                />
              </Form.Group>
            </Form>
          </Col>
          <Col md={6}>
            <Form>
              <Form.Group className="mb-3" controlId="formPostalCode">
                <Form.Label className="text-formatted">Postal Code</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter Postal Code"
                  size="lg"
                  name="pincode"
                  onChange={handleChange}
                  value={student?.pincode}
                />
              </Form.Group>
            </Form>
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <Form>
              <Form.Group className="mb-3" controlId="formStreet">
                <Form.Label className="text-formatted">Street</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter Street"
                  size="lg"
                  name="street"
                  onChange={handleChange}
                  value={student?.street}
                />
              </Form.Group>
            </Form>
          </Col>
        </Row>
        <Row>
          <Col md={7}>
            <h3>Permanent Address - check if address same :</h3>
          </Col>
          <Col md={5}>
            <Form>
              <Form.Group>
                <Form.Check
                  type="checkbox"
                  name="checkVal"
                  style={{
                    marginLeft: "-19px",
                    fontSize: "1.3rem",
                    marginTop: "0.3rem",
                  }}
                  onClick={handleCheckbox}
                />
              </Form.Group>
            </Form>
          </Col>
        </Row>
        <hr className="mt-3"></hr>
        <Row className="mt-3">
          <Col md={6}>
            <Form>
              <Form.Group className="mb-3" controlId="formCountry">
                <Form.Label className="text-formatted">Country</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter Country"
                  size="lg"
                  name="permanentcountry"
                  onChange={handleChange}
                  value={student?.permanentcountry}
                />
              </Form.Group>
            </Form>
          </Col>
          <Col md={6}>
            <Form>
              <Form.Group className="mb-3" controlId="formState">
                <Form.Label className="text-formatted">State</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter State"
                  size="lg"
                  name="permanentstate"
                  onChange={handleChange}
                  value={student?.permanentstate}
                />
              </Form.Group>
            </Form>
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <Form>
              <Form.Group className="mb-3" controlId="formCity">
                <Form.Label className="text-formatted">City</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter City"
                  size="lg"
                  name="permanentcity"
                  onChange={handleChange}
                  value={student?.permanentcity}
                />
              </Form.Group>
            </Form>
          </Col>
          <Col md={6}>
            <Form>
              <Form.Group className="mb-3" controlId="formPostalCode">
                <Form.Label className="text-formatted">Postal Code</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter Postal Code"
                  size="lg"
                  name="permanentpostalcode"
                  onChange={handleChange}
                  value={student?.permanentpostalcode}
                />
              </Form.Group>
            </Form>
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <Form>
              <Form.Group className="mb-3" controlId="formStreet">
                <Form.Label className="text-formatted">Street</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter Street"
                  size="lg"
                  name="permanentstreet"
                  onChange={handleChange}
                  value={student?.permanentstreet}
                />
              </Form.Group>
            </Form>
          </Col>
        </Row>
        <Row>
          <Col md={3}>
            <h3>Other Information :</h3>
          </Col>

          <Col md={9}></Col>
          <hr className="mt-3" />
        </Row>
        <Row className="mt-3">
          <Col lg={4}>
            <Form.Group className=" mb-3" controlId="formDob">
              <Form.Label className="text-formatted">Dob</Form.Label>
              <br />
              <DatePicker
                size="lg"
                selected={reportData.dateofbirth}
                onChange={(date) => handleDateChange(date, "dateofbirth")}
                dateFormat="dd-MM-yyyy"
                showMonthDropdown
                showYearDropdown
                className="react-datepicker__input-container form-control" // This makes the DatePicker look like a form control
                placeholderText="Enter date of birth"
                maxDate={new Date(new Date().setFullYear(new Date().getFullYear() - 3.5))}
                onKeyDown={(e) => e.preventDefault()}
                highlightDates={[new Date()]}
              />
            </Form.Group>
          </Col>
          <Col md={4}>
            <Form>
              <Form.Group className="mb-3" controlId="formGender">
                <Form.Label className="text-formatted">Gender</Form.Label>
                <Form.Select
                  size="lg"
                  name="gender"
                  onChange={handleChange}
                  value={student?.gender}
                >
                  <option value="">Select Gender</option>
                  <option value="Male">Male</option>
                  <option value="Female">Female</option>
                  <option value="Other">Other</option>
                </Form.Select>
              </Form.Group>
            </Form>
          </Col>
          <Col md={4}>
            <Form>
              <Form.Group className="mb-3" controlId="formEmail">
                <Form.Label className="text-formatted">Email</Form.Label>
                <Form.Control
                  type="email"
                  placeholder="Enter Email"
                  size="lg"
                  name="email"
                  onChange={handleEmailChange}
                  value={student?.email}
                />
              </Form.Group>
            </Form>
          </Col>
        </Row>
        <Row>
          <Col md={4}>
            <Form>
              <Form.Group className="mb-3" controlId="formClassName">
                <Form.Label className="text-formatted">Class Name</Form.Label>
                <Form.Select
                  size="lg"
                  name="classid"
                  required
                  onChange={handleClasses}
                  value={student?.classid}
                >
                  <option value="">Select Class</option>
                  {optionClasses.map((className, index) => (
                    // console.log("className.value@@=>", className.value),
                    <option key={index} value={className.value}>
                      {/* {console.log("classNameclassName-->", className)} */}
                      {className.label}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>
            </Form>
          </Col>
          <Col md={4}>
            <Form>
              <Form.Group className="mb-3" controlId="formClassName">
                <Form.Label className="text-formatted">Section Name</Form.Label>
                <Form.Select
                  size="lg"
                  name="sectionid"
                  required
                  disabled={student?.classid ? false : true}
                  onChange={handleSection}
                  value={student?.sectionid}
                >
                  <option value="">Select Section</option>
                  {optionSection.map((sec, index) => (
                    <option key={index} value={sec.value}>
                      {sec.label}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>
            </Form>
          </Col>
          <Col md={4}>
            <Form>
              <Form.Group className="mb-3" controlId="formClassName">
                <Form.Label className="text-formatted">Category</Form.Label>
                <Form.Select
                  size="lg"
                  name="category"
                  required
                  onChange={handleCategory}
                  disabled={student?.sectionid ? false : true}
                  value={student?.category}
                >
                  <option value="">--Select Category--</option>
                  <option value="General">General</option>
                  <option value="Obc">Obc</option>
                  <option value="Sc">Sc</option>
                  <option value="St">St</option>
                </Form.Select>
              </Form.Group>
            </Form>
          </Col>
          <Col md={4}>
            <Form>
              <Form.Group className="mb-3" controlId="formAadharNumber">
                <Form.Label className="text-formatted">
                  Aadhar Number
                </Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter Aadhar Number"
                  size="lg"
                  name="adharnumber"
                  onChange={handleAadharNum}
                  value={student?.adharnumber}
                />
              </Form.Group>
            </Form>
          </Col>
          <Col md={4}>
            <Form>
              <Form.Group className="mb-3" controlId="formPhone">
                <Form.Label className="text-formatted">Phone</Form.Label>
                <Form.Control
                  type="phone"
                  placeholder="Enter Phone"
                  size="lg"
                  value={student?.phone}
                  name="phone"
                  required
                  onChange={handlePhoneChange}
                />
              </Form.Group>
            </Form>
          </Col>
          <Col md={4}>
            <Form.Group className="mb-3" controlId="formReligion">
              <Form.Label className="text-formatted">Religion</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter Religion"
                size="lg"
                value={student?.religion}
                name="religion"
                onChange={handleChange}
              />
            </Form.Group>
          </Col>
          <Col lg={4}>
            <Form.Group className="mx-3 fees">
              <Form.Label className="form-view-label" htmlFor="formBasicClass">
                RTE
              </Form.Label>
              <Form.Check
                type="checkbox"
                style={{ fontSize: "20px" }}
                name="isrte"
                checked={student?.isrte}
                onChange={handleRteChange}
              />
            </Form.Group>
          </Col>
          <Col lg={4}>
            <Form.Group className="mx-3 fees">
              <Form.Label className="form-view-label" htmlFor="formBasicClass">
                Late Addmission
              </Form.Label>
              <Form.Check
                type="checkbox"
                style={{ fontSize: "20px" }}
                name="isLateAdmission"
                disabled={student?.category ? false : true}
                checked={student?.isLateAdmission}
                onChange={handlelatestudentChange}
              />
            </Form.Group>
          </Col>
          {/* <Col md={4}>
            <Form>
              <Form.Group className="mb-3" controlId="formClassName">
                <Form.Label
                  className="text-formatted"
                >
                  RTE
                </Form.Label>
                <Form.Select size="lg" name="isrte" required onChange={handleCategory} value={student?.isrte}>
                <option value="">--Select Category--</option>
                  <option value="true">RTE</option>
                  <option value="false">Non-RTE</option>
                </Form.Select>
              </Form.Group>
            </Form>
          </Col> */}
        </Row>
        <Row>
          <Col md={12}>
            <Form.Group className="mb-3 pb-1" controlId="formDescription">
              <Form.Label className="text-formatted">Description</Form.Label>
              <textarea
                className="form-control"
                placeholder="Enter Description"
                name="description"
                onChange={handleChange}
                value={student?.description}
              ></textarea>
            </Form.Group>
          </Col>
        </Row>
        {/* <Row>
        <Col md={12}>
            <h3>RTE Student :</h3>
            <hr />
          </Col>
          <Col lg={4}>
          <Form.Group className="mx-3 fees">
                <Form.Label
                  className="form-view-label"
                  htmlFor="formBasicClass"
                >
                  RTE
                </Form.Label>
                <Form.Check
                  type="checkbox"
                  style={{ fontSize: "20px" }}
                  name="isrte"
                  checked={student?.isrte}
                  onChange={handleRteChange}
                />
              </Form.Group>
          </Col>
          <Col lg={4}></Col>
          <Col lg={4}></Col>
          </Row> */}

        {/* ---------------------- Code Pawan ------------------------ */}

        {!props.student.student_addmission_id && (
          <>
            <Row>
              <Col md={12}>
                <h3>Fees And Discount :</h3>
                <hr />
              </Col>
            </Row>

            <Row className="mt-3">
              <Col md={4}>
                <Form>
                  {/* <Form.Group className=" mx-2 custom-dropdown">
                      <Form.Label
                        className="text-formatted"
                      >
                        Fee Installment Type
                      </Form.Label>
                      <Form.Select size="lg" 
                        required
                        name="fee_type"
                        value={student?.fee_type}
                        onChange={handleFeeChange}
                      >
                        <option value="">-- Select Type --</option>
                        {feeTypeOptions.length > 0 ? (
                            feeTypeOptions.map((res) => (
                              <option key={res.value} value={res.value}>
                                {res.label}
                              </option>
                            ))
                          ) : (
                            <option>No records found!!</option>
                          )}
                      </Form.Select>
                    </Form.Group> */}
                  <Form.Group className=" mx-2 custom-dropdown">
                    <Form.Label className="text-formatted">
                      Fee Installment Type
                    </Form.Label>
                    <Form.Select
                      size="lg"
                      required
                      name="fee_type"
                      disabled={student?.category ? false : true}
                      value={student?.fee_type}
                      onChange={handleFeeChange}
                    >
                      <option value="">-- Select Type --</option>
                      {feeTypeOptions.length > 0 ? (
                        feeTypeOptions.map(
                          (res) => (
                            console.log("fee type options == ", feeTypeOptions),
                            (
                              <option
                                key={res.value}
                                value={res.value}
                                disabled={res.disabled !== false}
                              >
                                {res.label}
                              </option>
                            )
                          )
                        )
                      ) : (
                        <option>No records found!!</option>
                      )}
                    </Form.Select>
                  </Form.Group>
                </Form>
              </Col>
              <Col md={4}>
                <Form>
                  <Form.Group
                    className="mx-3 fees"
                    style={{ marginBottom: "70px" }}
                  >
                    <Form.Label className="text-formatted">
                      Select Discounts
                    </Form.Label>
                    <Select
                      placeholder="Select discounts"
                      // value={fixedmonths}
                      value={discountOptions}
                      onChange={handleDiscounts}
                      options={discountRecords}
                      isMulti={true}
                      name="discount_id"
                      isDisabled={student.isrte === true}
                    ></Select>
                  </Form.Group>
                </Form>
              </Col>
            </Row>
          </>
        )}
      </Form>
    </>
  );
}
export default StudentTab;
